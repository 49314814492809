import { Box } from '@mui/material';
import { UIAutocompleted, UIButton, UITextInputWithTitle } from '../../../ui-kit';
import { styles } from './style';
import { useFormik } from 'formik';
import { companyDetailsSchema, sortTimeZone } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  deleteCompanyLogoRequest,
  editCustomerCompanyRequest,
  getCountriesRequest,
  getTimeZoneRequest
} from '../../../services/redux/slices';
import {
  adminLoadingStatus,
  currentPage,
  getAdminSuccessMessage,
  getCountries,
  getTimeZone,
  getTimezoneCurrentPage,
  getTimezoneTotalPage,
  settingLoadingStatus,
  totalPage
} from '../../../services/redux/selectors';
import { UploadProfilePicture } from '../../common/upload-profile-picture';
import { API, defaultValues } from '../../../constants';

export const EditDetails = ({
  company_name,
  company_description,
  company_country,
  company_post_code,
  company_city,
  company_locality,
  company_state,
  registration_number,
  time_zone_id,
  id,
  company_logo
}) => {
  const dispatch = useDispatch();

  const countries = useSelector(getCountries);
  const timeZone = useSelector(getTimeZone);
  const isLoading = useSelector(settingLoadingStatus);
  const totalPageCount = useSelector(totalPage);
  const currentPages = useSelector(currentPage);
  const totalTimezoneCount = useSelector(getTimezoneTotalPage);
  const currentTimezonePage = useSelector(getTimezoneCurrentPage);
  const isRequestLoading = useSelector(adminLoadingStatus);
  const successMessage = useSelector(getAdminSuccessMessage);
  let timer;
  let timezonePage = 0;

  const [timezones, setTimezones] = useState([]);
  const [country, setcountry] = useState([]);
  const [state, setState] = useState([]);
  const [SearchTimezone, setSearchTimezone] = useState('');
  const [SearchCountry, setSearchCountry] = useState('');
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [stateValue, setStateValue] = useState('');

  const { values, handleBlur, handleChange, touched, errors, setFieldValue, dirty } = useFormik({
    initialValues: {
      company_name: company_name || '',
      company_description: company_description || '',
      company_country: '',
      company_post_code: company_post_code || '',
      company_city: company_city || '',
      company_locality: company_locality || '',
      company_state: '',
      registration_number: registration_number || '',
      time_zone_id: '',
      id: id || '',
      company_logo: company_logo || ''
    },
    validationSchema: companyDetailsSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  useEffect(() => {
    if (successMessage) {
      setSelectedImageFile(null);
    }
  }, [successMessage]);

  useEffect(() => {
    if (company_state) {
      setStateValue(company_state);
    }
  }, []);

  useEffect(() => {
    let payload = {
      page: 1
    };
    if (SearchTimezone !== '') {
      payload.search_text = SearchTimezone;
    }
    dispatch(getTimeZoneRequest(payload));
    clearTimeout(timer);
  }, [SearchTimezone]);

  useEffect(() => {
    let payload = {
      page: 1
    };
    if (SearchCountry !== '') {
      payload.search_text = SearchCountry;
    }
    dispatch(getCountriesRequest(payload));
    clearTimeout(timer);
  }, [SearchCountry]);

  useEffect(() => {
    if (countries) {
      const list = [...country, ...countries];
      const temp = list.filter((item, index, self) => {
        return index === self.findIndex((s) => s.id === item.id);
      });
      setcountry(temp);
    }

    if (timeZone) {
      const list = [...timezones, ...timeZone];
      const temp = list.filter((item, index, self) => {
        return index === self.findIndex((s) => s.id === item.id);
      });
      setTimezones(sortTimeZone(temp, defaultValues.listConstants.timezone));
    }
  }, [countries, timeZone]);

  const handleSave = () => {
    dispatch(editCustomerCompanyRequest(values));
  };

  const selectedTimeZone = (val) => {
    const timeZoneInfo = timezones.find((zone) => zone.timezone === val);
    if (timeZoneInfo) {
      setFieldValue('time_zone_id', timeZoneInfo.id);
    }
  };

  const selectedCountry = (val) => {
    const countryInfo = countries.find((country) => country.country_name === val);
    if (countryInfo) {
      setFieldValue('company_country', countryInfo.id);
      setStateValue('');
      const states = countryInfo.states;
      setState(states);
    }
  };

  const selectedState = (val) => {
    const stateInfo = state.find((item) => item.name === val);
    if (stateInfo) {
      setStateValue(val);
      setFieldValue('company_state', stateInfo.id);
    }
  };

  const handleProfile = (val) => {
    setSelectedImageFile(val);
    setFieldValue('company_logo', val);
  };

  const handleDelete = () => {
    dispatch(deleteCompanyLogoRequest({ company_id: id }));
    selectedImageFile;
  };

  const getTimezones = () => {
    if (totalTimezoneCount > currentTimezonePage) {
      timezonePage = currentTimezonePage + 1;
      let payload = { page: timezonePage };
      if (SearchTimezone !== '') {
        payload.search_text = SearchTimezone;
      }
      dispatch(getTimeZoneRequest(payload));
      clearTimeout(timer);
    }
  };

  const getCountry = () => {
    if (totalPageCount > currentPages) {
      timezonePage = currentPages + 1;
      let payload = { page: timezonePage };
      if (SearchCountry !== '') {
        payload.search_text = SearchCountry;
      }
      dispatch(getCountriesRequest(payload));
      clearTimeout(timer);
    }
  };

  return (
    <Box sx={{ width: defaultValues.isResponsive ? '90%' : '80%', height: '100%' }}>
      <UploadProfilePicture
        onProfilePicChange={handleProfile}
        previewPicture={
          company_logo
            ? `${API.baseURL[API.currentEnv]}${company_logo}`
            : company_logo && `${API.baseURL[API.currentEnv]}${company_logo}`
        }
        onProPicDelete={handleDelete}
      />

      <UITextInputWithTitle
        title={'Company name'}
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        name="company_name"
        value={values.company_name}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.company_name}
        error={errors.company_name}
      />
      <UITextInputWithTitle
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        title={'Registration number'}
        name="registration_number"
        value={values.registration_number}
        disabled
      />

      <UIAutocompleted
        title={'Timezone'}
        name="time_zone_id"
        placeholder={'select your timezone'}
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        options={timezones && timezones.map((item) => item.timezone)}
        addShowButton={false}
        selectedValue={selectedTimeZone}
        isLoading={isLoading}
        style={styles.selectTitle(defaultValues.isResponsive)}
        onTyping={(val) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            if (val.length > 2) {
              setSearchTimezone(val);
            } else setSearchTimezone('');
          }, 500);
        }}
        onScrollEnd={() => getTimezones()}
        preSelectedValue={time_zone_id && time_zone_id}
        touched={touched.time_zone_id}
        onBlur={handleBlur}
        error={errors.time_zone_id}
      />
      <UITextInputWithTitle
        title={'Locality'}
        placeholder="enter your address"
        name="company_locality"
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        value={values.company_locality}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.company_locality}
        error={errors.company_locality}
      />

      <Box sx={styles.fieldStyle}>
        <Box sx={styles.selectOptionContainer}>
          <UITextInputWithTitle
            title={'City'}
            placeholder="enter your city"
            style={styles.inpuTextStyle(defaultValues.isResponsive)}
            name="company_city"
            variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
            value={values.company_city}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.company_city}
            error={errors.company_city}
          />
        </Box>
        <Box sx={styles.selectOptionContainer}>
          <UITextInputWithTitle
            title={'Postal code'}
            placeholder="enter your postal code"
            style={styles.inpuTextStyle(defaultValues.isResponsive)}
            name="company_post_code"
            variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
            value={values.company_post_code}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.company_post_code}
            error={errors.company_post_code}
          />
        </Box>
      </Box>
      <Box sx={styles.fieldStyle}>
        <Box sx={styles.selectOptionContainer}>
          <UIAutocompleted
            title={'Country'}
            name="company_country"
            placeholder={'select your country'}
            variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
            options={country && country.map((item) => item.country_name)}
            addShowButton={false}
            selectedValue={selectedCountry}
            isLoading={isLoading}
            style={styles.autoStyle(defaultValues.isResponsive)}
            onTyping={(val) => {
              clearTimeout(timer);
              timer = setTimeout(() => {
                if (val.length > 2) {
                  setSearchCountry(val);
                } else setSearchCountry('');
              }, 500);
            }}
            onScrollEnd={() => getCountry()}
            preSelectedValue={company_country && company_country}
            error={errors.company_country}
            touched={touched.company_country}
            onBlur={handleBlur}
          />
        </Box>
        <Box sx={styles.selectOptionContainer}>
          <UIAutocompleted
            options={state && state.map((item) => item.name)}
            title={'State'}
            placeholder={'select your state'}
            variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
            addShowButton={false}
            style={styles.autoStyle(defaultValues.isResponsive)}
            name="company_state"
            onBlur={handleBlur}
            touched={touched.company_state}
            error={errors.company_state}
            selectedValue={selectedState}
            onTyping={handleChange}
            preSelectedValue={stateValue}
            disabled={!values.company_country}
          />
        </Box>
      </Box>
      <UITextInputWithTitle
        title={'Additional details'}
        placeholder={'enter details'}
        lines={3}
        name="company_description"
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        value={values.company_description}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.company_description}
        error={errors.company_description}
      />
      <UIButton
        title={'Save changes'}
        style={styles.btn}
        onClick={handleSave}
        isLoading={isRequestLoading}
        disabled={!dirty}
      />
    </Box>
  );
};
