import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  profileData: {},
  profilePicture: ''
};

const profileSlice = createSlice({
  initialState,
  name: 'profile',
  reducers: {
    profileRequest(state) {
      state.isLoading = true;
    },
    clearProfilePic(state) {
      state.profileData = { ...state.profileData, photo_url: '' };
    },
    profileRequestSuccess(state, action) {
      state.isLoading = false;
      state.profileData = action.payload.data;
    },
    profileRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    editProfileRequest(state) {
      state.isLoading = true;
    },
    editProfileSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.profileData = action.payload.data;
    },
    editProfileFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    profilePicChangeRequest(state) {
      state.isLoading = true;
    },
    profilePicChangeSuccess(state, action) {
      state.isLoading = false;
      state.profileData = { ...state.profileData, photo_url: action.payload.photo_url };
      state.successMessage = action.payload.status_code;
    },
    profilePicChangeFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    profilePicDeleteRequest(state) {
      state.isLoading = true;
    },
    profilePicDeleteSuccess(state, action) {
      state.isLoading = false;
      state.profileData = { ...state.profileData, photo_url: '' };
      state.successMessage = action.payload.status_code;
    },
    profilePicDeleteFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },

    resetProfileMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    }
  }
});

export const {
  profileRequest,
  profileRequestFailure,
  profileRequestSuccess,
  profilePicChangeRequest,
  profilePicChangeSuccess,
  profilePicChangeFailure,
  profilePicDeleteRequest,
  profilePicDeleteSuccess,
  profilePicDeleteFailure,
  editProfileRequest,
  editProfileSuccess,
  editProfileFailure,
  resetProfileMessages,
  clearProfilePic
} = profileSlice.actions;

export default profileSlice.reducer;
