import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

const sm = defaultValues.isMobile;

export const detailsCardStyles = {
  container: {
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    padding: '15px',
    boxSizing: 'border-box',
    borderRadius: '8px',
    cursor: 'pointer',
    minHeight: sm ? '300px' : '250px'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: '15px'
  },
  iconContainer: {
    width: '70px',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '15px'
  },
  icon: {
    width: '100%',
    height: '100%'
  },
  textContainer: {
    ml: 2
  },
  buttonContainer: {
    mt: 2
  },
  heading: {
    fontWeight: 600,
    marginBottom: '10px'
  },
  divider: { marginTop: '10px', marginBottom: '10px' }
};
