import React from 'react';
import { Box, Paper } from '@mui/material';
import { color } from '../../theme/color';
import { Text } from '../text';
import { UIButton } from '../button';
import { TextInput } from '../text-field';
import { defaultValues, regExp } from '../../constants';

export const UITextWithBtn = ({
  name,
  id,
  title,
  placeholder,
  onAdd,
  touched,
  onBlur,
  error,
  errorStyle,
  required,
  style,
  addShowButton = true,
  variant = 'semiBold',
  onChange,
  value,
  allowOnlyAlphabets = false,
  allowOnlyNumbers = false,
  btnTitle = 'Add schedule'
}) => {
  const res = defaultValues.isResponsive;

  const handleAlphabeticChange = (e) => {
    const inputValue = e.target.value;
    if (regExp.alphabetRegExp.test(inputValue)) {
      onChange(e);
    }
  };

  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    if (regExp.onlyNum.test(inputValue)) {
      onChange(e);
    }
  };

  const defaultStyles = {
    mainBox: { width: res ? '100%' : '80%', margin: '25px 0' },
    boxStyle: {
      boxShadow: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '8px',
      paddingRight: '15px',
      backgroundColor: color.palette.textFieldRed,
      border: `1px solid ${color.palette.borderColor}`
    },
    textInput: {
      width: '100%',
      boxShadow: 0,
      borderRadius: '8px',
      '& fieldset': { border: 'none' },
      '& .MuiInputBase-input': {
        fontSize: res ? '12px' : '20px'
      }
    },
    btnStyle: { padding: '5px 20px', fontSize: res ? '12px' : '20px', marginLeft: '10px' }, // Add margin between input and button
    mB: { marginBottom: '10px' },
    errorStyles: {
      marginTop: '8px',
      ...errorStyle
    },
    asterisk: {
      color: color.palette.red
    }
  };

  const styles = {
    ...defaultStyles,
    ...style
  };

  return (
    <Box sx={styles.mainBox}>
      {title && (
        <Text variant={variant} style={defaultStyles.mB}>
          {title}
          {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}
      <Paper
        component="form"
        sx={{
          ...styles.boxStyle,
          border: error && touched && `1px solid ${color.errorText}`
        }}>
        <Box sx={{ flex: 1 }}>
          <TextInput
            id={id}
            name={name}
            onBlur={onBlur}
            value={value}
            required={required}
            placeholder={placeholder}
            style={styles.textInput}
            onChange={
              allowOnlyAlphabets
                ? handleAlphabeticChange
                : allowOnlyNumbers
                  ? handleNumberChange
                  : onChange
            }
          />
        </Box>
        {addShowButton && <UIButton title={btnTitle} style={styles.btnStyle} onClick={onAdd} />}
      </Paper>
      {error && touched && (
        <Text style={styles.errorStyles} variant="fieldError">
          {error}
        </Text>
      )}
    </Box>
  );
};
