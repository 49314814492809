import { Box, Divider, IconButton, ListSubheader, Menu, MenuItem } from '@mui/material';
import { Text } from '../text';
import { Styles } from './style';
import filter from '../../assets/icons/filter.png';
import { defaultValues } from '../../constants';
import { useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import { UICheckBox } from '../checkbox';
import { UIButton } from '../button';

export const Filter = ({
  label,
  selectedOption,
  options = defaultValues.mainFilterOption,
  applyFilter,
  activeData,
  onRefresh,
  isMultiSelect = false,
  scrollingOption
}) => {
  const containerRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeOp, setActiveOp] = useState(null);
  const [filterIndex, setFilterIndex] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (onRefresh && Object.keys(onRefresh).length === 0) {
      setFilterData([]);
    }
  }, [onRefresh]);

  useEffect(() => {
    setFilterData(JSON.parse(activeData || '[]'));
  }, [activeData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (e) => {
    let value = e.currentTarget.dataset.value;

    if (isMultiSelect) {
      value = parseInt(value, 10);
      setFilterData((old) => {
        const updatedData = [...old];
        const indexToUpdate = updatedData.findIndex((item) =>
          Object.prototype.hasOwnProperty.call(item, options[filterIndex].value)
        );
        if (indexToUpdate !== -1) {
          const selectedValues = updatedData[indexToUpdate][options[filterIndex].value];
          const valueIndex = selectedValues.indexOf(value);
          if (valueIndex !== -1) {
            // Value already selected, remove it
            selectedValues.splice(valueIndex, 1);
            if (selectedValues.length === 0) {
              // If there are no more values, remove the object from updatedData
              updatedData.splice(indexToUpdate, 1);
            }
          } else {
            // Value not selected, add it
            selectedValues.push(value);
          }
        } else {
          // Add new object with the selected value
          updatedData.push({ [options[filterIndex].value]: [value] });
        }
        return updatedData;
      });
    } else {
      setFilterData((old) => {
        let existingIndex = old.findIndex((item) => options[filterIndex].value in item);
        if (existingIndex !== -1) {
          let updatedData = [...old];
          updatedData[existingIndex][options[filterIndex].value] = value;
          return updatedData;
        }
        return [...old, { [options[filterIndex].value]: value }];
      });
      setActiveOp(value);
    }
  };

  const handleApplyClick = () => {
    selectedOption && selectedOption(activeOp);
    setAnchorEl(null);
    applyFilter && applyFilter(filterData);
    if (isMultiSelect) {
      setFilterData([]);
    } else {
      setFilterData((old) =>
        old.map((obj) => {
          for (const key in obj) {
            if (obj[key] === null) obj[key] = 'null';
          }
          return obj;
        })
      );
    }
  };

  const getIsChecked = (item) => {
    if (!isMultiSelect) {
      return (
        filterData &&
        filterData.some(
          (data) =>
            Object.prototype.hasOwnProperty.call(data, options[filterIndex].value) &&
            data[options[filterIndex].value] === item.value
        )
      );
    } else {
      return (
        filterData &&
        filterData.some(
          (data) =>
            data[options[filterIndex].value] &&
            data[options[filterIndex].value].includes(item.value)
        )
      );
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (parseInt(container.scrollTop + container.clientHeight) >= container.scrollHeight - 5) {
      scrollingOption && scrollingOption(options[filterIndex].value);
    }
  };

  return (
    <Box>
      <Box style={Styles.toolsIconContainer} onClick={handleClick}>
        <img style={Styles.toolsIcons} src={filter} />
        <Text variant={defaultValues.isXSmall ? 'cardResSubTitle' : 'medium'}>{label}</Text>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={Styles.menu(anchorEl)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <ListSubheader
          disableGutters
          sx={{ padding: '5px', paddingBottom: '0px', minWidth: '300px' }}>
          <Box sx={Styles.menuHeader}>
            <Text style={Styles.projectFilter} variant="smallBold">
              Filter ( {options.length} )
            </Text>
            <IconButton onClick={() => setAnchorEl(null)}>
              <Close sx={Styles.closeBtn} />
            </IconButton>
          </Box>
        </ListSubheader>
        <Divider sx={Styles.divider} />
        <Box sx={Styles.contentContainer}>
          <Box sx={Styles.subContainer}>
            {options.length > 0 &&
              options.map((item, i) => (
                <Box sx={Styles.typesText} key={i} onClick={() => setFilterIndex(i)}>
                  <Text variant={i === filterIndex ? 'smallBold' : 'medium'}>
                    {item.filterType}
                  </Text>
                </Box>
              ))}
          </Box>
          <Box sx={Styles.menuAndBtnContainer}>
            <div ref={containerRef} onScroll={handleScroll} style={Styles.menuContainer}>
              {options.length > 0 &&
                options[filterIndex].option.map((item, index) => (
                  <MenuItem
                    key={index}
                    sx={Styles.menuItem}
                    data-value={item.value}
                    onClick={handleChange}>
                    <Box sx={Styles.itemContainer}>
                      <UICheckBox
                        sx={Styles.checkBox}
                        checkedIconStyle={Styles.checkboxIcon}
                        checked={getIsChecked(item)}
                        iconStyle={Styles.icon}
                        type="square"
                      />

                      <div style={Styles.textBreak}>{item.name}</div>
                    </Box>
                  </MenuItem>
                ))}
            </div>
            <Box sx={Styles.applyBtnContainer}>
              <UIButton title="Apply" style={Styles.applyButton} onClick={handleApplyClick} />
            </Box>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};
