import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

const responsive = defaultValues.isResponsive;
export const supplierOtpStyles = {
  formContainer: {
    width: '100%',
    paddingLeft: responsive ? '0px' : '20px',
    boxSizing: 'border-box'
  },
  heading: {
    fontWeight: 550,
    fontSize: responsive ? '18px' : '40px',
    marginTop: responsive ? '20px' : '0px'
  },
  desc: {
    fontSize: responsive ? '12px' : '22px',
    marginTop: responsive ? '0px' : '20px',
    marginBottom: responsive ? '0px' : '10px'
  },
  buttonStyle: {
    display: 'block',
    marginTop: '30px',
    color: color.palette.primaryWhite,
    fontSize: '24px',
    padding: '2px 50px'
  },
  otpInputStyles: {
    '& .MuiOtpInput-TextField': {
      borderRadius: '8px',
      width: responsive ? '40px' : '58px',
      height: responsive ? '40px' : '58px',
      fontSize: '12px',
      color: 'black',
      fontWeight: 400,
      border: 'none',
      backgroundColor: color.palette.textFieldRed
    }
  },
  otpResendContainer: {
    width: '100%'
  },
  otpTimer: {
    color: color.palette.primaryBlack
  },
  resendOtp: {
    color: color.palette.primaryBlack,
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontSize: responsive && '11px',
    marginTop: '10px'
  },
  undrawImage: {
    maxWidth: '100%',
    maxHeight: '350px',
    marginTop: 50
  },
  otpContainer: {
    alignItems: 'left'
  }
};
