import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme/color';
import { fonts } from '../../../../theme/fonts';
const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const ManageListServiceStyles = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.palette.primaryBackground,
    padding: '2%',
    boxSizing: 'border-box'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: responsive ? '0%' : '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px'
  },
  headingCell: {
    fontSize: '22px',
    fontFamily: fonts.primary,
    backgroundColor: color.palette.buttonLight
  },
  actionButton: (status) => ({
    padding: '4px 10px',
    boxSizing: 'border-box',
    backgroundColor: status === 'active' ? color.palette.primaryRed : color.palette.buttonGreen,
    fontSize: '16px',
    fontFamily: fonts.header,
    '&:hover': {
      backgroundColor: status === 'active' ? color.palette.primaryRed : color.palette.buttonGreen
    }
  }),
  profileContainer: {
    width: '100%',
    display: 'flex'
  },
  profileImage: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: color.palette.primaryRed,
    marginRight: '10px'
  },
  email: {
    color: color.palette.emailGray,
    fontSize: '16px'
  },
  name: {
    color: color.palette.primaryBlack
  },

  primaryFont: {
    fontSize: '24px',
    fontFamily: fonts.primary
  },
  subHeading: {
    fontFamily: fonts.primary,
    fontSize: '16px',
    marginLeft: '10px',
    color: color.palette.gray
  },
  services: {
    color: color.palette.gray
  },
  editIcon: {
    maxWidth: '100%',
    maxHeight: '35px'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  link: {
    color: color.palette.skyBlue
  },
  searchBar: {
    width: '100%',
    height: '40px',
    paddingLeft: '10px'
  },
  searchIcon: {
    maxWidth: '100%',
    maxHeight: '28px'
  },
  searchTabContainer: {
    width: '100%',
    height: responsive ? 'auto' : '60px',
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    alignItems: 'center',
    padding: '5px 0px',
    boxSizing: 'border-box',
    marginBottom: '15px'
  },
  searchToolsContainer: {
    width: responsive ? '100%' : '80%',
    height: '100%',
    display: 'flex',
    justifyContent: responsive ? 'space-between' : 'none',
    alignItems: responsive ? 'center' : 'none',
    paddingBottom: responsive ? '8px' : '0px',
    paddingTop: responsive ? '6px' : '0px',
    borderBottom: responsive ? `1px solid ${color.primaryBorder}` : 'none'
  },
  searchBarContainer: {
    display: 'flex',
    height: '40px',
    border: `1px solid ${color.primaryBorder}`,
    width: '40%',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '4px',
    boxSizing: 'border-box'
  },

  newServiceButton: {
    padding: responsive ? '6px 11px' : '7px 30px',
    backgroundColor: color.palette.primaryRed,
    textTransform: 'none',
    marginRight: '10px',
    marginLeft: '20px',
    '&:hover': {
      backgroundColor: color.palette.primaryRed
    },
    fontSize: responsive ? '12px' : md ? '16px' : '20px'
  },
  toolsContainer: {
    width: responsive ? '100%' : 'fit-content',
    display: 'flex',
    justifyContent: responsive ? 'center' : 'end',
    marginTop: responsive ? '10px' : '0px',
    alignItems: 'center'
  },
  toolsIcons: {
    maxWidth: '100%',
    height: '24px'
  },
  toolsIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px',
    boxSizing: 'border-box',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    marginLeft: '10px'
  },
  tableContainer: {
    minWidth: 650,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '5px',
    marginTop: '10px'
  },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 }
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    marginTop: '10px',
    padding: responsive ? '2%' : '0%',
    boxSizing: 'border-box',
    marginBottom: responsive ? '50px' : '20px'
  },
  marginLeft: { marginLeft: 'auto' },
  showingPagesText: {
    color: color.palette.gray
  },
  icon: {
    fontSize: responsive ? '20px' : '36px',
    color: color.palette.primaryBlack
  },
  notFoundBox: {
    margin: '10px auto',
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    background: color.palette.disableColor
  },
  notFoundText: { width: '100%', textAlign: 'center' },
  headingText: {
    marginLeft: '10px',
    fontWeight: 600
  },
  collapseContainer: {
    width: '100%',
    padding: '2%',
    boxSizing: 'border-box'
  },
  heading: {
    marginTop: '20px',
    marginBottom: '10px',
    fontWeight: 600
  }
};
