import { Avatar, Box } from '@mui/material';
import { adminProfileStyles } from './styles';
import { Text } from '../../ui-kit';

import { getPhotoUrl } from '../../utils';

export const AdminProfile = ({ profile, onTitleClick, titleStyle }) => {
  return (
    <Box sx={adminProfileStyles.profileContainer}>
      {profile ? (
        <>
          <Box sx={adminProfileStyles.profilePicContainer}>
            <Avatar
              alt={
                profile.full_name
                  ? profile.full_name.toUpperCase()
                  : profile.first_name.toUpperCase() || 'DP'
              }
              sx={adminProfileStyles.profilePic}
              src={profile.photo_url ? getPhotoUrl(profile.photo_url) : ''}
            />
          </Box>
          <Box sx={adminProfileStyles.textContainer}>
            <Text
              variant="desc"
              style={{ ...adminProfileStyles.name, ...titleStyle }}
              onClick={onTitleClick}>
              {profile.full_name ? profile.full_name : `${profile.first_name} ${profile.last_name}`}
            </Text>
            <Text style={adminProfileStyles.email}>{profile.email}</Text>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
