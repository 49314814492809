import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const responsive = defaultValues.isResponsive;
export const styles = {
  container: { width: '100%', height: '100%', backgroundColor: color.secondaryBackground },
  subContainer: {
    width: '94%',
    backgroundColor: color.primaryBackground,
    m: responsive ? 1 : 3,
    p: !responsive && 2,
    borderRadius: 1,
    pb: '50px'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: responsive ? 'space-between' : 'flex-end',
    alignItems: 'center',
    padding: '17px 17px 0px 0px',
    boxSizing: 'border-box'
  },
  paginationContainer: {
    display: 'flex',
    width: responsive ? '96%' : '100%',
    justifyContent: 'right',
    marginTop: '10px',
    padding: responsive ? '0% 2% 2% 2%' : '0%',
    marginBottom: '20px'
  },
  filCon: { display: 'flex' },
  txt: { ml: 2 },
  icon: {
    fontSize: responsive ? '20px' : '30px',
    color: 'black'
  },
  apply: {
    backgroundColor: color.palette.tertiaryLightBlue
  },
  modalCancel: {
    color: color.palette.primaryRed
  }
};
