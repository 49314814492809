import { Box, Divider } from '@mui/material';
import { Text } from '../../../ui-kit';
import { defaultValues } from '../../../constants';
import { detailsCardStyles } from './detailCardStyles';

export const ProjectDetailsCard = ({ icon, heading, description, onClick }) => {
  return (
    <Box sx={detailsCardStyles.container} onClick={onClick}>
      <Text
        style={detailsCardStyles.heading}
        variant={defaultValues.isResponsive ? 'smallBold' : 'medium'}>
        {heading}
      </Text>
      <Divider sx={detailsCardStyles.divider} />
      <Box sx={detailsCardStyles.iconContainer}>
        <img style={detailsCardStyles.icon} src={icon} />
      </Box>

      <Text variant={defaultValues.isResponsive ? 'responsiveDesc' : 'responsiveText'}>
        {description}
      </Text>
    </Box>
  );
};
