import { color } from '../../../theme/color';

export const Style = {
  title: (isRes) => ({ fontWeight: '500', color: isRes && color.palette.primaryRed }),
  formContainer: (isRes) => ({
    minWidth: 120,
    width: isRes ? '100%' : '95%'
  }),
  selectArea: {
    backgroundColor: color.palette.textFieldRed
  },
  label: {
    marginTop: '20px',
    marginBottom: '10px'
  },
  emailField: (isRes) => ({
    width: isRes ? '90%' : '95%',
    backgroundColor: color.palette.textFieldRed,
    borderRadius: '4px'
  }),
  buttonStyle: (isRes) => ({
    backgroundColor: color.palette.primaryRed,
    marginTop: '30px',
    color: color.palette.primaryWhite,
    fontSize: isRes ? '14px' : '24px',
    width: isRes && '100%',
    height: isRes && '40px',
    padding: '2px 70px'
  }),
  container: { width: '100%', height: '100%' },
  subContainer: { minHeight: '65%' }
};
