import { color, customStyles } from '../../../theme';

export const MTStyles = {
  tableContainer: {
    minWidth: 650,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '5px',
    marginTop: '20px'
  },
  headingCell: {
    fontSize: '18px',
    backgroundColor: color.palette.buttonLight
  },
  tableHead: {
    backgroundColor: color.palette.secondaryWhite
  },
  maxW: { maxWidth: '13vw' },
  profile: { height: '55px', width: '55px', marginRight: '10px' },
  dos: { color: color.palette.ligherBlack },
  actionMax: { maxWidth: '100px' },
  actionBox: {
    ...customStyles.flexBox,
    justifyContent: 'space-between'
  },
  resActionBox: {
    ...customStyles.flexBox,
    justifyContent: 'space-between',
    width: '50%'
  },
  chatBtn: {
    minWidth: '85px',
    backgroundColor: color.palette.tertiaryBlue
  },
  iBtn: {
    ...customStyles.flexBox,
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: `2px solid ${color.palette.yellowIcon}`,
    cursor: 'pointer'
  },
  iText: { color: color.palette.yellowIcon, marginX: 'auto' },
  resContainer: {
    marginTop: '15px',
    marginBottom: '10px',
    border: `1px solid ${color.primaryBorder}`,
    borderBottom: 'none',
    borderRadius: '5px'
  },
  expandIcon: {
    height: '24px',
    width: '24px',
    backgroundColor: color.palette.primaryRed,
    color: color.palette.primaryWhite,
    borderRadius: '50%'
  },
  summeryBox: {
    background: color.palette.secondaryWhite
  },
  iconStyle: {
    height: '40px',
    width: '40px',
    marginRight: '15px',
    borderRadius: '50%'
  },
  maxWidth: { maxWidth: '60vw' },
  itemContainer: {
    display: 'flex',
    marginTop: '10px'
  },
  actionButtonContainer: {
    width: '40%'
  }
};
