import { defaultValues } from '../../../constants';
import { palette } from '../../../theme/palette';

const responsive = defaultValues.isResponsive;
export const SupplierRegistrationStyles = {
  container: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: responsive ? 'column' : 'row',
    padding: '3%',
    boxSizing: 'border-box',
    position: 'relative',
    overflowY: 'hidden',
    gap: '2.5%'
  },

  registrationContainer: {
    width: '100%',
    paddingLeft: responsive ? '0px' : '20px',
    boxSizing: 'border-box',
    boxShadow: 'none',
    p: 0
  },
  detailsContainerStyles: {
    mt: 0
  },

  leftArrowContainer: {
    width: '10%',
    height: '10%'
  },
  leftArrow: {
    position: responsive ? 'static' : 'fixed',
    top: 45,
    left: '1%',
    cursor: 'pointer',
    padding: responsive && '10px 10px 10px 0px'
  },
  leftSide: {
    width: responsive ? '100%' : '40%',
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    justifyContent: 'space-between'
  },
  rightSide: {
    width: responsive ? '100%' : '60%',
    height: 'fit-content',
    boxSizing: 'border-box',
    marginTop: responsive ? '20px' : '0px'
  },

  undrawImage: {
    maxWidth: '100%',
    maxHeight: '350px',
    marginTop: 50
  },
  description: {
    fontSize: responsive ? '12px' : '20px',
    marginTop: 4
  },
  heading: {
    fontSize: responsive ? '24px' : '40px',
    color: palette.primaryRed,
    fontWeight: 600
  },
  leftSubContainer: {
    paddingLeft: responsive ? '0px' : '10%',
    boxSizing: 'border-box'
  }
};
