import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const res = defaultValues.isResponsive;

export const PRChatStyles = {
  nofoundContainer: {
    height: '60vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px'
  },
  newBtn: {
    fontSize: res ? '12px' : '20px'
  },
  chatText: {
    fontSize: res ? '12px' : '32px',
    fontWeight: 600
  },
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    padding: '2%',
    boxSizing: 'border-box',
    marginBottom: '20px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground
  },
  navContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: res && '10px'
  },
  marBot: {
    marginBottom: '20px'
  },
  navText: {
    fontSize: res ? '14px' : '28px',
    fontWeight: 600,
    cursor: 'pointer',
    color: color.palette.linkBlue,
    textDecoration: 'underline'
  },
  navIdleText: {
    fontSize: res ? '20px' : '28px',
    fontWeight: 600,
    textAlign: 'center'
  },
  icon: {
    fontSize: res ? '12px' : '20px',
    marginX: res ? '5px' : '10px',
    color: color.palette.linkBlue
  }
};
