import {
  Filter,
  IconsButton,
  PaginationCustom,
  SearchBar,
  Sort,
  Text,
  UIButton,
  ActionButton,
  Screen
} from '../../../ui-kit';
import { manageAdminStyles } from './styles';
import { Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useEffect, useState } from 'react';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  AddUserModal,
  AdminProfile,
  CollapsableList,
  NoData,
  StatusIndicator
} from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdminErrorMessage,
  getAdminSuccessMessage,
  getAdmins
} from '../../../services/redux/selectors';
import {
  addAdminRequest,
  editAdminRequest,
  getAdminRequest,
  resetAdminMessages,
  updateAdminParams
} from '../../../services/redux/slices';
import {
  adminLoadingStatus,
  getAdminIndicator,
  getCurrentPage,
  getadminParam,
  totalAdminPages
} from '../../../services/redux/selectors/adminSelector';
import { getUserRole, resetMessge } from '../../../utils';
import { toast } from 'react-toastify';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { useNavigate } from 'react-router-dom';
import { customStyles } from '../../../theme';

export const ManageAdminPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getAllAdmins = useSelector(getAdmins);
  const pageCount = useSelector(totalAdminPages);
  const currentRenderPage = useSelector(getCurrentPage);
  const successMessage = useSelector(getAdminSuccessMessage);
  const errorMessage = useSelector(getAdminErrorMessage);
  const isLoading = useSelector(adminLoadingStatus);
  const indicator = useSelector(getAdminIndicator);
  const roleId = getUserRole(defaultValues.userRole.admin);
  const adminParams = useSelector(getadminParam);

  const [admins, setAdmins] = useState([]);
  const [openProfile, setOpenProfile] = useState(false);

  useEffect(() => {
    handleAdmins();
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage !== 'E-10045') {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetAdminMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    setAdmins(getAllAdmins);
  }, [getAllAdmins]);

  useEffect(() => {
    if (indicator) {
      handleAdmins();
    }
  }, [indicator]);

  const handleAdmins = (data) => {
    let payload = {
      page: currentRenderPage || 1,
      ...adminParams,
      ...data
    };
    dispatch(updateAdminParams(payload));

    dispatch(getAdminRequest(payload));
  };

  const handleProfileClose = () => {
    setOpenProfile(false);
  };

  const handlePageChange = (e, value) => {
    const payload = {
      page: value
    };
    dispatch(getAdminRequest(payload));
  };

  const handleAdminAdd = (firstname, lastname, email, propic) => {
    let formdata = new FormData();
    formdata.append('first_name', firstname);
    formdata.append('last_name', lastname);
    formdata.append('email', email);
    formdata.append('photo', propic);
    let payload = {};
    formdata.forEach((value, key) => {
      payload[key] = value;
    });
    dispatch(addAdminRequest(payload));
  };

  const handleStatus = (row) => {
    const payload = { id: row.id, active: !row.active };
    dispatch(editAdminRequest(payload));
  };

  const handelInfo = (id) => {
    navigate(`${SCREEN_PATH.VIEW_USER_PROFILE}?role=${roleId}&id=${id}`, {
      state: {
        type: defaultValues.listConstants.admin
      }
    });
  };

  return (
    <Screen showSideBar={true} currentPage="Manage Admin">
      <Box sx={manageAdminStyles.superContainer}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={manageAdminStyles.container}>
          <Box sx={manageAdminStyles.contentContainer}>
            <Box sx={manageAdminStyles.searchTabContainer}>
              <Box sx={manageAdminStyles.searchToolsContainer}>
                {!defaultValues.isResponsive ? (
                  <SearchBar
                    label={'Search for an admin'}
                    searchedVlaue={(search_text) => {
                      if (search_text.length === 0 || search_text.length >= 2) {
                        handleAdmins({ search_text });
                      }
                    }}
                    onRefresh={!adminParams.search_text && {}}
                    defaultValue={adminParams.search_text}
                  />
                ) : (
                  <Text sx={manageAdminStyles.headingText} variant="medium">
                    Manage Admins
                  </Text>
                )}
                <UIButton
                  style={manageAdminStyles.newButton}
                  title="+ new admin"
                  onClick={() => setOpenProfile(true)}
                />
              </Box>

              <Box sx={manageAdminStyles.toolsContainer}>
                <IconsButton
                  title={!defaultValues.isResponsive ? null : 'Refresh'}
                  icon={<RefreshIcon sx={manageAdminStyles.icon} />}
                  onClick={() => {
                    handleAdmins({ sort: null, filter: null, page: 1, search_text: null });
                  }}
                />
                <Sort
                  selectedOption={(sort) => {
                    handleAdmins({ sort });
                  }}
                  activeOption={adminParams.sort}
                />
                <Filter
                  label={'Filter'}
                  selectedOption={(value) => {
                    handleAdmins({ filter: `[{ isActive: ${value} }]` });
                  }}
                  onRefresh={!adminParams.filter && {}}
                />
              </Box>
            </Box>

            {defaultValues.isResponsive && (
              <Box sx={manageAdminStyles.searchContainer}>
                <SearchBar
                  label={'Search for an admin'}
                  searchedVlaue={(search_text) => {
                    if (search_text.length === 0 || search_text.length >= 2) {
                      handleAdmins({ search_text });
                    }
                  }}
                />
              </Box>
            )}
            <Text sx={manageAdminStyles.tableHeading} variant="fileName">
              {defaultValues.manageAdminTitle}
            </Text>
            {!defaultValues.isResponsive ? (
              admins.length > 0 ? (
                <Table sx={manageAdminStyles.tableContainer} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={manageAdminStyles.headingCell} align="left">
                        Admin details
                      </TableCell>
                      <TableCell sx={manageAdminStyles.headingCell} align="left">
                        Status
                      </TableCell>

                      <TableCell sx={manageAdminStyles.headingCell} align="center">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {admins &&
                      admins.map((row, index) => (
                        <TableRow key={index} sx={manageAdminStyles.tableRow}>
                          <TableCell component="th" scope="row">
                            <AdminProfile
                              profile={row}
                              onTitleClick={() => handelInfo(row.id)}
                              titleStyle={{ ...customStyles.link, ...customStyles.sTitle }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <StatusIndicator status={row.active} />
                          </TableCell>

                          <TableCell sx={customStyles.actionCont} align="center">
                            <ActionButton
                              item={row}
                              isEdit={false}
                              onButtonClick={() => handleStatus(row)}
                              isloading={isLoading}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <NoData message={getErrorMessage('E-10045')} />
              )
            ) : (
              <Box sx={manageAdminStyles.collapseContainer}>
                <CollapsableList
                  contentArray={admins}
                  type="admin"
                  isEdit={false}
                  onButtonClick={(id, active, index, item) => handleStatus(item)}
                  loaderSize={defaultValues.isResponsive && 15}
                  onPMtitleClick={(row) => handelInfo(row.id)}
                />
              </Box>
            )}

            {currentRenderPage && pageCount && (
              <Box sx={manageAdminStyles.paginationContainer}>
                <PaginationCustom
                  onChange={handlePageChange}
                  currentPage={currentRenderPage}
                  count={pageCount}
                />
              </Box>
            )}

            <AddUserModal
              open={openProfile}
              onClose={handleProfileClose}
              heading={'Add new admin'}
              onButtonClick={handleAdminAdd}
              isLoading={isLoading}
              successMessage={successMessage}
            />
          </Box>
        </Box>
      </Box>
    </Screen>
  );
};
