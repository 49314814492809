import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

const responsive = defaultValues.isResponsive;
export const uploadProfilePicStyles = {
  profileContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '20px'
  },
  profilePic: {
    width: responsive ? '100px' : '130px',
    height: responsive ? '100px' : '130px',
    borderRadius: '50%',
    lineHeight: responsive ? '100px' : '130px',
    textAlign: 'center'
  },

  profileChangeBtnContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: responsive ? '10px' : '40px'
  },
  changeBtnSubContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  changeBtn: {
    width: responsive ? '110px' : '160px',
    height: responsive ? '34px' : '40px',
    padding: '10px',
    backgroundColor: color.palette.primaryBlack,
    color: color.palette.primaryWhite,
    fontSize: responsive ? '12px' : '16px',
    marginRight: responsive ? '0px' : '30px'
  },
  deleteBtn: {
    width: responsive ? '110px' : '160px',
    height: responsive ? '34px' : '40px',
    padding: '10px',
    backgroundColor: color.palette.primaryWhite,
    color: color.palette.primaryBlack,
    fontSize: responsive ? '12px' : '16px',
    marginTop: '10px',
    border: `1px solid ${color.palette.primaryBlack}`,
    boxShadow: 'none'
  }
};
