import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { color } from '../../theme/color';
import { defaultValues } from '../../constants';

export const UIButton = ({
  variant = 'contained',
  size = 'large',
  title,
  style,
  disabled,
  endIcon,
  onClick,
  sx,
  isLoading = false,
  loaderSize,
  ...rest
}) => {
  let styles = {
    backgroundColor: variant === 'contained' ? color.palette.primaryRed : color.palette.buttonLight,
    textTransform: 'none',
    ...style
  };

  if (variant === 'secondary') {
    styles = {
      ...styles,
      backgroundColor: color.palette.primaryWhite,
      color: color.palette.primaryBlack,
      border: `1px solid ${color.palette.primaryBlack}`,
      boxShadow: 'none',
      fontWeight: 'bold'
    };
  }

  if (disabled) {
    styles.backgroundColor = color.palette.disabledRed;
    styles.color = color.palette.lightGray;
  }

  return (
    <Button
      variant={variant}
      size={size}
      style={{
        minWidth: defaultValues.isResponsive ? '80px' : styles.width || '150px',
        backgroundColor: isLoading ? color.palette.lightGray : styles,
        ...styles
      }}
      {...{ disabled }}
      sx={{
        '& .MuiButton-endIcon': {
          marginLeft: 'auto'
        },
        ...sx
      }}
      onClick={!isLoading ? onClick : () => {}}
      {...rest}
      endIcon={endIcon}>
      {isLoading ? <CircularProgress size={loaderSize || 20} color={'inherit'} /> : title}
    </Button>
  );
};
