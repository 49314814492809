import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
import { customStyles } from '../../../theme/styles';
const res = defaultValues.isResponsive;

export const invitationStyles = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    padding: '2%',
    boxSizing: 'border-box',
    marginBottom: '20px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground
  },
  navContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: res && '10px'
  },
  navText: {
    fontSize: res ? '14px' : '28px',
    fontWeight: 600,
    cursor: 'pointer',
    color: color.palette.linkBlue,
    textDecoration: 'underline'
  },
  navIdleText: {
    fontSize: res ? '20px' : '28px',
    fontWeight: 600
  },
  spanText: {
    fontSize: res ? '11px' : '16px',
    fontWeight: 400
  },
  icon: {
    fontSize: res ? '12px' : '20px',
    marginX: res ? '5px' : '10px',
    color: color.palette.linkBlue
  },
  subContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '2%',
    marginTop: '2%'
  },
  supplierContainer: {
    width: res ? '100%' : '60%',
    marginTop: res ? '20px' : '0px',
    ...customStyles.commonContainer
  },
  projectsContainer: {
    width: res ? '100%' : '40%',
    marginTop: res ? '20px' : '0px',
    ...customStyles.commonContainer
  },
  briefContainer: {
    width: '100%',
    ...customStyles.commonContainer,
    marginTop: '20px'
  },
  checklistContainer: {
    width: '100%',
    ...customStyles.commonContainer,
    marginTop: '20px',
    marginBottom: '20px'
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px'
  },
  checkSubBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  addCheckBtn: {
    fontSize: res ? '12px' : '20px',
    width: !res ? '200px' : '130px',
    boxSizing: 'border-box'
  },
  divider: { marginTop: '10px', marginBottom: '10px', border: `2px solid  ${color.primaryBorder}` },
  chipContainer: {
    width: '100%'
  },
  chipButton: {
    fontSize: res ? '14px' : '20px',
    backgroundColor: color.palette.primaryWhite,
    color: color.palette.primaryBlack,
    padding: res ? '5px 8px' : '5px 20px',
    border: `1px solid ${color.primaryBorder}`,
    width: '100%',
    justifyContent: 'space-between',
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal'
    }
  },
  deleteIcon: {
    color: color.palette.primaryBlack,
    paddingLeft: res ? '20px' : '10px'
  },
  desc: {
    color: color.palette.emailGray,
    marginTop: '8px'
  },
  checkTitle: {
    fontSize: res ? '14px' : '16px',
    fontWeight: 600
  },
  sendBtn: {
    fontSize: res ? '12px' : '20px'
  },
  mb: {
    marginBottom: res ? '10px' : '20px'
  },
  primeTitle: {
    fontSize: res ? '20px' : '24px',
    fontWeight: 600
  },
  textClr: { color: color.palette.gray }
};
