import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const isResponsive = defaultValues.isResponsive;

export const Styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    minHeight: !isResponsive && '80vh',
    padding: 1,
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground
  },
  cardContainer: {
    width: isResponsive ? '300px' : '530px',
    display: 'flex',
    flexDirection: isResponsive ? 'column' : 'row',
    borderRadius: 1,
    boxShadow: 1,
    p: 1,
    margin: 1,
    height: isResponsive ? '120px' : '170px',
    backgroundColor: color.primaryBackground,
    alignItems: 'center'
  },
  avatarStyle: {
    width: isResponsive ? 74 : 130,
    height: isResponsive ? 74 : 130,
    mx: isResponsive ? 1 : 2
  },
  detailsContainer: {
    width: isResponsive ? '100%' : '75%',
    display: isResponsive && 'flex'
  },
  buttonContainer: {
    mt: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: isResponsive ? '95%' : '70%'
  },
  rejectBtn: {
    width: isResponsive ? '48%' : '123px',
    fontSize: isResponsive ? '12px' : '16px',
    backgroundColor: 'white',
    color: 'red'
  },
  approveBtn: {
    width: isResponsive ? '48%' : '123px',
    fontSize: isResponsive ? '12px' : '16px',
    backgroundColor: 'green',
    color: 'white'
  }
};
