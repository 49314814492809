import { Box, Divider, Grid } from '@mui/material';
import { Screen, Text, UIBackButton } from '../../../ui-kit';
import { SCREEN_PATH, defaultValues, getErrorMessage } from '../../../constants';
import { ProjectDetailsCard } from '../../common/card/detailsCard';
import { projectDetailsStyles } from './styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjecsErrorMessage,
  getProjectDetailsSel,
  getProjectsLoadingStatus,
  getUserId,
  getUserType
} from '../../../services/redux/selectors';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { StepperElem } from '../../../ui-kit/stepper';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProjectDetailsRequest, resetProjectsMessages } from '../../../services/redux/slices';
import { toast } from 'react-toastify';
import { resetMessge, truncateText } from '../../../utils';

export const ProjectDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userType = useSelector(getUserType);
  const isLoading = useSelector(getProjectsLoadingStatus);
  const projectDetSel = useSelector(getProjectDetailsSel);
  const errorMessage = useSelector(getProjecsErrorMessage);
  const userId = useSelector(getUserId);

  const [proDetails, setProDetails] = useState(defaultValues.projectDetails);
  const [projectDetails, setProjectDetails] = useState({});

  const admin = defaultValues.userRole.admin;
  const superAdmin = defaultValues.userRole.superAdmin;

  useEffect(() => {
    if (location.state && location.state.data) {
      getProjectDetails();
    }
  }, [location.state]);

  useEffect(() => {
    if (errorMessage) {
      setProjectDetails({});
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [errorMessage]);

  useEffect(() => {
    let list = [];
    if ([admin, superAdmin].includes(userType)) {
      list = defaultValues.projectDetails.filter((elem) => {
        return elem.id !== 7;
      });
    }

    if ([defaultValues.userRole.supplier, defaultValues.userRole.customer].includes(userType)) {
      list = defaultValues.projectDetails.filter((elem) => {
        if (
          projectDetails &&
          projectDetails.project_status &&
          projectDetails.project_status.id === 7 &&
          userType === defaultValues.userRole.supplier
        ) {
          return elem.id !== 2;
        } else if (
          userType === defaultValues.userRole.customer &&
          projectDetails &&
          projectDetails.project_status &&
          projectDetails.project_status.id === 7 &&
          projectDetails.customer_user_id === userId
        ) {
          return elem.id !== 2;
        } else {
          return elem.id !== 2 && elem.id !== 7;
        }
      });
    }

    if (userType === defaultValues.userRole.projectManger) {
      list = defaultValues.projectDetails.filter((elem) => {
        return elem.id !== 2 && elem.id !== 7;
      });
    }

    if (list.length > 0) {
      setProDetails(list);
    }
  }, [userType, projectDetails]);

  const onClickMenu = (val) => {
    navigate(`${val.route}`, { state: { data: projectDetails } });
  };

  const getProjectDetails = () => {
    let payload = {
      project_id: location.state.data.id || location.state.data.project_id
    };
    dispatch(getProjectDetailsRequest(payload));
  };

  useEffect(() => {
    if (projectDetSel) {
      setProjectDetails(projectDetSel);
    }
  }, [projectDetSel]);

  const handlefeedbackClick = () => {
    navigate(SCREEN_PATH.FEEDBACK, { state: { data: projectDetails } });
  };
  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive}
      currentPage="Project details">
      <Box sx={projectDetailsStyles.container}>
        <LoadingScreen isLoading={isLoading} />
        <UIBackButton
          style={projectDetailsStyles.topNav}
          onClick={() => navigate(SCREEN_PATH.MANAGE_PROJECTS)}
        />
        <Text style={projectDetailsStyles.title_1} variant={'menuText'}>
          {truncateText(projectDetails.project_name, defaultValues.isMobile ? 20 : 100)}
        </Text>

        {[defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType) &&
          projectDetails &&
          projectDetails.project_status &&
          projectDetails.project_status.id === 7 && (
            <Text
              style={projectDetailsStyles.title_3}
              variant={'small'}
              onClick={handlefeedbackClick}>
              feedback
            </Text>
          )}

        <Text style={projectDetailsStyles.title_2} variant={'small'}>
          Project progress
        </Text>
        <br />
        <Box sx={projectDetailsStyles.subContainer}>
          <Box sx={projectDetailsStyles.border}>
            <Box sx={projectDetailsStyles.stepContainer}>
              <StepperElem
                status={projectDetails.project_status && projectDetails.project_status.id}
                options={
                  userType === defaultValues.userRole.supplier &&
                  projectDetails.project_status &&
                  projectDetails.project_status.id === 10
                    ? defaultValues.cancelledSupProjectFilterOptions
                    : userType === defaultValues.userRole.supplier &&
                        projectDetails.project_status &&
                        projectDetails.project_status.id !== 10
                      ? defaultValues.projectFilterOptionsForSupplier
                      : projectDetails.project_status && projectDetails.project_status.id === 10
                        ? defaultValues.cancelledProjectFilterOptions
                        : defaultValues.projectFilterOptions
                }
                userRole={userType}
                title="key"
              />
            </Box>
            {(userType === admin || userType === superAdmin) && (
              <>
                <Divider />
                <Box sx={projectDetailsStyles.viewTextContainer}>
                  <Text
                    style={projectDetailsStyles.viewStyle}
                    onClick={() => navigate(SCREEN_PATH.PROJECT_LOGS)}>
                    View log of status change
                  </Text>
                </Box>
              </>
            )}
          </Box>

          <Grid container sx={projectDetailsStyles.gridStyle} spacing={2}>
            {proDetails &&
              proDetails.map((item, index) => (
                <Grid key={index} item xs={6} md={4} lg={4} sm={6}>
                  <ProjectDetailsCard
                    heading={item.title}
                    description={item.desc}
                    icon={item.icon}
                    onClick={() => onClickMenu(item)}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </Screen>
  );
};
