import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { SCREEN_PATH, defaultValues, getErrorMessage } from '../../../../constants';
import { IconsButton, SearchBar, Sort, Text } from '../../../../ui-kit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CollapsableList, NoData, ProjectTitle, Title } from '../../../../components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { customStyles } from '../../../../theme/styles';
import { projectsStyles } from './style';
import { ConvertedTime, getFormatDate } from '../../../../utils';
import { LoadingScreen } from '../../../../ui-kit/loading-screen';
import { setSelectedTab } from '../../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType, userInfo } from '../../../../services/redux/selectors';

export const ActiveProjectsPage = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector(userInfo);
  const userRole = useSelector(getUserType);

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortValue, setSortValue] = useState({});
  const [userTimezone, setUserTimezone] = useState(null);

  useEffect(() => {
    if (userData) {
      setUserTimezone(userData.timezone);
    }
  }, [userData]);

  useEffect(() => {
    if (data.length > 0) {
      setProjects(data);
    }
  }, [data]);

  const handleSearch = (searchData) => {
    if (searchData.length > 2) {
      setSortValue({ search_text: searchData });
      setIsLoading(true);
      setTimeout(() => {
        const filteredProjects = projects.filter((project) => {
          return (
            project.project_id.toString().includes(searchData) ||
            project.project_title.toLowerCase().includes(searchData.toLowerCase())
          );
        });
        setProjects(filteredProjects);
        setIsLoading(false);
      }, 1000);
    } else {
      setProjects(data);
    }
  };

  const handleRefresh = () => {
    setIsLoading(true);
    setTimeout(() => {
      setProjects(data);
      setSortValue({});
      setIsLoading(false);
    }, 1000);
  };

  const handleSort = (sortType) => {
    setSortValue(sortType);
    setIsLoading(true);
    setTimeout(() => {
      let sortedProjects = [...projects];

      switch (sortType) {
        case 'asc':
          sortedProjects.sort((a, b) => a.project_title.localeCompare(b.project_title));
          break;
        case 'desc':
          sortedProjects.sort((a, b) => b.project_title.localeCompare(a.project_title));
          break;
        case 'latest':
          sortedProjects.sort((a, b) => new Date(b.event_time) - new Date(a.event_time));
          break;
        case 'oldest':
          sortedProjects.sort((a, b) => new Date(a.event_time) - new Date(b.event_time));
          break;
        default:
          break;
      }

      setProjects(sortedProjects);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <Box sx={projectsStyles.container}>
      <LoadingScreen isLoading={isLoading} />
      {!defaultValues.isResponsive && (
        <Text variant={'subTitle'} style={projectsStyles.mLeft}>
          Active projects
        </Text>
      )}
      <Box sx={projectsStyles.contentContainer}>
        <Box sx={projectsStyles.searchTabContainer}>
          <Box sx={projectsStyles.searchToolsContainer}>
            {!defaultValues.isResponsive ? (
              <SearchBar
                label={'search by project name or id'}
                searchedVlaue={handleSearch}
                onRefresh={!sortValue.search_text && {}}
                defaultValue={sortValue.search_text}
              />
            ) : (
              projects.length > 0 && (
                <Text sx={projectsStyles.headingText} variant="medium">
                  Active Projects
                </Text>
              )
            )}
          </Box>

          <Box sx={projectsStyles.toolsContainer}>
            <IconsButton
              title={!defaultValues.isResponsive ? null : 'Refresh'}
              icon={<RefreshIcon sx={projectsStyles.icon} />}
              onClick={handleRefresh}
            />
            <Sort selectedOption={handleSort} activeOption={sortValue} />
          </Box>
        </Box>

        {defaultValues.isResponsive && projects.length > 0 && (
          <Box sx={projectsStyles.searchContainer}>
            <SearchBar
              label={'search by project name or id'}
              searchedVlaue={handleSearch}
              onRefresh={!sortValue.search_text && {}}
              defaultValue={sortValue.search_text}
            />
          </Box>
        )}

        {!defaultValues.isResponsive ? (
          projects.length > 0 ? (
            <Table sx={projectsStyles.tableContainer} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={projectsStyles.headingCell} align="left">
                    Id
                  </TableCell>
                  <TableCell sx={projectsStyles.headingCell} align="left">
                    Project name
                  </TableCell>
                  <TableCell sx={projectsStyles.headingCell} align="left">
                    Date & time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects &&
                  projects.map((row, index) => (
                    <TableRow key={index} sx={projectsStyles.tableRow}>
                      <TableCell
                        sx={projectsStyles.headingCell350}
                        component="th"
                        scope="row"
                        align="left">
                        <Title
                          sx={{ ...customStyles.link }}
                          title={row.project_id}
                          onClick={() => {
                            dispatch(setSelectedTab(2));
                            navigate(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                              state: { data: row }
                            });
                          }}
                        />
                      </TableCell>

                      <TableCell sx={projectsStyles.title} align="left">
                        <ProjectTitle
                          titleStyle={projectsStyles.proTitle}
                          title={row.project_title}
                          flag={row.flag}
                        />
                      </TableCell>

                      <TableCell align="left">
                        {userRole === defaultValues.userRole.customer ? (
                          <>
                            <Title title={getFormatDate(row.event_time, 'DD-MM-YYYY hh:mm A')} />
                            <Title sx={projectsStyles.timeZoneStyle} title={row.timezone_name} />
                          </>
                        ) : userRole === defaultValues.userRole.supplier ? (
                          <>
                            <ConvertedTime
                              time={row.event_time}
                              originalTimeZoneName={row.timezone_name}
                              targetTimeZoneName={userTimezone}
                            />
                            <Title sx={projectsStyles.timeZoneStyle} title={userTimezone} />
                          </>
                        ) : (
                          <>
                            <ConvertedTime
                              time={row.event_time}
                              originalTimeZoneName={row.timezone_name}
                              targetTimeZoneName={defaultValues.uKTimeZone}
                            />
                            <Title
                              sx={projectsStyles.timeZoneStyle}
                              title={defaultValues.uKTimeZone}
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <NoData message={getErrorMessage('E-10072')} />
          )
        ) : (
          projects.length > 0 && (
            <Box sx={projectsStyles.collapseContainer}>
              <CollapsableList
                userTimezone={userTimezone}
                contentArray={projects}
                type={defaultValues.listConstants.active_project}
                onProjectTitleClick={(row) => {
                  navigate(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                    state: { data: row }
                  });
                }}
                isEdit={false}
                isButton={false}
              />
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};
