import { Autocomplete, Box, TextField } from '@mui/material';
import {
  HiddenInput,
  IconsButton,
  Text,
  TextInput,
  UIBackButton,
  UIButton,
  UIChipButton
} from '../../ui-kit';
import { chatStyles } from './styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ThreadCard } from './thread-card';
import { useEffect, useRef, useState } from 'react';
import attach from '../../assets/images/attach.png';
import send from '../../assets/images/white-send.png';
import { MessageCard } from '../common/card/chatMessage';
import { color } from '../../theme';
import { format, isToday, isYesterday, parseISO } from 'date-fns';
import { defaultValues } from '../../constants';
import { truncateText } from '../../utils';
import { MemberCard } from '../projects/member-card';
import { clearMessages } from '../../services/redux/slices';
import { useDispatch } from 'react-redux';

export const Inbox = ({
  file,
  handleFileChange,
  handleSendMessage,
  message,
  handleMessageChange,
  threads,
  messages,
  handleThreadChange,
  handleFileDelete,
  handleGetMessages,
  currThread,
  onRefresh,
  type,
  proMembers,
  addPeople,
  handleAddPeople,
  addedMembers,
  handleAddmembers,
  handleMemerDelete,
  selThread = null
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const chatContainerRef = useRef();

  const [selected, setSelected] = useState(0);
  const [unreadCount, setUnreadCount] = useState(null);
  const [threadList, setThreadList] = useState(null);
  const [currRender, setCurrRender] = useState(1);

  useEffect(() => {
    if (threads && threads.length > 0) {
      handleUnreadCount();
      setThreadList(threads);
    }
  }, [threads]);

  useEffect(() => {
    if (selThread !== null) {
      setSelected(selThread);
    }
  }, [selThread]);

  const handleUnreadCount = () => {
    const count = threads.filter((item) => item.unread_messages);
    setUnreadCount(count.length > 0 ? count.length : null);
  };

  const handleSelect = (idx, item) => {
    setSelected(idx);
    handleThreadChange(item, idx);
    setCurrRender(2);
  };

  const handleAttachClick = () => {
    inputRef.current.click();
  };

  const handleChatScroll = () => {
    const container = chatContainerRef.current;
    if (
      Math.abs(parseInt(container.scrollTop - container.clientHeight)) >=
      container.scrollHeight - 2
    ) {
      setTimeout(() => {
        handleGetMessages();
      }, 500);
    }
  };

  const getDayTag = (sentAt) => {
    const date = parseISO(sentAt);

    if (isToday(date)) {
      return 'Today';
    } else if (isYesterday(date)) {
      return 'Yesterday';
    } else {
      return format(date, 'EEE d yyyy');
    }
  };

  const groupMessagesByDay = (messages) => {
    const groupedMessages = {};

    messages.forEach((item) => {
      const dayTag = getDayTag(item.sent_at);

      if (!groupedMessages[dayTag]) {
        groupedMessages[dayTag] = [];
      }

      groupedMessages[dayTag].push(item);
    });

    return groupedMessages;
  };

  const groupedMessages = groupMessagesByDay(messages);

  const memberName = () => {
    if (type === defaultValues.listConstants.personel_chat) {
      return (
        (currThread && currThread.user && currThread.user.full_name) ||
        (threadList && threadList.length > 0 && threadList[0].user && threadList[0].user.full_name)
      );
    }
    if (type === defaultValues.listConstants.project_chat) {
      let name =
        (currThread &&
          currThread.users &&
          currThread.users.map((item) => item.full_name).join(', ')) ||
        (threadList &&
          threadList.length > 0 &&
          threadList[0].users &&
          threadList[0].users.map((item) => item.full_name).join(', '));
      return name;
    }
  };

  const getFirstName = (name) => {
    const fn = name.split(' ');
    return fn[0];
  };

  return (
    <Box sx={chatStyles.chatSuperContainer}>
      <Box sx={chatStyles.chatTopNav}>
        {(currRender === 1 && defaultValues.isResponsive) || !defaultValues.isResponsive ? (
          <Box sx={chatStyles.chatHeading}>
            <Box sx={chatStyles.chatTitleBox}>
              <Text sx={chatStyles.chatText}>Chat</Text>
              {unreadCount && (
                <Box sx={chatStyles.unreadContainer}>
                  <Text style={chatStyles.unreadCount}>{unreadCount}</Text>
                </Box>
              )}
            </Box>
            <Box sx={chatStyles.btnsCont}>
              <IconsButton
                icon={<RefreshIcon sx={chatStyles.refreshIcon} />}
                style={chatStyles.refreshStyle}
                onClick={() => onRefresh()}
              />
              {type == defaultValues.listConstants.project_chat && (
                <UIButton
                  style={chatStyles.newBtn}
                  title="+ new"
                  onClick={() => {
                    handleAddPeople(true);
                    setCurrRender(2);
                    dispatch(clearMessages());
                  }}
                />
              )}
            </Box>
          </Box>
        ) : (
          <></>
        )}
        {(currRender === 2 && defaultValues.isResponsive) || !defaultValues.isResponsive ? (
          <Box sx={chatStyles.chatPersonHeadCont}>
            {defaultValues.isResponsive && (
              <UIBackButton
                onClick={() => {
                  if (type === defaultValues.listConstants.project_chat) {
                    handleAddPeople(false);
                  }
                  setCurrRender(1);
                }}
              />
            )}
            {addPeople ? (
              <Box sx={chatStyles.autoCompCont}>
                <Autocomplete
                  name="todoTitle"
                  sx={chatStyles.autoComplete}
                  options={proMembers && proMembers.length > 0 && proMembers.map((item) => item)}
                  getOptionLabel={(option) => option.full_name}
                  renderOption={(params, option) => {
                    return (
                      <MemberCard
                        name={
                          defaultValues.isResponsive
                            ? getFirstName(option.full_name)
                            : option.full_name
                        }
                        profile={option.profile_pic}
                        onClick={() => handleAddmembers(option)}
                        containerStyle={chatStyles.optionsCard}
                      />
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={chatStyles.autoTextF}
                      placeholder="Add people"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />

                <Box sx={chatStyles.memCardCont}>
                  {addedMembers &&
                    addedMembers.length > 0 &&
                    addedMembers.map((item, idx) => (
                      <MemberCard
                        key={idx}
                        name={
                          defaultValues.isResponsive ? getFirstName(item.full_name) : item.full_name
                        }
                        profile={item.profile_pic}
                        showDelete={true}
                        onDelete={() => handleMemerDelete(idx)}
                        containerStyle={chatStyles.memCard}
                      />
                    ))}
                </Box>
              </Box>
            ) : (
              <Box sx={chatStyles.memberNameCont}>
                <Text style={chatStyles.chatPersonHeading}>{memberName()}</Text>
              </Box>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={chatStyles.CSecondCont}>
        {(currRender === 1 && defaultValues.isResponsive) || !defaultValues.isResponsive ? (
          <div style={chatStyles.CsecLeft}>
            {threads &&
              threads.length > 0 &&
              threads.map((item, idx) => (
                <ThreadCard
                  key={idx}
                  item={item}
                  selected={selected === idx}
                  onClick={() => handleSelect(idx, item)}
                  type={type}
                />
              ))}
          </div>
        ) : (
          <></>
        )}

        {(currRender === 2 && defaultValues.isResponsive) || !defaultValues.isResponsive ? (
          <Box sx={chatStyles.CsecRight}>
            <div style={chatStyles.CSecChats} ref={chatContainerRef} onScroll={handleChatScroll}>
              {Object.entries(groupedMessages).map(([dayTag, dayMessages], idx) => {
                const reversedDayMessages = dayMessages.slice().reverse();

                return (
                  <Box key={idx} sx={chatStyles.msgCont}>
                    <Text style={chatStyles.daytag}>{dayTag}</Text>
                    {reversedDayMessages.map((item, messageIdx) => (
                      <MessageCard key={messageIdx} item={item} threads={threadList} />
                    ))}
                  </Box>
                );
              })}
            </div>

            {(type === defaultValues.listConstants.project_chat && threads && threads.length > 0) ||
            (addedMembers && addedMembers.length > 0) ||
            type === defaultValues.listConstants.personel_chat ? (
              <Box sx={chatStyles.inputContainer}>
                <Box sx={chatStyles.inputFsub}>
                  <Box sx={chatStyles.inputSub}>
                    <TextInput
                      value={message}
                      textFieldContainerStyle={chatStyles.textFieldContainerStyle}
                      style={chatStyles.textField}
                      onChange={(e) => handleMessageChange(e.target.value)}
                      placeholder={'type here...'}
                      maxRows={4}
                      multiline={true}
                      lines={false}
                    />
                    {file && (
                      <UIChipButton
                        style={chatStyles.chip}
                        title={truncateText(file.name, defaultValues.isResponsive ? 10 : 25)}
                        onDelete={handleFileDelete}
                        deleteIconStyle={{ color: color.palette.primaryBlack }}
                      />
                    )}
                  </Box>
                  <img src={attach} style={chatStyles.attachIcon} onClick={handleAttachClick} />

                  <HiddenInput inpRef={inputRef} handleChange={(val) => handleFileChange(val)} />
                </Box>
                <Box sx={chatStyles.sendCont(!message && !file)}>
                  <img
                    src={send}
                    style={chatStyles.sendIcon(!message && !file)}
                    onClick={() =>
                      handleSendMessage(
                        currThread && currThread.thread_id,
                        addedMembers && addedMembers.length > 0 && addedMembers
                      )
                    }
                  />
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
