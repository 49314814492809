import Box from '@mui/material/Box';
import { Text } from '../../../ui-kit';

import { dragNdropStyles } from './styles';

import { getErrorMessage } from '../../../constants';
import { FileUploader } from 'react-drag-drop-files';
import uploadImg from '../../../assets/icons/upload.png';
import pdf from '../../../assets/icons/pdf.png';
import { toast } from 'react-toastify';

export const DragDrop = ({ handleChange, pdfFile, fileTypes = ['Pdf'] }) => {
  const handleFileChange = (file) => {
    if (file.size > 10000000) {
      return toast.error(getErrorMessage('E-10074'));
    }
    handleChange(file);
  };

  return (
    <Box sx={dragNdropStyles.fileUploadContainer}>
      <FileUploader handleChange={handleFileChange} name="file" types={fileTypes}>
        <Box sx={dragNdropStyles.uploadContainer}>
          {pdfFile && <Text variant="medium">uploaded file</Text>}
          <Box sx={dragNdropStyles.browseContainer}>
            <img src={pdfFile ? pdf : uploadImg} style={dragNdropStyles.uploadIcon} />
            <Text variant="responsiveDesc" sx={dragNdropStyles.browseText}>
              {pdfFile ? `${pdfFile.name.slice(0, 15)}` : 'Browse file'}
            </Text>
          </Box>
          {!pdfFile && (
            <Text variant="medium" style={dragNdropStyles.dropText}>
              or
            </Text>
          )}

          <Text variant="medium" style={dragNdropStyles.dropText}>
            {pdfFile ? (
              <u style={dragNdropStyles.underLine}>upload new file</u>
            ) : (
              'drag and drop your file here'
            )}
          </Text>
        </Box>
      </FileUploader>
    </Box>
  );
};
