import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import undrawSupplierImage from '../../../assets/images/undraw_supplier.png';
import { Box } from '@mui/material';

import { Text } from '../../../ui-kit/text';
import { SupplierRegistrationStyles } from './styles';
import { useEffect, useState } from 'react';
import { RegistrationForm, SupplierOtpForm } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetServiceMessages,
  supplierRegistrationRequest,
  supplierResendOtpRequest,
  supplierVerificationRequest
} from '../../../services/redux/slices';
import {
  getServiceErrorMessage,
  getServiceSuccessMessage,
  getUserEmail,
  serviceLoadingStatus
} from '../../../services/redux/selectors';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetMessge } from '../../../utils';
import { UIBackButton } from '../../../ui-kit';
import { LoadingScreen } from '../../../ui-kit/loading-screen';

export const SupplierRegistrationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(serviceLoadingStatus);
  const successMessage = useSelector(getServiceSuccessMessage);
  const errorMessage = useSelector(getServiceErrorMessage);
  const userEmail = useSelector(getUserEmail);

  const [rightSection, setRightSection] = useState(1);

  useEffect(() => {
    if (!isLoading && successMessage) {
      setRightSection(2);
    }
  }, [isLoading]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetServiceMessages()));
  }, [successMessage, errorMessage]);

  const handleRegistrationRequest = (values) => {
    let payload = {
      ...values
    };
    if (payload.state === '') {
      payload.state = null;
    }
    delete payload.stateReq;
    delete payload.skype_id;
    delete payload.telephone_number;
    delete payload.whatsapp_number;

    dispatch(supplierRegistrationRequest(payload));
  };

  const handleVerifyOtp = (otp) => {
    const payload = {
      email_id: userEmail,
      otp
    };
    dispatch(supplierVerificationRequest(payload));
  };

  const handleResendOtp = () => {
    const payload = {
      email_id: userEmail
    };
    dispatch(supplierResendOtpRequest(payload));
  };

  const handleBackButton = () => {
    setRightSection((rightSection) => rightSection - 1);
    if (rightSection === 1) {
      navigate(SCREEN_PATH.HOME);
    }
  };

  return (
    <Box sx={SupplierRegistrationStyles.container}>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={SupplierRegistrationStyles.leftSide}>
        <UIBackButton style={SupplierRegistrationStyles.leftArrow} onClick={handleBackButton} />

        <Box sx={SupplierRegistrationStyles.leftSubContainer}>
          {(rightSection === 2 && !defaultValues.isResponsive) || rightSection === 1 ? (
            <>
              <Text style={SupplierRegistrationStyles.heading}>
                {defaultValues.supplierHeading}
              </Text>
              <Text style={SupplierRegistrationStyles.description}>
                {defaultValues.supplierDesc}
              </Text>
            </>
          ) : (
            <></>
          )}
          {!defaultValues.isResponsive && (
            <img style={SupplierRegistrationStyles.undrawImage} src={undrawSupplierImage} />
          )}
        </Box>
      </Box>

      <Box sx={SupplierRegistrationStyles.rightSide}>
        {rightSection === 1 && (
          <RegistrationForm
            isLoading={isLoading}
            languageTitle="Languages"
            currencyTitle="Currency"
            buttonTitle="Submit"
            divider={false}
            containerStyles={SupplierRegistrationStyles.registrationContainer}
            detailsContainerStyles={SupplierRegistrationStyles.detailsContainerStyles}
            onSubmit={handleRegistrationRequest}
            type={defaultValues.listConstants.supplier}
          />
        )}
        {rightSection === 2 && (
          <SupplierOtpForm
            email={userEmail}
            onResendOtp={handleResendOtp}
            isLoading={isLoading}
            onSubmit={handleVerifyOtp}
          />
        )}
      </Box>
    </Box>
  );
};
