import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { Style } from './style';
import { Text, UIButton, UITextInputWithTitle, UIRadioButtonWithTitle } from '../../../ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { resetServiceMessages, submitEnquiryRequest } from '../../../services/redux/slices';
import {
  getEventDetails,
  getQuestion,
  getServiceId,
  getServiceSuccessMessage
} from '../../../services/redux/selectors';
import { resetMessge } from '../../../utils';
import { useNavigate } from 'react-router-dom';

export const CommonQuestion = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const successMessage = useSelector(getServiceSuccessMessage);
  const selectedQuestion = useSelector(getQuestion);
  const serviceId = useSelector(getServiceId);
  const eventDetails = useSelector(getEventDetails);

  const [id, setId] = useState('');
  const [questions, setQuestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [response, setResponse] = useState([]);
  const [eventDetail, setEventDetail] = useState({});

  useEffect(() => {
    if (selectedQuestion.length > 0) {
      const activeQuestions = selectedQuestion.filter((question) => question.active);
      setQuestions(activeQuestions);
    }
    if (eventDetails) {
      setEventDetail(eventDetails);
    }
    if (serviceId) {
      setId(serviceId);
    }
  }, [selectedQuestion, serviceId, eventDetails]);

  useEffect(() => {
    if (successMessage) {
      navigation(SCREEN_PATH.ADMIN_DASHBOARD);
    }

    resetMessge(() => dispatch(resetServiceMessages()));
  }, [successMessage]);

  useEffect(() => {
    const selectedOptionIds = selectedOption.map((option) => option.id);
    const updatedResponse = response.filter((item) => selectedOptionIds.includes(item.optionId));
    const qAnswer = response.filter((item) => item.optionId === null);

    setResponse([...qAnswer, ...updatedResponse]);
  }, [selectedOption]);

  const handleSelectedValue = useCallback((value, id, questionId) => {
    setSelectedOption((prevSelected) => {
      const uniqueOption = prevSelected.filter((item) => item.question_id !== questionId);
      return [...uniqueOption, { id: id, option: value, question_id: questionId }];
    });
  }, []);

  const handleChange = (e, id, optionId) => {
    setResponse((prev) => {
      const index = prev.findIndex((item) => item.question_id === id);
      const updatedItem = {
        question_id: id,
        response: e.target.value,
        optionId: optionId !== undefined ? optionId : null
      };

      if (index !== -1) {
        const newResponses = [...prev];
        newResponses[index] = updatedItem;
        return newResponses;
      } else {
        return [...prev, updatedItem];
      }
    });
  };

  const handleSubmit = () => {
    const optionAnswers = selectedOption.map((item) => ({
      question_id: item.question_id,
      option_id: item.id,
      response: ''
    }));

    const lineAswers = response.map((item) => ({
      question_id: item.question_id,
      option_id: null,
      response: item.response
    }));
    const payload = {
      service_id: id,
      event_title: eventDetail.eventName,
      event_details: eventDetail.eventDetails,
      event_date_time: eventDetail.eventDate,
      event_duration: eventDetail.eventDuration,
      captioning_languages: eventDetail.languages,
      spoken_languages: eventDetail.spokenLanguages,
      schedule_details: eventDetail.schedule_details,
      translation_required: eventDetail.translation_required,
      timezone_id: eventDetail.timezone_id,
      answers: [...optionAnswers, ...lineAswers]
    };

    dispatch(submitEnquiryRequest(payload));
  };

  const renderSubQuestions = (subQuestions) => {
    return subQuestions.map((subQuestion, index) => {
      switch (subQuestion.answer_type) {
        case 1: // single line
          return (
            <UITextInputWithTitle
              key={index}
              title={subQuestion.question}
              placeholder="type here ..."
              onChange={(e) => handleChange(e, subQuestion.id, subQuestion.option_id)}
            />
          );
        case 2: // multi line
          return (
            <UITextInputWithTitle
              key={index}
              lines={3}
              title={subQuestion.question}
              placeholder="type here ..."
              onChange={(e) => handleChange(e, subQuestion.id, subQuestion.option_id)}
            />
          );

        default:
          return null;
      }
    });
  };

  const renderQuestion = (question, index) => {
    const activeOptions = question.options.filter((option) => option.active);
    return (
      <React.Fragment key={index}>
        {question.answer_type === 1 && (
          <UITextInputWithTitle
            key={index}
            title={question.question}
            placeholder="type here ..."
            onChange={(e) => handleChange(e, question.id)}
          />
        )}
        {question.answer_type === 2 && (
          <UITextInputWithTitle
            key={index}
            lines={3}
            title={question.question}
            placeholder="type here ..."
            onChange={(e) => handleChange(e, question.id)}
          />
        )}
        {question.answer_type === 3 && (
          <UIRadioButtonWithTitle
            values={activeOptions}
            title={question.question}
            onCheck={handleSelectedValue}
          />
        )}
        {question.answer_type === 3 &&
          activeOptions &&
          activeOptions.map((option) => {
            const isSelected = selectedOption.some((selected) => selected.id === option.id);
            return (
              <React.Fragment key={option.id}>
                {isSelected &&
                  Array.isArray(option.sub_questions) &&
                  renderSubQuestions(option.sub_questions)}
              </React.Fragment>
            );
          })}
      </React.Fragment>
    );
  };

  const areAllQuestionsAnswered = () => {
    return (
      questions.every((question) => {
        const { id, answer_type } = question;

        switch (answer_type) {
          case 1: // single line
          case 2: // multi line
            return response.some((item) => item.question_id === id);
          case 3: // options
            return selectedOption.some((item) => item.question_id === id);

          default:
            return false;
        }
      }) &&
      questions.every((question) => {
        const { options } = question;

        if (Array.isArray(options)) {
          return options.every((option) => {
            const isSelected = selectedOption.some((selected) => selected.id === option.id);

            if (isSelected && Array.isArray(option.sub_questions)) {
              return option.sub_questions.every((subQuestion) => {
                const subQuestionId = subQuestion.id;
                const isSubQuestionAnswered = response.some(
                  (item) => item.question_id === subQuestionId
                );

                return isSubQuestionAnswered;
              });
            } else {
              return true;
            }
          });
        }

        return true;
      })
    );
  };

  return (
    <Box sx={Style.container}>
      <Text
        style={Style.title(defaultValues.isResponsive)}
        variant={defaultValues.isResponsive ? 'body' : 'secondaryHeader'}>
        Complete Your Booking Details
      </Text>
      <Box sx={Style.subContainer}>
        {questions.length > 0 &&
          questions.map((question, index) => renderQuestion(question, index))}
      </Box>

      <Box>
        <UIButton
          title={'Submit'}
          onClick={handleSubmit}
          sx={Style.buttonStyle(defaultValues.isResponsive)}
          disabled={!areAllQuestionsAnswered()}
        />
      </Box>
    </Box>
  );
};
