import { Box, IconButton } from '@mui/material';
import { ViewQuestionStyle } from './style';
import { Text, UIRadioButton } from '../../ui-kit';
import { defaultValues } from '../../constants';
import { EditIcon } from '../../assets/svg-components/editIcon';
import { UICheckBox } from '../../ui-kit/checkbox';
import { QuestionBox } from '../question-add/components/questionBox';

export const QuestionView = ({
  key,
  data,
  editable = true,
  onEditQuestion,
  editQuestion,
  handleAddQuestion,
  handleRemoveQuestion
}) => {
  return (
    <Box sx={ViewQuestionStyle.mainQuestionBox} key={key}>
      <Box sx={ViewQuestionStyle.flexBox}>
        <div>
          <Text
            variant={
              defaultValues.isResponsive ? 'responsiveText' : 'title'
            }>{`${data.order_number}. ${data.question}`}</Text>
          {[1, 2].includes(data.answer_type) && (
            <Text variant={defaultValues.isResponsive ? 'resDesc' : 'small'}>
              {defaultValues.questionTypes[data.answer_type - 1].title}
            </Text>
          )}
        </div>
        {editable && data.editable && (
          <IconButton onClick={() => onEditQuestion(data)}>
            <EditIcon />
          </IconButton>
        )}
      </Box>
      {editQuestion && editQuestion.order_number === data.order_number && (
        <QuestionBox
          key="mainQuestion"
          name={name}
          addQuestion={handleAddQuestion}
          enableSubQuestion={true}
          editData={editQuestion}
          removeQuestion={handleRemoveQuestion}
          questionMenu={defaultValues.questionTypes}
        />
      )}
      {[3, 4].includes(data.answer_type) &&
        data.options &&
        data.options.map(
          (item, index) =>
            item.active && (
              <>
                <Box key={index} sx={ViewQuestionStyle.optionBox}>
                  {data.answer_type === 3 ? (
                    <UIRadioButton key={item} size="25px" checked={false} />
                  ) : (
                    <UICheckBox key={item} size="25px" checked={false} />
                  )}
                  <Text variant="medium">{item.option}</Text>
                </Box>
                {item.sub_questions && (
                  <Box sx={ViewQuestionStyle.subQuestion}>
                    {item.sub_questions.map(
                      (item, index) =>
                        item.active && (
                          <QuestionView key={index + item.question} data={item} editable={false} />
                        )
                    )}
                  </Box>
                )}
              </>
            )
        )}
    </Box>
  );
};
