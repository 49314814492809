import { defaultValues } from '../../constants';
import { color } from '../../theme';
const isRes = defaultValues.isResponsive;

export const styles = {
  container: {
    height: '100%',
    width: '100%',
    m: 3,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: 1,
    boxSizing: 'border-box',
    p: 1
  },
  title: { fontWeight: 600, ml: 2, p: 1, textAlign: 'left' },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    mt: 2
  },
  cardStyle: {
    width: isRes ? '260px' : '320px',
    height: isRes ? '160px' : '200px',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: 1,
    ml: 1,
    mb: '20px'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mb: '2px'
  },
  stackStyle: { display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '2px' },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mt: 2,
    width: '100%'
  },
  btnStyle: {
    fontSize: isRes ? '10px' : '14px',
    height: isRes ? '23px' : '40px',
    width: isRes ? '110px' : '135px'
  },
  btnnS: {
    fontSize: isRes ? '10px' : '14px',
    height: isRes ? '23px' : '40px',
    width: isRes ? '110px' : '135px',
    backgroundColor: color.primaryBackground,
    color: color.primaryText,
    border: `1px solid ${color.primaryText}`
  },

  imgStyle: { objectFit: 'contain' },
  nextIcon: { width: '5%', cursor: 'pointer' },
  cardsStyle: {
    width: '90%',
    display: 'flex',
    overflowX: 'scroll',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
    boxSizing: 'border-box',
    p: 1
  }
};
