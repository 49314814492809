import { Avatar, Box } from '@mui/material';
import { Text, UIButton } from '../../../ui-kit';
import img from '../../../assets/images/team-member.png';
import { Styles } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { adminLoadingStatus, getIncomingRequest } from '../../../services/redux/selectors';
import { useEffect, useState } from 'react';
import { approveTeamMemberRequest } from '../../../services/redux/slices';
import { defaultValues } from '../../../constants';
import { getFormatDate } from '../../../utils';
import { NoData } from '../../common/no-data-message';

export const IncomingRequest = () => {
  const dispatch = useDispatch();
  const incomingRequests = useSelector(getIncomingRequest);
  const isLoading = useSelector(adminLoadingStatus);

  const [requests, setRequests] = useState([]);
  const [currentIndex, setCurrentIndex] = useState({});

  useEffect(() => {
    if (incomingRequests) {
      setRequests(incomingRequests);
    }
  }, [incomingRequests]);

  useEffect(() => {
    if (!isLoading) {
      setCurrentIndex({});
    }
  }, [isLoading]);

  return (
    <Box sx={Styles.container}>
      {requests.length > 0 ? (
        requests.map((item, index) => (
          <Box sx={Styles.cardContainer} key={index}>
            {!defaultValues.isResponsive && (
              <Avatar alt="memberProfile" src={img} sx={Styles.avatarStyle} />
            )}
            <Box sx={Styles.detailsContainer}>
              {defaultValues.isResponsive && (
                <Avatar alt="memberProfile" src={img} sx={Styles.avatarStyle} />
              )}
              <Box>
                <Text
                  variant={
                    defaultValues.isResponsive ? 'smallBold' : 'fileName'
                  }>{`${item.first_name} ${item.last_name}`}</Text>
                <Text variant={defaultValues.isResponsive ? 'extraSmall' : 'small'}>
                  {item.email}
                </Text>
                <Text variant={defaultValues.isResponsive ? 'responsiveDesc' : 'desc'}>
                  Date of association: {getFormatDate(item.date_of_association, 'DD-MM-YY')}
                </Text>
              </Box>
              {!defaultValues.isResponsive && (
                <Box sx={Styles.buttonContainer}>
                  <UIButton
                    title={'Reject'}
                    size="medium"
                    style={Styles.rejectBtn}
                    isLoading={
                      index === currentIndex.index && 'Reject' === currentIndex.type && isLoading
                    }
                    onClick={() => {
                      setCurrentIndex({ index, type: 'Reject' });
                      dispatch(approveTeamMemberRequest({ id: item.id, active: false }));
                    }}
                  />
                  <UIButton
                    title={'Approve'}
                    size="medium"
                    style={Styles.approveBtn}
                    onClick={() => {
                      setCurrentIndex({ index, type: 'Approve' });
                      dispatch(approveTeamMemberRequest({ id: item.id, active: true }));
                    }}
                    isLoading={
                      index === currentIndex.index && 'Approve' === currentIndex.type && isLoading
                    }
                  />
                </Box>
              )}
            </Box>
            {defaultValues.isResponsive && (
              <Box sx={Styles.buttonContainer}>
                <UIButton
                  title={'Reject'}
                  size="medium"
                  style={Styles.rejectBtn}
                  isLoading={
                    index === currentIndex.index && 'Reject' === currentIndex.type && isLoading
                  }
                  onClick={() => {
                    setCurrentIndex({ index, type: 'Reject' });
                    dispatch(approveTeamMemberRequest({ id: item.id, active: true }));
                  }}
                />
                <UIButton
                  title={'Approve'}
                  size="medium"
                  style={Styles.approveBtn}
                  onClick={() => {
                    setCurrentIndex({ index, type: 'Approve' });
                    dispatch(approveTeamMemberRequest({ id: item.id, active: false }));
                  }}
                  isLoading={
                    index === currentIndex.index && 'Approve' === currentIndex.type && isLoading
                  }
                />
              </Box>
            )}
          </Box>
        ))
      ) : (
        <NoData message={defaultValues.noIncomingRequest} />
      )}
    </Box>
  );
};
