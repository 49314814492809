import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { defaultValues } from '../../constants';
import { color } from '../../theme';
import dayjs from 'dayjs';

export const DatePickers = ({ onChange, value }) => {
  const styles = {
    input: {
      '& .MuiInputBase-input': {
        fontSize: defaultValues.isResponsive ? '8px' : '22px'
      },
      backgroundColor: color.palette.textFieldRed,
      width: '100%',
      borderRadius: 1,
      '& fieldset': { border: 'none' }
    }
  };
  const today = dayjs();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          value={value}
          disablePast
          defaultValue={today}
          onChange={onChange}
          sx={styles.input}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
