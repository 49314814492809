import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import {
  SignInPage,
  LandingPage,
  SupplierRegistrationPage,
  EnquirePage,
  ManageAdminPage,
  AdminDashboardPage,
  OtherSettingsPage,
  ManageCustomersPage,
  ManageSuppliersPage,
  ManageJobsPage,
  ManagePMPage,
  ProfilePage,
  ProjectsPage,
  ManageTeamPage,
  ManageQuotationPage,
  NonApprovedUsersLandingPage,
  ViewUserProfilePage,
  ManageInvitation,
  ViewJobs,
  SupplierHub,
  JobDetails,
  InviteSupplier,
  SupplierList,
  TaskManagement,
  ViewTask,
  TaskDetails,
  Notification,
  PersonalChatPage,
  ProjectChatPage,
  FeedbackPage
} from '../pages';
import { SCREEN_PATH, defaultValues } from '../constants';
import { useSelector } from 'react-redux';
import { getApprovedStatus, getAuthStatus, getUserType } from '../services/redux/selectors';
import { useEffect } from 'react';

import {
  CalenderManagement,
  ProjectDetails,
  ProjectDocumentManagement,
  ProjectLogs,
  ProjectRequirement
} from '../components';

export default function RootNavigation() {
  const isAuthenticated = useSelector(getAuthStatus);
  const isApproved = useSelector(getApprovedStatus);
  const userType = useSelector(getUserType);
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentUrl = location.pathname;
    const urlParams = new URLSearchParams(location.search);
    const proId = urlParams.get('proId');
    if (currentUrl === SCREEN_PATH.FEEDBACK && proId) {
      const urlWithProId = `${currentUrl}?proId=${proId}`;
      sessionStorage.setItem('currentUrl', urlWithProId);
    }
    if (!isAuthenticated) {
      navigation(SCREEN_PATH.HOME);
    } else {
      const currentUrl = sessionStorage.getItem('currentUrl');
      if (currentUrl) {
        sessionStorage.removeItem('currentUrl');
        navigation(currentUrl);
      } else {
        navigation(SCREEN_PATH.ADMIN_DASHBOARD);
      }
    }

    if (userType === defaultValues.supplier && !isApproved) {
      navigation(SCREEN_PATH.NON_APPROVED_USERS);
    }
  }, [isAuthenticated, isApproved]);

  if (isAuthenticated) {
    return (
      <Routes>
        <Route path={SCREEN_PATH.ADMIN_DASHBOARD} element={<AdminDashboardPage />} />
        <Route path={SCREEN_PATH.MANAGE_PROJECTS} element={<ProjectsPage />} />
        <Route path={SCREEN_PATH.MANAGE_CUSTOMERS} element={<ManageCustomersPage />} />
        <Route path={SCREEN_PATH.MANAGE_SUPPLIERS} element={<ManageSuppliersPage />} />
        <Route path={SCREEN_PATH.MANAGE_ADMINS} element={<ManageAdminPage />} />
        <Route path={SCREEN_PATH.MANAGE_JOBS} element={<ManageJobsPage />} />
        <Route path={SCREEN_PATH.PROJECT_MANAGERS} element={<ManagePMPage />} />
        <Route path={SCREEN_PATH.PROFILE_SETTINGS} element={<ProfilePage />} />
        <Route path={SCREEN_PATH.OTHER_SETTINGS} element={<OtherSettingsPage />} />
        <Route path={SCREEN_PATH.MANAGE_QUOTATION} element={<ManageQuotationPage />} />
        <Route path={SCREEN_PATH.MANAGE_TEAM} element={<ManageTeamPage />} />
        <Route path={SCREEN_PATH.NON_APPROVED_USERS} element={<NonApprovedUsersLandingPage />} />
        <Route path={SCREEN_PATH.VIEW_USER_PROFILE} element={<ViewUserProfilePage />} />
        <Route path={SCREEN_PATH.ENQUIRE} element={<EnquirePage />} />
        <Route path={SCREEN_PATH.PROJECT_DETAILS} element={<ProjectDetails />} />
        <Route path={SCREEN_PATH.MANAGE_INVITATION} element={<ManageInvitation />} />
        <Route path={SCREEN_PATH.PROJECT_DETAILS} element={<ProjectDetails />} />
        <Route path={SCREEN_PATH.VIEW_JOBS} element={<ViewJobs />} />
        <Route path={SCREEN_PATH.PROJECT_REQUIREMENTS} element={<ProjectRequirement />} />
        <Route path={SCREEN_PATH.PROJECT_SUPPLIER_HUB} element={<SupplierHub />} />
        <Route path={SCREEN_PATH.JOB_DETAILS} element={<JobDetails />} />
        <Route path={SCREEN_PATH.INVITE_SUPPLIER} element={<InviteSupplier />} />
        <Route path={SCREEN_PATH.SUPPLIER_LIST} element={<SupplierList />} />
        <Route path={SCREEN_PATH.PROJECT_TASK_MAN} element={<TaskManagement />} />
        <Route path={SCREEN_PATH.PROJECT_DOCUMENT_MAN} element={<ProjectDocumentManagement />} />
        <Route path={SCREEN_PATH.PROJECT_TASKS} element={<ViewTask />} />
        <Route path={SCREEN_PATH.PROJECT_TASKDETAILS} element={<TaskDetails />} />
        <Route path={SCREEN_PATH.CALENDER_DOCUMENT_MAN} element={<CalenderManagement />} />
        <Route path={SCREEN_PATH.NOTIFICATION} element={<Notification />} />
        <Route path={SCREEN_PATH.CHAT_SCREEN} element={<PersonalChatPage />} />
        <Route path={SCREEN_PATH.PROJECT_CHAT} element={<ProjectChatPage />} />
        <Route path={SCREEN_PATH.FEEDBACK} element={<FeedbackPage />} />
        <Route path={SCREEN_PATH.PROJECT_LOGS} element={<ProjectLogs />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path={SCREEN_PATH.HOME} element={<LandingPage />} />
        <Route path={SCREEN_PATH.SIGN_IN_PAGE} element={<SignInPage />} />
        <Route path={SCREEN_PATH.SUPPLIER_REGISTER} element={<SupplierRegistrationPage />} />
        <Route path={SCREEN_PATH.ENQUIRE} element={<EnquirePage />} />
      </Routes>
    );
  }
}
