import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';
const isRes = defaultValues.isResponsive;

export const Styles = {
  margin: {
    top: 20,
    right: isRes ? 10 : 20,
    left: isRes ? -20 : 10,
    bottom: 40
  },
  size: { containerWidth: '100%', height: '500', width: '800' },
  colors: { yellow: color.palette.goldenYellow, red: color.palette.lightRed }
};
