import { defaultValues } from '../../../constants';

export const Styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    boxShadow: 3,
    borderRadius: 1,
    mr: 1
  },
  chartContainer: { width: '60%', height: '100%' },
  textStyle: { pt: 1, pl: 2 },
  size: '90%',
  rightSection: {
    width: '40%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    p: defaultValues.isResponsive ? 0 : 2
  },
  detailsContainer: {
    display: 'flex',
    width: '100%',
    height: '6%',
    alignItems: 'center',
    my: defaultValues.isResponsive ? 1 : 2
  },
  colorIndicator: (colors, index) => ({
    height: defaultValues.isResponsive ? '10px' : '15px',
    width: defaultValues.isResponsive ? '10px' : '15px',
    backgroundColor: colors[index % colors.length],
    borderRadius: '60%',
    mx: 1
  }),
  underline: { textDecoration: 'underline' }
};
