import React, { useEffect, useState } from 'react';
import { Box, Divider, IconButton, ListSubheader, Menu, MenuItem } from '@mui/material';
import { Text } from '../text';
import { style } from './style';
import sortUp from '../../assets/icons/sort-up.png';
import { defaultValues } from '../../constants';
import { Close } from '@mui/icons-material';
import { color } from '../../theme';
import { UIRadioButton } from '../radio-button';

export const Sort = ({
  activeOption,
  selectedOption,
  iconStyle,
  dropdownOptions = defaultValues.sortOptions
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeOp, setActiveOp] = useState(null);
  const [sortOptions, setSortOptions] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setActiveOp(activeOption);
  }, [activeOption]);

  useEffect(() => {
    if (dropdownOptions) {
      setSortOptions(dropdownOptions);
    }
  }, [dropdownOptions]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (e) => {
    let value = e.currentTarget.dataset.value;
    selectedOption(value);
    setActiveOp(value);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box style={style.toolsIconContainer} onClick={handleClick}>
        <img style={style.toolsIcons(iconStyle)} src={sortUp} alt="Sort Icon" />
        <Text variant={defaultValues.isXSmall ? 'cardResSubTitle' : 'medium'}>Sort</Text>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        style={{
          background: anchorEl !== null ? color.palette.backDropColor : 'none'
        }}
        PaperProps={{
          style: style.menuBox
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <ListSubheader>
          <Box sx={style.menuHeader}>
            <Text variant="smallBold">Sort By</Text>
            <IconButton sx={style.marginAuto} onClick={() => setAnchorEl(null)}>
              <Close sx={style.closeBtn} />
            </IconButton>
          </Box>
        </ListSubheader>
        <Divider sx={{ my: 0.5 }} />
        {sortOptions &&
          sortOptions.map((item, index) => (
            <MenuItem
              key={index + 1}
              data-value={item.value}
              onClick={handleChange}
              sx={style.menuText}>
              <Box
                sx={{ ...style.menuItem, fontWeight: item.value === activeOp ? 'bold' : 'normal' }}>
                {item.key}
                <UIRadioButton
                  size="23px"
                  sx={style.marginLeft}
                  checked={item.value === activeOp}
                />
              </Box>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};
