import { createSelector } from 'reselect';

export const getSettingsSuccessMessage = createSelector(
  [(state) => state.settings.successMessage],
  (successMessage) => successMessage
);

export const getSuccessServicesMessage = createSelector(
  [(state) => state.settings.successServicesMessage],
  (successMessage) => successMessage
);

export const getSuccessMessageAddServices = createSelector(
  [(state) => state.settings.successMessageAddServices],
  (successMessage) => successMessage
);

export const getSettingsErrorMessage = createSelector(
  [(state) => state.settings.errorMessage],
  (errorMessage) => errorMessage
);

export const settingLoadingStatus = createSelector(
  [(state) => state.settings.isLoading],
  (loadingStatus) => loadingStatus
);

export const getLanguages = createSelector(
  [(state) => state.settings.languages],
  (languages) => languages
);

export const getCurrencies = createSelector(
  [(state) => state.settings.currencies],
  (currencies) => currencies
);

export const getCountries = createSelector(
  [(state) => state.settings.countries],
  (countries) => countries
);

export const getStats = createSelector([(state) => state.settings.states], (states) => states);

export const services = createSelector(
  [(state) => state.settings.services],
  (services) => services
);

export const totalPage = createSelector(
  [(state) => state.settings.totalPage],
  (totalPage) => totalPage
);

export const currentPage = createSelector(
  [(state) => state.settings.currentPage],
  (currentPage) => currentPage
);

export const getNextPage = createSelector(
  [(state) => state.settings.nextPage],
  (nextPage) => nextPage
);

export const getSkills = createSelector([(state) => state.settings.skills], (skills) => skills);

export const getServices = createSelector(
  [(state) => state.settings.services],
  (services) => services
);

export const getMasterList = createSelector(
  [(state) => state.settings.masterList],
  (masterList) => masterList
);

export const getTimeZone = createSelector(
  [(state) => state.settings.timeZone],
  (timeZone) => timeZone
);

export const getServicesTotalPage = createSelector(
  [(state) => state.settings.serviceTotalPage],
  (serviceTotalPage) => serviceTotalPage
);

export const getServicesCurrentPage = createSelector(
  [(state) => state.settings.serviceCurrentPage],
  (serviceCurrentPage) => serviceCurrentPage
);

export const getTimezoneTotalPage = createSelector(
  [(state) => state.settings.timeZoneTotalPage],
  (timeZoneTotalPage) => timeZoneTotalPage
);

export const getTimezoneCurrentPage = createSelector(
  [(state) => state.settings.timeZoneCurrentPage],
  (timeZoneCurrentPage) => timeZoneCurrentPage
);

export const getTimezoneNextPage = createSelector(
  [(state) => state.settings.timeZoneNextPage],
  (timeZoneNextPage) => timeZoneNextPage
);

export const getLanguageNextPage = createSelector(
  [(state) => state.settings.languageNextPage],
  (languageNextPage) => languageNextPage
);
export const getSkillsNextPage = createSelector(
  [(state) => state.settings.skillsNextPage],
  (skillsNextPage) => skillsNextPage
);

export const getCurrenciesNextPage = createSelector(
  [(state) => state.settings.currenciesNextPage],
  (currenciesNextPage) => currenciesNextPage
);

export const getCountriesNextPage = createSelector(
  [(state) => state.settings.countriesNextPage],
  (countriesNextPage) => countriesNextPage
);

export const getSettingsIndicator = createSelector(
  [(state) => state.settings.indicator],
  (indicator) => indicator
);
