import { useCallback, useEffect, useState } from 'react';
import { defaultValues } from '../../../constants';
import {
  finalList,
  getPhotoUrl,
  getValueWithId,
  profileCustomerSchema,
  profileOtherSchema,
  sortAlpha,
  sortTimeZone,
  supplierSchema
} from '../../../utils';
import { useFormik } from 'formik';
import { registrationStyles } from './styles';
import {
  Text,
  UIAutocompleted,
  UIButton,
  UIChipButton,
  UITextInputWithTitle
} from '../../../ui-kit';
import { SelectWithTitle } from '../../../ui-kit/select-option-with-title';
import { Box, Checkbox, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import { UploadProfilePicture } from '../upload-profile-picture';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountries,
  getCountriesNextPage,
  getCurrencies,
  getCurrenciesNextPage,
  getLanguageNextPage,
  getLanguages,
  getServices,
  getTimeZone,
  getTimezoneNextPage
} from '../../../services/redux/selectors';
import { color } from '../../../theme';
import {
  getCountriesRequest,
  getCurrenciesRequest,
  getLanguageRequest,
  getTimeZoneRequest,
  serviceRequest
} from '../../../services/redux/slices';

import 'react-phone-number-input/style.css';
import './style.css';
import { CheckBox } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const RegistrationForm = ({
  type = null,
  data,
  onSubmit,
  isLoading,
  propicChange,
  propicDelete,
  emailDisabled,
  languageTitle = 'Languages',
  currencyTitle = 'Currency',
  buttonTitle = 'Save changes',
  userType = null,
  divider = true,
  containerStyles,
  detailsContainerStyles
}) => {
  const dispatch = useDispatch();
  const allLanguages = useSelector(getLanguages);
  const allServices = useSelector(getServices);
  const currencyList = useSelector(getCurrencies);
  const timezonelist = useSelector(getTimeZone);
  const countriesList = useSelector(getCountries);
  const timeZoneNextPage = useSelector(getTimezoneNextPage);
  const languageNextPage = useSelector(getLanguageNextPage);
  const currencyNextPage = useSelector(getCurrenciesNextPage);
  const countriesNextPage = useSelector(getCountriesNextPage);

  const [stateList, setStateList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [currenciesList, setCurrenciesList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [wAppChecked, setWAppChecked] = useState(false);
  const [telErrMsg, setTelErrMsg] = useState('');
  const [watErrMsg, setWatErrMsg] = useState('');
  const [sevicesNextPage, setServicesNextPage] = useState(null);
  const [showAvailabilityDetails, setShowAvailabilityDetails] = useState(false);
  const [availability, setAvailability] = useState('');
  const timestamp = new Date().getTime();

  let timer;

  const schemaFn = () => {
    if (userType === defaultValues.userRole.customer) {
      return profileCustomerSchema;
    } else if (userType === defaultValues.userRole.supplier || !userType) {
      return supplierSchema;
    } else {
      return profileOtherSchema;
    }
  };

  const extractId = (options) => {
    let arr = [];
    if (options) {
      options.map((item) => {
        arr.push(item.id);
      });
      return arr;
    }
  };

  const getId = (val, options, title) => {
    let id;
    options.map((item) => {
      if (item[title] === val) {
        id = item.id;
        return;
      }
    });
    return id;
  };

  const extractTitle = (options, title, val) => {
    const index = options.findIndex((item) => item.id === val);
    return index >= 0 ? options[index][title] : null;
  };

  const { values, handleChange, errors, touched, handleBlur, setFieldValue, isValid } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email_id: '',
      skills: [],
      languages: [],
      currency: '',
      locality: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
      timezoneId: '',
      skype_id: '',
      telephone_number: '',
      whatsapp_number: '',
      stateReq: false,
      availability_type: '',
      availability_details: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: schemaFn()
  });

  useEffect(() => {
    dispatch(getLanguageRequest());
    dispatch(serviceRequest());
    dispatch(getCountriesRequest());
    dispatch(getTimeZoneRequest());
    dispatch(getCurrenciesRequest());
  }, []);

  useEffect(() => {
    if (type === defaultValues.listConstants.supplier) {
      setButtonDisabled(false);
    }
  }, [type]);

  const addListData = useCallback(
    (data) => {
      if (data.skills) {
        let filterData = [...serviceList];
        data.skills.forEach((item) => {
          if (filterData.findIndex((e) => e.id === item.id) < 0) {
            filterData.push(item);
          }
        });
        setServiceList(filterData);
      }
    },
    [serviceList]
  );

  const addLangListData = useCallback(
    (data) => {
      if (data.preferred_languages) {
        let filterData = [...languageList];
        data.preferred_languages.forEach((item) => {
          if (filterData.findIndex((e) => e.id === item.id) < 0) {
            filterData.push(item);
          }
        });
        setLanguageList(filterData);
      }
    },
    [languageList]
  );

  const filterFun = (arr) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((s) => s.id === item.id);
    });
  };

  useEffect(() => {
    if (data) {
      setFieldValue(defaultValues.listConstants.first_name, data.first_name, true);
      setFieldValue(defaultValues.listConstants.last_name, data.last_name, true);
      setFieldValue(defaultValues.listConstants.email_id, data.email_id, true);
      setFieldValue(defaultValues.listConstants.skills, extractId(data.skills), true);
      setFieldValue(defaultValues.listConstants.availability_details, data.availability_details);

      setFieldValue(
        defaultValues.listConstants.languages,
        extractId(data.preferred_languages),
        true
      );
      setFieldValue(defaultValues.listConstants.locality, data.address, true);
      setFieldValue(
        defaultValues.listConstants.currency,
        getId(data.currency, currencyList, defaultValues.listConstants.currency),
        true
      );
      setFieldValue(
        defaultValues.listConstants.country,
        getId(data.country, countriesList, defaultValues.listConstants.country_name),
        true
      );
      setFieldValue(defaultValues.listConstants.city, data.city, true);
      setFieldValue(defaultValues.listConstants.postal_code, data.postal_code, true);
      if (data.timezone_data) {
        setFieldValue(defaultValues.listConstants.timezoneId, data.timezone_data.id, true);
      }

      if (data.availability_type) {
        setFieldValue(defaultValues.listConstants.availability_type, data.availability_type);
        setAvailability(getValueWithId(defaultValues.availabilityOptions, data.availability_type));
        setShowAvailabilityDetails(true);
      }

      if (
        userType === defaultValues.userRole.supplier ||
        userType === defaultValues.userRole.customer
      ) {
        addListData(data);
        addLangListData(data);
      }

      if (data.telephone_number) {
        setFieldValue(defaultValues.listConstants.telephone_number, data.telephone_number, true);
      }
      if (data.whatsapp_number) {
        setFieldValue(defaultValues.listConstants.whatsapp_number, data.whatsapp_number, true);
      }
      if (data.skype_id) {
        setFieldValue(defaultValues.listConstants.skype_id, data.skype_id, true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (allServices && allServices.next_page) {
      setServicesNextPage(allServices.next_page);
    }

    if (allServices.services) {
      let temp = [...serviceList, ...allServices.services];

      temp = filterFun(temp);

      setServiceList(sortAlpha(temp, defaultValues.listConstants.title));
    }

    if (allLanguages) {
      let temp = [...languageList, ...allLanguages];

      temp = filterFun(temp);
      setLanguageList(sortAlpha(temp, defaultValues.listConstants.language_title));
    }
    if (currencyList) {
      let temp = [...currenciesList, ...currencyList];

      temp = filterFun(temp);
      setCurrenciesList(sortAlpha(temp, defaultValues.listConstants.currency));
    }
    if (countriesList) {
      let temp = [...countries, ...countriesList];

      temp = filterFun(temp);
      setCountries(sortAlpha(temp, defaultValues.listConstants.company_name));
    }
    if (timezonelist) {
      let temp = [...timezones, ...timezonelist];

      temp = filterFun(temp);
      setTimezones(sortTimeZone(temp, defaultValues.listConstants.timezone));
    }
  }, [allLanguages, currencyList, countriesList, allServices.services, timezonelist]);

  const searchList = (val, title) => {
    let payload = {};
    if (title === defaultValues.listConstants.title) {
      payload = { search_text: val };
      dispatch(serviceRequest(payload));
    }
    if (title === defaultValues.listConstants.languages) {
      payload = { search_text: val };
      dispatch(getLanguageRequest(payload));
    }

    if (title === defaultValues.listConstants.timezone) {
      payload = { search_text: val };
      dispatch(getTimeZoneRequest(payload));
    }
    if (title === defaultValues.listConstants.currency) {
      payload = { search_text: val };
      dispatch(getCurrenciesRequest(payload));
    }
    if (title === defaultValues.listConstants.countries) {
      payload = { search_text: val };
      dispatch(getCountriesRequest(payload));
    }
  };

  useEffect(() => {
    const selectedCountry = countries.find((item) => item.id === values.country);

    if (selectedCountry) {
      setStateList(sortAlpha(selectedCountry.states, defaultValues.listConstants.name));
      setFieldValue('stateReq', selectedCountry.states && selectedCountry.states.length > 0);

      if (type === defaultValues.listConstants.profile && data && data.state) {
        const selectedStateId = getId(
          data.state,
          selectedCountry.states,
          defaultValues.listConstants.name
        );
        setFieldValue(defaultValues.listConstants.state, selectedStateId);
      } else {
        setFieldValue(defaultValues.listConstants.state, '');
      }
    }
  }, [values.country, countriesList, data, type]);

  const handleDelete = (start, end, title) => {
    let updatedList = [...values[title]];
    updatedList.splice(start, end);
    setFieldValue(`${title}`, updatedList);
    setButtonDisabled(false);
  };

  const selectedItem = (val, title, valueTitle, list, valueList) => {
    const Info = list.find((item) => item[title] === val);
    if (Info) {
      let isDuplicateVal = valueList.findIndex((el) => el === Info.id);

      if (isDuplicateVal < 0) {
        type === defaultValues.listConstants.profile && setButtonDisabled(false);
        setFieldValue(`${valueTitle}`, [...values[valueTitle], Info.id]);
      }
    }
  };

  const handleScrollEnd = (title) => {
    let payload = {};
    if (title === defaultValues.listConstants.services && sevicesNextPage !== -1) {
      payload = { page: sevicesNextPage };
      dispatch(getServices(payload));
    }
    if (title === defaultValues.listConstants.languages && languageNextPage !== -1) {
      payload = { page: languageNextPage };
      dispatch(getLanguageRequest(payload));
    }

    if (title === defaultValues.listConstants.currency && currencyNextPage !== -1) {
      payload = { page: currencyNextPage };
      dispatch(getCurrenciesRequest(payload));
    }
    if (title === defaultValues.listConstants.countries && countriesNextPage !== -1) {
      payload = { page: countriesNextPage };
      dispatch(getCountriesRequest(payload));
    }
    if (title === defaultValues.listConstants.timezone && timeZoneNextPage !== -1) {
      payload = { page: timeZoneNextPage };
      dispatch(getTimeZoneRequest(payload));
    }
  };

  const handleWappCheck = () => {
    setWAppChecked(!wAppChecked);
    if (!wAppChecked) {
      setFieldValue(defaultValues.listConstants.whatsapp_number, values.telephone_number);
    } else {
      setFieldValue(defaultValues.listConstants.whatsapp_number, '');
    }
  };

  const handleTelephoneChange = (value) => {
    setFieldValue(defaultValues.listConstants.telephone_number, value);
    if (value !== undefined) {
      if (isValidPhoneNumber(value)) {
        setTelErrMsg('');
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
        setTelErrMsg('Please enter valid phone number');
      }
    } else {
      setButtonDisabled(true);
      setTelErrMsg('');
    }
  };

  const handleWappChange = (value) => {
    setFieldValue(defaultValues.listConstants.whatsapp_number, value);

    if (value !== undefined) {
      if (isValidPhoneNumber(value)) {
        setWatErrMsg('');
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
        setWatErrMsg('Please enter valid phone number');
      }
    } else {
      setButtonDisabled(true);
      setWatErrMsg('');
    }
  };

  return (
    <Box sx={registrationStyles.container(containerStyles)}>
      {type && type === defaultValues.listConstants.profile && (
        <UploadProfilePicture
          previewPicture={
            data && data.photo_url ? `${getPhotoUrl(data.photo_url)}?timestamp=${timestamp}` : ''
          }
          onProfilePicChange={propicChange}
          onProPicDelete={propicDelete}
          type={defaultValues.listConstants.profile}
        />
      )}
      {divider && <Box sx={registrationStyles.horizonalLine}></Box>}
      <Box sx={registrationStyles.detailsContainer(detailsContainerStyles)}>
        <Box sx={registrationStyles.nameContainer}>
          <UITextInputWithTitle
            title="First name"
            name={defaultValues.listConstants.first_name}
            variant={defaultValues.isResponsive ? 'medium' : 'body'}
            required
            value={values.first_name}
            placeholder="enter first name"
            onChange={(val) => {
              type === defaultValues.listConstants.profile && setButtonDisabled(false);
              handleChange(val);
            }}
            error={errors.first_name}
            touched={touched.first_name}
            onBlur={handleBlur}
            allowOnlyAlphabets={true}
          />

          <UITextInputWithTitle
            title="Last name"
            name={defaultValues.listConstants.last_name}
            variant={defaultValues.isResponsive ? 'medium' : 'body'}
            required
            value={values.last_name}
            placeholder="enter last name"
            onChange={(val) => {
              type === defaultValues.listConstants.profile && setButtonDisabled(false);
              handleChange(val);
            }}
            error={errors.last_name}
            touched={touched.last_name}
            onBlur={handleBlur}
            allowOnlyAlphabets={true}
          />
        </Box>

        {type === defaultValues.listConstants.profile && (
          <Box sx={registrationStyles.locContainer}>
            <UITextInputWithTitle
              title="Skype-id"
              name={defaultValues.listConstants.skype_id}
              variant={defaultValues.isResponsive ? 'medium' : 'body'}
              value={values.skype_id}
              placeholder="enter your skype-id"
              onChange={(val) => {
                type === defaultValues.listConstants.profile && setButtonDisabled(false);
                handleChange(val);
              }}
              error={errors.skype_id}
              touched={touched.skype_id}
              onBlur={handleBlur}
            />
          </Box>
        )}

        <Box sx={registrationStyles.locContainer}>
          <UITextInputWithTitle
            title="Email-id"
            name={defaultValues.listConstants.email_id}
            disabled={emailDisabled}
            variant={defaultValues.isResponsive ? 'medium' : 'body'}
            required
            value={values.email_id}
            placeholder="enter your email-id"
            onChange={(val) => {
              type === defaultValues.listConstants.profile && setButtonDisabled(false);
              handleChange(val);
            }}
            error={errors.email_id}
            touched={touched.email_id}
            onBlur={handleBlur}
          />
        </Box>

        {type === defaultValues.listConstants.profile && (
          <Box sx={registrationStyles.cityStateContainer}>
            <Box sx={registrationStyles.cityStateSubContainer}>
              <Text
                style={registrationStyles.title}
                variant={defaultValues.isResponsive ? 'medium' : 'body'}>
                Telephone Number
              </Text>
              <PhoneInput
                name="telephone_number"
                className="phone"
                international={true}
                countryCallingCodeEditable={false}
                value={values.telephone_number}
                onChange={handleTelephoneChange}
                touched={touched.telephone_number}
                onBlur={handleBlur}
                placeholder="Enter phone number"
              />
              {telErrMsg && <Text variant="fieldError">{telErrMsg}</Text>}
            </Box>

            <Box sx={registrationStyles.cityStateSubContainer2}>
              <Text
                style={registrationStyles.title}
                variant={defaultValues.isResponsive ? 'medium' : 'body'}>
                Whatsapp Number
              </Text>
              <PhoneInput
                name="whatsapp_number"
                className=" phone"
                international={true}
                countryCallingCodeEditable={false}
                value={wAppChecked ? values.telephone_number : values.whatsapp_number}
                onChange={handleWappChange}
                onBlur={handleBlur}
                touched={touched.telephone_number}
                placeholder="Enter whatsapp number"
              />
              {watErrMsg && <Text variant="fieldError">{watErrMsg}</Text>}
              <Box sx={registrationStyles.checkBoxContainer}>
                <FormGroup>
                  <FormControlLabel
                    sx={registrationStyles.formControl}
                    control={
                      <Checkbox
                        disabled={!values.telephone_number}
                        checked={wAppChecked}
                        sx={registrationStyles.checkBox}
                        onChange={handleWappCheck}
                        icon={<CheckBoxOutlineBlankIcon style={registrationStyles.square} />}
                        checkedIcon={<CheckBox style={registrationStyles.checkedIcon} />}
                      />
                    }
                    label={<Text sx={registrationStyles.checkText}>same as phone number</Text>}
                  />
                </FormGroup>
              </Box>
            </Box>
          </Box>
        )}

        {userType === defaultValues.userRole.supplier && (
          <UIAutocompleted
            title={'Availability'}
            name={defaultValues.listConstants.availability_type}
            placeholder={'select your availability'}
            variant={defaultValues.isResponsive ? 'medium' : 'body'}
            required={true}
            options={
              defaultValues.availabilityOptions &&
              defaultValues.availabilityOptions.map((item) => item.name)
            }
            preSelectedValue={availability.length > 0 && availability}
            addShowButton={false}
            touched={touched.availability_type}
            onBlur={handleBlur}
            error={errors.availability_type}
            onChange={(val) => {
              type === defaultValues.listConstants.profile && setButtonDisabled(false);
              setShowAvailabilityDetails(true);
              setFieldValue(
                defaultValues.listConstants.availability_type,
                getId(val, defaultValues.availabilityOptions, defaultValues.listConstants.name)
              );
            }}
          />
        )}

        {showAvailabilityDetails && userType === defaultValues.userRole.supplier && (
          <Box sx={registrationStyles.locContainer}>
            <UITextInputWithTitle
              title="Working schedule"
              name={defaultValues.listConstants.availability_details}
              variant={defaultValues.isResponsive ? 'medium' : 'body'}
              value={values.availability_details}
              required
              lines={3}
              placeholder="type here..."
              onChange={(val) => {
                type === defaultValues.listConstants.profile && setButtonDisabled(false);
                handleChange(val);
              }}
              error={errors.availability_details}
              touched={touched.availability_details}
              onBlur={handleBlur}
            />
          </Box>
        )}

        {(userType === defaultValues.userRole.supplier || !userType) && (
          <>
            <UIAutocompleted
              title={'Skills'}
              name={defaultValues.listConstants.skills}
              placeholder={'select skills'}
              required={true}
              options={finalList(values.skills, serviceList).map(
                (item) => item.title || item.skill_title
              )}
              variant={defaultValues.isResponsive ? 'medium' : 'body'}
              onAdd={(val) => {
                selectedItem(
                  val,
                  defaultValues.listConstants.title,
                  defaultValues.listConstants.skills,
                  serviceList,
                  values.skills
                );
              }}
              isLoading={isLoading}
              style={registrationStyles.selectTitle(defaultValues.isResponsive)}
              onTyping={(val) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  if (val.length > 2) {
                    searchList(val, defaultValues.listConstants.title);
                  }
                }, 500);
              }}
              onScrollEnd={() => handleScrollEnd(defaultValues.listConstants.services)}
              touched={touched.skills}
              onBlur={handleBlur}
              error={errors.skills}
              mainBoxStyle={registrationStyles.mainBox}
            />

            {values.skills && (
              <Box>
                {values.skills.map((item, index) => (
                  <UIChipButton
                    key={index}
                    backgroundColor={color.palette.primaryRed}
                    title={extractTitle(serviceList, defaultValues.listConstants.title, item)}
                    onDelete={() => handleDelete(index, 1, defaultValues.listConstants.skills)}
                  />
                ))}
              </Box>
            )}
          </>
        )}

        {(userType === defaultValues.userRole.supplier || !userType) && (
          <>
            <UIAutocompleted
              title={languageTitle}
              name={defaultValues.listConstants.languages}
              placeholder={'select languages'}
              required={true}
              options={finalList(values.languages, languageList).map((item) => item.language_title)}
              variant={defaultValues.isResponsive ? 'medium' : 'body'}
              onAdd={(val) => {
                selectedItem(
                  val,
                  defaultValues.listConstants.language_title,
                  defaultValues.listConstants.languages,
                  languageList,
                  values.languages
                );
              }}
              style={registrationStyles.selectTitle(defaultValues.isResponsive)}
              onTyping={(val) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  if (val.length > 2) {
                    searchList(val, defaultValues.listConstants.languages);
                  }
                }, 500);
              }}
              onScrollEnd={() => handleScrollEnd(defaultValues.listConstants.languages)}
              touched={touched.languages}
              onBlur={handleBlur}
              error={errors.languages}
              mainBoxStyle={registrationStyles.mainBox}
            />

            {values.languages && (
              <Box>
                {values.languages.map((item, index) => (
                  <UIChipButton
                    key={index}
                    backgroundColor={color.palette.primaryRed}
                    title={extractTitle(
                      languageList,
                      defaultValues.listConstants.language_title,
                      item
                    )}
                    onDelete={() => handleDelete(index, 1, defaultValues.listConstants.languages)}
                  />
                ))}
              </Box>
            )}
          </>
        )}

        <Box sx={registrationStyles.accentCurrContainer(type)}>
          {(userType === defaultValues.userRole.supplier ||
            userType === defaultValues.userRole.customer ||
            !userType) && (
            <UIAutocompleted
              title={'Timezone'}
              name={defaultValues.listConstants.timezoneId}
              placeholder={'select timezone'}
              required={true}
              options={timezones && timezones.map((elem) => elem.timezone)}
              variant={defaultValues.isResponsive ? 'medium' : 'body'}
              addShowButton={false}
              style={registrationStyles.selectTitle(defaultValues.isResponsive)}
              preSelectedValue={data && data.timezone_data && data.timezone_data.timezone}
              onChange={(val) => {
                type === defaultValues.listConstants.profile && setButtonDisabled(false);
                setFieldValue(
                  defaultValues.listConstants.timezoneId,
                  getId(val, timezones, defaultValues.listConstants.timezone)
                );
              }}
              onTyping={(val) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  if (val.length > 2 || val.length === 0) {
                    searchList(val, defaultValues.listConstants.timezone);
                  }
                }, 500);
              }}
              onScrollEnd={() => handleScrollEnd(defaultValues.listConstants.timezone)}
              touched={touched.timezoneId}
              onBlur={handleBlur}
              error={errors.timezoneId}
              mainBoxStyle={registrationStyles.mainBox}
            />
          )}
          {(userType === defaultValues.userRole.supplier || !userType) && (
            <UIAutocompleted
              title={currencyTitle}
              name={defaultValues.listConstants.currency}
              placeholder={'select currency'}
              required={true}
              options={currenciesList && currenciesList.map((elem) => elem.currency)}
              variant={defaultValues.isResponsive ? 'medium' : 'body'}
              addShowButton={false}
              style={registrationStyles.selectTitle(defaultValues.isResponsive)}
              preSelectedValue={data && data.currency}
              onChange={(val) => {
                type === defaultValues.listConstants.profile && setButtonDisabled(false);

                setFieldValue(
                  defaultValues.listConstants.currency,
                  getId(val, currencyList, defaultValues.listConstants.currency)
                );
              }}
              onTyping={(val) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  if (val.length > 2 || val.length === 0) {
                    searchList(val, defaultValues.listConstants.currency);
                  }
                }, 500);
              }}
              onScrollEnd={() => handleScrollEnd(defaultValues.listConstants.currency)}
              touched={touched.currency}
              onBlur={handleBlur}
              error={errors.currency}
              mainBoxStyle={registrationStyles.mainBox}
            />
          )}

          {!userType && (
            <UIAutocompleted
              title={'Availability'}
              name={defaultValues.listConstants.availability_type}
              placeholder={'select your availability'}
              variant={defaultValues.isResponsive ? 'medium' : 'body'}
              required={true}
              options={
                defaultValues.availabilityOptions &&
                defaultValues.availabilityOptions.map((item) => item.name)
              }
              addShowButton={false}
              touched={touched.availability_type}
              onBlur={handleBlur}
              error={errors.availability_type}
              onChange={(val) => {
                setShowAvailabilityDetails(true);
                setFieldValue(
                  defaultValues.listConstants.availability_type,
                  getId(val, defaultValues.availabilityOptions, defaultValues.listConstants.name)
                );
              }}
            />
          )}

          {showAvailabilityDetails && !userType && (
            <Box sx={registrationStyles.locContainer}>
              <UITextInputWithTitle
                title="Working schedule"
                name={defaultValues.listConstants.availability_details}
                variant={defaultValues.isResponsive ? 'medium' : 'body'}
                value={values.availability_details}
                required
                lines={3}
                placeholder="type here..."
                onChange={handleChange}
                error={errors.availability_details}
                touched={touched.availability_details}
                onBlur={handleBlur}
              />
            </Box>
          )}
        </Box>

        {(userType === defaultValues.userRole.supplier ||
          userType === defaultValues.userRole.customer ||
          !userType) && (
          <Box sx={registrationStyles.locContainer}>
            <UITextInputWithTitle
              title="Locality"
              name={defaultValues.listConstants.locality}
              variant={defaultValues.isResponsive ? 'medium' : 'body'}
              value={values.locality}
              required
              placeholder="enter your address"
              onChange={(val) => {
                type === defaultValues.listConstants.profile && setButtonDisabled(false);
                handleChange(val);
              }}
              error={errors.locality}
              touched={touched.locality}
              onBlur={handleBlur}
            />
          </Box>
        )}

        {(userType === defaultValues.userRole.supplier ||
          userType === defaultValues.userRole.customer ||
          !userType) && (
          <>
            <Box sx={registrationStyles.cityStateContainer}>
              <Box sx={registrationStyles.cityStateSubContainer}>
                <UITextInputWithTitle
                  title="City"
                  name="city"
                  variant={defaultValues.isResponsive ? 'medium' : 'body'}
                  value={values.city}
                  required
                  placeholder="enter your city"
                  onChange={(val) => {
                    type === defaultValues.listConstants.profile && setButtonDisabled(false);
                    handleChange(val);
                  }}
                  error={errors.city}
                  touched={touched.city}
                  onBlur={handleBlur}
                />
              </Box>

              <Box sx={registrationStyles.cityStateSubContainer2}>
                <UITextInputWithTitle
                  title="Postal code"
                  name={defaultValues.listConstants.postal_code}
                  variant={defaultValues.isResponsive ? 'medium' : 'body'}
                  required
                  value={values.postal_code}
                  placeholder="enter postal code"
                  onChange={(val) => {
                    type === defaultValues.listConstants.profile && setButtonDisabled(false);
                    handleChange(val);
                  }}
                  error={errors.postal_code}
                  touched={touched.postal_code}
                  onBlur={handleBlur}
                />
              </Box>
            </Box>
            <Box sx={registrationStyles.cityStateContainer}>
              <Box sx={registrationStyles.cityStateSubContainer}>
                <UIAutocompleted
                  title={'Country'}
                  name={defaultValues.listConstants.country}
                  placeholder={'select country'}
                  required={true}
                  options={countries && countries.map((elem) => elem.country_name)}
                  variant={defaultValues.isResponsive ? 'medium' : 'body'}
                  addShowButton={false}
                  style={registrationStyles.selectTitle(defaultValues.isResponsive)}
                  preSelectedValue={data && data.country}
                  onChange={(val) => {
                    type === defaultValues.listConstants.profile && setButtonDisabled(false);
                    setFieldValue(
                      defaultValues.listConstants.country,
                      getId(val, countries, defaultValues.listConstants.country_name)
                    );
                  }}
                  onTyping={(val) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      if (val.length > 2 || val.length === 0) {
                        searchList(val, defaultValues.listConstants.countries);
                      }
                    }, 500);
                  }}
                  onScrollEnd={() => handleScrollEnd(defaultValues.listConstants.countries)}
                  touched={touched.country}
                  onBlur={handleBlur}
                  error={errors.country}
                  mainBoxStyle={registrationStyles.mainCountryBox}
                />
              </Box>

              <Box sx={registrationStyles.cityStateSubContainer2}>
                <SelectWithTitle
                  title="State(if applicable)"
                  name={defaultValues.listConstants.state}
                  value={values.state}
                  placeholder="select state"
                  onChange={(val) => {
                    type === defaultValues.listConstants.profile && setButtonDisabled(false);
                    handleChange(val);
                  }}
                  touched={true}
                  error={errors.state}
                  onBlur={handleBlur}>
                  {stateList.length > 0 &&
                    stateList.map((elem, idx) => (
                      <MenuItem key={idx} value={elem.id}>
                        {elem.name}
                      </MenuItem>
                    ))}
                </SelectWithTitle>
              </Box>
            </Box>
          </>
        )}
      </Box>

      <UIButton
        title={buttonTitle}
        disabled={
          (type === defaultValues.listConstants.supplier && !isValid) ||
          (type === defaultValues.listConstants.profile && !isValid) ||
          (type === defaultValues.listConstants.profile && isButtonDisabled)
        }
        style={registrationStyles.submitButton}
        onClick={() => {
          onSubmit(values);
          setButtonDisabled(true);
        }}
      />
    </Box>
  );
};
