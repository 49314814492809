import { Box } from '@mui/material';
import rightArrow from '../../../assets/images/Vector.png';
import { Text } from '../../../ui-kit';
import { defaultValues } from '../../../constants';

export const UpdatesCard = ({ data, msg, color, onClick }) => {
  const isRes = defaultValues.isResponsive;

  const styles = {
    container: {
      width: '100%',
      boxShadow: 1,
      borderRadius: 1,
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      my: 2
    },
    innerContainer: {
      minHeight: isRes ? '60px' : '80px',
      width: '2%',
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
      backgroundColor: color
    },
    contentContainer: {
      width: '80%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    iconContainer: {
      width: '10%',
      pr: 2,
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center'
    },
    fontColor: { color: color },
    iconStyle: { cursor: 'pointer', width: isRes && '20px' }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}></Box>
      <Box sx={styles.contentContainer}>
        <Text variant={isRes ? 'body' : 'subTitle'} style={styles.fontColor}>
          {data}
        </Text>
        <Text variant={isRes ? 'responsiveDesc' : 'medium'}>{msg}</Text>
      </Box>{' '}
      <Box sx={styles.iconContainer}>
        <img src={rightArrow} style={styles.iconStyle} onClick={onClick} />
      </Box>
    </Box>
  );
};
