import { color } from '../../../theme/color';

export const SelectServiceStyles = {
  container: {
    width: '100%',
    paddingLeft: '20px',
    boxSizing: 'border-box',
    height: '100vh'
  },
  label: {
    marginTop: '20px',
    marginBottom: '10px'
  },
  desc: {
    fontSize: '22px'
  },
  selectField: {
    width: '80%',
    backgroundColor: color.palette.primaryWhite,
    borderRadius: '4px'
  },
  buttonStyle: (select, isRes) => ({
    backgroundColor: select ? color.palette.primaryRed : color.palette.primaryDisabledRed,
    display: 'block',
    color: color.palette.primaryWhite,
    fontSize: isRes ? '14px' : '24px',
    width: isRes && '100%',
    height: isRes && '40px',
    padding: '2px 70px',
    marginTop: !isRes && '30px'
  }),
  heading: (isRes) => ({
    fontWeight: 550,
    color: isRes && color.palette.primaryRed
  }),
  formContainer: (isRes) => ({
    minWidth: 120,
    width: isRes ? '100%' : '80%'
  }),
  selectArea: {
    backgroundColor: color.palette.textFieldRed
  },
  serviceDetailsHeading: {
    fontWeight: 550,
    marginBottom: '20px',
    marginTop: '20px'
  },
  buttonContainer: (isRes) => isRes && { height: '18%', display: 'flex', alignItems: 'flex-end' },
  selectTitle: (isRes) => ({
    mB: { fontSize: isRes ? '16px' : '28px' },
    mainBox: { width: isRes ? '100%' : '80%' }
  }),
  selectContainer: (isRes, isSelected) => ({
    height: isSelected ? 'fit-content' : isRes ? '78%' : 'fit-content',
    width: '100%'
  }),
  details: { minHeight: '65%' }
};
