import { Box } from '@mui/material';
import { Text } from '../text';
import { defaultValues } from '../../constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { color } from '../../theme';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DateAndTimePickerWithTitle = ({
  title,
  onBlur,
  onChange,
  errors,
  style,
  selValue
}) => {
  const defaultStyles = {
    title: {
      marginTop: '20px',
      marginBottom: '5px'
    },
    container: {
      marginTop: '25px'
    },
    input: {
      '& .MuiInputBase-input': {
        fontSize: defaultValues.isResponsive ? '8px' : '20px'
      },
      backgroundColor: color.palette.textFieldRed,
      width: '100%',
      borderRadius: 1,
      '& fieldset': { border: 'none' }
    }
  };

  const styles = {
    ...defaultStyles,
    ...style
  };

  return (
    <Box sx={styles.container}>
      <Text style={styles.title} variant={defaultValues.isResponsive ? 'medium' : 'body'}>
        {title}
      </Text>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          onBlur={onBlur}
          onChange={onChange}
          value={selValue && dayjs.utc(selValue)}
          timezone="UTC"
          sx={styles.input}
        />
      </LocalizationProvider>
      {errors && <Text variant="fieldError">{errors}</Text>}
    </Box>
  );
};
