import { createSlice } from '@reduxjs/toolkit';
import { defaultValues } from '../../../constants';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  isUser: null,
  userEmail: null,
  isCompanyRegisterd: null,
  isCompanyActive: null,
  rightSection: 'email',
  regNo: null,
  question: null,
  serviceId: null,
  languages: null,
  eventDetails: null,
  userVerified: null,
  companyDetails: {},
  isLoggedIn: false,
  servicesParams: {}
};

const serviceSlice = createSlice({
  initialState,
  name: 'service',
  reducers: {
    updateServicesParams(state, action) {
      state.servicesParams = action.payload;
    },
    enquiryOtpRequest(state, action) {
      state.isLoading = true;
      state.userEmail = action.payload.email;
    },
    enquiryOtpRequestSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.isUser = false;
      state.rightSection = 'otp';
    },
    enquiryOtpRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.isUser = action.payload.status_code === defaultValues.existingUser && true;
      state.userEmail = null;
    },
    setUserStatus(state) {
      state.isUser = null;
    },
    enquiryVerifyOtpRequest(state) {
      state.isLoading = true;
    },
    enquiryVerifyOtpRequestSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.isUser = null;
      state.userEmail = null;
      state.rightSection = 'registration';
    },
    enquiryVerifyOtpRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    resendEnquiryOtpRequest(state) {
      state.isLoading = true;
    },
    resendEnquiryOtpRequestSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    resendEnquiryOtpRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    searchCompanyRequest(state, action) {
      state.isLoading = true;
      state.regNo = action.payload.registration_number;
    },
    searchCompanySuccess(state) {
      state.isLoading = false;
      state.isCompanyRegisterd = false;
      state.isCompanyActive = true;
    },
    setIsCompanyRegistered(state, action) {
      state.isCompanyRegisterd = action.payload;
    },
    isRegisteredCompany(state, action) {
      state.isLoading = false;
      state.isCompanyRegisterd = true;
      state.companyDetails = action.payload.company_detail;
      state.errorMessage = action.payload.status_code;
    },
    searchCompanyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.regNo = null;
    },
    setCompanyActive(state, action) {
      state.isCompanyActive = action.payload;
    },
    registerCompanyRequest(state) {
      state.isLoading = true;
    },
    registerCompanySuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.regNo = null;
      state.rightSection = 'selectService';
    },
    registerCompanyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    submitEnquiryRequest(state) {
      state.isLoading = true;
    },
    submitEnquirySuccess(state, action) {
      state.isLoading = false;
      state.successMessage = state.isLoggedIn
        ? defaultValues.postLoginStatusCode
        : action.payload.status_code;
      state.rightSection = 'email';
      state.question = [];
      state.serviceId = null;
      state.languages = null;
      state.eventDetails = null;
    },
    submitEnquiryFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.question = [];
      state.serviceId = null;
      state.languages = null;
      state.eventDetails = null;
    },
    selectService(state, action) {
      state.languages = action.payload.languages;
      state.serviceId = action.payload.serviceId;
      state.question = action.payload.question;
      state.rightSection = 'eventDetails';
      state.eventDetails = null;
    },
    getCommonQuestionRequest(state, action) {
      state.rightSection = 'commonQuestion';
      state.eventDetails = action.payload;
    },
    resetServiceMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    setRightSection(state, action) {
      state.rightSection = action.payload;
    },
    resetRightSection(state) {
      state.rightSection = 'email';
      state.userEmail = null;
      state.question = null;
      state.serviceId = null;
      state.languages = null;
      state.eventDetails = null;
      state.isCompanyRegisterd = null;
      state.regNo = null;
    },
    resetCompanyStatus(state) {
      state.isCompanyRegisterd = null;
    },
    supplierRegistrationRequest(state, action) {
      state.isLoading = true;
      state.userEmail = action.payload.email_id;
    },
    supplierRegistrationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    supplierRegistrationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },

    supplierVerificationRequest(state) {
      state.isLoading = true;
    },
    supplierVerificationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.userEmail = null;
      state.userVerified = true;
    },
    supplierVerificationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    supplierResendOtpRequest(state) {
      state.isLoading = true;
    },
    supplierResendOtpSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    supplierResendOtpFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    associateCompanyRequest(state) {
      state.isLoading = true;
    },
    associateCompanySuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.companyDetails = {};
      state.rightSection = 'selectService';
      state.regNo = null;
      state.isCompanyRegisterd = null;
    },
    associateCompanyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    postLoginServiceRequest(state, action) {
      state.rightSection = action.payload;
      state.isLoggedIn = true;
    },
    resetLoggedInStatus(state) {
      state.isLoggedIn = false;
    }
  }
});

export const {
  enquiryOtpRequest,
  enquiryOtpRequestSuccess,
  enquiryOtpRequestFailure,
  enquiryVerifyOtpRequest,
  enquiryVerifyOtpRequestFailure,
  enquiryVerifyOtpRequestSuccess,
  resendEnquiryOtpRequest,
  resendEnquiryOtpRequestFailure,
  resendEnquiryOtpRequestSuccess,
  registerCompanyRequest,
  registerCompanySuccess,
  registerCompanyFailure,
  resetServiceMessages,
  searchCompanyFailure,
  searchCompanyRequest,
  searchCompanySuccess,
  setCompanyActive,
  submitEnquiryRequest,
  submitEnquirySuccess,
  submitEnquiryFailure,
  setUserStatus,
  setRightSection,
  resetCompanyStatus,
  selectService,
  getCommonQuestionRequest,
  resetRightSection,
  supplierRegistrationRequest,
  supplierRegistrationSuccess,
  supplierRegistrationFailure,
  supplierVerificationRequest,
  supplierVerificationSuccess,
  supplierVerificationFailure,
  supplierResendOtpRequest,
  supplierResendOtpSuccess,
  supplierResendOtpFailure,
  isRegisteredCompany,
  associateCompanySuccess,
  associateCompanyRequest,
  associateCompanyFailure,
  postLoginServiceRequest,
  resetLoggedInStatus,
  updateServicesParams,
  setIsCompanyRegistered
} = serviceSlice.actions;

export default serviceSlice.reducer;
