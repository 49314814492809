export const findOutCardStyles = {
  container: {
    width: '100%',
    padding: '2%',
    margin: '1%',
    boxSizing: 'border-box'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'spacebetween'
  },
  iconContainer: {
    maxWidth: '100px',
    maxheight: '100px',
    width: '20%',
    height: '20%'
  },
  icon: {
    width: '100%',
    height: '100%'
  }
};
