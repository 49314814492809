import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import { signInStyles } from './signInPageStyles';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { loginSchema } from '../../utils/schemas';
import { Text, TextInput, UIButton } from '../../ui-kit';
import logo from '../../assets/icons/logo.png';
import close from '../../assets/icons/close.png';
import iconOne from '../../assets/icons/Vector 3.png';
import iconTwo from '../../assets/icons/Vector 4.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmailVerified,
  loginError,
  loginLoadingStatus,
  loginSuccessMsg
} from '../../services/redux/selectors';
import {
  loginRequest,
  otpResendRequest,
  resetMessages,
  verificationRequest
} from '../../services/redux/slices';
import { toast } from 'react-toastify';
import { resetMessge } from '../../utils';
import { Otp } from '../../components';
import { generateToken } from '../../notification/firebase';

export const SignInPage = (props) => {
  const { manageLogin } = props;

  const dispatch = useDispatch();
  const emailVerified = useSelector(isEmailVerified);
  const successMessage = useSelector(loginSuccessMsg);
  const errorMessage = useSelector(loginError);
  const loading = useSelector(loginLoadingStatus);

  const [otp, setOtp] = useState('');
  const [otpSend, setOtpSend] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [fcmToken, setFcmToken] = useState('');
  const [buttonTitle, setButtonTitle] = useState(defaultValues.listConstants.Login);

  useEffect(() => {
    if (emailVerified) {
      setButtonTitle(defaultValues.listConstants.Verify);
      setOtpSend(true);
    }
    tokenHandle();
  }, [emailVerified]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetMessages()));
  }, [successMessage, errorMessage]);

  const tokenHandle = useCallback(() => {
    generateToken().then((res) => setFcmToken(res));
  }, []);

  const { values, handleBlur, handleChange, touched, isValid, errors } = useFormik({
    initialValues: { email: '' },
    validationSchema: loginSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true
  });

  const handleButtonclick = (title) => {
    setOtpSend(false);
    const payload = {};
    if (title === defaultValues.listConstants.Login) {
      payload.email = values.email;
      dispatch(loginRequest(payload));
    } else {
      payload.email = values.email;
      payload.otp = otp;
      payload.fcm_token = fcmToken;
      dispatch(verificationRequest(payload));
    }
  };

  const handleOtpChange = (val) => {
    if (!isNaN(Number(val))) {
      setOtp(val);
      setOtpErrorMessage('');
    } else if (!otp) {
      setOtpErrorMessage(getErrorMessage('E-10006'));
    }
  };

  const handleOtpResend = () => {
    dispatch(otpResendRequest({ email: values.email }));
  };

  const handleOtpBlur = () => {
    if (otp.length !== 6) {
      setOtpErrorMessage(getErrorMessage('E-10006'));
    }
  };

  const handleOtpSend = (val) => {
    setOtpSend(val);
  };

  const handleEnter = (e) => {
    if (e.key === defaultValues.listConstants.Enter) {
      e.preventDefault();
    }
  };

  return (
    <Box sx={signInStyles.container}>
      <Box sx={signInStyles.closeContainer} onClick={manageLogin}>
        <img style={signInStyles.closeIcon} src={close} alt="icon" />
      </Box>

      <Box sx={signInStyles.logoContainer}>
        <img style={signInStyles.logo} src={logo} alt="logo" />
      </Box>
      <Text variant="header" style={signInStyles.headingPart1}>
        {defaultValues.loginHeading1}
        <span style={signInStyles.headingPart2}>{defaultValues.loginHeading2}</span>
      </Text>

      {buttonTitle === defaultValues.listConstants.Verify && (
        <Text style={signInStyles.otpNotification} variant="fileName">
          {defaultValues.otpNotification}
        </Text>
      )}

      {buttonTitle === defaultValues.listConstants.Login ? (
        <Box sx={signInStyles.emailContainer}>
          <TextInput
            onKeyPress={handleEnter}
            required
            name="email"
            variant="outlined"
            placeholder="enter your email-id"
            value={values.email}
            onBlur={handleBlur}
            size={defaultValues.isResponsive ? 'small' : 'medium'}
            style={signInStyles.emailField}
            error={touched.email && errors.email}
            onChange={handleChange}
            errorStyle={signInStyles.errorField}
          />
        </Box>
      ) : (
        <Box sx={signInStyles.otpContainer}>
          <Otp
            otp={otp}
            onComplete={handleEnter}
            onChange={handleOtpChange}
            onBlur={handleOtpBlur}
            otpErrorMessage={otpErrorMessage}
            onResendClick={handleOtpResend}
            otpSend={otpSend}
            handleOtpSend={handleOtpSend}
            errorStyle={signInStyles.errorField}
          />
        </Box>
      )}

      <UIButton
        disabled={
          buttonTitle === defaultValues.listConstants.Login ? !isValid : !otp || otpErrorMessage
        }
        style={signInStyles.loginButton}
        size={defaultValues.isResponsive ? 'small' : 'medium'}
        title={buttonTitle}
        onClick={() => handleButtonclick(buttonTitle)}
        isLoading={!otpSend && loading}
        loaderSize={defaultValues.isResponsive && 15}
      />
      <img style={signInStyles.vectorIconOne} src={iconOne} alt="icon" />
      <img style={signInStyles.vectorIconTwo} src={iconTwo} alt="icon" />
    </Box>
  );
};
