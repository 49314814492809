import { Box } from '@mui/material';
import { TextInput } from '../text-field';
import { color } from '../../theme';
import { defaultValues } from '../../constants';
import { UIButton } from '../button';

export const UITextInputWithButton = ({
  name,
  value,
  placeholder,
  error,
  errorStyle,
  inputStyle,
  onBlur,
  setFieldTouched,
  setFieldError,
  onChange,
  onAdd,
  btnTitle
}) => {
  const UIStyles = {
    input: {
      width: '100%',
      height: '100%',
      flex: 1,
      backgroundColor: color.palette.textFieldRed,
      fontSize: defaultValues.isResponsive ? '14px' : '24px',
      '& .MuiTextField-root': {
        border: 'none'
      },
      '& fieldset': { border: 'none' },
      '& .MuiInputBase-root': {
        width: defaultValues.isResponsive ? 'calc(100% - 70px)' : 'calc(100% - 140px)'
      },
      ...inputStyle
    },
    errorField: {
      color: color.palette.primaryRed,
      marginTop: '3px',
      fontSize: '14px',
      ...errorStyle
    },
    addButton: (value) => ({
      color: !value ? color.palette.lightGray : color.palette.primaryWhite,
      backgroundColor: value ? color.palette.primaryRed : color.palette.primaryDisabledRed,
      fontSize: defaultValues.isResponsive ? '10px' : '22px',
      padding: defaultValues.isResponsive ? '5px 12px' : '3px 40px',
      boxSizing: 'border-box',
      position: 'absolute',
      right: '6px',
      top: '7px'
    }),
    closeButton: {
      color: color.palette.primaryWhite,
      fontSize: defaultValues.isResponsive ? '10px' : '22px',
      padding: defaultValues.isResponsive ? '5px 12px' : '3px 40px',
      boxSizing: 'border-box'
    },
    container: {
      position: 'relative'
    }
  };
  return (
    <Box sx={UIStyles.container}>
      <TextInput
        name={name}
        style={UIStyles.input}
        size={defaultValues.isResponsive ? 'small' : 'medium'}
        value={value}
        placeholder={placeholder}
        error={error}
        errorStyle={UIStyles.errorField}
        onBlur={onBlur}
        setFieldTouched={setFieldTouched}
        setFieldError={setFieldError}
        onChange={onChange}
      />
      <UIButton
        style={UIStyles.addButton(value)}
        title={btnTitle}
        onClick={onAdd}
        disabled={!value}
      />
    </Box>
  );
};
