import React, { useEffect, useState } from 'react';
import { Avatar, Box, FormControl, MenuItem, Select, Toolbar, Menu } from '@mui/material';
import { headerStyles } from './headerStyles';
import { Text, UIButton } from '../../../ui-kit';
import logo from '../../../assets/images/logo.png';
import { defaultValues } from '../../../constants/defaultValues';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuthStatus,
  getCurrentPages,
  getCustomerStatus,
  getNotificationCount,
  getUserType,
  loginPageStatus,
  userInfo
} from '../../../services/redux/selectors/authSelector';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API, SCREEN_PATH } from '../../../constants';
import {
  dashboardDataRequest,
  logoutRequest,
  postLoginServiceRequest,
  setCurrentPage,
  setLoginPage
} from '../../../services/redux/slices';
import Badge from '@mui/material/Badge';
import { getDashboardType } from '../../../services/redux/selectors';

export const Header = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const isAuthenticated = useSelector(getAuthStatus);
  const activePage = useSelector(getCurrentPages);
  const isLogin = useSelector(loginPageStatus);
  const userType = useSelector(getUserType);
  const userData = useSelector(userInfo);
  const count = useSelector(getNotificationCount);
  const optionType = useSelector(getDashboardType);
  const isCustomerAdmin = useSelector(getCustomerStatus);

  const [isCompanyAssociated, setIsCompanyAssociated] = useState(null);
  const [currentPage, setCurrentPages] = useState('My Dashboard');
  const [profilePicture, setProfilePicture] = useState('');
  const [dashboard, setDashboard] = useState(false);
  const [option, setOption] = useState('monthly');
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState('');
  const [menu, setMenu] = useState(defaultValues.superAdminMenuItemsWithLogout);
  const [sOpen, setSOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const timestamp = new Date().getTime();

  useEffect(() => {
    if (location.pathname === SCREEN_PATH.ADMIN_DASHBOARD) {
      setDashboard(true);
      dispatch(setCurrentPage({ menu: 'My Dashboard' }));
    }
    if (activePage) {
      setCurrentPages(activePage);
    }
    if (props.currentPage) {
      setCurrentPages(props.currentPage);
    }

    if (optionType) {
      setOption(optionType);
    }
  }, [activePage, optionType]);

  useEffect(() => {
    if (userData && userData.first_name && userData.last_name) {
      setName(userData.first_name + ' ' + userData.last_name);
    } else {
      setName('no name available');
    }
    if (userData && userData.photo_url) {
      setProfilePicture(
        `${API.baseURL[API.currentEnv]}${userData.photo_url}?timestamp=${timestamp}`
      );
    } else {
      setProfilePicture('');
    }

    if (userData && userData.is_company_associated) {
      setIsCompanyAssociated(userData.is_company_associated);
    }
  }, [userData]);

  useEffect(() => {
    if (count >= 0) {
      setNotificationCount(count);
    }
  }, [count]);

  useEffect(() => {
    if (userType === defaultValues.superAdmin) {
      setMenu(defaultValues.superAdminMenuItemsWithLogout);
    }
    if (defaultValues.userRole.admin) {
      setMenu(defaultValues.superAdminMenuItemsWithLogout.filter((item) => item.id !== 5));
    }
    if (userType === defaultValues.supplier) {
      setMenu(defaultValues.supplierMenuWithLogout);
    }
    if (userType === defaultValues.customer) {
      if (!isCustomerAdmin) {
        setMenu(
          defaultValues.customerMenuWithLogout.filter((item) => item.id !== 3 && item.id !== 4)
        );
      } else {
        setMenu(defaultValues.customerMenuWithLogout);
      }
    }
    if (userType === defaultValues.userRole.projectManger) {
      setMenu(defaultValues.PMMenuWithLogout);
    }
  }, [userType]);

  const handleLogin = () => {
    dispatch(setLoginPage(!isLogin));
  };

  const handleChange = (event) => {
    setOption(event.target.value);
    dispatch(dashboardDataRequest({ type: event.target.value }));
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    dispatch(logoutRequest());
    dispatch({ type: 'USER_LOGOUT' });
    localStorage.clear();
    navigation(SCREEN_PATH.HOME);
  };

  const handleSclose = () => {
    setSOpen(false);
  };

  const handleSopen = () => {
    setSOpen(!sOpen);
  };

  return (
    <Box position="static" style={headerStyles.container}>
      <Box sx={headerStyles.subContainer(isAuthenticated)}>
        <Toolbar
          disableGutters
          sx={headerStyles.toolbar(isAuthenticated, dashboard, userType, props.currentPage)}>
          {!isAuthenticated ? (
            <Box sx={headerStyles.logoContainer}>
              <img src={logo} alt="logo" style={headerStyles.logoStyles} />
            </Box>
          ) : (userType === defaultValues.userRole.superAdmin ||
              userType === defaultValues.userRole.admin) &&
            dashboard ? (
            <FormControl sx={headerStyles.dropDown} size="small">
              <Select value={option} onChange={handleChange}>
                {defaultValues.selectOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Text variant={defaultValues.isResponsive ? 'smallBold' : 'menuTextBold'}>
              {currentPage}
            </Text>
          )}

          <Box sx={headerStyles.signInbuttonContainer(isAuthenticated)}>
            {isAuthenticated ? (
              <>
                {userType === defaultValues.customer &&
                  dashboard &&
                  !defaultValues.isResponsive && (
                    <UIButton
                      title={'Book a service'}
                      style={headerStyles.customerBtn}
                      size="medium"
                      onClick={() => {
                        if (isCompanyAssociated) {
                          dispatch(postLoginServiceRequest('selectService'));
                        } else {
                          dispatch(postLoginServiceRequest('registration'));
                        }
                        navigation(SCREEN_PATH.ENQUIRE);
                      }}
                    />
                  )}

                <Badge badgeContent={notificationCount} color="error" sx={headerStyles.badge}>
                  <NotificationsIcon
                    sx={headerStyles.marginBtw(defaultValues.isResponsive)}
                    onClick={() => navigation(SCREEN_PATH.NOTIFICATION)}
                  />
                </Badge>
                {profilePicture ? (
                  <Avatar
                    alt="profile"
                    src={profilePicture}
                    sx={headerStyles.marginBtw(defaultValues.isResponsive)}
                  />
                ) : (
                  <Avatar sx={headerStyles.marginBtw(defaultValues.isResponsive)}>
                    {name &&
                      name
                        .split(' ')
                        .filter((part) => part)
                        .slice(0, 2)
                        .map((part) => part[0].toUpperCase())
                        .join('')}
                  </Avatar>
                )}

                {!defaultValues.isResponsive && (
                  <Text
                    variant={defaultValues.isResponsive ? 'responsiveText' : 'small'}
                    sx={headerStyles.marginBtw(defaultValues.isResponsive)}>
                    {name}
                  </Text>
                )}

                {defaultValues.isResponsive && (
                  <>
                    <ArrowDropDownIcon onClick={handleMenuOpen} />
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                      {menu.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={
                            item.menu === 'Logout'
                              ? onLogout
                              : item.menu === 'Book Service'
                                ? () => {
                                    if (isCompanyAssociated) {
                                      dispatch(postLoginServiceRequest('selectService'));
                                    } else {
                                      dispatch(postLoginServiceRequest('registration'));
                                    }
                                    navigation(SCREEN_PATH.ENQUIRE);
                                  }
                                : () => navigation(item.route)
                          }>
                          {item.menu}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </>
            ) : (
              <>
                <UIButton title="Sign in" style={headerStyles.signInBtn} onClick={handleLogin} />
                <UIButton
                  title="Book Now"
                  style={headerStyles.booknowBtn}
                  onClick={() => navigation(SCREEN_PATH.ENQUIRE)}
                />
              </>
            )}
          </Box>
        </Toolbar>

        {!isAuthenticated && (
          <Box>
            <Box sx={headerStyles.line} />
            <Toolbar disableGutters sx={headerStyles.navToolbar}>
              <Box sx={headerStyles.menuStyle}>
                {defaultValues.no_auth_header_options.map((item, index) => {
                  if (item.title === defaultValues.listConstants.Services) {
                    return (
                      <>
                        <Box sx={headerStyles.servicesContainer}>
                          <Text
                            variant="menuText"
                            key={index}
                            style={headerStyles.menuTextStyle}
                            onClick={() => handleSopen()}>
                            {item.title}
                          </Text>
                          <Select
                            open={sOpen}
                            onClose={handleSclose}
                            sx={headerStyles.services}
                            onClick={handleSopen}>
                            {item.options.map((item, idx) => (
                              <Link to={item.url} key={idx} style={headerStyles.link}>
                                <option className="Smenu" style={headerStyles.sMenu}>
                                  {item.title}
                                </option>
                              </Link>
                            ))}
                          </Select>
                        </Box>
                      </>
                    );
                  } else {
                    return (
                      <Text variant="menuText" key={index} style={headerStyles.menuTextStyle}>
                        <Link to={item.url} style={headerStyles.link}>
                          {item.title}
                        </Link>
                      </Text>
                    );
                  }
                })}
              </Box>
            </Toolbar>
            <Box sx={headerStyles.line} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
