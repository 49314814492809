import { Box, Input } from '@mui/material';
import searchIcon from '../../assets/icons/search.png';
import { Styles } from './style';
import { useEffect, useState } from 'react';

export const SearchBar = ({
  searchedVlaue,
  defaultValue,
  label,
  style,
  showSearchIcon = true,
  onRefresh
}) => {
  const [search, setSearch] = useState('');
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (changed) {
      const getData = setTimeout(() => {
        searchedVlaue && searchedVlaue(search);
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [search]);

  useEffect(() => {
    if (defaultValue) {
      setSearch(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onRefresh && Object.keys(onRefresh).length === 0) {
      setSearch('');
    }
  }, [onRefresh]);

  return (
    <Box sx={{ ...Styles.searchBarContainer, ...style }}>
      {showSearchIcon && <img style={Styles.searchIcon} src={searchIcon} />}
      <Input
        type="search"
        value={search}
        disableUnderline
        placeholder={label}
        sx={Styles.searchBar}
        onChange={(e) => {
          setSearch(e.target.value);
          setChanged(true);
        }}
      />
    </Box>
  );
};
