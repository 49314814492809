import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  jobs: [],
  applicants: [],
  currentPage: null,
  totalPage: null,
  jobParams: {},
  indicator: null,
  applicantCurrPage: 1,
  currJob: null
};

const jobSlice = createSlice({
  initialState,
  name: 'jobs',
  reducers: {
    updateJobParams(state, action) {
      state.jobParams = action.payload;
    },
    resetJobsMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
      state.indicator = null;
    },
    updateApplicantCurrentPage(state, action) {
      state.applicantCurrPage = action.payload.page;
    },
    updateCurrJob(state, action) {
      state.currJob = action.payload;
    },
    getJobRequest(state) {
      state.isLoading = true;
    },
    getJobSuccess(state, action) {
      state.isLoading = false;
      state.jobs = action.payload.data.jobs;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
    },
    getJobFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.jobs = [];
      state.currentPage = null;
      state.totalPage = null;
    },
    getJobApplicantRequest(state) {
      state.isLoading = true;
    },
    getJobApplicantSuccess(state, action) {
      state.isLoading = false;
      state.applicants = action.payload.applicants;
    },
    getJobApplicantFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.applicants = [];
    },
    applyJobRequest(state) {
      state.isLoading = true;
    },
    applyJobSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    applyJobFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    acceptRejectJobRequest(state) {
      state.isLoading = true;
    },
    acceptRejectJobSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.applicants = action.payload.applicants;
    },
    acceptRejectJobFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateJobStatusRequest(state) {
      state.isLoading = true;
    },
    updateJobStatusSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.indicator = true;
    },
    updateJobStatusFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  resetJobsMessages,
  getJobRequest,
  getJobSuccess,
  getJobFailure,
  getJobApplicantRequest,
  getJobApplicantSuccess,
  getJobApplicantFailure,
  applyJobRequest,
  applyJobSuccess,
  applyJobFailure,
  acceptRejectJobRequest,
  acceptRejectJobSuccess,
  acceptRejectJobFailure,
  updateJobParams,
  updateJobStatusRequest,
  updateJobStatusSuccess,
  updateJobStatusFailure,
  updateApplicantCurrentPage,
  updateCurrJob
} = jobSlice.actions;

export default jobSlice.reducer;
