import { createSlice } from '@reduxjs/toolkit';
// the commented codes are related to error messages and required later
const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  languages: [],
  services: {},
  skills: [],
  currencies: [],
  countries: [],
  states: [],
  masterList: [],
  timeZone: [],
  nextPage: null,
  currentPage: null,
  totalPage: null,
  serviceTotalPage: null,
  serviceCurrentPage: null,
  timeZoneTotalPage: null,
  timeZoneCurrentPage: null,
  languageNextPage: null,
  currenciesNextPage: null,
  timeZoneNextPage: null,
  countriesNextPage: null,
  skillsNextPage: null,
  masterListNextPage: null,
  indicator: false
};

const settingsSlice = createSlice({
  initialState,
  name: 'settings',
  reducers: {
    getLanguageRequest(state) {
      state.isLoading = true;
    },
    getLanguagesSuccess(state, action) {
      state.isLoading = false;
      state.languages = action.payload.data.languages;
      state.languageNextPage = action.payload.data.next_page;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
    },
    getLanguagesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.languages = [];
      state.languageNextPage = null;
      state.currentPage = null;
      state.totalPage = null;
    },
    addLanguageRequest(state) {
      state.isLoading = true;
    },
    addLanguageSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    addLanguageFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    editLanguageRequest(state) {
      state.isLoading = true;
    },
    editLanguageSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    editLanguageFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    serviceRequest(state) {
      state.isLoading = true;
    },
    serviceSuccess(state, action) {
      state.isLoading = false;
      state.errorMessage = null;
      state.successServicesMessage = null;
      state.services = action.payload.data;
      state.serviceTotalPage = action.payload.data.total_page;
      state.serviceCurrentPage = action.payload.data.current_page;
    },
    serviceFailure(state, action) {
      state.isLoading = false;
      state.successServicesMessage = null;
      state.errorMessage = action.payload.status_code;
    },
    addServiceRequest(state) {
      state.isLoading = true;
    },
    addServiceSuccess(state, action) {
      state.isLoading = false;
      state.successMessageAddServices = action.payload.status_code;
    },
    addServiceFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    pushServiceQaRequest(state) {
      state.isLoading = true;
    },
    pushServiceQaSuccess(state, action) {
      state.isLoading = false;
      state.successMessageAddServices = action.payload.status_code;
    },
    pushServiceQaFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getSkillsRequest(state) {
      state.isLoading = true;
    },
    getSkillRequestSuccess(state, action) {
      state.isLoading = false;
      state.skills = action.payload.data.skills;
      state.skillsNextPage = action.payload.data.next_page;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
    },
    getSkillsRequestFailure(state) {
      state.isLoading = false;
      // state.errorMessage = action.payload.status_code;
    },
    addSkillRequest(state) {
      state.isLoading = true;
    },
    addSkillRequestSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.errorMessage = null;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    addSkillRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    editSkillRequest(state) {
      state.isLoading = true;
    },
    editSkillRequestSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    editSkillRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCurrenciesRequest(state) {
      state.isLoading = true;
    },
    getCurrenciesSuccess(state, action) {
      state.isLoading = false;
      state.currencies = action.payload.data.currency;
      state.currenciesNextPage = action.payload.data.next_page;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
    },
    getCurrenciesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.currencies = [];
      state.currenciesNextPage = null;
      state.currentPage = null;
      state.totalPage = null;
    },
    addCurrencyRequest(state) {
      state.isLoading = true;
    },
    addCurrencySuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    addCurrencyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    editCurrencyRequest(state) {
      state.isLoading = true;
    },
    editCurrencySuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    editCurrencyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCountriesRequest(state) {
      state.isLoading = true;
    },
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload.data.country;
      state.countriesNextPage = action.payload.data.next_page;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
    },
    getCountriesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.countries = [];
      state.countriesNextPage = null;
      state.currentPage = null;
      state.totalPage = null;
    },
    addCountriesRequest(state) {
      state.isLoading = true;
    },
    addCountriesSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    addCountriesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    editCountriesRequest(state) {
      state.isLoading = true;
    },
    editCountriesSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    editCountriesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    addStateList(state, action) {
      state.states = action.payload;
    },
    pushStateRequest(state) {
      state.isLoading = true;
    },
    pushStateSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.states = action.payload.data.states;
    },
    pushStateFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },

    resetSettingsMessages(state) {
      state.successMessage = null;
      state.successMessageAddServices = null;
      state.successServicesMessage = null;
      state.errorMessage = null;
      state.successMessageAddServices = null;
      state.successServicesMessage = null;
      state.indicator = false;
    },
    masterListRequest(state) {
      state.isLoading = true;
    },
    getMasterListSuccess(state, action) {
      state.isLoading = false;
      state.masterList = action.payload.data;
    },
    getMasterListFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.masterList = [];
    },
    addMasterListRequest(state) {
      state.isLoading = true;
    },
    addMasterListSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.masterList = action.payload.data;
    },
    addMasterListFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getTimeZoneRequest(state) {
      state.isLoading = true;
    },
    timeZoneRequestSuccess(state, action) {
      state.isLoading = false;
      state.timeZone = action.payload.data.timezones;
      state.timeZoneNextPage = action.payload.data.next_page;
      state.timeZoneTotalPage = action.payload.data.total_page;
      state.timeZoneCurrentPage = action.payload.data.current_page;
    },
    timeZoneRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.timeZone = [];
      state.timeZoneNextPage = null;
      state.timeZoneTotalPage = null;
      state.timeZoneCurrentPage = null;
    },
    addTimeZoneRequest(state) {
      state.isLoading = true;
    },
    addTimeZoneRequestSuccess(state, action) {
      state.isLoading = false;
      state.timeZoneTotalPage = action.payload.data.total_page;
      state.timeZoneCurrentPage = action.payload.data.current_page;
      state.successMessage = action.payload.status_code;
      state.indicator = true;
    },
    addTimeZoneRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    editTimeZoneRequest(state) {
      state.isLoading = true;
    },
    editTimeZoneRequestSuccess(state, action) {
      state.isLoading = false;
      state.timeZoneTotalPage = action.payload.data.total_page;
      state.timeZoneCurrentPage = action.payload.data.current_page;
      state.successMessage = action.payload.status_code;
      state.indicator = true;
    },
    editTimeZoneRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  getLanguageRequest,
  getLanguagesSuccess,
  getLanguagesFailure,
  addLanguageFailure,
  addLanguageRequest,
  addLanguageSuccess,
  editLanguageRequest,
  editLanguageSuccess,
  editLanguageFailure,
  getCurrenciesRequest,
  getCurrenciesSuccess,
  getCurrenciesFailure,
  addCurrencyRequest,
  addCurrencySuccess,
  addCurrencyFailure,
  pushServiceQaRequest,
  pushServiceQaSuccess,
  pushServiceQaFailure,
  editCurrencyRequest,
  editCurrencySuccess,
  editCurrencyFailure,
  serviceRequest,
  serviceSuccess,
  serviceFailure,
  getSkillRequestSuccess,
  getSkillsRequest,
  getSkillsRequestFailure,
  addSkillRequest,
  addSkillRequestSuccess,
  addSkillRequestFailure,
  editSkillRequest,
  editSkillRequestSuccess,
  editSkillRequestFailure,
  getCountriesRequest,
  getCountriesSuccess,
  getCountriesFailure,
  addCountriesRequest,
  addCountriesSuccess,
  addCountriesFailure,
  editCountriesRequest,
  editCountriesSuccess,
  editCountriesFailure,
  addStateList,
  pushStateRequest,
  pushStateSuccess,
  pushStateFailure,
  resetSettingsMessages,
  masterListRequest,
  getMasterListSuccess,
  getMasterListFailure,
  addMasterListRequest,
  addMasterListSuccess,
  addMasterListFailure,
  addServiceRequest,
  addServiceSuccess,
  addServiceFailure,
  getTimeZoneRequest,
  timeZoneRequestSuccess,
  timeZoneRequestFailure,
  addTimeZoneRequest,
  addTimeZoneRequestFailure,
  addTimeZoneRequestSuccess,
  editTimeZoneRequest,
  editTimeZoneRequestSuccess,
  editTimeZoneRequestFailure
} = settingsSlice.actions;

export default settingsSlice.reducer;
