import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';
const responsive = defaultValues.isResponsive;

export const Styles = {
  services: {
    color: color.palette.gray,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word'
  },
  statusStyle: (status) => ({
    padding: responsive ? '4px' : '10px 20px',
    boxSizing: 'border-box',
    backgroundColor: status ? color.palette.statusLightGreen : color.palette.statusLightRed,
    color: status ? color.palette.buttonGreen : color.palette.buttonRed,
    borderRadius: '4px',
    textAlign: 'center',
    maxWidth: '120px',
    minWidth: responsive ? '80px' : '120px',
    fontSize: responsive ? '10px' : '16px'
  }),
  projectStatus: (status, role, type) => {
    return {
      color: status.color,
      backgroundColor: status.bgColor,
      borderRadius: '4px',
      textAlign: type === 'text' ? 'center' : 'left',
      width: responsive ? '120px' : '180px',
      fontSize: responsive ? '10px' : '14px',
      fontWeight: 600,
      boxShadow: 'none',
      padding:
        role === defaultValues.userRole.admin || role === defaultValues.userRole.superAdmin
          ? '0px'
          : '10px',
      '.MuiOutlinedInput-notchedOutline': { border: 0 },
      '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: 0
      },
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 0
      },
      '.MuiSvgIcon-root ': {
        fill: status.color
      }
    };
  },
  customIndStyle: (status) => ({
    padding: '8px 15px',
    boxSizing: 'border-box',
    backgroundColor:
      status === null
        ? color.palette.secondaryLightRed
        : status
          ? color.palette.statusLightGreen
          : color.palette.statusLightRed,
    color:
      status === null
        ? color.palette.secondaryRed
        : status
          ? color.palette.buttonGreen
          : color.palette.buttonRed,
    borderRadius: '4px',
    textAlign: 'center',
    maxWidth: '160px',
    minWidth: '60px',
    fontSize: responsive ? '10px' : '14px'
  }),
  projectStatusInd: (val) => ({
    color: val.color,
    backgroundColor: val.bgColor,
    borderRadius: '4px',
    textAlign: 'center',
    maxWidth: '200px',
    minWidth: '60px',
    padding: responsive ? '4px 8px' : '12px 20px',
    boxSizing: 'border-box',
    fontWeight: 600
  }),
  basicContainer: {
    width: responsive ? 'none' : '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  propic: { width: '40px', height: '40px' },
  basicTitle: (style) => ({
    color: color.palette.primaryBlack,
    ...style
  }),
  basicSubTitle: (style) => ({
    color: color.palette.gray,
    fontSize: responsive && '12px',
    ...style
  })
};
