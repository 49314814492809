import { Box, Checkbox, Divider, FormControlLabel, FormGroup } from '@mui/material';
import { Screen, Text, UIBackButton, UIButton } from '../../../ui-kit';
import { mInvitationStyles } from './styles';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  acceptRejectInvitationRequest,
  initiateChatRequest,
  projectInvitationRequest,
  resetChatMessages,
  resetSupplierMessages,
  setSelectedTab
} from '../../../services/redux/slices';
import {
  getProjectInvitations,
  getSupplierErrorMessage,
  getSupplierSuccessMessage,
  supplierLoadingStatus
} from '../../../services/redux/selectors/supplierSelector';
import { toast } from 'react-toastify';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import {
  ConvertedTime,
  getFormatDateNotUtc,
  getInviteStatus,
  getPhotoUrl,
  resetMessge
} from '../../../utils';
import { BasicProfileDetails, CustomModal } from '../../../components';
import { PdfViewer } from '../../../components/pdf-viewer';
import { CheckBox } from '@mui/icons-material';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
  getChatErrorMessage,
  getChatSuccessMessage,
  userInfo
} from '../../../services/redux/selectors';

export const ManageInvitation = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const containerRef = useRef();
  const projectInvitation = useSelector(getProjectInvitations);
  const successMessage = useSelector(getSupplierSuccessMessage);
  const errorMessage = useSelector(getSupplierErrorMessage);
  const isLoading = useSelector(supplierLoadingStatus);
  const chatSuccessMsg = useSelector(getChatSuccessMessage);
  const chatErrorMsg = useSelector(getChatErrorMessage);
  const userData = useSelector(userInfo);

  const [details, setDetails] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [invitation, setInvitation] = useState({});
  const [checked, setChecked] = useState(new Set());
  const [scrollCurr, setScrollCurr] = useState(0);
  const [open, setOpen] = useState(false);
  const [accept, setAccept] = useState(null);
  const [invitedUserId, setInvitedUserId] = useState('');
  const [userTimezone, setUserTimezone] = useState(null);

  useEffect(() => {
    dispatch(projectInvitationRequest({ type: 1 }));
  }, []);

  useEffect(() => {
    if (userData) {
      setUserTimezone(userData.timezone);
    }
  }, [userData]);

  useEffect(() => {
    setDetails(projectInvitation);
  }, [projectInvitation]);

  useEffect(() => {
    if (!isLoading && successMessage) {
      setCurrPage(1);
      dispatch(projectInvitationRequest());
      setChecked(new Set());
      handleClose();
    }
  }, [isLoading]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage !== 'E-10107' || chatErrorMsg) {
      toast.error(getErrorMessage(errorMessage || chatErrorMsg));
    }
    if (chatSuccessMsg && ['S-10111', 'S-10112'].includes(chatSuccessMsg)) {
      dispatch(setSelectedTab(1));
      navigation(SCREEN_PATH.CHAT_SCREEN, { state: { oppUserId: invitedUserId } });
    }
    resetMessge(() => dispatch(resetChatMessages()));
    resetMessge(() => dispatch(resetSupplierMessages()));
  }, [successMessage, errorMessage, chatSuccessMsg, chatErrorMsg]);

  const getTitle = (id) => {
    return defaultValues.inviteStatus.map((item) => {
      if (item.id === id) {
        return item.title;
      }
    });
  };

  const handleViewInvitations = (data) => {
    setCurrPage(2);
    setInvitation(data);
  };

  const handleCheckboxChange = (itemId) => {
    setChecked((prevChecked) => {
      const newChecked = new Set(prevChecked);

      if (newChecked.has(itemId)) {
        newChecked.delete(itemId);
      } else {
        newChecked.add(itemId);
      }

      return newChecked;
    });
  };

  const handleClose = () => {
    setOpen(false);
    setAccept(null);
  };

  const handleAcceptReject = (action) => {
    let payload = {
      invitation_id: invitation.invitation_id,
      action
    };
    dispatch(acceptRejectInvitationRequest(payload));
  };

  const handleScroll = (val) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth;
      const newPosition =
        val === 'prev' ? container.scrollLeft - scrollAmount : container.scrollLeft + scrollAmount;
      setScrollCurr(newPosition);

      container.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
    }
  };

  const getEndDate = (date) => {
    if (date) {
      return (
        <ConvertedTime
          time={date}
          originalTimeZoneName={invitation.timezone}
          targetTimeZoneName={userTimezone}
          style={mInvitationStyles.textClr}
        />
      );
    } else {
      return '';
    }
  };

  const handleAskQuestions = (card) => {
    setInvitedUserId(card.sender_id);
    dispatch(initiateChatRequest({ send_to: card.sender_id }));
  };

  if (currPage === 2) {
    return (
      <Screen
        showSideBar={!defaultValues.isResponsive}
        showHeader={defaultValues.isResponsive}
        currentPage={'Manage Invitations'}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={mInvitationStyles.container}>
          <Box sx={mInvitationStyles.contentContainer}>
            <Box sx={mInvitationStyles.backContainer}>
              <UIBackButton
                buttonStyle={mInvitationStyles.backBtn}
                backRoot={mInvitationStyles.backRoot}
                onClick={() => setCurrPage(1)}
              />
            </Box>
            <Box sx={mInvitationStyles.cardsContainer2}>
              <Box sx={mInvitationStyles.inviteCardStyle}>
                <Text style={mInvitationStyles.title}>Project Details</Text>
                <Divider />
                <BasicProfileDetails
                  title={`${invitation.project_name} ( ${invitation.project_id} )`}
                  subTitle={`${getFormatDateNotUtc(
                    invitation.received_date,
                    'DD-MM-YYYY hh:mm A'
                  )}`}
                  titleStyle={mInvitationStyles.basicTitle}
                />
                <BasicProfileDetails
                  title={'Timeline'}
                  subTitle={
                    <>
                      <ConvertedTime
                        time={invitation.start_date}
                        originalTimeZoneName={invitation.timezone}
                        targetTimeZoneName={userTimezone}
                        style={mInvitationStyles.textClr}
                      />
                      {getEndDate(invitation && invitation.end_date)}
                    </>
                  }
                  titleStyle={mInvitationStyles.basicTitle}
                />

                <BasicProfileDetails
                  title={'Service Required'}
                  subTitle={invitation.service.title}
                  titleStyle={mInvitationStyles.basicTitle}
                />
                <Box>
                  <Text style={mInvitationStyles.basicTitle}>Project Description</Text>
                  <Text>
                    {invitation && (
                      <span style={mInvitationStyles.spanText}>
                        {invitation.project_description}
                      </span>
                    )}
                  </Text>
                </Box>
              </Box>
              <Box sx={mInvitationStyles.inviteCardStyle}>
                <Text style={mInvitationStyles.title}>Requirements</Text>
                <Divider />
                <Text style={mInvitationStyles.basicTitle}>
                  Spoken languages -
                  {invitation &&
                    invitation.spoken_languages &&
                    invitation.spoken_languages.map((item, idx) => (
                      <span style={mInvitationStyles.spanText} key={idx}>
                        {` ${item.language}${
                          idx === invitation.spoken_languages.length - 1 ? '' : ', '
                        }`}
                      </span>
                    ))}
                </Text>
                <Text style={mInvitationStyles.basicTitle}>
                  Captioning languages
                  {invitation &&
                    invitation.captioning_languages &&
                    invitation.captioning_languages.map((item, idx) => (
                      <span style={mInvitationStyles.spanText} key={idx}>
                        {` ${item.language}${
                          idx === invitation.captioning_languages.length - 1 ? '' : ', '
                        }`}
                      </span>
                    ))}
                </Text>
                <Text style={mInvitationStyles.basicTitle}>
                  Timezone -
                  {invitation && invitation.timezone && (
                    <span style={mInvitationStyles.spanText}>{userTimezone}</span>
                  )}
                </Text>
                <Text
                  style={mInvitationStyles.link}
                  onClick={() => window.open('https://www.worldtimebuddy.com/', '_blank')}>
                  Check event time in your timezone
                </Text>
                <Text style={mInvitationStyles.basicTitle}>
                  Translation required -
                  {invitation && (
                    <span style={mInvitationStyles.spanText}>
                      {invitation.translation_required === 1
                        ? 'Yes'
                        : invitation.translation_required === 2
                          ? 'No'
                          : 'Not applicable'}
                    </span>
                  )}
                </Text>

                <Text style={mInvitationStyles.basicTitle}>
                  Event duration -
                  {invitation && (
                    <span style={mInvitationStyles.spanText}>{` ${invitation.event_duration} ${
                      invitation.event_duration === 1 ? 'hour' : 'hours'
                    }`}</span>
                  )}
                </Text>
                {invitation.schedule_details.length > 0 && (
                  <Box>
                    <Text style={mInvitationStyles.basicTitle}>Schedule breakup</Text>
                    {invitation.schedule_details &&
                      invitation.schedule_details.map((item, index) => (
                        <Text key={index}>
                          <span style={mInvitationStyles.spanText}>{item}</span>
                        </Text>
                      ))}
                  </Box>
                )}
              </Box>
              <Box sx={mInvitationStyles.inviteCardStyle}>
                <Text style={mInvitationStyles.title}>Briefing document</Text>
                <Divider />

                <PdfViewer
                  url={invitation.breifing_doc && `${getPhotoUrl(invitation.breifing_doc)}`}
                  containerStyles={mInvitationStyles.pdfRoot}
                  subContainer={mInvitationStyles.pdfSub}
                />
              </Box>
            </Box>

            <Box sx={mInvitationStyles.checkContainer}>
              <Text style={mInvitationStyles.title}>Checklist</Text>
              <Text style={mInvitationStyles.spanText}>{defaultValues.mInvitationDesc}</Text>
              <Box>
                {invitation &&
                  invitation.checklist &&
                  invitation.checklist.length > 0 &&
                  invitation.checklist.map((item, idx) => (
                    <Box key={idx}>
                      <FormGroup>
                        <FormControlLabel
                          sx={mInvitationStyles.formControl}
                          control={
                            <Checkbox
                              checked={checked.has(item.id)}
                              sx={mInvitationStyles.checkBox}
                              onChange={() => handleCheckboxChange(item.id)}
                              icon={<CheckBoxOutlineBlankIcon style={mInvitationStyles.icon} />}
                              checkedIcon={<CheckBox style={mInvitationStyles.checkedIcon} />}
                            />
                          }
                          label={
                            <Text style={mInvitationStyles.checkText}>{item.checklistItem}</Text>
                          }
                        />
                      </FormGroup>
                    </Box>
                  ))}
              </Box>
              <Box sx={mInvitationStyles.btnSuperCont}>
                <Box sx={mInvitationStyles.btsContainer}>
                  <UIButton
                    style={mInvitationStyles.reject}
                    title={defaultValues.listConstants.Reject}
                    onClick={() => {
                      setAccept(false);
                      setOpen(true);
                    }}
                  />
                  <UIButton
                    disabled={checked.size < invitation.checklist.length}
                    style={mInvitationStyles.accept}
                    title={defaultValues.listConstants.Accept}
                    onClick={() => {
                      setAccept(true);
                      setOpen(true);
                    }}
                  />
                </Box>
                {invitation.status === 1 && (
                  <Text
                    sx={mInvitationStyles.askQuestions}
                    onClick={() => handleAskQuestions(invitation)}>
                    Ask a question
                  </Text>
                )}
              </Box>
            </Box>
            <CustomModal
              title={
                accept
                  ? defaultValues.userInvitationModal(defaultValues.listConstants.accept).title
                  : defaultValues.userInvitationModal(defaultValues.listConstants.reject).title
              }
              message={
                accept
                  ? defaultValues.userInvitationModal(defaultValues.listConstants.accept).message
                  : defaultValues.userInvitationModal(defaultValues.listConstants.reject).message
              }
              open={open}
              btnType={
                accept
                  ? defaultValues.userInvitationModal(defaultValues.listConstants.accept).btn
                  : defaultValues.userInvitationModal(defaultValues.listConstants.reject).btn
              }
              onClose={handleClose}
              onConfirm={() =>
                handleAcceptReject(
                  accept ? defaultValues.listConstants.accept : defaultValues.listConstants.reject
                )
              }
              onCancel={handleClose}
              cancelStyle={mInvitationStyles.modalCancel}
              confirmStyle={accept ? mInvitationStyles.modalAccept : mInvitationStyles.modalReject}
              modalType={
                accept ? defaultValues.listConstants.accept : defaultValues.listConstants.reject
              }
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Screen>
    );
  }
  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive}
      currentPage={'Manage Invitations'}>
      <Box sx={mInvitationStyles.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={mInvitationStyles.contentContainer}>
          <Box sx={mInvitationStyles.box1}>
            <Text style={mInvitationStyles.title}>Current Invitations</Text>
            <Box sx={mInvitationStyles.subBox1(details && details.active_invitations)}>
              <ArrowBackIosNewOutlinedIcon
                onClick={() => handleScroll('prev')}
                fontSize={defaultValues.isResponsive ? 'small' : 'large'}
                color="action"
                style={mInvitationStyles.leftArrow(
                  scrollCurr,
                  containerRef.current && containerRef.current.scrollWidth,
                  containerRef.current && containerRef.current.offsetWidth
                )}
              />
              <Box ref={containerRef} sx={details && mInvitationStyles.cardsContainer}>
                {details && details.active_invitations && details.active_invitations.length > 0 ? (
                  details.active_invitations.map((card, index) => (
                    <Box key={index} sx={mInvitationStyles.cardStyle}>
                      <BasicProfileDetails
                        title={'Service Required'}
                        subTitle={card.service.title}
                        titleStyle={mInvitationStyles.basicTitle}
                      />
                      <Text sx={mInvitationStyles.subTitle}>
                        Invitation received on{' '}
                        {getFormatDateNotUtc(card.received_date, 'DD-MM-YYYY hh:mm A')}
                      </Text>
                      <Box sx={mInvitationStyles.activeBtnCont}>
                        <UIButton
                          style={mInvitationStyles.buttonStyle}
                          onClick={() => handleViewInvitations(card)}
                          variant="contained"
                          title={'view invitations'}
                        />

                        {card.status === 1 && (
                          <UIButton
                            style={mInvitationStyles.buttonStyle}
                            onClick={() => handleAskQuestions(card)}
                            variant="contained"
                            title={'Ask questions'}
                          />
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Text style={mInvitationStyles.empty}>{getErrorMessage('E-10107')}</Text>
                )}
              </Box>
              <ArrowForwardIosOutlinedIcon
                onClick={() => handleScroll('next')}
                fontSize={defaultValues.isResponsive ? 'small' : 'large'}
                color="action"
                style={mInvitationStyles.rightArrow(
                  scrollCurr,
                  containerRef.current && containerRef.current.scrollWidth,
                  containerRef.current && containerRef.current.offsetWidth
                )}
              />
            </Box>
          </Box>
          <Box sx={mInvitationStyles.box2}>
            <Text style={mInvitationStyles.title}>Invitation history</Text>
            <Box sx={mInvitationStyles.historyContainer}>
              {details && details.invitation_history && details.invitation_history.length > 0 ? (
                details.invitation_history.map((card, index) => (
                  <Box key={index} sx={mInvitationStyles.cardStyle2}>
                    <Box sx={mInvitationStyles.statusContainer}>
                      <BasicProfileDetails
                        title={'Service Required'}
                        subTitle={card.service.title}
                        titleStyle={mInvitationStyles.basicTitle}
                      />
                      <Text sx={mInvitationStyles.status(getInviteStatus(card.status))}>
                        {getTitle(card.status)}
                      </Text>
                    </Box>

                    <Text sx={mInvitationStyles.subTitle}>
                      Invitation received on{' '}
                      {getFormatDateNotUtc(card.received_date, 'DD-MM-YYYY hh:mm A')}
                    </Text>
                  </Box>
                ))
              ) : (
                <Text style={mInvitationStyles.empty}>{getErrorMessage('E-10107')}</Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Screen>
  );
};
