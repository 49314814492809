import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const projectsModalStyle = {
  container: {
    position: 'absolute',
    top: responsive ? '50%' : '70%',
    left: responsive ? '50%' : '70%',
    transform: responsive ? 'translate(-50%, -50%)' : 'translate(-70%, -70%)',
    width: responsive ? '85%' : '65%',
    backgroundColor: color.primaryBackground,
    borderRadius: '5px',
    boxShadow: 24,
    padding: responsive ? 2 : '15px 60px 15px 60px',
    boxSizing: 'border-box',
    minHeight: '60vh',
    border: 'none'
  },
  headingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left'
  },
  heading: {
    fontSize: responsive ? '20px' : '35px',
    textAlign: responsive ? 'left' : 'none',
    marginBottom: responsive ? '20px' : 'none'
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginBottom: responsive ? '10px' : '0px'
  },
  divider: {
    width: '100%',
    backgroundColor: color.primaryBorder,
    marginTop: !responsive && '10px'
  },
  buttonContainer: {
    width: '100%',
    height: '65px',
    display: 'flex',
    justifyContent: 'space-evenly',
    mt: 5,
    gap: '10px'
  },
  cancelButton: {
    width: responsive && '80%',
    backgroundColor: color.palette.primaryWhite,
    color: color.palette.red,
    fontSize: responsive ? '14px' : '22px',
    fontWeight: 600,
    height: responsive ? '40px' : '55px',
    minWidth: responsive ? 'fit-content' : '200px'
  },
  button: (disabled) => ({
    backgroundColor: disabled ? color.palette.primaryDisabledRed : color.palette.primaryBlack,
    color: disabled ? color.palette.lightGray : color.palette.primaryWhite,
    width: responsive ? '80%' : 'fit-content',
    '&:hover': {
      backgroundColor: color.palette.primaryRed
    },
    fontSize: responsive ? '14px' : '22px',
    fontWeight: 600,
    height: responsive ? '40px' : '55px',
    minWidth: responsive ? 'fit-content' : '200px'
  }),
  closeButton: {
    maxWidth: '100%',
    maxHeight: responsive ? '40px' : '50px',
    backgroundColor: color.palette.primaryRed,
    borderRadius: '50%',
    position: 'absolute',
    top: '-25px',
    right: responsive ? '-20px' : '-25px',
    cursor: 'pointer'
  },

  icon: { fontSize: '25px' },
  checkedIcon: {
    fontSize: '25px'
  },
  pdfViewer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'fit-content',
    marginTop: '20px'
  },
  pdfIcon: {
    height: '50px',
    width: '50px',
    cursor: 'pointer',
    position: 'absolute',
    top: responsive ? 5 : 80,
    right: responsive ? 5 : 20
  },
  pdfViewSubcontainer: {
    width: responsive ? '100%' : '80%',
    height: md ? '40vh' : '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '8px'
  },
  errorContainer: {
    height: '250px',
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  objectPdf: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px'
  },
  altText: { textAlign: 'center', margin: '0px 10px' }
};
