import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';
const isRes = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const Styles = {
  container: {
    height: isRes ? '100%' : md ? '120%' : '100%',
    p: 2,
    boxSizing: 'border-box',
    marginBottom: isRes ? '30px' : md ? '100px' : 'none'
  },
  cardsSectionContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardContainer: {
    width: isRes ? '44%' : '23%',
    m: 1,
    boxShadow: 3
  },
  cardSubContainer: { display: 'flex', height: '70%' },
  cardSubContainerLeftSection: {
    width: '70%',
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box'
  },
  cardSubContainerRightSection: {
    width: '30%',
    pr: 2,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  iconStyle: { cursor: 'pointer', width: isRes && '20px' },
  cardSecondaryContainer: (index) => ({
    backgroundColor: color.palette.cardColors[index % color.palette.cardColors.length],
    height: '30%',
    display: 'flex',
    alignItems: 'center',
    pl: 2
  }),
  cardTitle: { color: color.primaryBackground },

  chartSectionContainer: {
    display: 'flex',
    flexDirection: isRes ? 'column' : 'row',
    height: isRes ? '60%' : '38%',
    mt: isRes ? 1 : 3
  },
  chartLeftSectionContainer: {
    height: isRes ? '50%' : 'auto',
    width: isRes ? '100%' : '40%'
  },
  chartRightSectionContainer: {
    width: isRes ? '100%' : '60%',
    height: isRes ? '50%' : 'auto',
    boxShadow: 3,
    borderRadius: 1,
    mt: isRes && 1,
    ml: !isRes && 1
  },
  secondChartSectionContainer: {
    display: 'flex',
    flexDirection: isRes ? 'column-reverse' : 'row',
    height: isRes ? '70%' : md ? '50%' : '38%',
    mt: 3
  },
  barChartContainer: {
    width: isRes ? '100%' : '60%',
    height: isRes ? '50%' : '100%',
    boxShadow: 3,
    borderRadius: 1,
    mt: isRes && 1,
    mr: !isRes && 2
  },
  pieChartContainer: { height: '65%' },
  registrationInfoContainer: {
    height: '30%',
    width: '100%',
    boxShadow: 3,
    mt: 1.5,
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: 1
  },
  titleStyle: { pt: 1, pl: 2 },
  textStyle: { color: color.palette.lightBlue },
  pieChartColors: color.palette.pieChartColors,
  custonPieChartColor: color.palette.customPieChartColors,
  subtitle: (index) => ({
    color: color.palette.cardColors[index % color.palette.cardColors.length]
  }),
  updatesCardContainer: {
    width: isRes ? '100%' : '37%',
    boxSizing: 'border-box',
    boxShadow: 2,
    p: 1,
    height: isRes ? '210px' : '255px',
    borderRadius: 2,
    my: isRes && 1
  },
  commonDB: {
    backgroundColor: color.primaryBackground,
    padding: '2%',
    boxSizing: 'border-box',
    minHeight: '100%'
  },
  commonChart: {
    p: !isRes && 2,
    display: 'flex',
    flexDirection: isRes ? 'column' : 'row',
    justifyContent: !isRes && 'space-between',
    mb: isRes && 1
  },
  commonBar: (a, b) => ({
    width: a && b && !isRes ? '60%' : '100%',
    boxShadow: isRes && 1,
    borderRadius: isRes && 1
  })
};
