import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Text } from '../text';
import { Close } from '@mui/icons-material';
import { color } from '../../theme/color';
import { defaultValues } from '../../constants';

export const TextInput = (props) => {
  const isRes = defaultValues.isResponsive;
  const {
    id,
    ref,
    required = false,
    name,
    variant = 'outlined',
    label,
    value,
    onChange,
    error,
    errorStyle,
    style,
    size,
    onBlur,
    lines = 1,
    disabled,
    showClear = false,
    onClear,
    allowOnlyAlphabets = false,
    allowOnlyNumbers = false,
    textFieldContainerStyle,
    maxRows = 1,
    ...rest
  } = props;

  const handleAlphabeticChange = (e) => {
    const inputValue = e.target.value;
    if (/^[A-Za-z]*$/.test(inputValue)) {
      onChange(e);
    }
  };

  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*$/.test(inputValue)) {
      onChange(e);
    }
  };
  return (
    <div style={textFieldContainerStyle}>
      <TextField
        id={id}
        ref={ref}
        required={required}
        name={name}
        variant={variant}
        label={label}
        value={value}
        error={error}
        size={size}
        onChange={
          allowOnlyAlphabets
            ? handleAlphabeticChange
            : allowOnlyNumbers
              ? handleNumberChange
              : onChange
        }
        sx={style}
        onBlur={onBlur}
        multiline
        rows={lines}
        maxRows={maxRows}
        disabled={disabled}
        InputProps={{
          style: { cursor: disabled ? 'no-drop' : 'auto', fontSize: isRes ? '14px' : '20px' },
          endAdornment: (
            <InputAdornment position="end">
              {showClear && (
                <IconButton aria-label="toggle password visibility" edge="end" onClick={onClear}>
                  <Close sx={{ marginRight: '5px', color: color.palette.primaryBlack }} />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
        {...rest}
      />
      {error && (
        <Text style={errorStyle} variant="fieldError">
          {error}
        </Text>
      )}
    </div>
  );
};
