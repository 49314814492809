import { Box } from '@mui/material';
import { Screen } from '../../../ui-kit';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { useEffect } from 'react';
import { styles } from './style';
import { IncomingRequest, TeamDeatails, CompanyDetailsCard } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyDetailsRequest,
  resetAdminMessages,
  updateTeamTab
} from '../../../services/redux/slices';
import {
  getAdminErrorMessage,
  getAdminSuccessMessage,
  getTeamTab
} from '../../../services/redux/selectors';
import { toast } from 'react-toastify';
import { resetMessge } from '../../../utils';

export const ManageTeamPage = () => {
  const dispatch = useDispatch();
  const successMessage = useSelector(getAdminSuccessMessage);
  const errorMessage = useSelector(getAdminErrorMessage);
  const value = useSelector(getTeamTab);

  const handleChange = (event, newValue) => {
    dispatch(updateTeamTab(newValue));
  };

  useEffect(() => {
    dispatch(getCompanyDetailsRequest());
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      dispatch(getCompanyDetailsRequest());
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }

    resetMessge(() => dispatch(resetAdminMessages()));
  }, [successMessage, errorMessage]);

  return (
    <Screen showSideBar={true} currentPage="Manage Teams">
      <Box sx={styles.container}>
        <TabContext value={value}>
          <Box sx={styles.navContainer}>
            <TabList
              textColor="none"
              TabIndicatorProps={{ sx: styles.tabIndicator }}
              onChange={handleChange}
              variant="scrollable"
              aria-label="lab API tabs example">
              {defaultValues.manageTeamTab.map((item, index) => (
                <Tab key={index} sx={styles.tab} label={item.heading} value={index} />
              ))}
            </TabList>
          </Box>
          <Box sx={styles.pagesContainer}>
            <TabPanel sx={styles.panel} value={0}>
              <CompanyDetailsCard />
            </TabPanel>
            <TabPanel sx={styles.panel} value={1}>
              <TeamDeatails />
            </TabPanel>
            <TabPanel sx={styles.panel} value={2}>
              <IncomingRequest />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Screen>
  );
};
