import { Box } from '@mui/material';
import { PopupModal, Screen, Text, UIButton } from '../../../ui-kit';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { ArrowForwardIos } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { CreateToDo, TaskListForm, TaskStatus } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectMembers,
  resetProjectsMessages,
  taskListRequest,
  updateTaskListStatusRequest
} from '../../../services/redux/slices';
import {
  getProjecsErrorMessage,
  getProjectDetailsSel,
  getProjectsLoadingStatus,
  getProjectsSuccessMessage,
  getTaskLists,
  getUserType,
  projectMembers
} from '../../../services/redux/selectors';
import { getFormatDate, resetMessge } from '../../../utils';
import { toast } from 'react-toastify';
import { style } from './style';
import { useNavigate } from 'react-router-dom';
import edit from '../../../assets/icons/edit.png';
import { LoadingScreen } from '../../../ui-kit/loading-screen';

export const TaskManagement = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const userType = useSelector(getUserType);
  const members = useSelector(projectMembers);
  const taskLists = useSelector(getTaskLists);
  const error = useSelector(getProjecsErrorMessage);
  const success = useSelector(getProjectsSuccessMessage);
  const isLoading = useSelector(getProjectsLoadingStatus);
  const projectDetails = useSelector(getProjectDetailsSel);

  const [taskList, setTaskList] = useState([]);
  const [addTodo, setAddTodo] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [clickedIndex, setClickedIndex] = useState(null);
  const [projectMember, setProjectMember] = useState([]);
  const [showCrateBtn, setShowCrateBtn] = useState(false);
  const [editTaskList, setEditTaskList] = useState({});

  const handleStatusChange = (event, id) => {
    dispatch(updateTaskListStatusRequest({ list_id: id, status: event.target.value }));
  };

  useEffect(() => {
    if (projectDetails && projectDetails.id) {
      setProjectId(projectDetails.id);
    }
    if (projectDetails.project_name) {
      setProjectName(projectDetails.project_name);
    }
    if (taskLists) {
      setTaskList(taskLists);
    }
    if (members) {
      setProjectMember(members);
    }

    if (
      userType === defaultValues.userRole.superAdmin ||
      userType === defaultValues.userRole.projectManger ||
      userType === defaultValues.userRole.admin
    ) {
      setShowCrateBtn(true);
    }
  }, [projectDetails, taskLists, members, userType]);

  useEffect(() => {
    if (projectId) {
      dispatch(taskListRequest({ project_id: projectId }));
      dispatch(getProjectMembers({ project_id: projectId }));
    }
  }, [projectId, success]);

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(success));
    }

    if (error) {
      toast.error(getErrorMessage(error));
    }

    setShowModal(false);
    setAddTodo(false);
    setEditTaskList({});

    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [success, error]);

  const handleAddTodoClick = (index) => {
    setClickedIndex(index);
    setAddTodo(true);
  };

  const handleCancel = () => {
    setAddTodo(false);
  };

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive}
      currentPage={'Project details'}>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={style.container}>
        <Box sx={style.subContainer}>
          <Box sx={style.titleContainer}>
            <Text
              onClick={() => {
                navigation(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                  state: { data: { id: projectDetails.id } }
                });
              }}
              variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
              style={style.navStyle}>
              {projectName}
            </Text>

            {!defaultValues.isResponsive && <ArrowForwardIos sx={style.forwardIcon} />}
            <Text variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}>
              Task management
            </Text>
          </Box>
          <Box sx={style.taskListContainer}>
            {showCrateBtn && (
              <Box sx={style.listTilte}>
                <Text variant={defaultValues.isResponsive ? 'cardTitle' : 'titleBold'}>
                  Create tasks
                </Text>
                <UIButton
                  title={'+ new task list'}
                  style={style.addBtn}
                  onClick={() => setShowModal(true)}
                />
              </Box>
            )}

            {taskList.length > 0 ? (
              taskList.map((item, index) => (
                <Box key={index} sx={style.listCard}>
                  <Box sx={style.listDetailsContainer}>
                    <Box sx={style.detailsContainer}>
                      <Box sx={style.listTitleContainer}>
                        <Box sx={style.titleBox}>
                          <Text
                            style={style.navTitleStyle}
                            variant={defaultValues.isResponsive ? 'responsiveText' : 'fileName'}
                            onClick={() =>
                              navigation(SCREEN_PATH.PROJECT_TASKS, {
                                state: { data: item.id, showCrateBtn }
                              })
                            }>
                            {`${item.list_title} (Est.time: ${item.estimated_time} hrs)`}
                          </Text>
                        </Box>
                        {showCrateBtn && (
                          <img
                            style={style.editIcon}
                            src={edit}
                            onClick={() => {
                              setShowModal(true);
                              setEditTaskList(item);
                            }}
                          />
                        )}
                        {!defaultValues.isResponsive && (
                          <TaskStatus
                            status={item.status}
                            onChange={(e) => handleStatusChange(e, item.id)}
                          />
                        )}
                      </Box>

                      <Box sx={style.countContainer}>
                        <Box sx={style.circle}>
                          <Box
                            sx={style.innerCircle(
                              Math.round(
                                (item.tasks.reduce((count, task) => {
                                  return task.status === 2 ? count + 1 : count;
                                }, 0) /
                                  item.tasks.length) *
                                  100
                              )
                            )}></Box>
                        </Box>
                        <Text
                          style={style.countDetail}
                          variant={defaultValues.isResponsive ? 'responsiveDesc' : 'small'}>
                          {`${item.tasks.reduce((count, task) => {
                            return task.status === 2 ? count + 1 : count;
                          }, 0)} / ${item.tasks.length} completed`}
                        </Text>
                      </Box>

                      <Box sx={style.dueDate}>
                        {item.due_date && (
                          <Text
                            variant={defaultValues.isResponsive ? 'extraSmall' : 'responsiveText'}
                            style={style.dueDateColor}>
                            {`Due on ${getFormatDate(item.due_date)}`}
                          </Text>
                        )}
                      </Box>
                      {defaultValues.isResponsive && (
                        <TaskStatus
                          status={item.status}
                          onChange={(e) => handleStatusChange(e, item.id)}
                        />
                      )}
                    </Box>
                    {showCrateBtn && (
                      <UIButton
                        title={'add to do'}
                        style={style.toDoBtn}
                        onClick={() => handleAddTodoClick(index)}
                      />
                    )}
                  </Box>
                  {addTodo && clickedIndex === index && (
                    <CreateToDo
                      projectMembers={projectMember}
                      list_id={item.id}
                      onCancel={handleCancel}
                      showBtn={showCrateBtn}
                    />
                  )}
                </Box>
              ))
            ) : (
              <Box sx={style.noFoundBox}>
                <Text style={style.noFoundText}>No Tasks Found</Text>
              </Box>
            )}
          </Box>
        </Box>

        <PopupModal
          customContentWidth={style.customPopUp}
          showModel={showModal}
          heading={Object.keys(editTaskList).length === 0 ? 'Create task list' : 'Edit task list'}
          showDivider={true}
          titlePosition={true}
          onClose={() => {
            setShowModal(false);
            setEditTaskList({});
          }}>
          <TaskListForm
            data={editTaskList}
            btnTitle={Object.keys(editTaskList).length === 0 ? 'Create list' : 'Update list'}
            isLoading={isLoading}
          />
        </PopupModal>
      </Box>
    </Screen>
  );
};
