import { Box } from '@mui/material';
import { Screen, Text } from '../../../ui-kit';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { CalStyles } from './styles';
import { ArrowForwardIos } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { customStyles } from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjecsErrorMessage,
  getProjectsLoadingStatus,
  getProjectsSuccessMessage,
  getTaskSchedules
} from '../../../services/redux/selectors';

import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { getTaskStatus, resetMessge, truncateText } from '../../../utils';
import { Calendar } from '@0101-labs/react-calendar';
import {
  clearTaskListAndSchedules,
  getTaskSchedulesRequest,
  resetProjectsMessages
} from '../../../services/redux/slices';
import { toast } from 'react-toastify';

export const CalenderManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoading = useSelector(getProjectsLoadingStatus);
  const schedules = useSelector(getTaskSchedules);
  const successMessage = useSelector(getProjectsSuccessMessage);
  const errorMessage = useSelector(getProjecsErrorMessage);

  const [details, setDetails] = useState({});
  const [parentHeight, setParentHeight] = useState(0);
  const [childHeight, setChildHeight] = useState(0);
  const [taskList, setTaskList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const todaysDate = new Date();

  useEffect(() => {
    updateHeights();
    window.addEventListener('resize', updateHeights);

    return () => {
      window.removeEventListener('resize', updateHeights);
    };
  }, [taskList]);

  const showScrollbar = childHeight > parentHeight;

  const updateHeights = () => {
    const parentDiv = document.getElementById('parentDiv');
    const childDiv = document.getElementById('childDiv');

    if (parentDiv && childDiv) {
      setParentHeight(parentDiv.clientHeight);
      setChildHeight(childDiv.clientHeight);
    }
  };

  useEffect(() => {
    if (location.state) {
      setDetails(location.state.data);
      handleSchedules();
    }
  }, [location.state]);

  useEffect(() => {
    if (schedules && schedules.length > 0) {
      setScheduleList(schedules);
      handleDateChange(todaysDate);
    }
  }, [schedules]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      setTaskList([]);
      setScheduleList([]);
      dispatch(clearTaskListAndSchedules());
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [successMessage, errorMessage]);

  const handleSchedules = () => {
    dispatch(getTaskSchedulesRequest({ project_id: location.state.data.id }));
  };

  const handleDateChange = (date) => {
    let inputDateString = date;
    let inputDate = new Date(inputDateString);
    let year = inputDate.getFullYear();
    let month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    let day = inputDate.getDate().toString().padStart(2, '0');
    let outputDateString = `${year}-${month}-${day}`;
    let list = schedules.filter(
      (item) => item && item.due_date && item.due_date.includes(outputDateString)
    );
    setTaskList(list);
  };

  const handleTitleClick = (item) => {
    navigate(SCREEN_PATH.PROJECT_TASKDETAILS, {
      state: {
        projectDetails: details,
        taskDetails: item,
        type: defaultValues.listConstants.view_task_details
      }
    });
  };

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive && true}
      currentPage={'Project details'}>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={CalStyles.container}>
        <Box sx={CalStyles.contentContainer}>
          <Box sx={CalStyles.navContainer}>
            <Box sx={CalStyles.navContainer}>
              <Box sx={customStyles.flexBox}>
                <Text style={CalStyles.navText} onClick={() => navigate(-1)}>
                  {details && details.project_name}
                </Text>
                {!defaultValues.isResponsive && <ArrowForwardIos sx={CalStyles.icon} />}
              </Box>

              <Text style={CalStyles.navIdleText}>Calender management</Text>
            </Box>
          </Box>

          <Box sx={CalStyles.uploadSuperContainer}>
            <Box sx={CalStyles.uploadContainer}>
              <Box sx={CalStyles.calenderContainer}>
                <Calendar
                  headerLabelStyle={CalStyles.calenderLabel}
                  style={CalStyles.calenderRoot}
                  dropdownStyle={CalStyles.dropdownIcon}
                  key={scheduleList.join(',')}
                  taskArray={scheduleList}
                  onSelectDate={(date) => handleDateChange(date._d)}
                />
              </Box>
              <Box sx={CalStyles.shedulesContainer(showScrollbar, taskList)} id="parentDiv">
                <Box sx={CalStyles.shedulesSubContainer} id="childDiv">
                  {taskList.length > 0 ? (
                    taskList.map(
                      (item, idx) =>
                        item.status !== 4 &&
                        item.status !== 3 && (
                          <Box key={idx} sx={CalStyles.taskContainer}>
                            <Box sx={CalStyles.indicator(getTaskStatus(item.status))}></Box>
                            <Box sx={CalStyles.taskCont}>
                              <Text sx={CalStyles.taskTitle} onClick={() => handleTitleClick(item)}>
                                <span style={CalStyles.spanText}>
                                  {truncateText(item.task_title, 20)}
                                </span>
                              </Text>
                              <Text sx={CalStyles.Tdesc}>
                                <span style={CalStyles.spanText}>
                                  {truncateText(item.task_description, 20)}
                                </span>
                              </Text>
                            </Box>
                          </Box>
                        )
                    )
                  ) : (
                    <Box sx={CalStyles.nofoundContainer}>
                      {
                        <Text
                          variant={defaultValues.isResponsive ? 'responsiveDescBold' : 'smallBold'}>
                          {getErrorMessage('E-10122')}
                        </Text>
                      }
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Screen>
  );
};
