/**
 * The various styles of fonts are defined in the <Text /> component.
 * How to add fonts -
 * Add fonts ttf file to the fonts folder in your assets folder
 * here user the font name from the added font files
 */
export const fonts = {
  /**
   * The primary font.  Used in most places like normal text and paragraphs.
   */
  primary: 'GorditaRegular',
  /**
   * An alternate font used for perhaps sub-titles and stuff.
   */
  // secondary: 'Quicksand',
  /**
   * An alternate font used for perhaps Page Header and etc.
   */
  header: 'GorditaBold'
};
