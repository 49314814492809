import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { popModalStyle } from './style';
import { Text } from '../../ui-kit';
import closeIcon from '../../assets/icons/close.png';
import { Divider } from '@mui/material';

export const PopupModal = ({
  showModel,
  onClose,
  heading,
  children,
  titlePosition,
  showDivider = false,
  dividerWidth = false,
  headingStyle = {},
  customContentWidth = '100%',
  childBoxStyle = {},
  dividerStyle = {},
  headingContainerStyle = {},
  containerStyles = {},
  contentContainerStyles
}) => {
  return (
    <div>
      <Modal open={showModel} onClose={onClose}>
        <Box sx={popModalStyle.container(containerStyles)}>
          <img style={popModalStyle.closeButton} onClick={onClose} src={closeIcon} />
          <Box
            sx={popModalStyle.contentContainer(
              titlePosition,
              customContentWidth,
              contentContainerStyles
            )}>
            {heading && (
              <>
                <Box sx={popModalStyle.headingContainer(headingContainerStyle)}>
                  <Text style={popModalStyle.heading(headingStyle)}>{heading}</Text>
                </Box>
              </>
            )}
            {showDivider && <Divider sx={popModalStyle.diver(dividerWidth, dividerStyle)} />}
            <Box sx={popModalStyle.childBox(childBoxStyle)}>{children}</Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
