export const HiddenInput = ({ handleChange, inpRef }) => {
  const style = {
    input: {
      display: 'none'
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    handleChange(selectedFile);
  };

  return (
    <input
      type="file"
      id="fileInput"
      accept=".pdf, .doc, .docx, .png, .jpeg, .jpg"
      onChange={handleFileChange}
      style={style.input}
      ref={inpRef}
    />
  );
};
