import { defaultValues } from '../../constants';
import { customStyles } from '../../theme';
import { color } from '../../theme/color';
const responsive = defaultValues.isResponsive;
const sm = defaultValues.isXSmall;

export const style = {
  toolsIcons: (style) => ({
    maxWidth: '100%',
    maxHeight: sm ? '14px' : responsive ? '18px' : '24px',
    marginRight: '8px',
    ...style
  }),
  toolsIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: sm ? '10px' : '10px 20px',
    boxSizing: 'border-box',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    marginLeft: sm ? '4px' : '10px',
    cursor: 'pointer'
  },
  menuBox: {
    borderRadius: '10px',
    marginTop: '10px',
    height: 'auto'
  },
  menuText: { fontSize: responsive ? '14px' : '16px' },
  menuItem: {
    ...customStyles.flexBox,
    justifyContent: 'space-between',
    height: '30px',
    width: '100%',
    padding: 'auto 150px'
  },
  closeBtn: { height: '25px', width: '25px', color: 'black' },
  menuHeader: {
    ...customStyles.flexBox,
    height: '40px'
  },
  marginLeft: { marginLeft: '20px', marginRight: '0px' },
  marginAuto: { marginLeft: 'auto' }
};
