import { Box } from '@mui/material';
import { IconsButton, PaginationCustom, Screen, Sort, Text } from '../../../ui-kit';
import { styles } from './style';
import { CustomModal, JobCard, NoData } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  applyJobRequest,
  getJobRequest,
  resetJobsMessages,
  updateJobParams
} from '../../../services/redux/slices/jobsSlice';
import {
  getCurrentPages,
  getJobErrorMessage,
  getJobLoading,
  getJobParams,
  getJobSuccessMessage,
  getJobs,
  getTotalPages
} from '../../../services/redux/selectors/jobSelector';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { toast } from 'react-toastify';
import { resetMessge } from '../../../utils';
import { userInfo } from '../../../services/redux/selectors';

export const ViewJobs = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const allJobs = useSelector(getJobs);
  const totalPage = useSelector(getTotalPages);
  const currentPage = useSelector(getCurrentPages);
  const isLoading = useSelector(getJobLoading);
  const successMessage = useSelector(getJobSuccessMessage);
  const errorMessage = useSelector(getJobErrorMessage);
  const jobsParams = useSelector(getJobParams);
  const userData = useSelector(userInfo);

  const [page, setPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [totalPages, setTotalPage] = useState(0);
  const [sortValue, setSortValue] = useState({});
  const [currJob, setCurrJob] = useState(null);
  const [open, setOpen] = useState(false);
  const [userTimezone, setUserTimezone] = useState(null);

  useEffect(() => {
    if (userData) {
      setUserTimezone(userData.timezone);
    }
  }, [userData]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      handleManageJob();
      handleClose();
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetJobsMessages()));
  }, [successMessage, errorMessage]);

  const handleSort = (sort) => {
    setSortValue({ sort });
    handleManageJob({ sort, page: 1 });
  };

  const handleRefresh = () => {
    setSortValue({});
    handleManageJob({ sort: null, filter: null, page: null });
  };

  const handleManageJob = (data) => {
    let payload = {
      page: currentPage || 1,
      ...jobsParams,
      ...data
    };
    dispatch(updateJobParams(payload));
    dispatch(getJobRequest(payload));
  };

  useEffect(() => {
    handleManageJob();
  }, []);

  useEffect(() => {
    if (allJobs) {
      setJobs(allJobs);
    }
    if (totalPage) {
      setTotalPage(totalPage);
    }
    if (currentPage) {
      setPage(currentPage);
    }
  }, [allJobs]);

  const handlePageChange = (e, value) => {
    const payload = {
      page: value,
      ...sortValue
    };
    handleManageJob(payload);
  };

  const handleApplyJob = () => {
    dispatch(applyJobRequest({ job_id: currJob.job_id }));
  };

  const handleOpen = (item) => {
    setCurrJob(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Screen showSideBar={true} currentPage="View jobs">
      <Box sx={styles.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={styles.subContainer}>
          <Box sx={styles.actionContainer}>
            {defaultValues.isResponsive && (
              <Text variant="smallBold" style={styles.txt}>
                View jobs
              </Text>
            )}
            <Box sx={styles.filCon}>
              <IconsButton
                title={!defaultValues.isResponsive ? null : 'Refresh'}
                icon={<RefreshIcon sx={styles.icon} />}
                onClick={handleRefresh}
              />
              <Sort selectedOption={handleSort} activeOption={sortValue.sort} />
            </Box>
          </Box>
          {jobs.length > 0 ? (
            jobs.map((item, index) => (
              <JobCard
                timezone={userTimezone}
                data={item}
                onTitleClick={() => navigation(SCREEN_PATH.JOB_DETAILS, { state: { data: item } })}
                key={index}
                onButtonClick={() => handleOpen(item)}
              />
            ))
          ) : (
            <NoData message={getErrorMessage('E-10106')} />
          )}
          {page && totalPages && (
            <Box sx={styles.paginationContainer}>
              <PaginationCustom onChange={handlePageChange} currentPage={page} count={totalPages} />
            </Box>
          )}
        </Box>
        <CustomModal
          title={defaultValues.applyJobModal.title}
          message={defaultValues.applyJobModal.message}
          open={open}
          btnType={defaultValues.applyJobModal.btn}
          onClose={handleClose}
          onConfirm={handleApplyJob}
          onCancel={handleClose}
          cancelStyle={styles.modalCancel}
          confirmStyle={styles.apply}
          modalType={defaultValues.listConstants.jobs}
          isLoading={isLoading}
        />
      </Box>
    </Screen>
  );
};
