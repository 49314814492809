import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

const md = defaultValues.isMedium;

export const styles = {
  container: {
    backgroundColor: color.palette.secondaryBlack,
    width: '18%',
    height: '100vh',
    py: '1%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: { height: '60px', width: defaultValues.isMedium ? '150px' : '200px', objectFit: 'contain' },
  searchBar: {
    backgroundColor: color.palette.ligherBlack,
    borderRadius: 2
  },
  searchIcon: { color: color.primaryBackground },
  inputText: {
    color: color.primaryBackground
  },
  menuContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: md ? 0 : '0px 10px',
    boxSizing: 'border-box'
  },
  menuItems: {
    color: color.secondaryText,
    textAlign: 'left',
    fontWeight: '400',
    textTransform: 'none',
    fontSize: md ? '12px' : '18px'
  },
  menuIcons: {
    marginRight: 10,
    width: md ? '20px' : '30px',
    backgroundColor: color.palette.secondaryBlack,
    color: color.primaryBackground
  },
  logoutContainer: {
    width: '100%',
    height: '10%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    mt: 3,
    marginBottom: '20px',
    paddingLeft: '25px',
    boxSizing: 'border-box',
    cursor: 'pointer'
  },
  logout: {
    cursor: 'pointer',
    marginLeft: 20,
    width: '50%'
  },
  logoContainer: { width: '90%', marginY: 1 },
  tabs: {
    style: {
      background: color.primaryBackground,
      width: '10px'
    }
  },
  tabsStyle: {
    '.MuiTabs-indicator': {
      left: 0,
      borderRadius: 1
    },
    '& .MuiTabScrollButton-vertical': {
      color: color.palette.primaryWhite
    },
    '& .MuiSvgIcon-root': {
      fontSize: '40px'
    },
    marginTop: 2,
    height: '90%',
    width: '100%',
    '& .MuiButtonBase-root': {
      minHeight: md ? '0px' : '48px'
    }
  },
  tabStyle: {
    marginY: '3px',
    height: md ? '30px' : '40px'
  }
};
