import {
  Filter,
  PaginationCustom,
  SearchBar,
  Sort,
  Text,
  UIButton,
  ActionButton,
  IconsButton
} from '../../../ui-kit';
import { languageStyles } from './styles';
import { Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useEffect, useState } from 'react';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { CollapsableList, NoData, OtherSettingsModal, StatusIndicator, Title } from '../..';
import { useFormik } from 'formik';
import { otherSettingsLanguageSchema, resetMessge } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLanguageRequest,
  editLanguageRequest,
  getLanguageRequest,
  resetSettingsMessages
} from '../../../services/redux/slices';
import {
  currentPage,
  getLanguages,
  getSettingsErrorMessage,
  getSettingsIndicator,
  getSettingsSuccessMessage,
  settingLoadingStatus,
  totalPage
} from '../../../services/redux/selectors';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { customStyles } from '../../../theme';

export const Languages = () => {
  const dispatch = useDispatch();
  const allLanguages = useSelector(getLanguages);
  const pageCount = useSelector(totalPage);
  const currentRenderPage = useSelector(currentPage);
  const successMessage = useSelector(getSettingsSuccessMessage);
  const errorMessage = useSelector(getSettingsErrorMessage);
  const isLoading = useSelector(settingLoadingStatus);
  const indicator = useSelector(getSettingsIndicator);

  const [languages, setLanguages] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [sortValue, setSortValue] = useState({});
  const [filterValue, setFilterValue] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [pageNo, setPageNo] = useState(null);

  useEffect(() => {
    handleLanguage();
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      handleClose();
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetSettingsMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    setLanguages(allLanguages);
  }, [allLanguages]);

  useEffect(() => {
    if (indicator) {
      handleLanguage();
    }
  }, [indicator]);

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldError,
    setFieldTouched,
    setFieldValue
  } = useFormik({
    initialValues: {
      language: '',
      description: '',
      id: ''
    },
    validationSchema: otherSettingsLanguageSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  useEffect(() => {
    if (!isLoading && successMessage) {
      handleClose();
      setCurrentIndex('');
    }
  }, [isLoading]);

  const handleOpen = () => {
    setIsEditMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFieldValue('language', '');
    setFieldValue('description', '');
    setFieldTouched('language', false);
    setFieldTouched('description', false);
  };

  const handleAddLanguage = () => {
    const payload = { language_title: values.language, description: values.description };
    dispatch(addLanguageRequest(payload));
  };

  const handleOpenEdit = (item) => {
    setFieldValue('language', item.language_title);
    setFieldValue('description', item.description);
    setFieldValue('id', item.id);
    setOpen(true);
    setIsEditMode(true);
  };

  function handleEditLanguage() {
    const payload = {
      id: values.id,
      language: values.language,
      description: values.description
    };
    dispatch(editLanguageRequest(payload));
  }

  const handleStatus = (id, status, index) => {
    const payload = { id: id, active: !status };
    setCurrentIndex(index);
    dispatch(editLanguageRequest(payload));
  };

  const handleLanguage = (data) => {
    let payload = {
      page: pageNo || 1,
      ...sortValue,
      ...filterValue,
      ...data
    };
    dispatch(getLanguageRequest(payload));
  };

  const handleSort = (sort) => {
    setSortValue({ sort });
    handleLanguage({ sort });
  };

  const handleFilter = (value) => {
    setFilterValue({ filter: `[{ isActive: ${value} }]` });
    handleLanguage({ filter: `[{ isActive: ${value} }]` });
  };

  const handleRefresh = () => {
    setSortValue({});
    setFilterValue({});
    setPageNo(null);
    handleLanguage({ sort: null, filter: null, page: 1 });
  };

  const handleSearch = (search_text) => {
    if (search_text.length === 0 || search_text.length >= 2) {
      setTimeout(() => {
        handleLanguage({ search_text });
      }, 1500);
    }
  };

  const handlePageChange = (e, value) => {
    const payload = {
      page: value,
      ...sortValue,
      ...filterValue
    };
    setPageNo(value);
    dispatch(getLanguageRequest(payload));
  };

  return (
    <Box sx={languageStyles.container}>
      <LoadingScreen isLoading={!currentIndex && isLoading} />
      <Box sx={languageStyles.contentContainer}>
        <Box sx={languageStyles.searchTabContainer}>
          <Box sx={languageStyles.searchToolsContainer}>
            {!defaultValues.isResponsive ? (
              <SearchBar label={'Search languages'} searchedVlaue={handleSearch} />
            ) : (
              <Text sx={languageStyles.headingText} variant="medium">
                Current Languages
              </Text>
            )}
            <UIButton
              style={languageStyles.newButton}
              title="+ new language"
              onClick={handleOpen}
            />
          </Box>

          <Box sx={languageStyles.toolsContainer}>
            <IconsButton
              title={!defaultValues.isResponsive ? null : 'Refresh'}
              icon={<RefreshIcon sx={languageStyles.icon} />}
              onClick={handleRefresh}
            />
            <Sort selectedOption={handleSort} activeOption={sortValue.sort} />
            <Filter label={'Filter'} selectedOption={handleFilter} />
          </Box>
        </Box>

        {!defaultValues.isResponsive ? (
          <Text sx={languageStyles.heading} variant="fileName">
            {defaultValues.otherSettingsLanguagesHeading}
          </Text>
        ) : (
          <Box sx={languageStyles.searchContainer}>
            <SearchBar label={'Search languages'} searchedVlaue={handleSearch} />
          </Box>
        )}

        {!defaultValues.isResponsive ? (
          <>
            {languages.length > 0 ? (
              <Table sx={languageStyles.tableContainer} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={languageStyles.headingCell} align="left">
                      Language
                    </TableCell>
                    <TableCell sx={languageStyles.headingCell} align="left">
                      Status
                    </TableCell>
                    <TableCell sx={languageStyles.headingCell} align="left">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {languages &&
                    languages.map((row, index) => (
                      <TableRow key={index} sx={languageStyles.tableRow}>
                        <TableCell component="th" scope="row">
                          <Title title={row.language_title} />
                        </TableCell>
                        <TableCell align="left">
                          <StatusIndicator status={row.active} />
                        </TableCell>
                        <TableCell sx={customStyles.actionCont} align="left">
                          <ActionButton
                            item={row}
                            onEditClick={() => handleOpenEdit(row)}
                            onButtonClick={() => handleStatus(row.id, row.active, index)}
                            isLoading={index === currentIndex && !open && isLoading}
                            contStyle={customStyles.actionSub}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <NoData message={getErrorMessage('E-10027')} />
            )}
          </>
        ) : (
          <Box sx={languageStyles.collapseContainer}>
            {languages && languages.length > 0 ? (
              <CollapsableList
                contentArray={languages}
                type="languages"
                onEditClick={handleOpenEdit}
                onButtonClick={handleStatus}
                currentIndex={!open && isLoading && currentIndex}
                loaderSize={defaultValues.isResponsive && 15}
              />
            ) : (
              <NoData message={getErrorMessage('E-10027')} />
            )}
          </Box>
        )}

        {currentRenderPage && pageCount && (
          <Box sx={languageStyles.paginationContainer}>
            <PaginationCustom
              onChange={handlePageChange}
              currentPage={currentRenderPage}
              count={pageCount}
            />
          </Box>
        )}
        <OtherSettingsModal
          open={open}
          close={handleClose}
          heading={isEditMode ? 'Edit Language' : defaultValues.otherSettingsLanguageModalHeading}
          placeholder1="Enter language name"
          placeholder2="type here..."
          inputTitle1="Language"
          inputTitle2="Description"
          inputValue1={values.language}
          inputValue2={values.description}
          inputName1={'language'}
          inputName2={'description'}
          buttonTitle={isEditMode ? 'Save changes' : 'Save'}
          onChange={handleChange}
          error={errors}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
          touched={touched}
          onButtonClick={isEditMode ? handleEditLanguage : handleAddLanguage}
          type="language"
          disabled={
            errors.language || errors.description || !values.language || !values.description
          }
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};
