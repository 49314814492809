import axios from 'axios';
import { API } from '../../constants/api';
import { store } from '../redux/store';
import { logoutRequest } from '../redux/slices';
import { SCREEN_PATH } from '../../constants';

export const createAxiosInstance = (info) => {
  const { url, method, headers, data, params } = info;
  const axiosInstance = axios.create({
    baseURL: API.baseURL[API.currentEnv],
    headers
  });
  axiosInstance.interceptors.response.use(
    (response) => {
      const code = response.data && response.data.status_code;
      if (code === 'E-10010') {
        store.dispatch(logoutRequest());
        window.location.href = SCREEN_PATH.HOME;
        store.dispatch({ type: 'USER_LOGOUT' });
        localStorage.clear();
      }

      return response;
    },

    (error) => {
      if (error.response && error.response.data.status == '401') {
        store.dispatch(logoutRequest());
        window.location.href = SCREEN_PATH.HOME;
        store.dispatch({ type: 'USER_LOGOUT' });
        localStorage.clear();
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance({ url, method, headers, data, params });
};
