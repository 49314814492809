import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { store, persistor } from './services/redux/store';
import RootNavigation from './navigation';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { palette } from './theme/palette';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the toastify CSS
import { color } from './theme/color';
import { messaging } from './notification/firebase';
import { onMessage } from 'firebase/messaging';

const App = () => {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Theme customization for typography
  const theme = createTheme({
    typography: {
      fontFamily: 'GorditaRegular, Arial',
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500
    },
    palette: palette,
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            transform: 'scale(1.3)'
          },
          switchBase: {
            color: color.palette.gray
          },
          colorPrimary: {
            '&.Mui-checked': {
              color: color.palette.buttonGreen
            }
          },
          track: {
            opacity: 0.3,
            backgroundColor: color.palette.gray,
            '.Mui-checked.Mui-checked + &': {
              opacity: 0.4,
              backgroundColor: color.palette.buttonGreen
            }
          }
        }
      }
    }
  });

  // window.onresize = () => {
  //   if (windowWidth !== window.innerWidth) {
  //     window.location.reload();
  //     setWindowWidth(window.innerWidth);
  //   }
  // };

  useEffect(() => {
    onMessage(messaging, () => {});
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router basename={process.env.PUBLIC_URL}>
            <RootNavigation />
          </Router>
        </PersistGate>
      </Provider>

      {/* ToastContainer for notifications */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
};

export default App;
