import * as yup from 'yup';
import { getErrorMessage, regExp } from '../constants';

export const loginSchema = yup.object({
  email: yup
    .string()
    .required(getErrorMessage('E-10002'))
    .email(getErrorMessage('E-10003'))
    .matches(regExp.email, getErrorMessage('E-10003'))
});

export const supplierSchema = yup.object({
  first_name: yup.string().required(getErrorMessage('E-10042')).matches(regExp.alphabetRegExp),
  last_name: yup.string().required(getErrorMessage('E-10043')).matches(regExp.alphabetRegExp),

  email_id: yup
    .string()
    .matches(regExp.email, getErrorMessage('E-10003'))
    .required(getErrorMessage('E-10002')),
  skills: yup
    .array()
    .of(yup.string().required(getErrorMessage('E-10048')))
    .min(1, getErrorMessage('E-10048')),
  languages: yup
    .array()
    .of(yup.string().required(getErrorMessage('E-10026')))
    .min(1, getErrorMessage('E-10026')),
  currency: yup.string().required(getErrorMessage('E-10049')),
  locality: yup.string().required(getErrorMessage('E-10051')),
  city: yup.string().required(getErrorMessage('E-10052')),
  country: yup.string().required(getErrorMessage('E-10050')),
  postal_code: yup.string().required(getErrorMessage('E-10053')),
  timezoneId: yup.string().required(getErrorMessage('E-10082')),
  stateReq: yup.boolean(),
  state: yup.string().when('stateReq', {
    is: true,
    then: () => yup.string().required(getErrorMessage('E-10071'))
  }),
  availability_type: yup.string().required(getErrorMessage('E-10130')),
  availability_details: yup.string().required(getErrorMessage('E-10130'))
});

export const profileOtherSchema = yup.object({
  first_name: yup
    .string()
    .required(getErrorMessage('E-10042'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10089')),
  last_name: yup
    .string()
    .required(getErrorMessage('E-10043'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10090')),
  email_id: yup
    .string()
    .required(getErrorMessage('E-10044'))
    .matches(regExp.email, getErrorMessage('E-10003'))
});

export const profileCustomerSchema = yup.object({
  first_name: yup.string().required(getErrorMessage('E-10042')),
  last_name: yup.string().required(getErrorMessage('E-10043')),
  email_id: yup
    .string()
    .matches(regExp.email, getErrorMessage('E-10003'))
    .required(getErrorMessage('E-10002')),
  locality: yup.string().required(getErrorMessage('E-10051')),
  city: yup.string().required(getErrorMessage('E-10052')),
  country: yup.string().required(getErrorMessage('E-10050')),
  postal_code: yup.string().required(getErrorMessage('E-10053')),
  timezoneId: yup.string().required(getErrorMessage('E-10082'))
});

export const otherSettingsCurrencySchema = yup.object({
  currency: yup
    .string()
    .required(getErrorMessage('E-10032'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10035'))
});

export const otherSettingsLanguageSchema = yup.object({
  language: yup
    .string()
    .required(getErrorMessage('E-10028'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10029')),
  description: yup.string().required(getErrorMessage('E-10033'))
});

export const otherSettingsSkillsSchema = yup.object({
  skill: yup.string().required(getErrorMessage('E-10039')),
  description: yup.string().required(getErrorMessage('E-10060'))
});

export const otherSettingsServicesSchema = yup.object({
  title: yup.string().required(getErrorMessage('E-10014')).nullable(),
  summary: yup.string().required(getErrorMessage('E-10015')),
  pre_payment_required: yup.boolean().required(getErrorMessage('E-10016')),
  offered_languages: yup.array().min(1, getErrorMessage('E-10026')),
  questions: yup.array().min(1, getErrorMessage('E-10017'))
});

export const otherSettingsCountrySchema = yup.object({
  country: yup
    .string()
    .required(getErrorMessage('E-10055'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10055')),
  state: yup.string().required(getErrorMessage('E-10056'))
});

export const stateSchema = yup.object({
  country_id: yup.string().required(),
  state_name: yup
    .string()
    .required(getErrorMessage('E-10056'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10056'))
});

export const bookServiceSchema = yup.object({
  regNo: yup.string().required(getErrorMessage('E-10018')),
  companyName: yup.string().required(getErrorMessage('E-10019')),
  companyType: yup.string().required(getErrorMessage('E-10020')),
  eventName: yup.string().required(getErrorMessage('E-10067')),
  eventDetails: yup.string().required(getErrorMessage('E-10023')),
  eventDate: yup
    .date()
    .required(getErrorMessage('E-10024'))
    .min(new Date(), getErrorMessage('E-10041')),
  eventDuration: yup.string().required(getErrorMessage('E-10068')),
  eventType: yup.boolean().required(getErrorMessage('E-10025')),
  team: yup.boolean().required(getErrorMessage('E-10025')),
  file_type: yup.boolean().required(getErrorMessage('E-10025')),
  post_event: yup.boolean().required(getErrorMessage('E-10025')),
  plan: yup.string().required(getErrorMessage('E-10025')),
  displayCaption: yup.string().required(getErrorMessage('E-10025')),
  fileTypeDesc: yup.string().required(getErrorMessage('E-10025')),
  teamDesc: yup.string().required(getErrorMessage('E-10025')),
  languages: yup
    .array()
    .of(yup.string().required(getErrorMessage('E-10026')))
    .min(1, getErrorMessage('E-10026')),
  spokenLanguages: yup
    .array()
    .of(yup.string().required(getErrorMessage('E-10026')))
    .min(1, getErrorMessage('E-10026')),
  first_name: yup
    .string()
    .required(getErrorMessage('E-10042'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10089')),
  last_name: yup
    .string()
    .required(getErrorMessage('E-10043'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10090')),
  skills: yup
    .array()
    .of(yup.string().required(getErrorMessage('E-10048')))
    .min(1, getErrorMessage('E-10048'))
});

export const adminSchema = yup.object({
  firstname: yup
    .string()
    .required(getErrorMessage('E-10042'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10089')),
  lastname: yup
    .string()
    .required(getErrorMessage('E-10043'))
    .matches(regExp.alphabetRegExp, getErrorMessage('E-10090')),
  email: yup
    .string()
    .required(getErrorMessage('E-10044'))
    .matches(regExp.email, getErrorMessage('E-10003'))
});

export const otherSettingsTimezoneSchema = yup.object({
  timezone: yup.string().required(getErrorMessage('E-10087'))
});

export const addTeamMemberSchema = yup.object({
  first_name: yup.string().required(getErrorMessage('E-10042')),
  last_name: yup.string().required(getErrorMessage('E-10043')),
  email: yup
    .string()
    .required(getErrorMessage('E-10044'))
    .matches(regExp.email, getErrorMessage('E-10003'))
});

export const companyDetailsSchema = yup.object({
  company_name: yup.string().required(getErrorMessage('E-10019')),
  company_description: yup.string().required(getErrorMessage('E-10081')),
  company_country: yup.string().required(getErrorMessage('E-10050')),
  company_state: yup.string().required(getErrorMessage('E-10056')),
  company_post_code: yup.string().required(getErrorMessage('E-10053')),
  company_locality: yup.string().required(getErrorMessage('E-10080')),
  company_city: yup.string().required(getErrorMessage('E-10052')),
  time_zone_id: yup.string().required(getErrorMessage('E-10082'))
});

export const projectsSchema = yup.object({
  reason: yup.string().required(getErrorMessage('E-10084')).min(10, getErrorMessage('E-10085'))
});

export const jobPostSchema = yup.object({
  job_title: yup.string().required(getErrorMessage('E-10102')),
  sel_start_date: yup
    .date()
    .required(getErrorMessage('E-10103'))
    .min(new Date(), getErrorMessage('E-10103')),
  sel_end_date: yup
    .date()
    .required(getErrorMessage('E-10104'))
    .min(yup.ref('sel_start_date'), getErrorMessage('E-10104')),
  job_description: yup.string().required(getErrorMessage('E-10105'))
});

export const invitationSchema = yup.object({
  checkListText: yup.string().required(getErrorMessage('E-10101'))
});

export const taskListSchema = yup.object({
  description: yup.string().required(getErrorMessage('E-10114')),
  list_title: yup.string().required(getErrorMessage('E-10112'))
});

export const DMSchema = yup.object({
  docType: yup.string().required(getErrorMessage('E-10121'))
});
