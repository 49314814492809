import axios from 'axios';
import { API, defaultValues } from '../constants';
import dayjs from 'dayjs';

export const getPhotoUrl = (path) => `${API.baseURL[API.currentEnv]}${path}`;

export const getFormatDate = (value, format = 'DD-MM-YYYY') => {
  return dayjs(value).utc().format(format);
};

export const getFormatDateNotUtc = (value, format = 'DD-MM-YYYY') => {
  return dayjs(value).format(format);
};

export const getProjectStatus = (id, title) => {
  const index = defaultValues.projectsStatues.findIndex(
    (e) => e.id === id || (title && e.title.toLowerCase() === title.toLowerCase())
  );
  if (index >= 0) {
    return defaultValues.projectsStatues[index];
  }
};

export const getTaskStatus = (id) => {
  const item = defaultValues.taskColorCode.find((e) => e.id === id);
  return item;
};

export const getInviteStatus = (id, statuses) => {
  let arr = statuses || defaultValues.inviteStatus;
  const index = arr.findIndex((e) => e.id === id);
  if (index >= 0) {
    return arr[index];
  }
};

export const getProjectFlagsTitle = (id) => {
  const index = defaultValues.projectsFlags.findIndex((i) => i.id === id);
  if (index >= 0) {
    return defaultValues.projectsFlags[index].title;
  }
};

// this function created for check with uri there image exits or not in backend
export const isExitsURL = async (uri) => {
  try {
    if (!uri || uri === '') return false;
    const response = await axios.get(`${API.baseURL[API.currentEnv]}${uri}`);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getUserRoleId = (rolName) => {
  const userData = defaultValues.userRoles.filter((item) => item.name === rolName);
  if (userData && userData.length === 1) {
    return userData[0].id;
  }
};

export const truncateText = (text, maxLength) => {
  if (text && text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

export const formatDate = (dateString) => {
  return dayjs(dateString).format('DD MM YY');
};

export const getTaskStatuses = (statusCode) => {
  const index = defaultValues.taskStatus.findIndex((e) => e.statusCode === statusCode);
  if (index >= 0) {
    return defaultValues.taskStatus[index];
  }
};

export const getUserRole = (userType) => {
  return defaultValues.userRoles.find((item) => item.name === userType).id;
};

//function to get time from date

export const getFormattedTime = (date) => {
  const timestamp = dayjs(date);
  const formattedTime = timestamp.format('hh:mm A');
  return formattedTime;
};

// to get random color for notification
export const getRandomColor = (colors) => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const getValueWithId = (options, id) => {
  let value;
  options.forEach((item) => {
    if (item.id === id) {
      value = item.name;
      return;
    }
  });
  return value;
};

export const getUserRoleName = (userType) => {
  return defaultValues.userRoles.find((item) => item.id === userType).name;
};

export const getOptionsFromList = (arr) => {
  const options = [
    { filterType: 'Language', value: 'languages', option: [] },
    { filterType: 'Skills', value: 'skills', option: [] },
    { filterType: 'Timezone', value: 'timezones', option: [] }
  ];

  arr.forEach((subArr, index) => {
    subArr.forEach((item) => {
      options[index].option.push({
        name: item.language_title || item.title || item.timezone || item.item,
        value: item.id
      });
    });
  });

  const op = options.map((item) => {
    if (item.filterType === 'Timezone') {
      return {
        ...item,
        option: sortTimeZone(item.option, 'name')
      };
    } else {
      return item;
    }
  });

  return op;
};

export const filterFun = (arr) => {
  return arr.filter((item, index, self) => {
    return index === self.findIndex((s) => s.id === item.id);
  });
};

export const formatKeyName = (key, includeAfterUnderscore = false) => {
  const parts = key.split('_');
  if (includeAfterUnderscore && parts.length > 1) {
    return parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
  } else {
    return parts.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
};

export const trimText = (text) => {
  if (text.endsWith('ly')) {
    return text.slice(0, -2);
  } else {
    return text;
  }
};

export const calculatePercentage = (chartData, data) => {
  if (chartData && data) {
    const activeValue = chartData.find((item) => item.type === 'active').value || 0;
    const totalValue = data.find((item) => item.type === 'total').value || 1;
    return `${((activeValue / totalValue) * 100).toFixed()}%`;
  } else {
    return '';
  }
};

export const sortAlpha = (arr, title) => {
  if (arr && arr.length > 0 && title) {
    const newArr = [...arr].sort((a, b) => {
      const titleA = a[title] && a[title].toUpperCase();
      const titleB = b[title] && b[title].toUpperCase();

      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
    return newArr;
  } else {
    return [];
  }
};

export const sortTimeZone = (arr, title) => {
  if (arr && arr.length > 0 && title) {
    const newArr = [...arr].sort((a, b) => {
      const centerPartA = extractCenterPart(a[title]);
      const centerPartB = extractCenterPart(b[title]);

      if (centerPartA < centerPartB) {
        return -1;
      }
      if (centerPartA > centerPartB) {
        return 1;
      }
      return 0;
    });
    return newArr;
  } else {
    return [];
  }
};

function extractCenterPart(timezone) {
  const parts = timezone.split(' ');
  if (parts.length > 1) {
    const centralPart = parts[1].trim();
    return centralPart;
  }
  return timezone;
}

export const finalList = (arr1, arr2) => {
  if (arr1 && arr2) {
    const newArr = arr2.filter((item) => !arr1.includes(item && item.id ? item.id : ''));
    return newArr;
  } else {
    return [];
  }
};

export const removeTime = (currentDate) =>
  `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(
    currentDate.getDate()
  ).padStart(2, '0')}T00:00:00.000Z`;

// can be used to get formated date and time from  time stamps
export const formatDateTime = (createdAt) => {
  const dateObject = new Date(createdAt);
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear().toString().slice(-2);
  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
};

// time coverting from one timezone to another
export const convertTimeToTimeZone = (
  time,
  originalTimeZoneName,
  targetTimeZoneName,
  timezones
) => {
  let originalTimeZone = timezones.find((item) => item.label === originalTimeZoneName);
  let targetTimeZone = timezones.find((item) => item.label === targetTimeZoneName);

  if (!originalTimeZone) {
    originalTimeZone = originalTimeZoneName;
  }
  if (!targetTimeZone) {
    targetTimeZone = targetTimeZoneName;
  }

  const parsedTime = dayjs.tz(time, originalTimeZone.value);
  const convertedTime = parsedTime.clone().tz(targetTimeZone.value);
  const formattedTime = convertedTime.utc(true).format();
  const inFormat = convertedTime.format('DD-MM-YYYY hh:mm A');

  return { formattedTime, inFormat };
};

export function convertDateTimeFormat(inputDateTime) {
  // Parse the input date-time string
  const parsedDateTime = new Date(inputDateTime);

  // Get the individual components of the parsed date-time
  const year = parsedDateTime.getUTCFullYear();
  const month = ('0' + (parsedDateTime.getUTCMonth() + 1)).slice(-2); // Add 1 to month because months are zero-indexed
  const day = ('0' + parsedDateTime.getUTCDate()).slice(-2);
  const hours = ('0' + parsedDateTime.getUTCHours()).slice(-2);
  const minutes = ('0' + parsedDateTime.getUTCMinutes()).slice(-2);
  const seconds = ('0' + parsedDateTime.getUTCSeconds()).slice(-2);
  const milliseconds = ('00' + parsedDateTime.getUTCMilliseconds()).slice(-3);

  // Construct the new date-time string in the desired format
  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

  return formattedDateTime;
}
