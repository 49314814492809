import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

export const Style = {
  lightBlue: color.palette.lightBlue,
  white: color.primaryBackground,
  margin: {
    top: 10,
    right: defaultValues.isResponsive ? 20 : 30,
    left: defaultValues.isResponsive ? -20 : 20,
    bottom: 10
  }
};
