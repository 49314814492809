import React from 'react';
import { Typography } from '@mui/material';
import { fonts } from '../../theme/fonts';
import { color } from '../../theme/color';
import { mergeAll, flatten } from 'ramda';
import { defaultValues } from '../../constants';

const BASE = {
  fontFamily: fonts.primary,
  fontSize: 20,
  color: color.primaryText
};

const variants = {
  default: BASE,

  header: {
    ...BASE,
    fontFamily: fonts.header,
    fontSize: 56,
    fontWeight: 400
  },

  secondaryHeader: {
    ...BASE,
    fontFamily: fonts.header,
    fontSize: 40,
    fontWeight: 550
  },

  bold: {
    ...BASE,
    fontWeight: 'bold'
  },
  semiBold: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 20,
    fontWeight: 600
  },
  subTitle: {
    ...BASE,
    fontFamily: fonts.header,
    fontSize: 32,
    fontWeight: 500
  },

  sectionTitle: {
    ...BASE,
    fontFamily: fonts.primary,
    fontWeight: 550,
    fontSize: 32
  },
  sectionTitleBold: {
    ...BASE,
    fontFamily: fonts.primary,
    fontWeight: 'bold',
    fontSize: 32
  },
  menuText: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 28
  },
  menuTextBold: {
    ...BASE,
    fontFamily: fonts.primary,
    fontWeight: 'bold',
    fontSize: 28
  },
  fieldError: {
    ...BASE,
    fontSize: defaultValues.isResponsive ? 8 : 14,
    fontFamily: fonts.primary,
    color: color.palette.primaryRed
  },

  responsiveText: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 14
  },

  fileName: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 24
  },
  desc: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 20
  },
  small: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 18
  },
  smallBold: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 18,
    fontWeight: 600
  },
  smallLight: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 18,
    color: color.palette.ligherBlack
  },
  resSmallLight: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 12,
    color: color.palette.ligherBlack
  },
  body: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 24
  },
  lightBody: {
    ...BASE,
    fontFamily: fonts.primary,
    color: color.palette.ligherBlack
  },
  responsiveDesc: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 12
  },
  responsiveDescBold: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: '12px',
    fontWeight: 600
  },
  medium: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 16
  },
  mediumLight: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 16,
    color: color.palette.ligherBlack
  },
  title: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 24,
    fontWeight: 550
  },
  titleBold: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: '0.5px'
  },
  subHeading: {
    ...BASE,
    fontFamily: fonts.primary,
    fontWeight: 600,
    letterSpacing: '0.5px',
    fontSize: 48
  },
  noData: {
    ...BASE,
    fontSize: defaultValues.isResponsive ? 12 : 20,
    fontFamily: fonts.primary,
    color: color.palette.gray,
    fontWeight: 600
  },
  tableTitle: {
    ...BASE,
    leadingTrim: 'both',
    fontFamily: fonts.primary,
    fontSize: '22px',
    fontWeight: '400px',
    lineHeight: '142.428 % px'
  },
  projectTitle: {
    ...BASE,
    fontSize: '40px',
    fontWeight: '400px',
    lineHeight: '142.428 % px'
  },
  projectSubTitle: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 32,
    fontWeight: 400
  },
  question: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 24,
    fontWeight: 600
  },
  answer: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 20,
    fontWeight: 400
  },
  answerBold: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 20,
    fontWeight: 600
  },
  cardTitle: {
    ...BASE,
    fontFamily: fonts.primary,
    fontWeight: 600,
    fontSize: 16
  },
  cardSubTitle: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 16,
    fontWeight: 400
  },
  extraSmall: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 10
  },
  resDesc: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 10
  },
  cardResTitle: {
    ...BASE,
    fontFamily: fonts.primary,
    fontWeight: 600,
    fontSize: 12
  },
  cardResSubTitle: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: 12,
    fontWeight: 400
  },
  resExtraSmall: {
    ...BASE,
    fontFamily: fonts.primary,
    fontSize: '6px'
  }
};

export const Text = (props) => {
  const { variant = 'default', style: styleOverride, children, ...rest } = props;

  const style = mergeAll(flatten([variants[variant] || variants.default, styleOverride]));

  return (
    <Typography sx={style} {...rest}>
      {children}
    </Typography>
  );
};
