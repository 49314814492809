import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Stack, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { Screen, Text, UIBackButton, UIButton } from '../../../ui-kit';
import { VPStyle } from './styles';
import { Email, Phone, Place, WhatsApp } from '@mui/icons-material';
import { customStyles } from '../../../theme';
import {
  EDIT_KEY,
  SCREEN_PATH,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../../constants';
import profilePic from '../../../assets/images/profilePic.png';
import { color } from '../../../theme';
import {
  chatLoadingStatus,
  getAdminErrorMessage,
  getAdminSuccessMessage,
  getChatErrorMessage,
  getChatSuccessMessage,
  getProjecsErrorMessage,
  getProjectsSuccessMessage,
  userInfo
} from '../../../services/redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { viewUserProfile } from '../../../services/api';
import {
  getFormatDate,
  getPhotoUrl,
  getUserRoleId,
  getValueWithId,
  resetMessge
} from '../../../utils';
import { ChipsList, ProjectStatusIndicator } from '../../../components';
import {
  clearThreads,
  editAdminRequest,
  initiateChatRequest,
  pushCustomerRequest,
  resetAdminMessages,
  resetChatMessages,
  resetCustomerCompanyMessages,
  resetProjectsMessages,
  resetSupplierMessages,
  setSelectedTab,
  updateProjectManagersRequest,
  updateSupplierRequest
} from '../../../services/redux/slices';
import {
  getSupplierErrorMessage,
  getSupplierSuccessMessage
} from '../../../services/redux/selectors/supplierSelector';
import { toast } from 'react-toastify';
import {
  getCCompanyErrorMessage,
  getCCompanySuccessMessage
} from '../../../services/redux/selectors/customerCompanySelector';
import { SkypeIcon } from '../../../assets/svg-components/skype';
import star from '../../../assets/icons/Star.png';

export const ViewUserProfilePage = () => {
  const navigation = useNavigate();
  const search = useLocation().search;
  const location = useLocation();
  const searchParams = new URLSearchParams(search);
  const currentUser = useSelector(userInfo);
  const supSuccessMsg = useSelector(getSupplierSuccessMessage);
  const supErrorMsg = useSelector(getSupplierErrorMessage);
  const pmSuccessMsg = useSelector(getProjectsSuccessMessage);
  const pmErrorMsg = useSelector(getProjecsErrorMessage);
  const adminSuccessMsg = useSelector(getAdminSuccessMessage);
  const adminErrorMsg = useSelector(getAdminErrorMessage);
  const custSuccessMsg = useSelector(getCCompanySuccessMessage);
  const custErrorMsg = useSelector(getCCompanyErrorMessage);
  const chatSuccessMsg = useSelector(getChatSuccessMessage);
  const chatErrorMsg = useSelector(getChatErrorMessage);
  const isChatLoading = useSelector(chatLoadingStatus);

  const dispatch = useDispatch();

  const isMobileView = defaultValues.isResponsive;
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [locationData, setLocationData] = useState({});
  const [showActonBtn, setShowActonBtn] = useState(false);
  const [availability, setAvailability] = useState('');

  const responsive = defaultValues.isResponsive;

  useEffect(() => {
    if (supSuccessMsg || custSuccessMsg || pmSuccessMsg || adminSuccessMsg) {
      toast.success(
        getSuccessMessage(supSuccessMsg || custSuccessMsg || pmSuccessMsg || adminSuccessMsg)
      );
      getUserData(searchParams.get('id'), searchParams.get('role'));
    }
    if (supErrorMsg || custErrorMsg || pmErrorMsg || adminErrorMsg || chatErrorMsg) {
      toast.error(
        getErrorMessage(supErrorMsg || custErrorMsg || pmErrorMsg || adminErrorMsg || chatErrorMsg)
      );
    }
    resetMessge(() => dispatch(resetSupplierMessages()));
    resetMessge(() => dispatch(resetCustomerCompanyMessages()));
    resetMessge(() => dispatch(resetProjectsMessages()));
    resetMessge(() => dispatch(resetAdminMessages()));
    resetMessge(() => dispatch(resetChatMessages()));
  }, [
    supSuccessMsg,
    supErrorMsg,
    custErrorMsg,
    custSuccessMsg,
    pmErrorMsg,
    pmSuccessMsg,
    adminErrorMsg,
    adminSuccessMsg,
    chatErrorMsg
  ]);

  useEffect(() => {
    if (currentUser && userData) {
      const currRole = getUserRoleId(currentUser.role);
      if (currRole === 1 && userData.role !== 1) {
        setShowActonBtn(true);
      } else if (currRole === 2 && ![1, 2].includes(userData.role)) {
        setShowActonBtn(false);
      }
    }

    if (userData && userData.availability_type) {
      setAvailability(
        getValueWithId(defaultValues.availabilityOptions, userData.availability_type)
      );
    }
  }, [currentUser, userData]);

  useEffect(() => {
    if (searchParams.get('id') && searchParams.get('role')) {
      getUserData(searchParams.get('id'), searchParams.get('role'));
    }
  }, [currentUser]);

  useEffect(() => {
    if (location.state) {
      setLocationData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    if (['S-10111', 'S-10112'].includes(chatSuccessMsg)) {
      dispatch(setSelectedTab(1));
      navigation(SCREEN_PATH.CHAT_SCREEN, {
        state: {
          oppUserId: userData.id
        }
      });
    }
  }, [chatSuccessMsg]);

  const getUserData = useCallback(
    async (id, role) => {
      setIsLoading(true);
      const response = await viewUserProfile({ id, role });
      if (response.data.status === SUCCESS_KEY) {
        let user = { ...response.data.data };

        let address = [user.locality, user.city, user.state, user.country];
        address = address.filter((e) => e !== '' && e !== null).join(', ');
        address += user.post_code ? ` -  ${user.post_code}` : '';
        address += user.timezone ? ` ( ${user.timezone} )` : '';
        setUserData({ ...user, address });
      }
      setIsLoading(false);
    },
    [userData]
  );

  const handleBackButton = () => {
    if (!location.state || !location.state.route) {
      navigation(-1);
    } else {
      navigation(location.state.route, { state: { ...locationData } });
    }
  };

  const onChangeStatus = () => {
    if (locationData && locationData.type === defaultValues.listConstants.customers) {
      const data = {
        payload: { id: searchParams.get('id'), active: !userData.status },
        type: EDIT_KEY
      };
      dispatch(pushCustomerRequest(data));
      getUserData(searchParams.get('id'), searchParams.get('role'));
    }

    if (locationData && locationData.type === defaultValues.listConstants.supplier) {
      let payload = { supplier_id: searchParams.get('id'), status: !userData.status };

      dispatch(updateSupplierRequest(payload));
      getUserData(searchParams.get('id'), searchParams.get('role'));
    }

    if (locationData && locationData.type === defaultValues.listConstants.projectManager) {
      let payload = { id: searchParams.get('id'), active: !userData.status };
      dispatch(updateProjectManagersRequest(payload));
      getUserData(searchParams.get('id'), searchParams.get('role'));
    }

    if (locationData && locationData.type === defaultValues.listConstants.admin) {
      let payload = { id: searchParams.get('id'), active: !userData.status };
      dispatch(editAdminRequest(payload));
      getUserData(searchParams.get('id'), searchParams.get('role'));
    }
  };

  const handleChat = () => {
    dispatch(clearThreads());
    dispatch(initiateChatRequest({ send_to: userData.id }));
  };

  return (
    <Screen showSideBar={true} currentPage="View Profile">
      <LoadingScreen isLoading={isLoading || isChatLoading} />
      {userData && (
        <>
          <Box sx={VPStyle.mainBox}>
            <Box sx={VPStyle.container}>
              <Box sx={VPStyle.contentContainer}>
                {!responsive && <UIBackButton onClick={handleBackButton} />}
                {responsive && (
                  <Box sx={VPStyle.topNav}>
                    <UIBackButton onClick={handleBackButton} />
                    {showActonBtn && (
                      <UIButton
                        title={userData.status ? 'Deactivate' : 'Reactivate'}
                        style={VPStyle.activeButton(userData.status)}
                        onClick={onChangeStatus}
                      />
                    )}
                  </Box>
                )}

                <Box sx={VPStyle.logoBox}>
                  <Avatar
                    alt=""
                    style={VPStyle.logoSize}
                    src={userData.photo_url ? getPhotoUrl(userData.photo_url) : profilePic}
                  />
                </Box>
                <Box sx={VPStyle.infoBox}>
                  <Text style={VPStyle.comName}>
                    {userData.first_name + ' ' + userData.last_name}
                  </Text>
                  <Text variant="smallLight">
                    Date of Association - {getFormatDate(userData.date_of_association)}
                  </Text>
                  <Text style={VPStyle.statusStyle(userData.status)}>
                    {userData.status ? 'Active' : 'Inactive'}
                  </Text>
                  {userData.rating && (
                    <Stack sx={VPStyle.stackStyle}>
                      <img src={star} style={VPStyle.imgStyle} />
                      <Text
                        variant={
                          defaultValues.isResponsive
                            ? 'medium'
                            : defaultValues.isMedium
                              ? 'small'
                              : 'answer'
                        }>
                        {userData.rating.toFixed(1)}
                      </Text>
                    </Stack>
                  )}
                  <Box sx={VPStyle.contactSuper}>
                    <Box sx={VPStyle.contactContainer}>
                      <Box sx={VPStyle.emailBox}>
                        <Email style={VPStyle.mailIcon} />
                        <Text style={VPStyle.emailText} variant="small">
                          {userData.email}
                        </Text>
                      </Box>
                      {userData.skype_id && (
                        <Box sx={VPStyle.emailBox}>
                          <SkypeIcon />

                          <Text style={VPStyle.emailText} variant="small">
                            {userData.skype_id}
                          </Text>
                        </Box>
                      )}
                    </Box>
                    <Box sx={VPStyle.contactContainer}>
                      {userData.telephone_number && (
                        <Box sx={VPStyle.emailBox}>
                          <Phone style={VPStyle.mailIcon} />
                          <Text style={VPStyle.emailText} variant="small">
                            {userData.telephone_number}
                          </Text>
                        </Box>
                      )}
                      {userData.whatsapp_number && (
                        <Box sx={VPStyle.emailBox}>
                          <WhatsApp style={VPStyle.mailIcon} />
                          <Text style={VPStyle.emailText} variant="small">
                            {userData.whatsapp_number}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  {[3, 4].includes(userData.role) && userData.address && (
                    <Box sx={VPStyle.emailBox}>
                      <Place style={VPStyle.mailIcon} />
                      <Text style={VPStyle.emailText} variant="small">
                        {userData.address}
                      </Text>
                    </Box>
                  )}
                  <UIButton
                    disabled={!userData.status}
                    style={VPStyle.chatBtn}
                    title="Chat"
                    onClick={handleChat}
                  />
                  {!responsive && showActonBtn && (
                    <UIButton
                      title={userData.status ? 'Deactivate' : 'Reactivate'}
                      style={VPStyle.activeButton(userData.status)}
                      onClick={onChangeStatus}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {userData.role === defaultValues.listConstants.four && (
            <Box sx={VPStyle.mainBox}>
              <Box sx={VPStyle.container}>
                <Text variant={responsive ? 'desc' : 'menuText'}>Additional information</Text>
                <Box sx={VPStyle.availablity}>
                  <Box sx={VPStyle.availablitySub}>
                    <Text
                      variant={defaultValues.isResponsive && 'cardResTitle'}
                      style={VPStyle.font}>
                      {'Availability - '}
                    </Text>
                    <Text variant={defaultValues.isResponsive && 'responsiveDesc'}>
                      {availability.length > 0 && availability}
                    </Text>
                  </Box>
                  <Box sx={VPStyle.padding}>
                    <Text
                      variant={defaultValues.isResponsive && 'cardResTitle'}
                      style={VPStyle.font}>
                      Working Schedule
                    </Text>
                    <Text
                      variant={defaultValues.isResponsive ? 'extraSmall' : 'small'}
                      style={{ color: color.palette.emailGray }}>
                      {userData && userData.availability_details}
                    </Text>
                  </Box>
                </Box>
                {!responsive ? (
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={VPStyle.rBorder}>
                          <ChipsList
                            keyName="skill_title"
                            list={userData.skills}
                            title="Skills"
                            labelColor={color.palette.purple}
                          />
                        </TableCell>
                        <TableCell>
                          <ChipsList
                            keyName="language_title"
                            list={userData.languages}
                            title="Languages"
                            labelColor={color.palette.skyBlue}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={VPStyle.bNone}>
                          <ChipsList
                            keyName={['symbol', 'currency']}
                            list={[userData.currency]}
                            title="Currency"
                            labelColor={color.palette.secondaryOrange}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ) : (
                  <Box sx={VPStyle.resAddBox}>
                    <ChipsList
                      keyName="skill_title"
                      list={userData.skills}
                      title="Skills"
                      labelColor={color.palette.purple}
                      style={VPStyle.bBorder}
                    />
                    <ChipsList
                      keyName="language_title"
                      list={userData.languages}
                      title="Languages"
                      labelColor={color.palette.skyBlue}
                      style={VPStyle.bBorder}
                    />

                    <ChipsList
                      keyName={['symbol', 'currency']}
                      list={[userData.currency]}
                      title="Currency"
                      labelColor={color.palette.secondaryOrange}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {[3, 4, 5].includes(userData.role) && (
            <Box sx={VPStyle.mainBox}>
              <Box sx={VPStyle.container}>
                <Text variant={responsive ? 'desc' : 'menuText'}>Projects Associated</Text>
                {userData &&
                userData.associated_projects &&
                userData.associated_projects.length == 0 ? (
                  <Box sx={VPStyle.notFoundBox}>
                    <Text variant="lightBody" style={VPStyle.subTitle}>
                      {getErrorMessage('E-10094')}
                    </Text>
                  </Box>
                ) : (
                  <>
                    {userData &&
                      userData.associated_projects &&
                      userData.associated_projects.map((item, i) =>
                        !isMobileView ? (
                          <Box key={i} sx={VPStyle.listTile}>
                            <Box sx={VPStyle.tileBox}>
                              <Text variant="bold" style={VPStyle.noText}>
                                {i + 1}
                              </Text>
                              <Box>
                                <Text
                                  variant="bold"
                                  style={{
                                    ...customStyles.link,
                                    ...customStyles.sTitle
                                  }}
                                  onClick={() => {
                                    navigation(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                                      state: { data: item }
                                    });
                                  }}>
                                  {item.project_title}
                                </Text>
                                <Text variant="smallLight">{item.service_title}</Text>
                              </Box>
                            </Box>
                            <ProjectStatusIndicator title={item.status} />
                          </Box>
                        ) : (
                          <Box key={i} sx={VPStyle.resBox}>
                            <Box sx={VPStyle.resTitle}>
                              <Text
                                variant="bold"
                                style={{
                                  ...VPStyle.w70,
                                  ...customStyles.link,
                                  ...customStyles.sTitle
                                }}
                                onClick={() => {
                                  navigation(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                                    state: { data: item }
                                  });
                                }}>
                                {item.project_title}
                              </Text>
                              <ProjectStatusIndicator title={item.status} />
                            </Box>
                            <Text variant="smallLight">{item.service_title}</Text>
                          </Box>
                        )
                      )}
                  </>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
    </Screen>
  );
};
