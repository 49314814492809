import { ACCESS_KEY, ADD_KEY, API } from '../../constants';
import { createAxiosInstance } from './axiosConfig';

const defaultHeader = {
  'Content-type': 'application/json'
};

const multipartHeaders = {
  'Content-Type': 'multipart/form-data'
};

const setToken = (val) => {
  localStorage.setItem(ACCESS_KEY, val);
};

const getToken = () => localStorage.getItem(ACCESS_KEY);

const authHeader = () => getToken() && `Bearer ${getToken()}`;

export function signIn(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.request_login_otp,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function verifyLoginRequest(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.verify_login,
    method: 'POST',
    headers: defaultHeader,
    data: values
  }).then((response) => {
    setToken(response.data.auth_token);
    return response;
  });
}

export function otpResend(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.resend_login_otp,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export async function FuctionDashBoard(params) {
  return createAxiosInstance({
    url: API.auth_urls.admin_dashboard,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getSkills(params) {
  return createAxiosInstance({
    url: API.auth_urls.get_skills,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...params }
  });
}

export async function addSkills(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_skills,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function editSkills(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_skills,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}
export async function getLanguagesApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.get_languages,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...params }
  });
}
export async function addLanguageApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_languages,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function editLanguageApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_languages,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getCurrenciesApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.get_currencies,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...params }
  });
}

export async function addCurrencyApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_currencies,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function editCurrencyApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_currencies,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getCountryApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.get_countries,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...params }
  });
}

export async function addCountryApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_countries,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function editCountryApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_countries,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function pushStateApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.state,
    method: data.type === ADD_KEY ? 'POST' : 'PATCH',
    headers: { Authorization: authHeader() },
    data: data.payload
  });
}

export async function getServicesApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.get_services,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getAdminsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.get_admins,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...params }
  });
}

export async function addAdminsApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_admins,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: data
  });
}

export async function editAdminsApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_admins,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getMasterListAPI() {
  return createAxiosInstance({
    url: API.auth_urls.master_list,
    method: 'GET',
    headers: { Authorization: authHeader() }
  });
}

export async function addMasterListAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.master_list,
    method: data.type === ADD_KEY ? 'POST' : 'PATCH',
    headers: { Authorization: authHeader() },
    data: data.payload
  });
}

export function enquiryOtpAPI(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.request_enquiry_otp,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function enquiryOtpVerifyAPI(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.verify_enquiry_otp,
    method: 'POST',
    headers: defaultHeader,
    data: values
  }).then((response) => {
    setToken(response.data.auth_token);
    return response;
  });
}

export function resendEnquiryOtpAPI(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.resend_enquiry_otp,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export async function searchCustomerCompanyAPI(regNo) {
  return createAxiosInstance({
    url: API.auth_urls.search_customer_company,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { registration_number: regNo.registration_number }
  });
}

export async function registerCustomerCompanyAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.register_customer_company,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export async function submitEnquiryAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.submit_enquiry,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export async function pushServiceAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.services,
    method: data.type === ADD_KEY ? 'POST' : 'PATCH',
    headers: { Authorization: authHeader() },
    data: data.payload
  });
}

export async function pushServiceQaAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.services_question,
    method: data.type === ADD_KEY ? 'POST' : 'PATCH',
    headers: { Authorization: authHeader() },
    data: data.payload
  });
}

export async function getTimeZoneApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_timeZone,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: data
  });
}

export async function getProjectsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.get_projects,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...params }
  });
}

export async function getProjectStatusApi() {
  return createAxiosInstance({
    url: API.auth_urls.project_status,
    method: 'GET',
    headers: { Authorization: authHeader() }
  });
}

export async function updateProjectStatusApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.update_project_status,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data
  });
}

export async function getCustomerCompanyApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.customer_company,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function pushCustomerCompaniesApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.customer_company,
    method: data.type === ADD_KEY ? 'POST' : 'PATCH',
    headers: { Authorization: authHeader() },
    data: data.payload
  });
}

export async function getCustomerApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.customer,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getCompanyNoteApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.company_note,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function pushCompanyNoteApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.company_note,
    method: data.type === ADD_KEY ? 'POST' : 'PATCH',
    headers: { Authorization: authHeader() },
    data: data.payload
  });
}

export async function pushCustomerApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.customer,
    method: data.type === ADD_KEY ? 'POST' : 'PATCH',
    headers: { Authorization: authHeader() },
    data: data.payload
  });
}

export async function editTimeZoneAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_timeZone,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function addTimeZoneAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_timeZone,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getProfileApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.get_profile,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: data
  });
}

export async function editProfileApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.update_profile,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data
  });
}

export async function changeProPicApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.profile_picture,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: data
  });
}

export async function deleteProPicApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.profile_picture,
    method: 'DELETE',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export function supplierRegisterApi(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.supplier_registration,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function verifySupplierApi(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.verify_supplier,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function resendSupplierOtpApi(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.resend_supplier_otp,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export async function getSuppliersAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.suppliers,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getProjectSuppliersAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.supplierList,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function updateSuppliersAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.updateSuppliers,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data
  });
}

export async function approvalSuppliersAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.approvalSuppliers,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data
  });
}

export async function getCustomerConpanyDetails() {
  return createAxiosInstance({
    url: API.auth_urls.customer_company_details,
    method: 'GET',
    headers: { Authorization: authHeader() }
  });
}

export async function addTeamMemberAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.add_team_member,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function editTeamMemberAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.edit_team_member,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function editCustomerCompanyAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.edit_custome_company_details,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: data
  });
}

export async function associateCustomerCompanyAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.associate_customer_company,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export async function approveTeamMemberAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.approve_team_member,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function sendQuotationApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.quotation,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: data
  });
}

export async function viewQuotationApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.quotation,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: data
  });
}

export async function acceptQuotationApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.accept_quotation,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function rejectQuotationApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.reject_quotation,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function resendQuotationApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.quotation,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: data
  });
}

export async function viewUserProfile(params) {
  return createAxiosInstance({
    url: API.auth_urls.viewUserProfile,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getProjectManagerApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.project_manager,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: data
  });
}

export async function createProjectManagerApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.project_manager,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: data
  });
}

export async function updateProjectManagerApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.project_manager,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getStatusIdApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.get_status_id,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getProjectDetailsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.project_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}
export async function getSupplierRecomendationAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.supplier_recommendation,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { project_id: params }
  });
}

export async function supplierInvitationAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.supplier_invitation,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: data
  });
}

export async function postJobsAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.post_jobs,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function projectInvitationAPI() {
  return createAxiosInstance({
    url: API.auth_urls.project_invitation,
    method: 'GET',
    headers: { Authorization: authHeader() }
  });
}

export async function acceptRejectInvitationAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.project_invitation,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getJobsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.post_jobs,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...params }
  });
}

export async function updateJobStatusApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.post_jobs,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: { ...params }
  });
}

export async function getJobsApplicantsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.job_applicants,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...params }
  });
}

export async function acceptRejectJobApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.job_applicants,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function applyJobApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.apply_job,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function assignPMApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.assign_pm,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function removePMApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.remove_pm,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function removeSupplierApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.remove_supplier,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function createTaskListAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.task_lists,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getTaskListAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.task_lists,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getProjectDocumentsApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.documents,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: data
  });
}

export async function uploadProjectDocumentsApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.documents,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: data
  });
}

export async function getProjectMembersAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.project_members,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function createTaskAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.task,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getTaskAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.task,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function updateTaskListAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.task_lists,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function updateTaskListStatusAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.task_list_status,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function updateTaskStatusAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.task_status,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function updateTaskAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.task,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function deleteCompanyLogoApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.company_logo,
    method: 'DELETE',
    headers: { Authorization: authHeader() },
    data: params
  });
}

export async function getTaskShedulesAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.task_shedules,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getTaskDetailsAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.task_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getNotificationCountAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.notification_count,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function initiateChatApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.personal_threads,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: params
  });
}

export async function getThreadsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.personal_threads,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getNotificationAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.notification,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getMessagesApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.personal_messages,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function updateNotificationAPI(data) {
  return createAxiosInstance({
    url: API.auth_urls.notification,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function conversationApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.personal_messages,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: params
  });
}

export async function unreadStatusApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.update_read_status,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: params
  });
}

export async function postListCommentsApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.list_comments,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getListCommentsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.list_comments,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function postTaskCommentsApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.task_comments,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function feedbackApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.feedback,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: data
  });
}

export async function supplierFeedbackApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.supplier_feedback,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function customerFeedbackApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.customer_feedback,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: data
  });
}

export async function getTaskCommentsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.task_comments,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function deleteProjectDocumentApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.documents,
    method: 'DELETE',
    headers: { Authorization: authHeader() },
    data: params
  });
}

export async function sendRatingApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.rating,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: params
  });
}

export async function editRatingApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.rating,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: params
  });
}

export async function initiateProjectChatApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.project_threads,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: params
  });
}

export async function getProjectThreadsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.project_threads,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getProjectMessagesApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.project_messages,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function projectConversationApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.project_messages,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: params
  });
}

export async function customerDashBoardAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.customer_dashboard,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function supplierDashBoardAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.supplier_dashboard,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function projectManagerDashBoardAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.project_manager_dashboard,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getProjectsLogApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.projects_logs,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function getActiveProjectsApi(params) {
  return createAxiosInstance({
    url: API.auth_urls.active_projects,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: params
  });
}

export async function updateProjectAPI(params) {
  return createAxiosInstance({
    url: API.auth_urls.update_project,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: params
  });
}
