import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const adminProfileStyles = {
  profileContainer: {
    width: '100%',
    display: 'flex'
  },
  profilePicContainer: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginRight: '10px'
  },
  profilePic: {
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  },
  email: {
    color: color.palette.emailGray,
    fontSize: responsive ? '10px' : md ? '14px' : '16px'
  },
  name: {
    color: color.palette.primaryBlack,
    fontSize: responsive ? '15px' : md ? '16px' : '20px'
  },
  textContainer: {
    height: responsive ? '100%' : 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
};
