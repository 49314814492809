import { Box } from '@mui/material';
import { defaultValues } from '../../../constants';
import { Text, UIAutocompleted, UIButton, UITextInputWithTitle } from '../../../ui-kit';
import { useFormik } from 'formik';
import { bookServiceSchema } from '../../../utils';
import { Styles } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {
  associateCompanyRequest,
  masterListRequest,
  registerCompanyRequest
} from '../../../services/redux/slices';
import {
  getMasterList,
  getRegNo,
  getRegisteredCompanyDetails,
  isCompanyRegisterd
} from '../../../services/redux/selectors';

export const CompanyDetails = () => {
  const dispatch = useDispatch();
  const masterList = useSelector(getMasterList);
  const registerNumber = useSelector(getRegNo);
  const isRegistered = useSelector(isCompanyRegisterd);
  const companyDetails = useSelector(getRegisteredCompanyDetails);

  const [companyType, setCompanyType] = useState([]);

  const { values, handleBlur, handleChange, errors, touched, setFieldValue, setValues } = useFormik(
    {
      initialValues: {
        regNo: '',
        companyName: '',
        companyType: '',
        first_name: '',
        last_name: '',
        charity_no: '',
        company_id: ''
      },
      validationSchema: bookServiceSchema,
      validateOnChange: true,
      validateOnBlur: true
    }
  );

  const getId = (val, options, title) => {
    let id;
    options.map((item) => {
      if (item[title] === val) {
        id = item.id;
        return;
      }
    });
    return id;
  };

  const handleNext = () => {
    const payload = {
      registration_number: values.regNo,
      company_name: values.companyName,
      company_type_id: values.companyType,
      first_name: values.first_name,
      last_name: values.last_name,
      charity_no: values.charity_no
    };
    if (isRegistered) {
      dispatch(
        associateCompanyRequest({
          company_id: values.company_id,
          first_name: values.first_name,
          last_name: values.last_name
        })
      );
    } else {
      dispatch(registerCompanyRequest(payload));
    }
  };

  useEffect(() => {
    dispatch(masterListRequest());
  }, [dispatch]);

  useEffect(() => {
    if (masterList.length) {
      const companyTypeList = masterList.find((item) => item.list_name === 'Company-type');
      if (companyTypeList && companyTypeList.list_items) {
        setCompanyType(companyTypeList.list_items);
      }
    }

    if (companyDetails) {
      setValues({
        ...values,
        companyName: companyDetails.company_name || '',
        charity_no: companyDetails.charity_no || '',
        company_id: companyDetails.id || ''
      });
    }
  }, [masterList, companyDetails]);

  useEffect(() => {
    if (registerNumber) {
      setFieldValue('regNo', registerNumber);
    }
  }, [registerNumber]);

  const handleSelectedValue = useCallback(
    (value) => {
      const type = getId(value, companyType, defaultValues.listConstants.item);
      setFieldValue('companyType', type);
    },
    [companyType, defaultValues.listConstants.item]
  );

  return (
    <Box>
      <Box sx={Styles.subContainer(defaultValues.isResponsive)}>
        <Text
          style={Styles.title(defaultValues.isResponsive)}
          variant={defaultValues.isResponsive ? 'body' : 'secondaryHeader'}>
          Company details
        </Text>

        <UITextInputWithTitle
          title={'Registration number'}
          name="regNo"
          value={values.regNo}
          variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
          style={Styles.fieldStyle(defaultValues.isResponsive)}
          disabled
        />
        <Box sx={Styles.nameContainer(defaultValues.isResponsive)}>
          <UITextInputWithTitle
            title={
              <span>
                First name
                <span style={Styles.spanStyle}>*</span>
              </span>
            }
            name="first_name"
            placeholder="enter your first name"
            variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
            value={values.first_name}
            style={Styles.fieldStyleName(defaultValues.isResponsive)}
            onBlur={handleBlur}
            error={errors.first_name && errors.first_name}
            onChange={handleChange}
            touched={touched.companyName}
            allowOnlyAlphabets={true}
          />
          <UITextInputWithTitle
            title={
              <span>
                Last name
                <span style={Styles.spanStyle}>*</span>
              </span>
            }
            name="last_name"
            placeholder="enter your last name"
            variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
            value={values.last_name}
            style={Styles.fieldStyleName(defaultValues.isResponsive)}
            onBlur={handleBlur}
            error={errors.last_name && errors.last_name}
            onChange={handleChange}
            touched={touched.last_name}
            allowOnlyAlphabets={true}
          />
        </Box>
        <UITextInputWithTitle
          title={
            <span>
              Company name
              <span style={Styles.spanStyle}>*</span>
            </span>
          }
          name="companyName"
          placeholder="enter your company name"
          variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
          value={values.companyName}
          style={Styles.fieldStyle(defaultValues.isResponsive)}
          onBlur={handleBlur}
          error={errors.companyName && errors.companyName}
          onChange={handleChange}
          touched={touched.companyName}
          disabled={isRegistered}
        />

        {companyDetails && companyDetails.company_type && companyDetails.company_type ? (
          <UITextInputWithTitle
            title={
              <span>
                Company type
                <span style={Styles.spanStyle}>*</span>
              </span>
            }
            variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
            style={Styles.fieldStyle(defaultValues.isResponsive)}
            name="companyType"
            value={companyDetails.company_type.type}
            disabled={isRegistered}
          />
        ) : (
          <UIAutocompleted
            title={'Company type'}
            variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
            options={companyType && companyType.map((item) => item.item)}
            placeholder="select company name"
            name="companyType"
            error={errors.companyType}
            onBlur={handleBlur}
            touched={values.companyType}
            required={true}
            addShowButton={false}
            selectedValue={handleSelectedValue}
          />
        )}

        <UITextInputWithTitle
          title={`Charity id (optional)`}
          name="charity_no"
          placeholder="enter charity id"
          variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
          value={values.charity_no}
          style={Styles.fieldStyle(defaultValues.isResponsive)}
          onBlur={handleBlur}
          error={errors.charity_no && errors.charity_no}
          onChange={handleChange}
          touched={touched.charity_no}
          disabled={isRegistered}
        />
      </Box>

      <Box sx={Styles.buttonContainer(defaultValues.isResponsive)}>
        <UIButton
          disabled={
            !values.companyName.length ||
            !values.first_name.length ||
            !values.last_name.length ||
            (!isRegistered && !values.companyType)
          }
          title={'Next'}
          sx={Styles.buttonStyle(defaultValues.isResponsive)}
          onClick={handleNext}
        />
      </Box>
    </Box>
  );
};
