import { useState } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { AddQuestionStyle } from '../style';
import { Text, TextInput, UIButton } from '../../../ui-kit';
import {
  ArrowDropDown,
  CheckBox,
  FormatAlignLeft,
  RadioButtonChecked,
  ShortText
} from '@mui/icons-material';
import { defaultValues } from '../../../constants';

export const AddQuestionForm = ({
  id,
  name,
  question,
  questionValue,
  onSelectQa,
  questionType,
  onAddOptions,
  key,
  onBlur,
  questionMenu
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleQuestion = (data) => {
    onSelectQa(data);
    if ([3, 4].includes(data.answer_type)) {
      onAddOptions({ option: '', sub_questions: [], active: true, editable: true });
    }
    setAnchorEl(null);
  };

  const getMenuIcon = (question) => {
    if (question === defaultValues.questionTypes[0].answer_type) {
      return <ShortText sx={AddQuestionStyle.menuIcon} />;
    } else if (question === defaultValues.questionTypes[1].answer_type) {
      return <FormatAlignLeft sx={AddQuestionStyle.menuIcon} />;
    } else if (question === defaultValues.questionTypes[2].answer_type) {
      return <RadioButtonChecked sx={AddQuestionStyle.menuIcon} />;
    } else if (question === defaultValues.questionTypes[3].answer_type) {
      return <CheckBox sx={AddQuestionStyle.menuIcon} />;
    }
    return <></>;
  };

  return (
    <Box sx={AddQuestionStyle.flexBox} key={key}>
      <Box sx={{ flex: 1 }}>
        <TextInput
          id={id}
          name={name}
          variant="outlined"
          onBlur={onBlur}
          value={question}
          onChange={(e) => {
            questionValue(e.target.value);
          }}
          placeholder="type your question here"
          style={AddQuestionStyle.qaInput}
        />
      </Box>
      <UIButton
        disabled={question === ''}
        title={
          questionType ? (
            <Box sx={AddQuestionStyle.btnFlex}>
              {getMenuIcon(questionType.answer_type)}
              <Text
                variant={defaultValues.isResponsive ? 'responsiveDesc' : 'medium'}
                style={AddQuestionStyle.fontClr}>
                {questionType.title}
              </Text>
            </Box>
          ) : (
            'select question type'
          )
        }
        style={AddQuestionStyle.qaBtn}
        endIcon={<ArrowDropDown sx={{ fontSize: 40 }} />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        aria-expanded={open ? 'true' : undefined}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{ width: '500px' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        {questionMenu.map((item) => (
          <MenuItem
            key={item.title}
            onClick={() => handleQuestion(item)}
            sx={AddQuestionStyle.menuItem}>
            {getMenuIcon(item.answer_type)}
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
