import { color } from './color';

/*
For all the styles objects which are commonly used accross the application
*/

export const customStyles = {
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'space-between',
    justifyContent: 'center'
  },
  w100: { width: '100%' },
  ml: (xSize) => ({
    marginRight: `${15 * xSize}px`
  }),
  mr: (xSize) => ({
    marginRight: `${15 * xSize}px`
  }),
  txtC: { textAlign: 'center' },
  bold: { fontWeight: 'bold' },
  w550: { fontWeight: '550' },
  borderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'space-between',
    justifyContent: 'center',
    padding: '15px',
    border: `1px solid ${color.palette.borderColor}`,
    borderRadius: '8px'
  },
  commonContainer: {
    padding: '2%',
    boxSizing: 'border-box',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '5px'
  },
  wordBr: {
    wordBreak: 'break-all'
  },
  link: {
    '&:hover': { color: color.palette.linkBlue },
    textDecoration: 'underline',
    cursor: 'pointer',
    color: color.palette.emailGray
  },
  sTitle: {
    color: color.palette.primaryBlack
  },
  actionCont: {
    width: '300px'
  },
  actionSub: {
    justifyContent: 'space-start'
  }
};
