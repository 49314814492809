import { color } from '../../../theme/color';

export const CollapseStyles = {
  container: {
    marginBottom: '10px',
    border: `1px solid ${color.primaryBorder}`,
    borderBottom: 'none',
    borderRadius: '5px'
  },
  listContainer: {
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    marginTop: '10px'
  },
  expandIcon: {
    height: '24px',
    width: '24px',
    backgroundColor: color.palette.primaryRed,
    color: color.palette.primaryWhite,
    borderRadius: '50%'
  },
  symbol: {
    marginLeft: '35%',
    textAlign: 'center'
  },
  admin1: {
    marginLeft: '25%'
  },
  admin2: {
    marginLeft: '27.5%'
  },
  actionButtonContainer: {
    width: '40%'
  },
  actionValue: {
    width: '60%'
  },
  newStateButton: {
    color: color.palette.primaryWhite,
    backgroundColor: color.palette.primaryRed,
    padding: 0,
    margin: 0,
    fontSize: '10px'
  },
  maxWidth: { maxWidth: '60vw' },
  takeOwnership: {
    backgroundColor: color.palette.lightBlue,
    padding: '4px',
    fontSize: '10px',
    marginLeft: '42%'
  },
  summeryBox: {
    background: color.palette.secondaryWhite
  },
  iconStyle: {
    height: '40px',
    width: '40px',
    marginRight: '15px',
    borderRadius: '50%'
  }
};
