import { color } from '../../../theme/color';

export const Styles = {
  formContainer: (isRes) => ({
    minWidth: 120,
    width: isRes ? '100%' : '80%'
  }),
  selectArea: {
    backgroundColor: color.palette.textFieldRed
  },

  title: (isRes) => ({ fontWeight: '500', color: isRes && color.palette.primaryRed }),
  label: {
    marginTop: '20px',
    marginBottom: '10px'
  },
  fieldStyle: (isRes) => ({
    inputContainer: { width: isRes ? '100%' : '80%', marginTop: '25px' },
    title: { fontSize: isRes ? '16px' : '28px', mb: '5px' }
  }),
  fieldStyleName: (isRes) => ({
    inputContainer: { width: isRes ? '100%' : '48%', marginTop: '25px' },
    title: { fontSize: isRes ? '16px' : '28px', mb: '5px' }
  }),
  buttonStyle: (isRes) => ({
    backgroundColor: color.palette.primaryRed,
    marginTop: '30px',
    color: color.palette.primaryWhite,
    fontSize: isRes ? '14px' : '24px',
    width: isRes && '100%',
    height: isRes && '40px',
    padding: '2px 70px'
  }),
  subContainer: (isRes) => !isRes && { minHeight: '80vh' },
  buttonContainer: (isRes) => isRes && { height: '45vh', display: 'flex', alignItems: 'flex-end' },
  nameContainer: (isRes) => ({
    display: 'flex',
    width: isRes ? '100%' : '80%',
    flexDirection: isRes ? 'column' : 'row',
    justifyContent: !isRes && 'space-between'
  }),
  spanStyle: { color: color.palette.red, marginLeft: '5px' }
};
