import { color } from '../../../theme';

export const RenderFile = ({ filePath, handleDownload }) => {
  const styles = {
    pdfView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px'
    },
    link: {
      color: color.palette.primaryBlue,
      cursor: 'pointer'
    },
    imgCont: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    img: { maxWidth: '100%', maxHeight: '100%' }
  };
  const extension = filePath.split('.').pop().toLowerCase();

  switch (extension) {
    case 'pdf':
      return (
        <object
          data={filePath}
          style={styles.pdfView}
          type="application/pdf"
          width="100%"
          height="100%">
          <p>
            <u style={styles.link} onClick={handleDownload}>
              click here
            </u>
            &nbsp;to view or download the file
          </p>
        </object>
      );
    case 'doc':
    case 'docx':
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
          width="100%"
          height="100%"
        />
      );
    case 'jpeg':
    case 'jpg':
    case 'png':
      return (
        <div style={styles.imgCont}>
          <img src={filePath} style={styles.img} alt="image not found" />
        </div>
      );
    default:
      return <p>Unsupported file format</p>;
  }
};
