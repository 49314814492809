import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { Text, UIButton } from '../../../ui-kit';
import { StatusIndicator } from '../../other-settings/table-title';
import { MTStyles } from './styles';
import { getFormatDate, getUserRole } from '../../../utils';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { useEffect, useState } from 'react';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { customStyles } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearThreads, initiateChatRequest, setSelectedTab } from '../../../services/redux/slices';
import { getChatSuccessMessage } from '../../../services/redux/selectors';

export const TeamMemberTable = ({ listData }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const chatSuccessMsg = useSelector(getChatSuccessMessage);

  const [expanded, setExpanded] = useState(false);
  const roleId = getUserRole(defaultValues.userRole.customer);

  useEffect(() => {
    if (['S-10111', 'S-10112'].includes(chatSuccessMsg)) {
      dispatch(setSelectedTab(1));
      navigation(SCREEN_PATH.CHAT_SCREEN);
    }
  }, [chatSuccessMsg]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handelInfo = (id) => {
    navigation(`${SCREEN_PATH.VIEW_USER_PROFILE}?role=${roleId}&id=${id}`, {
      state: { type: defaultValues.listConstants.customers }
    });
  };

  const handleChat = (id) => {
    dispatch(clearThreads());
    dispatch(initiateChatRequest({ send_to: id }));
  };

  return !defaultValues.isResponsive ? (
    <Table
      sx={{
        ...MTStyles.tableContainer,
        borderCollapse: 'collapse'
      }}
      aria-label="simple table">
      <TableHead sx={MTStyles.tableHead}>
        <TableRow>
          <TableCell sx={MTStyles.headingCell} align="left">
            Member
          </TableCell>
          <TableCell sx={MTStyles.headingCell} align="left">
            Date of association
          </TableCell>
          <TableCell sx={MTStyles.headingCell} align="left">
            Status
          </TableCell>
          <TableCell sx={MTStyles.headingCell} align="left">
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listData &&
          listData.length > 0 &&
          listData.map((item, index) => (
            <TableRow key={index}>
              <TableCell sx={MTStyles.maxW} align="left">
                <Box>
                  <Text
                    variant="small"
                    onClick={() => handelInfo(item.id)}
                    style={{ ...customStyles.link, ...customStyles.sTitle }}>
                    {item.first_name + ' ' + item.last_name}
                  </Text>
                  <Text variant="mediumLight">{item.email}</Text>
                </Box>
              </TableCell>
              <TableCell align="left">
                <Text variant="smallLight" style={MTStyles.dos}>
                  {getFormatDate(item.date_of_association)}
                </Text>
              </TableCell>
              <TableCell align="left">
                <StatusIndicator status={item.active} />
              </TableCell>
              <TableCell align="left" sx={MTStyles.actionMax}>
                <Box sx={MTStyles.actionBox}>
                  <UIButton
                    title="Chat"
                    style={MTStyles.chatBtn}
                    disabled={!item.active}
                    onClick={() => handleChat(item.user_id)}
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  ) : (
    <>
      {listData &&
        listData.map((item, index) => (
          <Accordion
            key={index}
            sx={MTStyles.resContainer}
            expanded={expanded === index}
            onChange={handleChange(index)}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={MTStyles.expandIcon} />}
              aria-controls={index}
              sx={MTStyles.summeryBox}
              id={index}>
              <Box>
                <Text
                  variant="medium"
                  style={{ ...MTStyles.maxWidth, ...customStyles.link, ...customStyles.sTitle }}
                  onClick={() => handelInfo(item.id)}>
                  {item.first_name + ' ' + item.last_name}
                </Text>
                <Text variant="responsiveDesc">{item.email}</Text>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={customStyles.w100}>
                <Box sx={MTStyles.itemContainer}>
                  <Box sx={MTStyles.actionButtonContainer}>
                    <Text variant="responsiveDesc">Date of association</Text>
                  </Box>
                  <Text variant="responsiveDesc">{getFormatDate(item.date_of_association)}</Text>
                </Box>

                <Box sx={MTStyles.itemContainer}>
                  <Box sx={MTStyles.actionButtonContainer}>
                    <Text variant="responsiveDesc">Status</Text>
                  </Box>
                  <StatusIndicator status={item.active} />
                </Box>

                <Box sx={MTStyles.itemContainer}>
                  <Box sx={MTStyles.actionButtonContainer}>
                    <Text variant="responsiveDesc">Action</Text>
                  </Box>
                  <Box sx={MTStyles.resActionBox}>
                    <UIButton
                      size="small"
                      title="Chat"
                      style={MTStyles.chatBtn}
                      disabled={!item.active}
                      onClick={() => handleChat(item.user_id)}
                    />
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};
