import React from 'react';

import { style } from './styles';
import { Header, Sidebar } from '../../components';
import { defaultValues } from '../../constants';

function UserScreenWithHeader(props) {
  return (
    <div style={style.container}>
      <Header {...props} />
      {props.children}
    </div>
  );
}

function ScreenWithSidebar(props) {
  return (
    <div style={style.sidebarContainer}>
      {!defaultValues.isResponsive && <Sidebar />}
      <div style={style.subContainer(defaultValues.isResponsive)}>
        <Header {...props} />
        <div style={style.childrenContainer}>{props.children}</div>
      </div>
    </div>
  );
}

function UserScreenWithOutHeader(props) {
  return <div style={style.container}>{props.children}</div>;
}

export const Screen = (props) => {
  if (props.showHeader) {
    return <UserScreenWithHeader {...props} />;
  } else if (props.showSideBar) {
    return <ScreenWithSidebar {...props} />;
  } else {
    return <UserScreenWithOutHeader {...props} />;
  }
};
