import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MSupStyle } from './styles';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import {
  ActionButton,
  Filter,
  IconsButton,
  PaginationCustom,
  Screen,
  SearchBar,
  Sort,
  Text
} from '../../../ui-kit';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  ApprovalIndicator,
  CollapsableList,
  CustomModal,
  NoData,
  StatusIndicator
} from '../../../components';
import { color, customStyles } from '../../../theme';
import { Check, Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSupplierErrorMessage,
  getSupplierSuccessMessage,
  supplierLoadingStatus,
  getSuppliersList,
  getSupplierTotalPg,
  getSupplierCurrentPg,
  getSupplierIndicator,
  getSupplierParams
} from '../../../services/redux/selectors/supplierSelector';
import { toast } from 'react-toastify';
import {
  approvalSupplierRequest,
  getSupplierRequest,
  resetSupplierMessages,
  updateSupplierParams,
  updateSupplierRequest
} from '../../../services/redux/slices';
import { getUserRole } from '../../../utils';

export const ManageSuppliersPage = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(supplierLoadingStatus);
  const curPage = useSelector(getSupplierCurrentPg);
  const successMessage = useSelector(getSupplierSuccessMessage);
  const errorMessage = useSelector(getSupplierErrorMessage);
  const supplierSel = useSelector(getSuppliersList);
  const totalPage = useSelector(getSupplierTotalPg);
  const indicator = useSelector(getSupplierIndicator);
  const supParams = useSelector(getSupplierParams);

  const [supplierList, setSupplierList] = useState([]);
  const [filter, setFilter] = useState({});
  const [editSupplier, setEditSupplier] = useState(null);
  const roleId = getUserRole(defaultValues.userRole.supplier);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    dispatch(resetSupplierMessages());
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (indicator) {
      getSupplerList({ page: curPage });
    }
  }, [indicator]);

  useEffect(() => getSupplerList({ page: curPage }), []);

  const getSupplerList = useCallback(
    (data) => {
      let payload = { page: curPage || 1 };
      if (data !== undefined) {
        payload = {
          ...payload,
          ...supParams,
          ...data
        };
      }
      setFilter(payload);
      dispatch(updateSupplierParams(payload));
      dispatch(getSupplierRequest(payload));
    },
    [filter, supplierList]
  );

  useEffect(() => {
    setSupplierList(supplierSel);
  }, [supplierSel]);

  const handleSearch = (search_text) => {
    (search_text.length === 0 || search_text.length > 2) &&
      getSupplerList({ search_text, showLoading: false });
  };

  const filterData = (data) => {
    const newData = data.map((obj) => {
      for (const key in obj) {
        if (obj[key] === 'null') obj[key] = null;
      }
      return obj;
    });
    getSupplerList({ filter: JSON.stringify(newData), page: 1 });
  };

  const handleApproved = (payload) => {
    dispatch(approvalSupplierRequest(payload));
    setEditSupplier(null);
  };

  const onChangeStatus = (supplier_id, status) => {
    const payload = { supplier_id, status };
    dispatch(updateSupplierRequest(payload));
  };

  const actionMenu = (row) => {
    return (
      <Box sx={MSupStyle.actionBox}>
        {row.isApproved !== null ? (
          <ActionButton
            item={row}
            isEdit={false}
            onButtonClick={() => onChangeStatus(row.id, !row.isActive)}
            disabled={row.isApproved === 0}
            name={`${row.first_name} ${row.last_name}`}
          />
        ) : (
          <Box sx={MSupStyle.actionBtn}>
            <>
              <IconsButton
                iconStyle={MSupStyle.actionIcon(color.palette.green)}
                icon={<Check />}
                toolTip="Approve"
                style={MSupStyle.bNone}
                onClick={() => {
                  setEditSupplier({
                    name: `${row.first_name} ${row.last_name}`,
                    payload: { supplier_id: row.id, approval_status: 1 }
                  });
                }}
              />
              <IconsButton
                iconStyle={MSupStyle.actionIcon(color.palette.red)}
                icon={<Close />}
                toolTip="Reject"
                style={MSupStyle.bNone}
                onClick={() =>
                  setEditSupplier({
                    name: `${row.first_name} ${row.last_name}`,
                    payload: { supplier_id: row.id, approval_status: 0 }
                  })
                }
              />
            </>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Screen showSideBar={true} currentPage="Manage Suppliers">
      <Box sx={MSupStyle.mainBox}>
        <Box sx={MSupStyle.container}>
          <LoadingScreen isLoading={isLoading} />
          <Box sx={MSupStyle.contentContainer}>
            <Box sx={MSupStyle.searchTabContainer}>
              <Box sx={MSupStyle.searchToolsContainer}>
                {!defaultValues.isResponsive ? (
                  <SearchBar
                    label="search for a supplier"
                    searchedVlaue={handleSearch}
                    onRefresh={!supParams.search_text && {}}
                    defaultValue={supParams.search_text}
                  />
                ) : (
                  <Text sx={MSupStyle.headingText} variant="semiBold">
                    Manage Suppliers
                  </Text>
                )}
              </Box>
              <Box sx={MSupStyle.toolsContainer}>
                <IconsButton
                  title={!defaultValues.isResponsive ? null : 'Refresh'}
                  icon={<RefreshIcon sx={MSupStyle.icon} />}
                  onClick={() => {
                    setFilter({});
                    getSupplerList({ sort: null, filter: null, page: null, search_text: null });
                  }}
                />
                <Sort
                  selectedOption={(sort) => getSupplerList({ sort, page: 1 })}
                  activeOption={filter.sort}
                />
                <Filter
                  label={'Filter'}
                  applyFilter={filterData}
                  options={defaultValues.approvedFilterOption}
                  activeData={filter && filter.filter}
                />
              </Box>
            </Box>

            {defaultValues.isResponsive && (
              <Box sx={MSupStyle.searchContainer}>
                <SearchBar
                  label={'Search Supplier'}
                  searchedVlaue={handleSearch}
                  onRefresh={!supParams.search_text && {}}
                  defaultValue={supParams.search_text}
                />
              </Box>
            )}

            {!defaultValues.isResponsive ? (
              supplierList.length > 0 ? (
                <Table sx={MSupStyle.tableContainer} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={MSupStyle.headingCell} align="left">
                        Supplier name
                      </TableCell>
                      <TableCell sx={MSupStyle.headingCell} align="left">
                        Account status
                      </TableCell>
                      <TableCell sx={MSupStyle.headingCell} align="left">
                        Approval status
                      </TableCell>
                      <TableCell sx={MSupStyle.headingCell} align="center">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {supplierList.map((row, index) => {
                      return (
                        <TableRow key={index} sx={MSupStyle.tableRow}>
                          <TableCell scope="row">
                            <Box sx={MSupStyle.word_b}>
                              <Text
                                variant="desc"
                                disabled={row.isApproved === 0}
                                onClick={
                                  row.isApproved === 0
                                    ? null
                                    : () => {
                                        navigation(
                                          `${SCREEN_PATH.VIEW_USER_PROFILE}?role=${roleId}&id=${row.id}`,
                                          {
                                            state: {
                                              type: defaultValues.listConstants.supplier
                                            }
                                          }
                                        );
                                      }
                                }
                                style={
                                  row.isApproved !== 0 && {
                                    ...customStyles.link,
                                    ...customStyles.sTitle
                                  }
                                }>{`${row.first_name} ${row.last_name}`}</Text>
                              <Text variant="small" style={{ color: color.palette.gray }}>
                                {row.email}
                              </Text>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <StatusIndicator status={row.isActive} />
                          </TableCell>
                          <TableCell align="left">
                            <ApprovalIndicator status={row.isApproved} />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ ...customStyles.actionCont, ...MSupStyle.maxWidth }}>
                            {actionMenu(row)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <NoData message={getErrorMessage('E-10091')} />
              )
            ) : (
              <Box sx={MSupStyle.collapseContainer}>
                {supplierList && (
                  <CollapsableList
                    contentArray={supplierList}
                    type={defaultValues.listConstants.supplier}
                    isEdit={false}
                    loaderSize={defaultValues.isResponsive && 15}
                    supplierTitleStyle={(row) =>
                      row.isApproved !== 0
                        ? {
                            ...customStyles.link,
                            ...customStyles.sTitle
                          }
                        : { ...customStyles.sTitle }
                    }
                    childComment={(row) => (
                      <Box>
                        <Box sx={MSupStyle.collBox1}>
                          <Text style={MSupStyle.w37} variant="responsiveDesc">
                            Approval status
                          </Text>
                          <ApprovalIndicator status={row.isApproved} />
                        </Box>
                        <Box sx={MSupStyle.collBox2}>
                          <Text style={MSupStyle.w37} variant="responsiveDesc">
                            Action
                          </Text>
                          {actionMenu(row)}
                        </Box>
                      </Box>
                    )}
                  />
                )}
              </Box>
            )}

            {curPage && totalPage && (
              <Box sx={MSupStyle.paginationContainer}>
                <PaginationCustom
                  onChange={(e, page) => getSupplerList({ page })}
                  currentPage={curPage}
                  count={totalPage}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <CustomModal
        open={editSupplier}
        onClose={() => setEditSupplier(false)}
        title={defaultValues.changeStatusModal.title}
        message={
          editSupplier &&
          `${editSupplier.name} approval will be ${
            editSupplier.payload.approval_status === 1 ? 'accepted' : 'rejected'
          }`
        }
        btnType={defaultValues.changeStatusModal.btn}
        onCancel={() => setEditSupplier(false)}
        onConfirm={() => handleApproved(editSupplier.payload)}
      />
    </Screen>
  );
};
