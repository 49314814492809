import { Avatar, Box } from '@mui/material';
import { getPhotoUrl } from '../../../utils';
import { Text } from '../../../ui-kit';
import { Styles } from './style';
import CloseIcon from '@mui/icons-material/Close';
import { defaultValues } from '../../../constants';

export const MemberCard = ({
  name,
  profile,
  onClick,
  showDelete,
  onDelete = () => {},
  containerStyle = {}
}) => {
  return (
    <Box sx={{ ...Styles.container, ...containerStyle }} onClick={onClick}>
      <Box sx={Styles.imgContainer}>
        <Avatar style={Styles.imgStyle} src={profile ? getPhotoUrl(profile) : ''} alt="" />
      </Box>
      <Box sx={Styles.nameContainer}>
        <Text
          variant={defaultValues.isResponsive ? 'resExtraSmall' : 'small'}
          style={Styles.textStyle}>
          {name}
        </Text>
      </Box>
      {showDelete && (
        <CloseIcon
          sx={Styles.closeIcon}
          fontSize={defaultValues.isResponsive ? 'small' : 'medium'}
          onClick={onDelete}
        />
      )}
    </Box>
  );
};
