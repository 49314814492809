export const SCREEN_PATH = {
  HOME: '/',
  LOGIN: '/login',
  ENQUIRE: '/enquire',
  SUPPLIER_REGISTER: '/supplier-register',
  LANDING_PAGE: '/landing-page',
  SIGN_IN_PAGE: '/signin-page',
  ADMIN_DASHBOARD: '/admin-dashboard',
  MANAGE_SERVICES: '/manage-services',
  MANAGE_PROJECTS: '/manage-projects',
  MANAGE_CUSTOMERS: '/manage-customers',
  MANAGE_SUPPLIERS: '/manage-suppliers',
  MANAGE_ADMINS: '/manage-admins',
  MANAGE_JOBS: '/manage-jobs',
  PROJECT_MANAGERS: '/project-managers',
  PROFILE_SETTINGS: '/profile-settings',
  OTHER_SETTINGS: '/other-settings',
  MANAGE_TEAM: '/manage-team',
  MANAGE_QUOTATION: '/manage-quotation',
  NON_APPROVED_USERS: '/approval-pending',
  VIEW_USER_PROFILE: '/view-user-profile',
  PROJECT_DETAILS: '/project-details',
  VIEW_JOBS: '/view-jobs',
  PROJECT_REQUIREMENTS: '/project-details/requirements',
  MANAGE_INVITATION: '/project-details/supplier-hub/manage-invitations',
  PROJECT_SUPPLIER_HUB: '/project-details/supplier-hub',
  JOB_DETAILS: '/job-details',
  INVITE_SUPPLIER: '/project-details/supplier-hub/invite-supplier',
  SUPPLIER_LIST: '/project-details/supplier-hub/supplier-list',
  PROJECT_TASK_MAN: '/project-details/supplier-hub/task-management',
  PROJECT_TASKS: '/project-details/supplier-hub/task-management/tasks',
  PROJECT_DOCUMENT_MAN: '/project-details/document-management',
  PROJECT_TASKDETAILS: '/project-details/supplier-hub/task-management/tasks/details',
  CALENDER_DOCUMENT_MAN: '/project-details/calender-management',
  NOTIFICATION: '/notification',
  CHAT_SCREEN: '/chat',
  PROJECT_CHAT: '/project-details/project-chat',
  FEEDBACK: '/project-details/feedback',
  PROJECT_LOGS: '/project-details/logs'
};
