/*
 *Commonly used regular expressions
 *
 * Add or remove variables according to your requirement
 */
export const regExp = {
  strLowerCase: /^(?=.*[a-z])/,
  strUpperCase: /^(?=.*[A-Z])/,
  containsNum: /^(?=.*[0-9])/,
  containsSpecialChar: /^(?=.*[!@#$%^&*])/,
  onlyNumbers: /^\d+$/,
  email: /\S+@\S+\.\S+/,
  alphabetRegExp: /^[A-Za-z]+$/,
  pinCode: /^\d{6}$/,
  phoneRegExp:
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
  onlyNum: /^[0-9]*$/
};
