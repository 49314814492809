import { Box } from '@mui/material';
import React from 'react';
import { Text } from '../text';
import { UIRadioButton } from '../radio-button';

export const UIRadioButtonWithTitle = ({
  title,
  onCheck,
  values,
  touched,
  error,
  errorStyle,
  ...rest
}) => {
  const [checked, setChecked] = React.useState('');
  const styles = {
    mainBox: { margin: '20px 0px' },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      flexWrap: 'wrap',
      height: '50px',
      marginBottom: '50px'
    },
    mb: { marginBottom: '15px' },
    flexBox: { display: 'flex', alignItems: 'center' }
  };

  return (
    <Box sx={styles.mainBox} {...rest}>
      <Text variant="semiBold" sx={styles.mb}>
        {title}
      </Text>
      <Box sx={styles.checkbox}>
        {values.map((item) => (
          <Box sx={styles.flexBox} key={item.option}>
            <UIRadioButton
              checked={checked === item.option}
              onChange={() => {
                if (checked === item.option) {
                  // If the item is already selected, removing it
                  setChecked('');
                } else {
                  // If the item is not selected, adding it
                  setChecked(item.option);
                }
                onCheck(item.option, item.id, item.question_id);
              }}
            />

            <Text variant="desc" style={{ marginRight: '80px' }}>
              {item.option}
            </Text>
          </Box>
        ))}
      </Box>
      {error && touched && (
        <Text style={errorStyle} variant="fieldError">
          {error}
        </Text>
      )}
    </Box>
  );
};
