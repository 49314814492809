import { useState } from 'react';
import { ManageAddServices } from './add-services';
import { ManageListServices } from './list-services';

export const ManageServices = () => {
  const [showAddService, setShowAddService] = useState(false);
  const [editService, setEditService] = useState(null);

  return (
    <div>
      {showAddService ? (
        <ManageAddServices onBack={() => setShowAddService(false)} editServiceId={editService} />
      ) : (
        <ManageListServices
          onAddServices={() => {
            setShowAddService(true);
            setEditService(null);
          }}
          onEditServiceId={(id) => {
            setEditService(id);
            setShowAddService(true);
          }}
        />
      )}
    </div>
  );
};
