import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme/color';
const responsive = defaultValues.isResponsive;
export const profileModalStyle = {
  container: {
    position: 'absolute',
    top: '50%',
    left: responsive ? '50%' : '80%',
    transform: responsive ? 'translate(-50%, -50%)' : 'translate(-80%, -50%)',
    width: responsive ? '90%' : '70%',
    backgroundColor: color.primaryBackground,
    borderRadius: '5px',
    boxShadow: 24,
    p: responsive ? 2 : '20px 60px 10px 60px',
    boxSizing: 'border-box',
    maxWidth: '1200px',
    minHeight: responsive ? '520px' : 'none',
    border: 'none'
  },
  headingContainer: {
    width: '100%',
    borderBottom: `1px solid ${color.primaryBorder}`,
    paddingBottom: responsive ? '6px' : '10px',
    boxSizing: 'border-box'
  },
  heading: {
    fontSize: responsive ? '20px' : '30px',
    textAlign: 'left',
    marginBottom: responsive ? '10px' : 'none'
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginBottom: responsive ? '10px' : '10px'
  },
  inputContainer: {
    width: responsive ? '100%' : '60%',
    marginTop: '10px'
  },
  input: {
    width: '100%',
    backgroundColor: color.palette.textFieldRed,
    marginTop: '10px',
    fontSize: responsive ? '14px' : '24px',
    '& .MuiTextField-root': {
      border: 'none'
    }
  },
  errorField: {
    color: color.palette.primaryRed,
    marginTop: '3px',
    fontSize: '14px'
  },
  closeButton: {
    maxWidth: '100%',
    maxHeight: responsive ? '40px' : '50px',
    backgroundColor: color.palette.primaryRed,
    borderRadius: '50%',
    position: 'absolute',
    top: '-25px',
    right: responsive ? '-20px' : '-25px',
    cursor: 'pointer'
  },
  button: {
    backgroundColor: color.palette.primaryRed,
    color: color.palette.primaryWhite,
    width: responsive ? '90%' : 'fit-content',
    marginTop: '30px',
    '&:hover': {
      backgroundColor: color.palette.primaryRed
    },
    position: responsive ? 'absolute' : 'static',
    bottom: '20px',
    fontSize: responsive ? '14px' : '22px',
    minHeight: responsive ? '40px' : '55px',
    minWidth: '180px'
  }
};
