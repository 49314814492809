import { color } from '../../../theme';

export const styles = {
  container: {
    border: `1px solid ${color.primaryBorder}`,
    maxWidth: '250px',
    display: 'flex',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    p: 1,
    boxShadow: 1,
    borderRadius: 1,
    m: 1
  },
  dayStyle: (selectedDays, day) => ({
    height: '25px',
    width: '25px',
    boxShadow: 1,
    borderRadius: 3,
    textAlign: 'center',
    p: '1px',
    backgroundColor: selectedDays.includes(day.value)
      ? color.palette.red
      : color.secondaryBackground,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
};
