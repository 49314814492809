import { Box } from '@mui/material';
import { Text } from '../../../ui-kit';
import { findOutCardStyles } from './findOutCardStyle';
import { defaultValues } from '../../../constants';

export const FindOutCard = (props) => {
  const { icon, heading, description } = props;
  return (
    <Box sx={findOutCardStyles.container}>
      <Box sx={findOutCardStyles.iconContainer}>
        <img style={findOutCardStyles.icon} alt="logo" src={icon} />
      </Box>
      <Text variant={defaultValues.isResponsive ? 'semiBold' : 'secondaryHeader'}>{heading}</Text>
      <Text variant={defaultValues.isResponsive ? 'responsiveText' : 'body'}>{description}</Text>
    </Box>
  );
};
