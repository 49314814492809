import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Text } from '../../../ui-kit/text';
import {
  CommonQuestion,
  CompanyDetails,
  EmailSection,
  EventDetailsSection,
  OtpSection,
  Registration,
  SelectServiceSection
} from '../../../components';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { EnquireStyles } from './styles';

import undrawImage from '../../../assets/images/undraw_events.png';
import { UIBackButton } from '../../../ui-kit';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRightSection,
  getServiceErrorMessage,
  getServiceSuccessMessage,
  serviceLoadingStatus
} from '../../../services/redux/selectors';
import {
  resetRightSection,
  resetServiceMessages,
  setCompanyActive,
  setRightSection
} from '../../../services/redux/slices';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { toast } from 'react-toastify';
import { resetMessge } from '../../../utils';

export const EnquirePage = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const rightSections = useSelector(getRightSection);
  const isLoading = useSelector(serviceLoadingStatus);
  const errorMessage = useSelector(getServiceErrorMessage);
  const successMessage = useSelector(getServiceSuccessMessage);

  const [rightSection, setRightSections] = useState('');
  const [sectionStack, setSectionStack] = useState([]);

  const sectionComponents = {
    email: () => <EmailSection />,
    otp: () => <OtpSection />,
    registration: () => <Registration />,
    companyDetails: () => <CompanyDetails />,
    selectService: () => <SelectServiceSection />,
    eventDetails: () => <EventDetailsSection />,
    commonQuestion: () => <CommonQuestion />
  };

  const handleBackButton = () => {
    let currentIndex = sectionStack.indexOf(rightSection);
    let previousSection = sectionStack[currentIndex - 1];
    if (currentIndex > 0) {
      if (rightSection === 'selectService' || rightSection === 'registration') {
        dispatch(resetRightSection());
        navigation(-1);
      } else {
        dispatch(setRightSection(previousSection));
      }
    } else {
      dispatch(resetRightSection());
      navigation(-1);
    }
  };

  useEffect(() => {
    if (rightSections) {
      setRightSections(rightSections);
      setSectionStack((prevStack) => {
        if (!prevStack.includes(rightSections)) {
          return [...prevStack, rightSections];
        }
        return prevStack;
      });
    }
  }, [rightSections]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      if (errorMessage === 'E-10099') {
        dispatch(setCompanyActive(false));
      } else {
        dispatch(setCompanyActive(true));
      }
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetServiceMessages()));
  }, [successMessage, errorMessage]);

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={EnquireStyles.container(defaultValues.isResponsive)}>
        {defaultValues.isResponsive && (
          <Box sx={EnquireStyles.backBtnContainer}>
            <UIBackButton onClick={handleBackButton} />
          </Box>
        )}
        {(rightSection === 'registration' && defaultValues.isResponsive) ||
        (rightSection === 'companyDetails' && defaultValues.isResponsive) ||
        (rightSection === 'selectService' && defaultValues.isResponsive) ||
        (rightSection === 'eventDetails' && defaultValues.isResponsive) ||
        (rightSection === 'commonQuestion' && defaultValues.isResponsive) ? (
          <></>
        ) : (
          <Box sx={EnquireStyles.leftSide(defaultValues.isResponsive)}>
            {!defaultValues.isResponsive && (
              <Box sx={EnquireStyles.backBtnContainer}>
                <UIBackButton onClick={handleBackButton} />
              </Box>
            )}

            <Box sx={EnquireStyles.leftSubContainer}>
              <Text style={EnquireStyles.heading(defaultValues.isResponsive)}>
                {defaultValues.enquireHeading}
              </Text>
              <Text style={EnquireStyles.description(defaultValues.isResponsive)}>
                {defaultValues.enquireDesc}
              </Text>
              <img style={EnquireStyles.undrawImage} src={undrawImage} />
            </Box>
          </Box>
        )}
        <Box sx={EnquireStyles.rightSide(defaultValues.isResponsive)}>
          {rightSection && sectionComponents[rightSection]()}
        </Box>
      </Box>
    </>
  );
};
