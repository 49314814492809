import { createSlice } from '@reduxjs/toolkit';
import { defaultValues } from '../../../constants';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  token: null,
  user_email: null, //for unauthenticated users, clear when authenticated.
  isEmailVerified: false,
  successMessage: null,
  errorMessage: null,
  userData: null,
  isLoginPage: false,
  userRole: null,
  currentPage: null,
  nextPage: 1,
  activePage: null,
  isCustomerAdmin: null,
  isApproved: null,
  notificationCount: 0,
  notifications: [],
  userId: null,
  user_fullname: null,
  timeZones: []
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    loginRequest(state, action) {
      state.isLoading = true;
      state.errorMessage = null;
      state.user_email = action.payload.email_id;
    },
    loginSuccess(state, action) {
      state.isLoading = false;
      state.isEmailVerified = true;
      state.successMessage = action.payload.status_code;
    },
    loginFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    verificationRequest(state) {
      state.isLoading = true;
      state.errorMessage = null;
      state.successMessage = null;
    },
    verificationSuccess(state, action) {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.userData = action.payload.user_data;
      state.token = action.payload.auth_token;
      state.isEmailVerified = false;
      state.userRole = action.payload.user_data.role;
      state.currentPage = 'My Dashboard';
      state.isCustomerAdmin = action.payload.user_data.is_customer_admin;
      state.isApproved = action.payload.user_data.isApproved;
      state.userId = action.payload.user_data.user_id;
      state.user_fullname = `${action.payload.user_data.first_name} ${action.payload.user_data.last_name}`;
    },
    verificationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    otpResendRequest(state) {
      state.isLoading = true;
      state.errorMessage = null;
    },
    otpResendSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    otpResendFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    logoutRequest(state) {
      state.isAuthenticated = false;
    },
    resetMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    setLoginPage(state, action) {
      state.isLoginPage = action.payload;
      state.isEmailVerified = false;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload.menu;
    },
    updateUserProfilePicture(state, action) {
      state.userData = { ...state.userData, photo_url: action.payload.photo_url };
    },
    updateUserData(state, action) {
      state.userData = action.payload.data;
    },
    clearDP(state) {
      state.userData = { ...state.userData, photo_url: '' };
    },
    getNotificationCountRequest(state) {
      state.isLoading = true;
    },
    getNotificationCountSuccess(state, action) {
      state.isLoading = false;
      state.notificationCount = action.payload.count;
    },
    getNotificationCountFailure(state) {
      state.isLoading = false;
      state.notificationCount = 0;
    },
    getNotificationRequest(state) {
      state.isLoading = true;
    },
    getNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload.data.notifications;
      state.nextPage = action.payload.data.next_page;
      state.activePage = action.payload.data.current_page;
    },
    getNotificationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.notifications = [];
    },
    updateNotificationRequest(state) {
      state.isLoading = true;
    },
    updateNotificationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateNotificationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    authenticationRequest(state, action) {
      state.isAuthenticated = true;
      state.userData = action.payload.user_data;
      state.token = action.payload.auth_token;
      state.isCustomerAdmin = action.payload.user_data.is_customer_admin;
      state.userRole = action.payload.user_data.role;
      state.userId = action.payload.user_data.user_id;
      state.user_fullname = `${action.payload.user_data.first_name} ${action.payload.user_data.last_name}`;
    },
    setTimezoneOptionsValue(state, action) {
      state.timeZones = action.payload;
    },
    autoLoginSupplier(state) {
      state.isApproved = null;
      state.isAuthenticated = true;
      state.userRole = defaultValues.userRole.supplier;
    },
    updateTimezoneOnProfileUpdate(state, action) {
      state.userData.timezone = action.payload.data.timezone_data.timezone;
    }
  }
});

export const {
  loginFailure,
  loginRequest,
  loginSuccess,
  verificationRequest,
  verificationSuccess,
  verificationFailure,
  otpResendFailure,
  otpResendRequest,
  otpResendSuccess,
  logoutRequest,
  resetMessages,
  setLoginPage,
  setCurrentPage,
  updateUserData,
  updateUserProfilePicture,
  clearDP,
  getNotificationCountRequest,
  getNotificationCountSuccess,
  getNotificationCountFailure,
  getNotificationRequest,
  getNotificationSuccess,
  getNotificationFailure,
  updateNotificationRequest,
  updateNotificationSuccess,
  updateNotificationFailure,
  authenticationRequest,
  autoLoginSupplier,
  setTimezoneOptionsValue,
  updateTimezoneOnProfileUpdate
} = authSlice.actions;

export default authSlice.reducer;
