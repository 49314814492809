/* eslint-disable no-undef */
import { put, takeLatest } from 'redux-saga/effects';
import {
  addCurrencyFailure,
  addCurrencyRequest,
  addCurrencySuccess,
  addLanguageFailure,
  addLanguageRequest,
  addLanguageSuccess,
  addSkillRequest,
  addSkillRequestFailure,
  addSkillRequestSuccess,
  editSkillRequest,
  editSkillRequestFailure,
  editSkillRequestSuccess,
  getSkillRequestSuccess,
  getSkillsRequest,
  getSkillsRequestFailure,
  editCurrencyFailure,
  editCurrencyRequest,
  editCurrencySuccess,
  editLanguageFailure,
  editLanguageRequest,
  editLanguageSuccess,
  getCurrenciesFailure,
  getCurrenciesRequest,
  getCurrenciesSuccess,
  getLanguageRequest,
  getLanguagesFailure,
  getLanguagesSuccess,
  serviceFailure,
  serviceRequest,
  serviceSuccess,
  getCountriesSuccess,
  getCountriesFailure,
  addCountriesSuccess,
  addCountriesFailure,
  editCountriesSuccess,
  editCountriesFailure,
  getCountriesRequest,
  addCountriesRequest,
  editCountriesRequest,
  masterListRequest,
  getMasterListSuccess,
  getMasterListFailure,
  addMasterListRequest,
  addMasterListSuccess,
  addMasterListFailure,
  addServiceRequest,
  addServiceSuccess,
  addServiceFailure,
  pushServiceQaRequest,
  timeZoneRequestSuccess,
  timeZoneRequestFailure,
  getTimeZoneRequest,
  addTimeZoneRequestSuccess,
  addTimeZoneRequestFailure,
  addTimeZoneRequest,
  editTimeZoneRequestSuccess,
  editTimeZoneRequestFailure,
  editTimeZoneRequest,
  pushStateSuccess,
  pushStateFailure,
  pushStateRequest,
  pushServiceQaSuccess,
  pushServiceQaFailure
} from '../slices';
import {
  addSkills,
  editSkills,
  getSkills,
  addCurrencyApi,
  addLanguageApi,
  editCurrencyApi,
  editLanguageApi,
  getCurrenciesApi,
  getLanguagesApi,
  getCountryApi,
  addCountryApi,
  getServicesApi,
  getMasterListAPI,
  addMasterListAPI,
  pushServiceAPI,
  pushServiceQaAPI,
  getTimeZoneApi,
  addTimeZoneAPI,
  editTimeZoneAPI,
  editCountryApi,
  pushStateApi
} from '../../api';
import { SUCCESS_KEY } from '../../../constants';

function* getLanguges(action) {
  try {
    const response = yield getLanguagesApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getLanguagesSuccess(response.data));
    } else {
      yield put(getLanguagesFailure(response.data));
    }
  } catch (error) {
    yield put(getLanguagesFailure('E-10001'));
  }
}

function* addLanguage(action) {
  try {
    const response = yield addLanguageApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addLanguageSuccess(response.data));
    } else {
      yield put(addLanguageFailure(response.data));
    }
  } catch (error) {
    yield put(addLanguageFailure('E-10001'));
  }
}

function* editLanguage(action) {
  try {
    const response = yield editLanguageApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editLanguageSuccess(response.data));
    } else {
      yield put(editLanguageFailure(response.data));
    }
  } catch (error) {
    yield put(editLanguageFailure('E-10001'));
  }
}

function* getAllSkills(action) {
  try {
    const response = yield getSkills(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getSkillRequestSuccess(response.data));
    } else {
      yield put(getSkillsRequestFailure(response.data));
    }
  } catch (error) {
    yield put(getSkillsRequestFailure('E-10001'));
  }
}

function* addSkillsReq(action) {
  try {
    const response = yield addSkills(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addSkillRequestSuccess(response.data));
    } else {
      yield put(addSkillRequestFailure(response.data));
    }
  } catch (error) {
    yield put(addSkillRequestFailure('E-10001'));
  }
}

function* editSkillsReq(action) {
  try {
    const response = yield editSkills(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editSkillRequestSuccess(response.data));
    } else {
      yield put(editSkillRequestFailure(response.data));
    }
  } catch (error) {
    yield put(editSkillRequestFailure('E-10001'));
  }
}
function* getCurrencies(action) {
  try {
    const response = yield getCurrenciesApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getCurrenciesSuccess(response.data));
    } else {
      yield put(getCurrenciesFailure(response.data));
    }
  } catch (error) {
    yield put(getCurrenciesFailure('E-10001'));
  }
}

function* addCurrency(action) {
  try {
    const response = yield addCurrencyApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addCurrencySuccess(response.data));
    } else {
      yield put(addCurrencyFailure(response.data));
    }
  } catch (error) {
    yield put(addCurrencyFailure('E-10001'));
  }
}

export function* editCurrency(action) {
  try {
    const response = yield editCurrencyApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editCurrencySuccess(response.data));
    } else {
      yield put(editCurrencyFailure(response.data));
    }
  } catch (error) {
    yield put(editCurrencyFailure('E-10001'));
  }
}

function* getCountries(action) {
  try {
    const response = yield getCountryApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getCountriesSuccess(response.data));
    } else {
      yield put(getCountriesFailure(response.data));
    }
  } catch (error) {
    yield put(getCountriesFailure('E-10001'));
  }
}

function* addCountry(action) {
  try {
    const response = yield addCountryApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addCountriesSuccess(response.data));
    } else {
      yield put(addCountriesFailure(response.data));
    }
  } catch (error) {
    yield put(addCountriesFailure('E-10001'));
  }
}

export function* editCountry(action) {
  try {
    const response = yield editCountryApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editCountriesSuccess(response.data));
    } else {
      yield put(editCountriesFailure(response.data));
    }
  } catch (error) {
    yield put(addCountriesFailure('E-10001'));
  }
}

export function* pushState(action) {
  try {
    const response = yield pushStateApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(pushStateSuccess(response.data));
    } else {
      yield put(pushStateFailure(response.data));
    }
  } catch (error) {
    yield put(pushStateFailure('E-10001'));
  }
}

function* getServices(action) {
  try {
    const response = yield getServicesApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(serviceSuccess(response.data));
    } else {
      yield put(getSkillsRequestFailure(response.data));
    }
  } catch (error) {
    yield put(serviceFailure('E-10001'));
  }
}

function* getMasterList(action) {
  try {
    const response = yield getMasterListAPI(action.payload);
    if (response.data.status === 'success') {
      yield put(getMasterListSuccess(response.data));
    } else {
      yield put(getMasterListFailure(response.data));
    }
  } catch (error) {
    yield put(getMasterListFailure('E-10001'));
  }
}

function* addMasterList(action) {
  try {
    const response = yield addMasterListAPI(action.payload);
    if (response.data.status === 'success') {
      yield put(addMasterListSuccess(response.data));
    } else {
      yield put(addMasterListFailure(response.data));
    }
  } catch (error) {
    yield put(addMasterListFailure('E-10001'));
  }
}

function* pushService(action) {
  try {
    const response = yield pushServiceAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addServiceSuccess(response.data));
    } else {
      yield put(addServiceFailure(response.data));
    }
  } catch (error) {
    yield put(addServiceFailure('E-10001'));
  }
}

function* pushServiceQa(action) {
  try {
    const response = yield pushServiceQaAPI(action.payload);
    if (response.data.status === SUCCESS_KEY || response.data.status_code === 'S-10024') {
      yield put(pushServiceQaSuccess(response.data));
    } else {
      yield put(pushServiceQaFailure(response.data));
    }
  } catch (error) {
    yield put(pushServiceQaFailure('E-10001'));
  }
}

function* getTimeZone(action) {
  try {
    const response = yield getTimeZoneApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(timeZoneRequestSuccess(response.data));
    } else {
      yield put(timeZoneRequestFailure(response.data));
    }
  } catch (error) {
    yield put(timeZoneRequestFailure('E-10001'));
  }
}

function* addTimeZone(action) {
  try {
    const response = yield addTimeZoneAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addTimeZoneRequestSuccess(response.data));
    } else {
      yield put(addTimeZoneRequestFailure(response.data));
    }
  } catch (error) {
    yield put(addTimeZoneRequestFailure('E-10001'));
  }
}

function* editTimeZone(action) {
  try {
    const response = yield editTimeZoneAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editTimeZoneRequestSuccess(response.data));
    } else {
      yield put(editTimeZoneRequestFailure(response.data));
    }
  } catch (error) {
    yield put(editTimeZoneRequestFailure('E-10001'));
  }
}

export function* settingsSaga() {
  yield takeLatest(getLanguageRequest.type, getLanguges);
  yield takeLatest(addLanguageRequest.type, addLanguage);
  yield takeLatest(editLanguageRequest.type, editLanguage);
  yield takeLatest(getCurrenciesRequest.type, getCurrencies);
  yield takeLatest(addCurrencyRequest.type, addCurrency);
  yield takeLatest(editCurrencyRequest.type, editCurrency);
  yield takeLatest(getSkillsRequest.type, getAllSkills);
  yield takeLatest(addSkillRequest.type, addSkillsReq);
  yield takeLatest(editSkillRequest.type, editSkillsReq);
  yield takeLatest(getCountriesRequest.type, getCountries);
  yield takeLatest(addCountriesRequest.type, addCountry);
  yield takeLatest(editCountriesRequest.type, editCountry);
  yield takeLatest(pushStateRequest.type, pushState);
  yield takeLatest(serviceRequest.type, getServices);
  yield takeLatest(masterListRequest.type, getMasterList);
  yield takeLatest(addMasterListRequest.type, addMasterList);
  yield takeLatest(addServiceRequest.type, pushService);
  yield takeLatest(pushServiceQaRequest.type, pushServiceQa);
  yield takeLatest(getTimeZoneRequest.type, getTimeZone);
  yield takeLatest(addTimeZoneRequest.type, addTimeZone);
  yield takeLatest(editTimeZoneRequest.type, editTimeZone);
}
