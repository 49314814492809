import { createSelector } from 'reselect';

export const getProjectsSuccessMessage = createSelector(
  [(state) => state.project.successMessage],
  (successMessage) => successMessage
);

export const getProjecsErrorMessage = createSelector(
  [(state) => state.project.errorMessage],
  (errorMessage) => errorMessage
);

export const getProjectsLoadingStatus = createSelector(
  [(state) => state.project.isLoading],
  (loadingStatus) => loadingStatus
);

export const getProjects = createSelector(
  [(state) => state.project.projectsList],
  (projectsList) => projectsList
);

export const getProjectsTotalPage = createSelector(
  [(state) => state.project.totalPage],
  (totalPage) => totalPage
);

export const getProjectsCurrentPage = createSelector(
  [(state) => state.project.currentPage],
  (currentPage) => currentPage
);

export const getProjectsStatus = createSelector(
  [(state) => state.project.projectStatus],
  (projectStatus) => projectStatus
);

export const getQuotation = createSelector(
  [(state) => state.project.quotation],
  (quotation) => quotation
);

export const getQuotationId = createSelector(
  [(state) => state.project.quotationId],
  (quotationId) => quotationId
);

export const getProjectManagers = createSelector(
  [(state) => state.project.project_managers],
  (project_managers) => project_managers
);

export const getProjectStatusId = createSelector(
  [(state) => state.project.project_status],
  (status) => status
);

export const getProjectDetailsSel = createSelector(
  [(state) => state.project.project_details],
  (project_details) => project_details
);

export const getProjectsNextPage = createSelector(
  [(state) => state.project.nextPage],
  (nextPage) => nextPage
);

export const getTaskLists = createSelector(
  [(state) => state.project.taskLists],
  (taskLists) => taskLists
);

export const getProjectsIndicator = createSelector(
  [(state) => state.project.indicator],
  (indicator) => indicator
);

export const getProjectParams = createSelector(
  [(state) => state.project.projectParams],
  (projectParams) => projectParams
);

export const getProjectDocuments = createSelector(
  [(state) => state.project.project_documents],
  (project_documents) => project_documents
);

export const projectMembers = createSelector(
  [(state) => state.project.projectMembers],
  (projectMembers) => projectMembers
);

export const projectTasks = createSelector([(state) => state.project.tasks], (tasks) => tasks);

export const getPMParams = createSelector(
  [(state) => state.project.pm_params],
  (pm_params) => pm_params
);

export const getTaskSchedules = createSelector(
  [(state) => state.project.task_schedules],
  (task_schedules) => task_schedules
);
export const getTaskDetails = createSelector(
  [(state) => state.project.taskDetails],
  (taskDetails) => taskDetails
);

export const getComments = createSelector(
  [(state) => state.project.comments],
  (comments) => comments
);

export const getCmntNxtPage = createSelector(
  [(state) => state.project.nextCommentsPage],
  (nextCommentsPage) => nextCommentsPage
);

export const getTaskComments = createSelector(
  [(state) => state.project.taskComments],
  (taskComments) => taskComments
);

export const getTaskCmntNxtPage = createSelector(
  [(state) => state.project.nextTaskCommentsPage],
  (nextTaskCommentsPage) => nextTaskCommentsPage
);

export const getFeedbacks = createSelector(
  [(state) => state.project.feedbacks],
  (feedbacks) => feedbacks
);

export const projectsLog = createSelector([(state) => state.project.logs], (logs) => logs);
