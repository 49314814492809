import { Box } from '@mui/material';
import { Text, UIButton } from '../../ui-kit';
import { defaultValues, getErrorMessage } from '../../constants';
import { supplierOtpStyles } from './styles';
import { useEffect, useState } from 'react';
import forgotPasswordImage from '../../assets/images/forgot_password.png';
import { Otp } from '../common/otp';

export const SupplierOtpForm = ({ onSubmit, onResendOtp, isLoading, email }) => {
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otpSend, setOtpSend] = useState(false);

  useEffect(() => {
    setOtpSend(true);
  }, []);

  const handleOtpChange = (val) => {
    if (!isNaN(Number(val))) {
      setOtp(val);
      setErrorMessage('');
    } else if (!otp) {
      setErrorMessage(getErrorMessage('E-10006'));
    }
  };

  const handleOtpBlur = () => {
    if (otp.length !== 6) {
      setErrorMessage(getErrorMessage('E-10006'));
    }
  };

  const handleResend = () => onResendOtp();

  const handleOtpSend = (val) => {
    setOtpSend(val);
  };
  return (
    <Box sx={supplierOtpStyles.formContainer}>
      {defaultValues.isResponsive && (
        <img style={supplierOtpStyles.undrawImage} src={forgotPasswordImage} />
      )}
      <Text style={supplierOtpStyles.heading} variant="secondaryHeader">
        {defaultValues.supplierOtpHeading}
      </Text>
      <Text style={supplierOtpStyles.desc} variant="menuText">
        {defaultValues.supplierOtpDesc(email)}
      </Text>

      <Otp
        otp={otp}
        onChange={handleOtpChange}
        onBlur={handleOtpBlur}
        otpErrorMessage={errorMessage}
        otpSend={otpSend}
        handleOtpSend={handleOtpSend}
        onResendClick={handleResend}
        otpInputStyle={supplierOtpStyles.otpInputStyles}
        otpContainerStyles={supplierOtpStyles.otpContainer}
        timerStyle={supplierOtpStyles.otpTimer}
      />

      <UIButton
        isLoading={isLoading}
        disabled={otp.length < 6 || errorMessage}
        size="large"
        style={supplierOtpStyles.buttonStyle}
        title="Verify"
        onClick={() => onSubmit(otp)}
      />
    </Box>
  );
};
