import { Avatar, Box } from '@mui/material';
import { color } from '../../../theme';
import { Text } from '../../../ui-kit';
import DP from '../../../assets/images/chatPropic.png';
import { getPhotoUrl, truncateText } from '../../../utils';
import { defaultValues } from '../../../constants';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const styles = {
  container: (sel) => ({
    width: '100%',
    height: '60px',
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: sel ? color.palette.textFieldRed : color.secondaryBackground
  }),
  selected: (selected) => ({
    width: '7px',
    height: '100%',
    backgroundColor: selected ? color.palette.primaryRed : 'none',
    borderRadius: '0px 4px 4px 0px'
  }),
  dotCont: {
    width: '10px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dot: (unread) => ({
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: unread ? color.palette.primaryBlack : 'color'
  }),
  itemCont: {
    flex: 1,
    width: 'calc(100% - 20px)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer'
  },
  lastMessage: {
    color: color.palette.emailGray,
    fontSize: '14px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  fullname: {
    color: color.palette.primaryBlack,
    fontSize: res ? '16px' : md ? '18px' : '20px',
    fontWeight: 600,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  propic: {
    width: res ? '35px' : md ? '40px' : '45px',
    height: res ? '35px' : md ? '40px' : '45px'
  },
  itemSubCont: {
    width: res ? 'calc(100% - 50px)' : md ? 'calc(100% - 55px)' : 'calc(100% - 60px)'
  }
};

export const ThreadCard = ({ item, selected, onClick, type }) => {
  const memberName = () => {
    if (type === defaultValues.listConstants.personel_chat) {
      return (
        item &&
        item.user &&
        item.user.full_name &&
        truncateText(`${item.user.full_name}`, md ? 20 : 15)
      );
    }

    if (type === defaultValues.listConstants.project_chat) {
      let name =
        item &&
        item.users &&
        item.users.map((item) => {
          const elem = item.full_name.split(' ');
          return elem[0];
        });
      return `${name && name.length > 0 ? name.join(', ') : ''}`;
    }
  };

  return (
    <Box sx={styles.container(selected)} onClick={() => onClick()}>
      <Box sx={styles.selected(selected)}></Box>
      <Box sx={styles.dotCont}>
        <Box sx={styles.dot(item && item.unread_messages)}></Box>
      </Box>
      <Box sx={styles.itemCont}>
        <Avatar
          alt={(item && item.user && item.user.fullname && item.user.full_name.toUpperCase()) || ''}
          src={
            item && item.user && item.user.profile_pic && item.user.profile_pic
              ? getPhotoUrl(item.user.profile_pic)
              : DP
          }
          sx={styles.propic}
        />
        <Box sx={styles.itemSubCont}>
          <Text style={styles.fullname}>{memberName()}</Text>

          <Text style={styles.lastMessage}>
            {item &&
              item.last_message &&
              item.last_message.message &&
              `${item.last_message.sender}:${item.last_message.message}`}
            {item.thread_status === 1 && ` (draft)`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
