import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
import { customStyles } from '../../theme';

const responsive = defaultValues.isResponsive;
const sm = defaultValues.isXSmall;
export const Styles = {
  toolsIcons: {
    maxWidth: '100%',
    maxHeight: sm ? '14px' : responsive ? '18px' : '24px',
    marginRight: '6px'
  },
  toolsIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: sm ? '10px' : '10px 20px',
    boxSizing: 'border-box',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    marginLeft: sm ? '4px' : '10px',
    cursor: 'pointer',
    alignItems: 'center',
    marginRight: '5px'
  },
  menuHeader: {
    ...customStyles.flexBox,
    height: '40px',
    width: '100%',
    justifyContent: 'space-between'
  },
  projectFilter: { padding: '0px 10px' },
  contentContainer: {
    display: 'flex',
    height: '100%',
    minHeight: '150px',
    width: '100%'
  },
  subContainer: {
    minWidth: '50px',
    width: '35%',
    backgroundColor: color.palette.secondaryWhite,
    padding: '0px 10px'
  },
  typesText: {
    padding: '10px',
    cursor: 'pointer'
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0'
  },
  applyButton: {
    padding: '5px 20px',
    boxSizing: 'border-Box',
    width: 'fit-content',
    minWidth: 0,
    marginRight: '10px',
    fontSize: '14px'
  },
  applyBtnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    marginTop: 'auto'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: responsive ? 'none' : '350px',
    minHeight: '75%',
    overflowY: 'auto'
  },
  checkBox: { padding: 0 },
  checkboxIcon: {
    fontSize: '25px'
  },
  icon: {
    fontSize: '25px'
  },
  menuItem: {
    fontSize: responsive ? '14px' : '16px'
  },
  divider: { mt: 0.5 },
  menu: (anchorEl) => ({
    background: anchorEl !== null ? color.palette.backDropColor : 'none',
    paddingBottom: '0px'
  }),
  textBreak: { whiteSpace: 'pre-wrap' },
  menuAndBtnContainer: { width: '65%' }
};
