import { color } from '../../../theme/color';

export const EventDetailsStyles = {
  container: {
    width: '100%',
    paddingLeft: '20px',
    boxSizing: 'border-box'
  },
  title: (isRes) => ({ fontWeight: '500', color: isRes && color.palette.primaryRed }),
  label: {
    marginTop: '20px',
    marginBottom: '10px'
  },
  eventDetailsField: {
    width: '90%',
    backgroundColor: color.palette.textFieldRed,
    borderRadius: '4px',
    height: '150px',
    padding: '30px',
    boxSizing: 'border-box',
    fontSize: '24px'
  },
  buttonStyle: (isRes) => ({
    backgroundColor: color.palette.primaryRed,
    marginTop: '30px',
    color: color.palette.primaryWhite,
    fontSize: isRes ? '14px' : '24px',
    width: isRes && '100%',
    height: isRes && '40px',
    padding: '2px 70px'
  }),
  formContainer: (isRes) => ({
    minWidth: 120,
    width: isRes ? '100%' : '80%'
  }),
  selectArea: {
    backgroundColor: color.palette.textFieldRed
  },
  lanContainer: { display: 'flex' },
  chip: {
    backgroundColor: color.palette.primaryRed
  },
  closeIcon: { color: color.primaryBackground, cursor: 'pointer' },
  fieldStyle: (isRes) => ({
    inputContainer: { width: isRes ? '100%' : '80%', marginTop: '25px' },
    title: { mb: '5px' }
  }),
  inputContainer: (isRes) => ({ width: isRes ? '100%' : '80%', marginTop: '25px' }),
  textColor: { color: color.palette.primaryBackground },
  selectTitle: (isRes) => ({
    mB: { fontSize: isRes ? '16px' : '28px' },
    mainBox: { width: isRes ? '100%' : '80%', marginTop: '25px' }
  }),
  datePicker: (isRes) => ({ container: { width: isRes ? '100%' : '80%' } })
};
