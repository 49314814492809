import { Box } from '@mui/material';
import {
  DateAndTimePickerWithTitle,
  Text,
  UIButton,
  UIChipButton,
  UITextInputWithTitle
} from '../../../ui-kit';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { color } from '../../../theme';
import { styles } from './style';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
  convertDateTimeFormat,
  convertTimeToTimeZone,
  jobPostSchema,
  resetMessge
} from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  postJobsRequest,
  resetProjectsMessages,
  setTimezoneOptionsValue
} from '../../../services/redux/slices';
import {
  getAllTimezones,
  getProjecsErrorMessage,
  getProjectsSuccessMessage
} from '../../../services/redux/selectors';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
const labelStyle = 'abbrev';

export const JobPostForm = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const successMessage = useSelector(getProjectsSuccessMessage);
  const errorMessage = useSelector(getProjecsErrorMessage);
  const timezones = useSelector(getAllTimezones);

  const [languages, setLanguages] = useState([]);
  const [skills, setSkills] = useState([]);

  const { values, handleBlur, handleChange, touched, errors, setFieldValue, isValid } = useFormik({
    initialValues: {
      project_id: data && data.id,
      job_title: '',
      start_date: '',
      end_date: '',
      job_description: '',
      sel_start_date: '',
      sel_end_date: ''
    },
    validationSchema: jobPostSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  const { options: timezoneOptions } = useTimezoneSelect({ labelStyle, timezones: allTimezones });
  useEffect(() => {
    dispatch(setTimezoneOptionsValue(timezoneOptions));
  }, [timezoneOptions, dispatch]);

  const handlePost = () => {
    dispatch(postJobsRequest(values));
  };

  useEffect(() => {
    if (data && data.languages) {
      setLanguages(data.languages);
    }
    if (data && data.skills) {
      setSkills(data.skills);
    }
  }, [data]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    resetMessge(() => dispatch(resetProjectsMessages()));
  });

  const handleDate = (selField, dateField, date) => {
    let selDateInUTC = dayjs(date).utc(true).format();
    const updatedTime = convertTimeToTimeZone(
      selDateInUTC,
      defaultValues.uKTimeZone,
      data.event_timezone,
      timezones
    );
    const formatedDateTime = convertDateTimeFormat(updatedTime.formattedTime);
    setFieldValue(dateField, formatedDateTime);
    setFieldValue(selField, selDateInUTC);
  };

  return (
    <Box sx={styles.container}>
      <UITextInputWithTitle
        title={'Project name'}
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        value={data && data.project_name}
        disabled
      />
      <UITextInputWithTitle
        title={'Service name'}
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        value={data && data.service_name}
        disabled
      />
      <UITextInputWithTitle
        title={'Job title'}
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        placeholder="enter job title"
        name="job_title"
        value={values.job_title}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.job_title}
        error={errors.job_title}
      />
      <Box sx={styles.dateContainer}>
        <DateAndTimePickerWithTitle
          label="select start date"
          title={'Start date'}
          style={styles.datePicker}
          onChange={(d) => handleDate('sel_start_date', 'start_date', d)}
          value={values.sel_start_date}
          errors={errors.sel_start_date}
        />
        <DateAndTimePickerWithTitle
          title={'End date'}
          label="select end date"
          style={styles.datePicker}
          onChange={(d) => handleDate('sel_end_date', 'end_date', d)}
          value={values.sel_end_date}
          errors={errors.sel_end_date}
        />
      </Box>
      <UITextInputWithTitle
        title={'Job description'}
        placeholder="type here"
        lines={3}
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        name="job_description"
        value={values.job_description}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.job_description}
        error={errors.job_description}
      />
      {skills.length > 0 && (
        <Box sx={styles.chipContainer}>
          <Text variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>Skills</Text>
          {skills.map((item, index) => (
            <UIChipButton
              key={index}
              backgroundColor={color.palette.primaryRed}
              showDelete={false}
              title={item.skill}
            />
          ))}
        </Box>
      )}
      {languages.length > 0 && (
        <Box sx={styles.chipContainer}>
          <Text variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>Languages</Text>
          {languages.map((item, index) => (
            <UIChipButton
              key={index}
              backgroundColor={color.palette.primaryRed}
              showDelete={false}
              title={item.language}
            />
          ))}
        </Box>
      )}

      <UIButton
        title={'Post'}
        style={styles.btnStyle}
        onClick={handlePost}
        isLoading={isLoading}
        disabled={!isValid || !values.job_description.length || !values.job_title.length}
      />
    </Box>
  );
};
