import { Box, Divider, Stack } from '@mui/material';
import {
  DateCalendarPicker,
  Text,
  TextInput,
  UIButton,
  UICheckBoxTitle,
  UISelectWithTitle
} from '../../../ui-kit';
import { Styles } from './style';
import { defaultValues } from '../../../constants';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useEffect, useState } from 'react';
import { UICheckBox } from '../../../ui-kit/checkbox';
import { getFormatDate, removeTime } from '../../../utils';
import { useDispatch } from 'react-redux';
import { createTaskRequest, updateTaskRequest } from '../../../services/redux/slices';
import { MemberCard } from '../member-card';
import { DayPicker } from '../day-picker';
import dayjs from 'dayjs';

export const CreateToDo = ({ projectMembers, list_id, onCancel, data, showBtn, isEditable }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [repValue, setRepValue] = useState(0);
  const [assignTo, setAssignTo] = useState('');
  const [notifyTo, setNotifyTo] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [tillDate, setTillDate] = useState(null);
  const [customDay, setCustomDay] = useState(null);
  const [customDate, setCustomDate] = useState(null);
  const [description, setDescription] = useState('');
  const [estimatedTime, setEstimatedTime] = useState('');
  const [isPriority, setIsPriority] = useState(false);
  const [showDueDate, setShowDueDate] = useState(false);
  const [showFromDate, setShowFromDate] = useState(false);
  const [showTillDate, setShowTillDate] = useState(false);
  const [showCustomDay, setShowCustomDay] = useState(false);
  const [customDays, setCustomDays] = useState([]);
  const [customDates, setCustomDates] = useState([]);
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [notifiedMembers, setNotifiedMembers] = useState([]);
  const [assignedMembersId, setAssignedMembersId] = useState([]);
  const [notifiedMembersId, setNotifiedMembersId] = useState([]);
  const [showCustomMonth, setShowCustomMonth] = useState(false);

  useEffect(() => {
    if (data) {
      if (data.title) {
        setTitle(data.title);
      }
      if (data.description) {
        setDescription(data.description);
      }
      if (data.estimated_time) {
        setEstimatedTime(data.estimated_time);
      }
      if (data.assigned_to) {
        setAssignedMembers([...data.assigned_to]);
        const assignedUserIds = data.assigned_to.map((member) => member.user_id);
        setAssignedMembersId([...assignedUserIds]);
      }
      if (data.notify_completion) {
        setNotifiedMembers([...data.notify_completion]);
        const notifiedUserIds = data.notify_completion.map((member) => member.user_id);
        setNotifiedMembersId([...notifiedUserIds]);
      }
      if (data.due_date != null) {
        setDueDate(data.due_date);
      }
      if (data.repetition) {
        setRepValue(data.repetition);
      }
      if (data.repetition != 0) {
        setFromDate(data.repeat_from_date);
        setTillDate(data.repeat_till_date);
      }
      if (data.isPriority) {
        setIsPriority(data.isPriority);
      }
      if (data.repeat_specific_day) {
        setCustomDay(data.repeat_specific_day);
      }
      if (data.repeat_specific_date) {
        setCustomDate(data.repeat_specific_date);
      }
      if (data.custom_days && data.repetition === 7) {
        setShowCustomDay(true);
        setCustomDays(data.custom_days);
      }
      if (data.custom_dates) {
        setCustomDates([...data.custom_dates]);
      }
    }
  }, [data]);

  const searchedAssigMembers = projectMembers.filter((member) =>
    member.full_name.toLowerCase().includes(assignTo.toLowerCase())
  );
  const searchedNotifyMembers = projectMembers.filter((member) =>
    member.full_name.toLowerCase().includes(notifyTo.toLowerCase())
  );

  const handleOnCheck = (data) => {
    setShowDueDate(data);
    if (data === false) {
      setShowDueDate(false);
      setDueDate(null);
    }
  };

  const handleCalender = (newValue) => {
    const parsedDate = dayjs(newValue);
    if (showFromDate && (!dueDate || parsedDate.isBefore(dueDate) || parsedDate.isSame(dueDate))) {
      setFromDate(newValue);
      setCustomDate(null);
      setTillDate(null);
      setShowFromDate(false);
    }
    if (
      showTillDate &&
      ((!dueDate && parsedDate.isAfter(fromDate)) ||
        (parsedDate.isAfter(fromDate) && parsedDate.isBefore(dayjs(dueDate).add(1, 'day'))) ||
        parsedDate.isSame(dueDate))
    ) {
      setTillDate(newValue);
      setShowTillDate(false);
    }
    if (showDueDate) {
      setDueDate(newValue);
      setTillDate(null);
      setFromDate(null);
      setCustomDate(null);
      setShowDueDate(false);
      setShowFromDate(false);
      setShowTillDate(false);
    }
    if (showCustomMonth) {
      if (repValue === 8) {
        if (parsedDate.isAfter(fromDate) && parsedDate.isBefore(tillDate)) {
          const index = customDates.findIndex((date) => date.isSame(parsedDate, 'day'));
          if (index !== -1) {
            const updatedCustomDates = customDates.filter((date, i) => i !== index);
            setCustomDates(updatedCustomDates);
          } else {
            setCustomDates((prev) => [...prev, parsedDate]);
          }
          setShowCustomMonth(false);
        }
      } else if (repValue === 5) {
        if (parsedDate.isBefore(tillDate) && parsedDate.isAfter(fromDate)) {
          setCustomDay(newValue);
          setShowCustomMonth(false);
        }
      } else {
        if (parsedDate.isBefore(tillDate) && parsedDate.isAfter(fromDate)) {
          setCustomDate(newValue);
          setShowCustomMonth(false);
        }
      }
    }
  };

  const handleSelectedDay = (val) => {
    const isIncluded = customDays.includes(val);
    if (isIncluded) {
      setCustomDays((prev) => prev.filter((day) => day !== val));
    } else {
      setCustomDays((prev) => [...prev, val]);
    }
  };

  const handleToDo = (type) => {
    const originalDate = new Date(dueDate);
    let convertedDueDate = removeTime(originalDate);

    let originalFromDate = new Date(fromDate);
    let convertedFromDate = removeTime(originalFromDate);
    let originalTillDate = new Date(tillDate);
    let convertedTillDate = removeTime(originalTillDate);
    const payload = {
      title: title,
      description: description,
      assigned_to: assignedMembersId,
      notify_completion: notifiedMembersId,
      due_date: convertedDueDate,
      estimated_time: estimatedTime,
      isPriority: isPriority,
      repetition: repValue,
      repeat_from_date: convertedFromDate,
      repeat_till_date: convertedTillDate,
      repeat_specific_day: customDay,
      repeat_specific_date: customDate,
      custom_days: customDays,
      custom_dates: customDates
    };
    if (type === 2) {
      payload.task_id = data.id;
      dispatch(updateTaskRequest(payload));
    }
    if (type === 1) {
      payload.list_id = list_id;
      dispatch(createTaskRequest(payload));
    }
  };

  const handleDeleteMember = (index, type) => {
    let updatedMembers;
    let updatedMembersId;

    if (type === 1) {
      updatedMembers = [...assignedMembers];
      updatedMembersId = [...assignedMembersId];
    } else if (type === 2) {
      updatedMembers = [...notifiedMembers];
      updatedMembersId = [...notifiedMembersId];
    } else {
      return;
    }

    updatedMembers.splice(index, 1);
    updatedMembersId.splice(index, 1);

    if (type === 1) {
      setAssignedMembers(updatedMembers);
      setAssignedMembersId(updatedMembersId);
    } else if (type === 2) {
      setNotifiedMembers(updatedMembers);
      setNotifiedMembersId(updatedMembersId);
    }
  };

  return (
    <Box>
      <Divider sx={Styles.dividerStyle} />
      <Box sx={Styles.container}>
        <Box sx={Styles.labelContainer}>
          <Text
            style={Styles.txtMargin}
            variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
            To-do title
          </Text>
        </Box>
        <Box sx={Styles.valueContainer}>
          <TextInput
            name="todoTitle"
            variant="standard"
            fullWidth
            InputProps={{ disableUnderline: true }}
            placeholder="Enter title here"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            disabled={isEditable || !showBtn}
          />
        </Box>
      </Box>
      <Box sx={Styles.container}>
        <Box sx={Styles.labelContainer}>
          <Text
            style={Styles.txtMargin}
            variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
            Description
          </Text>
        </Box>
        <Box sx={Styles.valueContainer}>
          <TextInput
            name="todoTitle"
            variant="standard"
            fullWidth
            InputProps={{ disableUnderline: true }}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Describe this to-do"
            value={description}
            disabled={isEditable || !showBtn}
          />
        </Box>
      </Box>
      <Box sx={Styles.container}>
        <Box sx={Styles.labelContainer}>
          <Text
            style={Styles.txtMargin}
            variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
            Assigned to
          </Text>
        </Box>
        <Box sx={Styles.valueContainer}>
          {assignedMembers.length > 0 &&
            assignedMembers.map((member, index) => (
              <MemberCard
                key={index}
                profile={member.profile_pic}
                name={member.full_name}
                showDelete={showBtn}
                onDelete={() => handleDeleteMember(index, 1)}
              />
            ))}
          <TextInput
            name="todoTitle"
            variant="standard"
            fullWidth
            InputProps={{ disableUnderline: true }}
            placeholder="Type name(s) to assign"
            onChange={(e) => setAssignTo(e.target.value)}
            value={assignTo}
            disabled={isEditable || !showBtn}
          />
        </Box>
      </Box>
      {searchedAssigMembers && assignTo.length > 1 && (
        <Box sx={Styles.container}>
          <Box sx={Styles.labelContainer}></Box>
          <Box sx={Styles.valueContainer}>
            <Box sx={Styles.cardContainer}>
              {searchedAssigMembers.map((member, index) => (
                <MemberCard
                  name={member.full_name}
                  profile={member.profile_pic}
                  key={index}
                  onClick={() => {
                    const memberExists = assignedMembers.some(
                      (existingMember) =>
                        existingMember.id === member.id || existingMember.user_id === member.id
                    );

                    if (!memberExists) {
                      setAssignTo('');
                      setAssignedMembers((prev) => [...prev, member]);
                      setAssignedMembersId((prev) => [...prev, member.id]);
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={Styles.container}>
        <Box sx={Styles.labelContainer}>
          <Text
            style={Styles.txtMargin}
            variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
            When done, notify to
          </Text>
        </Box>
        <Box sx={Styles.valueContainer}>
          {notifiedMembers.length > 0 &&
            notifiedMembers.map((member, index) => (
              <MemberCard
                key={index}
                profile={member.profile_pic}
                name={member.full_name}
                showDelete={showBtn}
                onDelete={() => handleDeleteMember(index, 2)}
              />
            ))}
          <TextInput
            name="todoTitle"
            variant="standard"
            fullWidth
            InputProps={{ disableUnderline: true }}
            placeholder="Type name(s) to notify"
            onChange={(e) => setNotifyTo(e.target.value)}
            value={notifyTo}
            disabled={isEditable || !showBtn}
          />
        </Box>
      </Box>
      {searchedNotifyMembers && notifyTo.length > 1 && (
        <Box sx={Styles.container}>
          <Box sx={Styles.labelContainer}></Box>
          <Box sx={Styles.valueContainer}>
            <Box sx={Styles.cardContainer}>
              {searchedNotifyMembers.map((member, index) => (
                <MemberCard
                  name={member.full_name}
                  profile={member.profile_pic}
                  key={index}
                  onClick={() => {
                    const memberExists = notifiedMembers.some(
                      (existingMember) =>
                        existingMember.id === member.id || existingMember.user_id === member.id
                    );
                    if (!memberExists) {
                      setNotifyTo('');
                      setNotifiedMembers((prev) => [...prev, member]);
                      setNotifiedMembersId((prev) => [...prev, member.id]);
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={Styles.container}>
        <Box sx={Styles.labelContainer}>
          <Text
            style={Styles.txtMargin}
            variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
            Due date
          </Text>
        </Box>
        <Box sx={Styles.valueContainer}>
          <UICheckBoxTitle
            values={defaultValues.taskListOptions}
            onCheck={handleOnCheck}
            variant={
              defaultValues.isResponsive
                ? 'cardResSubTitle'
                : defaultValues.isMedium
                  ? 'default'
                  : 'tableTitle'
            }
            selected={data && data.due_date != null ? true : false}
            defaultOption={data && data.due_date != null ? 'Select date' : 'No due date'}
            margin={Styles.checkBox}
          />
        </Box>
      </Box>
      {showDueDate && <DateCalendarPicker handleCalender={handleCalender} />}
      {dueDate && !showDueDate && (
        <Box sx={Styles.container}>
          <Box sx={Styles.labelContainer}></Box>
          <Box sx={Styles.valueContainer}>
            <Stack sx={Styles.calenderIconWithText}>
              <Stack sx={Styles.date} onClick={() => setShowDueDate(true)}>
                <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                  {dueDate && getFormatDate(dueDate)}
                </Text>

                <CalendarMonthIcon color="action" sx={Styles.calenderIcon} />
              </Stack>
            </Stack>
          </Box>
        </Box>
      )}
      <Box sx={Styles.container}>
        <Box sx={Styles.labelContainer}>
          <Text
            style={Styles.txtMargin}
            variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
            Estimated time ( in hrs )
          </Text>
        </Box>
        <Box sx={Styles.valueContainer}>
          <TextInput
            name="todoTitle"
            variant="standard"
            fullWidth
            InputProps={{ disableUnderline: true }}
            value={estimatedTime}
            onChange={(e) => setEstimatedTime(e.target.value)}
            placeholder="Enter time here"
            allowOnlyNumbers={true}
            disabled={isEditable || !showBtn}
          />
        </Box>
      </Box>
      <Box sx={Styles.container}>
        <Box sx={Styles.labelContainer}>
          <Text
            style={Styles.txtMargin}
            variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
            Repetition
          </Text>
        </Box>
        <Box sx={Styles.valueContainer}>
          <UISelectWithTitle
            name="todoTitle"
            variant="standard"
            fullWidth
            InputProps={{ disableUnderline: true, underline: 'none' }}
            placeholder="select"
            sx={Styles.inputSize}
            marginTop="0px"
            marginBottom="0px"
            onChange={(val) => {
              setRepValue(val);
              setFromDate(null);
              setTillDate(null);
              setCustomDay(null);
              setCustomDays([]);
              setCustomDate(null);
              setCustomDates([]);
              setShowCustomDay(false);
            }}
            options={defaultValues.repeatOptions}
            value={repValue}
          />
        </Box>
      </Box>

      {repValue != 0 && (
        <Box sx={Styles.container}>
          <Box sx={Styles.labelContainer}></Box>
          <Box sx={Styles.repitContainer}>
            <Stack sx={Styles.calenderIconWithText}>
              <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                From
              </Text>
              <Stack sx={Styles.date} onClick={() => setShowFromDate(true)}>
                <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                  {fromDate ? getFormatDate(fromDate) : `Select date`}
                </Text>

                <CalendarMonthIcon color="action" sx={Styles.calenderIcon} />
              </Stack>
            </Stack>
            {showFromDate && <DateCalendarPicker handleCalender={handleCalender} />}
            <Stack sx={Styles.calenderIconWithText}>
              <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                Until
              </Text>
              <Stack sx={Styles.date} onClick={() => setShowTillDate(true)}>
                <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                  {tillDate ? getFormatDate(tillDate) : `Select date`}
                </Text>
                <CalendarMonthIcon color="action" sx={Styles.calenderIcon} />
              </Stack>
            </Stack>
            {showTillDate && <DateCalendarPicker handleCalender={handleCalender} />}
            {repValue === 7 && (
              <Stack sx={Styles.calenderIconWithText}>
                <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                  On
                </Text>
                <Stack sx={Styles.date} onClick={() => setShowCustomDay(true)}>
                  <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                    {`Select day`}
                  </Text>
                  <CalendarMonthIcon color="action" sx={Styles.calenderIcon} />
                </Stack>
              </Stack>
            )}
            {showCustomDay && <DayPicker selDay={handleSelectedDay} selectedDays={customDays} />}
            {(repValue === 8 || repValue === 6 || repValue === 5) && (
              <Stack sx={Styles.calenderIconWithText}>
                <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                  On
                </Text>
                <Stack sx={Styles.date} onClick={() => setShowCustomMonth(true)}>
                  {customDates.length > 0 ? (
                    <Box>
                      {customDates.map((date, index) => (
                        <Text
                          key={index}
                          variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                          {date ? getFormatDate(date) : `Select date`}
                        </Text>
                      ))}
                    </Box>
                  ) : (
                    <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
                      {customDay
                        ? getFormatDate(customDay)
                        : customDate
                          ? getFormatDate(customDate)
                          : `Select date`}
                    </Text>
                  )}

                  <CalendarMonthIcon color="action" sx={Styles.calenderIcon} />
                </Stack>
              </Stack>
            )}
            {showCustomMonth && <DateCalendarPicker handleCalender={handleCalender} />}
          </Box>
        </Box>
      )}
      <Box sx={Styles.btnContainer}>
        <UICheckBox
          type={'square'}
          onChange={() => setIsPriority(!isPriority)}
          checked={isPriority}
        />
        <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'tableTitle'}>
          Mark as priority
        </Text>
      </Box>
      {showBtn && !isEditable && (
        <Box sx={Styles.btnContainer}>
          {data ? (
            <UIButton
              title={'save changes'}
              onClick={() => handleToDo(2)}
              style={Styles.saveBtnStyle}
              disabled={
                (data.title === title &&
                  data.description === description &&
                  data.due_date === dueDate &&
                  data.repetition === repValue &&
                  data.estimated_time === estimatedTime &&
                  JSON.stringify(notifiedMembersId) ===
                    JSON.stringify(data.notify_completion.map((member) => member.user_id)) &&
                  JSON.stringify(assignedMembersId) ===
                    JSON.stringify(data.assigned_to.map((member) => member.user_id))) ||
                !assignedMembersId.length ||
                !notifiedMembersId.length ||
                (repValue > 0 && !fromDate) ||
                (repValue > 0 && !tillDate) ||
                (repValue === 8 && !customDates.length) ||
                (repValue === 5 && !customDay) ||
                (repValue === 6 && !customDate) ||
                (repValue === 7 && !customDays.length)
              }
            />
          ) : (
            <>
              <UIButton
                title={'add to do'}
                onClick={() => handleToDo(1)}
                style={Styles.btnStyle}
                disabled={
                  !title.length ||
                  !description.length ||
                  !estimatedTime.length ||
                  !assignedMembersId.length ||
                  !notifiedMembersId.length ||
                  (repValue > 0 && !fromDate) ||
                  (repValue > 0 && !tillDate) ||
                  (repValue === 8 && !customDates.length) ||
                  (repValue === 5 && !customDay) ||
                  (repValue === 6 && !customDate) ||
                  (repValue === 7 && !customDays.length)
                }
              />
              <UIButton title={'cancel'} style={Styles.cancelBtn} onClick={() => onCancel()} />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
