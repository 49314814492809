import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme/color';
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;

export const Styles = {
  container: (isRes) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isRes ? '70%' : md ? '35%' : '30%',
    height: 'fit-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1000
  }),
  imageStyleScss: {
    width: '80px',
    height: '80px'
  },
  imageStyle: {
    width: '100px',
    height: '100px'
  },
  buttonContainer: (type) => ({
    mt: 3,
    width: '100%',
    display: 'flex',
    justifyContent: type === defaultValues.listConstants.success ? 'center' : 'space-evenly',
    mb: 1
  }),
  buttonStyleCancel: (isRes, style) => ({
    fontSize: isRes && '14px',
    fontWeight: 'bold',
    color: color.palette.buttonRed,
    backgroundColor: color.primaryBackground,
    ...style
  }),
  buttonStyleConfirm: (isRes, style) => ({
    fontSize: isRes && '14px',
    color: color.palette.primaryBackground,
    backgroundColor: color.palette.secondaryOrange,
    ...style
  }),
  messageStyle: { textAlign: 'center' },
  buttonStyleOk: (isRes) => ({
    fontSize: isRes && '14px',
    paddingLeft: '15%',
    marginTop: '2%',
    paddingRight: '15%',
    color: color.primaryBackground,
    backgroundColor: color.palette.primaryBlue
  }),
  ratingStar: {
    '& .MuiRating-icon': {
      fontSize: sm ? '45px' : md ? '50px' : '60px'
    }
  }
};
