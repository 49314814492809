import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

export const ViewQuestionStyle = {
  mainQuestionBox: {
    width: '95%',
    margin: '20px 0',
    border: `1px solid ${color.palette.lightBorder}`,
    padding: defaultValues.isResponsive ? '5px 8px' : '10px 20px',
    borderRadius: '8px'
  },
  flexBox: {
    width: '100%',
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: !defaultValues.isResponsive && '0 10px'
  },
  qaInput: {
    flex: 1,
    boxShadow: 0,
    width: '100%',
    backgroundColor: color.palette.textFieldRed,
    border: '0px',
    borderRadius: '8px',
    marginBottom: '5px'
  },
  qaInputDisable: {
    backgroundColor: color.palette.disableColor,
    width: 'calc(100% - 290px)',
    border: `1px solid ${color.palette.lightBorder}`
  },
  optionBox: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  subQuestion: { marginLeft: !defaultValues.isResponsive && '35px' }
};
