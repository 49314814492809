import { Box } from '@mui/material';

export const EditIcon = ({ width = '35', height = '35', iconColor = '#050505', style }) => {
  return (
    <Box
      sx={{
        width: width,
        height: height,
        ...style
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 35 35"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.29183 29.167H27.7085C28.0953 29.167 28.4662 29.3206 28.7397 29.5941C29.0132 29.8676 29.1668 30.2386 29.1668 30.6253C29.1668 31.0121 29.0132 31.383 28.7397 31.6565C28.4662 31.93 28.0953 32.0837 27.7085 32.0837H7.29183C6.90506 32.0837 6.53412 31.93 6.26063 31.6565C5.98714 31.383 5.8335 31.0121 5.8335 30.6253C5.8335 30.2386 5.98714 29.8676 6.26063 29.5941C6.53412 29.3206 6.90506 29.167 7.29183 29.167ZM5.8335 21.8753L20.4168 7.29199L24.7918 11.667L10.2085 26.2503H5.8335V21.8753ZM21.8752 5.83366L24.7918 2.91699L29.1668 7.29199L26.2487 10.2101L21.8752 5.83366Z"
          fill={iconColor}
          fillOpacity="0.65"
        />
      </svg>
    </Box>
  );
};
