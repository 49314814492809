import { useEffect, useState } from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import {
  ActionButton,
  Filter,
  SearchBar,
  Sort,
  Text,
  UIButton,
  IconsButton,
  PaginationCustom
} from '../../../ui-kit';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { CollapsableList, NoData, OtherSettingsModal, StatusIndicator, Title } from '../..';
import { currencyStyles } from './style';
import { useFormik } from 'formik';
import { otherSettingsCurrencySchema, resetMessge } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentPage,
  getCurrencies,
  getSettingsErrorMessage,
  getSettingsIndicator,
  getSettingsSuccessMessage,
  settingLoadingStatus,
  totalPage
} from '../../../services/redux/selectors';
import {
  addCurrencyRequest,
  editCurrencyRequest,
  getCurrenciesRequest,
  resetSettingsMessages
} from '../../../services/redux/slices';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { customStyles } from '../../../theme';

export const Currency = () => {
  const dispatch = useDispatch();
  const currenciesList = useSelector(getCurrencies);
  const pageCount = useSelector(totalPage);
  const currentRenderPage = useSelector(currentPage);
  const successMessage = useSelector(getSettingsSuccessMessage);
  const errorMessage = useSelector(getSettingsErrorMessage);
  const isLoading = useSelector(settingLoadingStatus);
  const indicator = useSelector(getSettingsIndicator);

  const [currencies, setCurrencies] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [sortValue, setSortValue] = useState({});
  const [filterValue, setFilterValuer] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [pageNo, setPageNo] = useState(null);

  useEffect(() => {
    handleCurrency();
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetSettingsMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    setCurrencies(currenciesList);
  }, [currenciesList]);

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    setFieldTouched
  } = useFormik({
    initialValues: {
      currency: '',
      symbol: '',
      id: ''
    },
    validationSchema: otherSettingsCurrencySchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  useEffect(() => {
    if (!isLoading && successMessage) {
      handleClose();
      setCurrentIndex('');
    }
  }, [isLoading]);

  useEffect(() => {
    if (indicator) {
      handleCurrency();
    }
  }, [indicator]);

  const handleOpenAdd = () => {
    setIsEditMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFieldValue('currency', '');
    setFieldValue('symbol', '');
    setFieldTouched('currency', false);
  };

  const handleAddCurrency = () => {
    const payload = {
      currency: values.currency,
      currency_symbol: values.symbol && values.symbol
    };
    dispatch(addCurrencyRequest(payload));
  };

  const handleOpenEdit = (item) => {
    setFieldValue('currency', item.currency);
    setFieldValue('symbol', item.currency_symbol);
    setFieldValue('id', item.id);
    setOpen(true);
    setIsEditMode(true);
  };

  const handleEditCurrency = () => {
    const payload = {
      id: values.id,
      currency: values.currency,
      currency_symbol: values.symbol
    };
    dispatch(editCurrencyRequest(payload));
  };

  const handleCurrency = (data) => {
    let payload = {
      page: pageNo || 1,
      ...sortValue,
      ...filterValue,
      ...data
    };
    dispatch(getCurrenciesRequest(payload));
  };

  const handleSort = (sort) => {
    setSortValue({ sort });
    handleCurrency({ sort });
  };

  const handleFilter = (value) => {
    setFilterValuer({ filter: `[{ isActive: ${value} }]` });
    handleCurrency({ filter: `[{ isActive: ${value} }]` });
  };

  const handleRefresh = () => {
    setSortValue({});
    setFilterValuer({});
    setPageNo(1);
    handleCurrency({ sort: null, filter: null, page: 1 });
  };

  const handleSearch = (search_text) => {
    if (search_text.length === 0 || search_text.length >= 2) {
      setTimeout(() => {
        handleCurrency({ search_text });
      }, 1500);
    }
  };

  const handlePageChange = (e, value) => {
    const payload = {
      page: value,
      ...sortValue,
      ...filterValue
    };
    setPageNo(value);
    dispatch(getCurrenciesRequest(payload));
  };

  const handleStatus = (id, status, index) => {
    const payload = { id: id, active: !status };
    setCurrentIndex(index);
    dispatch(editCurrencyRequest(payload));
  };

  return (
    <Box sx={currencyStyles.container}>
      <LoadingScreen isLoading={!currentIndex && isLoading} />
      <Box sx={currencyStyles.contentContainer}>
        <Box sx={currencyStyles.searchTabContainer}>
          <Box sx={currencyStyles.searchToolsContainer}>
            {!defaultValues.isResponsive ? (
              <SearchBar label={'Search currency'} searchedVlaue={handleSearch} />
            ) : (
              <Text style={currencyStyles.headingText} variant="medium">
                Available currencies
              </Text>
            )}
            <UIButton
              style={currencyStyles.newButton}
              title="+ new currency"
              onClick={handleOpenAdd}
            />
          </Box>

          <Box sx={currencyStyles.toolsContainer}>
            <IconsButton
              title={!defaultValues.isResponsive ? null : 'Refresh'}
              icon={<RefreshIcon sx={currencyStyles.icon} />}
              onClick={handleRefresh}
            />
            <Sort selectedOption={handleSort} activeOption={sortValue.sort} />
            <Filter label={'Filter'} selectedOption={handleFilter} />
          </Box>
        </Box>

        {!defaultValues.isResponsive ? (
          <Text sx={currencyStyles.heading} variant="fileName">
            {defaultValues.otherSettingsCurrencyHeading}
          </Text>
        ) : (
          <Box sx={currencyStyles.searchContainer}>
            <SearchBar label={'Search currency'} searchedVlaue={handleSearch} />
          </Box>
        )}

        {!defaultValues.isResponsive ? (
          currencies.length > 0 ? (
            <Table sx={currencyStyles.tableContainer} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={currencyStyles.headingCell} align="left">
                    Currency name
                  </TableCell>
                  <TableCell sx={currencyStyles.headingCell} align="center">
                    Symbol
                  </TableCell>
                  <TableCell sx={currencyStyles.headingCell} align="left">
                    Status
                  </TableCell>
                  <TableCell sx={currencyStyles.headingCell} align="left">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currencies &&
                  currencies.map((row, index) => (
                    <TableRow key={row.id} sx={currencyStyles.tableRow}>
                      <TableCell component="th" scope="row">
                        <Title title={row.currency} />
                      </TableCell>
                      <TableCell align="center" sx={currencyStyles.symbol}>
                        {row.currency_symbol}
                      </TableCell>
                      <TableCell align="left">
                        <StatusIndicator status={row.active} />
                      </TableCell>
                      <TableCell sx={customStyles.actionCont} align="left">
                        <ActionButton
                          item={row}
                          onEditClick={() => handleOpenEdit(row)}
                          onButtonClick={() => handleStatus(row.id, row.active, index)}
                          isLoading={index === currentIndex && !open && isLoading}
                          contStyle={customStyles.actionSub}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <NoData message={getErrorMessage('E-10034')} />
          )
        ) : (
          <Box sx={currencyStyles.collapseContainer}>
            {currencies.length > 0 ? (
              <CollapsableList
                contentArray={currencies}
                type="currency"
                onEditClick={handleOpenEdit}
                onButtonClick={handleStatus}
                currentIndex={!open && isLoading && currentIndex}
                loaderSize={defaultValues.isResponsive && 15}
              />
            ) : (
              <NoData message={getErrorMessage('E-10034')} />
            )}
          </Box>
        )}

        {currentRenderPage && pageCount && (
          <Box sx={currencyStyles.paginationContainer}>
            <PaginationCustom
              onChange={handlePageChange}
              currentPage={currentRenderPage}
              count={pageCount}
            />
          </Box>
        )}
        <OtherSettingsModal
          open={open}
          close={handleClose}
          heading={isEditMode ? 'Edit currency' : defaultValues.otherSettingsCurrencyModalHeading}
          placeholder1="Enter currency name"
          placeholder2="enter currency symbol"
          inputTitle1="Currency"
          inputTitle2="Symbol"
          inputValue1={values.currency}
          inputValue2={values.symbol}
          inputName1={'currency'}
          inputName2={'symbol'}
          buttonTitle={isEditMode ? 'Save changes' : 'Save'}
          onChange={handleChange}
          error={errors}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
          touched={touched}
          onButtonClick={isEditMode ? handleEditCurrency : handleAddCurrency}
          type="currency"
          disabled={errors.currency || !values.currency}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};
