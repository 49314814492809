import { defaultValues } from '../../constants';
import { color } from '../../theme';
const isRes = defaultValues.isResponsive;

export const styles = {
  container: {
    height: !isRes && '200px',
    m: 2,
    borderRadius: 1,
    border: `1px solid ${color.primaryBorder}`,
    display: 'flex',
    pb: isRes && 1,
    flexDirection: isRes ? 'column' : 'row'
  },
  titleContainer: {
    flex: !isRes && 1,
    pb: !isRes && 2,
    px: 2,
    pt: 2,
    display: 'flex',
    flexDirection: isRes ? 'row' : 'column',
    justifyContent: isRes && 'space-between',
    boxSizing: 'border-box'
  },
  textStyle: { fontWeight: 600 },
  textColor: { color: color.palette.emailGray },
  btnStyle: {
    width: isRes ? '120' : '150px',
    fontSize: isRes && '12px',
    height: isRes ? '26px' : '45px',
    marginTop: !isRes && '25px'
  },
  boxStyle: {
    flex: !isRes && 1,
    pl: isRes ? 2 : 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: !isRes && 'center'
  },
  navLink: {
    fontWeight: 600,
    mt: isRes && -1,
    mb: isRes && 1,
    cursor: 'pointer',
    '&:hover': {
      color: color.palette.linkBlue,
      textDecoration: 'underline'
    }
  },
  textClr: { color: color.palette.gray, fontSize: isRes ? '10px' : '16px' },
  to: { display: 'flex', justifyContent: 'center' },
  timelineCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'fit-content'
  }
};
