import { defaultValues } from '../../../constants';
import { color, customStyles } from '../../../theme';

const isMobile = defaultValues.isResponsive;
const sm = defaultValues.isMobile;
const md = defaultValues.isMedium;

export const pRStyles = {
  container: {
    width: '98%',
    minHeight: '85vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: color.primaryBackground,
    padding: '0',
    boxSizing: 'border-box',
    margin: isMobile ? '5px 5px' : '15px 10px',
    paddingTop: '20px'
  },
  topNav: {
    ...customStyles.flexBox,
    flexDirection: isMobile ? 'column' : 'row',
    margin: '15px 0px',
    mb: '25px'
  },
  icon: { fontSize: '20px', marginX: '10px' },
  projectDetailsContainer: {
    height: 'fit-content',
    width: isMobile ? '100%' : '93%',
    maxWidth: isMobile ? '90vw' : '100vw',
    borderRadius: 1,
    boxShadow: 2,
    margin: '10px 0',
    padding: isMobile ? '10px' : '25px 25px',
    boxSizing: 'border-box'
  },
  cDetailsCont: {
    display: 'flex',
    flexDirection: sm ? 'column' : 'row',
    justifyContent: 'space-between'
  },
  rateComp: {
    color: color.palette.primaryBlue,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: sm ? '14px' : md ? '16px' : '22px',
    marginTop: '10px'
  },
  ratingStar: {
    '& .MuiRating-icon': {
      fontSize: sm ? '45px' : '70px'
    }
  },
  ratingChildBox: {
    marginTop: '20px',
    height: isMobile ? '100%' : 'fit-content',
    maxHeight: 'none'
  },
  ratingContBox: {
    height: isMobile ? '60vh' : 'fit-content',
    minHeight: 'none',
    minWidth: '40%',
    p: !isMobile && 5,
    left: isMobile ? '50%' : '65%'
  },
  ratingContentBox: {
    justifyContent: isMobile && 'none',
    height: isMobile && '100%',
    position: 'relative'
  },
  ratingBtnCont: {
    width: '100%',
    marginTop: '20px',
    position: isMobile ? 'absolute' : 'static',
    bottom: 0
  },
  ratingBtn: {
    width: isMobile && '100%'
  },
  projectName: {
    fontSize: isMobile ? '18px' : '24px'
  },
  eventDetailsContainer: {
    width: isMobile ? '90%' : '96%',
    borderRadius: 1,
    boxShadow: 1,
    pt: isMobile ? 1 : 2,
    pl: isMobile ? 1 : 3,
    pb: 4,
    mt: 2,
    mb: 5
  },
  secondaryTitle: {
    fontSize: isMobile ? '16px' : '28px',
    fontWeight: 600
  },
  tertiaryTitle: {
    fontSize: isMobile ? '12px' : '28px',
    fontWeight: 600
  },
  thirdTitle: {
    fontSize: isMobile ? '14px' : '28px',
    fontWeight: 600
  },
  secondaryText: {
    fontSize: isMobile ? '12px' : '20px'
  },
  column: { width: '50%', paddingRight: '10px', boxSizing: 'border-box' },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '75px',
    width: sm ? '100%' : isMobile ? '250px' : '400px',
    justifyContent: 'space-between'
  },
  statusText: {
    fontSize: isMobile ? '16px' : '24px'
  },
  eventDetailsSubContainer: {
    width: isMobile ? '95%' : '80%',
    marginTop: '20px',
    overflow: 'hidden',
    borderRadius: '15px',
    border: `1px solid ${color.palette.lightBorder}`
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    p: 2,
    borderBottom: `0.5px solid ${color.palette.lightBorder}`
  },
  questionContainer: (subQ) => ({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: subQ ? '20px' : '0px'
  }),
  additionalBox: {
    ...customStyles.flexBox,
    width: isMobile ? '100%' : '80%',
    maxWidth: '550px',
    justifyContent: 'space-between',
    paddingY: isMobile ? '7px' : '15px'
  },
  tableContainer: {
    minWidth: 650
  },
  headingCell: {
    fontSize: '22px',
    backgroundColor: color.palette.buttonLight
  },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 }
  },
  additionalTop: {
    ...customStyles.flexBox,
    justifyContent: 'space-between',
    mb: '15px',
    padding: isMobile ? '0px' : '0 10px'
  },
  proDHead: {
    fontSize: sm ? '14px' : isMobile ? '18px' : '24px'
  },
  supplierLiBox: {
    marginTop: '10px',
    borderRadius: '5px',
    border: `1px solid ${color.primaryBorder}`,
    boxShadow: 'none',
    overflow: 'hidden'
  },
  bgColor: { backgroundColor: color.palette.secondaryWhite },
  mW: { maxWidth: '160px' },
  btnBox: {
    ...customStyles.flexBox,
    justifyContent: 'center'
  },
  title: { marginY: 2, mt: '35px', fontSize: isMobile ? '16px' : '28px', ...customStyles.w550 },
  py: { py: '10px' },
  ml: { marginLeft: '15px' },
  wBtn: { width: '250px' },
  w35: { width: '35%' },
  w15: { width: '15%' },
  w250: {
    width: sm ? '120px' : isMobile ? '200px' : '250px',
    fontSize: sm ? '10px' : isMobile ? '12px' : '14px',
    padding: isMobile && '4px',
    wordWrap: 'no-wrap'
  },
  email: {
    fontSize: md ? '14px' : '18px'
  },
  navLink: {
    cursor: 'pointer',
    color: color.palette.linkBlue,
    textDecoration: 'underline',
    fontSize: isMobile ? '16px' : '24px'
  },
  idleText: {
    fontSize: isMobile ? '18px' : '24px'
  },
  collapseContainer: {
    width: '100%',
    padding: '20px 0px',
    boxSizing: 'border-box'
  },
  collBox: { ...customStyles.flexBox, alignItems: 'start', justifyContent: 'start', mt: '15px' },
  collAction: {
    width: '100%'
  },
  actionBtn: { height: '30px', fontSize: '12px' },
  tH: { height: '50px' },
  w58: { width: '58%' },
  formControl: {
    marginLeft: isMobile ? '0px' : '-4px'
  },
  checkBox: {
    padding: '9px 9px 9px 0px',
    marginRight: '8px'
  },
  square: {
    color: color.palette.primaryBlack,
    fontSize: isMobile ? '29px' : '42px',
    borderRadius: '4px',
    '& MuiButtonBase-root': {
      padding: '9px 9px 9px 0px'
    }
  },
  checkedIcon: {
    color: color.palette.primaryRed,
    fontSize: isMobile ? '29px' : '42px'
  },
  checkText: {
    fontSize: isMobile ? '12px' : '20px',
    fontWeight: 400
  },
  btsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    position: isMobile ? 'absolute' : 'static',
    bottom: 10,
    left: 0
  },
  assignBtn: {
    fontSize: isMobile ? '12px' : '24px',
    width: isMobile && '95%',
    padding: isMobile && '10px 0px',
    boxSizing: 'border-box'
  },
  assignHeading: {
    fontSize: isMobile ? '20px' : '32px',
    fontWeight: 600,
    marginBottom: 0
  },
  assignSelectTitle: {
    fontSize: isMobile ? '14px' : '24px',
    fontWeight: 400
  },
  ratingHCont: { width: '100%' },
  ratingDivider: { width: '100%', marginRight: 0 },
  selectTitle: {
    fontSize: isMobile ? '16px' : '28px'
  },
  confirmStyle: (type) => ({
    backgroundColor:
      type === defaultValues.listConstants.Replace
        ? color.palette.primaryWhite
        : type === defaultValues.listConstants.Remove ||
            type === defaultValues.listConstants.supplier ||
            type === defaultValues.listConstants.rate_supplier
          ? color.palette.primaryRed
          : color.palette.secondaryOrange,
    color:
      type === defaultValues.listConstants.Replace
        ? color.palette.primaryBlack
        : color.palette.primaryWhite,
    fontWeight: 600,
    border:
      type === defaultValues.listConstants.Replace
        ? `1px solid ${color.palette.primaryBlack}`
        : 'none'
  }),
  quoteBtn: {
    fontSize: isMobile && '12px',
    padding: isMobile && '6px'
  },
  tLink: {
    color: color.palette.linkBlue,
    textDecoration: 'underline',
    fontSize: defaultValues.isResponsive ? '11px' : '16px',
    fontWeight: 400,
    cursor: 'pointer'
  },
  edit: { cursor: 'pointer', ml: 2 },
  editContainer: {
    width: '50%',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row'
  },
  clr: { color: color.palette.gray },
  convCont: { display: 'flex' }
};
