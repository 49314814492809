import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  supplierList: [],
  current_page: null,
  total_page: null,
  suggestedSuppliers: [],
  existingSuppliers: [],
  projectInvitation: null,
  indicator: null,
  supplierParams: {}
};

const supplierSlice = createSlice({
  initialState,
  name: 'supplier',
  reducers: {
    updateSupplierParams(state, action) {
      state.supplierParams = action.payload;
    },
    getSupplierRequest(state) {
      state.isLoading = true;
    },
    getSupplierSuccess(state, action) {
      state.isLoading = false;
      state.supplierList = action.payload.data.suppliers;
      state.current_page = action.payload.data.current_page;
      state.total_page = action.payload.data.total_page;
    },
    getSupplierFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.supplierList = [];
      state.current_page = null;
      state.total_page = null;
    },
    getProjectSupplierRequest(state) {
      state.isLoading = true;
    },
    getProjectSupplierSuccess(state, action) {
      state.isLoading = false;
      state.supplierList = action.payload.data.suppliers;
      state.current_page = action.payload.data.current_page;
      state.total_page = action.payload.data.total_page;
    },
    getProjectSupplierFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.supplierList = [];
      state.current_page = null;
      state.total_page = null;
    },
    updateSupplierRequest(state) {
      state.isLoading = true;
    },
    updateSupplierSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.total_page = action.payload.data.total_page;
      state.indicator = true;
    },
    updateSupplierFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    approvalSupplierRequest(state) {
      state.isLoading = true;
    },
    approvalSupplierSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.total_page = action.payload.data.total_page;
      state.indicator = true;
    },
    approvalSupplierFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    resetSupplierMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
      state.indicator = null;
    },
    supplierRecomendationRequest(state) {
      state.isLoading = true;
    },
    supplierRecomendationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.suggestedSuppliers = action.payload.suggested_suppliers;
      state.existingSuppliers = action.payload.existing_suppliers;
    },
    supplierRecomendationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.suggestedSuppliers = [];
      state.existingSuppliers = [];
    },
    supplierInvitationRequest(state) {
      state.isLoading = true;
    },
    supplierInvitationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    supplierInvitationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    projectInvitationRequest(state) {
      state.isLoading = true;
    },
    projectInvitationSuccess(state, action) {
      state.isLoading = false;
      state.projectInvitation = action.payload.data;
    },
    projectInvitationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    acceptRejectInvitationRequest(state) {
      state.isLoading = true;
    },
    acceptRejectInvitationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    acceptRejectInvitationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  getSupplierRequest,
  getSupplierSuccess,
  getSupplierFailure,
  resetSupplierMessages,
  updateSupplierRequest,
  updateSupplierSuccess,
  updateSupplierFailure,
  approvalSupplierRequest,
  approvalSupplierSuccess,
  approvalSupplierFailure,
  supplierRecomendationRequest,
  supplierRecomendationSuccess,
  supplierRecomendationFailure,
  supplierInvitationRequest,
  supplierInvitationSuccess,
  supplierInvitationFailure,
  projectInvitationRequest,
  projectInvitationSuccess,
  projectInvitationFailure,
  acceptRejectInvitationRequest,
  acceptRejectInvitationSuccess,
  acceptRejectInvitationFailure,
  updateSupplierParams,
  getProjectSupplierRequest,
  getProjectSupplierSuccess,
  getProjectSupplierFailure
} = supplierSlice.actions;

export default supplierSlice.reducer;
