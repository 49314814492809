import { Box, Checkbox } from '@mui/material';
import React from 'react';
import { color } from '../../theme/color';
import { CheckBox, CheckCircle, Circle } from '@mui/icons-material';
import SquareIcon from '@mui/icons-material/Square';
import { defaultValues } from '../../constants';

export const UICheckBox = ({
  onChange,
  checked,
  sx,
  type,
  iconStyle,
  checkedIconStyle,
  ...rest
}) => {
  const styles = {
    checkbox: {
      display: 'flex',
      alignItem: 'center'
    },
    icon: { color: color.palette.lightGray, fontSize: '35px', ...iconStyle },
    checkedIcon: {
      color: color.palette.primaryRed,
      fontSize: defaultValues.isResponsive ? '25px' : '35px',
      ...checkedIconStyle
    }
  };

  return (
    <Box sx={styles.checkbox}>
      <Checkbox
        checked={checked}
        sx={{ marginRight: '8px', ...sx }}
        onChange={onChange}
        icon={
          type === 'square' ? <SquareIcon style={styles.icon} /> : <Circle style={styles.icon} />
        }
        checkedIcon={
          type === 'square' ? (
            <CheckBox style={styles.checkedIcon} />
          ) : (
            <CheckCircle style={styles.checkedIcon} />
          )
        }
        {...rest}
      />
    </Box>
  );
};
