import { put, takeLatest } from 'redux-saga/effects';
import {
  acceptRejectInvitationFailure,
  acceptRejectInvitationRequest,
  acceptRejectInvitationSuccess,
  approvalSupplierFailure,
  approvalSupplierRequest,
  approvalSupplierSuccess,
  getProjectSupplierFailure,
  getProjectSupplierRequest,
  getProjectSupplierSuccess,
  getSupplierFailure,
  getSupplierRequest,
  getSupplierSuccess,
  projectInvitationFailure,
  projectInvitationRequest,
  projectInvitationSuccess,
  resetSupplierMessages,
  supplierInvitationFailure,
  supplierInvitationRequest,
  supplierInvitationSuccess,
  supplierRecomendationFailure,
  supplierRecomendationRequest,
  supplierRecomendationSuccess,
  updateSupplierFailure,
  updateSupplierRequest,
  updateSupplierSuccess
} from '../slices';
import {
  acceptRejectInvitationAPI,
  approvalSuppliersAPI,
  getProjectSuppliersAPI,
  getSupplierRecomendationAPI,
  getSuppliersAPI,
  projectInvitationAPI,
  supplierInvitationAPI,
  updateSuppliersAPI
} from '../../api';
import { SUCCESS_KEY } from '../../../constants';

function* getSuppliers(action) {
  try {
    const response = yield getSuppliersAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getSupplierSuccess(response.data));
    } else {
      yield put(getSupplierFailure(response.data));
    }
  } catch (error) {
    yield put(getSupplierFailure('E-10001'));
  }
}

function* getProjectSuppliers(action) {
  try {
    const response = yield getProjectSuppliersAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getProjectSupplierSuccess(response.data));
    } else {
      yield put(getProjectSupplierFailure(response.data));
    }
  } catch (error) {
    yield put(getProjectSupplierFailure('E-10001'));
  }
}

function* updateSuppliers(action) {
  try {
    const response = yield updateSuppliersAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateSupplierSuccess(response.data));
    } else {
      yield put(updateSupplierFailure(response.data));
    }
  } catch (error) {
    yield put(updateSupplierFailure('E-10001'));
  }
}

function* approvalSuppliers(action) {
  try {
    const response = yield approvalSuppliersAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(approvalSupplierSuccess(response.data));
    } else {
      yield put(approvalSupplierFailure(response.data));
    }
  } catch (error) {
    yield put(approvalSupplierFailure('E-10001'));
  }
}

function* supplierRecommendation(action) {
  try {
    const response = yield getSupplierRecomendationAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(supplierRecomendationSuccess(response.data));
      yield put(resetSupplierMessages());
    } else {
      yield put(supplierRecomendationFailure(response.data));
    }
  } catch (error) {
    yield put(supplierRecomendationFailure('E-10001'));
  }
}

function* supplierInvitation(action) {
  try {
    const response = yield supplierInvitationAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(supplierInvitationSuccess(response.data));
    } else {
      yield put(supplierInvitationFailure(response.data));
    }
  } catch (error) {
    yield put(supplierInvitationFailure('E-10001'));
  }
}

function* projectInvitation() {
  try {
    const response = yield projectInvitationAPI();
    if (response.data.status === SUCCESS_KEY) {
      yield put(projectInvitationSuccess(response.data));
    } else {
      yield put(projectInvitationFailure(response.data));
    }
  } catch (error) {
    yield put(projectInvitationFailure('E-10001'));
  }
}

function* acceptRejectInvitation(data) {
  try {
    const response = yield acceptRejectInvitationAPI(data.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(acceptRejectInvitationSuccess(response.data));
    } else {
      yield put(acceptRejectInvitationFailure(response.data));
    }
  } catch (error) {
    yield put(acceptRejectInvitationFailure('E-10001'));
  }
}

export function* supplierSaga() {
  yield takeLatest(getSupplierRequest.type, getSuppliers);
  yield takeLatest(updateSupplierRequest.type, updateSuppliers);
  yield takeLatest(approvalSupplierRequest.type, approvalSuppliers);
  yield takeLatest(supplierRecomendationRequest.type, supplierRecommendation);
  yield takeLatest(supplierInvitationRequest.type, supplierInvitation);
  yield takeLatest(projectInvitationRequest.type, projectInvitation);
  yield takeLatest(acceptRejectInvitationRequest.type, acceptRejectInvitation);
  yield takeLatest(getProjectSupplierRequest.type, getProjectSuppliers);
}
