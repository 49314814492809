import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DateAndTimePickerWithTitle, UIButton } from '../../../ui-kit';
import { bookServiceSchema, convertDateTimeFormat, convertTimeToTimeZone } from '../../../utils';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setTimezoneOptionsValue, updateProjects } from '../../../services/redux/slices';
import { defaultValues } from '../../../constants';
import { getAllTimezones } from '../../../services/redux/selectors';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
const labelStyle = 'abbrev';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const UpdateEventTime = ({ id, time, timezone, submited }) => {
  const styles = {
    datePicker: { container: { width: '100%' } },
    btnContainer: { mt: 3, display: 'flex', justifyContent: 'center' },
    btn: { fontSize: defaultValues.isResponsive ? '14px' : '20px' }
  };

  const dispatch = useDispatch();

  const [isTimeChanged, setTimeChanged] = useState(false);
  const [newTime, setNewTime] = useState(null);

  const { handleBlur, values, setFieldValue, errors } = useFormik({
    initialValues: {
      eventDate: time || '',
      id: id || ''
    },
    validationSchema: bookServiceSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  const timezones = useSelector(getAllTimezones);

  const { options: timezoneOptions } = useTimezoneSelect({ labelStyle, timezones: allTimezones });
  useEffect(() => {
    dispatch(setTimezoneOptionsValue(timezoneOptions));
  }, [timezoneOptions, dispatch]);

  const handleTimeChange = (date) => {
    let selDateInUTC = dayjs(date).utc(true).format();
    const updatedTime = convertTimeToTimeZone(
      selDateInUTC,
      defaultValues.uKTimeZone,
      timezone,
      timezones
    );
    setFieldValue('eventDate', selDateInUTC);
    const formatedDateTime = convertDateTimeFormat(updatedTime.formattedTime);
    setNewTime(formatedDateTime);
    setTimeChanged(true);
  };
  const handleSave = () => {
    dispatch(updateProjects({ id: id, event_time: newTime }));
    submited(true);
  };

  return (
    <Box>
      <Box>
        <DateAndTimePickerWithTitle
          title={'Event date and time'}
          style={styles.datePicker}
          onBlur={handleBlur}
          onChange={handleTimeChange}
          selValue={time}
          value={values.eventDate}
          errors={errors.eventDate}
        />
      </Box>
      <Box sx={styles.btnContainer}>
        <UIButton
          title={'Save changes'}
          onClick={handleSave}
          sx={styles.btn}
          disabled={!isTimeChanged || errors.eventDate}
        />
      </Box>
    </Box>
  );
};
