import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

const responsive = defaultValues.isResponsive;
const isMobile = defaultValues.isMobile;
export const registrationStyles = {
  container: (style) => ({
    width: '100%',
    minHeight: '90vh',
    borderRadius: 1,
    p: 3,
    boxSizing: 'border-box',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)',
    marginBottom: '40px',
    ...style
  }),
  inputContainer: {
    width: '100%'
  },
  label: {
    marginTop: responsive ? '16px' : '20px',
    marginBottom: '10px'
  },
  asterisk: {
    color: 'red'
  },
  inputSubformContainer: {
    width: responsive ? '100%' : '80%'
  },
  selectArea: {
    backgroundColor: color.palette.textFieldRed,
    border: 'none'
  },
  errorField: {
    color: color.palette.primaryRed,
    marginTop: '3px',
    fontSize: responsive ? '11px' : '15px'
  },
  nameContainer: {
    width: responsive ? '100%' : '80%',
    display: { xs: 'block', md: 'flex', lg: 'flex' },
    gap: { xs: '0px', md: '20px', lg: '20px' }
  },
  cityStateContainer: {
    width: responsive ? '100%' : '80%',
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    justifyContent: 'space-between',
    gap: '20px'
  },
  cityStateSubContainer: {
    width: responsive ? '100%' : '50%'
  },
  cityStateSubContainer2: {
    width: responsive ? '100%' : '50%'
  },
  horizonalLine: { width: '100%', height: '2px', backgroundColor: '#00000033', my: 2 },
  detailsContainer: (styles) => ({ width: '100%', mt: 2, ...styles }),
  submitButton: {
    marginTop: responsive ? '4%' : '2%',
    backgroundColor: color.palette.primaryRed,
    width: responsive ? '100%' : 'fit-content',
    minWidth: '120px',
    minHeight: '46px',
    marginBottom: responsive && '40px'
  },
  accentCurrContainer: (type) => ({
    width: type === 'profile' ? '100%' : responsive ? '100%' : '100%',
    display: type === 'profile' ? 'block' : 'block',
    flexDirection: type === 'profile' ? 'column' : responsive ? 'column' : 'row',
    justifyContent: type === 'profile' ? 'none' : 'space-between',
    gap: type === 'profile' ? 0 : responsive ? 0 : '20px',
    boxSizing: 'border-box'
  }),
  selectTitle: (isRes) => ({
    mB: { fontSize: isRes ? '16px' : '28px', margin: 0 }
  }),
  mainBox: {
    margin: 0,
    marginTop: '15px'
  },
  mainCountryBox: {
    width: '100%',
    margin: 0,
    marginTop: '15px'
  },
  locContainer: {
    width: responsive ? '100%' : '80%'
  },

  title: {
    marginBottom: '10px',
    marginTop: '10px'
  },
  formControl: {
    marginLeft: '0px'
  },
  checkBox: {
    padding: '9px 9px 9px 0px',
    marginRight: '8px'
  },
  square: {
    color: color.palette.primaryBlack,
    fontSize: isMobile ? '29px' : '42px',
    borderRadius: '4px',
    '& MuiButtonBase-root': {
      padding: '9px 9px 9px 0px'
    }
  },
  checkedIcon: {
    color: color.palette.primaryRed,
    fontSize: isMobile ? '29px' : '42px'
  },
  checkText: {
    fontSize: isMobile ? '12px' : '20px',
    fontWeight: 400
  }
};
