import React, { useEffect, useState } from 'react';
import companyPic from '../../../assets/images/companyPic.png';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CollapseStyles } from './style';
import { Box } from '@mui/material';
import { ActionButton, Text } from '../../../ui-kit';
import {
  ProjectStatus,
  ProjectTitle,
  StatusIndicator,
  Title
} from '../../other-settings/table-title';
import { AdminProfile } from '../../admin-profile';
import { customStyles } from '../../../theme';
import {
  ConvertedTime,
  getFormatDate,
  getFormatDateNotUtc,
  getPhotoUrl,
  getUserRole
} from '../../../utils';
import { useSelector } from 'react-redux';
import { getUserType } from '../../../services/redux/selectors';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useNavigate } from 'react-router';

export const CollapsableList = ({
  contentArray,
  statusOptions,
  statusChange,
  type,
  onEditClick,
  onButtonClick,
  onViewDetailsClick,
  isEdit,
  isButton,
  isViewDetails,
  currentIndex,
  loaderSize,
  childComment,
  onProjectButtonClick,
  onInfoClick,
  viewTitle = 'view details',
  onTitleClick,
  onCustClick,
  supplierTitleStyle = () => {},
  onProjectTitleClick,
  onPMtitleClick,
  modelName,
  onManageTitleClick,
  handleEditTime,
  userTimezone
}) => {
  const navigation = useNavigate();
  const userRole = useSelector(getUserType);
  const [expanded, setExpanded] = useState(false);
  const [itemName, setItemName] = useState('');

  useEffect(() => {
    if (type === defaultValues.listConstants.services) {
      setItemName(defaultValues.listConstants.title);
    }
    if (type === defaultValues.listConstants.languages) {
      setItemName(defaultValues.listConstants.language_title);
    }
    if (type === defaultValues.listConstants.skills) {
      setItemName(defaultValues.listConstants.skill_title);
    }
    if (type === defaultValues.listConstants.currency) {
      setItemName(defaultValues.listConstants.currency);
    }
    if (type === defaultValues.listConstants.countries) {
      setItemName(defaultValues.listConstants.country_name);
    }
    if (type === defaultValues.listConstants.masterList) {
      setItemName(defaultValues.listConstants.item);
    }
    if (type === defaultValues.listConstants.timezone) {
      setItemName(defaultValues.listConstants.timezone);
    }
    if (type === defaultValues.listConstants.projects) {
      setItemName(defaultValues.listConstants.project_title);
    }
    if (type === defaultValues.listConstants.state) {
      setItemName(defaultValues.listConstants.name);
    }
    if (type === defaultValues.listConstants.supplier) {
      setItemName(defaultValues.listConstants.first_name);
    }
    if (type === defaultValues.listConstants.company_customer) {
      setItemName(defaultValues.listConstants.company_name);
    }
    if (type === defaultValues.listConstants.manage_job) {
      setItemName(defaultValues.listConstants.job_title);
    }
    if (type === defaultValues.listConstants.active_project) {
      setItemName(defaultValues.listConstants.project_title);
    }
    if (type === defaultValues.listConstants.active_admin_project) {
      setItemName(defaultValues.listConstants.name);
    }
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {contentArray.map((item, index) => {
        return (
          <Accordion
            key={index}
            sx={CollapseStyles.container}
            expanded={expanded === index}
            onChange={handleChange(index)}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={CollapseStyles.expandIcon} />}
              aria-controls={index}
              sx={CollapseStyles.summeryBox}
              id={index}>
              {type === defaultValues.listConstants.admin ||
              type === defaultValues.listConstants.pm ? (
                <AdminProfile
                  profile={item}
                  onTitleClick={() => onPMtitleClick(item)}
                  titleStyle={{ ...customStyles.link, ...customStyles.sTitle }}
                />
              ) : type === defaultValues.listConstants.projects ||
                type === defaultValues.listConstants.active_project ||
                type === defaultValues.listConstants.active_admin_project ? (
                <ProjectTitle
                  title={item.project_title || item.name}
                  titleStyle={{ ...customStyles.link, ...customStyles.sTitle }}
                  onTitleClick={() => onProjectTitleClick(item)}
                  subTitle={item.flag}
                />
              ) : type === defaultValues.listConstants.supplier ? (
                <Box>
                  <Title
                    title={item.first_name + ' ' + item.last_name}
                    sx={{ ...CollapseStyles.maxWidth, ...supplierTitleStyle(item) }}
                    onClick={
                      item.isApproved === 0
                        ? null
                        : () => {
                            navigation(
                              `${SCREEN_PATH.VIEW_USER_PROFILE}?role=${getUserRole(
                                defaultValues.listConstants.supplier
                              )}&id=${item.id}`,
                              {
                                state: {
                                  type: defaultValues.listConstants.supplier
                                }
                              }
                            );
                          }
                    }
                  />
                  <Text variant="responsiveText" style={CollapseStyles.supEmail}>
                    {item.email}
                  </Text>
                </Box>
              ) : type === defaultValues.listConstants.company_customer ? (
                <Box sx={customStyles.flexBox}>
                  <img
                    src={item.company_logo_url ? getPhotoUrl(item.company_logo_url) : companyPic}
                    style={CollapseStyles.iconStyle}
                  />
                  <Title
                    title={item[itemName]}
                    sx={{ ...CollapseStyles.maxWidth, ...customStyles.link }}
                    onClick={() => onCustClick(item)}
                  />
                </Box>
              ) : type === defaultValues.listConstants.manageTeam ? (
                <Title
                  title={item.first_name + ' ' + item.last_name}
                  sx={{
                    ...CollapseStyles.maxWidth,
                    ...CollapseStyles.maxWidth,
                    ...customStyles.link
                  }}
                  onClick={() => onManageTitleClick(item)}
                />
              ) : (
                <Title
                  title={item[itemName]}
                  sx={
                    onTitleClick
                      ? { ...CollapseStyles.maxWidth, ...customStyles.link }
                      : CollapseStyles.maxWidth
                  }
                  onClick={() => onTitleClick(item)}
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={CollapseStyles.listContainer}>
                {(type === defaultValues.listConstants.projects ||
                  type === defaultValues.listConstants.active_project ||
                  type === defaultValues.listConstants.active_admin_project) && (
                  <>
                    {type !== defaultValues.listConstants.active_admin_project ? (
                      <Box sx={CollapseStyles.itemContainer}>
                        <Text style={CollapseStyles.textStyle} variant="responsiveDesc">
                          Id
                        </Text>

                        <Text style={CollapseStyles.projects} variant="responsiveDesc">
                          {item.id || item.project_id}
                        </Text>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex' }}>
                        <AdminProfile
                          profile={item.supplier[0] && item.supplier[0]}
                          titleStyle={{ ...customStyles.link, ...customStyles.sTitle }}
                        />
                        {item.supplier.length > 1 && <Text> +{item.supplier.length}</Text>}
                      </Box>
                    )}
                    {type === defaultValues.listConstants.projects ? (
                      <Box sx={CollapseStyles.itemContainer}>
                        <Text style={CollapseStyles.textStyle} variant="responsiveDesc">
                          Service
                        </Text>
                        <Text style={CollapseStyles.projects} variant="responsiveDesc">
                          {item.service.name}
                        </Text>
                      </Box>
                    ) : (
                      <Box sx={CollapseStyles.itemContainer}>
                        <Text style={CollapseStyles.textStyle} variant="responsiveDesc">
                          Date & time
                        </Text>

                        {userRole === defaultValues.userRole.supplier ? (
                          <Box>
                            <ConvertedTime
                              time={item.event_time}
                              originalTimeZoneName={
                                item.timezone_name ? item.timezone_name : item.timezone.name
                              }
                              targetTimeZoneName={userTimezone}
                            />
                            <Text variant="responsiveDesc">{userTimezone}</Text>
                          </Box>
                        ) : (
                          <Box>
                            <Text variant="responsiveDesc">
                              {getFormatDateNotUtc(item.event_time, 'DD-MM-YYYY hh:mm A')}
                            </Text>
                            <Text variant="responsiveDesc">
                              {item.timezone_name ? item.timezone_name : item.timezone.name}
                            </Text>
                          </Box>
                        )}
                        {type === defaultValues.listConstants.active_admin_project && (
                          <ModeEditIcon
                            sx={CollapseStyles.edit}
                            onClick={() =>
                              handleEditTime(item.id, item.event_time, item.project_timezone.name)
                            }
                          />
                        )}
                      </Box>
                    )}
                  </>
                )}
                {type === defaultValues.listConstants.manageTeam && (
                  <>
                    <Box sx={CollapseStyles.itemContainer}>
                      <Text style={CollapseStyles.textStyle} variant="responsiveDesc">
                        Date of association
                      </Text>

                      <Text variant="responsiveDesc">
                        {getFormatDate(item.date_of_association)}
                      </Text>
                    </Box>
                  </>
                )}

                {type !== defaultValues.listConstants.active_project && (
                  <Box sx={CollapseStyles.itemContainer}>
                    <Text style={CollapseStyles.textStyle} variant="responsiveDesc">
                      {'Status'}
                    </Text>
                    <Box sx={CollapseStyles.statusIndicator(type)}>
                      {type === defaultValues.listConstants.projects ||
                      type === defaultValues.listConstants.active_admin_project ? (
                        <ProjectStatus
                          data={item}
                          onChange={statusChange}
                          options={statusOptions}
                          role={userRole}
                        />
                      ) : (
                        <Box sx={CollapseStyles.textStyle}>
                          <StatusIndicator status={item.active || item.isActive} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
                {type === defaultValues.listConstants.currency && (
                  <Box sx={CollapseStyles.itemContainer}>
                    <Text style={CollapseStyles.textStyle} variant="responsiveDesc">
                      Symbol
                    </Text>
                    <Text style={CollapseStyles.symbol} variant="responsiveDesc">
                      {item.currency_symbol}
                    </Text>
                  </Box>
                )}

                {type === defaultValues.listConstants.pm && (
                  <Box sx={CollapseStyles.itemContainer}>
                    <Text variant="responsiveDesc">Project count</Text>
                    <Text style={CollapseStyles.admin1} variant="responsiveDesc">
                      {item.project_count}
                    </Text>
                  </Box>
                )}

                {![
                  defaultValues.listConstants.supplier,
                  defaultValues.listConstants.active_project,
                  defaultValues.listConstants.active_admin_project
                ].includes(type) && (
                  <Box sx={CollapseStyles.itemContainer}>
                    <Text style={CollapseStyles.textStyle} variant="responsiveDesc">
                      Actions
                    </Text>
                    <Box sx={CollapseStyles.actionButtonContainer}>
                      <ActionButton
                        viewTitle={viewTitle}
                        isEdit={isEdit}
                        isButton={isButton}
                        isViewDetails={isViewDetails}
                        item={item}
                        onButtonClick={() => onButtonClick(item.id, item.active, index, item)}
                        onViewDetailsClick={() => onViewDetailsClick && onViewDetailsClick(item)}
                        onEditClick={() => onEditClick(item, index)}
                        isLoading={currentIndex && index === currentIndex}
                        loaderSize={loaderSize}
                        projectButtonClick={() => onProjectButtonClick(item)}
                        onInfoClick={() => onInfoClick(item)}
                        type={type}
                        name={modelName ? `${item.first_name} ${item.last_name}` : item[itemName]}
                      />
                    </Box>
                  </Box>
                )}
                {childComment && childComment(item)}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
