import { color } from '../../../theme/color';

export const EmailStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box'
  },
  label: {
    marginTop: '20px',
    marginBottom: '10px'
  },
  emailField: (isRes) => ({
    width: isRes ? '100%' : '80%',
    backgroundColor: color.palette.textFieldRed,
    borderRadius: '4px'
  }),
  buttonStyle: (errors, email, isRes) => ({
    backgroundColor: !errors && email ? color.palette.primaryRed : color.palette.primaryDisabledRed,
    display: 'block',
    marginTop: '30px',
    color: color.palette.primaryWhite,
    fontSize: isRes ? '15px' : '24px',
    width: isRes ? '100%' : '200px',
    padding: '5px 50px'
  }),
  heading: {
    fontWeight: 600
  },
  errorStyle: {
    marginLeft: 0
  }
};
