import { Box, Rating } from '@mui/material';
import { Text } from '../../../ui-kit';
import { color, palette } from '../../../theme';
import { BasicProfileDetails } from '../../other-settings/table-title';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { getUserFullname, getUserType, userInfo } from '../../../services/redux/selectors';
import { useSelector } from 'react-redux';
import { getFormatDateNotUtc, getPhotoUrl, getUserRoleName } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;

const styles = {
  container: {
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    padding: '20px',
    boxSizing: 'border-box',
    marginBottom: '10px'
  },
  message: {
    fontSize: res ? '16px' : md ? '18px' : '22px',
    marginTop: '10px'
  },
  profileCont: {
    width: '100%',
    display: 'flex',
    flexDirection: sm ? 'column' : 'row',
    justifyContent: sm ? 'none' : 'space-between',
    gap: '7px'
  },
  profileSub: {
    width: sm ? '100%' : '40%'
  },
  feedStatus: (role) => ({
    color: role && role === 3 ? color.palette.buttonGreen : color.palette.primaryRed,
    backgroundColor: role && role === 3 ? color.palette.lightGreen : color.palette.primaryLightRed,
    borderRadius: '4px',
    width: res ? '120px' : '180px',
    height: res ? '30px' : '40px',
    fontSize: res ? '14px' : '18px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  ratingMBox: {
    width: '100%',
    display: 'flex'
  },
  ratingMText: {
    width: sm ? '40%' : res ? '30%' : md ? '35%' : '20%',
    margin: '10px 0px',
    fontSize: sm ? '14px' : res ? '16px' : '22px'
  },
  ratingStar: {
    '& .MuiRating-icon': {
      fontSize: res ? '25px' : '35px'
    },
    margin: '10px 0px'
  },
  subStyle: {
    fontSize: res ? '12px' : '16px'
  },
  titleStyle: (userType) => ({
    fontSize: sm ? '16px' : '24px',
    textDecoration:
      [defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType) &&
      'underline',
    cursor:
      [defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType) &&
      'pointer'
  }),
  date: {
    color: palette.gray,
    fontSize: res ? '12px' : md ? '16px' : '18px'
  }
};

export const FeedbackCard = ({ item }) => {
  const navigate = useNavigate();
  const userType = useSelector(getUserType);
  const fullname = useSelector(getUserFullname);
  const userData = useSelector(userInfo);

  const getDp = () => {
    if ([defaultValues.userRole.customer, defaultValues.userRole.supplier].includes(userType)) {
      return getPhotoUrl(userData.photo_url);
    } else if (
      [defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType)
    ) {
      return getPhotoUrl(item.submitted_by.photo_url);
    } else {
      return '';
    }
  };

  const handleTitleClick = (data) => {
    if ([defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType)) {
      navigate(
        `${SCREEN_PATH.VIEW_USER_PROFILE}?role=${data.role}&id=${
          data.role === 3 ? data.customer_id : data.supplier_id
        }`,
        {
          state: {
            type:
              data.role === 3
                ? defaultValues.listConstants.customers
                : defaultValues.listConstants.supplier
          }
        }
      );
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.profileCont}>
        <Box sx={styles.profileSub}>
          <BasicProfileDetails
            title={
              [defaultValues.userRole.customer, defaultValues.userRole.supplier].includes(userType)
                ? fullname
                : [defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(
                      userType
                    )
                  ? item && item.submitted_by && item.submitted_by.full_name
                  : ''
            }
            onTitleClick={() => handleTitleClick(item.submitted_by)}
            titleStyle={styles.titleStyle(userType)}
            subStyle={styles.subStyle}
            subTitle={
              [defaultValues.userRole.customer, defaultValues.userRole.supplier].includes(userType)
                ? userData.email
                : [defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(
                      userType
                    )
                  ? item && item.submitted_by && item.submitted_by.email
                  : ''
            }
            propic={true}
            image={getDp()}
          />
        </Box>
        {[defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType) ? (
          <Text style={styles.feedStatus(item.submitted_by && item.submitted_by.role)}>
            {getUserRoleName(item.submitted_by && item.submitted_by.role)}
          </Text>
        ) : [defaultValues.userRole.customer, defaultValues.userRole.supplier].includes(
            userType
          ) ? (
          <Text style={styles.date}>
            {getFormatDateNotUtc(item.created_at, 'DD-MM-YYYY hh:mm A')}
          </Text>
        ) : (
          <></>
        )}
      </Box>

      <Text style={styles.message}>{item.comment}</Text>

      {((item && item.submitted_by && item.submitted_by.role === 3) ||
        userType === defaultValues.userRole.customer) && (
        <Box>
          <Box sx={styles.ratingMBox}>
            <Text sx={styles.ratingMText}>Booking process</Text>
            <Rating sx={styles.ratingStar} readOnly value={item.booking_process_rating} />
          </Box>

          <Box sx={styles.ratingMBox}>
            <Text sx={styles.ratingMText}>Captioner</Text>
            <Rating sx={styles.ratingStar} readOnly value={item.captioner_rating} />
          </Box>
          <Box sx={styles.ratingMBox}>
            <Text sx={styles.ratingMText}>Overall service</Text>
            <Rating sx={styles.ratingStar} readOnly value={item.overall_rating} />
          </Box>
        </Box>
      )}

      {((item && item.submitted_by && item.submitted_by.role === 4) ||
        userType === defaultValues.userRole.supplier) && (
        <Box>
          <Box sx={styles.ratingMBox}>
            <Text sx={styles.ratingMText}>Audio quality</Text>
            <Rating sx={styles.ratingStar} readOnly value={item.audio_quality} />
          </Box>

          <Box sx={styles.ratingMBox}>
            <Text sx={styles.ratingMText}>Quality of preperation materials</Text>
            <Rating sx={styles.ratingStar} readOnly value={item.quality_of_prep_materials} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
