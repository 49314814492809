import { put, takeLatest } from 'redux-saga/effects';
import {
  addAdminFailure,
  addAdminRequest,
  addAdminSuccess,
  addTeamMemberFailure,
  addTeamMemberRequest,
  addTeamMemberSuccess,
  adminActiveProjectsFailure,
  adminActiveProjectsSuccess,
  approveTeamMemberFailure,
  approveTeamMemberRequest,
  approveTeamMemberSuccess,
  customerDashboardFailure,
  customerDashboardSuccess,
  dashboardDataRequest,
  dashboardDataRequestFailure,
  dashboardDataRequestSuccess,
  deleteCompanyLogoFailure,
  deleteCompanyLogoRequest,
  deleteCompanyLogoSuccess,
  editAdminFailure,
  editAdminRequest,
  editAdminSuccess,
  editCustomerCompanyFailure,
  editCustomerCompanyRequest,
  editCustomerCompanySuccess,
  editTeamMemberFailure,
  editTeamMemberRequest,
  editTeamMemberSuccess,
  getAdminActiveProjects,
  getAdminFailure,
  getAdminRequest,
  getAdminSuccess,
  getCompanyDetailsFailure,
  getCompanyDetailsRequest,
  getCompanyDetailsSuccess,
  projectManagerDashboardFailure,
  projectManagerDashboardSuccess,
  requestCustomerDashboard,
  requestProjectManagerDashboard,
  requestSupplierDashboard,
  supplierDashboardFailure,
  supplierDashboardSuccess,
  updateProjects,
  updateProjectsFailure,
  updateProjectsSuccess
} from '../slices/adminSlice';
import {
  FuctionDashBoard,
  addAdminsApi,
  addTeamMemberAPI,
  approveTeamMemberAPI,
  customerDashBoardAPI,
  deleteCompanyLogoApi,
  editAdminsApi,
  editCustomerCompanyAPI,
  editTeamMemberAPI,
  getActiveProjectsApi,
  getAdminsApi,
  getCustomerConpanyDetails,
  projectManagerDashBoardAPI,
  supplierDashBoardAPI,
  updateProjectAPI
} from '../../api';
import { SUCCESS_KEY } from '../../../constants';
import { updateProjectTime } from '../slices';

function* getDashBoardData(action) {
  try {
    const response = yield FuctionDashBoard(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(dashboardDataRequestSuccess(response.data));
    } else {
      yield put(dashboardDataRequestFailure(response.data));
    }
  } catch (error) {
    yield put(dashboardDataRequestFailure('E-10001'));
  }
}

function* getAdminData(action) {
  try {
    const response = yield getAdminsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getAdminSuccess(response.data));
    } else {
      yield put(getAdminFailure(response.data));
    }
  } catch (error) {
    yield put(getAdminFailure('E-10001'));
  }
}

function* addAdminData(action) {
  try {
    const response = yield addAdminsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addAdminSuccess(response.data));
    } else {
      yield put(addAdminFailure(response.data));
    }
  } catch (error) {
    yield put(getAdminFailure('E-10001'));
  }
}

function* editAdminData(action) {
  try {
    const response = yield editAdminsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editAdminSuccess(response.data));
    } else {
      yield put(editAdminFailure(response.data));
    }
  } catch (error) {
    yield put(editAdminFailure('E-10001'));
  }
}

function* customerCompanyDetails(action) {
  try {
    const response = yield getCustomerConpanyDetails(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getCompanyDetailsSuccess(response.data));
    } else {
      yield put(getCompanyDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getCompanyDetailsFailure('E-10001'));
  }
}

function* addTeamMember(action) {
  try {
    const response = yield addTeamMemberAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addTeamMemberSuccess(response.data));
    } else {
      yield put(addTeamMemberFailure(response.data));
    }
  } catch (error) {
    yield put(addTeamMemberFailure('E-10001'));
  }
}

function* editTeamMember(action) {
  try {
    const response = yield editTeamMemberAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editTeamMemberSuccess(response.data));
    } else {
      yield put(editTeamMemberFailure(response.data));
    }
  } catch (error) {
    yield put(editTeamMemberFailure('E-10001'));
  }
}

function* editCustomerCompany(action) {
  try {
    const response = yield editCustomerCompanyAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editCustomerCompanySuccess(response.data));
    } else {
      yield put(editCustomerCompanyFailure(response.data));
    }
  } catch (error) {
    yield put(editCustomerCompanyFailure('E-10001'));
  }
}

function* approveTeamMember(action) {
  try {
    const response = yield approveTeamMemberAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(approveTeamMemberSuccess(response.data));
    } else {
      yield put(approveTeamMemberFailure(response.data));
    }
  } catch (error) {
    yield put(approveTeamMemberFailure('E-10001'));
  }
}

function* deleteCompanyLogo(action) {
  try {
    const response = yield deleteCompanyLogoApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(deleteCompanyLogoSuccess(response.data));
    } else {
      yield put(deleteCompanyLogoFailure(response.data));
    }
  } catch (error) {
    yield put(deleteCompanyLogoFailure('E-10001'));
  }
}

function* customerDashboard(action) {
  try {
    const response = yield customerDashBoardAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(customerDashboardSuccess(response.data));
    } else {
      yield put(customerDashboardFailure(response.data));
    }
  } catch (error) {
    yield put(customerDashboardFailure('E-10001'));
  }
}

function* supplierDashboard(action) {
  try {
    const response = yield supplierDashBoardAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(supplierDashboardSuccess(response.data));
    } else {
      yield put(supplierDashboardFailure(response.data));
    }
  } catch (error) {
    yield put(supplierDashboardFailure('E-10001'));
  }
}

function* projectManagerDashboard(action) {
  try {
    const response = yield projectManagerDashBoardAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(projectManagerDashboardSuccess(response.data));
    } else {
      yield put(projectManagerDashboardFailure(response.data));
    }
  } catch (error) {
    yield put(projectManagerDashboardFailure('E-10001'));
  }
}

function* adminActiveProjects(action) {
  try {
    const response = yield getActiveProjectsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(adminActiveProjectsSuccess(response.data));
    } else {
      yield put(adminActiveProjectsFailure(response.data));
    }
  } catch (error) {
    yield put(adminActiveProjectsFailure('E-10001'));
  }
}

function* updateProject(action) {
  try {
    const response = yield updateProjectAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateProjectsSuccess(response.data));
      yield put(updateProjectTime(response.data));
    } else {
      yield put(updateProjectsFailure(response.data));
    }
  } catch (error) {
    yield put(updateProjectsFailure('E-10001'));
  }
}

export function* adminSaga() {
  yield takeLatest(dashboardDataRequest.type, getDashBoardData);
  yield takeLatest(getAdminRequest.type, getAdminData);
  yield takeLatest(addAdminRequest.type, addAdminData);
  yield takeLatest(editAdminRequest.type, editAdminData);
  yield takeLatest(getCompanyDetailsRequest.type, customerCompanyDetails);
  yield takeLatest(addTeamMemberRequest.type, addTeamMember);
  yield takeLatest(editTeamMemberRequest.type, editTeamMember);
  yield takeLatest(editCustomerCompanyRequest.type, editCustomerCompany);
  yield takeLatest(approveTeamMemberRequest.type, approveTeamMember);
  yield takeLatest(deleteCompanyLogoRequest.type, deleteCompanyLogo);
  yield takeLatest(requestCustomerDashboard.type, customerDashboard);
  yield takeLatest(requestSupplierDashboard.type, supplierDashboard);
  yield takeLatest(requestProjectManagerDashboard.type, projectManagerDashboard);
  yield takeLatest(getAdminActiveProjects.type, adminActiveProjects);
  yield takeLatest(updateProjects.type, updateProject);
}
