import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const isResponsive = defaultValues.isResponsive;
export const styles = {
  container: {
    display: 'flex',
    flexDirection: isResponsive ? 'column' : 'row',
    width: '100%',
    height: '100%',
    mt: 4
  },
  logoStyle: {
    width: isResponsive ? '30%' : '300px',
    height: isResponsive ? '100px' : '250px',
    flexShrink: '0'
  },
  detailsContainer: {
    width: isResponsive ? '100%' : '75%',
    display: 'flex',
    flexDirection: 'column',
    ml: !isResponsive && 3
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%'
  },
  editIcon: { cursor: 'pointer' },
  locationContainer: { display: 'flex', flexDirection: 'row', my: 2 },
  descContainer: { m: 1 },
  fieldStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
  },
  inpuTextStyle: (isRes) => ({
    input: {
      width: '100%',
      backgroundColor: color.palette.textFieldRed,
      '& fieldset': { border: 'none' },
      borderRadius: 1
    },
    title: { fontSize: isRes ? '16px' : '28px', mb: '5px' }
  }),
  selectOptionContainer: { width: '48%' },
  selectFieldStyle: { formContainer: { width: '95%' } },
  btn: { marginTop: '20px', width: isResponsive && '100%' },
  selectTitle: (isRes) => ({
    mB: { fontSize: isRes ? '16px' : '28px' },
    mainBox: { width: '100%' }
  }),
  input: (isRes) => ({
    inputContainer: {
      width: isRes ? '100%' : '80%',
      marginTop: '25px',
      '& fieldset': { border: 'none' },
      borderRadius: 1
    },
    title: { fontSize: isRes ? '16px' : '28px', mb: '5px' }
  }),
  regNoColor: { color: color.palette.emailGray },
  logoContainer: { width: '30%' },
  autoStyle: (isRes) => ({
    mB: { fontSize: isRes ? '16px' : '28px' },
    mainBox: { width: '100%' }
  }),
  customWidth: '98%',
  responsiveContainer: { display: 'flex', width: '100%' },
  responsiveTextContainer: { width: '80%', ml: 2 }
};
