import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';
import { palette } from '../../../theme/palette';

const responsive = defaultValues.isResponsive;
export const footerStyles = {
  container: {
    alignItems: 'center',
    color: color.palette.primaryWhite,
    width: '100%',
    backgroundColor: color.palette.primaryBlack
  },
  socialContainer: {
    mt: 2,
    alignItems: 'center'
  },

  descText: { mt: 2, color: color.palette.primaryWhite },
  buttonStyle: {
    backgroundColor: color.palette.primaryRed,
    ml: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
    color: palette.primaryWhite,
    '&:hover': {
      backgroundColor: color.primary
    }
  },
  inputFieldStyle: (isResponsive) => ({
    backgroundColor: color.palette.primaryWhite,
    borderRadius: 1,
    width: isResponsive ? '100%' : '285px'
  }),

  linkTexts: {
    color: color.palette.primaryWhite,
    cursor: 'pointer',
    padding: '8px 8px 8px 0px',
    paddingRight: '3px'
  },

  subHeading: {
    color: color.palette.primaryWhite,
    padding: '11px 11px 11px 0px',
    paddingRight: '10px',
    fontSize: responsive ? '20px' : '24px',
    fontWeight: 500
  },
  icon: {
    cursor: 'pointer',
    width: '42px',
    height: '42px'
  },
  iconSecondary: {
    cursor: 'pointer',
    width: '38px',
    height: '36px'
  },
  link: { color: 'inherit', textDecoration: 'none' },
  mainContainer: (isResponsive) => ({
    flex: 1,
    display: 'flex',
    justifyContent: !isResponsive && 'center'
  }),

  subContainer: (isResponsive) => ({
    display: 'flex',
    flexDirection: isResponsive ? 'column' : 'row',
    justifyContent: 'center',
    p: isResponsive ? 1 : 5,
    ml: isResponsive ? 1 : 5
  }),

  desContainer: { width: defaultValues.isResponsive ? '100%' : '90%', marginTop: '10px' },

  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  labelContainer: (responsive) => ({
    display: 'flex',
    px: responsive ? 0 : 5,
    ml: !responsive && 3,
    mr: !responsive && 5,
    justifyContent: responsive ? 'center' : 'space-between',
    flexDirection: responsive ? 'column' : 'row'
  }),
  laberLeft: (isResponsive) => ({
    width: isResponsive ? '90%' : '50%',
    textAlign: responsive ? 'center' : 'left',
    p: 2,
    display: 'flex',
    gap: '10px'
  }),
  laberRight: (isResponsive) => ({
    p: 2,
    width: isResponsive ? '90%' : '50%',
    textAlign: responsive ? 'center' : 'right',
    borderTop: responsive ? `1px solid ${color.primaryBorder}` : 'none'
  }),
  labelText: (isResponsive) => ({
    fontSize: isResponsive ? 8 : 15,
    fontWeight: 400,
    color: color.palette.primaryWhite,
    cursor: 'pointer',
    textDecoration: 'none'
  }),
  copyRight: {
    cursor: 'default'
  },
  flex: { flex: 1 },

  logoContainer: (isRes) => ({
    width: isRes ? '50%' : '70%',
    height: isRes ? '30%' : '40%'
  }),
  logo: {
    width: '100%',
    height: '100%',
    maxWidth: '260px',
    maxHeight: '90px'
  },
  privacy: { textDecoration: 'none' }
};
