import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

export const responsive = defaultValues.isResponsive;
export const otpStyles = {
  otpContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '15px'
  },
  otpSubContainer: { width: responsive ? '100%' : 'fit-content' },
  otpResendContainer: {
    width: '100%'
  },
  otpInput: {
    gap: responsive ? '5px' : '20px',
    justifyContent: responsive ? 'space-between' : 'left',
    '& .MuiOtpInput-TextField': {
      border: '1px solid',
      borderRadius: '8px',
      width: responsive ? '40px' : '58px',
      height: responsive ? '40px' : '58px',
      fontSize: '12px',
      color: 'black',
      fontWeight: 400,
      backgroundColor: 'white'
    },
    '& .MuiInputBase-root': {
      height: '100%',
      width: '100%'
    },
    '& .MuiInputBase-input': {
      padding: 0,
      fonSize: '8px'
    }
  },
  resendOtp: {
    color: color.palette.primaryWhite,
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontSize: responsive && '11px',
    marginTop: '10px'
  },
  otpTimer: (otpsend) => ({
    color: color.palette.primaryWhite,
    boxSizing: 'border-box',
    cursor: otpsend ? 'default' : 'pointer',
    fontSize: responsive && '11px',
    marginTop: '10px'
  }),
  otpNotification: {
    color: color.palette.primaryWhite,
    fontSize: responsive && '11px'
  },
  errorField: {
    color: color.palette.primaryRed,
    fontSize: responsive ? '11px' : '15px',
    marginTop: '10px'
  }
};
