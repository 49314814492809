/*
 *Global cnfig for api and other credentials which are kept in .env
 *All the secrets should be kept in .env file
 *and configured here with some variables
 * Here some sample API endpoints are shown,
 *change them as per project requirement
 */

const {
  REACT_APP_SERVER,
  REACT_APP_API_URL_DEV,
  REACT_APP_API_URL_TEST,
  REACT_APP_API_URL_UAT,
  REACT_APP_API_URL_PROD
} = process.env;

export const API = {
  currentEnv: REACT_APP_SERVER, //api server environment dev/test/uat/prod, fetched from .env
  baseURL: {
    dev: REACT_APP_API_URL_DEV,
    test: REACT_APP_API_URL_TEST, //remove if there is no test serverd configured
    uat: REACT_APP_API_URL_UAT,
    prod: REACT_APP_API_URL_PROD
  },
  //change the below endpoints as per project requirement
  noAuthUrls: {
    request_login_otp: 'api/v1/request_login_otp',
    verify_login: 'api/v1/verify_login_otp',
    resend_login_otp: 'api/v1/resend_login_otp',
    request_enquiry_otp: 'api/v1/request_enquiry_otp',
    verify_enquiry_otp: 'api/v1/verify_enquiry_otp',
    resend_enquiry_otp: 'api/v1/resend_enquiry_otp',
    supplier_registration: 'api/v1/supplier_reg',
    verify_supplier: 'api/v1/verify_supplier_reg_otp',
    resend_supplier_otp: 'api/v1/resend_supplier_reg_otp'
  },
  auth_urls: {
    add_language: 'api/v1/add_language',
    get_languages: 'api/v1/languages',
    add_services: 'api/v1/add_service',
    admin_dashboard: 'api/v1/admin_dashboard',
    get_skills: 'api/v1/skills',
    get_currencies: 'api/v1/currencies',
    get_countries: 'api/v1/countries',
    state: 'api/v1/states',
    get_admins: 'api/v1/admins',
    services: 'api/v1/services',
    services_question: 'api/v1/question',
    master_list: 'api/v1/master_list',
    get_services: 'api/v1/services',
    search_customer_company: 'api/v1/search-customer-company',
    register_customer_company: 'api/v1/register-customer-company',
    submit_enquiry: '/api/v1/submit_enquiry',
    get_timeZone: 'api/v1/timezone',
    get_projects: 'api/v1/projects',
    project_status: 'api/v1/projects-statuses',
    update_project_status: 'api/v1/update-project-status',
    customer_company_details: 'api/v1/customer_company_details',
    add_team_member: 'api/v1/add_team_member',
    edit_team_member: 'api/v1/update_team_member',
    edit_custome_company_details: 'api/v1/update_customer_company_details',
    associate_customer_company: 'api/v1/customer-company-association',
    approve_team_member: 'api/v1/approve_team_member',
    customer: '/api/v1/customer',
    customer_company: '/api/v1/customer-companies',
    company_note: '/api/v1/cc_notes',
    get_profile: 'api/v1/my_profile',
    update_profile: 'api/v1/my-profile',
    profile_picture: 'api/v1/profile-picture',
    suppliers: 'api/v1/suppliers',
    supplierList: 'api/v1/project-suppliers-list',
    quotation: 'api/v1/quotation',
    accept_quotation: 'api/v1/quotation-acceptance',
    reject_quotation: 'api/v1/quotation-rejection',
    updateSuppliers: 'api/v1/update-supplier-status',
    approvalSuppliers: 'api/v1/supplier-approval',
    viewUserProfile: 'api/v1/user-profile',
    project_manager: 'api/v1/project-managers',
    get_status_id: 'api/v1/project_current_status',
    project_details: 'api/v1/project_details',
    supplier_recommendation: 'api/v1/supplier_recommendation',
    post_jobs: 'api/v1/jobs',
    supplier_invitation: 'api/v1/supplier-invitation',
    project_invitation: 'api/v1/project-invitations',
    job_applicants: 'api/v1/job-appliants',
    apply_job: 'api/v1/apply-to-jobs',
    assign_pm: 'api/v1/assign-pm',
    remove_pm: 'api/v1/remove-pm',
    remove_supplier: 'api/v1/remove-project-supplier',
    task_lists: 'api/v1/task-list',
    documents: 'api/v1/documents',
    project_members: 'api/v1/project-members',
    task: 'api/v1/tasks',
    task_list_status: 'api/v1/task-list-status',
    task_status: 'api/v1/tasks-status',
    company_logo: 'api/v1/company_logo',
    task_shedules: 'api/v1/schedules',
    task_details: 'api/v1/task-details',
    notification: 'api/v1/notifications',
    notification_count: 'api/v1/unread-notifications',
    personal_threads: 'api/v1/personal-threads',
    personal_messages: 'api/v1/perosnal-messages',
    update_read_status: 'api/v1/update-read-status',
    list_comments: 'api/v1/list-comments',
    task_comments: 'api/v1/task-comments',
    rating: 'api/v1/rating',
    feedback: 'api/v1/feedback',
    supplier_feedback: 'api/v1/supplier-feedback',
    customer_feedback: 'api/v1/customer-feedbacks',
    project_threads: 'api/v1/project-threads',
    project_messages: 'api/v1/project-messages',
    customer_dashboard: 'api/v1/customer-dashboard',
    supplier_dashboard: 'api/v1/supplier-dashboard',
    project_manager_dashboard: 'api/v1/pm-dashboard',
    projects_logs: 'api/v1/project-logs',
    active_projects: 'api/v1/active-projects',
    update_project: 'api/v1/update-project'
  }
};
