import { createSelector } from 'reselect';

export const getProfileLoadingStatus = createSelector(
  [(state) => state.profile.isLoading],
  (loadingStatus) => loadingStatus
);

export const getProfileData = createSelector(
  [(state) => state.profile.profileData],
  (profile) => profile
);

export const getProfilePicture = createSelector(
  [(state) => state.profile.profilePicture],
  (profilePic) => profilePic
);

export const getProfileSuccessMessage = createSelector(
  [(state) => state.profile.successMessage],
  (successMessage) => successMessage
);

export const getProfileErrorMessage = createSelector(
  [(state) => state.profile.errorMessage],
  (errorMessage) => errorMessage
);
