import React from 'react';
import { Box } from '@mui/material';
import { Text } from '../text';
import { TextInput } from '../text-field';
import { color } from '../../theme/color';
import { defaultValues } from '../../constants';

export const UITextInputWithTitle = ({
  title,
  name,
  required = false,
  value,
  placeholder = '',
  onChange,
  error,
  touched,
  size = defaultValues.isResponsive ? 'small' : 'medium',
  onBlur,
  lines = 1,
  variant = 'semiBold',
  style = {}, // Add style prop
  allowOnlyAlphabets = false,
  allowOnlyNumbers = false,
  ...rest
}) => {
  const responsive = defaultValues.isResponsive;
  const defaultStyles = {
    inputContainer: {
      width: responsive ? '100%' : '100%',
      marginTop: '15px'
    },
    title: {
      marginBottom: '10px'
    },
    input: {
      boxShadow: 0,
      width: '100%',
      backgroundColor: color.palette.textFieldRed,
      borderRadius: '8px',
      '& fieldset': { border: 'none' },
      '& .MuiInputBase-input': {
        fontSize: responsive ? '12px' : '20px'
      }
    },
    asterisk: {
      color: color.palette.red
    }
  };

  const styles = {
    ...defaultStyles,
    ...style
  };

  const handleAlphabeticChange = (e) => {
    const inputValue = e.target.value;
    if (/^[A-Za-z]*$/.test(inputValue)) {
      onChange(e);
    }
  };

  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*$/.test(inputValue)) {
      onChange(e);
    }
  };

  return (
    <Box sx={styles.inputContainer}>
      <Text variant={variant} style={styles.title}>
        {title} {required && <span style={styles.asterisk}>*</span>}
      </Text>
      <TextInput
        id={name}
        size={size}
        name={name}
        style={styles.input}
        value={value}
        placeholder={placeholder}
        error={touched && error}
        errorStyle={styles.errorField}
        onBlur={onBlur}
        onChange={
          allowOnlyAlphabets
            ? handleAlphabeticChange
            : allowOnlyNumbers
              ? handleNumberChange
              : onChange
        }
        required={required}
        lines={lines}
        {...rest}
      />
    </Box>
  );
};
