import { Box } from '@mui/material';
import { MasterTable, Text, UIButton, UISubTitleBar } from '../../../../ui-kit';
import { ADD_KEY, EDIT_KEY, defaultValues } from '../../../../constants';
import { CollapsableList } from '../../../common/collapsable-list';

import { CDStyles } from './style';
import { countriesStyles } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { addStateList, pushStateRequest } from '../../../../services/redux/slices';
import { useEffect, useState } from 'react';
import {
  getSettingsSuccessMessage,
  getStats,
  settingLoadingStatus
} from '../../../../services/redux/selectors';
import { OtherSettingsModal } from '../../../modal';
import { stateSchema } from '../../../../utils';
import { useFormik } from 'formik';

export const CountriesDetails = ({ onBack, detailData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(settingLoadingStatus);
  const successMessage = useSelector(getSettingsSuccessMessage);
  const selStates = useSelector(getStats);
  const [states, setStates] = useState([]);
  const [showModel, setShowModel] = useState(false);

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    isValid
  } = useFormik({
    initialValues: {
      country_id: '',
      state_name: ''
    },
    validationSchema: stateSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  useEffect(() => {
    dispatch(addStateList(detailData.states));
    setFieldValue('country_id', detailData.id);
  }, [detailData]);

  useEffect(() => {
    if (selStates && selStates.length > 0) {
      setStates(selStates.slice().sort((a, b) => a.name.localeCompare(b.name)));
    } else {
      setStates([]);
    }
  }, [selStates]);

  useEffect(() => {
    if (!isLoading && successMessage) {
      handleClose();
    }
  }, [detailData, isLoading]);

  const handleEditCountry = (editData) => {
    const payload = {
      country_id: detailData.id,
      ...editData
    };
    dispatch(pushStateRequest({ type: EDIT_KEY, payload }));
  };

  const handleClose = () => {
    setShowModel(false);
  };

  const onAddState = () => {
    const payload = values;
    dispatch(pushStateRequest({ type: ADD_KEY, payload }));
  };

  return (
    <Box sx={CDStyles.container}>
      <Box sx={CDStyles.contentContainer}>
        <UISubTitleBar
          title="Details"
          onBack={onBack}
          endComponent={
            <UIButton
              style={countriesStyles.newButton}
              title="+ new State"
              onClick={() => setShowModel(true)}
            />
          }
        />
        <Box sx={countriesStyles.contentContainer}>
          <Text sx={countriesStyles.heading} variant="menuText">
            States of {detailData.country_name}
          </Text>

          {!defaultValues.isResponsive ? (
            <MasterTable
              columnName="name"
              data={states}
              title="State"
              notFoundMessage="State Not found"
              onChangeState={(data) => handleEditCountry({ ...data, active: !data.active })}
            />
          ) : (
            <Box sx={countriesStyles.collapseContainer}>
              <CollapsableList
                isEdit={false}
                onButtonClick={(id, active, index, item) =>
                  handleEditCountry({ ...item, active: !item.active })
                }
                contentArray={states}
                type="state"
              />
            </Box>
          )}
          {states.length === 0 && (
            <Box sx={CDStyles.notFound}>
              <Text>State Not Found</Text>
            </Box>
          )}
        </Box>
      </Box>
      <OtherSettingsModal
        disabledInput1={true}
        open={showModel}
        close={handleClose}
        heading={defaultValues.countriesModalHeadingState}
        inputTitle1="Country"
        placeholder1="Enter state name"
        inputValue1={detailData.country_name}
        placeholder2="Enter State name"
        inputTitle2="State"
        inputName1={'country'}
        inputName2={'state_name'}
        buttonTitle={'save'}
        onChange={handleChange}
        error={errors}
        onBlur={handleBlur}
        setFieldTouched={setFieldTouched}
        setFieldError={setFieldError}
        touched={touched}
        onButtonClick={onAddState}
        type="state"
        disabled={!isValid}
        stateList={values.stateList}
        isLoading={isLoading}
      />
    </Box>
  );
};
