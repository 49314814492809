import React, { useState } from 'react';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { Text } from '../text';
import { color } from '../../theme/color';

export const UISelectWithTitle = ({
  title,
  name,
  required = false,
  value,
  placeholder = '',
  onChange,
  error,
  touched,
  onBlur,
  style,
  options, // option is required like [{name: 'a1', value: '1'}]
  minWidth,
  marginTop = '15px',
  marginBottom = '25px',
  ...rest
}) => {
  const styles = {
    inputContainer: {
      width: '80%',
      marginTop: marginTop,
      marginBottom: marginBottom
    },
    title: {
      marginBottom: '10px'
    },
    input: {
      boxShadow: 0,
      minWidth: minWidth || '150px',
      backgroundColor: color.palette.primaryWhite,
      border: `2px solid ${color.palette.primaryBlack}`,
      borderRadius: '8px',
      fontWeight: 'bold'
    }
  };

  const [ShowPlaceholder, setShowPlaceholder] = useState(true);

  return (
    <Box sx={styles.inputContainer}>
      <Text variant="semiBold" sx={styles.title}>
        {title}
      </Text>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          displayEmpty
          id={name}
          name={name}
          sx={{ ...styles.input, ...style }}
          value={value}
          onFocus={() => setShowPlaceholder(false)}
          error={touched && error}
          errorStyle={styles.errorField}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          required={required}
          {...rest}>
          {ShowPlaceholder && (
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {options &&
            options.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};
