import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';
import { fonts } from '../../../theme/fonts';

const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;
export const countriesStyles = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.palette.primaryBackground,
    borderRadius: '5px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: responsive ? '0%' : '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px'
  },
  searchTabContainer: {
    width: '100%',
    height: responsive ? 'auto' : '60px',
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    alignItems: 'center',
    padding: '5px 0px',
    boxSizing: 'border-box'
  },
  searchToolsContainer: {
    width: responsive ? '100%' : '80%',
    height: '100%',
    display: 'flex',
    justifyContent: responsive ? 'space-between' : 'none',
    alignItems: responsive ? 'center' : 'none',
    paddingBottom: responsive ? '8px' : '0px',
    paddingTop: responsive ? '6px' : '0px',
    borderBottom: responsive ? `1px solid ${color.primaryBorder}` : 'none'
  },
  newButton: {
    padding: responsive ? '6px 11px' : '7px 30px',
    backgroundColor: color.palette.primaryRed,
    textTransform: 'none',
    marginRight: '10px',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: color.palette.primaryRed
    },
    fontSize: responsive ? '12px' : md ? '16px' : '20px'
  },
  toolsContainer: {
    width: responsive ? '100%' : 'fit-content',
    display: 'flex',
    justifyContent: responsive ? 'center' : 'end',
    marginTop: responsive ? '10px' : '0px',
    alignItems: 'center'
  },
  heading: {
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 600,
    marginLeft: responsive ? '20px' : '0'
  },
  tableContainer: {
    minWidth: 650,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '5px'
  },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 }
  },
  headingCell: {
    fontSize: '22px',
    fontFamily: fonts.primary,
    backgroundColor: color.palette.buttonLight
  },
  collapseContainer: {
    width: '100%',
    padding: '2%',
    boxSizing: 'border-box'
  },
  paginationContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'right',
    marginTop: '10px',
    padding: responsive ? '2%' : '0%',
    boxSizing: 'border-box',
    marginBottom: responsive ? '50px' : '20px'
  },
  showingPagesText: {
    color: color.palette.gray
  },
  searchContainer: {
    p: '2%'
  },
  headingText: {
    marginLeft: '10px',
    fontWeight: 600
  },
  icon: {
    fontSize: responsive ? '20px' : '36px',
    color: 'black'
  }
};
