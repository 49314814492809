import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Text } from '../text';
import { UICheckBox } from '../checkbox';
import { defaultValues } from '../../constants';

export const UICheckBoxTitle = ({
  title,
  onCheck,
  values,
  touched,
  error,
  errorStyle,
  checkedValue,
  selected,
  disableSelection,
  variant = 'semiBold',
  defaultOption,
  margin = '15px 0px',
  ...rest
}) => {
  const [checked, setChecked] = React.useState('');

  useEffect(() => {
    setChecked(checkedValue);
  }, [checkedValue]);

  const styles = {
    mainBox: { margin: margin },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      height: '50px'
    },
    mb: { marginBottom: '15px' },
    flexBox: { display: 'flex', alignItems: 'center' },
    checkBoxItem: {
      marginRight: defaultValues.isResponsive ? '10px' : '80px',
      fontSize: defaultValues.isResponsive ? '12px' : defaultValues.isMedium ? '16px' : '22px'
    }
  };

  useEffect(() => {
    let selectedOption;
    if (selected === true) {
      selectedOption = defaultOption ? defaultOption : 'Yes';
    } else if (selected === false) {
      selectedOption = defaultOption ? defaultOption : 'No';
    }
    setChecked(selectedOption);
  }, [selected, values]);

  return (
    <Box sx={styles.mainBox} {...rest}>
      <Text variant={variant} style={styles.mb}>
        {title}
      </Text>
      <Box sx={styles.checkbox}>
        {values.map((item) => (
          <Box sx={styles.flexBox} key={item.title}>
            <UICheckBox
              checked={checked === item.title}
              onChange={() => {
                setChecked(item.title);
                onCheck(item.value);
              }}
              disabled={disableSelection}
            />
            <Text
              variant={defaultValues.isResponsive ? 'responsiveDesc' : 'desc'}
              style={styles.checkBoxItem}>
              {item.title}
            </Text>
          </Box>
        ))}
      </Box>
      {error && touched && (
        <Text style={errorStyle} variant="fieldError">
          {error}
        </Text>
      )}
    </Box>
  );
};
