import { Avatar, Box } from '@mui/material';
import { UIButton } from '../../../ui-kit';
import { defaultValues, getErrorMessage } from '../../../constants';
import { uploadProfilePicStyles } from './styles';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ProfilePic from '../../../assets/images/adminProPic.png';
import { useSelector } from 'react-redux';
import { getUserFullname } from '../../../services/redux/selectors';

export const UploadProfilePicture = ({
  onProfilePicChange,
  onProPicDelete,
  previewPicture,
  successMessage,
  isLoading,
  type
}) => {
  const fullname = useSelector(getUserFullname);
  const [selectedImage, setSelectedImage] = useState(null);

  const inputRef = useRef(null);

  useEffect(() => {
    if (!isLoading && successMessage) {
      setSelectedImage(null);
    }
  }, [isLoading]);

  useEffect(() => {
    if (previewPicture) {
      setSelectedImage(previewPicture);
    }
  }, [previewPicture]);

  const handlefileChange = (e) => {
    if (e.target.files.length > 0) {
      let file = e.target.files[0];

      if (file && file.size > 5000000) {
        toast.error(getErrorMessage('E-10037'));
        e.target.value = '';
      } else if (file && file.size < 5000000) {
        const image = URL.createObjectURL(file);
        onProfilePicChange(file);
        type !== defaultValues.listConstants.profile && setSelectedImage(image);
      }
    }
  };

  return (
    <Box sx={uploadProfilePicStyles.profileContainer}>
      <Avatar
        alt={fullname.toUpperCase() || ''}
        src={selectedImage ? selectedImage : ProfilePic}
        sx={uploadProfilePicStyles.profilePic}
      />

      <Box sx={uploadProfilePicStyles.profileChangeBtnContainer}>
        <Box sx={uploadProfilePicStyles.changeBtnSubContainer}>
          <input
            style={{ display: 'none' }}
            type="file"
            accept="image/jpeg"
            ref={inputRef}
            onChange={handlefileChange}
          />

          <UIButton
            style={uploadProfilePicStyles.changeBtn}
            title={selectedImage ? 'Change Picture' : 'Upload Picture'}
            onClick={() => {
              inputRef.current.click();
            }}
          />
        </Box>
        <UIButton
          style={uploadProfilePicStyles.deleteBtn}
          title={'Delete Picture'}
          onClick={() => {
            onProPicDelete();
            setSelectedImage(null);
            inputRef.current.value = '';
          }}
        />
      </Box>
    </Box>
  );
};
