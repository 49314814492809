/*
    Dashboard for both admin and super admin
*/

import React, { useEffect, useState } from 'react';
import { Screen, Text } from '../../../ui-kit';
import { Box } from '@mui/material';
import rightArrow from '../../../assets/images/Vector.png';
import { Styles } from './style';
import {
  BarCharts,
  PieChartWithDetails,
  SupplierEngagementChart,
  UpdatesCard
} from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminLoadingStatus,
  getActiveProjects,
  getAllProjects,
  getDashboardType,
  getJobsPosted,
  getLeadDistributionData,
  getLeadsCount,
  getNewSupplierCout,
  getProjectReceived,
  getProjectsCount,
  getSupplierCountData,
  getSupplierEngagementData,
  getUserType
} from '../../../services/redux/selectors';
import {
  dashboardDataRequest,
  getNotificationCountRequest,
  requestCustomerDashboard,
  requestProjectManagerDashboard,
  requestSupplierDashboard,
  setSelectedTab
} from '../../../services/redux/slices';
import { formatKeyName, trimText } from '../../../utils';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { useNavigate } from 'react-router-dom';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { ActiveProjectsPage } from './table/table';
import { color } from '../../../theme';
import { ActiveAdminProjectsPage } from './table/active-table';

export const AdminDashboardPage = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const isRes = defaultValues.isResponsive;

  const userRole = useSelector(getUserType);
  const isLoading = useSelector(adminLoadingStatus);
  const totalLeadsCount = useSelector(getLeadsCount);
  const totalProjectsCount = useSelector(getProjectsCount);
  const totalSuuplierCount = useSelector(getSupplierCountData);
  const totalNewSuppliersCount = useSelector(getNewSupplierCout);
  const totalLeadDistributionData = useSelector(getLeadDistributionData);
  const totalSupplierEngagementData = useSelector(getSupplierEngagementData);
  const optionType = useSelector(getDashboardType);
  const allProjects = useSelector(getAllProjects);
  const activeProjects = useSelector(getActiveProjects);
  const jobsReceived = useSelector(getJobsPosted);
  const projectReceived = useSelector(getProjectReceived);

  const [leadsCount, setLeadsCount] = useState([]);
  const [projectsCount, setProjectsCount] = useState([]);

  const [supplierEngagementData, setSupplierEngagementData] = useState([]);
  const [allProject, setAllProjects] = useState([]);
  const [activeProject, setActiveProjects] = useState([]);
  const [option, setOption] = useState('monthly');
  const [jobs, setJobs] = useState(null);
  const [receivedProjects, setReceivedProjects] = useState(null);

  useEffect(() => {
    if (
      userRole === defaultValues.userRole.admin ||
      userRole === defaultValues.userRole.superAdmin
    ) {
      dispatch(dashboardDataRequest({ type: 'monthly' }));
    }
    if (userRole === defaultValues.userRole.customer) {
      dispatch(requestCustomerDashboard({ type: 'monthly' }));
    }
    if (userRole === defaultValues.userRole.supplier) {
      dispatch(requestSupplierDashboard({ type: 'monthly' }));
    }
    if (userRole === defaultValues.userRole.projectManger) {
      dispatch(requestProjectManagerDashboard({ type: 'monthly' }));
    }
    dispatch(getNotificationCountRequest());
  }, [dispatch]);

  useEffect(() => {
    if (totalLeadsCount) {
      const formattedLeadsCount = Object.entries(totalLeadsCount).map(([key, value]) => ({
        key,
        value,
        name: formatKeyName(key, true)
      }));
      setLeadsCount(formattedLeadsCount);
    }

    if (totalProjectsCount) {
      const formattedProjectsCount = Object.entries(totalProjectsCount).map(([key, value]) => ({
        key,
        value,
        name: formatKeyName(key)
      }));
      setProjectsCount(formattedProjectsCount);
    }

    setSupplierEngagementData(totalSupplierEngagementData);

    if (allProjects) {
      setAllProjects(allProjects);
    }

    if (activeProjects) {
      setActiveProjects(activeProjects);
    }
    if (jobsReceived) {
      setJobs(jobsReceived);
    }
    if (projectReceived) {
      setReceivedProjects(projectReceived);
    }
  }, [
    totalLeadsCount,
    totalProjectsCount,
    totalLeadDistributionData,
    totalSupplierEngagementData,
    totalSuuplierCount,
    totalNewSuppliersCount,
    allProjects,
    activeProjects
  ]);

  useEffect(() => {
    if (optionType) {
      setOption(optionType);
    }
  }, [optionType]);

  const handleChange = (event) => {
    setOption(event.target.value);
    if (userRole === defaultValues.userRole.customer) {
      dispatch(requestCustomerDashboard({ type: event.target.value }));
    }
    if (userRole === defaultValues.userRole.supplier) {
      dispatch(requestSupplierDashboard({ type: event.target.value }));
    }
    if (userRole === defaultValues.userRole.projectManger) {
      dispatch(requestProjectManagerDashboard({ type: event.target.value }));
    }
  };

  return (
    <Screen showSideBar={true} currentPage="Dashboard">
      <LoadingScreen isLoading={isLoading} />

      <Box sx={Styles.container}>
        {userRole === defaultValues.userRole.admin ||
        userRole === defaultValues.userRole.superAdmin ? (
          <>
            <Box sx={Styles.cardsSectionContainer}>
              {projectsCount.map((item, index) => (
                <Box key={index} sx={Styles.cardContainer}>
                  <Box sx={Styles.cardSubContainer}>
                    <Box sx={Styles.cardSubContainerLeftSection}>
                      <Text variant={isRes ? 'body' : 'subTitle'} style={Styles.subtitle(index)}>
                        {item.value}
                      </Text>
                      <Text variant={isRes ? 'responsiveDesc' : 'medium'}>
                        {'This ' + trimText(option)}
                      </Text>
                    </Box>
                    <Box sx={Styles.cardSubContainerRightSection}>
                      <img
                        src={rightArrow}
                        style={Styles.iconStyle}
                        onClick={() => {
                          dispatch(setSelectedTab(2));
                          navigation(SCREEN_PATH.MANAGE_PROJECTS);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={Styles.cardSecondaryContainer(index)}>
                    <Text variant={isRes ? 'responsiveDesc' : 'medium'} style={Styles.cardTitle}>
                      {item.name}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box sx={Styles.chartSectionContainer}>
              <Box sx={Styles.chartLeftSectionContainer}>
                <PieChartWithDetails data={leadsCount} colors={Styles.pieChartColors} />
              </Box>
              <Box sx={Styles.chartRightSectionContainer}>
                <Text variant={isRes && 'responsiveText'} style={Styles.titleStyle}>
                  Supplier’s engagement
                </Text>
                <BarCharts data={supplierEngagementData} />
              </Box>
            </Box>
            <ActiveAdminProjectsPage />
          </>
        ) : (
          <Box sx={Styles.commonDB}>
            <Box sx={Styles.commonChart}>
              <Box sx={Styles.commonBar(receivedProjects, jobs)}>
                <SupplierEngagementChart
                  data={allProject && allProject}
                  option={option}
                  handleChange={handleChange}
                  userRole={userRole}
                />
              </Box>
              {receivedProjects && jobs && (
                <Box sx={Styles.updatesCardContainer}>
                  <Text variant={isRes ? 'small' : 'fileName'}>Updates</Text>
                  <UpdatesCard
                    data={jobsReceived}
                    msg={'New jobs posted, check them out'}
                    color={color.palette.lightBlue}
                    onClick={() => {
                      dispatch(setSelectedTab(4));
                      navigation(SCREEN_PATH.VIEW_JOBS);
                    }}
                  />
                  <UpdatesCard
                    data={projectReceived}
                    msg={'New projects received, this ' + trimText(option)}
                    color={color.palette.lightRed}
                    onClick={() => {
                      dispatch(setSelectedTab(3));
                      navigation(SCREEN_PATH.MANAGE_INVITATION);
                    }}
                  />
                </Box>
              )}
            </Box>
            <ActiveProjectsPage data={activeProject.length > 0 && activeProject} />
          </Box>
        )}
      </Box>
    </Screen>
  );
};
