import { useRef, useState, useEffect } from 'react';
import { Text } from '../../ui-kit';
import { Box } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { styles } from './style';
import { getUserRole, truncateText } from '../../utils';
import { SCREEN_PATH, defaultValues } from '../../constants';
import { SupplierDetailsCard } from '../supplier-detailsCard';
import { useNavigate } from 'react-router-dom';

export const MultiCardsContainer = ({ data, title, onInviteClick }) => {
  const navigation = useNavigate();
  const containerRef = useRef();
  const [showPrevScroll, setShowPrevScroll] = useState(false);
  const [showNextScroll, setShowNextScroll] = useState(false);

  const roleId = getUserRole(defaultValues.userRole.supplier);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        setShowPrevScroll(container.scrollLeft > 0);
        setShowNextScroll(container.scrollLeft + container.clientWidth < container.scrollWidth);
      };

      container.addEventListener('scroll', handleScroll);
      setShowNextScroll(container.scrollLeft + container.clientWidth < container.scrollWidth);

      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const cardWidth = defaultValues.isMdSmall ? 225 : defaultValues.isResponsive ? 272 : 320;
      const scrollAmount = direction === 'prev' ? -cardWidth : cardWidth;

      container.scrollTo({
        left: container.scrollLeft + scrollAmount,
        behavior: 'smooth'
      });
    }
  };
  return (
    <Box sx={styles.container}>
      <Text style={styles.title} variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>
        {title}
      </Text>
      <Box sx={styles.cardContainer}>
        <Box sx={styles.nextIcon}>
          {showPrevScroll && (
            <ArrowBackIosNewOutlinedIcon
              onClick={() => handleScroll('prev')}
              fontSize={defaultValues.isResponsive ? 'small' : 'medium'}
              color="action"
            />
          )}
        </Box>
        <Box ref={containerRef} sx={styles.cardsStyle}>
          {data.map((card, index) => (
            <SupplierDetailsCard
              key={index}
              title={` ${card.first_name}
            ${card.last_name}`}
              language={
                card.supplier_languages &&
                truncateText(
                  card.supplier_languages.map((item) => item.language).join(', '),
                  defaultValues.isMdSmall ? 15 : defaultValues.isResponsive ? 18 : 22
                )
              }
              skill={
                card.supplier_skills &&
                truncateText(
                  card.supplier_skills.map((item) => item.skill_title).join(','),
                  defaultValues.isMdSmall ? 15 : defaultValues.isResponsive ? 18 : 22
                )
              }
              timezone={card.timezone && card.timezone.timezone}
              currency={card.currency && card.currency.currency}
              rating={card.rating}
              onClick={() => onInviteClick(card)}
              onRightBtnClick={() =>
                navigation(`${SCREEN_PATH.VIEW_USER_PROFILE}?role=${roleId}&id=${card.id}`, {
                  state: {
                    type: defaultValues.listConstants.supplier
                  }
                })
              }
              btnDisable={card.is_invited}
              leftBtnTitle={card.is_invited ? 'Invited' : 'Invite supplier'}
            />
          ))}
        </Box>
        <Box sx={styles.nextIcon}>
          {showNextScroll && (
            <ArrowForwardIosOutlinedIcon
              onClick={() => handleScroll('next')}
              fontSize={defaultValues.isResponsive ? 'small' : 'medium'}
              color="action"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
