import React from 'react';
import { Box, Modal, Backdrop } from '@mui/material';
import { styles } from './style';
import { Text, UIButton } from '../../ui-kit';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import closeIcon from '../../assets/icons/close.png';
import { defaultValues } from '../../constants';
import Select from 'react-select';

const labelStyle = 'abbrev';
const timezones = { ...allTimezones };

export const ModalWithSelectOption = ({
  open,
  close,
  name,
  heading,
  title,
  disabled,
  onButtonClick,
  buttonTitle,
  isLoading,
  selectedValue,
  onBlur,
  touched,
  error
}) => {
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

  const handleChange = (selectedOption) => {
    const selectedTimezone = parseTimezone(selectedOption.value);
    selectedValue(selectedTimezone.label);
  };

  return (
    <div>
      <Modal open={open} onClose={close}>
        <Box sx={styles.container}>
          <img style={styles.closeButton} onClick={close} src={closeIcon} />
          <Box sx={styles.contentContainer}>
            <Box sx={styles.headingContainer}>
              <Text style={styles.heading}>{heading}</Text>
            </Box>
            <Box sx={styles.inputContainer}>
              <Text variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>{title}</Text>
              <Select
                name={name}
                placeholder="select timezone"
                styles={styles.input}
                options={options.map((option) => ({
                  value: option.value,
                  label: option.label
                }))}
                onChange={handleChange}
                onBlur={onBlur}
              />
              {touched && error && <Text variant="fieldError">{error}</Text>}
            </Box>

            <UIButton
              disabled={disabled}
              size="small"
              style={styles.button(disabled)}
              onClick={onButtonClick}
              title={buttonTitle}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Modal>

      <Backdrop open={open} onClick={close} />
    </div>
  );
};
