import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
const responsive = defaultValues.isResponsive;
export const popModalStyle = {
  container: (styles) => ({
    position: 'absolute',
    top: '50%',
    left: responsive ? '50%' : '75%',
    transform: responsive ? 'translate(-50%, -50%)' : 'translate(-70%, -50%)',
    minWidth: responsive ? '80%' : '70%',
    backgroundColor: color.primaryBackground,
    borderRadius: '5px',
    boxShadow: 24,
    maxWidth: '70%',
    width: 'fit-content',
    minHeight: responsive ? '500px' : 'none',
    border: 'none',
    padding: responsive ? '10px' : '4%',
    boxSizing: 'border-box',
    ...styles
  }),
  headingContainer: (style) => ({
    width: responsive ? '100%' : 'fit-content',
    ...style
  }),
  contentContainer: (position, customContentWidth, style) => ({
    width: customContentWidth,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: position ? 'start' : 'center',
    marginBottom: '5px',
    marginLeft: position && '8%',
    ...style
  }),
  heading: (style) => ({
    fontSize: responsive ? '20px' : '35px',
    textAlign: responsive ? 'left' : 'none',
    marginBottom: responsive ? '20px' : 'none',
    ...style
  }),
  closeButton: {
    maxWidth: '100%',
    maxHeight: responsive ? '40px' : '50px',
    backgroundColor: color.palette.primaryRed,
    borderRadius: '50%',
    position: 'absolute',
    top: '-25px',
    right: responsive ? '-20px' : '-25px',
    cursor: 'pointer'
  },
  diver: (dividerStyle, style) => ({
    minWidth: !responsive ? '85%' : dividerStyle ? '85%' : '60%',
    marginRight: '50px',
    backgroundColor: color.primaryBorder,
    marginTop: '10px',
    ...style
  }),
  childBox: (style) => ({
    margin: '0px',
    width: '100%',
    maxHeight: '60vh',
    overflowY: 'auto',
    ...style
  })
};
