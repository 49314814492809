import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
export const DMStyles = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    padding: '2%',
    boxSizing: 'border-box',
    marginBottom: '20px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground
  },
  navContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: res && '10px'
  },
  navText: {
    fontSize: res ? '14px' : '28px',
    fontWeight: 600,
    cursor: 'pointer',
    color: color.palette.linkBlue,
    textDecoration: 'underline'
  },
  navIdleText: {
    fontSize: res ? '20px' : '28px',
    fontWeight: 600,
    textAlign: 'center'
  },
  title: {
    fontSize: res ? '16px' : '28px',
    fontWeight: 600
  },
  icon: {
    fontSize: res ? '12px' : '20px',
    marginX: res ? '5px' : '10px',
    color: color.palette.linkBlue
  },
  uploadContainer: {
    display: 'flex',
    width: '100%',
    height: 'fit-content'
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexDirection: res ? 'column' : 'row',
    paddingRight: '2%'
  },
  uploadSuperContainer: {
    width: '100%',
    minHeight: '60vh',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5%',
    padding: '2%',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: `1px solid ${color.primaryBorder}`
  },
  sendBtn: {
    fontSize: res ? '12px' : '20px',
    height: 'fit-content',
    padding: res && '5px 10px'
  },
  childBox: {
    overflowY: 'none'
  },
  divider: {
    minWidth: '100%',
    marginRight: '0px'
  },
  uploadButton: {
    marginTop: '10px',
    width: res && '100%',
    fontSize: res ? '12px' : '20px'
  },
  pdfSuperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '30px'
  },
  pdfContainer: {
    minWidth: '30%',
    width: res ? 'calc(50% - 10px)' : '30%',
    marginTop: 0,
    height: res ? '200px' : '500px'
  },
  pdfSubContainer: {
    width: '100%',
    height: '100%'
  },
  docCont: {
    width: res ? '100%' : '400px',
    height: res ? '400px' : '500px',
    position: 'relative',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px'
  },
  downloadIcon: {
    position: 'absolute',
    top: '10px',
    left: res ? '10px' : '30px',
    width: res ? '30px' : '50px',
    height: res ? '30px' : '50px',
    cursor: 'pointer'
  },

  deleteIcon: {
    position: 'absolute',
    top: '10px',
    left: res ? '50px' : '90px',
    width: res ? '30px' : '50px',
    height: res ? '30px' : '50px',
    cursor: 'pointer'
  },
  altResText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontSize: '12px',
    textAlign: 'center'
  },
  altSpanText: {
    color: color.palette.linkBlue,
    textDecoration: 'underline'
  },
  popupContainer: { minHeight: '200px', width: '100%' },
  popupContentBox: {
    overflowY: md && 'scroll',
    overflowX: 'hidden'
  },
  popupHeadContainer: { width: '100%' },
  popupHead: { textAlign: 'left', marginBottom: res ? '0px' : '20px' },
  altText: { fontSize: res ? '10px' : '14px', wordWrap: 'break-word', margin: '0% 2%' }
};
