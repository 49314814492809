import { defaultValues } from '../../constants';
import { color } from '../../theme';
import { customStyles } from '../../theme';
const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;

export const chatStyles = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    padding: '2%',
    boxSizing: 'border-box',
    marginBottom: '20px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground
  },
  chatContainer: {
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    width: '100%',
    height: 'fit-content'
  },
  chatTopNav: {
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    width: '100%',
    height: '60px',
    borderBottom: `1px solid ${color.primaryBorder}`
  },
  chatHeading: {
    minWidth: res ? '100%' : md ? '300px' : '350px',
    backgroundColor: color.secondaryBackground,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px 0px 0px 0px',
    height: '100%',
    paddingRight: '4px',
    boxSizing: 'border-box'
  },
  chatTitleBox: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  chatText: {
    marginLeft: '10px',
    color: color.palette.primaryBlack,
    fontWeight: 600,
    fontSize: res ? '20px' : md ? '20px' : '24px'
  },
  chatPersonHeadCont: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: res ? '98%' : md ? 'calc(100% - 310px)' : 'calc(100% - 370px)',
    marginLeft: res ? 0 : md ? 0 : '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  autoCompCont: { display: 'flex', width: res ? '90%' : '100%' },
  autoComplete: {
    width: sm ? '150px' : res ? '180px' : md ? '220px' : '300px',
    '& fieldset': { border: 'none' },
    fontSize: '12px'
  },
  autoTextF: {
    fontSize: '12px'
  },
  optionsCard: {
    minWidth: res ? '100%' : 'fit-content'
  },
  memCardCont: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '7px',
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  memCard: {
    height: res ? '30px' : '40px'
  },
  chatPersonHeading: {
    fontSize: res ? '16px' : md ? '20px' : '24px',
    fontWeight: 600,
    color: color.palette.primaryBlack
  },
  memberNameCont: {
    overflow: 'auto',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    marginLeft: '10px'
  },
  unreadCount: { fontSize: '14px', color: color.palette.primaryWhite },
  unreadContainer: {
    width: '20px',
    height: '20px',
    backgroundColor: color.palette.primaryRed,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '7px'
  },
  chatSuperContainer: {
    width: '100%',
    minHeight: '75vh',
    height: '75vh',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: `1px solid ${color.primaryBorder}`
  },
  refreshIcon: {
    fontSize: res ? '20px' : '30px',
    color: 'black'
  },
  refreshStyle: {
    display: 'flex',
    border: 'none'
  },
  newBtn: {
    fontSize: '14px',
    padding: 0,
    margin: 0,
    height: res ? '30px' : '40px',
    width: res ? '40px' : '80px',
    marginLeft: '10px',
    boxSizing: 'content-box'
  },
  btnsCont: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px'
  },
  CSecondCont: {
    width: '100%',
    height: 'calc(100% - 60px)',
    display: 'flex'
  },
  CsecLeft: {
    width: res ? '100%' : md ? '300px' : '350px',
    height: '100%',
    backgroundColor: color.secondaryBackground,
    overflowY: 'auto'
  },
  CLeftSub: {
    width: '100%',
    height: 'fit-content'
  },
  CsecRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: res && '100%'
  },
  CSecChats: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: 'auto',
    width: res && '100%'
  },
  daytag: {
    textAlign: 'center',
    color: color.palette.gray,
    marginTop: '10px',
    fontSize: res ? '12px' : '16px'
  },

  msgCont: { width: '100%' },

  inputContainer: {
    width: '100%',
    padding: '0px 10px',
    boxSizing: 'border-box',
    marginBottom: '10px',
    display: 'flex',
    gap: '10px',
    alignItems: 'flex-end'
  },
  inputFsub: {
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'flex-end'
  },
  inputSub: {
    flex: 1
  },
  textFieldContainerStyle: {
    width: '100%',
    height: res && '40px',
    flex: 1
  },
  textField: {
    width: '100%',
    height: res && '100%',
    padding: 0,
    fontSize: res && '16px',
    margin: 0,
    '& fieldset': {
      border: 'none'
    },
    '& .MuiInputBase-root': {
      padding: res ? '2px 2px 2px 4px' : md ? '12px' : '16px',
      boxSizing: res && 'border-box',
      height: res && '100%'
    }
  },
  chip: {
    display: 'inline-block',
    backgroundColor: color.secondaryBackground,
    color: color.palette.primaryBlack,
    marginLeft: '10px'
  },
  attachIcon: {
    width: res ? '20px' : '35px',
    height: res ? '20px' : '35px',
    marginRight: '10px',
    marginBottom: '10px',
    cursor: 'pointer'
  },
  sendIcon: (disable) => ({
    width: res ? '20px' : '30px',
    height: res ? '20px' : '30px',
    cursor: 'pointer',
    pointerEvents: disable && 'none'
  }),
  sendCont: (disable) => ({
    width: res ? '40px' : '60px',
    height: res ? '40px' : '60px',
    backgroundColor: disable ? color.palette.lightGray : color.palette.primaryRed,
    border: `1px solid transparent`,
    borderRadius: '4px',
    ...customStyles.flexBox
  })
};
