import React, { useEffect } from 'react';
import { Screen } from '../../ui-kit';
import { RegistrationForm } from '../../components';
import { Box } from '@mui/material';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDP,
  clearProfilePic,
  editProfileRequest,
  profilePicChangeRequest,
  profilePicDeleteRequest,
  profileRequest,
  resetProfileMessages
} from '../../services/redux/slices';
import {
  getProfileData,
  getProfileErrorMessage,
  getProfileLoadingStatus,
  getProfileSuccessMessage,
  getUserType
} from '../../services/redux/selectors';
import { LoadingScreen } from '../../ui-kit/loading-screen';
import { toast } from 'react-toastify';
import { resetMessge } from '../../utils';

export const ProfilePage = () => {
  const style = {
    container: {
      width: '100%',
      padding: defaultValues.isResponsive ? 0 : 3,
      boxSizing: 'border-box'
    },
    regContainer: {
      padding: defaultValues.isResponsive ? '12px' : 3
    }
  };

  const dispatch = useDispatch();
  const profileData = useSelector(getProfileData);
  const successMessage = useSelector(getProfileSuccessMessage);
  const errorMessage = useSelector(getProfileErrorMessage);
  const isLoading = useSelector(getProfileLoadingStatus);
  const userRole = useSelector(getUserType);

  useEffect(() => {
    dispatch(profileRequest());
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetProfileMessages()));
  }, [successMessage, errorMessage]);

  const handleProPicChange = (file) => {
    dispatch(clearDP());
    dispatch(profilePicChangeRequest({ image: file }));
  };

  const handleProPicDelete = () => {
    dispatch(profilePicDeleteRequest({}));
    dispatch(clearProfilePic());
  };

  const handleEditProfile = (values) => {
    let payload = null;
    if (userRole === defaultValues.userRole.customer) {
      payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        currency: values.currency,
        country: values.country,
        timezoneId: values.timezoneId,
        address: values.locality,
        city: values.city,
        state: values.state,
        postal_code: values.postal_code,
        telephone_number: values.telephone_number,
        whatsapp_number: values.whatsapp_number,
        skype_id: values.skype_id
      };
    } else if (userRole === defaultValues.userRole.supplier) {
      payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        currency: values.currency,
        country: values.country,
        timezoneId: values.timezoneId,
        address: values.locality,
        city: values.city,
        state: values.state,
        postal_code: values.postal_code,
        skills: values.skills,
        languages: values.languages,
        telephone_number: values.telephone_number,
        whatsapp_number: values.whatsapp_number,
        skype_id: values.skype_id,
        availability_type: values.availability_type,
        availability_details: values.availability_details
      };
    } else {
      payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        telephone_number: values.telephone_number,
        whatsapp_number: values.whatsapp_number,
        skype_id: values.skype_id
      };
    }
    dispatch(editProfileRequest(payload));
  };

  return (
    <Screen showSideBar={true} currentPage="Manage Profile">
      <Box sx={style.container}>
        <LoadingScreen isLoading={isLoading} />
        <RegistrationForm
          emailDisabled={true}
          userType={userRole}
          data={profileData}
          propicChange={handleProPicChange}
          propicDelete={handleProPicDelete}
          languageTitle="Preferred language(s)"
          currencyTitle="Preferred currency"
          type="profile"
          onSubmit={handleEditProfile}
          isLoading={isLoading}
          containerStyles={style.regContainer}
          successMessage={successMessage}
        />
      </Box>
    </Screen>
  );
};
