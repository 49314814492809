import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

export const EbookStyles = {
  container: {
    width: '100%',
    backgroundColor: color.palette.primaryRed,
    borderRadius: '4px',
    padding: '5%',
    boxSizing: 'border-box',
    position: 'relative',
    marginBottom: defaultValues.isResponsive ? 3 : 10
  },
  heading: {
    color: color.palette.primaryWhite
  },
  desc: {
    color: color.palette.primaryWhite
  },
  button: {
    color: color.palette.primaryBlack,
    backgroundColor: color.palette.primaryWhite,
    fontWeight: 600,
    fontSize: defaultValues.isResponsive ? '14px' : '24px',
    marginTop: '10px'
  },

  primaryImage: {
    width: defaultValues.isResponsive ? '50px' : '180px',
    height: defaultValues.isResponsive ? '80px' : '230px',
    position: 'absolute',
    bottom: defaultValues.isResponsive ? '-15px' : '-30px',
    left: '77%'
  },
  secondaryImage: {
    width: defaultValues.isResponsive ? '50px' : '100px',
    height: defaultValues.isResponsive ? '60px' : '130px',
    position: 'absolute',
    bottom: defaultValues.isResponsive ? '-15px' : '-35px',
    left: '71%'
  },
  iconFive: {
    maxWidth: '100px',
    maxHeight: '100px',
    position: 'absolute',
    bottom: '3%',
    right: '0'
  }
};
