import { color } from '../../theme/color';
import { fonts } from '../../theme/fonts';

export const styles = {
  tableContainer: {
    minWidth: 650,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '5px'
  },
  headingCell: {
    fontSize: '22px',
    fontFamily: fonts.primary,
    backgroundColor: color.palette.buttonLight
  },
  maxWidth: { width: '400px' },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 }
  }
};
