import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { PopupModal, Text } from '../../ui-kit';
import { QuestionView } from '../question-view';
import { QuestionBox } from './components/questionBox';
import { AddQuestionStyle } from './style';
import { defaultValues } from '../../constants';

export const QuestionAdd = ({
  onChanged,
  onBlur,
  id,
  name,
  error,
  touched,
  editQuestionData,
  onUpdateQuestion
}) => {
  const [questionData, setQuestionData] = useState([]);
  const [editQuestion, setEditQuestion] = useState();
  const [editModelData, setEditModelData] = useState(null);

  useEffect(() => {
    if (editQuestionData && editQuestionData.length > 0) {
      const sortedData = editQuestionData.slice().sort((a, b) => a.order_number - b.order_number);
      if (JSON.stringify(questionData) !== JSON.stringify(sortedData)) {
        setQuestionData(sortedData);
      }
    }
  }, [editQuestionData]);

  const handleAddQuestion = (data) => {
    setEditQuestion();
    let addData;
    if (editQuestionData) {
      const qData = data;
      qData.order_number = questionData.length + 1;
      onChanged(qData);
    } else {
      if (data.order_number) {
        addData = questionData.map((item) =>
          item.order_number === data.order_number ? data : item
        );
      } else {
        addData = [
          ...questionData,
          {
            order_number: questionData.length + 1,
            ...data
          }
        ];

        addData = addData.map((item, i) => ({ ...item, order_number: i + 1 }));
      }
      setQuestionData(addData);
      onChanged(addData);
    }
  };

  const handleRemoveQuestion = (orderNo) => {
    let qData = questionData;
    qData = qData.filter((item) => item.order_number !== orderNo);
    qData = qData.map((item, i) => ({ ...item, order_number: i + 1 }));
    setQuestionData(qData);
    setEditQuestion();
  };

  return (
    <>
      <Box sx={AddQuestionStyle.mainBox}>
        <Text variant="semiBold" style={AddQuestionStyle.titleStyle}>
          Define Your Service: Add Your Questions
        </Text>
        {questionData &&
          questionData.map((item, index) => (
            <QuestionView
              key={index + item.question}
              data={item}
              onEditQuestion={(data) => {
                !editQuestionData ? setEditQuestion(data) : setEditModelData(data);
              }}
              editQuestion={editQuestion}
              handleAddQuestion={handleAddQuestion}
              handleRemoveQuestion={handleRemoveQuestion}
            />
          ))}
        <QuestionBox
          id={id}
          key="mainQuestion"
          name={name}
          addQuestion={handleAddQuestion}
          enableSubQuestion={true}
          removeQuestion={handleRemoveQuestion}
          onBlur={onBlur}
          error={error}
          touched={touched}
          questionMenu={defaultValues.questionTypes}
        />
      </Box>

      <PopupModal
        showModel={editModelData !== null}
        onClose={() => setEditModelData(null)}
        containerStyles={AddQuestionStyle.editContainer}>
        <Box sx={{ marginTop: '10px' }}>
          <QuestionBox
            id={id}
            key="editQuestion"
            name={name}
            addQuestion={handleAddQuestion}
            enableSubQuestion={true}
            editData={editModelData}
            showAction={true}
            onBlur={onBlur}
            error={error}
            touched={touched}
            styleBox={AddQuestionStyle.editQuestionNox}
            updateQuestion={(qData) => {
              onUpdateQuestion(qData);
              setEditModelData(null);
            }}
            questionMenu={defaultValues.questionTypes}
          />
        </Box>
      </PopupModal>
    </>
  );
};
