import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const res = defaultValues.isResponsive;

export const CalStyles = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    padding: '2%',
    boxSizing: 'border-box',
    marginBottom: '20px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground
  },
  navContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: res && '10px'
  },
  navText: {
    fontSize: res ? '14px' : '28px',
    fontWeight: 600,
    cursor: 'pointer',
    color: color.palette.linkBlue,
    textDecoration: 'underline'
  },
  navIdleText: {
    fontSize: res ? '20px' : '28px',
    fontWeight: 600,
    textAlign: 'center'
  },
  icon: {
    fontSize: res ? '12px' : '20px',
    marginX: res ? '5px' : '10px',
    color: color.palette.linkBlue
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    width: '100%',
    height: 'fit-content'
  },
  uploadSuperContainer: {
    width: '100%',
    minHeight: '60vh',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5%',
    padding: res ? '0%' : '2%',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: `1px solid ${color.primaryBorder}`
  },
  calenderLabel: {
    color: 'white',
    marginLeft: '5px',
    fontSize: res ? '14px' : '20px',
    cursor: 'pointer'
  },
  calenderRoot: {
    margin: res && '7px'
  },
  calenderContainer: { width: res ? '100%' : '60%' },
  shedulesContainer: (showScrollbar, taskList) => ({
    width: res ? '100%' : '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: taskList.length === 0 && 'center',
    alignItems: 'center',
    height: '60vh',
    overflowY: showScrollbar ? 'scroll' : 'hidden'
  }),
  shedulesSubContainer: {
    width: '100%',
    height: res ? 'fit-content' : 'none'
  },
  taskContainer: {
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: res ? 'left' : 'center',
    width: '100%'
  },
  indicator: (status) => {
    return {
      width: '10px',
      backgroundColor: status && status.bgColor,
      borderRadius: '5px 0px 0px 5px'
    };
  },

  taskCont: {
    width: '80%',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '0px 5px 5px 0px',
    paddingLeft: '10px',
    boxSizing: 'border-box',
    flex: res && 1
  },
  Tdesc: {
    color: color.palette.emailGray,
    wordWrap: 'break-word'
  },
  taskTitle: {
    wordWrap: 'break-word',
    cursor: 'pointer'
  },
  spanText: {
    textDecoration: 'underline'
  },
  dropdownIcon: {
    height: res ? '8px' : '12px',
    width: res ? '14px' : '20px',
    marginLeft: '10px',
    cursor: 'pointer'
  },
  nofoundContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
