import { defaultValues } from '../../../../constants';
import { palette } from '../../../../theme/palette';
const respo = defaultValues.isResponsive;

export const ManageAddServicesStyles = {
  container: {
    width: '100%',
    padding: '10px 15px',
    margin: !defaultValues.isMobile && '15px',
    minHeight: '100vh',
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: palette.primaryWhite,
    borderRadius: '8px'
  },
  formBody: {
    marginLeft: respo ? '0px' : '70px'
  },
  submitBtn: {
    width: defaultValues.isResponsive ? '100%' : '180px',
    marginBottom: '20px',
    fontSize: defaultValues.isResponsive && 12
  },
  editSubmitBtn: {
    width: defaultValues.isResponsive ? '100px' : '180px',
    marginBottom: '20px',
    fontSize: defaultValues.isResponsive && 12
  }
};
