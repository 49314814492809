import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  projectsList: [],
  projectStatus: [],
  project_status: null,
  quotation: '',
  quotationId: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  project_managers: [],
  project_details: null,
  taskLists: [],
  indicator: null,
  projectParams: {},
  project_documents: [],
  projectMembers: [],
  tasks: [],
  pm_params: {},
  task_schedules: [],
  taskDetails: null,
  comments: [],
  nextCommentsPage: null,
  taskComments: [],
  nextTaskCommentsPage: null,
  feedbacks: [],
  logs: []
};

const projectSlice = createSlice({
  initialState,
  name: 'project',
  reducers: {
    updateProjectParams(state, action) {
      state.projectParams = action.payload;
    },
    updatePmParams(state, action) {
      state.pm_params = action.payload;
    },
    clearTaskListAndSchedules(state) {
      state.schedules = [];
    },
    clearQuotation(state) {
      state.quotation = '';
    },
    projectRequest(state) {
      state.isLoading = true;
    },
    projectRequestSuccess(state, action) {
      state.isLoading = false;
      state.projectsList = action.payload.data.projects;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
    },
    projectRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.projectsList = [];
      state.currentPage = null;
      state.totalPage = null;
    },

    projectStatusRequest(state) {
      state.isLoading = true;
    },
    projectStatusRequestSuccess(state, action) {
      state.isLoading = false;
      state.projectStatus = action.payload.statuses;
    },
    projectstatusRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateProjectStatusRequest(state) {
      state.isLoading = true;
    },
    updateProjectStatusRequestSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    updateProjectstatusRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    sendQuotationRequest(state) {
      state.isLoading = true;
    },
    sendQuotationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.indicator = true;
    },
    sendQuotationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    viewQuotationRequest(state) {
      state.isLoading = true;
    },
    viewQuotationSuccess(state, action) {
      state.isLoading = false;
      state.quotation = action.payload.quotation_url;
      state.quotationId = action.payload.quotation_id;
    },
    viewQuotationFailure(state, action) {
      state.isLoading = false;
      state.quotation = null;
      state.errorMessage = action.payload.status_code;
    },
    acceptQuotationRequest(state) {
      state.isLoading = true;
    },
    acceptQuotationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    acceptQuotationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    rejectQuotationRequest(state) {
      state.isLoading = true;
    },
    rejectQuotationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    rejectQuotationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    resendQuotationRequest(state) {
      state.isLoading = true;
    },
    resendQuotationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.indicator = true;
    },
    resendQuotationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getStatusIdRequest(state) {
      state.isLoading = true;
    },
    getStatusIdSuccess(state, action) {
      state.isLoading = false;
      state.project_status = action.payload.project_status;
    },
    getStatusIdFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getProjectDetailsRequest(state) {
      state.isLoading = true;
    },
    getProjectDetailsSuccess(state, action) {
      state.isLoading = false;
      state.project_details = action.payload.data;
    },
    getProjectDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    resetProjectsMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
      state.indicator = null;
    },
    projectManagersRequest(state) {
      state.isLoading = true;
    },
    projectManagersRequestSuccess(state, action) {
      state.isLoading = false;
      state.project_managers = action.payload.data.project_managers;
      state.nextPage = action.payload.data.next_page;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
    },
    projectManagersRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.project_managers = [];
    },
    createProjectManagersRequest(state) {
      state.isLoading = true;
    },
    createProjectManagersRequestSuccess(state, action) {
      state.isLoading = false;
      state.project_managers = action.payload.data.project_managers;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.successMessage = action.payload.status_code;
    },
    createProjectManagersRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateProjectManagersRequest(state) {
      state.isLoading = true;
    },
    updateProjectManagersRequestSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.successMessage = action.payload.status_code;
      state.indicator = true;
    },
    updateProjectManagersRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    postJobsRequest(state) {
      state.isLoading = true;
    },
    postJobsSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.isLoading = false;
    },
    postJobsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    assignPMRequest(state) {
      state.isLoading = true;
    },
    assignPMSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.isLoading = false;
    },
    assignPMFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    removePMRequest(state) {
      state.isLoading = true;
    },
    removePMSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.project_details.project_manager = action.payload.data.project_manager;
      state.isLoading = false;
    },
    removePMFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    removeSupplierRequest(state) {
      state.isLoading = true;
    },
    removeSupplierSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.project_details.suppliers = action.payload.data.suppliers;
      state.isLoading = false;
    },
    removeSupplierFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    createTaskListRequest(state) {
      state.isLoading = true;
    },
    createTaskListSuccess(state, action) {
      state.isLoading = false;
      state.taskLists = action.payload.data.task_list;
      state.successMessage = action.payload.status_code;
    },
    createTaskListFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    taskListRequest(state) {
      state.isLoading = true;
    },
    taskListRequestSuccess(state, action) {
      state.isLoading = false;
      state.taskLists = action.payload.data.task_list;
    },
    taskListRequestFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getProjectDocumentRequest(state) {
      state.isLoading = true;
    },
    getProjectDocumentSuccess(state, action) {
      state.project_documents = action.payload.data.documents;
      state.isLoading = false;
    },
    getProjectDocumentFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    uploadProjectDocumentRequest(state) {
      state.isLoading = true;
    },
    uploadProjectDocumentSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.project_documents = action.payload.data.documents;
      state.isLoading = false;
    },
    uploadProjectDocumentFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    deleteProDocumentRequest(state) {
      state.isLoading = true;
    },
    deleteProjectDocumentSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.project_documents = action.payload.data.documents;
      state.isLoading = false;
    },
    deleteProjectDocumentFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getProjectMembers(state) {
      state.isLoading = true;
    },
    getProjectMembersSuccess(state, action) {
      state.isLoading = false;
      const list = [...action.payload.data.members];
      const temp = list.filter((item, index, self) => {
        return index === self.findIndex((s) => s.id === item.id);
      });
      state.projectMembers = temp;
    },
    getProjectMembersFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    createTaskRequest(state) {
      state.isLoading = true;
    },
    createTaskSuccess(state, action) {
      state.isLoading = false;
      state.tasks = action.payload.data.tasks;
      state.successMessage = action.payload.status_code;
    },
    createTaskFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getTaskRequest(state) {
      state.isLoading = true;
    },
    getTaskSuccess(state, action) {
      state.isLoading = false;
      state.tasks = action.payload.data.tasks;
    },
    getTaskFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getTaskDetailsRequest(state) {
      state.isLoading = true;
    },
    getTaskDetailsSuccess(state, action) {
      state.isLoading = false;
      state.taskDetails = action.payload.task_details;
    },
    getTaskDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateTaskListRequest(state) {
      state.isLoading = true;
    },
    updateTaskListSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.taskLists = action.payload.data.task_list;
    },
    updateTaskListFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateTaskListStatusRequest(state) {
      state.isLoading = true;
    },
    updateTaskListStatusSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.taskLists = action.payload.data.task_list;
    },
    updateTaskListStatusFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateTaskStatusRequest(state) {
      state.isLoading = true;
    },
    updateTaskStatusSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.tasks = action.payload.data.tasks;
    },
    updateTaskStatusFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateTaskRequest(state) {
      state.isLoading = true;
    },
    updateTaskSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.tasks = action.payload.data.tasks;
    },
    updateTaskFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getTaskSchedulesRequest(state) {
      state.isLoading = true;
    },
    getTaskSchedulesSuccess(state, action) {
      state.task_schedules = action.payload.data.schedules;
      state.isLoading = false;
    },
    getTaskSchedulesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.task_schedules = [];
    },
    addListCommentsRequest(state) {
      state.isLoading = true;
    },
    addListCommentsSuccess(state, action) {
      state.comments = action.payload.data.comments;
      state.successMessage = action.payload.status_code;
      state.isLoading = false;
      state.nextCommentsPage = action.payload.data.next_page;
    },
    addListCommentsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getListCommentsRequest(state) {
      state.isLoading = true;
    },
    getListCommentsSuccess(state, action) {
      state.comments = action.payload.data.comments;
      state.isLoading = false;
      state.nextCommentsPage = action.payload.data.next_page;
    },
    getListCommentsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.comments = [];
    },
    addTaskCommentsRequest(state) {
      state.isLoading = true;
    },
    addTaskCommentsSuccess(state, action) {
      state.taskComments = action.payload.data.comments;
      state.successMessage = action.payload.status_code;
      state.isLoading = false;
      state.nextTaskCommentsPage = action.payload.data.next_page;
    },
    addTaskCommentsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getTaskCommentsRequest(state) {
      state.isLoading = true;
    },
    getTaskCommentsSuccess(state, action) {
      state.taskComments = action.payload.data.comments;
      state.isLoading = false;
      state.nextTaskCommentsPage = action.payload.data.next_page;
    },
    getTaskCommentsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.taskComments = [];
    },
    sendRatingRequest(state) {
      state.isLoading = true;
    },
    sendRatingSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.isLoading = false;
    },
    sendRatingFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    editRatingRequest(state) {
      state.isLoading = true;
    },
    editRatingSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.isLoading = false;
    },
    editRatingFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getFeedbackRequest(state) {
      state.isLoading = true;
    },
    getFeedbackSuccess(state, action) {
      state.isLoading = false;
      state.feedbacks = action.payload.data.feedbacks;
    },
    getFeedbackFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.feedbacks = [];
    },
    sendSupplierFeedbackRequest(state) {
      state.isLoading = true;
    },
    sendSupplierFeedbackSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.isLoading = false;
    },
    sendSupplierFeedbackFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    sendCustomerFeedbackRequest(state) {
      state.isLoading = true;
    },
    sendCustomerFeedbackSuccess(state, action) {
      state.successMessage = action.payload.status_code;
      state.isLoading = false;
    },
    sendCustomerFeedbackFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getProjectsLogs(state) {
      state.isLoading = true;
    },
    projectLogsSuccess(state, action) {
      state.isLoading = false;
      state.logs = action.payload.data.logs;
    },
    projectLogsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.stat;
    },
    updateProjectTime(state, action) {
      const { id, event_time } = action.payload.data;
      const projectToUpdate = state.project_details.id === id;
      if (projectToUpdate) {
        state.project_details.event_time = event_time;
      }
    }
  }
});

export const {
  clearTaskListAndSchedules,
  projectRequest,
  projectRequestFailure,
  projectRequestSuccess,
  projectStatusRequest,
  projectStatusRequestSuccess,
  projectstatusRequestFailure,
  resetProjectsMessages,
  updateProjectStatusRequest,
  updateProjectStatusRequestSuccess,
  updateProjectstatusRequestFailure,
  sendQuotationRequest,
  sendQuotationSuccess,
  sendQuotationFailure,
  viewQuotationRequest,
  viewQuotationSuccess,
  viewQuotationFailure,
  acceptQuotationRequest,
  acceptQuotationSuccess,
  acceptQuotationFailure,
  rejectQuotationRequest,
  rejectQuotationSuccess,
  rejectQuotationFailure,
  resendQuotationRequest,
  resendQuotationSuccess,
  resendQuotationFailure,
  projectManagersRequest,
  projectManagersRequestSuccess,
  projectManagersRequestFailure,
  createProjectManagersRequest,
  createProjectManagersRequestSuccess,
  createProjectManagersRequestFailure,
  updateProjectManagersRequest,
  updateProjectManagersRequestSuccess,
  updateProjectManagersRequestFailure,
  getStatusIdRequest,
  getStatusIdSuccess,
  getStatusIdFailure,
  getProjectDetailsRequest,
  getProjectDetailsSuccess,
  getProjectDetailsFailure,
  postJobsRequest,
  postJobsSuccess,
  postJobsFailure,
  assignPMRequest,
  assignPMSuccess,
  assignPMFailure,
  removePMRequest,
  removePMSuccess,
  removePMFailure,
  removeSupplierRequest,
  removeSupplierSuccess,
  removeSupplierFailure,
  createTaskListRequest,
  createTaskListFailure,
  createTaskListSuccess,
  taskListRequest,
  taskListRequestSuccess,
  taskListRequestFailure,
  updateProjectParams,
  getProjectDocumentRequest,
  getProjectDocumentSuccess,
  getProjectDocumentFailure,
  uploadProjectDocumentRequest,
  uploadProjectDocumentSuccess,
  uploadProjectDocumentFailure,
  getProjectMembers,
  getProjectMembersSuccess,
  getProjectMembersFailure,
  createTaskFailure,
  createTaskRequest,
  createTaskSuccess,
  getTaskFailure,
  getTaskRequest,
  getTaskSuccess,
  updateTaskListRequest,
  updateTaskListSuccess,
  updateTaskListFailure,
  updateTaskListStatusFailure,
  updateTaskListStatusRequest,
  updateTaskListStatusSuccess,
  updateTaskStatusRequest,
  updateTaskStatusSuccess,
  updateTaskStatusFailure,
  updateTaskRequest,
  updateTaskSuccess,
  updateTaskFailure,
  getTaskSchedulesRequest,
  getTaskSchedulesSuccess,
  getTaskSchedulesFailure,
  getTaskDetailsRequest,
  getTaskDetailsSuccess,
  getTaskDetailsFailure,
  getListCommentsRequest,
  getListCommentsSuccess,
  getListCommentsFailure,
  addListCommentsRequest,
  addListCommentsSuccess,
  addListCommentsFailure,
  getTaskCommentsRequest,
  getTaskCommentsSuccess,
  getTaskCommentsFailure,
  addTaskCommentsRequest,
  addTaskCommentsSuccess,
  addTaskCommentsFailure,
  deleteProDocumentRequest,
  deleteProjectDocumentFailure,
  deleteProjectDocumentSuccess,
  sendRatingFailure,
  sendRatingRequest,
  sendRatingSuccess,
  editRatingFailure,
  editRatingRequest,
  editRatingSuccess,
  getFeedbackFailure,
  getFeedbackRequest,
  getFeedbackSuccess,
  sendSupplierFeedbackFailure,
  sendSupplierFeedbackRequest,
  sendSupplierFeedbackSuccess,
  sendCustomerFeedbackFailure,
  sendCustomerFeedbackRequest,
  sendCustomerFeedbackSuccess,
  clearQuotation,
  getProjectsLogs,
  projectLogsFailure,
  projectLogsSuccess,
  updateProjectTime
} = projectSlice.actions;

export default projectSlice.reducer;
