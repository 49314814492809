import { color } from '../../../theme/color';
import { defaultValues } from '../../../constants';
import { fonts } from '../../../theme/fonts';
import { customStyles } from '../../../theme';

const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const VPStyle = {
  mainBox: {
    width: '100%',
    padding: '15px 2%',
    boxSizing: 'border-box',
    backgroundColor: color.secondaryBackground
  },
  container: {
    width: '100%',
    height: 'fit-content',
    backgroundColor: color.palette.primaryBackground,
    padding: responsive ? '5%' : '2%',
    boxSizing: 'border-box',
    marginBottom: responsive && '20px'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    justifyContent: 'start',
    justifyItems: 'start',
    alignItems: 'start',
    height: responsive ? 'fit-content' : 'fit-content'
  },
  logoBox: {
    display: responsive ? 'flex' : 'block',
    justifyContent: 'center',
    margin: responsive ? '0px' : md ? '0px' : '0 20px',
    width: responsive ? '90%' : md ? '250px' : '300px',
    height: responsive ? '200px' : '300px',
    borderRadius: '100%',
    alignSelf: 'center',
    marginRight: responsive ? '0px' : md ? '0px' : '25px',
    marginTop: responsive && '20px'
  },
  logoSize: {
    width: responsive ? '200px' : md ? '230px' : '300px',
    height: responsive ? '200px' : md ? '230px' : '300px',
    borderRadius: '50%'
  },
  infoBox: {
    height: '100%',
    margin: responsive ? '10px' : md ? '10px' : '10px 10px 10px 50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    position: 'relative',
    flexGrow: 1
  },
  comName: {
    fontSize: responsive ? '25px' : md ? '27px' : 32,
    fontWeight: 'bold'
  },
  emailBox: {
    ...customStyles.flexBox,
    justifyContent: 'start',
    margin: '0px 0px 10px 0px'
  },
  mailIcon: {
    height: '30px',
    width: '30px',
    marginRight: '10px'
  },
  skype: { marginRight: '10px' },
  stackStyle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3px',
    alignItems: 'center',
    mt: '2px'
  },
  imgStyle: {
    objectFit: 'contain',
    width: responsive ? '16px' : '20px',
    height: responsive ? '16px' : '20px'
  },
  statusStyle: (status) => ({
    padding: responsive ? '4px 30px' : '10px 20px',
    boxSizing: 'border-box',
    backgroundColor: status ? color.palette.statusLightGreen : color.palette.statusLightRed,
    color: status ? color.palette.buttonGreen : color.palette.buttonRed,
    borderRadius: '4px',
    textAlign: 'center',
    maxWidth: '120px',
    minWidth: '60px',
    fontSize: responsive ? '15px' : '16px'
  }),
  activeButton: (status) => ({
    boxSizing: 'border-box',
    borderRadius: '4px',
    textAlign: 'center',
    color: color.palette.primaryWhite,
    backgroundColor: status ? color.palette.primaryRed : color.palette.buttonGreen,
    fontSize: responsive ? '12px' : md ? '14px' : '16px',
    cursor: 'pointer',
    minWidth: responsive ? '100px' : md ? '100px' : '123px',
    minHeight: responsive ? '5px' : '45px',
    height: responsive ? '30px' : 'auto',
    padding: responsive ? '10px 20px' : 0,
    marginLeft: 'auto',
    fontFamily: fonts.primary,
    '&:hover': {
      backgroundColor: status ? color.palette.primaryRed : color.palette.buttonGreen
    },
    position: !responsive && 'absolute',
    top: 0,
    right: 0
  }),
  notFoundBox: {
    width: '100%',
    height: '200px',
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  subTitle: { textAlign: 'center', fontSize: responsive ? '20px' : '25px' },
  listTile: {
    ...customStyles.flexBox,
    margin: '20px 0',
    width: '95%',
    justifyContent: 'space-between',
    padding: '15px',
    borderBottom: `1px solid ${color.palette.borderColor}`,
    paddingRight: '15px'
  },
  aProTitle: {
    fontSize: responsive ? '14px' : '20px'
  },
  viewBtn: {
    color: color.palette.skyBlue,
    cursor: 'pointer',
    minWidth: '100px',
    marginRight: '30px',
    mt: responsive ? '10px' : 0
  },
  chipsBox: {
    ...customStyles.flexBox,
    justifyContent: 'start',
    borderBottom: `1px solid ${color.palette.borderColor}`
  },
  rBorder: { borderRight: `1px solid ${color.palette.borderColor}` },
  bNone: { border: 'none' },
  tileBox: { ...customStyles.flexBox, width: '30%', justifyContent: 'start' },
  noText: { padding: '0px 50px 0 30px' },
  bBorder: {
    borderBottom: responsive ? `1px solid ${color.palette.borderColor}` : 'none'
  },
  resAddBox: { width: '100%', display: 'flex', flexDirection: 'column' },
  resBox: {
    padding: '15px 20px',
    background: color.palette.buttonLight,
    borderRadius: '8px',
    border: `1px solid ${color.palette.borderColor}`,
    mt: '15px'
  },
  resTitle: {
    ...customStyles.flexBox,
    justifyContent: 'space-between',
    alignItems: 'start'
  },
  w70: { width: '70%', fontSize: '16px' },
  topNav: {
    ...customStyles.flexBox,
    justifyContent: 'space-between',
    width: '100%',
    height: '20px'
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  contactSuper: {
    display: 'flex',
    flexDirection: md ? 'column' : 'row',
    width: '100%',
    gap: '20%',
    margin: '10px 0px'
  },
  emailText: {
    fontSize: md && '14px'
  },
  chatBtn: {
    width: 'fit-content',
    fontSize: responsive ? '12px' : md ? '14px' : '16px'
  },
  availablity: {
    border: `1px solid ${color.primaryBorder}`,
    minHeight: !defaultValues.isResponsive && '120px',
    p: 1,
    borderRadius: 1,
    mb: 2
  },
  availablitySub: { display: 'flex', p: 1 },
  padding: { p: 1 },
  font: { fontWeight: 600 }
};
