import { createSlice } from '@reduxjs/toolkit';
import { defaultValues } from '../../../constants';

const initialState = {
  isLoading: false,
  successMessage: null,
  noteSuccessMessage: null,
  errorMessage: null,
  companies: [],
  customers: [],
  notesData: null,
  comp_current_page: null,
  comp_total_page: null,
  indicator: null,
  currentTab: 1,
  findCustomerSearch: '',
  filters: {},
  detailsData: null,
  companyDetailsTab: defaultValues.companyDetailsTabs[0].value
};

const customerCompanySlice = createSlice({
  initialState,
  name: 'customer_company',
  reducers: {
    updateCustomerFilters(state, action) {
      state.filters = action.payload;
    },
    updateDetailsData(state, action) {
      state.detailsData = action.payload;
    },
    updateCompanyDetailsTab(state, action) {
      state.companyDetailsTab = action.payload;
    },
    getCustomerCompanyRequest(state) {
      state.isLoading = true;
    },
    getCustomerCompanySuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload.data.companies;
      state.comp_current_page = action.payload.data.current_page;
      state.comp_total_page = action.payload.data.total_page;
    },
    getCustomerCompanyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.companies = [];
      state.comp_current_page = null;
      state.comp_total_page = null;
    },
    pushCustomerCompanyRequest(state) {
      state.isLoading = true;
    },
    pushCustomerCompanySuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.comp_current_page = action.payload.data.current_page;
      state.comp_total_page = action.payload.data.total_page;
      state.indicator = true;
    },
    pushCustomerCompanyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCustomersRequest(state) {
      state.isLoading = true;
    },
    getCustomersSuccess(state, action) {
      state.isLoading = false;
      state.customers = action.payload.data;
    },
    getCustomersFailure(state, action) {
      state.isLoading = false;
      state.customers = [];
      state.errorMessage = action.payload.status_code;
    },
    pushCustomerRequest(state) {
      state.isLoading = true;
    },
    pushCustomerSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    pushCustomerFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCompanyNoteRequest(state) {
      state.isLoading = true;
    },
    getCompanyNoteSuccess(state, action) {
      state.isLoading = false;
      state.notesData = {
        notes: action.payload.notes
      };
    },
    getCompanyNoteFailure(state) {
      state.isLoading = false;
      state.notesData = null;
    },
    pushCompanyNoteRequest(state) {
      state.isLoading = true;
    },
    pushCompanyNoteSuccess(state, action) {
      state.isLoading = false;
      state.notesData = action.payload;
    },
    pushCompanyNoteFailure(state, action) {
      state.isLoading = false;
      state.notesData.status_code = action.payload.status_code;
    },
    clearCustomer(state) {
      state.customers = [];
      state.findCustomerSearch = '';
    },
    tabChange(state, action) {
      state.currentTab = action.payload;
    },
    findCustomerSearchChange(state, action) {
      state.findCustomerSearch = action.payload;
    },
    resetCustomerCompanyMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
      state.indicator = null;
    },
    resetCompanyNoteMessages(state) {
      if (state.notesData && state.notesData.status_code) {
        state.notesData.status_code = null;
      }
    }
  }
});

export const {
  updateDetailsData,
  updateCompanyDetailsTab,
  getCustomerCompanyRequest,
  getCustomerCompanySuccess,
  getCustomerCompanyFailure,
  pushCustomerCompanyRequest,
  pushCustomerCompanySuccess,
  pushCustomerCompanyFailure,
  getCustomersRequest,
  getCustomersSuccess,
  getCustomersFailure,
  pushCustomerRequest,
  pushCustomerSuccess,
  pushCustomerFailure,
  getCompanyNoteRequest,
  getCompanyNoteSuccess,
  getCompanyNoteFailure,
  pushCompanyNoteRequest,
  pushCompanyNoteSuccess,
  pushCompanyNoteFailure,
  clearCustomer,
  tabChange,
  findCustomerSearchChange,
  updateCustomerFilters,
  resetCustomerCompanyMessages,
  resetCompanyNoteMessages
} = customerCompanySlice.actions;

export default customerCompanySlice.reducer;
