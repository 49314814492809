import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const feedStyle = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    padding: '2%',
    boxSizing: 'border-box',
    marginBottom: '20px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground
  },
  navContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: res && '10px'
  },
  marBot: {
    marginBottom: '20px'
  },
  navText: {
    fontSize: res ? '14px' : '28px',
    fontWeight: 600,
    cursor: 'pointer',
    color: color.palette.linkBlue,
    textDecoration: 'underline'
  },
  navIdleText: {
    fontSize: res ? '20px' : '28px',
    fontWeight: 600,
    textAlign: 'center'
  },
  icon: {
    fontSize: res ? '12px' : '20px',
    marginX: res ? '5px' : '10px',
    color: color.palette.linkBlue
  },
  subCont: {
    width: '100%',
    padding: res ? '10px  0px' : '20px 0px',
    boxSizing: 'border-box'
  },
  titleCont: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: res ? '16px' : md ? '20px' : '32px',
    fontWeight: 600
  },
  feedBtn: {
    fontSize: res ? '12px' : '18px',
    marginRight: '10px',
    padding: 0,
    margin: 0,
    boxSizing: 'border-box'
  },
  textInp: {
    boxShadow: 0,
    width: '100%',
    backgroundColor: color.palette.textFieldRed,
    borderRadius: '8px',
    '& fieldset': { border: 'none' },
    '& .MuiInputBase-input': {
      fontSize: res ? '12px' : '20px'
    }
  },
  assignHeading: {
    fontSize: res ? '20px' : '32px',
    fontWeight: 600,
    marginBottom: 0
  },
  ratingHCont: { width: '100%' },
  ratingDivider: { width: '100%', marginRight: 0 },
  ratingStar: {
    '& .MuiRating-icon': {
      fontSize: res ? '25px' : '35px'
    },
    margin: '10px 0px'
  },
  ratingChildBox: {
    marginTop: '20px',
    height: res ? '100%' : 'fit-content',
    maxHeight: 'none'
  },
  ratingContBox: {
    height: res ? '60vh' : 'fit-content',
    minHeight: 'none',
    position: 'relative'
  },
  ratingContentBox: {
    justifyContent: res && 'none',
    height: res && '80%'
  },
  ratingBtnCont: {
    width: '100%',
    marginTop: '20px',
    position: res ? 'absolute' : 'static',
    bottom: 10,
    left: 0,
    padding: res && '0px 10px',
    boxSizing: 'border-box'
  },
  ratingBtn: {
    width: res && '100%'
  },
  ratingMCont: {
    width: '100%'
  },
  ratingMBox: {
    width: '100%',
    display: 'flex'
  },
  ratingMText: {
    width: res ? '40%' : '30%',
    margin: '10px 0px',
    fontSize: res ? '12px' : '20px',
    fontWeight: 600
  },
  feedCardCont: {
    width: '100%'
  }
};
