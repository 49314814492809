import React from 'react';
import { Facebook, LinkedIn } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { defaultValues } from '../../../constants/defaultValues';
import { Text } from '../../../ui-kit';
import { footerStyles } from './footerStyles';
import logo from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import tiktok from '../../../assets/images/tiktok.png';

export const Footer = () => {
  return (
    <Box sx={footerStyles.container}>
      <Box sx={footerStyles.subContainer(defaultValues.isResponsive)}>
        <Box sx={footerStyles.flex}>
          <Box sx={footerStyles.logoContainer(defaultValues.isResponsive)}>
            <img style={footerStyles.logo} alt="logo" src={logo} />
          </Box>

          <Box sx={footerStyles.desContainer}>
            <Text
              variant={defaultValues.isResponsive ? 'responsiveDesc' : 'desc'}
              style={footerStyles.descText}>
              {defaultValues.footer.desc}
            </Text>
          </Box>
        </Box>

        <Box sx={footerStyles.mainContainer(defaultValues.isResponsive)}>
          <Box>
            <Text variant="small" style={footerStyles.subHeading}>
              Quick Links
            </Text>
            {defaultValues.quickLinks.map((item, index) => (
              <Link to={item.url} key={index} style={{ textDecoration: 'none' }}>
                <Text
                  variant={defaultValues.isResponsive ? 'responsiveDesc' : 'small'}
                  style={footerStyles.linkTexts}>
                  {item.title}
                </Text>
              </Link>
            ))}
          </Box>
        </Box>
        <Box sx={footerStyles.flex}>
          <Text variant="small" style={footerStyles.subHeading}>
            Connect With Us
          </Text>

          <Stack sx={footerStyles.socialContainer} spacing={1} direction="row">
            <Link to={defaultValues.landingPageUrls.facebook} style={footerStyles.link}>
              <Facebook sx={footerStyles.icon} />
            </Link>
            <Link to={defaultValues.landingPageUrls.linkedIn} style={footerStyles.link}>
              <LinkedIn sx={footerStyles.icon} />
            </Link>
            <Link to={defaultValues.landingPageUrls.instagram} style={footerStyles.link}>
              <InstagramIcon sx={footerStyles.icon} />
            </Link>
            <Link to={defaultValues.landingPageUrls.twitter} style={footerStyles.link}>
              <XIcon sx={footerStyles.iconSecondary} />
            </Link>
            <Link to={defaultValues.landingPageUrls.tiktok} style={footerStyles.link}>
              <img src={tiktok} style={footerStyles.iconSecondary} alt="tiktok" />
            </Link>
          </Stack>
        </Box>
      </Box>

      <Box sx={footerStyles.labelContainer(defaultValues.isResponsive)}>
        <Box sx={footerStyles.laberLeft(defaultValues.isResponsive)}>
          {defaultValues.terms.map((term, index) => (
            <Link to={term.link} key={index} style={footerStyles.privacy}>
              <Text style={footerStyles.labelText(defaultValues.isResponsive)}>{term.title}</Text>
            </Link>
          ))}
        </Box>
        <Box sx={footerStyles.laberRight(defaultValues.isResponsive)}>
          <Text
            style={{
              ...footerStyles.labelText(defaultValues.isResponsive),
              ...footerStyles.copyRight
            }}>
            {defaultValues.copyright}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
