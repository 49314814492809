import { color } from '../../../theme/color';

export const style = {
  container: {
    height: '100%',
    width: '100%',
    boxShadow: 3,
    borderRadius: 1,
    display: 'flex',
    paddingBottom: '10px',
    boxSizing: 'border-box'
  },
  leftSection: { width: '50%', height: '100%' },
  textStyle: { pt: 1, pl: 2 },
  width: '100%',
  height: '80%',
  font: { size: 20, color: color.primaryText },

  rightSection: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  detailsContainer: {
    display: 'flex',
    width: '100%',
    height: '10%',
    m: 1,
    alignItems: 'center'
  },
  statusBar: (colors, index) => ({
    width: '20%',
    height: '100%',
    backgroundColor: colors[index % colors.length],
    mr: 2
  }),
  chartContainer: {
    width: '100%',
    height: '80%',
    display: 'flex',
    alignItems: 'center'
  }
};
