import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const btnColor = (currentColor) => {
  if (currentColor === 'maroon') {
    return color.palette.maroon;
  } else if (currentColor === 'green') {
    return color.palette.green;
  } else if (currentColor === 'skyblue') {
    return color.palette.skyBlue;
  } else if (currentColor === 'violet') {
    return color.palette.violet;
  } else if (currentColor === 'orange') {
    return color.palette.orange;
  } else if (currentColor === 'red') {
    return color.palette.primaryRed;
  } else if (currentColor === 'blue') {
    return color.palette.blue;
  } else if (currentColor === 'yellow') {
    return color.palette.yellow;
  }
};

export const LandingStyles = {
  primaryWhite: {
    color: color.palette.primaryWhite
  },
  primaryBlack: {
    color: color.palette.primaryBlack
  },
  primaryRed: {
    color: color.palette.primaryRed
  },
  heading2: {
    color: color.palette.primaryRed,
    lineHeight: 0.7,
    fontWeight: 550,
    fontSize: defaultValues.isResponsive ? '24px' : '56px'
  },
  description: {
    marginTop: '30px'
  },

  landingImagePrimary: {
    maxWidth: '400px',
    maxHeight: '400px',
    minWidth: '300px',
    minHeight: '300px'
  },

  container: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: color.palette.primaryWhite
  },
  sectionContainer: {
    width: '100%',
    padding: '0% 5%',
    boxSizing: 'border-box',
    backgroundColor: color.palette.primaryWhite
  },
  serviceContainer: {
    width: '100%',
    padding: '0% 5%',
    boxSizing: 'border-box',
    marginTop: defaultValues.isResponsive ? 3 : 8,
    position: 'relative'
  },
  secondaryContainer: {
    width: '100%',
    backgroundColor: color.palette.primaryBlack
  },
  introContainer: {
    width: '100%',
    position: 'relative',
    marginTop: '20px',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    backgroundColor: color.palette.primaryWhite
  },
  introSubContainer: {
    width: defaultValues.isResponsive ? '100%' : '50%'
  },
  signupContainer: {
    width: '100%',
    backgroundColor: color.palette.primaryBlack,
    marginTop: '40px',
    borderRadius: '4px',
    padding: '2%',
    boxSizing: 'border-box'
  },
  enquireNowContainer: {
    width: '100%',
    marginTop: '40px',
    borderRadius: '4px',
    padding: ' 2% 2%',
    backgroundColor: color.palette.primaryRed,
    boxSizing: 'border-box'
  },

  imageContainer: {
    width: defaultValues.isResponsive ? '100%' : '50%',
    display: 'flex',
    justifyContent: 'center'
  },

  liveDemonstrationBtn: {
    backgroundColor: color.palette.primaryRed,
    color: color.palette.primaryWhite,
    textTransform: 'none',
    fontSize: defaultValues.isResponsive ? '14px' : '24px',
    marginTop: 20
  },
  signUpButton: {
    backgroundColor: color.palette.primaryRed,
    textTransform: 'none',
    color: color.palette.primaryWhite,
    fontSize: defaultValues.isResponsive ? '14px' : '24px',
    marginTop: 20,
    padding: '4px 20px',
    boxSizing: 'border-box'
  },
  enqireButton: {
    backgroundColor: color.palette.primaryWhite,
    color: color.palette.primaryBlack,
    textTransform: 'none',
    fontWeight: 700,
    fontSize: defaultValues.isResponsive ? '14px' : '24px',
    marginTop: 20,
    padding: '4px 20px',
    boxSizing: 'border-box'
  },
  iconPartOne: {
    width: '60px',
    height: '130px',
    position: 'absolute',
    left: '-5.7%',
    top: '35%'
  },
  iconPartTwo: {
    maxWidth: '100%',
    height: '80px',
    position: 'absolute',
    top: '10%',
    left: '0px'
  },
  iconPartThree: {
    maxWidth: '100%',
    height: '100px',
    position: 'absolute',
    bottom: '20%',
    right: 0
  },
  vector2: {
    position: 'absolute',
    top: '-18px',
    right: '-20px',
    height: '90px',
    width: '43px'
  },

  buttonColor: (colorName) => ({
    backgroundColor: btnColor(colorName),
    color: color.palette.primaryWhite,
    fontSize: defaultValues.isResponsive ? '16px' : '24px',
    padding: '4px 10px'
  }),
  findOutContainer: { width: '100%', position: 'relative' },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: responsive ? '90%' : md ? '80%' : '60%',
    height: 'fit-content',
    boxShadow: 24,
    p: defaultValues.isResponsive ? 2 : 4,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: color.palette.primaryBlack
  },
  SCardCont: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px'
  },
  sCard: {
    width: defaultValues.isResponsive ? '100%' : 'calc(50% - 20px)'
  }
};
