import { Box } from '@mui/material';
import { serviceCardStyles } from './serviceCardStyles';
import { Text, UIButton } from '../../../ui-kit';
import { defaultValues } from '../../../constants';
import { Link } from 'react-router-dom';

export const ServiceCard = (props) => {
  const { icon, buttonStyle, heading, description, url } = props;

  return (
    <Box sx={serviceCardStyles.container}>
      <Box sx={serviceCardStyles.headerContainer}>
        <Box sx={serviceCardStyles.iconContainer}>
          <img style={serviceCardStyles.icon} src={icon} />
        </Box>
        <Box sx={serviceCardStyles.textContainer}>
          <Text variant={defaultValues.isResponsive ? 'semiBold' : 'secondaryHeader'}>
            {heading}
          </Text>
        </Box>
      </Box>
      <Text variant={defaultValues.isResponsive ? 'responsiveDesc' : 'body'}>{description}</Text>
      {
        <Box sx={serviceCardStyles.buttonContainer}>
          <Link to={url}>
            <UIButton title="Read more" style={buttonStyle} />
          </Link>
        </Box>
      }
    </Box>
  );
};
