import { all } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { settingsSaga } from './settingsSaga';
import { adminSaga } from './adminSaga';
import { projectSaga } from './projectSaga';
import { serviceSaga } from './serviceSaga';
import { customerCompanySaga } from './customerCompanySaga';
import { profileSaga } from './profileSaga';
import { supplierSaga } from './supplierSaga';
import { jobSaga } from './jobSaga';
import { chatSaga } from './chatSaga';

const root = function* root() {
  yield all([
    authSaga(),
    settingsSaga(),
    adminSaga(),
    projectSaga(),
    serviceSaga(),
    customerCompanySaga(),
    profileSaga(),
    supplierSaga(),
    jobSaga(),
    chatSaga()
  ]);
};

export default root;
