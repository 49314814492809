import { useEffect, useState } from 'react';
import { Inbox } from '../../components';
import { defaultValues, getErrorMessage } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPersonelMessages,
  clearPersonelThreads,
  conversationRequest,
  getMessagesRequest,
  getThreadsRequest,
  resetChatMessages,
  unreadStatusRequest
} from '../../services/redux/slices';
import {
  chatLoadingStatus,
  getChatErrorMessage,
  getChatSuccessMessage,
  getPersonelChatMessages,
  getPersonelChatNextpage,
  getPersonelChatThreads,
  getPersonelMsgCurrPage,
  getPersonelMsgNextPage
} from '../../services/redux/selectors';
import { Box } from '@mui/material';
import { Screen, Text } from '../../ui-kit';
import { LoadingScreen } from '../../ui-kit/loading-screen';
import { resetMessge } from '../../utils';
import { color } from '../../theme';
import { useLocation } from 'react-router-dom';

const styles = {
  nofoundContainer: {
    height: '70vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    padding: '2%',
    boxSizing: 'border-box',
    marginBottom: '20px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground
  }
};

export const PersonalChatPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currMsgPage = useSelector(getPersonelMsgCurrPage);
  const nextMsgPage = useSelector(getPersonelMsgNextPage);
  const chatNxtPage = useSelector(getPersonelChatNextpage);
  const threads = useSelector(getPersonelChatThreads);
  const allMessages = useSelector(getPersonelChatMessages);
  const chatSuccessMessage = useSelector(getChatSuccessMessage);
  const chatErrorMessage = useSelector(getChatErrorMessage);

  const isLoading = useSelector(chatLoadingStatus);

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [threadList, setThreadList] = useState([]);
  const [currThread, setCurrThread] = useState(threads && threads[0]);

  const [isCall, setIscall] = useState(true);
  useEffect(() => {
    dispatch(clearPersonelMessages());

    return () => {
      dispatch(clearPersonelMessages());
      dispatch(clearPersonelThreads());
    };
  }, []);

  useEffect(() => {
    handleThreads();
  }, [location.state]);

  useEffect(() => {
    if (chatErrorMessage) {
      resetMessge(() => dispatch(resetChatMessages()));
    }
  }, [chatErrorMessage]);

  useEffect(() => {
    if (threads && threads.length > 0) {
      setThreadList(threads);
    }
  }, [threads]);

  useEffect(() => {
    if (chatNxtPage && chatNxtPage !== -1) {
      handleThreads(chatNxtPage);
    }
    if (chatNxtPage !== null && chatNxtPage === -1) {
      if (!currThread) {
        setCurrThread(threads[0]);
      } else {
        const Th = threads.find((item) => item.thread_id === currThread.thread_id);
        setCurrThread(Th);
      }
      if (location && location.state) {
        const sortedThreads = [...threads].sort((a, b) => {
          if (a.user.id === location.state.oppUserId) return -1;
          if (b.user.id === location.state.oppUserId) return 1;
          return 0;
        });
        setThreadList(sortedThreads);
        if (isCall) {
          setCurrThread(sortedThreads && sortedThreads[0]);
          return handleGetMessages(sortedThreads && sortedThreads[0], 1);
        }
      }

      handleGetMessages();
    }
  }, [chatNxtPage, location.state, threads]);

  useEffect(() => {
    if (chatSuccessMessage === 'S-10113') {
      dispatch(clearPersonelThreads());
      handleThreads();
    }
  }, [chatSuccessMessage]);

  useEffect(() => {
    if (currThread && currThread.unread_messages) {
      setTimeout(() => {
        dispatch(
          unreadStatusRequest({
            thread_id: currThread.thread_id
          })
        );
      }, 5000);
    }
  }, [currThread]);

  const handleThreads = (page) => {
    let payload = { page: page || 1 };
    if (page !== -1) {
      dispatch(getThreadsRequest(payload));
    }
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleGetMessages = (item, page) => {
    let payload = {
      thread_id:
        (item && item.thread_id) ||
        (currThread && currThread.thread_id) ||
        (threads && threads.length > 0 && threads[0].thread_id),
      page: page || currMsgPage
    };
    if (page !== -1) {
      dispatch(getMessagesRequest(payload));
    }
  };

  const handleSendMessage = (thread_id) => {
    let payload = {
      thread_id: thread_id || (threads && threads.length > 0 && threads[0].thread_id)
    };
    if (message) {
      payload = { ...payload, message: message };
    }
    if (file) {
      payload = { ...payload, attachment: file };
    }
    dispatch(conversationRequest(payload));
    setMessage('');
    setFile(null);
  };

  const handleMessageChange = (val) => {
    setMessage(val);
  };

  const handleThreadChange = (item) => {
    setCurrThread(item);
    dispatch(clearPersonelMessages());
    handleGetMessages(item, 1);
    setFile(null);
    setMessage('');
  };

  const handleFileDelete = () => {
    setFile(null);
  };

  const handleRefresh = () => {
    setIscall(false);
    dispatch(clearPersonelThreads());
    handleThreads();
    dispatch(clearPersonelMessages());
    handleGetMessages(currThread, 1);
  };

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive && true}
      currentPage="Chat">
      <LoadingScreen isLoading={isLoading} />
      <Box sx={styles.container}>
        <Box sx={styles.contentContainer}>
          {threadList && threadList.length > 0 ? (
            <Inbox
              handleFileChange={handleFileChange}
              file={file}
              handleSendMessage={handleSendMessage}
              message={message}
              handleMessageChange={handleMessageChange}
              threads={threadList}
              messages={allMessages}
              handleThreadChange={handleThreadChange}
              handleFileDelete={handleFileDelete}
              handleGetMessages={() => handleGetMessages(currThread, nextMsgPage)}
              currThread={currThread}
              onRefresh={handleRefresh}
              type={defaultValues.listConstants.personel_chat}
            />
          ) : (
            <Box sx={styles.nofoundContainer}>
              <Text variant="noData">{getErrorMessage('E-10123')}</Text>
            </Box>
          )}
        </Box>
      </Box>
    </Screen>
  );
};
