import { styles } from './style';
import { useEffect, useState } from 'react';
import { ConvertedTime, resetMessge } from '../../../utils';
import { Box, Divider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Screen, Text, UIBackButton, UIButton } from '../../../ui-kit';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType, userInfo } from '../../../services/redux/selectors';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import {
  getJobErrorMessage,
  getJobLoading,
  getJobSuccessMessage
} from '../../../services/redux/selectors/jobSelector';
import { applyJobRequest, resetJobsMessages } from '../../../services/redux/slices/jobsSlice';
import { toast } from 'react-toastify';
import { CustomModal } from '../../../components';

export const JobDetails = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const userRole = useSelector(getUserType);
  const isLoading = useSelector(getJobLoading);
  const successMessage = useSelector(getJobSuccessMessage);
  const errorMessage = useSelector(getJobErrorMessage);
  const userData = useSelector(userInfo);

  const [data, setData] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [userTimezone, setUserTimezone] = useState(null);

  // const formattedStartDate = getFormatDate(data.start_date, 'DD-MM-YYYY hh:mm A');
  // const formattedEndDate = getFormatDate(data.end_date, 'DD-MM-YYYY hh:mm A');

  useEffect(() => {
    if (location && location.state.data) {
      setData(location.state.data);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setUserTimezone(userData.timezone);
    }
  }, [userData]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      setButtonDisabled(true);
      handleClose();
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetJobsMessages()));
  }, [successMessage, errorMessage]);

  const handleApplyJob = () => {
    dispatch(applyJobRequest({ job_id: data.job_id }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Screen showSideBar={true} currentPage={'View jobs'}>
      <Box sx={styles.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={styles.subContainer}>
          <UIBackButton onClick={() => navigation(-1)} />
          <Box sx={styles.cardContainer}>
            <Text
              variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
              style={styles.title}>
              Project details
            </Text>
            <Divider sx={styles.divider} />
            <Text
              variant={defaultValues.isResponsive ? 'medium' : 'tableTitle'}
              style={styles.title}>
              {data.job_title}
            </Text>
            <Box sx={styles.contentContainer}>
              <Box sx={styles.cardRow}>
                <Text
                  variant={defaultValues.isResponsive ? 'responsiveText' : 'tableTitle'}
                  style={styles.font}>
                  {data.project_name}
                </Text>
                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.fontColor}>
                  {data.job_id}
                </Text>
              </Box>
              <Box sx={styles.cardRow}>
                <Text
                  variant={defaultValues.isResponsive ? 'responsiveText' : 'tableTitle'}
                  style={styles.font}>
                  Service required
                </Text>
                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.fontColor}>
                  {data.service && data.service.title}
                </Text>
              </Box>
              <Box sx={styles.cardRow}>
                <Text
                  variant={defaultValues.isResponsive ? 'responsiveText' : 'tableTitle'}
                  style={styles.font}>
                  Timeline
                </Text>
                <Box sx={styles.timelineCont}>
                  <ConvertedTime
                    time={data.start_date}
                    originalTimeZoneName={data.timezone}
                    targetTimeZoneName={
                      userRole === defaultValues.userRole.supplier
                        ? userTimezone
                        : defaultValues.uKTimeZone
                    }
                    style={styles.textClr}
                  />
                  <Box sx={styles.to}>
                    <Text style={styles.textClr}>to</Text>
                  </Box>
                  <ConvertedTime
                    time={data.end_date}
                    originalTimeZoneName={data.timezone}
                    targetTimeZoneName={
                      userRole === defaultValues.userRole.supplier
                        ? userTimezone
                        : defaultValues.uKTimeZone
                    }
                    style={styles.textClr}
                  />
                </Box>
              </Box>
            </Box>
            <Text
              variant={defaultValues.isResponsive ? 'responsiveText' : 'tableTitle'}
              style={styles.desc}>
              Job description
            </Text>{' '}
            <Text
              variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
              style={styles.fontColor}>
              {data.job_description}
            </Text>
          </Box>

          <Box sx={styles.cardContainer}>
            <Text variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}>Requirements</Text>
            <Divider sx={styles.divider} />
            <Box sx={styles.margin}>
              <Box sx={styles.requirmentCard}>
                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.font}>
                  Event duration
                </Text>
                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.details}>
                  {data &&
                    data.event_duration &&
                    `${data.event_duration} ${data.event_duration === 1 ? 'hour' : 'hours'}`}
                </Text>
              </Box>

              <Box sx={styles.requirmentCard}>
                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.font}>
                  Spoken languages -
                </Text>

                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.details}>
                  {data.spoken_languages &&
                    data.spoken_languages.map((item) => item.language).join(', ')}
                </Text>
              </Box>

              <Box sx={styles.requirmentCard}>
                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.font}>
                  Captioning languages -
                </Text>

                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.details}>
                  {data.captioning_languages &&
                    data.captioning_languages.map((item) => item.language).join(', ')}
                </Text>
              </Box>

              <Box sx={styles.requirmentCard}>
                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.font}>
                  Translation required -
                </Text>
                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.details}>
                  {data.translation_required === 1
                    ? 'Yes'
                    : data.translation_required === 2
                      ? 'No'
                      : 'Not applicable'}
                </Text>
              </Box>
              <Box sx={styles.requirmentCard}>
                <Text
                  variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                  style={styles.font}>
                  Timezone -
                </Text>
                {userRole === defaultValues.userRole.customer ? (
                  <Text
                    variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                    style={styles.details}>
                    {data.timezone}
                  </Text>
                ) : userRole === defaultValues.userRole.supplier ? (
                  <Text
                    variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                    style={styles.details}>
                    {userTimezone}
                  </Text>
                ) : (
                  <Text
                    variant={defaultValues.isResponsive ? 'resDesc' : 'small'}
                    style={styles.details}>
                    (GMT+1:00) Edinburgh, London (BST)
                  </Text>
                )}
              </Box>

              <Text
                style={styles.tLink}
                onClick={() => window.open('https://www.worldtimebuddy.com/', '_blank')}>
                Check event time in your timezone
              </Text>
            </Box>
          </Box>

          {userRole === defaultValues.userRole.supplier && (
            <Box sx={styles.btnContainer}>
              <UIButton
                disabled={data.hasApplied || buttonDisabled}
                title={'Apply'}
                sx={styles.btnStyle}
                onClick={() => setOpen(true)}
              />
            </Box>
          )}
        </Box>

        <CustomModal
          title={defaultValues.applyJobModal.title}
          message={defaultValues.applyJobModal.message}
          open={open}
          btnType={defaultValues.applyJobModal.btn}
          onClose={handleClose}
          onConfirm={handleApplyJob}
          onCancel={handleClose}
          cancelStyle={styles.modalCancel}
          confirmStyle={styles.apply}
          modalType={defaultValues.listConstants.jobs}
          isLoading={isLoading}
        />
      </Box>
    </Screen>
  );
};
