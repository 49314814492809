import React from 'react';
import { Box, FormControl, InputLabel, Select } from '@mui/material';
import { Text } from '../text';
import { color } from '../../theme/color';
import { defaultValues } from '../../constants';
const responsive = defaultValues.isResponsive;
export const SelectWithTitle = ({
  title,
  name,
  value,
  onChange,
  error,
  onBlur,
  required = false,
  multiple = false,
  placeholder = '',
  titleVariant = responsive ? 'medium' : 'body',
  size = responsive ? 'small' : 'medium',
  containerStyle,
  titleStyle,
  errorStyle,
  inputStyle,
  formControlStyle,
  disabled,
  children,
  touched,
  ...rest
}) => {
  const responsive = defaultValues.isResponsive;
  const styles = {
    inputContainer: {
      width: '100%',
      marginTop: '15px',
      ...containerStyle
    },
    title: {
      marginBottom: '10px',
      ...titleStyle
    },
    input: {
      boxShadow: 0,
      backgroundColor: color.palette.textFieldRed,
      border: 'none',
      borderRadius: '5px',
      '& fieldset': { border: 'none' },
      ...inputStyle
    },
    label: {
      fontSize: responsive ? '14px' : '20px'
    },
    errorField: {
      color: color.palette.primaryRed,
      marginTop: '3px',
      fontSize: responsive ? '11px' : '15px',
      ...errorStyle
    },
    asterisk: {
      color: color.palette.red
    },
    inputSubformContainer: {
      width: '100%',
      ...formControlStyle
    },
    optionsContainer: {
      height: '300px',
      overflowY: 'scroll'
    }
  };

  return (
    <Box sx={styles.inputContainer}>
      <Text variant={titleVariant} style={styles.title}>
        {title} {required && <span style={styles.asterisk}>*</span>}
      </Text>

      <FormControl
        sx={{
          ...styles.inputSubformContainer
        }}
        size={size}>
        <InputLabel sx={styles.label} id={name}>
          {placeholder}
        </InputLabel>
        <Select
          id={name}
          label={placeholder}
          name={name}
          sx={styles.input}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          required={required}
          disabled={disabled}
          multiple={multiple}
          {...rest}>
          {children}
        </Select>
      </FormControl>

      {touched && error && <Text style={styles.errorField}>{error}</Text>}
    </Box>
  );
};
