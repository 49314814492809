import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

export const Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${color.primaryBorder}`,
    boxSizing: 'border-box',
    p: defaultValues.isResponsive ? '2px' : 1,
    borderRadius: 1,
    m: defaultValues.isResponsive ? '0px' : '2px',
    backgroundColor: color.secondaryBackground,
    cursor: 'pointer',
    minWidth: 'fit-content'
  },
  imgContainer: {
    width: defaultValues.isResponsive ? '15px' : '25px',
    height: defaultValues.isResponsive ? '15px' : '25px',
    borderRadius: '50%',
    marginRight: '10px'
  },
  imgStyle: {
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  },
  nameContainer: {
    height: defaultValues.isResponsive ? '100%' : 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    whiteSpace: 'nowrap'
  },
  textStyle: {
    color: color.palette.primaryBlack,
    fontSize: defaultValues.isResponsive ? '12px' : '18px'
  },
  closeIcon: {
    fontSize: defaultValues.isResponsive ? '12px' : '18px'
  }
};
