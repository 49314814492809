import { createSelector } from 'reselect';

export const getSupplierSuccessMessage = createSelector(
  [(state) => state.supplier.successMessage],
  (successMessage) => successMessage
);

export const getSupplierErrorMessage = createSelector(
  [(state) => state.supplier.errorMessage],
  (errorMessage) => errorMessage
);

export const supplierLoadingStatus = createSelector(
  [(state) => state.supplier.isLoading],
  (loadingStatus) => loadingStatus
);

export const getSupplierCurrentPg = createSelector(
  [(state) => state.supplier.current_page],
  (current_page) => current_page
);

export const getSupplierTotalPg = createSelector(
  [(state) => state.supplier.total_page],
  (total_page) => total_page
);

export const getSuppliersList = createSelector(
  [(state) => state.supplier.supplierList],
  (supplier) => supplier
);

export const getSuggestedSuppliers = createSelector(
  [(state) => state.supplier.suggestedSuppliers],
  (suggestedSuppliers) => suggestedSuppliers
);

export const getExixstingSuppliers = createSelector(
  [(state) => state.supplier.existingSuppliers],
  (existingSuppliers) => existingSuppliers
);

export const getProjectInvitations = createSelector(
  [(state) => state.supplier.projectInvitation],
  (projectInvitation) => projectInvitation
);

export const getSupplierIndicator = createSelector(
  [(state) => state.supplier.indicator],
  (indicator) => indicator
);

export const getSupplierParams = createSelector(
  [(state) => state.supplier.supplierParams],
  (supplierParams) => supplierParams
);
