import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';
import { fonts } from '../../../theme/fonts';

const responsive = defaultValues.isResponsive;

export const style = {
  container: {
    width: '100%',
    padding: '2%',
    boxSizing: 'border-box',
    backgroundColor: color.secondaryBackground
  },
  navContainer: {
    borderBottom: `1px solid ${color.primaryBorder}`
  },
  tabIndicator: {
    backgroundColor: color.palette.primaryRed,
    height: '3px'
  },
  tab: {
    color: color.palette.primaryBlack,
    fontFamily: fonts.primary,
    textTransform: 'none',
    fontSize: responsive ? '18px' : '24px'
  },
  pagesContainer: {
    width: '100%',
    marginTop: responsive ? '10px' : '20px'
  },
  panel: {
    padding: 0
  }
};
