import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/system';
import { defaultValues } from '../../../constants';
import {
  DateCalendarPicker,
  DatePickers,
  UIButton,
  UICheckBoxTitle,
  UITextInputWithTitle
} from '../../../ui-kit';
import { useFormik } from 'formik';
import { taskListSchema } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { createTaskListRequest, updateTaskListRequest } from '../../../services/redux/slices';
import { getProjectDetailsSel } from '../../../services/redux/selectors';
import { styles } from './style';

export const TaskListForm = ({ isLoading, data, btnTitle }) => {
  const dispatch = useDispatch();
  const projectDetails = useSelector(getProjectDetailsSel);

  const [value, setValue] = useState(null);
  const [showDate, setShowDate] = useState(false);
  const [editableData, setEditableData] = useState([]);
  const [dateSelected, setDateSelected] = useState(false);

  const checkedValue = (data) => {
    setShowDate(data);
    if (data === false) {
      setDateSelected(false);
      setFieldValue('due_date', null);
    }
  };
  useEffect(() => {
    if (data) {
      setEditableData(data);
    }
    if (data && data.description) {
      setFieldValue('description', data.description);
    }
    if (data && data.list_title) {
      setFieldValue('list_title', data.list_title);
    }
    if (data && data.estimated_time) {
      setFieldValue('estimated_time', data.estimated_time);
    }
    if (data && data.due_date != null) {
      setDateSelected(true);
      setValue(dayjs(data.due_date));
      setFieldValue('due_date', data.due_date);
    }
  }, [data]);

  const { values, handleBlur, handleChange, errors, touched, isValid, setFieldValue } = useFormik({
    initialValues: {
      project_id: '',
      list_title: '',
      due_date: null,
      estimated_time: '',
      description: ''
    },
    validationSchema: taskListSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  const handleCalender = (newValue) => {
    setValue(newValue);
    setFieldValue('due_date', newValue);
    setDateSelected(true);
    setShowDate(false);
  };

  const handleCreateTaskList = (id) => {
    if (id) {
      dispatch(updateTaskListRequest({ list_id: id, ...values }));
    } else {
      dispatch(createTaskListRequest(values));
    }
  };

  useEffect(() => {
    if (projectDetails && projectDetails.id) {
      setFieldValue('project_id', projectDetails.id);
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <UITextInputWithTitle
        title={'List title'}
        placeholder="enter list title"
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        name="list_title"
        value={values.list_title}
        error={errors.list_title}
        touched={touched.list_title}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <UICheckBoxTitle
        title={'Due date'}
        values={defaultValues.taskListOptions}
        onCheck={checkedValue}
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        selected={data && data.due_date != null ? true : false}
        defaultOption={data && data.due_date != null ? 'Select date' : 'No due date'}
      />
      {showDate && !dateSelected && <DateCalendarPicker handleCalender={handleCalender} />}
      {dateSelected && <DatePickers value={value} onChange={handleCalender} />}
      <UITextInputWithTitle
        title={'Estimated time (Hours)'}
        placeholder="enter estimated time in hours"
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        name="estimated_time"
        onChange={handleChange}
        value={values.estimated_time}
        allowOnlyNumbers={true}
      />
      <UITextInputWithTitle
        lines={3}
        title={'Description'}
        placeholder="add your description here "
        variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
        name="description"
        value={values.description}
        error={errors.description}
        touched={touched.description}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <UIButton
        title={btnTitle}
        style={styles.btn}
        onClick={() => handleCreateTaskList(editableData.id)}
        disabled={!isValid || !values.description.length || !values.list_title.length || showDate}
        isLoading={isLoading}
      />
    </Box>
  );
};
