import { Box } from '@mui/material';
import { defaultValues } from '../../../constants';
import profilePic from '../../../assets/images/profilePic.png';
import { Text } from '../../../ui-kit';
import { getFormattedTime, getPhotoUrl } from '../../../utils';

export const ViewComments = ({ comment }) => {
  const styles = {
    container: {
      display: 'flex',
      p: 1,
      m: 1
    },
    imgContainer: {
      width: defaultValues.isResponsive ? '25px' : '45px',
      height: defaultValues.isResponsive ? '25px' : '45px',
      borderRadius: '50%',
      marginRight: '10px'
    },
    imgStyle: {
      width: '100%',
      height: '100%',
      borderRadius: '50%'
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.imgContainer}>
        <img
          style={styles.imgStyle}
          src={
            comment.posted_by.profile_pic ? getPhotoUrl(comment.posted_by.profile_pic) : profilePic
          }
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Text variant={defaultValues.isResponsive && 'cardResSubTitle'}>
          {comment.posted_by.full_name}
        </Text>
        <Text variant={defaultValues.isResponsive ? 'resExtraSmall' : 'responsiveText'}>
          {getFormattedTime(comment.posted_at)}
        </Text>
        <Text variant={defaultValues.isResponsive ? 'resExtraSmall' : 'medium'}>
          {comment.comment}
        </Text>
      </Box>
    </Box>
  );
};
