import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const sm = defaultValues.isXSmall;

export const styles = {
  container: {
    width: '100%',
    height: '100%',
    p: defaultValues.isResponsive ? 1 : 3,
    boxSizing: 'border-box',
    backgroundColor: color.secondaryBackground
  },
  subContainer: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.primaryBackground,
    borderRadius: 1,
    p: defaultValues.isResponsive ? 1 : 4,
    boxSizing: 'border-box'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    justifyContent: 'center',
    mb: !defaultValues.isResponsive && '25px',
    p: 1
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  navStyle: {
    cursor: 'pointer',

    color: color.palette.linkBlue,
    textDecoration: 'underline',
    fontWeight: 600
  },
  iconStyle: {
    fontSize: defaultValues.isResponsive ? '14px' : '20px',
    marginX: defaultValues.isResponsive ? '5px' : '10px'
  },
  fontStyle: { fontWeight: 600 },
  cardContainer: {
    width: '100%',
    minHeight: '80vh',
    mt: 2,
    p: defaultValues.isResponsive ? 0 : 2,
    boxSizing: 'border-box',
    borderRadius: 1,
    border: `1px solid ${color.primaryBorder}`
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    p: defaultValues.isResponsive ? 1 : 3
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    padding: defaultValues.isResponsive ? '2%' : '0%',
    boxSizing: 'border-box',
    marginBottom: defaultValues.isResponsive ? '30px' : '20px'
  },
  titleM: { ml: 2, mt: defaultValues.isResponsive && 1 },
  noFoundBoxSuper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    boxSizing: 'border-box'
  },
  noFoundBox: {
    width: '100%',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.secondaryBackground,
    marginTop: '10px'
  },
  noFoundText: {
    color: color.palette.primaryRed,
    fontWeight: 600
  },
  icon: {
    fontSize: sm ? '14px' : defaultValues.isResponsive ? '18px' : '36px',
    color: color.palette.primaryBlack,
    p: sm ? '4px' : '10px'
  },
  actionContainer: {
    display: 'flex',
    mt: defaultValues.isResponsive && 1,
    justifyContent: defaultValues.isResponsive && 'space-between',
    alignItems: 'center'
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    alignItems: !defaultValues.isMobile && 'center'
  }
};
