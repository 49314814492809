import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const res = defaultValues.isResponsive;

export const style = {
  container: {
    backgroundColor: color.secondaryBackground,
    width: '100%',
    height: '100%',
    padding: res ? '4px' : '12px',
    boxSizing: 'border-box',
    overflowY: 'auto'
  },
  subContainer: {
    backgroundColor: color.primaryBackground,
    width: '100%',
    boxSizing: 'border-box',
    minHeight: res ? '100vh' : '80vh',
    borderRadius: 1,
    py: res ? 1 : 2,
    px: res ? 1 : 4
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  notificationContainer: {
    backgroundColor: color.primaryBackground,
    width: '100%',
    boxSizing: 'border-box',
    minHeight: res ? '98vh' : '70vh',
    borderRadius: 1,
    mt: 2,
    p: res ? 1 : 3,
    px: res ? 2 : 5,
    border: `1px solid ${color.primaryBorder}`
  },
  navLink: {
    cursor: 'pointer',
    color: color.palette.linkBlue,
    textDecoration: 'underline'
  },
  icon: { fontSize: '20px', marginX: '10px' },
  logContainer: {
    backgroundColor: color.primaryBackground,
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: 1,
    mt: 2,
    p: 1,
    border: `2px solid ${color.primaryBorder}`
  },
  divider: { my: 1, border: `1px solid ${color.primaryBorder}` }
};
