import { MenuItem, Select } from '@mui/material';
import { defaultValues } from '../../../constants';
import { getTaskStatuses } from '../../../utils/commonFunction';
import { styles } from './style';
export const TaskStatus = ({ status, onChange }) => {
  const taskStatus = getTaskStatuses(status);

  return (
    <Select value={status} onChange={onChange} sx={styles.input(taskStatus)}>
      {defaultValues.taskStatus.map((item, index) => (
        <MenuItem value={item.statusCode} key={index}>
          {item.status}
        </MenuItem>
      ))}
    </Select>
  );
};
