import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
export const mInvitationStyles = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    padding: '2%',
    boxSizing: 'border-box',
    marginBottom: res ? '20px' : '70px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground,
    marginBottom: '20px'
  },
  box1: {
    width: '100%'
  },
  box2: {
    width: '100%',
    marginTop: '20px'
  },
  title: {
    fontSize: res ? '16px' : '24px',
    fontWeight: '600'
  },
  buttonStyle: {
    fontSize: res ? '12px' : '20px',
    height: res ? '35px' : '40px',
    width: 'fit-content'
  },
  activeBtnCont: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px'
  },
  subBox1: (invitation) => ({
    position: 'relative',
    paddingLeft: res
      ? invitation && invitation.length >= 2 && '30px'
      : md
        ? invitation && invitation.length >= 2 && '50px'
        : invitation && invitation.length > 3 && '50px',
    paddingRight: res
      ? invitation && invitation.length >= 2 && '30px'
      : md
        ? invitation && invitation.length >= 2 && '50px'
        : invitation && invitation.length > 3 && '50px',
    boxSizing: 'border-box',
    height: 'fit-content',
    marginTop: '20px'
  }),
  backContainer: { width: '100%' },
  cardsContainer: {
    display: 'flex',
    gap: '20px',
    width: '100%',
    overflowX: 'scroll',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
    marginTop: '20px',
    paddingBottom: '5px',
    boxSizing: 'border-box'
  },
  cardsContainer2: {
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '20px',
    width: '100%',
    marginTop: '20px',
    paddingBottom: '5px',
    boxSizing: 'border-box'
  },
  cardStyle: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: res ? '200px' : '400px',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: 1,
    padding: '15px',
    boxSizing: 'border-box',
    gap: '20px'
  },
  inviteCardStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: res ? '100%' : 'calc(100%/3)',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: 1,
    padding: '15px',
    boxSizing: 'border-box',
    gap: '20px'
  },
  cardStyle2: {
    display: 'flex',
    flexDirection: 'column',
    width: res ? '100%' : '48%',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: 1,
    padding: '15px',
    boxSizing: 'border-box',
    gap: '20px'
  },
  historyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    gap: '20px',
    flexWrap: 'wrap'
  },
  statusContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  status: (item) => ({
    height: 'fit-content',
    width: res ? '70px' : '100px',
    color: item.color,
    backgroundColor: item.bgColor,
    padding: '4px',
    borderRadius: '4px',
    fontSize: res ? '11px' : '14px',
    textAlign: 'center'
  }),
  spanText: {
    fontSize: res ? '11px' : '16px',
    fontWeight: 400
  },
  checkContainer: {
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '5px',
    padding: '15px',
    boxSizing: 'border-box',
    marginTop: '20px'
  },
  checkBox: {
    padding: '9px 9px 9px 0px',
    marginRight: '8px'
  },
  formControl: {
    marginLeft: '-4px'
  },
  icon: {
    color: color.palette.primaryBlack,
    fontSize: res ? '29px' : '42px',
    borderRadius: '4px',
    '& MuiButtonBase-root': {
      padding: '9px 9px 9px 0px'
    }
  },
  checkedIcon: {
    color: color.palette.primaryRed,
    fontSize: res ? '29px' : '42px'
  },
  btsContainer: {
    width: res ? '100%' : 'fit-content',
    display: 'flex',
    justifyContent: res ? 'space-between' : 'none',
    gap: '20px'
  },
  btnSuperCont: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    alignItems: res ? 'start' : 'center',
    gap: '10px'
  },
  accept: {
    backgroundColor: color.palette.primaryWhite,
    border: '1px solid',
    color: color.palette.primaryBlack,
    fontSize: res ? '12px' : '24px',
    fontWeight: 600,
    width: res ? '200px' : '230px'
  },
  reject: {
    width: res ? '200px' : '230px',
    fontSize: res ? '12px' : '24px',
    fontWeight: 600
  },
  askQuestions: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  modalAccept: {
    backgroundColor: color.palette.tertiaryLightBlue,
    width: '48%'
  },
  modalReject: {
    backgroundColor: color.palette.primaryRed,
    width: '48%'
  },
  modalCancel: {
    color: color.palette.primaryBlack,
    width: '48%'
  },
  basicTitle: {
    fontSize: res ? '14px' : '16px',
    fontWeight: 600
  },
  subTitle: {
    fontSize: res ? '10px' : '20px',
    fontWeight: 400
  },
  checkText: {
    fontSize: res ? '12px' : '20px',
    fontWeight: 600,
    wordWrap: 'wrap'
  },
  backBtn: {
    fontSize: res ? '24px' : '36px'
  },
  backRoot: {
    width: 'fit-content',
    height: 'fit-content',
    padding: 0
  },
  pdfRoot: {
    height: res ? '300px' : '400px',
    marginTop: 0
  },
  pdfSub: {
    height: '100%',
    width: '100%'
  },
  empty: {
    fontSize: res ? '12px' : '20px',
    fontWeight: 600,
    color: color.palette.primaryRed
  },
  rightArrow: (val, end, wid) => {
    return {
      visibility:
        parseInt(val + wid) >= end || parseInt(end) === parseInt(wid) ? 'hidden' : 'visible',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-48%)',
      cursor: 'pointer'
    };
  },
  leftArrow: (val, end, wid) => {
    return {
      visibility: parseInt(val) <= 0 || parseInt(end) === parseInt(wid) ? 'hidden' : 'visible',
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-48%)',
      cursor: 'pointer'
    };
  },
  link: {
    color: color.palette.linkBlue,
    textDecoration: 'underline',
    fontSize: res ? '11px' : '16px',
    fontWeight: 400,
    cursor: 'pointer',
    mt: -2
  },
  textClr: { color: color.palette.gray }
};
