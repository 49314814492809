import { Box } from '@mui/material';
import { color, customStyles } from '../../../theme';
import { Text } from '../../../ui-kit';
import { defaultValues } from '../../../constants';

export const ChipsList = ({ list, keyName, title, style, labelColor }) => {
  const responsive = defaultValues.isResponsive;

  const styles = {
    mainBox: {
      width: responsive ? '80%' : '30vw',
      display: 'inline-block',
      borderTop: 'none',
      borderLeft: 'none',
      padding: '10px 30px',
      mt: responsive ? '15px' : 0,
      ...style
    },
    flexBox: {
      ...customStyles.flexBox,
      justifyContent: 'start',
      flexWrap: 'wrap',
      margin: '15px 0'
    },
    label: {
      display: 'inline-block',
      marginBottom: '10px',
      marginRight: responsive ? '8px' : '20px',
      color: labelColor || color.palette.primaryBlack,
      padding: responsive ? '5px 10px' : '10px 20px',
      border: `2px solid ${labelColor || color.palette.primaryBlack}`,
      borderRadius: '8px'
    }
  };

  return (
    <Box sx={styles.mainBox}>
      <Text variant={responsive ? 'medium' : 'fileName'}>{title}</Text>
      <Box sx={styles.flexBox}>
        {list && list.length === 0 ? (
          <Text variant="mediumLight" style={styles.notfound}>
            No {title.toLowerCase()} found!
          </Text>
        ) : (
          list.map((item, i) => (
            <Text
              key={i}
              variant={responsive ? 'responsiveText' : 'smallBold'}
              style={styles.label}>
              {keyName instanceof Array ? keyName.map((i) => item[i]).join(' ') : item[keyName]}
            </Text>
          ))
        )}
      </Box>
    </Box>
  );
};
