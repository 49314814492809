import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

export const GoalStyles = {
  container: {
    width: '100%',
    padding: '4% 5%',
    boxSizing: 'border-box',
    position: 'relative'
  },
  heading1: {
    color: color.palette.primaryWhite,
    paddingTop: '0px',
    width: '100%'
  },
  heading2: {
    color: color.palette.primaryRed,
    fontWeight: 550
  },
  heading3: {
    color: color.palette.primaryWhite,
    paddingTop: '30px',
    width: '95%',
    textAlign: 'justify'
  },
  button: {
    backgroundColor: color.palette.primaryRed,
    color: color.palette.primaryWhite,
    marginTop: 20,
    fontSize: defaultValues.isResponsive ? '14px' : '24px'
  },
  image: {
    width: '100px',
    position: 'absolute',
    height: '170px',
    top: '300px',
    right: '0px'
  }
};
