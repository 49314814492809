import { Box, MenuItem } from '@mui/material';
import { PopupModal, Screen, Text, UIButton } from '../../../ui-kit';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { DMStyles } from './styles';
import { ArrowForwardIos } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { customStyles } from '../../../theme';
import { DragDrop } from '../../common/drag-and-drop';
import { SelectWithTitle } from '../../../ui-kit/select-option-with-title';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjecsErrorMessage,
  getProjectDocuments,
  getProjectsLoadingStatus,
  getProjectsSuccessMessage,
  getUserFullname,
  getUserType
} from '../../../services/redux/selectors';
import { useFormik } from 'formik';
import { DMSchema, getPhotoUrl, resetMessge } from '../../../utils';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import {
  deleteProDocumentRequest,
  getProjectDocumentRequest,
  resetProjectsMessages,
  uploadProjectDocumentRequest
} from '../../../services/redux/slices';
import { toast } from 'react-toastify';
import download from '../../../assets/icons/download.png';
import deleteIcon from '../../../assets/icons/mdi_delete.png';
import { saveAs } from 'file-saver';
import { RenderFile } from '../../common/render-file';
import { CustomModal } from '../../common/modals/status-modal';

export const ProjectDocumentManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoading = useSelector(getProjectsLoadingStatus);
  const documents = useSelector(getProjectDocuments);
  const successMessage = useSelector(getProjectsSuccessMessage);
  const errorMessage = useSelector(getProjecsErrorMessage);
  const userType = useSelector(getUserType);
  const userFullname = useSelector(getUserFullname);

  const [details, setDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [rmModel, setRmModel] = useState(false);
  const [currItem, setCurrItem] = useState(null);

  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid
  } = useFormik({
    initialValues: {
      docType: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: DMSchema
  });

  useEffect(() => {
    if (location.state) {
      setDetails(location.state.data);
    }
  }, [location.state]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      handleClose();
      handleRmClose();
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    dispatch(getProjectDocumentRequest({ project_id: details.id }));
  }, [details]);

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setFieldValue('docType', null);
    setFieldTouched('docType', false);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleUpload = () => {
    const payload = {
      project_id: details.id,
      attachment_type: values.docType,
      document: file
    };
    dispatch(uploadProjectDocumentRequest(payload));
  };
  const handleDownload = (url) => {
    const pdfUrl = url;
    let arr = url.split('/');
    saveAs(pdfUrl, `${arr[arr.length - 1]}`);
  };
  const handleDelete = () => {
    let payload = { attachment_id: currItem.id };
    dispatch(deleteProDocumentRequest(payload));
  };

  const openRm = (item) => {
    setCurrItem(item);
    setRmModel(true);
  };

  const handleRmClose = () => {
    setRmModel(false);
    setCurrItem(null);
  };

  const handleAccess = (type, user) => {
    if (
      user === userFullname &&
      ![defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType)
    ) {
      return true;
    } else if (
      [defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType) &&
      type !== 1 &&
      type !== 6
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive && true}>
      <LoadingScreen isLoading={!open && !rmModel && isLoading} />
      <Box sx={DMStyles.container}>
        <Box sx={DMStyles.contentContainer}>
          <Box sx={DMStyles.navContainer}>
            <Box sx={DMStyles.navContainer}>
              <Box sx={customStyles.flexBox}>
                <Text style={DMStyles.navText} onClick={() => navigate(-1)}>
                  {details && details.project_name}
                </Text>
                {!defaultValues.isResponsive && <ArrowForwardIos sx={DMStyles.icon} />}
              </Box>

              <Text style={DMStyles.navIdleText}>Document management</Text>
            </Box>
          </Box>

          <Box sx={DMStyles.uploadSuperContainer}>
            <Box sx={DMStyles.uploadContainer}>
              <Box sx={DMStyles.subContainer}>
                <Text style={DMStyles.title}>Upload docs & files </Text>
              </Box>
              <UIButton
                style={DMStyles.sendBtn}
                title={'+ new document'}
                onClick={() => setOpen(true)}
              />
            </Box>
            <Box sx={DMStyles.pdfSuperContainer}>
              {documents &&
                documents.length > 0 &&
                documents.map((elem, idx) => {
                  return (
                    <Box key={idx} sx={DMStyles.docCont}>
                      <img
                        src={download}
                        style={DMStyles.downloadIcon}
                        onClick={() => handleDownload(getPhotoUrl(elem.doc_url))}
                      />
                      {handleAccess(elem.doc_type, elem.uploaded_by) && (
                        <img
                          src={deleteIcon}
                          style={DMStyles.deleteIcon}
                          onClick={() => openRm(elem)}
                        />
                      )}
                      <RenderFile
                        filePath={getPhotoUrl(elem.doc_url)}
                        handleDownload={() => handleDownload(getPhotoUrl(elem.doc_url))}
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>

          <PopupModal
            showModel={open}
            onClose={handleClose}
            showDivider={true}
            heading={'Upload document'}
            childBoxStyle={DMStyles.childBox}
            dividerStyle={DMStyles.divider}
            headingStyle={DMStyles.popupHead}
            headingContainerStyle={DMStyles.popupHeadContainer}
            containerStyles={DMStyles.popupContainer}
            contentContainerStyles={DMStyles.popupContentBox}>
            <LoadingScreen isLoading={isLoading} />
            <DragDrop
              handleChange={handleFileChange}
              pdfFile={file}
              fileTypes={defaultValues.docFileTypes}
            />
            <SelectWithTitle
              title={'Document type'}
              name={'docType'}
              value={values.docType}
              onChange={handleChange}
              touched={touched.docType}
              onBlur={handleBlur}
              error={errors.docType}
              placeholder="select here">
              {defaultValues.docType.map((elem, idx) => (
                <MenuItem key={idx} value={elem.value}>
                  {elem.title}
                </MenuItem>
              ))}
            </SelectWithTitle>

            <UIButton
              title={'Upload'}
              style={DMStyles.uploadButton}
              onClick={handleUpload}
              disabled={!isValid || !values.docType || !file}
            />
          </PopupModal>

          <CustomModal
            open={rmModel}
            onClose={handleRmClose}
            onCancel={handleRmClose}
            message={'This document will be deleted'}
            onConfirm={handleDelete}
            btnType={'Delete'}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Screen>
  );
};
