import { put, takeLatest } from 'redux-saga/effects';
import {
  getNotificationAPI,
  getNotificationCountAPI,
  otpResend,
  signIn,
  updateNotificationAPI,
  verifyLoginRequest
} from '../../api';
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  otpResendSuccess,
  verificationFailure,
  verificationSuccess,
  otpResendRequest,
  verificationRequest,
  otpResendFailure,
  getNotificationCountSuccess,
  getNotificationCountFailure,
  getNotificationCountRequest,
  getNotificationSuccess,
  getNotificationFailure,
  getNotificationRequest,
  updateNotificationRequest,
  updateNotificationSuccess,
  updateNotificationFailure
} from '../slices';
import { SUCCESS_KEY } from '../../../constants';

function* login(action) {
  try {
    const loginResponse = yield signIn(action.payload);
    if (loginResponse.data.status === 'success') {
      yield put(loginSuccess(loginResponse.data));
    } else {
      yield put(loginFailure(loginResponse.data));
    }
  } catch (error) {
    yield put(loginFailure('E-10001'));
  }
}

function* veryfyLogin(action) {
  try {
    const verificationResponse = yield verifyLoginRequest(action.payload);
    if (verificationResponse.data.status === 'success') {
      yield put(verificationSuccess(verificationResponse.data));
    } else {
      yield put(verificationFailure(verificationResponse.data));
    }
  } catch (error) {
    yield put(verificationFailure('E-10001'));
  }
}

function* resendOtp(action) {
  try {
    const otpResendResponse = yield otpResend(action.payload);
    yield put(otpResendSuccess(otpResendResponse.data));
  } catch (error) {
    yield put(otpResendFailure('E-10001'));
  }
}

function* getNotificationCount(action) {
  try {
    const response = yield getNotificationCountAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getNotificationCountSuccess(response.data));
    } else {
      yield put(getNotificationCountFailure(response.data));
    }
  } catch (error) {
    yield put(getNotificationCountFailure('E-10001'));
  }
}

function* getNotification(action) {
  try {
    const response = yield getNotificationAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getNotificationSuccess(response.data));
    } else {
      yield put(getNotificationFailure(response.data));
    }
  } catch (error) {
    yield put(getNotificationFailure('E-10001'));
  }
}

function* updateNotification(action) {
  try {
    const response = yield updateNotificationAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateNotificationSuccess(response.data));
    } else {
      yield put(updateNotificationFailure(response.data));
    }
  } catch (error) {
    yield put(updateNotificationFailure('E-10001'));
  }
}

export function* authSaga() {
  yield takeLatest(loginRequest.type, login);
  yield takeLatest(verificationRequest.type, veryfyLogin);
  yield takeLatest(otpResendRequest.type, resendOtp);
  yield takeLatest(getNotificationCountRequest.type, getNotificationCount);
  yield takeLatest(getNotificationRequest.type, getNotification);
  yield takeLatest(updateNotificationRequest.type, updateNotification);
}
