import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import logo from '../../../assets/images/sidebarlogo.png';
import logout from '../../../assets/images/logout.png';
import { Text } from '../../../ui-kit';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { styles } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest, setCurrentPage, setSelectedTab } from '../../../services/redux/slices';
import { useNavigate } from 'react-router-dom';
import { getCustomerStatus, getSelectedTab, getUserType } from '../../../services/redux/selectors';

export const Sidebar = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const userType = useSelector(getUserType);
  const isCustomerAdmin = useSelector(getCustomerStatus);
  const selectedTab = useSelector(getSelectedTab);

  const [selectedTabIndex, setSelectedTabIndex] = useState(selectedTab);

  const handleTabChange = (event, newValue) => {
    dispatch(setSelectedTab(newValue));
  };

  useEffect(() => {
    if (selectedTab) {
      setSelectedTabIndex(selectedTab);
    }
  }, [selectedTab]);

  const onLogout = () => {
    dispatch(logoutRequest());
    dispatch({ type: 'USER_LOGOUT' });
    localStorage.clear();
    navigation(SCREEN_PATH.HOME);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.logoContainer}>
        <img src={logo} alt="logo" style={styles.logo} />
      </Box>
      <Tabs
        orientation="vertical"
        value={selectedTabIndex}
        variant="scrollable"
        onChange={handleTabChange}
        TabIndicatorProps={styles.tabs}
        sx={styles.tabsStyle}>
        {userType === defaultValues.userRole.customer
          ? defaultValues.customerMenu.map((item, index) => {
              if (!(!isCustomerAdmin && (item.id === 3 || item.id === 4))) {
                return (
                  <Tab
                    sx={styles.tabStyle}
                    key={index}
                    label={
                      <Box sx={styles.menuContainer}>
                        <img src={item.icon} alt="icons" style={styles.menuIcons} />
                        <Text variant="small" style={styles.menuItems}>
                          {item.menu}
                        </Text>
                      </Box>
                    }
                    onClick={() => {
                      dispatch(setCurrentPage({ menu: item.title }));
                      navigation(item.route);
                    }}
                  />
                );
              }
            })
          : userType === defaultValues.userRole.supplier
            ? defaultValues.supplierMenu.map((item, index) => (
                <Tab
                  sx={styles.tabStyle}
                  key={index}
                  label={
                    <Box sx={styles.menuContainer}>
                      <img src={item.icon} alt="icons" style={styles.menuIcons} />
                      <Text variant="small" style={styles.menuItems}>
                        {item.menu}
                      </Text>
                    </Box>
                  }
                  onClick={() => {
                    dispatch(setCurrentPage({ menu: item.title }));
                    navigation(item.route);
                  }}
                />
              ))
            : userType === defaultValues.userRole.projectManger
              ? defaultValues.PMMenu.map((item, index) => (
                  <Tab
                    sx={styles.tabStyle}
                    key={index}
                    label={
                      <Box sx={styles.menuContainer}>
                        <img src={item.icon} alt="icons" style={styles.menuIcons} />
                        <Text variant="small" style={styles.menuItems}>
                          {item.menu}
                        </Text>
                      </Box>
                    }
                    onClick={() => {
                      dispatch(setCurrentPage({ menu: item.title }));
                      navigation(item.route);
                    }}
                  />
                ))
              : defaultValues.superAdminMenuItems.map(
                  (item, index) =>
                    !(userType === defaultValues.userRole.admin && item.id === 5) && (
                      <Tab
                        sx={styles.tabStyle}
                        key={index}
                        label={
                          <Box sx={styles.menuContainer}>
                            <img src={item.icon} alt="icons" style={styles.menuIcons} />
                            <Text variant="small" style={styles.menuItems}>
                              {item.menu}
                            </Text>
                          </Box>
                        }
                        onClick={() => navigation(item.route)}
                      />
                    )
                )}
      </Tabs>
      <Box sx={styles.logoutContainer} onClick={onLogout}>
        <img src={logout} style={styles.menuIcons} />
        <Text style={styles.menuItems}>Logout</Text>
      </Box>
    </Box>
  );
};
