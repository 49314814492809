import { createSelector } from 'reselect';

export const getAuthStatus = createSelector(
  [(state) => state.auth.isAuthenticated],
  (isAuthenticated) => isAuthenticated
);

export const getToken = createSelector([(state) => state.auth.token], (token) => token);

export const loginLoadingStatus = createSelector(
  [(state) => state.auth.isLoading],
  (isLoading) => isLoading
);

export const loginPageStatus = createSelector(
  [(state) => state.auth.isLoginPage],
  (isLoginPage) => isLoginPage
);

export const loginSuccessMsg = createSelector(
  [(state) => state.auth.successMessage],
  (successMessage) => successMessage
);

export const userInfo = createSelector([(state) => state.auth.userData], (userInfo) => userInfo);

export const loginError = createSelector(
  [(state) => state.auth.errorMessage],
  (errorMessage) => errorMessage
);

export const isEmailVerified = createSelector(
  [(state) => state.auth.isEmailVerified],
  (isEmailVerified) => isEmailVerified
);

export const getCurrentPages = createSelector(
  [(state) => state.auth.currentPage],
  (currentPage) => currentPage
);

export const getUserType = createSelector([(state) => state.auth.userRole], (userRole) => userRole);

export const getApprovedStatus = createSelector(
  [(state) => state.auth.isApproved],
  (isApproved) => isApproved
);

export const getCustomerStatus = createSelector(
  [(state) => state.auth.isCustomerAdmin],
  (isCustomerAdmin) => isCustomerAdmin
);

export const getNotificationCount = createSelector(
  [(state) => state.auth.notificationCount],
  (notificationCount) => notificationCount
);

export const getNotification = createSelector(
  [(state) => state.auth.notifications],
  (notifications) => notifications
);

export const getNextPages = createSelector(
  [(state) => state.auth.nextPage],
  (nextPage) => nextPage
);

export const getActivePage = createSelector(
  [(state) => state.auth.activePage],
  (activePage) => activePage
);
export const getUserId = createSelector([(state) => state.auth.userId], (userId) => userId);

export const getUserFullname = createSelector(
  [(state) => state.auth.user_fullname],
  (user_fullname) => user_fullname
);

export const getAllTimezones = createSelector([(state) => state.auth.timeZones], (a) => a);
