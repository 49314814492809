import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  Filter,
  PaginationCustom,
  SearchBar,
  Sort,
  Text,
  UIButton,
  ActionButton,
  IconsButton
} from '../../../../ui-kit';
import { ManageListServiceStyles } from './styles';
import { useCallback, useEffect, useState } from 'react';
import { StatusIndicator, Title } from '../../table-title';
import { useDispatch, useSelector } from 'react-redux';
import {
  addServiceRequest,
  resetSettingsMessages,
  serviceRequest,
  updateServicesParams
} from '../../../../services/redux/slices';
import {
  getServices,
  getServicesParams,
  getSettingsErrorMessage,
  getSuccessMessageAddServices,
  getSuccessServicesMessage,
  settingLoadingStatus
} from '../../../../services/redux/selectors';
import { LoadingScreen } from '../../../../ui-kit/loading-screen';
import RefreshIcon from '@mui/icons-material/Refresh';
import { EDIT_KEY, defaultValues, getErrorMessage, getSuccessMessage } from '../../../../constants';
import { toast } from 'react-toastify';
import { CollapsableList } from '../../../common/collapsable-list';
import { customStyles } from '../../../../theme';
import { NoData } from '../../../common/no-data-message';

export const ManageListServices = ({ onAddServices, onEditServiceId }) => {
  const dispatch = useDispatch();
  const serviceSelector = useSelector(getServices);
  const isLoading = useSelector(settingLoadingStatus);
  const [servicesData, setServicesData] = useState({});
  const [filter, setFilter] = useState({});
  const [pageNo, setPageNo] = useState(1);

  const successMessage = useSelector(getSuccessServicesMessage);
  const addSuccessMessage = useSelector(getSuccessMessageAddServices);
  const errorMessage = useSelector(getSettingsErrorMessage);
  const servicesParams = useSelector(getServicesParams);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (addSuccessMessage) {
      toast.success(getSuccessMessage(addSuccessMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    dispatch(resetSettingsMessages());
  }, [successMessage, errorMessage, addSuccessMessage]);

  useEffect(() => getServicesList(), []);

  const reloadFilter = () => {
    setFilter({});
    setPageNo(1);
  };

  const getServicesList = useCallback(
    (data) => {
      let payload = { page: pageNo };
      if (data === undefined) {
        reloadFilter();
      } else {
        payload = {
          ...payload,
          ...servicesParams,
          ...data
        };
      }
      setFilter(payload);
      dispatch(updateServicesParams(payload));
      dispatch(serviceRequest(payload));
    },
    [filter, servicesData]
  );

  useEffect(() => {
    if (serviceSelector.services) {
      setServicesData(serviceSelector);
      setPageNo(serviceSelector.current_page);
    }
  }, [serviceSelector.services]);

  const onChangeActive = useCallback(
    async (service) => {
      const data = {
        type: EDIT_KEY,
        payload: {
          ...service,
          active: !service.active
        }
      };
      data.payload.questions && delete data.payload.questions;
      await dispatch(addServiceRequest(data));
      getServicesList({ ...filter });
    },
    [filter]
  );

  const handleSearch = (search_text) => {
    (search_text.length === 0 || search_text.length > 2) &&
      getServicesList({ search_text, showLoading: false });
  };

  return (
    <Box sx={ManageListServiceStyles.container}>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={ManageListServiceStyles.contentContainer}>
        <Box sx={ManageListServiceStyles.searchTabContainer}>
          <Box sx={ManageListServiceStyles.searchToolsContainer}>
            {!defaultValues.isResponsive ? (
              <SearchBar
                label="search for a service"
                searchedVlaue={handleSearch}
                onRefresh={!servicesParams.search_text && {}}
                defaultValue={servicesParams.search_text}
              />
            ) : (
              <Text sx={ManageListServiceStyles.headingText} variant="medium">
                Current Services
              </Text>
            )}
            <UIButton
              sx={ManageListServiceStyles.newServiceButton}
              title="+ new service"
              on
              onClick={() => onAddServices()}
            />
          </Box>

          <Box sx={ManageListServiceStyles.toolsContainer}>
            <IconsButton
              title={!defaultValues.isResponsive ? null : 'Refresh'}
              icon={<RefreshIcon sx={ManageListServiceStyles.icon} />}
              onClick={() => getServicesList()}
            />

            <Sort
              selectedOption={(sort) => getServicesList({ sort, page: 1 })}
              activeOption={filter.sort}
            />
            <Filter
              label={'Filter'}
              applyFilter={(data) => {
                getServicesList({ filter: JSON.stringify(data), page: 1 });
              }}
              activeData={filter && filter.filter}
            />
          </Box>
        </Box>

        {!defaultValues.isResponsive ? (
          <Text sx={ManageListServiceStyles.heading} variant="fileName">
            {defaultValues.otherSettingsServicesHeading}
          </Text>
        ) : (
          <Box sx={ManageListServiceStyles.searchContainer}>
            <SearchBar
              label={'Search languages'}
              searchedVlaue={handleSearch}
              onRefresh={!servicesParams.search_text && {}}
              defaultValues={servicesParams.search_text}
            />
          </Box>
        )}
        {!defaultValues.isResponsive ? (
          <>
            {servicesData && servicesData.services && servicesData.services.length > 0 ? (
              <Table sx={ManageListServiceStyles.tableContainer} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={ManageListServiceStyles.headingCell} align="left">
                      Services
                    </TableCell>
                    <TableCell sx={ManageListServiceStyles.headingCell} align="left">
                      Status
                    </TableCell>
                    <TableCell sx={ManageListServiceStyles.headingCell} align="center">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {servicesData &&
                    servicesData.services.map((row) => (
                      <TableRow key={row.id} sx={ManageListServiceStyles.tableRow}>
                        <TableCell component="th" scope="row" sx={{ maxWidth: '10vw' }}>
                          <Title
                            title={row.title}
                            sx={customStyles.link}
                            onClick={() => onEditServiceId(row.id)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <StatusIndicator status={row.active} />
                        </TableCell>
                        <TableCell align="left" sx={customStyles.actionCont}>
                          <ActionButton
                            item={row}
                            isEdit={false}
                            onButtonClick={() => onChangeActive(row)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <NoData message={getErrorMessage('E-10022')} />
            )}
          </>
        ) : (
          <Box sx={ManageListServiceStyles.collapseContainer}>
            {servicesData && servicesData.services && servicesData.services.length > 0 ? (
              <CollapsableList
                contentArray={servicesData.services}
                type="services"
                isEdit={false}
                onTitleClick={(item) => onEditServiceId(item.id)}
                onButtonClick={(id, active, index, item) => onChangeActive(item)}
                loaderSize={defaultValues.isResponsive && 15}
              />
            ) : (
              <NoData message={getErrorMessage('E-10022')} />
            )}
          </Box>
        )}

        <Box sx={ManageListServiceStyles.paginationContainer}>
          <Box sx={ManageListServiceStyles.marginLeft}>
            <PaginationCustom
              onChange={(e, page) => {
                setPageNo(page);
                getServicesList({ page });
              }}
              currentPage={pageNo}
              count={servicesData.total_page}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
