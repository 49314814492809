import { Avatar, Box, MenuItem, Select } from '@mui/material';
import { Text } from '../../../ui-kit';
import { Styles } from './style';
import { color } from '../../../theme';
import { defaultValues } from '../../../constants';
import { getProjectStatus, truncateText } from '../../../utils';
import { useEffect, useState } from 'react';

export const Title = ({ title, sx, onClick }) => {
  return (
    <Text style={{ ...Styles.services, ...sx }} variant="small" onClick={onClick}>
      {title}
    </Text>
  );
};

export const StatusIndicator = ({ status, title }) => (
  <Text style={Styles.statusStyle(status)}>{title ? title : status ? 'Active' : 'Inactive'}</Text>
);

export const ProjectStatusIndicator = ({ id, title }) => {
  const data = getProjectStatus(id, title);
  return (
    data && (
      <Text
        variant={defaultValues.isResponsive ? 'responsiveDesc' : 'responsiveText'}
        style={Styles.projectStatusInd(data)}>
        {data.title}
      </Text>
    )
  );
};

export const ApprovalIndicator = ({ status }) => {
  return (
    <Text style={Styles.customIndStyle(status)}>
      {status === null ? 'Approval pending' : status ? 'Approved' : 'Rejected'}
    </Text>
  );
};

export const ProjectTitle = ({ title, onTitleClick, titleStyle, subTitle, flag }) => {
  return (
    <Box>
      <Text
        style={{ color: color.palette.primaryBlack, ...titleStyle }}
        onClick={onTitleClick}
        variant="desc">
        {truncateText(title, 15)}
      </Text>
      <Text variant="medium" style={{ color: color.palette.gray }}>
        {subTitle}
      </Text>
      <Text variant="medium" style={{ color: color.palette.gray }}>
        {flag}
      </Text>
    </Box>
  );
};

export const ProjectStatus = ({ data, options, onChange, role }) => {
  const [currentStatus, setCurrentStatus] = useState(data.status ? data.status.id : data.id);
  const [title, setTitle] = useState('');
  var opData = options ? [...options].sort((a, b) => a.order - b.order) : [];
  useEffect(() => {
    setCurrentStatus(data.status ? data.status.id : data.id);
    if (data.status) {
      if (role === defaultValues.userRole.supplier) {
        if (data.status.id === 6 || data.status.id === 3) {
          setCurrentStatus(6);
          setTitle(defaultValues.projectStatusForSupplier.inProgress);
        } else if (
          data.status.id === 7 ||
          data.status.id === 8 ||
          data.status.id === 9 ||
          data.status.id === 11
        ) {
          setCurrentStatus(7);
          setTitle(defaultValues.projectStatusForSupplier.completed);
        } else if (data.status.id === 10) {
          setCurrentStatus(10);
          setTitle(defaultValues.projectStatusForSupplier.cancelled);
        } else {
          setCurrentStatus(1);
          setTitle(defaultValues.projectStatusForSupplier.pending);
        }
      } else {
        setCurrentStatus(data.status.id);
        setTitle(data.status.title ? data.status.title : data.title);
      }
    }
  }, [data]);

  const handleChange = (e) => {
    const statusid = e.target.value;
    onChange(data.id, statusid);
  };

  const status = getProjectStatus(currentStatus);

  return role !== defaultValues.userRole.admin && role !== defaultValues.userRole.superAdmin ? (
    <Text style={Styles.projectStatus(status, role, 'text')}>{title}</Text>
  ) : (
    <Select
      disabled={currentStatus === 11}
      sx={Styles.projectStatus(status, role, 'select')}
      size="small"
      value={currentStatus}
      onChange={handleChange}>
      {opData.length > 0 &&
        opData.map((item, index) => (
          <MenuItem key={index} sx={{ fontSize: '14px' }} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
    </Select>
  );
};

export const BasicProfileDetails = ({
  title,
  subTitle,
  image,
  propic = false,
  titleStyle,
  onTitleClick = () => {},
  subStyle,
  titleVariant = 'semiBold',
  subTitleVariant = 'medium'
}) => {
  return (
    <Box sx={Styles.basicContainer}>
      {propic && <Avatar style={Styles.propic} src={image} />}
      <Box>
        {title && (
          <Text variant={titleVariant} style={Styles.basicTitle(titleStyle)} onClick={onTitleClick}>
            {title}
          </Text>
        )}
        {subTitle && (
          <Text variant={subTitleVariant} style={Styles.basicSubTitle(subStyle)}>
            {subTitle}
          </Text>
        )}
      </Box>
    </Box>
  );
};
