import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopupModal, Screen, Text, TextInput, UIButton } from '../../../ui-kit';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { Box, Rating } from '@mui/material';
import { useEffect, useState } from 'react';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  getFeedbacks,
  getProjecsErrorMessage,
  getProjectsLoadingStatus,
  getProjectsSuccessMessage,
  getUserType
} from '../../../services/redux/selectors';
import { customStyles } from '../../../theme';
import { feedStyle } from './styles';
import { FeedbackCard, NoData } from '../../../components';
import {
  getFeedbackRequest,
  resetProjectsMessages,
  sendCustomerFeedbackRequest,
  sendSupplierFeedbackRequest,
  setSelectedTab
} from '../../../services/redux/slices';
import { toast } from 'react-toastify';
import { resetMessge } from '../../../utils';

export const FeedbackPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoading = useSelector(getProjectsLoadingStatus);
  const userType = useSelector(getUserType);
  const feedbacksList = useSelector(getFeedbacks);
  const successMessage = useSelector(getProjectsSuccessMessage);
  const errorMessage = useSelector(getProjecsErrorMessage);

  const [details, setDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [feedback, setFeedBack] = useState('');
  const [bookingProcess, setBookingProcess] = useState('');
  const [captioner, setCaptioner] = useState('');
  const [overallService, setOverallService] = useState('');
  const [audioQuality, setAudioQuality] = useState('');
  const [prepMaterials, setPrepMaterials] = useState('');
  const [proId, setProId] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const proId = urlParams.get('proId');
    if (proId) {
      setProId(proId);
      setOpen(true);
      dispatch(setSelectedTab(2));
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      setDetails(location.state.data);
      handleGetFeedbacks();
    }
  }, [location.state]);

  useEffect(() => {
    if (defaultValues.userRole.customer === userType) {
      if (feedback && bookingProcess && captioner && overallService) {
        setBtnDisable(false);
      } else {
        setBtnDisable(true);
      }
    }
    if (defaultValues.userRole.supplier === userType) {
      if (feedback && audioQuality && prepMaterials) {
        setBtnDisable(false);
      } else {
        setBtnDisable(true);
      }
    }
  }, [feedback, bookingProcess, captioner, overallService, audioQuality, prepMaterials]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      handleGetFeedbacks();
      handleClose();
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [successMessage, errorMessage]);

  const handleGetFeedbacks = () => {
    let payload = { project_id: proId ? proId : location.state.data.id };
    dispatch(getFeedbackRequest(payload));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFeedBack('');
    setBookingProcess('');
    setCaptioner('');
    setOverallService('');
    setAudioQuality('');
    setPrepMaterials('');
  };

  const handleSendFeedback = () => {
    let payload = {};
    if (userType === defaultValues.userRole.supplier) {
      payload = {
        project_id: proId ? proId : details.id,
        comment: feedback,
        audio_quality: audioQuality,
        quality_of_prep_materials: prepMaterials
      };
      dispatch(sendSupplierFeedbackRequest(payload));
    }
    if (userType === defaultValues.userRole.customer) {
      payload = {
        project_id: proId ? proId : details.id,
        comment: feedback,
        booking_process_rating: bookingProcess,
        captioner_rating: captioner,
        overall_rating: overallService
      };
      dispatch(sendCustomerFeedbackRequest(payload));
    }
  };

  const getFeedTitle = () => {
    if ([defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType)) {
      return 'View customer & supplier feedback';
    }
    if ([defaultValues.userRole.customer, defaultValues.userRole.supplier].includes(userType)) {
      return 'View feedbacks';
    }
  };

  const renderChild = () => {
    return (
      <Box>
        {userType === defaultValues.userRole.customer ? (
          <>
            <Box sx={feedStyle.ratingMBox}>
              <Text sx={feedStyle.ratingMText}>Booking process</Text>
              <Rating
                sx={feedStyle.ratingStar}
                value={bookingProcess}
                onChange={(e) => setBookingProcess(e.target.value)}
              />
            </Box>

            <Box sx={feedStyle.ratingMBox}>
              <Text sx={feedStyle.ratingMText}>Captioner</Text>
              <Rating
                sx={feedStyle.ratingStar}
                value={captioner}
                onChange={(e) => setCaptioner(e.target.value)}
              />
            </Box>
            <Box sx={feedStyle.ratingMBox}>
              <Text sx={feedStyle.ratingMText}>Overall service</Text>
              <Rating
                sx={feedStyle.ratingStar}
                value={overallService}
                onChange={(e) => setOverallService(e.target.value)}
              />
            </Box>
          </>
        ) : (
          userType === defaultValues.userRole.supplier && (
            <>
              <Box sx={feedStyle.ratingMBox}>
                <Text sx={feedStyle.ratingMText}>Audio quality</Text>
                <Rating
                  sx={feedStyle.ratingStar}
                  value={audioQuality}
                  onChange={(e) => setAudioQuality(e.target.value)}
                />
              </Box>
              <Box sx={feedStyle.ratingMBox}>
                <Text sx={feedStyle.ratingMText}>Quality of Preparation materials</Text>
                <Rating
                  sx={feedStyle.ratingStar}
                  value={prepMaterials}
                  onChange={(e) => setPrepMaterials(e.target.value)}
                />
              </Box>
            </>
          )
        )}
        <TextInput
          style={feedStyle.textInp}
          value={feedback}
          onChange={(e) => setFeedBack(e.target.value)}
          placeholder="Type here..."
          lines={3}
        />
      </Box>
    );
  };

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive && true}
      currentPage="Feedback">
      <LoadingScreen isLoading={isLoading} />
      <Box sx={feedStyle.container}>
        <Box sx={feedStyle.contentContainer}>
          <Box sx={{ ...feedStyle.navContainer, ...feedStyle.marBot }}>
            <Box sx={feedStyle.navContainer}>
              <Box sx={customStyles.flexBox}>
                <Text style={feedStyle.navText} onClick={() => navigate(-1)}>
                  {details && details.project_name}
                </Text>
                {!defaultValues.isResponsive && details && details.project_name && (
                  <ArrowForwardIos sx={feedStyle.icon} />
                )}
              </Box>

              <Text style={feedStyle.navIdleText}>Feedback</Text>
            </Box>
          </Box>

          <Box sx={feedStyle.subCont}>
            {
              <Box sx={feedStyle.titleCont}>
                <Text sx={feedStyle.title}>{getFeedTitle()}</Text>
                {[defaultValues.userRole.customer, defaultValues.userRole.supplier].includes(
                  userType
                ) && <UIButton title="+ feedback" style={feedStyle.feedBtn} onClick={handleOpen} />}
              </Box>
            }
          </Box>

          <Box sx={feedStyle.feedCardCont}>
            {feedbacksList && feedbacksList.length > 0 ? (
              feedbacksList.map((item, idx) => <FeedbackCard item={item} key={idx} />)
            ) : (
              <NoData message="No feedbacks found" />
            )}
          </Box>

          <PopupModal
            showModel={open}
            onClose={handleClose}
            heading={'Provide feedback'}
            headingStyle={feedStyle.assignHeading}
            showDivider={true}
            headingContainerStyle={feedStyle.ratingHCont}
            dividerStyle={feedStyle.ratingDivider}
            containerStyles={feedStyle.ratingContBox}
            contentContainerStyles={feedStyle.ratingContentBox}
            childBoxStyle={feedStyle.ratingChildBox}>
            {renderChild()}
            <Box sx={feedStyle.ratingBtnCont}>
              <UIButton
                style={feedStyle.ratingBtn}
                disabled={btnDisable}
                title="Submit"
                onClick={handleSendFeedback}
              />
            </Box>
          </PopupModal>
        </Box>
      </Box>
    </Screen>
  );
};
