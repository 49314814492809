import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { defaultValues } from '../../../constants';
import { Text } from '../../../ui-kit';
import { styles } from './style';

export const DayPicker = ({ selDay, selectedDays }) => {
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (selectedDays) {
      setDays(selectedDays);
    }
  }, [selectedDays]);

  const handleDay = (val) => {
    selDay(val.value);
  };

  return (
    <Box sx={styles.container}>
      {defaultValues.days.map((day, index) => (
        <Box key={index} onClick={() => handleDay(day)} sx={styles.dayStyle(days, day)}>
          <Text>{day.name}</Text>
        </Box>
      ))}
    </Box>
  );
};
