import { defaultValues } from '../../../constants';
import { fonts } from '../../../theme/fonts';
import { color } from '../../../theme/color';
import { customStyles } from '../../../theme';

const responsive = defaultValues.isResponsive;

export const MSupStyle = {
  mainBox: {
    width: '100%',
    padding: '2%',
    boxSizing: 'border-box',
    backgroundColor: color.secondaryBackground,
    height: '85vh'
  },
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.palette.primaryBackground,
    padding: '2%',
    boxSizing: 'border-box'
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginBottom: responsive ? '10px' : '30px'
  },
  searchTabContainer: {
    width: '100%',
    height: responsive ? 'auto' : '60px',
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    alignItems: 'center',
    padding: '5px 0px',
    boxSizing: 'border-box',
    marginBottom: '15px'
  },
  searchToolsContainer: {
    width: responsive ? '100%' : '80%',
    height: '100%',
    display: 'flex',
    justifyContent: responsive ? 'space-between' : 'none',
    alignItems: responsive ? 'center' : 'none',
    paddingBottom: responsive ? '8px' : '0px',
    paddingTop: responsive ? '6px' : '0px',
    borderBottom: responsive ? `1px solid ${color.primaryBorder}` : 'none'
  },
  headingText: {
    marginLeft: '10px',
    fontWeight: 600,
    fontSize: '22px'
  },
  newServiceButton: {
    padding: responsive ? '6px 11px' : '7px 30px',
    backgroundColor: color.palette.primaryRed,
    textTransform: 'none',
    marginRight: '10px',
    marginLeft: '20px',
    '&:hover': {
      backgroundColor: color.palette.primaryRed
    },
    fontSize: responsive ? '12px' : '20px'
  },
  toolsContainer: {
    width: responsive ? '100%' : 'fit-content',
    display: 'flex',
    justifyContent: responsive ? 'center' : 'end',
    marginTop: responsive ? '10px' : '0px',
    alignItems: 'center'
  },
  icon: {
    fontSize: responsive ? '20px' : '36px',
    color: color.palette.primaryBlack,
    marginLeft: '0',
    p: '10px'
  },
  notFoundBox: {
    margin: '10px auto',
    height: '60vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: color.palette.disableColor
  },
  collapseContainer: {
    width: '100%',
    padding: '2%',
    boxSizing: 'border-box'
  },
  heading: {
    marginTop: '20px',
    marginBottom: '10px',
    fontWeight: 600
  },
  searchContainer: {
    p: '2%'
  },
  tableContainer: {
    minWidth: 650,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '5px'
  },
  headingCell: {
    fontSize: '22px',
    fontFamily: fonts.primary,
    backgroundColor: color.palette.buttonLight
  },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 }
  },
  actionBox: {
    ...customStyles.flexBox,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  w130: { width: '130px' },
  actionBtn: {
    ...customStyles.flexBox,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '130px'
  },
  actionIcon: (btnColor, disabled) => ({
    mt: '10px',
    width: responsive ? '30px' : '35px',
    height: responsive ? '30px' : '35px',
    borderRadius: '50%',
    border: `3px solid ${disabled ? color.palette.lightGray : btnColor}`,
    color: disabled ? color.palette.lightGray : btnColor
  }),
  word_b: { wordBreak: 'break-all' },
  iconColor: (btnColor, disabled) => ({
    color: disabled ? color.palette.lightGray : btnColor
  }),
  actionText: (disabled) => ({
    color: disabled ? color.palette.lightGray : color.palette.yellowIcon
  }),
  paginationContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    marginTop: '10px',
    padding: responsive ? '2%' : '0%',
    boxSizing: 'border-box',
    marginBottom: responsive ? '50px' : '20px'
  },
  maxWidth: { minWidth: '120px' },
  w37: { width: '37%' },
  collBox1: {
    ...customStyles.flexBox,
    justifyContent: 'start',
    padding: '15px 0'
  },
  collBox2: {
    ...customStyles.flexBox,
    justifyContent: 'start',
    padding: '0'
  },
  infoBtn: {
    border: 'none',
    marginLeft: '15px'
  },
  bNone: { border: 'none' }
};
