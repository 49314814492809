import { Box, List, ListItem, ListItemText } from '@mui/material';
import { Text } from '../text';
import { defaultValues } from '../../constants';

export const BulletListsWithTitle = ({ data, title, style, type }) => {
  const totalLanguages = data.length;
  const midpoint = Math.ceil(totalLanguages / 2);
  const rightSection = data.slice(0, midpoint);
  const leftSection = data.slice(midpoint);

  const defaultStyles = {
    title: { fontWeight: 550, marginBottom: '10px', marginTop: '10px' },
    container: { display: 'flex' },
    sections: { width: '50%' }
  };

  const styles = {
    ...defaultStyles,
    ...style
  };

  return (
    <Box>
      <Text style={styles.title} variant={defaultValues.isResponsive ? 'smallBold' : 'menuText'}>
        {title}
      </Text>
      <Box sx={styles.container}>
        <Box sx={styles.sections}>
          <List>
            {rightSection.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={`• ${item[type]}`} />
              </ListItem>
            ))}
          </List>
        </Box>
        {leftSection.length > 0 && (
          <Box sx={styles.sections}>
            <List>
              {leftSection.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`• ${item[type]}`} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </Box>
  );
};
