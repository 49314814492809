import { Box, Divider } from '@mui/material';
import { ManageAddServicesStyles } from './styles';
import {
  UIAutocompleted,
  UIButton,
  UICheckBoxTitle,
  UIChipButton,
  UISubTitleBar,
  UITextInputWithTitle
} from '../../../../ui-kit';
import { QuestionAdd } from '../../../question-add';
import {
  ADD_KEY,
  EDIT_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../../../constants';
import { useFormik } from 'formik';
import { finalList, otherSettingsServicesSchema, resetMessge } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  addServiceRequest,
  getLanguageRequest,
  pushServiceQaRequest,
  resetSettingsMessages,
  serviceRequest
} from '../../../../services/redux/slices';
import { useEffect, useState } from 'react';
import {
  currentPage,
  getLanguages,
  getServices,
  getSettingsErrorMessage,
  getSuccessMessageAddServices,
  settingLoadingStatus,
  totalPage
} from '../../../../services/redux/selectors';
import { toast } from 'react-toastify';
import { LoadingScreen } from '../../../../ui-kit/loading-screen';

export const ManageAddServices = ({ onBack, editServiceId }) => {
  const dispatch = useDispatch();
  const serviceSelector = useSelector(getServices);
  const selLanguages = useSelector(getLanguages);
  const isLoading = useSelector(settingLoadingStatus);
  const totalPageNo = useSelector(totalPage);
  const currPageNo = useSelector(currentPage);
  const successMessage = useSelector(getSuccessMessageAddServices);
  const errorMessage = useSelector(getSettingsErrorMessage);
  const [language, setLanguages] = useState([]);
  const [isBack, setIsBack] = useState(true);
  const [editSerData, setEditSerData] = useState(null);
  const [searchLanguage, setSearchLanguage] = useState('');

  let timer;

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    setFieldValue,
    touched,
    setFieldTouched,
    isValid,
    handleSubmit,
    setValues
  } = useFormik({
    initialValues: {
      title: editSerData ? editSerData.title : '',
      summary: editSerData ? editSerData.summary : '',
      pre_payment_required: editSerData ? editSerData.pre_payment_required : null,
      offered_languages: editSerData ? editSerData.offered_languages : [],
      questions: editSerData ? editSerData.questions : []
    },
    validationSchema: otherSettingsServicesSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {
      const data = {
        type: editSerData ? EDIT_KEY : ADD_KEY,
        payload: {
          ...(editSerData && editSerData),
          ...values,
          active: true
        }
      };
      editSerData && data.payload.questions && delete data.payload.questions;
      setIsBack(true);
      dispatch(addServiceRequest(data));
    }
  });

  useEffect(() => {
    const payload = {
      page: 1,
      filter: `[{ isActive: true }]`
    };
    if (searchLanguage !== '') {
      payload.search_text = searchLanguage;
    }
    getLanData(payload);
  }, [searchLanguage]);

  const getLanData = (page) => {
    if (page) {
      dispatch(getLanguageRequest(page));
    } else if (totalPageNo > currPageNo) {
      const pageNo = page ? page : currPageNo + 1;
      let payload = { page: pageNo, filter: `[{ isActive: true }]` };
      if (searchLanguage !== '') {
        payload.search_text = searchLanguage;
      }
      dispatch(getLanguageRequest(payload));
      clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      isBack && onBack();
      editServiceId &&
        dispatch(serviceRequest({ page: 1, search_text: `${editSerData.title.toLowerCase()}` }));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
      resetMessge(() => dispatch(resetSettingsMessages()));
    }
    dispatch(resetSettingsMessages());
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (serviceSelector && serviceSelector.services) {
      const serviceData = serviceSelector.services.filter((item) => item.id === editServiceId)[0];
      if (serviceData) {
        setValues({ ...serviceData });
        setEditSerData(serviceData);
      }
    }
  }, [serviceSelector, editServiceId]);

  useEffect(() => {
    if (editSerData && language && language.length > 0) {
      setFieldValue('offered_languages', editSerData.offered_languages);
    }
  }, [editSerData, language]);

  useEffect(() => {
    let uniqueMap = {};
    let activeLan = [...language].filter((item) => item.active === true);
    let allLanguage = [...activeLan, ...selLanguages];
    allLanguage = allLanguage.filter((item) => {
      const key = item.language_title;
      if (!uniqueMap[key]) {
        uniqueMap[key] = true;
        return true;
      }
      return false;
    });
    setLanguages(allLanguage);
  }, [selLanguages]);

  const updateQuestion = async (data, type) => {
    let payload = data;
    setIsBack(false);
    if (type === ADD_KEY) {
      payload.service_id = editSerData.id;
    }
    await dispatch(pushServiceQaRequest({ payload, type }));
  };

  const onAddLanguage = (value) => {
    setSearchLanguage('');
    const index = language.findIndex((item) => item.language_title === value);
    if (values.offered_languages.findIndex((item) => item.id === language[index].id) >= 0) {
      toast.error(getErrorMessage('E-10012'));
    } else {
      setFieldValue('offered_languages', [
        ...values.offered_languages,
        { id: language[index].id, language: language[index].language_title }
      ]);
    }
  };

  const idList = (arr) => {
    const arr2 = arr.map((language) => language.id);
    return arr2;
  };

  return (
    <div>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={ManageAddServicesStyles.container}>
        <UISubTitleBar
          title={editSerData ? defaultValues.ServiceEditTitle : defaultValues.ServiceAddTitle}
          onBack={onBack}
        />
        <Box sx={ManageAddServicesStyles.formBody}>
          <UITextInputWithTitle
            title="Service"
            id="title"
            name="title"
            placeholder="enter the service title"
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.title}
            touched={touched.title}
            value={values.title}
          />
          <UITextInputWithTitle
            title="Description(Summary?)"
            id="summary"
            name="summary"
            placeholder="type here..."
            lines={4}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.summary}
            touched={touched.summary}
            value={values.summary}
          />
          <UIAutocompleted
            title="Offered language(s)"
            options={finalList(idList(values.offered_languages), language).map(
              (item) => item.language_title
            )}
            placeholder="enter language"
            onAdd={onAddLanguage}
            name="offered_languages"
            id="offered_languages"
            error={errors.offered_languages}
            onBlur={handleBlur}
            touched={touched.offered_languages}
            onScrollEnd={() => getLanData()}
            onTyping={(val) => {
              clearTimeout(timer);
              timer = setTimeout(() => {
                if (val.length > 3) {
                  setSearchLanguage(val);
                } else setSearchLanguage('');
              }, 1000);
            }}
          />
          {values.offered_languages &&
            values.offered_languages.map((val, index) => (
              <UIChipButton
                title={val.language}
                key={index}
                onDelete={() => {
                  setFieldValue(
                    'offered_languages',
                    values.offered_languages.filter((item) => item.id !== val.id)
                  );
                }}
              />
            ))}
          <UICheckBoxTitle
            key="pre_payment_required"
            title="Pre-payment required"
            onCheck={(data) => setFieldValue('pre_payment_required', data)}
            values={defaultValues.prePaymentOptions}
            error={errors.pre_payment_required}
            touched={touched.pre_payment_required}
            checkedValue={
              values.pre_payment_required !== null &&
              defaultValues.prePaymentOptions.filter(
                (item) => item.value === values.pre_payment_required
              )[0].title
            }
          />
          {editSerData && (
            <UIButton
              type="submit"
              title="Submit"
              disabled={!isValid || values.title === ''}
              sx={ManageAddServicesStyles.editSubmitBtn}
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          )}
          <Divider />
          <QuestionAdd
            id="questions"
            name="questions"
            error={errors.questions}
            touched={touched.questions}
            editQuestionData={editSerData && editSerData.questions}
            onBlur={() => {
              setFieldTouched('pre_payment_required', true);
              setFieldTouched('questions', true);
            }}
            onChanged={(qData) => {
              editSerData ? updateQuestion(qData, ADD_KEY) : setFieldValue('questions', qData);
            }}
            onUpdateQuestion={(qData) => updateQuestion(qData, EDIT_KEY)}
            onAddQuestion={() => {}}
          />
          {!editSerData && (
            <UIButton
              type="submit"
              title="Submit"
              disabled={!isValid || values.title === ''}
              sx={ManageAddServicesStyles.submitBtn}
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          )}
        </Box>
      </Box>
    </div>
  );
};
