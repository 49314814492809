import { Box, IconButton, Tooltip, tooltipClasses } from '@mui/material';
import { Text } from '../text';
import { Styles } from './style';
import styled from '@emotion/styled';
import { color } from '../../theme';
import { defaultValues } from '../../constants';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    border: `1px solid ${color.primaryBorder}`,
    fontSize: 15
  }
}));

export const IconsButton = ({
  onClick,
  icon,
  title,
  iconStyle,
  toolTip,
  disabled = false,
  style
}) => {
  return (
    <Box sx={{ ...Styles.container(disabled), ...style }} onClick={onClick}>
      <LightTooltip title={toolTip}>
        <IconButton
          sx={{ ...Styles.toolsIcons, ...iconStyle, ...Styles.iconfontSize }}
          disabled={disabled}>
          {icon}
        </IconButton>
      </LightTooltip>
      {title && (
        <Text variant={defaultValues.isXSmall ? 'cardResSubTitle' : 'medium'}>{title}</Text>
      )}
    </Box>
  );
};
