import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Styles } from './style';
import { defaultValues } from '../../../constants';

export const BarCharts = ({ data }) => {
  return (
    <ResponsiveContainer width={Styles.size.containerWidth}>
      <BarChart data={data} margin={Styles.margin}>
        <XAxis dataKey="key" fontSize={defaultValues.isResponsive ? 8 : 12} interval={0} />
        <YAxis fontSize={defaultValues.isResponsive ? 8 : 12} />
        <Tooltip />
        <Legend layout="vertical" verticalAlign="top" align="right" />

        <Bar dataKey="total_suppliers" name="Available" fill={Styles.colors.yellow} />
        <Bar dataKey="engaged_suppliers" name="Engaged" fill={Styles.colors.red} />
      </BarChart>
    </ResponsiveContainer>
  );
};
