import { Box } from '@mui/material';
import { Text } from '../../../ui-kit';
import { color } from '../../../theme';

export const NoData = ({ message }) => {
  const styles = {
    container: {
      margin: '10px auto',
      height: '60vh',
      display: 'flex',
      alignItems: 'center',
      background: color.palette.disableColor,
      width: '100%'
    },
    text: { width: '100%', textAlign: 'center' }
  };
  return (
    <Box style={styles.container}>
      <Text variant="noData" style={styles.text}>
        {message}
      </Text>
    </Box>
  );
};
