import { React, useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { EbookCard, Footer, Goal } from '../../components';
import { styles } from './style';
import img from '../../assets/images/landingTopLeftImg.png';
import centerImg from '../../assets/icons/icon_part_four.png';
import logo from '../../assets/images/landingLogo.png';
import { Text, UIButton } from '../../ui-kit';
import { SCREEN_PATH, defaultValues } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from '../../services/redux/slices';
import { useNavigate } from 'react-router-dom';
import { getApprovedStatus } from '../../services/redux/selectors';

export const NonApprovedUsersLandingPage = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const approvedStatus = useSelector(getApprovedStatus);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (approvedStatus === null) {
      setMessage(defaultValues.nonApproved.message);
      setStatus(defaultValues.nonApproved.status);
    }
    if (approvedStatus === 0) {
      setMessage(defaultValues.nonApproved.rejectedMsg);
      setStatus(defaultValues.nonApproved.rejected);
    }
  }, [approvedStatus]);

  const onLogout = () => {
    dispatch(logoutRequest());
    dispatch({ type: 'USER_LOGOUT' });
    localStorage.clear();
    navigation(SCREEN_PATH.HOME);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.subContainer}>
        <Box sx={styles.firstSection}>
          <img src={img} style={styles.leftImg(defaultValues.isResponsive)} />
          <img src={logo} style={styles.logo(defaultValues.isResponsive)} />
          <UIButton
            title={'Logout'}
            style={styles.buttonStyle(defaultValues.isResponsive)}
            onClick={onLogout}
          />
          {!defaultValues.isResponsive && (
            <img src={centerImg} style={styles.rightImg(defaultValues.isResponsive)} />
          )}
        </Box>
        <Box sx={styles.msgContainer}>
          <Divider sx={styles.divider} />
          <Box sx={styles.details(defaultValues.isResponsive)}>
            <Text variant={defaultValues.isResponsive ? 'semiBold' : 'subHeading'}>{status}</Text>
            <Box sx={styles.warning}>
              <Text variant={defaultValues.isResponsive && 'responsiveText'}>{message}</Text>
            </Box>
          </Box>
          <Box sx={styles.query}>
            <Text variant={defaultValues.isResponsive && 'responsiveText'} style={styles.text}>
              {defaultValues.nonApproved.details}
            </Text>
          </Box>
        </Box>
        <Box sx={styles.secondaryContainer}>
          <Goal />
        </Box>
        <Box sx={styles.serviceContainer}>
          <EbookCard />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};
