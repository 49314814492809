import { Box, Stack, Tab } from '@mui/material';
import { CDStyles } from './styles';
import { customStyles } from '../../../theme';
import companyPic from '../../../assets/images/companyPic.png';
import {
  PopupModal,
  SearchBar,
  Text,
  UIBackButton,
  UIButton,
  UITextInputWithTitle
} from '../../../ui-kit';
import { Place } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import {
  ADD_KEY,
  ERROR_KEY,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../../constants';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { getPhotoUrl, resetMessge } from '../../../utils';
import { TeamMemberTable } from '../../common/team-memberTable';
import { AssociatedProjectTable } from '../../common/associated-project-table';
import { AssociatedSupplierTable } from '../../common/associated-supplier-table';
import { toast } from 'react-toastify';
import { CompanyNotes } from '../../common/company-notes';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyNoteRequest,
  pushCompanyNoteRequest,
  resetChatMessages,
  resetCompanyNoteMessages,
  updateCompanyDetailsTab
} from '../../../services/redux/slices';
import {
  cCompanyLoadingStatus,
  getCompDetailsTab,
  notesData
} from '../../../services/redux/selectors/customerCompanySelector';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { chatLoadingStatus, getChatErrorMessage } from '../../../services/redux/selectors';
import star from '../../../assets/icons/Star.png';

export const CustomerCompanyDetails = ({ onBack, cData }) => {
  const dispatch = useDispatch();
  const noteDataSel = useSelector(notesData);
  const isLoading = useSelector(cCompanyLoadingStatus);
  const chatErrorMsg = useSelector(getChatErrorMessage);
  const isChatLoading = useSelector(chatLoadingStatus);
  const tabValue = useSelector(getCompDetailsTab);

  const [address, setAddress] = useState('');
  const [photo, setPhoto] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [teamMemberLi, setTeamMemberLi] = useState([]);
  const [projectLi, setProjectLi] = useState([]);
  const [supplierLi, setSupplierLi] = useState([]);
  const [noteList, setNoteList] = useState([]);
  const [addNote, setAddNote] = useState('');
  const [showError, setShowError] = useState(false);
  const response = defaultValues.isResponsive;

  useEffect(() => {
    dispatch(getCompanyNoteRequest({ customer_company_id: cData.id }));
  }, []);

  useEffect(() => {
    if (noteDataSel && noteDataSel.status_code && noteDataSel.status === SUCCESS_KEY) {
      toast.success(getSuccessMessage(noteDataSel.status_code));
      setShowModel(false);
      setShowError(false);
    }
    if (noteDataSel && noteDataSel.status_code && noteDataSel.status === ERROR_KEY) {
      toast.error(getErrorMessage(noteDataSel.status_code));
    }
    if (chatErrorMsg) {
      toast.error(getErrorMessage(chatErrorMsg));
    }
    dispatch(resetCompanyNoteMessages());
    resetMessge(() => dispatch(resetChatMessages()));
  }, [noteDataSel, chatErrorMsg]);

  useEffect(() => {
    if (noteDataSel && noteDataSel.notes) {
      setNoteList(noteDataSel.notes);
    } else {
      setNoteList([]);
    }
  }, [noteDataSel]);

  useEffect(() => {
    let addr = [
      cData.company_city,
      cData.company_city,
      cData.company_locality,
      cData.company_state,
      cData.company_country,
      cData.company_post_code
    ];
    addr = addr.filter((item) => item !== null);
    if (cData.company_time_zone && cData.company_time_zone.time_zone) {
      addr = [...addr, `( ${cData.company_time_zone.time_zone} )`];
    }
    setAddress(addr.join(', '));
    if (cData.members) setTeamMemberLi(cData.members);
    if (cData.projects) setProjectLi(cData.projects);
    if (cData.suppliers) setSupplierLi(cData.suppliers);
    if (cData.company_logo_url) setPhoto(cData.company_logo_url);
  }, [cData]);

  const handelSearch = (key, type) => {
    if (type === 'team-member') {
      const filterData =
        key === ''
          ? cData.members
          : cData.members.filter((item) =>
              (item.first_name + ' ' + item.last_name).toLowerCase().includes(key.toLowerCase())
            );
      if (filterData.length > 0) {
        setTeamMemberLi(filterData);
      } else if (key) {
        toast.error('Team member Not found');
      }
    } else if (type === 'project') {
      const filterData =
        key === ''
          ? cData.projects
          : cData.projects.filter((item) =>
              item.project_title.toLowerCase().includes(key.toLowerCase())
            );
      if (filterData.length > 0) {
        setProjectLi(filterData);
      } else if (key) {
        toast.error('Project Not found');
      }
    } else if (type === 'supplier') {
      const filterData =
        key === ''
          ? cData.suppliers
          : cData.suppliers.filter((item) =>
              (item.first_name + ' ' + item.last_name).toLowerCase().includes(key.toLowerCase())
            );
      if (filterData.length > 0) {
        setSupplierLi(filterData);
      } else if (key) {
        toast.error('Supplier Not found');
      }
    }
  };

  const onAddNote = () => {
    const payload = {
      customer_company_id: cData.id,
      note: addNote
    };
    dispatch(pushCompanyNoteRequest({ payload, type: ADD_KEY }));
  };

  const onChangeNote = useCallback(
    (value) => {
      setAddNote(value);
      setShowError(value.length > 0 || value !== '' ? false : true);
    },
    [showError]
  );

  return (
    <Box sx={CDStyles.container}>
      <LoadingScreen isLoading={isChatLoading || isLoading} />
      {response && (
        <Box sx={{ ...customStyles.flexBox, justifyContent: 'space-between' }}>
          <UIBackButton onClick={onBack} />
          <UIButton
            size="small"
            style={{ marginRight: '10px' }}
            title="+ new note"
            onClick={() => {
              setShowModel(true);
              setAddNote('');
              setShowError(false);
            }}
          />
        </Box>
      )}
      <Box sx={CDStyles.contentContainer}>
        {!response && <UIBackButton onClick={onBack} />}

        <Box sx={CDStyles.logoBox}>
          <img
            style={CDStyles.logoSize}
            src={photo ? getPhotoUrl(cData.company_logo_url) : companyPic}
          />
        </Box>
        <Box sx={CDStyles.infoBox}>
          <Text style={CDStyles.comName}>{cData.company_name}</Text>
          <Text variant="small" style={CDStyles.regNo}>
            Registration number - {cData.registration_no}
          </Text>

          <Stack sx={CDStyles.stackStyle}>
            {cData.rating && <img src={star} style={CDStyles.imgStyle} />}
            {cData.rating && (
              <Text variant={defaultValues.isResponsive ? 'cardTitle' : 'answer'}>
                {cData.rating.toFixed(1)}
              </Text>
            )}
          </Stack>

          <Text variant="small" style={CDStyles.comType}>
            Company Type - {cData.company_type}
          </Text>

          {!response && (
            <>
              <br />
              {address && (
                <Box sx={{ ...customStyles.flexBox, justifyContent: 'start' }}>
                  <Place style={CDStyles.ml} />
                  <Text variant="small">{address}</Text>
                </Box>
              )}
              <br />
              {cData.company_description && (
                <Text style={customStyles.wordBr} variant="medium">
                  {cData.company_description}
                </Text>
              )}
            </>
          )}
        </Box>
        {!response && (
          <UIButton
            style={{ marginRight: '10px' }}
            title="+ new note"
            onClick={() => {
              setShowModel(true);
              setAddNote('');
              setShowError(false);
            }}
          />
        )}
      </Box>
      {response && (
        <Box sx={{ padding: '0 20px' }}>
          {address && <br />}
          {address && (
            <Box sx={{ ...customStyles.flexBox, justifyContent: 'start' }}>
              <Place style={CDStyles.ml} />
              <Text variant="small" style={CDStyles.comType}>
                {address}
              </Text>
            </Box>
          )}
          {cData.company_description && <br />}
          {cData.company_description && (
            <Text variant="medium" style={CDStyles.comType}>
              <b>Company Description</b> - {cData.company_description}
            </Text>
          )}
        </Box>
      )}
      <Box sx={CDStyles.memberMain}>
        <TabContext value={tabValue}>
          <Box sx={CDStyles.tabContainer}>
            <TabList
              value={tabValue}
              textColor="none"
              TabIndicatorProps={{ sx: CDStyles.tabIndicator }}
              onChange={(e, newValue) => {
                dispatch(updateCompanyDetailsTab(newValue));
              }}
              variant="scrollable">
              {defaultValues.companyDetailsTabs.map((item, index) => (
                <Tab
                  key={index}
                  sx={CDStyles.tab(item.value === tabValue)}
                  label={item.heading}
                  value={item.value}
                />
              ))}
            </TabList>
          </Box>
          <Box>
            <TabPanel value={defaultValues.companyDetailsTabs[0].value}>
              <Box>
                <SearchBar
                  label="search for a member"
                  searchedVlaue={(key) => handelSearch(key, 'team-member')}
                />
                {teamMemberLi.length > 0 && <TeamMemberTable listData={teamMemberLi} />}
              </Box>
            </TabPanel>
            <TabPanel value={defaultValues.companyDetailsTabs[1].value}>
              <SearchBar
                label="search for a project"
                searchedVlaue={(key) => handelSearch(key, 'project')}
              />
              {projectLi.length > 0 && <AssociatedProjectTable listData={projectLi} />}
            </TabPanel>
            <TabPanel value={defaultValues.companyDetailsTabs[2].value}>
              <SearchBar
                label="search for a supplier"
                searchedVlaue={(key) => handelSearch(key, 'supplier')}
              />
              {supplierLi.length > 0 && <AssociatedSupplierTable listData={supplierLi} />}
            </TabPanel>
            <TabPanel value={defaultValues.companyDetailsTabs[3].value}>
              <CompanyNotes listData={noteList} />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
      <PopupModal
        showModel={showModel}
        onClose={() => setShowModel(false)}
        heading="Add new note"
        titlePosition={true}
        showDivider={true}>
        <Box sx={CDStyles.modelPop}>
          <UITextInputWithTitle
            title="Note"
            placeholder="enter note here"
            lines={4}
            value={addNote}
            touched={showError}
            error={getErrorMessage('E-10097')}
            onBlur={() => !addNote.length && setShowError(true)}
            onChange={(e) => onChangeNote(e.target.value)}
          />
          <br />
          <UIButton title="Save" onClick={onAddNote} disabled={showError} />
        </Box>
      </PopupModal>
    </Box>
  );
};
