/* eslint-disable no-unused-vars */
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { defaultValues } from '../../constants';

const res = defaultValues.isResponsive;
export const DateCalendarPicker = ({ handleCalender }) => {
  const styles = {
    input: {
      '& .MuiPickersCalendarHeader-root': {
        paddingRight: '18px',
        paddingLeft: res ? '12px' : '35px'
      },
      '& .MuiPickersCalendarHeader-label': {
        fontSize: res && '12px'
      },
      width: res ? '240px' : '320px'
    }
  };
  const today = dayjs();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar onChange={handleCalender} defaultValue={today} disablePast sx={styles.input} />
    </LocalizationProvider>
  );
};
