import {
  Filter,
  IconsButton,
  MasterTable,
  PaginationCustom,
  SearchBar,
  Sort,
  Text,
  UIButton
} from '../../../ui-kit';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { CollapsableList, OtherSettingsModal } from '../..';
import { useFormik } from 'formik';
import { otherSettingsCountrySchema } from '../../../utils/schemas';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCountriesRequest,
  editCountriesRequest,
  getCountriesRequest,
  resetSettingsMessages
} from '../../../services/redux/slices';
import {
  currentPage,
  getCountries,
  getSettingsErrorMessage,
  getSettingsIndicator,
  getSettingsSuccessMessage,
  settingLoadingStatus,
  totalPage
} from '../../../services/redux/selectors';
import { countriesStyles } from './style';
import RefreshIcon from '@mui/icons-material/Refresh';
import { resetMessge } from '../../../utils';
import { toast } from 'react-toastify';
import { CountriesDetails } from './countries-details';
import { LoadingScreen } from '../../../ui-kit/loading-screen';

export const Countries = () => {
  const dispatch = useDispatch();
  const allCountries = useSelector(getCountries);
  const pageCount = useSelector(totalPage);
  const currentRenderPage = useSelector(currentPage);
  const successMessage = useSelector(getSettingsSuccessMessage);
  const errorMessage = useSelector(getSettingsErrorMessage);
  const isLoading = useSelector(settingLoadingStatus);
  const indicator = useSelector(getSettingsIndicator);

  const [countries, setCountries] = useState([]);
  const [modelType, setModelType] = useState(null);
  const [sortValue, setSortValue] = useState({});
  const [filterValue, setFilterValuer] = useState({});
  const [checked, setChecked] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [pageNo, setPageNo] = useState(null);

  useEffect(() => {
    handleCountries();
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetSettingsMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    setCountries(allCountries);
  }, [allCountries]);

  useEffect(() => {
    if (!isLoading && successMessage) {
      handleClose();
    }
  }, [isLoading]);

  useEffect(() => {
    if (indicator) {
      handleCountries();
    }
  }, [indicator]);

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldError,
    setFieldTouched,
    setFieldValue
  } = useFormik({
    initialValues: {
      country: '',
      state: '',
      stateList: [],
      id: ''
    },
    validationSchema: otherSettingsCountrySchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  const handleClose = () => {
    setModelType(null);
    setFieldValue('id', '');
    setFieldValue('country', '');
    setFieldValue('state', '');
    setFieldValue('stateList', []);
    setFieldTouched('country', false);
    setFieldTouched('state', '');
    setChecked(false);
  };

  const handleSaveCountry = async () => {
    let payload = { country_name: values.country };
    if (values.id === '') {
      if (values.stateList.length > 0) {
        payload.states = values.stateList;
      }
      dispatch(addCountriesRequest(payload));
    } else {
      payload.country_id = values.id;
      dispatch(editCountriesRequest(payload));
    }
  };

  const handleEditCountry = async (data, editData) => {
    const payload = {
      country_id: data.id,
      country_name: data.country_name,
      active: data.active,
      ...editData
    };
    dispatch(editCountriesRequest(payload));
  };

  const handleCountries = (data) => {
    let payload = {
      page: pageNo || 1,
      ...sortValue,
      ...filterValue,
      ...data
    };
    dispatch(getCountriesRequest(payload));
  };

  const handleSort = (sort) => {
    setSortValue({ sort });
    handleCountries({ sort });
  };

  const handleFilter = (value) => {
    setFilterValuer({ filter: `[{ isActive: ${value} }]` });
    handleCountries({ filter: `[{ isActive: ${value} }]` });
  };

  const handleRefresh = () => {
    setSortValue({});
    setFilterValuer({});
    setPageNo(1);
    handleCountries({ sort: null, filter: null, page: 1 });
  };

  const handleSearch = (search_text) => {
    if (search_text.length === 0 || search_text.length >= 2) {
      handleCountries({ search_text });
    }
  };

  const handleStateChecked = (value) => {
    setChecked(value);
  };

  const handleAddState = () => {
    setFieldValue('stateList', [...values.stateList, values.state]);
    setFieldValue('state', '');
    setFieldTouched('state', false);
  };

  const handlePageChange = (e, value) => {
    const payload = {
      page: value,
      ...sortValue,
      ...filterValue
    };
    setPageNo(value);
    dispatch(getCountriesRequest(payload));
  };

  const handleStateDelete = (start, end) => {
    let updatedStateList = [...values.stateList];
    updatedStateList.splice(start, end);
    setFieldValue('stateList', updatedStateList);
  };

  const handleEdit = (item) => {
    setModelType(defaultValues.countriesModalHeadingEdit);
    setFieldValue('country', item.country_name);
    setFieldValue('id', item.id);
  };

  const handleDisable = () => {
    if (values.id === '') {
      return (
        errors.country ||
        !values.country ||
        (checked && !values.stateList.length && errors.state) ||
        (checked && !values.stateList.length)
      );
    } else {
      return errors.country === undefined ? false : true;
    }
  };

  return (
    <Box sx={countriesStyles.container}>
      <LoadingScreen isLoading={isLoading} />

      {detailsData ? (
        <CountriesDetails
          onEditClick={() => {
            setModelType(defaultValues.countriesModalHeadingEdit);
          }}
          onBack={() => setDetailsData(null)}
          detailData={detailsData}
        />
      ) : (
        <Box sx={countriesStyles.contentContainer}>
          <Box sx={countriesStyles.searchTabContainer}>
            <Box sx={countriesStyles.searchToolsContainer}>
              {!defaultValues.isResponsive ? (
                <SearchBar label={'Search countries'} searchedVlaue={handleSearch} />
              ) : (
                <Text sx={countriesStyles.headingText} variant="medium">
                  Current Countries
                </Text>
              )}
              <UIButton
                style={countriesStyles.newButton}
                title="+ new country"
                onClick={() => {
                  handleClose();
                  setModelType(defaultValues.countriesModalHeadingAdd);
                }}
              />
            </Box>

            <Box sx={countriesStyles.toolsContainer}>
              <IconsButton
                title={!defaultValues.isResponsive ? null : 'Refresh'}
                icon={<RefreshIcon sx={countriesStyles.icon} />}
                onClick={handleRefresh}
              />
              <Sort selectedOption={handleSort} activeOption={sortValue.sort} />
              <Filter label={'Filter'} selectedOption={handleFilter} />
            </Box>
          </Box>

          {!defaultValues.isResponsive ? (
            <Text sx={countriesStyles.heading} variant="fileName">
              {defaultValues.otherSettingsCountriesHeading}
            </Text>
          ) : (
            <Box sx={countriesStyles.searchContainer}>
              <SearchBar label={'Search countries'} searchedVlaue={handleSearch} />
            </Box>
          )}

          {!defaultValues.isResponsive ? (
            <MasterTable
              columnName="country_name"
              data={countries}
              title="Country"
              notFoundMessage={getErrorMessage('E-10059')}
              onChangeState={(data) => handleEditCountry(data, { active: !data.active })}
              OnViewDetails={(data) => setDetailsData(data)}
              onEdit={handleEdit}
            />
          ) : (
            <Box sx={countriesStyles.collapseContainer}>
              <CollapsableList
                onButtonClick={(x, y, z, data) => handleEditCountry(data, { active: !data.active })}
                contentArray={countries}
                type="countries"
                onEditClick={handleEdit}
                onTitleClick={(data) => setDetailsData(data)}
              />
            </Box>
          )}

          {currentRenderPage && pageCount && (
            <Box sx={countriesStyles.paginationContainer}>
              <PaginationCustom
                onChange={handlePageChange}
                currentPage={currentRenderPage}
                count={pageCount}
              />
            </Box>
          )}
        </Box>
      )}
      <OtherSettingsModal
        open={modelType !== null}
        close={handleClose}
        heading={modelType}
        placeholder1="Enter country name"
        placeholder2="Enter State name"
        inputTitle1="Country"
        inputValue1={values.country}
        inputValue2={values.state}
        inputName1={'country'}
        inputName2={'state'}
        isEditing={values.id !== ''}
        buttonTitle={values.id === '' ? 'Save' : 'Save changes'}
        onChange={handleChange}
        error={errors}
        onBlur={handleBlur}
        setFieldTouched={setFieldTouched}
        setFieldError={setFieldError}
        touched={touched}
        onButtonClick={handleSaveCountry}
        type="country"
        disabled={handleDisable()}
        addState={handleAddState}
        stateList={values.stateList}
        onStateDelete={handleStateDelete}
        checked={checked}
        onStateChecked={handleStateChecked}
        isLoading={isLoading}
      />
    </Box>
  );
};
