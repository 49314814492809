import { useDispatch, useSelector } from 'react-redux';
import {
  chatLoadingStatus,
  getChatErrorMessage,
  getChatMessages,
  getChatNextpage,
  getChatSuccessMessage,
  getChatThreads,
  getMsgCurrPage,
  getMsgNextPage,
  getUserId,
  projectMembers
} from '../../../services/redux/selectors';
import { useEffect, useState } from 'react';
import {
  clearMessages,
  clearThreads,
  getProjectMembers,
  getProjectMessagesRequest,
  getProjectThreadsRequest,
  initiateProjectChatRequest,
  projectConversationRequest,
  resetChatMessages,
  unreadStatusRequest
} from '../../../services/redux/slices';
import { resetMessge } from '../../../utils';
import { Screen, Text, UIButton } from '../../../ui-kit';
import { defaultValues, getErrorMessage } from '../../../constants';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { Inbox } from '../../../components';
import { Box } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { PRChatStyles } from './styles';
import { customStyles } from '../../../theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ProjectChatPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currMsgPage = useSelector(getMsgCurrPage);
  const nextMsgPage = useSelector(getMsgNextPage);
  const threads = useSelector(getChatThreads);
  const chatNxtPage = useSelector(getChatNextpage);
  const allMessages = useSelector(getChatMessages);
  const chatSuccessMessage = useSelector(getChatSuccessMessage);
  const chatErrorMessage = useSelector(getChatErrorMessage);
  const allprojectMembers = useSelector(projectMembers);
  const userId = useSelector(getUserId);

  const isLoading = useSelector(chatLoadingStatus);

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [proCurrThread, setProCurrThread] = useState(null);
  const [threadList, setThreadList] = useState([]);
  const [details, setDetails] = useState({});
  const [showChat, setShowChat] = useState(false);
  const [addPeople, setaddPeople] = useState(false);
  const [addedMembers, setAddedmembers] = useState([]);
  const [selThread, setSelThread] = useState(null);

  useEffect(() => {
    if (location.state) {
      setDetails(location.state.data);
      handleThreads();
      associatedProjectMembers();
    }
  }, [location.state]);

  useEffect(() => {
    dispatch(clearMessages());

    return () => {
      dispatch(clearMessages());
      dispatch(clearThreads());
    };
  }, []);

  useEffect(() => {
    if (addedMembers.length > 0) {
      dispatch(clearMessages());
    }
  }, [addedMembers]);

  useEffect(() => {
    if (chatSuccessMessage === 'S-10113' || chatSuccessMessage === 'S-10112') {
      if (chatSuccessMessage === 'S-10112') {
        setSelThread(0);
      }
      dispatch(clearThreads());
      handleThreads();
    }
    if (chatErrorMessage !== 'E-10123') {
      toast.error(getErrorMessage(chatErrorMessage));
      resetMessge(() => dispatch(resetChatMessages()));
    }
  }, [chatErrorMessage, chatSuccessMessage]);

  useEffect(() => {
    if (threads && threads.length > 0) {
      setThreadList(threads);
    }
  }, [threads]);

  useEffect(() => {
    if (proCurrThread && proCurrThread.unread_messages) {
      setTimeout(() => {
        dispatch(
          unreadStatusRequest({
            thread_id: proCurrThread.thread_id
          })
        );
      }, 5000);
    }
  }, [proCurrThread]);

  useEffect(() => {
    if (chatNxtPage && chatNxtPage !== -1) {
      handleThreads(chatNxtPage);
    }
    if (chatNxtPage === -1) {
      if (!proCurrThread) {
        setProCurrThread(threads[0]);
      } else {
        const Th = threads.find((item) => item.thread_id === proCurrThread.thread_id);
        setProCurrThread(Th);
      }
      handleGetMessages();
    }
  }, [chatNxtPage]);

  const handleThreads = (page) => {
    let payload = { page: page || 1, project_id: location.state.data.id };
    if (page !== -1) {
      dispatch(getProjectThreadsRequest(payload));
    }
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleGetMessages = (item, page) => {
    let payload = {
      thread_id:
        (item && item.thread_id) ||
        (proCurrThread && proCurrThread.thread_id) ||
        (threads && threads.length > 0 && threads[0].thread_id),
      page: page || currMsgPage
    };
    if (page !== -1 && payload.thread_id) {
      dispatch(getProjectMessagesRequest(payload));
    }
  };

  const handleSendMessage = (thread_id) => {
    let payload = {};
    if (message) {
      payload = { ...payload, message: message };
    }
    if (file) {
      payload = { ...payload, attachment: file };
    }
    if (addPeople) {
      let memIds = [...addedMembers].map((item) => item.id);
      payload = {
        ...payload,
        project_id: details.id,
        send_to: `[${memIds}]`
      };
      dispatch(initiateProjectChatRequest(payload));
      dispatch(clearMessages());
      setProCurrThread(null);
    } else {
      payload = {
        ...payload,
        thread_id: thread_id || (threads && threads.length > 0 && threads[0].thread_id)
      };

      dispatch(projectConversationRequest(payload));
    }

    setMessage('');
    setFile(null);
    setAddedmembers([]);
    setaddPeople(false);
  };

  const handleMessageChange = (val) => {
    setMessage(val);
  };

  const handleThreadChange = (item, idx) => {
    setProCurrThread(item);
    dispatch(clearMessages());
    handleGetMessages(item, 1);
    setFile(null);
    setMessage('');
    setSelThread(idx);
  };

  const handleFileDelete = () => {
    setFile(null);
  };

  const handleRefresh = () => {
    dispatch(clearMessages());
    dispatch(clearThreads());
    handleThreads();
    setaddPeople(false);
  };

  const associatedProjectMembers = () => {
    dispatch(getProjectMembers({ project_id: location.state.data.id }));
  };

  const handleAddPeople = (val) => {
    setaddPeople(val);
  };

  const handleAddmembers = (val) => {
    const list = [val, ...addedMembers];
    const temp = list.filter((item, index, self) => {
      return index === self.findIndex((s) => s.id === item.id);
    });

    setAddedmembers(temp);
  };

  const handleMemerDelete = (idx) => {
    let members = [...addedMembers];
    members.splice(idx, 1);
    setAddedmembers(members);
  };

  const rmCurrUser = (members, userId) => {
    return members.filter((item) => item.id !== userId);
  };

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive && true}
      currentPage="Chat">
      <LoadingScreen isLoading={isLoading} />
      <Box sx={PRChatStyles.container}>
        <Box sx={PRChatStyles.contentContainer}>
          <Box sx={{ ...PRChatStyles.navContainer, ...PRChatStyles.marBot }}>
            <Box sx={PRChatStyles.navContainer}>
              <Box sx={customStyles.flexBox}>
                <Text style={PRChatStyles.navText} onClick={() => navigate(-1)}>
                  {details && details.project_name}
                </Text>
                {!defaultValues.isResponsive && <ArrowForwardIos sx={PRChatStyles.icon} />}
              </Box>

              <Text style={PRChatStyles.navIdleText}>Chat</Text>
            </Box>
          </Box>
          {(threadList && threadList.length > 0) || showChat ? (
            <Inbox
              handleFileChange={handleFileChange}
              file={file}
              handleSendMessage={handleSendMessage}
              message={message}
              handleMessageChange={handleMessageChange}
              threads={threadList}
              messages={allMessages}
              handleThreadChange={handleThreadChange}
              handleFileDelete={handleFileDelete}
              handleGetMessages={() => handleGetMessages(proCurrThread, nextMsgPage)}
              currThread={proCurrThread}
              onRefresh={handleRefresh}
              type={defaultValues.listConstants.project_chat}
              proMembers={rmCurrUser(allprojectMembers, userId)}
              addPeople={addPeople}
              handleAddPeople={(val) => handleAddPeople(val)}
              handleAddmembers={(val) => handleAddmembers(val)}
              addedMembers={addedMembers}
              handleMemerDelete={handleMemerDelete}
              selThread={selThread}
            />
          ) : (
            <Box sx={PRChatStyles.nofoundContainer}>
              <Text style={PRChatStyles.chatText}>Click below to start a new chat</Text>
              <UIButton
                style={PRChatStyles.newBtn}
                title="+ new"
                onClick={() => {
                  setShowChat(true);
                  setaddPeople(true);
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Screen>
  );
};
