import { Box } from '@mui/material';
import { TextInput, UIButton } from '../../../ui-kit';
import { defaultValues } from '../../../constants';
import profilePic from '../../../assets/images/profilePic.png';
import { color } from '../../../theme';
import { userInfo } from '../../../services/redux/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getPhotoUrl } from '../../../utils';

export const Comments = ({ onChange, onClick, showBtn, value }) => {
  const userData = useSelector(userInfo);

  const [profilePicture, setProfilePicture] = useState('');

  useEffect(() => {
    if (userData && userData.photo_url) {
      let url = getPhotoUrl(userData.photo_url);
      setProfilePicture(url);
    }
  }, [userData]);
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      p: 1,
      borderBottom: `1px solid ${color.primaryBorder}`,
      mb: 2
    },
    imgContainer: {
      width: defaultValues.isResponsive ? '25px' : '45px',
      height: defaultValues.isResponsive ? '25px' : '45px',
      borderRadius: '50%',
      marginRight: '10px'
    },
    imgStyle: {
      width: '100%',
      height: '100%',
      borderRadius: '50%'
    },
    btn: { width: '100px', fontSize: defaultValues.isResponsive ? 8 : 16 }
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.imgContainer}>
        <img style={styles.imgStyle} src={profilePicture ? profilePicture : profilePic} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <TextInput
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            style: { fontSize: defaultValues.isResponsive ? 10 : 20 }
          }}
          value={value}
          onChange={onChange}
          placeholder="Add your comments here"
        />
      </Box>
      {showBtn && <UIButton title={'submit'} style={styles.btn} onClick={onClick} />}
    </Box>
  );
};
