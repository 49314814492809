import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const responsive = defaultValues.isResponsive;
export const styles = {
  container: {
    width: '100%',
    minHeight: 'auto',
    backgroundColor: color.secondaryBackground,
    paddingBottom: '20px'
  },
  subContainer: {
    backgroundColor: color.primaryBackground,
    m: 2,
    borderRadius: 1,
    paddingTop: '20px'
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    p: 1
  },
  details: {
    width: '100%',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    mb: '50px',
    p: 2,
    boxSizing: 'border-box',
    backgroundColor: color.primaryBackground
  },
  sub: {
    width: '100%',
    height: '80%',
    mt: 2,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: 1,
    px: responsive ? 2 : 4,
    py: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  searchTabContainer: {
    width: '100%',
    height: responsive ? 'auto' : '60px',
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    alignItems: 'center',
    padding: '5px 0px',
    boxSizing: 'border-box',
    pl: 3
  },
  searchToolsContainer: {
    width: responsive ? '100%' : '80%',
    height: '100%',
    display: 'flex',
    justifyContent: responsive ? 'space-between' : 'none',
    alignItems: responsive ? 'center' : 'none',
    paddingBottom: responsive ? '8px' : '0px',
    paddingTop: responsive ? '6px' : '0px',
    borderBottom: responsive ? `1px solid ${color.primaryBorder}` : 'none'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: responsive ? '250px' : '340px'
  },
  primaryBtn: {
    fontSize: responsive ? '10px' : '14px',
    height: responsive ? '24px' : '40px',
    width: responsive ? '120px' : '165px'
  },
  secBtn: {
    fontSize: responsive ? '10px' : '14px',
    height: responsive ? '24px' : '40px',
    width: responsive ? '120px' : '165px',
    backgroundColor: color.primaryBackground,
    color: color.primaryText,
    border: `1px solid ${color.primaryText}`
  },
  text: { fontWeight: 600 },
  desc: { color: color.palette.emailGray },
  title: { fontWeight: 600, textAlign: 'center', p: 2 },
  nodata: { fontWeight: 600, ml: 2, p: 2, color: color.palette.red },
  topNav: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: responsive ? 'column' : 'row',
    justifyContent: 'center',
    margin: '15px 0px',
    mb: !responsive && '25px'
  },
  icon: { fontSize: '20px', marginX: '10px' },
  navLink: {
    cursor: 'pointer',
    color: color.palette.linkBlue,
    textDecoration: 'underline'
  },
  contentWidth: '98%',
  noFoundBoxSuper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    boxSizing: 'border-box'
  },
  noFoundBox: {
    width: '100%',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.secondaryBackground,
    marginTop: '10px'
  },
  noFoundText: {
    color: color.palette.primaryRed,
    fontWeight: 600
  }
};
