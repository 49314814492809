import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import {
  SCREEN_PATH,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../../../constants';
import { IconsButton, PopupModal, SearchBar, Sort, Text } from '../../../../ui-kit';
import RefreshIcon from '@mui/icons-material/Refresh';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  AdminProfile,
  CollapsableList,
  NoData,
  ProjectStatus,
  Title,
  UpdateEventTime
} from '../../../../components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { customStyles } from '../../../../theme/styles';
import { projectsStyles } from './style';
import { getFormatDateNotUtc, resetMessge, truncateText } from '../../../../utils';
import { LoadingScreen } from '../../../../ui-kit/loading-screen';
import {
  getAdminActiveProjects,
  projectStatusRequest,
  resetAdminMessages,
  setSelectedTab
} from '../../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveProjects,
  getAdminSuccessMessage,
  getProjectsStatus
} from '../../../../services/redux/selectors';
import { toast } from 'react-toastify';

export const ActiveAdminProjectsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statuses = useSelector(getProjectsStatus);
  const activeProjects = useSelector(getActiveProjects);
  const success = useSelector(getAdminSuccessMessage);

  const [option, setOption] = useState('today');
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortValue, setSortValue] = useState({});
  const [filter, setFilter] = useState([{ event_time: 'today' }]);
  const [open, setOpen] = useState(false);
  const [eventTime, setEventTime] = useState('');
  const [eventZone, setEventZone] = useState('');
  const [id, setId] = useState('');

  const handleProjects = useCallback((data) => {
    let payload = {
      filter: JSON.stringify(filter)
    };
    if (data !== undefined) {
      payload = {
        ...payload,
        ...data
      };
    }
    dispatch(getAdminActiveProjects(payload));
  }, []);

  const handleFilter = (e) => {
    const selectedValue = e.target.value;
    setOption(selectedValue);
    setSortValue({});
    setFilter([{ event_time: selectedValue }]);
    handleProjects({ filter: JSON.stringify([{ event_time: selectedValue }]) });
  };

  useEffect(() => {
    dispatch(projectStatusRequest());
    handleProjects();
  }, []);

  useEffect(() => {
    if (activeProjects) {
      setProjects(activeProjects);
    }
  }, [activeProjects]);

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(success));
      handleProjects({ filter: JSON.stringify([{ event_time: option }]) });
    }
    resetMessge(() => dispatch(resetAdminMessages()));
  }, [success]);

  const handleSearch = (searchData) => {
    if (searchData.length > 2) {
      setSortValue({ search_text: searchData });
      setIsLoading(true);
      setTimeout(() => {
        const filteredProjects = projects.filter((project) => {
          const searchTerm = searchData.toLowerCase();
          const projectName = project.name.toLowerCase();
          return projectName.includes(searchTerm);
        });
        setProjects(filteredProjects);
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleRefresh = () => {
    setOption('today');
    setSortValue({});
    setFilter([{ event_time: 'today' }]);
    handleProjects({ filter: JSON.stringify([{ event_time: 'today' }]) });
  };

  const handleEditTime = (id, data, timezone) => {
    setOpen(true);
    setEventTime(data);
    setEventZone(timezone);
    setId(id);
  };

  return (
    <Box sx={projectsStyles.container}>
      <LoadingScreen isLoading={isLoading} />
      {!defaultValues.isResponsive && (
        <Text variant={'subTitle'} style={projectsStyles.mLeft}>
          Active projects
        </Text>
      )}
      <Box sx={projectsStyles.contentContainer}>
        <Box sx={projectsStyles.searchTabContainer}>
          <Box sx={projectsStyles.searchToolsContainer}>
            {!defaultValues.isResponsive ? (
              <SearchBar
                label={'search by project name or id'}
                searchedVlaue={handleSearch}
                onRefresh={!sortValue.search_text && {}}
                defaultValue={sortValue.search_text}
              />
            ) : (
              projects.length > 0 && (
                <Text sx={projectsStyles.headingText} variant="medium">
                  Active Projects
                </Text>
              )
            )}
          </Box>

          <Box sx={projectsStyles.toolsContainer}>
            <IconsButton
              title={
                !defaultValues.isResponsive ? null : defaultValues.isMdSmall ? null : 'Refresh'
              }
              icon={<RefreshIcon sx={projectsStyles.icon} />}
              onClick={handleRefresh}
            />
            <Sort
              selectedOption={(sort) => handleProjects({ sort, filter: JSON.stringify(filter) })}
              activeOption={sortValue}
            />

            <FormControl sx={projectsStyles.fmStyle} size="small">
              <Select value={option} onChange={handleFilter}>
                {defaultValues.activeProjects.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {defaultValues.isResponsive && projects.length > 0 && (
          <Box sx={projectsStyles.searchContainer}>
            <SearchBar
              label={'search by project name or id'}
              searchedVlaue={handleSearch}
              onRefresh={!sortValue.search_text && {}}
              defaultValue={sortValue.search_text}
            />
          </Box>
        )}

        {!defaultValues.isResponsive ? (
          projects.length > 0 ? (
            <Table sx={projectsStyles.tableContainer} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={projectsStyles.headingCell} align="left">
                    Project name
                  </TableCell>
                  <TableCell sx={projectsStyles.headingCell} align="left">
                    Supplier
                  </TableCell>
                  <TableCell sx={projectsStyles.headingCell} align="left">
                    Event time
                  </TableCell>
                  <TableCell sx={projectsStyles.headingCell} align="left">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects &&
                  projects.map((row, index) => (
                    <TableRow key={index} sx={projectsStyles.tableRow}>
                      <TableCell
                        sx={projectsStyles.headingCell350}
                        component="th"
                        scope="row"
                        align="left">
                        <Title
                          sx={{ ...customStyles.link }}
                          title={truncateText(row.name, 25)}
                          onClick={() => {
                            dispatch(setSelectedTab(2));
                            navigate(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                              state: { data: row }
                            });
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Box sx={projectsStyles.prContainer}>
                          <AdminProfile profile={row.supplier[0] && row.supplier[0]} />
                          {row.supplier.length > 1 && (
                            <Text style={projectsStyles.mR}>+{row.supplier.length} more</Text>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box sx={projectsStyles.eventContainer}>
                          <Box>
                            <Title
                              title={getFormatDateNotUtc(row.event_time, 'DD-MM-YYYY hh:mm A')}
                            />
                            <Title sx={projectsStyles.timeZoneStyle} title={row.timezone.name} />
                          </Box>
                          <ModeEditIcon
                            sx={projectsStyles.edit}
                            onClick={() =>
                              handleEditTime(row.id, row.event_time, row.project_timezone.name)
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={projectsStyles.headingCell} align="left">
                        <ProjectStatus
                          data={row}
                          role={defaultValues.customer}
                          options={statuses}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <NoData message={getErrorMessage('E-10072')} />
          )
        ) : (
          projects.length > 0 && (
            <Box sx={projectsStyles.collapseContainer}>
              <CollapsableList
                contentArray={projects}
                type={defaultValues.listConstants.active_admin_project}
                onProjectTitleClick={(row) => {
                  navigate(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                    state: { data: row }
                  });
                }}
                isEdit={false}
                isButton={false}
                statusOptions={statuses}
                handleEditTime={handleEditTime}
              />
            </Box>
          )
        )}
        <PopupModal
          showModel={open}
          onClose={() => setOpen(false)}
          heading={'Update event time'}
          containerStyles={{ minHeight: 'auto' }}>
          <UpdateEventTime
            time={eventTime}
            id={id}
            timezone={eventZone}
            submited={(data) => setOpen(!data)}
          />
        </PopupModal>
      </Box>
    </Box>
  );
};
