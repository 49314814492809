import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const isMobile = defaultValues.isResponsive;

export const projectDetailsStyles = {
  container: {
    width: '95%',
    minHeight: '85vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: color.primaryBackground,
    padding: '0',
    boxSizing: 'border-box',
    margin: isMobile ? '5px 10px' : '15px 50px',
    paddingTop: '20px',
    paddingBottom: '20px',
    position: 'relative',
    marginBottom: '50px'
  },
  title_1: {
    fontWeight: 600,
    fontSize: isMobile ? 20 : 28
  },
  title_2: {
    fontSize: isMobile ? 14 : 18
  },
  title_3: {
    fontSize: isMobile ? 14 : 20,
    color: color.palette.primaryBlue,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  stepContainer: {
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    marginTop: '10px'
  },
  subContainer: { width: '100%', padding: '0% 2%', boxSizing: 'border-box' },
  topNav: { position: 'absolute', top: '15px', left: '0', marginLeft: '15px' },
  gridStyle: { mt: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' },
  viewStyle: {
    color: color.palette.linkBlue,
    textDecoration: 'underline',
    fontSize: isMobile ? '11px' : '18px',
    fontWeight: 400,
    cursor: 'pointer'
  },
  viewTextContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    p: 1
  },
  border: { border: `1px solid ${color.primaryBorder}`, borderRadius: '8px' }
};
