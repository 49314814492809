import { Box, Stack } from '@mui/material';
import { PopupModal, Text } from '../../../ui-kit';
import img from '../../../assets/images/companyPic.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  adminLoadingStatus,
  getAdminSuccessMessage,
  getCompanyDetails
} from '../../../services/redux/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { styles } from './style';
import { EditDetails } from './editFields';
import { defaultValues } from '../../../constants';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { getPhotoUrl } from '../../../utils';

export const CompanyDetailsCard = () => {
  const isLoading = useSelector(adminLoadingStatus);
  const companyDetails = useSelector(getCompanyDetails);
  const successMessage = useSelector(getAdminSuccessMessage);

  const [details, setDetails] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (companyDetails) {
      setDetails(companyDetails);
    }
  }, [companyDetails]);

  useEffect(() => {
    if (!isLoading && successMessage === 'S-10052') {
      handleClose();
    }
  }, [isLoading, successMessage]);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Box sx={styles.container}>
      <PopupModal
        customContentWidth={styles.customWidth}
        showModel={showModal}
        onClose={handleClose}
        heading={'Edit company details'}
        titlePosition={true}>
        <EditDetails
          company_name={details.company_name}
          registration_number={details.registration_no}
          company_city={details.company_city}
          company_country={details.company_country}
          company_description={details.company_description}
          company_locality={details.company_locality}
          company_post_code={details.company_post_code}
          company_state={details.company_state}
          company_logo={details.company_logo}
          time_zone_id={details.company_time_zone && details.company_time_zone.time_zone}
          id={details.id}
        />
      </PopupModal>
      {!defaultValues.isResponsive ? (
        <img
          src={
            details.company_logo ? details.company_logo && getPhotoUrl(details.company_logo) : img
          }
          alt="company-image"
          style={styles.logoStyle}
        />
      ) : (
        <Box sx={styles.responsiveContainer}>
          <img
            src={
              details.company_logo ? details.company_logo && getPhotoUrl(details.company_logo) : img
            }
            alt="company-image"
            style={styles.logoStyle}
          />
          <Box sx={styles.responsiveTextContainer}>
            <Stack sx={styles.titleContainer}>
              <Text variant={defaultValues.isResponsive ? 'smallBold' : 'secondaryHeader'}>
                {details.company_name}
              </Text>
              <BorderColorIcon
                fontSize={defaultValues.isResponsive ? 'small' : 'large'}
                color="action"
                onClick={() => setShowModal(true)}
                sx={styles.editIcon}
              />
            </Stack>
            <Text
              variant={defaultValues.isResponsive ? 'responsiveDesc' : 'medium'}
              style={styles.regNoColor}>{`Registration no: ${details.registration_no}`}</Text>
          </Box>
        </Box>
      )}
      <Box sx={styles.detailsContainer}>
        {!defaultValues.isResponsive && (
          <>
            <Stack sx={styles.titleContainer}>
              <Text variant={defaultValues.isResponsive ? 'smallBold' : 'secondaryHeader'}>
                {details.company_name}
              </Text>
              <BorderColorIcon
                fontSize={defaultValues.isResponsive ? 'small' : 'large'}
                color="action"
                onClick={() => setShowModal(true)}
                sx={styles.editIcon}
              />
            </Stack>
            <Text
              variant={defaultValues.isResponsive ? 'responsiveDesc' : 'medium'}
              style={styles.regNoColor}>{`Registration no: ${details.registration_no}`}</Text>
          </>
        )}
        <Stack sx={styles.locationContainer}>
          {(details.company_city || details.company_state || details.company_time_zone) && (
            <LocationOnIcon />
          )}
          <Text variant={defaultValues.isResponsive ? 'responsiveDesc' : 'small'}>
            {`${details.company_city ? details.company_city + ', ' : ''}${
              details.company_state ? details.company_state + ' ' : ''
            }${details.company_time_zone ? `${details.company_time_zone.time_zone}` : ''}`}
          </Text>
        </Stack>
        <Text variant="medium" style={styles.descContainer}>
          {details.company_description}
        </Text>
      </Box>
    </Box>
  );
};
