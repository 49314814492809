import { Box } from '@mui/material';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { Text } from '../../../ui-kit/text';
import { EmailStyles } from './emailStyle';
import { UIButton } from '../../../ui-kit/button';
import { loginSchema } from '../../../utils/schemas';
import { useFormik } from 'formik';
import { TextInput } from '../../../ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { enquiryOtpRequest, setLoginPage, setUserStatus } from '../../../services/redux/slices';
import { getUserEmail, isExistingUser } from '../../../services/redux/selectors';
import { useNavigate } from 'react-router-dom';

import { CustomModal } from '../../common/modals/status-modal';
import { useEffect } from 'react';
import { resetMessge } from '../../../utils';

export const EmailSection = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const isUser = useSelector(isExistingUser);
  const userEmail = useSelector(getUserEmail);

  const { values, handleBlur, handleChange, errors, setValues } = useFormik({
    initialValues: { email: '' },
    validationSchema: loginSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  const handleSubmit = () => {
    dispatch(enquiryOtpRequest({ email: values.email }));
  };

  const handleClose = () => {
    dispatch(setUserStatus());
    navigation(SCREEN_PATH.HOME);
  };

  const handleShowLoginModal = () => {
    dispatch(setUserStatus());
    navigation(SCREEN_PATH.HOME, { state: { modalOpen: true } });
    resetMessge(() => dispatch(setLoginPage(true)));
  };

  useEffect(() => {
    if (userEmail) {
      setValues({ ...values, email: userEmail });
    }
  }, [userEmail, setValues]);

  return (
    <Box sx={EmailStyles.container}>
      <CustomModal
        open={isUser}
        onClose={handleClose}
        title={'Account already exists'}
        message={'To book a service, please log in to your account'}
        btnType={'login'}
        onCancel={handleClose}
        onConfirm={handleShowLoginModal}
      />
      <Text
        style={EmailStyles.heading}
        variant={defaultValues.isResponsive ? 'small' : 'secondaryHeader'}>
        {defaultValues.enquireEmail}
      </Text>
      <Text style={EmailStyles.label} variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>
        Email Address
      </Text>

      <TextInput
        required
        name="email"
        variant="outlined"
        size={defaultValues.isResponsive ? 'small' : 'medium'}
        style={EmailStyles.emailField(defaultValues.isResponsive)}
        errorStyle={EmailStyles.errorStyle}
        placeholder="enter your email-id"
        error={errors.email && errors.email}
        value={values.email}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <UIButton
        disabled={errors.email || !values.email.length}
        size="large"
        style={EmailStyles.buttonStyle(errors.email, values.email, defaultValues.isResponsive)}
        onClick={handleSubmit}
        title="Submit"
      />
    </Box>
  );
};
