import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

export const AddQuestionStyle = {
  mainBox: { padding: '25px 0' },
  mainQuestionBox: {
    width: '92%',
    border: `1px solid ${color.palette.lightBorder}`,
    padding: defaultValues.isResponsive ? '10px 10px' : '20px 20px',
    borderRadius: '8px'
  },
  errorBorder: {
    border: `1px solid ${color.palette.borderError}`
  },
  flexBox: {
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row'
  },
  questionBox: {
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    alignItems: !defaultValues.isResponsive && 'center',
    marginTop: '20px'
  },
  titleStyle: { marginBottom: '20px' },
  qaInput: {
    flex: 1,
    boxShadow: 0,
    width: '100%',
    backgroundColor: color.palette.textFieldRed,
    border: '0px',
    borderRadius: '8px'
  },
  qaBtn: {
    padding: '10px 20px',
    marginTop: defaultValues.isResponsive && '10px',
    marginLeft: !defaultValues.isResponsive && '20px',
    width: defaultValues.isResponsive ? '180px' : '270px',
    fontSize: defaultValues.isResponsive && 12
  },
  menuItem: {
    width: '250px',
    height: '50px',
    margin: '0px 5px',
    borderRadius: '3px',
    padding: '0px 20px'
  },
  addButton: {
    width: defaultValues.isResponsive ? '130px' : '180px',
    height: defaultValues.isResponsive ? '30px' : '45px',
    fontSize: defaultValues.isResponsive && 8,
    color: `${color.palette.primaryBlack}!important`,
    '& .MuiTouchRipple-root span': {
      backgroundColor: `${color.palette.primaryRed}!important`,
      opacity: 0.3
    }
  },
  bottomBtnBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '40px',
    width: '100%'
  },
  textBtn: {
    marginTop: '10px',
    marginLeft: !defaultValues.isResponsive && '60px',
    color: color.palette.primaryRed
  },
  textBtnText: {
    fontSize: defaultValues.isResponsive ? '12px' : '20px',
    fontWight: 'bold',
    color: color.palette.linkBlue,
    textDecoration: 'underline'
  },
  menuIcon: { marginRight: '15px', fontSize: '22px' },
  questionInputBox: { marginTop: '20px', width: 'calc(95% - 235px)' },
  questionInput: { backgroundColor: color.palette.disableColor, width: '100%' },
  subQuestionBox: { marginTop: '20px' },
  diver: { margin: '10px 0 25px 0' },
  diver2: { margin: '25px 0 0px 0', borderBottomWidth: 2 },
  btnFlex: { display: 'flex' },
  fontClr: { color: color.palette.primaryWhite },
  editContainer: { minHeight: defaultValues.isResponsive ? 'auto' : 'none' }
};
