/*
change the color or codes based on your project requirements
*/
export const palette = {
  // primaryYellow: '#FEE967',
  primaryBlue: '#408EDD',
  primaryBlack: '#050505',
  secondaryBlack: '#2E2D2D',
  ligherBlack: '#5e5c5c',
  primaryRed: '#C93F3F',
  primaryLightRed: 'rgba(226, 59, 59, 0.15)',
  secondaryRed: '#F19870',
  secondaryLightRed: 'rgba(255, 206, 87, 0.15)',
  primaryWhite: '#FDFFFC',
  secondaryWhite: '#F4F5FA',
  primaryDisabledRed: 'rgba(201, 63, 63, 0.5)',
  textFieldRed: 'rgba(201, 63, 63, 0.05)',
  buttonRed: ' #E23B3B',
  buttonGreen: '#44C36F',
  lightGreen: '#d9f3e1',
  buttonLight: '#FBFBFD',
  emailGray: 'rgba(5, 5, 5, 0.6)',
  statusLightGreen: 'rgba(68, 195, 111, 0.15)',
  statusLightRed: 'rgba(226, 59, 59, 0.15)',
  ligntBgBlue: 'rgba(93, 204, 211, 0.15)',
  lightBlue: '#5DCCD3',
  linkBlue: '#0062AE',
  lighterGray: '#eaeaf0',

  // white: '#FFFFFF',
  gray: '#808080',
  lightGray: '#D9D9D9',
  secondaryLightGray: '#E6E6E6',
  darkGray: '#4E4E4E',
  red: '#EA4335',
  primaryBackground: '#FFF',
  secondaryBackground: '#F4F5FA',
  borderColor: '#BDBEC1',
  borderError: 'red',
  lightBorder: '#E5E5E5',
  disableColor: '#F5F5F5',
  disableBackground: '#fff',
  backDropColor: '#00000080',
  navbarline: '#00000033',
  green: '#44C36F',
  skyBlue: '#37C0DE',
  maroon: '#7D2B3F',
  orange: '#E08530',
  secondaryOrange: '#EA8722',
  statusOrange: '#FFB500',
  statusLightOrange: 'rgba(255, 206, 87, 0.15)',
  yellow: '#E2E53D',
  yellowIcon: '#FFD700',
  goldenYellow: '#FFCE57',
  lightRed: '#ED6976',
  violet: '#C2389B',
  blue: '#52519D',
  purple: '#AC33D7',
  accentsLabel: '#E97878',
  secondaryBlue: '#4169E1',
  tertiaryBlue: '#01AAEF',
  secondaryLightBlue: 'rgba(65, 105, 225, 0.15)',
  tertiaryLightBlue: 'rgba(64, 142, 221, 1)',
  cardColors: ['#5DCCD3', '#56C189', '#F19870', '#ED6976'],
  pieChartColors: ['#D06D6D', '#E97878', '#F0D3D3', '#EF9D9D'],
  customPieChartColors: ['#56C189', '#F19870'],
  dueDate: '#F1987026',
  skyBlueLight: '#5DCCD34D',
  taskStatusBlue: '#5DCCD326',
  archived: '#7F7F7F',
  notificationColors: ['#FFB500', '#5DCCD3', '#FFB6B6', '#4169E1', '#44C36F', '#ED6976'],
  disabledRed: '#8F8F92'
};
