import { createSelector } from 'reselect';

export const getServiceSuccessMessage = createSelector(
  [(state) => state.service.successMessage],
  (successMessage) => successMessage
);

export const getServiceErrorMessage = createSelector(
  [(state) => state.service.errorMessage],
  (errorMessage) => errorMessage
);

export const serviceLoadingStatus = createSelector(
  [(state) => state.service.isLoading],
  (loadingStatus) => loadingStatus
);

export const getUserEmail = createSelector(
  [(state) => state.service.userEmail],
  (userEmail) => userEmail
);

export const getRightSection = createSelector(
  [(state) => state.service.rightSection],
  (rightSection) => rightSection
);

export const isCompanyRegisterd = createSelector(
  [(state) => state.service.isCompanyRegisterd],
  (isCompanyRegisterd) => isCompanyRegisterd
);

export const getRegNo = createSelector([(state) => state.service.regNo], (regNo) => regNo);

export const getQuestion = createSelector(
  [(state) => state.service.question],
  (question) => question
);

export const getServiceId = createSelector(
  [(state) => state.service.serviceId],
  (serviceId) => serviceId
);

export const getSelectedLanguages = createSelector(
  [(state) => state.service.languages],
  (languages) => languages
);

export const getEventDetails = createSelector(
  [(state) => state.service.eventDetails],
  (eventDetails) => eventDetails
);

export const getRegisteredCompanyDetails = createSelector(
  [(state) => state.service.companyDetails],
  (companyDetails) => companyDetails
);

export const isExistingUser = createSelector([(state) => state.service.isUser], (isUser) => isUser);
export const getUserVerified = createSelector(
  [(state) => state.service.userVerified],
  (User) => User
);

export const getLoginStatus = createSelector(
  [(state) => state.service.isLoggedIn],
  (isLoggedIn) => isLoggedIn
);

export const getServicesParams = createSelector(
  [(state) => state.service.servicesParams],
  (servicesParams) => servicesParams
);

export const getCompanyActive = createSelector(
  [(state) => state.service.isCompanyActive],
  (isCompanyActive) => isCompanyActive
);
