import { createSelector } from 'reselect';

export const getCCompanySuccessMessage = createSelector(
  [(state) => state.customer_company.successMessage],
  (successMessage) => successMessage
);

export const getCCompanyErrorMessage = createSelector(
  [(state) => state.customer_company.errorMessage],
  (errorMessage) => errorMessage
);

export const cCompanyLoadingStatus = createSelector(
  [(state) => state.customer_company.isLoading],
  (loadingStatus) => loadingStatus
);

export const getCCompanies = createSelector(
  [(state) => state.customer_company.companies],
  (companies) => companies
);

export const getCCompaniesCurrentPg = createSelector(
  [(state) => state.customer_company.comp_current_page],
  (companies) => companies
);

export const getCCompaniesTotalPg = createSelector(
  [(state) => state.customer_company.comp_total_page],
  (companies) => companies
);

export const getCustomers = createSelector(
  [(state) => state.customer_company.customers],
  (customers) => customers
);

export const notesData = createSelector(
  [(state) => state.customer_company.notesData],
  (notes) => notes
);

export const getCustomerCompanyIndicator = createSelector(
  [(state) => state.customer_company.indicator],
  (indicator) => indicator
);

export const getCurrentTab = createSelector(
  [(state) => state.customer_company.currentTab],
  (currentTab) => currentTab
);

export const getUserSearch = createSelector(
  [(state) => state.customer_company.findCustomerSearch],
  (findCustomerSearch) => findCustomerSearch
);

export const getFilters = createSelector(
  [(state) => state.customer_company.filters],
  (filters) => filters
);

export const getDetailsData = createSelector(
  [(state) => state.customer_company.detailsData],
  (detailsData) => detailsData
);

export const getCompDetailsTab = createSelector(
  [(state) => state.customer_company.companyDetailsTab],
  (companyDetailsTab) => companyDetailsTab
);
