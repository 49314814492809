import { createSelector } from 'reselect';

export const getAdminSuccessMessage = createSelector(
  [(state) => state.admin.successMessage],
  (successMessage) => successMessage
);

export const getAdminErrorMessage = createSelector(
  [(state) => state.admin.errorMessage],
  (errorMessage) => errorMessage
);

export const adminLoadingStatus = createSelector(
  [(state) => state.admin.isLoading],
  (loadingStatus) => loadingStatus
);

export const totalAdminPages = createSelector(
  [(state) => state.admin.totalPage],
  (totalPage) => totalPage
);

export const getProjectsCount = createSelector(
  [(state) => state.admin.projectsCount],
  (projectsCount) => projectsCount
);

export const getLeadsCount = createSelector(
  [(state) => state.admin.leadsCount],
  (leadsCount) => leadsCount
);

export const getLeadDistributionData = createSelector(
  [(state) => state.admin.leadDistributionData],
  (leadDistributionData) => leadDistributionData
);

export const getSupplierCountData = createSelector(
  [(state) => state.admin.supplierCountData],
  (supplierCountData) => supplierCountData
);

export const getSupplierEngagementData = createSelector(
  [(state) => state.admin.supplierEngagementData],
  (supplierEngagementData) => supplierEngagementData
);

export const getNewSupplierCout = createSelector(
  [(state) => state.admin.newSuppliers],
  (newSuppliers) => newSuppliers
);

export const getCurrentPage = createSelector(
  [(state) => state.admin.currentPage],
  (currentPage) => currentPage
);

export const getCompanyDetails = createSelector(
  [(state) => state.admin.companyDetails],
  (companyDetails) => companyDetails
);

export const getTeamDetails = createSelector(
  [(state) => state.admin.teamDetails],
  (teamDetails) => teamDetails
);

export const getIncomingRequest = createSelector(
  [(state) => state.admin.incomingRequest],
  (incomingRequest) => incomingRequest
);

export const getAdmins = createSelector([(state) => state.admin.admins], (admin) => admin);

export const getSelectedTab = createSelector(
  [(state) => state.admin.selectedTabIndex],
  (selectedTabIndex) => selectedTabIndex
);

export const getAdminIndicator = createSelector(
  [(state) => state.admin.indicator],
  (indicator) => indicator
);

export const getTeamTab = createSelector(
  [(state) => state.admin.teamTabIndex],
  (teamTabIndex) => teamTabIndex
);

export const getadminParam = createSelector(
  [(state) => state.admin.adminParams],
  (adminParams) => adminParams
);

export const getDashboardType = createSelector(
  [(state) => state.admin.dashboardType],
  (dashboardType) => dashboardType
);

export const getActiveProjects = createSelector(
  [(state) => state.admin.activeProjects],
  (activeProjects) => activeProjects
);
export const getAllProjects = createSelector(
  [(state) => state.admin.allProjects],
  (allProjects) => allProjects
);

export const getJobsPosted = createSelector(
  [(state) => state.admin.jobsPosted],
  (jobsPosted) => jobsPosted
);

export const getProjectReceived = createSelector(
  [(state) => state.admin.projectReceived],
  (projectReceived) => projectReceived
);
