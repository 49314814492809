import { defaultValues } from '../../../constants';

const isResponsive = defaultValues.isResponsive;
export const styles = {
  container: { width: isResponsive ? '90%' : '80%', height: '100%' },
  dateContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  datePicker: { container: { width: '48%' } },
  btnStyle: {
    bottom: '-5px',
    width: isResponsive && '100%',
    fontSize: isResponsive ? '12px' : '22px'
  },
  chipContainer: { width: '100%' }
};
