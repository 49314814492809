import { Backdrop, CircularProgress } from '@mui/material';
import { Text } from '../text';
import { palette } from '../../theme/palette';

export const LoadingScreen = ({ isLoading }) => {
  const styles = {
    backdrop: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: palette.disableBackground,
      zIndex: (theme) => theme.zIndex.drawer + 1
    },
    text: { color: palette.primaryWhite, marginTop: '15px' }
  };
  return (
    <Backdrop sx={styles.backdrop} open={isLoading}>
      <CircularProgress color="inherit" size={60} />
      <Text variant="title" style={styles.text}>
        Please wait...
      </Text>
    </Backdrop>
  );
};
