import { defaultValues } from '../../constants';
import { color } from '../../theme';
const res = defaultValues.isResponsive;

export const style = {
  container: {
    backgroundColor: color.secondaryBackground,
    width: '100%',
    height: '100%',
    padding: res ? '4px' : '12px',
    boxSizing: 'border-box',
    overflowY: 'auto'
  },
  subContainer: {
    backgroundColor: color.primaryBackground,
    width: '100%',
    boxSizing: 'border-box',
    minHeight: '80vh',
    borderRadius: 1,
    py: res ? 1 : 2,
    px: res ? 1 : 4
  },
  optionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: res ? 'space-between' : 'flex-end'
  },
  markText: {
    color: color.palette.blue,
    textDecorationLine: 'Underline',
    cursor: 'pointer',
    mx: 1
  },
  notificationContainer: {
    backgroundColor: color.primaryBackground,
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: 1,
    mt: 2,
    p: res ? 1 : 3,
    border: `1px solid ${color.primaryBorder}`
  },
  notificationCard: {
    width: '100%',
    boxShadow: 1,
    borderRadius: 1,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    my: 2
  },
  color: (randomColor) => ({
    backgroundColor: randomColor,
    minHeight: res ? '80px' : '100px',
    width: '11px',
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2
  }),
  details: {
    height: '100%',
    width: res ? '73%' : '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    pl: 1
  },
  checkBoxContainer: {
    width: res ? '25%' : '12%',
    p: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  checkBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  checkBoxStyle: { marginRight: '0px', marginBottom: '-10px' }
};
