/*
 *Commonly used keys
 * like success, failure, access token etc.
 *
 * Add or remove variables according to your requirement
 */
export const SUCCESS_KEY = 'success';
export const FAILURE_KEY = 'failure';
export const ERROR_KEY = 'error';
export const ACCESS_KEY = 'jwt_access_token';
export const ADD_KEY = 'add';
export const EDIT_KEY = 'edit';
