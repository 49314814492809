import { combineReducers } from 'redux';
import authReducer from './slices/authSlice';
import settingsReducer from './slices/settingsSlice';
import projectReducer from './slices/projectSlice';
import adminReducer from './slices/adminSlice';
import serviceReducer from './slices/serviceSlice';
import customerCompanyReducer from './slices/customerCompanySlice';
import supplierSliceReducer from './slices/supplierSlice';
import profileReducer from './slices/profileSlice';
import jobReducer from './slices/jobsSlice';
import chatReducer from './slices/chatSlice';

const appReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  project: projectReducer,
  admin: adminReducer,
  service: serviceReducer,
  customer_company: customerCompanyReducer,
  profile: profileReducer,
  supplier: supplierSliceReducer,
  jobs: jobReducer,
  chat: chatReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
