import { put, takeLatest } from 'redux-saga/effects';
import {
  createProjectManagersRequest,
  createProjectManagersRequestFailure,
  createProjectManagersRequestSuccess,
  projectManagersRequest,
  projectManagersRequestFailure,
  projectManagersRequestSuccess,
  acceptQuotationFailure,
  acceptQuotationRequest,
  acceptQuotationSuccess,
  getStatusIdFailure,
  getStatusIdRequest,
  getStatusIdSuccess,
  projectRequest,
  projectRequestFailure,
  projectRequestSuccess,
  projectStatusRequest,
  projectStatusRequestSuccess,
  projectstatusRequestFailure,
  updateProjectManagersRequest,
  updateProjectManagersRequestFailure,
  updateProjectManagersRequestSuccess,
  rejectQuotationFailure,
  rejectQuotationRequest,
  rejectQuotationSuccess,
  resendQuotationFailure,
  resendQuotationRequest,
  resendQuotationSuccess,
  sendQuotationFailure,
  sendQuotationRequest,
  sendQuotationSuccess,
  updateProjectStatusRequest,
  updateProjectStatusRequestSuccess,
  updateProjectstatusRequestFailure,
  viewQuotationFailure,
  viewQuotationRequest,
  viewQuotationSuccess,
  getProjectDetailsRequest,
  getProjectDetailsFailure,
  postJobsSuccess,
  postJobsFailure,
  getProjectDetailsSuccess,
  postJobsRequest,
  assignPMSuccess,
  assignPMFailure,
  assignPMRequest,
  removePMSuccess,
  removePMFailure,
  removePMRequest,
  removeSupplierSuccess,
  removeSupplierFailure,
  removeSupplierRequest,
  createTaskListSuccess,
  createTaskListFailure,
  createTaskListRequest,
  taskListRequestSuccess,
  taskListRequestFailure,
  taskListRequest,
  getProjectDocumentFailure,
  getProjectDocumentSuccess,
  getProjectDocumentRequest,
  uploadProjectDocumentSuccess,
  uploadProjectDocumentFailure,
  uploadProjectDocumentRequest,
  getProjectMembersSuccess,
  getProjectMembersFailure,
  getProjectMembers,
  createTaskSuccess,
  createTaskFailure,
  createTaskRequest,
  getTaskSuccess,
  getTaskFailure,
  getTaskRequest,
  updateTaskListRequest,
  updateTaskListSuccess,
  updateTaskListFailure,
  updateTaskListStatusRequest,
  updateTaskListStatusSuccess,
  updateTaskListStatusFailure,
  updateTaskStatusRequest,
  updateTaskStatusSuccess,
  updateTaskStatusFailure,
  updateTaskSuccess,
  updateTaskFailure,
  updateTaskRequest,
  getTaskSchedulesSuccess,
  getTaskSchedulesFailure,
  getTaskSchedulesRequest,
  getTaskDetailsFailure,
  getTaskDetailsSuccess,
  getTaskDetailsRequest,
  addListCommentsSuccess,
  addListCommentsFailure,
  addListCommentsRequest,
  getListCommentsRequest,
  getListCommentsSuccess,
  getListCommentsFailure,
  addTaskCommentsSuccess,
  addTaskCommentsFailure,
  getTaskCommentsSuccess,
  getTaskCommentsFailure,
  getTaskCommentsRequest,
  addTaskCommentsRequest,
  deleteProjectDocumentSuccess,
  deleteProjectDocumentFailure,
  deleteProDocumentRequest,
  sendRatingSuccess,
  sendRatingFailure,
  sendRatingRequest,
  editRatingFailure,
  editRatingSuccess,
  editRatingRequest,
  getFeedbackSuccess,
  getFeedbackFailure,
  getFeedbackRequest,
  sendSupplierFeedbackSuccess,
  sendSupplierFeedbackFailure,
  sendCustomerFeedbackSuccess,
  sendCustomerFeedbackFailure,
  sendSupplierFeedbackRequest,
  sendCustomerFeedbackRequest,
  getProjectsLogs,
  projectLogsSuccess,
  projectLogsFailure
} from '../slices/projectSlice';
import {
  acceptQuotationApi,
  getProjectStatusApi,
  getProjectsApi,
  getStatusIdApi,
  rejectQuotationApi,
  resendQuotationApi,
  sendQuotationApi,
  updateProjectStatusApi,
  viewQuotationApi,
  createProjectManagerApi,
  getProjectManagerApi,
  updateProjectManagerApi,
  getProjectDetailsApi,
  postJobsAPI,
  assignPMApi,
  removePMApi,
  removeSupplierApi,
  createTaskListAPI,
  getTaskListAPI,
  getProjectDocumentsApi,
  uploadProjectDocumentsApi,
  getProjectMembersAPI,
  createTaskAPI,
  getTaskAPI,
  updateTaskListAPI,
  updateTaskListStatusAPI,
  updateTaskStatusAPI,
  updateTaskAPI,
  getTaskShedulesAPI,
  getTaskDetailsAPI,
  postListCommentsApi,
  getListCommentsApi,
  postTaskCommentsApi,
  getTaskCommentsApi,
  deleteProjectDocumentApi,
  sendRatingApi,
  editRatingApi,
  feedbackApi,
  supplierFeedbackApi,
  customerFeedbackApi,
  getProjectsLogApi
} from '../../api';
import { SUCCESS_KEY } from '../../../constants';

function* getProjects(action) {
  try {
    const response = yield getProjectsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(projectRequestSuccess(response.data));
    } else {
      yield put(projectRequestFailure(response.data));
    }
  } catch (error) {
    yield put(projectRequestFailure(error.response.data));
  }
}

function* getProjectStatus() {
  try {
    const response = yield getProjectStatusApi();
    if (response.data.status === SUCCESS_KEY) {
      yield put(projectStatusRequestSuccess(response.data));
    } else {
      yield put(projectstatusRequestFailure(response.data));
    }
  } catch (error) {
    yield put(projectstatusRequestFailure(error.response.data));
  }
}

function* updateProjectStatus(action) {
  try {
    const response = yield updateProjectStatusApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateProjectStatusRequestSuccess(response.data));
    } else {
      yield put(updateProjectstatusRequestFailure(response.data));
    }
  } catch (error) {
    yield put(updateProjectstatusRequestFailure(error.response.data));
  }
}

function* sendQuotaton(action) {
  try {
    const response = yield sendQuotationApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(sendQuotationSuccess(response.data));
    } else {
      yield put(sendQuotationFailure(response.data));
    }
  } catch (error) {
    yield put(sendQuotationFailure(error.response.data));
  }
}

function* viewQuotaton(action) {
  try {
    const response = yield viewQuotationApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(viewQuotationSuccess(response.data));
    } else {
      yield put(viewQuotationFailure(response.data));
    }
  } catch (error) {
    yield put(viewQuotationFailure(error.response.data));
  }
}

function* acceptQuotaton(action) {
  try {
    const response = yield acceptQuotationApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(acceptQuotationSuccess(response.data));
    } else {
      yield put(acceptQuotationFailure(response.data));
    }
  } catch (error) {
    yield put(acceptQuotationFailure(error.response.data));
  }
}

function* rejectQuotaton(action) {
  try {
    const response = yield rejectQuotationApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(rejectQuotationSuccess(response.data));
    } else {
      yield put(rejectQuotationFailure(response.data));
    }
  } catch (error) {
    yield put(rejectQuotationFailure(error.response.data));
  }
}

function* resendQuotaton(action) {
  try {
    const response = yield resendQuotationApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(resendQuotationSuccess(response.data));
    } else {
      yield put(resendQuotationFailure(response.data));
    }
  } catch (error) {
    yield put(resendQuotationFailure(error.response.data));
  }
}

function* getProjectManagers(action) {
  try {
    const response = yield getProjectManagerApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(projectManagersRequestSuccess(response.data));
    } else {
      yield put(projectManagersRequestFailure(response.data));
    }
  } catch (error) {
    yield put(projectManagersRequestFailure(error.response.data));
  }
}

function* createProjectManagers(action) {
  try {
    const response = yield createProjectManagerApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(createProjectManagersRequestSuccess(response.data));
    } else {
      yield put(createProjectManagersRequestFailure(response.data));
    }
  } catch (error) {
    yield put(createProjectManagersRequestFailure(error.response.data));
  }
}

function* updateProjectManagers(action) {
  try {
    const response = yield updateProjectManagerApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateProjectManagersRequestSuccess(response.data));
    } else {
      yield put(updateProjectManagersRequestFailure(response.data));
    }
  } catch (error) {
    yield put(updateProjectManagersRequestFailure(error.response.data));
  }
}

function* getStatusId(action) {
  try {
    const response = yield getStatusIdApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getStatusIdSuccess(response.data));
    } else {
      yield put(getStatusIdFailure(response.data));
    }
  } catch (error) {
    yield put(getStatusIdFailure(error.response.data));
  }
}

function* getProjectDetails(action) {
  try {
    const response = yield getProjectDetailsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getProjectDetailsSuccess(response.data));
    } else {
      yield put(getProjectDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getProjectDetailsFailure(error.response.data));
  }
}

function* postJobs(action) {
  try {
    const response = yield postJobsAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(postJobsSuccess(response.data));
    } else {
      yield put(postJobsFailure(response.data));
    }
  } catch (error) {
    yield put(postJobsFailure(error.response.data));
  }
}

function* assignPM(action) {
  try {
    const response = yield assignPMApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(assignPMSuccess(response.data));
    } else {
      yield put(assignPMFailure(response.data));
    }
  } catch (error) {
    yield put(assignPMFailure(error.response.data));
  }
}

function* removePM(action) {
  try {
    const response = yield removePMApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(removePMSuccess(response.data));
    } else {
      yield put(removePMFailure(response.data));
    }
  } catch (error) {
    yield put(removePMFailure(error.response.data));
  }
}

function* removeSupplier(action) {
  try {
    const response = yield removeSupplierApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(removeSupplierSuccess(response.data));
    } else {
      yield put(removeSupplierFailure(response.data));
    }
  } catch (error) {
    yield put(removeSupplierFailure(error.response.data));
  }
}

function* createTaskList(action) {
  try {
    const response = yield createTaskListAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(createTaskListSuccess(response.data));
    } else {
      yield put(createTaskListFailure(response.data));
    }
  } catch (error) {
    yield put(createTaskListFailure(error.response.data));
  }
}

function* getTaskList(action) {
  try {
    const response = yield getTaskListAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(taskListRequestSuccess(response.data));
    } else {
      yield put(taskListRequestFailure(response.data));
    }
  } catch (error) {
    yield put(taskListRequestFailure(error.response.data));
  }
}

function* getDocuments(action) {
  try {
    const response = yield getProjectDocumentsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getProjectDocumentSuccess(response.data));
    } else {
      yield put(getProjectDocumentFailure(response.data));
    }
  } catch (error) {
    yield put(getProjectDocumentFailure(error.response.data));
  }
}

function* uploadProjectDocument(action) {
  try {
    const response = yield uploadProjectDocumentsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(uploadProjectDocumentSuccess(response.data));
    } else {
      yield put(uploadProjectDocumentFailure(response.data));
    }
  } catch (error) {
    yield put(uploadProjectDocumentFailure(error.response.data));
  }
}

function* projectMembers(action) {
  try {
    const response = yield getProjectMembersAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getProjectMembersSuccess(response.data));
    } else {
      yield put(getProjectMembersFailure(response.data));
    }
  } catch (error) {
    yield put(getProjectMembersFailure(error.response.data));
  }
}

function* createTasks(action) {
  try {
    const response = yield createTaskAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(createTaskSuccess(response.data));
    } else {
      yield put(createTaskFailure(response.data));
    }
  } catch (error) {
    yield put(createTaskFailure(error.response.data));
  }
}

function* getTasks(action) {
  try {
    const response = yield getTaskAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getTaskSuccess(response.data));
    } else {
      yield put(getTaskFailure(response.data));
    }
  } catch (error) {
    yield put(getTaskFailure(error.response.data));
  }
}

function* updateTaskList(action) {
  try {
    const response = yield updateTaskListAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateTaskListSuccess(response.data));
    } else {
      yield put(updateTaskListFailure(response.data));
    }
  } catch (error) {
    yield put(updateTaskListFailure(error.response.data));
  }
}

function* updateTaskListStatus(action) {
  try {
    const response = yield updateTaskListStatusAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateTaskListStatusSuccess(response.data));
    } else {
      yield put(updateTaskListStatusFailure(response.data));
    }
  } catch (error) {
    yield put(updateTaskListStatusFailure(error.response.data));
  }
}

function* updateTaskStatus(action) {
  try {
    const response = yield updateTaskStatusAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateTaskStatusSuccess(response.data));
    } else {
      yield put(updateTaskStatusFailure(response.data));
    }
  } catch (error) {
    yield put(updateTaskStatusFailure(error.response.data));
  }
}

function* updateTask(action) {
  try {
    const response = yield updateTaskAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateTaskSuccess(response.data));
    } else {
      yield put(updateTaskFailure(response.data));
    }
  } catch (error) {
    yield put(updateTaskFailure(error.response.data));
  }
}

function* getTaskShedules(action) {
  try {
    const response = yield getTaskShedulesAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getTaskSchedulesSuccess(response.data));
    } else {
      yield put(getTaskSchedulesFailure(response.data));
    }
  } catch (error) {
    yield put(getTaskSchedulesFailure(error.response.data));
  }
}

function* getTaskDetails(action) {
  try {
    const response = yield getTaskDetailsAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getTaskDetailsSuccess(response.data));
    } else {
      yield put(getTaskDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getTaskDetailsFailure(error.response.data));
  }
}

function* postListComments(action) {
  try {
    const response = yield postListCommentsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addListCommentsSuccess(response.data));
    } else {
      yield put(addListCommentsFailure(response.data));
    }
  } catch (error) {
    yield put(addListCommentsFailure(error.response.data));
  }
}

function* getListComments(action) {
  try {
    const response = yield getListCommentsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getListCommentsSuccess(response.data));
    } else {
      yield put(getListCommentsFailure(response.data));
    }
  } catch (error) {
    yield put(getListCommentsFailure(error.response.data));
  }
}

function* postTaskComments(action) {
  try {
    const response = yield postTaskCommentsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(addTaskCommentsSuccess(response.data));
    } else {
      yield put(addTaskCommentsFailure(response.data));
    }
  } catch (error) {
    yield put(addTaskCommentsFailure(error.response.data));
  }
}

function* getTaskComments(action) {
  try {
    const response = yield getTaskCommentsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getTaskCommentsSuccess(response.data));
    } else {
      yield put(getTaskCommentsFailure(response.data));
    }
  } catch (error) {
    yield put(getTaskCommentsFailure(error.response.data));
  }
}

function* deleteProjectDocument(action) {
  try {
    const response = yield deleteProjectDocumentApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(deleteProjectDocumentSuccess(response.data));
    } else {
      yield put(deleteProjectDocumentFailure(response.data));
    }
  } catch (error) {
    yield put(deleteProjectDocumentFailure(error.response.data));
  }
}

function* sendRating(action) {
  try {
    const response = yield sendRatingApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(sendRatingSuccess(response.data));
    } else {
      yield put(sendRatingFailure(response.data));
    }
  } catch (error) {
    yield put(sendRatingFailure(error.response.data));
  }
}

function* editRating(action) {
  try {
    const response = yield editRatingApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editRatingSuccess(response.data));
    } else {
      yield put(editRatingFailure(response.data));
    }
  } catch (error) {
    yield put(editRatingFailure(error.response.data));
  }
}

function* feedback(action) {
  try {
    const response = yield feedbackApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getFeedbackSuccess(response.data));
    } else {
      yield put(getFeedbackFailure(response.data));
    }
  } catch (error) {
    yield put(getFeedbackFailure(error.response.data));
  }
}

function* supplierFeedback(action) {
  try {
    const response = yield supplierFeedbackApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(sendSupplierFeedbackSuccess(response.data));
    } else {
      yield put(sendSupplierFeedbackFailure(response.data));
    }
  } catch (error) {
    yield put(sendSupplierFeedbackFailure(error.response.data));
  }
}

function* customerfeedback(action) {
  try {
    const response = yield customerFeedbackApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(sendCustomerFeedbackSuccess(response.data));
    } else {
      yield put(sendCustomerFeedbackFailure(response.data));
    }
  } catch (error) {
    yield put(sendCustomerFeedbackFailure(error.response.data));
  }
}

function* projectLogs(action) {
  try {
    const response = yield getProjectsLogApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(projectLogsSuccess(response.data));
    } else {
      yield put(projectLogsFailure(response.data));
    }
  } catch (error) {
    yield put(projectLogsFailure(error.response.data));
  }
}

export function* projectSaga() {
  yield takeLatest(projectRequest.type, getProjects);
  yield takeLatest(projectStatusRequest.type, getProjectStatus);
  yield takeLatest(updateProjectStatusRequest.type, updateProjectStatus);
  yield takeLatest(sendQuotationRequest.type, sendQuotaton);
  yield takeLatest(viewQuotationRequest.type, viewQuotaton);
  yield takeLatest(acceptQuotationRequest.type, acceptQuotaton);
  yield takeLatest(rejectQuotationRequest.type, rejectQuotaton);
  yield takeLatest(resendQuotationRequest.type, resendQuotaton);
  yield takeLatest(projectManagersRequest.type, getProjectManagers);
  yield takeLatest(createProjectManagersRequest.type, createProjectManagers);
  yield takeLatest(updateProjectManagersRequest.type, updateProjectManagers);
  yield takeLatest(getStatusIdRequest.type, getStatusId);
  yield takeLatest(getProjectDetailsRequest.type, getProjectDetails);
  yield takeLatest(postJobsRequest.type, postJobs);
  yield takeLatest(assignPMRequest.type, assignPM);
  yield takeLatest(removePMRequest.type, removePM);
  yield takeLatest(removeSupplierRequest.type, removeSupplier);
  yield takeLatest(createTaskListRequest.type, createTaskList);
  yield takeLatest(taskListRequest.type, getTaskList);
  yield takeLatest(getProjectDocumentRequest.type, getDocuments);
  yield takeLatest(uploadProjectDocumentRequest.type, uploadProjectDocument);
  yield takeLatest(getProjectMembers.type, projectMembers);
  yield takeLatest(createTaskRequest.type, createTasks);
  yield takeLatest(getTaskRequest.type, getTasks);
  yield takeLatest(updateTaskListRequest.type, updateTaskList);
  yield takeLatest(updateTaskListStatusRequest.type, updateTaskListStatus);
  yield takeLatest(updateTaskStatusRequest.type, updateTaskStatus);
  yield takeLatest(updateTaskRequest.type, updateTask);
  yield takeLatest(getTaskSchedulesRequest.type, getTaskShedules);
  yield takeLatest(getTaskDetailsRequest.type, getTaskDetails);
  yield takeLatest(addListCommentsRequest.type, postListComments);
  yield takeLatest(getListCommentsRequest.type, getListComments);
  yield takeLatest(addTaskCommentsRequest.type, postTaskComments);
  yield takeLatest(getTaskCommentsRequest.type, getTaskComments);
  yield takeLatest(deleteProDocumentRequest.type, deleteProjectDocument);
  yield takeLatest(sendRatingRequest.type, sendRating);
  yield takeLatest(editRatingRequest.type, editRating);
  yield takeLatest(getFeedbackRequest.type, feedback);
  yield takeLatest(sendSupplierFeedbackRequest.type, supplierFeedback);
  yield takeLatest(sendCustomerFeedbackRequest.type, customerfeedback);
  yield takeLatest(getProjectsLogs.type, projectLogs);
}
