import { put, takeLatest } from 'redux-saga/effects';
import {
  acceptRejectJobFailure,
  acceptRejectJobRequest,
  acceptRejectJobSuccess,
  applyJobFailure,
  applyJobRequest,
  applyJobSuccess,
  getJobApplicantFailure,
  getJobApplicantRequest,
  getJobApplicantSuccess,
  getJobFailure,
  getJobRequest,
  getJobSuccess,
  updateJobStatusFailure,
  updateJobStatusRequest,
  updateJobStatusSuccess
} from '../slices/jobsSlice';
import {
  acceptRejectJobApi,
  applyJobApi,
  getJobsApi,
  getJobsApplicantsApi,
  updateJobStatusApi
} from '../../api';
import { SUCCESS_KEY } from '../../../constants';

function* getJob(action) {
  try {
    const response = yield getJobsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getJobSuccess(response.data));
    } else {
      yield put(getJobFailure(response.data));
    }
  } catch (error) {
    yield put(getJobFailure('E-10001'));
  }
}

function* getJobApplicants(action) {
  try {
    const response = yield getJobsApplicantsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getJobApplicantSuccess(response.data));
    } else {
      yield put(getJobApplicantFailure(response.data));
    }
  } catch (error) {
    yield put(getJobApplicantFailure('E-10001'));
  }
}

function* acceptRejectJob(action) {
  try {
    const response = yield acceptRejectJobApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(acceptRejectJobSuccess(response.data));
    } else {
      yield put(acceptRejectJobFailure(response.data));
    }
  } catch (error) {
    yield put(acceptRejectJobFailure('E-10001'));
  }
}

function* applyJob(action) {
  try {
    const response = yield applyJobApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(applyJobSuccess(response.data));
    } else {
      yield put(applyJobFailure(response.data));
    }
  } catch (error) {
    yield put(applyJobFailure('E-10001'));
  }
}

function* updateJobStatus(action) {
  try {
    const response = yield updateJobStatusApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(updateJobStatusSuccess(response.data));
    } else {
      yield put(updateJobStatusFailure(response.data));
    }
  } catch (error) {
    yield put(updateJobStatusFailure('E-10001'));
  }
}

export function* jobSaga() {
  yield takeLatest(getJobRequest.type, getJob);
  yield takeLatest(getJobApplicantRequest.type, getJobApplicants);
  yield takeLatest(acceptRejectJobRequest.type, acceptRejectJob);
  yield takeLatest(applyJobRequest.type, applyJob);
  yield takeLatest(updateJobStatusRequest.type, updateJobStatus);
}
