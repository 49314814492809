import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  ActionButton,
  PopupModal,
  SearchBar,
  Text,
  UIButton,
  UITextInputWithTitle
} from '../../../ui-kit';
import { styles } from './style';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { StatusIndicator, Title } from '../../other-settings/table-title';
import { CollapsableList } from '../../common/collapsable-list';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminLoadingStatus,
  getAdminSuccessMessage,
  getCompanyDetails,
  getTeamDetails,
  getUserId,
  userInfo
} from '../../../services/redux/selectors';
import { AdminProfile } from '../../admin-profile';
import { addTeamMemberSchema, getFormatDate, getUserRole } from '../../../utils';
import { useFormik } from 'formik';
import { addTeamMemberRequest, editTeamMemberRequest } from '../../../services/redux/slices';
import { useCallback } from 'react';
import { customStyles } from '../../../theme/styles';
import { useNavigate } from 'react-router-dom';
import { NoData } from '../../common/no-data-message';

export const TeamDeatails = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const isLoading = useSelector(adminLoadingStatus);
  const companyDetails = useSelector(getCompanyDetails);
  const temsInfo = useSelector(getTeamDetails);
  const successMessage = useSelector(getAdminSuccessMessage);
  const currentUserData = useSelector(userInfo);
  const userId = useSelector(getUserId);

  const [open, setOpen] = useState(false);
  const [teamDetails, setTeamDetails] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const roleId = getUserRole(defaultValues.userRole.customer);

  const { values, handleBlur, handleChange, touched, errors, setFieldValue, resetForm } = useFormik(
    {
      initialValues: {
        first_name: '',
        last_name: '',
        email: '',
        company_id: ''
      },
      validationSchema: addTeamMemberSchema,
      validateOnChange: true,
      validateOnBlur: true
    }
  );

  useEffect(() => {
    if (temsInfo) {
      setTeamDetails(temsInfo);
    }
  }, [temsInfo]);

  useEffect(() => {
    if (companyDetails) {
      setFieldValue('company_id', companyDetails.id);
    }
  }, [companyDetails]);

  useEffect(() => {
    if (!isLoading && successMessage) {
      setCurrentIndex('');
      handleClose();
    }
  }, [isLoading]);

  const handleSearch = useCallback(
    (value) => {
      const temp = [...temsInfo];

      if (value && value.length > 1) {
        const searchedData = temp.filter((data) =>
          data.first_name.toLowerCase().includes(value.toLowerCase())
        );
        setTeamDetails(searchedData);
      } else {
        setTeamDetails(temp);
      }
    },
    [temsInfo]
  );

  const handleModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    resetForm();
    setOpen(false);
  };

  const handleStatus = (id, status, index) => {
    const payload = { id: id, active: !status };
    setCurrentIndex(index, payload);
    dispatch(editTeamMemberRequest(payload));
  };

  const handleAddTeam = () => {
    dispatch(addTeamMemberRequest(values));
  };

  const handleTitleClick = (item) => {
    if (item.user_id !== userId) {
      navigation(`${SCREEN_PATH.VIEW_USER_PROFILE}?role=${roleId}&id=${item.id}`, {
        state: {
          type: defaultValues.listConstants.customers
        }
      });
    }
  };

  return (
    <Box sx={styles.container}>
      <PopupModal
        showModel={open}
        onClose={handleClose}
        customContentWidth={styles.customWidth}
        heading={'Add new member'}
        showDivider={true}
        titlePosition={true}>
        <Box sx={styles.maxW}>
          <Box sx={styles.fieldContainer}>
            <UITextInputWithTitle
              title="First Name"
              name="first_name"
              placeholder="enter first name"
              variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
              onChange={handleChange}
              value={values.first_name}
              error={errors.first_name}
              onBlur={handleBlur}
              touched={touched.first_name}
              allowOnlyAlphabets={true}
            />
            <UITextInputWithTitle
              name="last_name"
              title="Last Name"
              placeholder="enter last name"
              variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
              value={values.last_name}
              onChange={handleChange}
              error={errors.last_name}
              touched={touched.last_name}
              allowOnlyAlphabets={true}
              onBlur={handleBlur}
            />
            <UITextInputWithTitle
              title="Email-id"
              name="email"
              placeholder="enter email-id"
              variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
              onChange={handleChange}
              value={values.email}
              error={errors.email}
              onBlur={handleBlur}
              touched={touched.email}
            />
          </Box>
          <UIButton
            title={'Save'}
            disabled={
              !values.email.length ||
              !values.last_name.length ||
              !values.first_name ||
              errors.email ||
              errors.first_name ||
              errors.last_name
            }
            style={styles.btnStyle}
            onClick={handleAddTeam}
            isLoading={isLoading}
          />
        </Box>
      </PopupModal>
      <Box sx={styles.contentContainer}>
        <Box sx={styles.searchTabContainer}>
          <Box sx={styles.searchToolsContainer}>
            {!defaultValues.isResponsive ? (
              <SearchBar
                label={defaultValues.teamDetails.searchLabel}
                searchedVlaue={handleSearch}
              />
            ) : (
              <Text style={styles.headingText} variant="medium">
                Available team members
              </Text>
            )}
            <UIButton
              style={styles.newButton}
              title={defaultValues.teamDetails.addBtn}
              onClick={handleModal}
            />
          </Box>
        </Box>

        {defaultValues.isResponsive && (
          <Box sx={styles.searchContainer}>
            <SearchBar label={defaultValues.teamDetails.searchLabel} searchedVlaue={handleSearch} />
          </Box>
        )}

        {!defaultValues.isResponsive ? (
          teamDetails.length > 0 ? (
            <Table sx={styles.tableContainer} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.headingCell} align="left">
                    <Text variant="tableTitle">Member</Text>
                  </TableCell>
                  <TableCell sx={styles.headingCell} align="left">
                    <Text variant="tableTitle">Date of association</Text>
                  </TableCell>
                  <TableCell sx={styles.headingCell} align="left">
                    <Text variant="tableTitle">Status</Text>
                  </TableCell>
                  <TableCell sx={styles.headingCell} align="center">
                    <Text variant="tableTitle">Actions</Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamDetails &&
                  teamDetails.map((row, index) => (
                    <TableRow key={row.id} sx={styles.tableRow}>
                      <TableCell component="th" scope="row" align="left">
                        <AdminProfile
                          titleStyle={
                            row.user_id !== userId && {
                              ...customStyles.link,
                              ...customStyles.sTitle
                            }
                          }
                          profile={row}
                          onTitleClick={() => handleTitleClick(row)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        <Title title={getFormatDate(row.date_of_association)} />
                      </TableCell>
                      <TableCell align="center">
                        <StatusIndicator status={row.active} />
                      </TableCell>
                      <TableCell sx={customStyles.actionCont} align="left">
                        <ActionButton
                          isEdit={false}
                          item={row}
                          isLoading={index === currentIndex && !open && isLoading}
                          onButtonClick={() => handleStatus(row.id, row.active, index)}
                          disabled={currentUserData.email === row.email}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <NoData message={defaultValues.noTeamMembers} />
          )
        ) : (
          <Box sx={styles.collapseContainer}>
            <CollapsableList
              isEdit={false}
              contentArray={teamDetails}
              type={defaultValues.teamDetails.name}
              onButtonClick={handleStatus}
              loaderSize={defaultValues.isResponsive && 15}
              onManageTitleClick={(row) => handleTitleClick(row)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
