import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const responsive = defaultValues.isResponsive;
const xsm = defaultValues.isXSmall;

export const dragNdropStyles = {
  browseContainer: {
    minWidth: xsm ? '70px' : responsive ? '170px' : '250px',
    maxWidth: xsm ? '100%' : responsive && '130px',
    backgroundColor: color.palette.primaryRed,
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    mb: 2,
    mt: 1,
    p: 1,
    boxSizing: 'border-box',
    cursor: 'pointer'
  },
  fileUploadContainer: { width: '100%', mt: responsive ? '8px' : 2 },
  uploadContainer: {
    width: 'calc(100% - 4px)',
    height: responsive ? '150px' : '200px',
    border: `2px dashed ${color.palette.gray}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: responsive ? '20px' : '40px'
  },
  uploadIcon: {
    width: responsive ? '25px' : '30px',
    height: responsive ? '25px' : '30px',
    marginRight: '8px'
  },
  browseText: {
    color: color.palette.primaryWhite,
    fontSize: responsive ? '12px' : '16px',
    overflowX: 'hidden'
  },
  dropText: {
    color: color.palette.gray,
    fontSize: responsive && '12px'
  },
  dropArea: {
    '& .drop_area drop_zone': {
      width: '100%'
    }
  },
  underLine: { color: color.palette.primaryBlack, cursor: 'pointer' }
};
