import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
const responsive = defaultValues.isResponsive;
export const modalStyle = {
  container: {
    position: 'absolute',
    top: '50%',
    left: responsive ? '50%' : '75%',
    transform: responsive ? 'translate(-50%, -50%)' : 'translate(-70%, -50%)',
    width: responsive ? '80%' : '70%',
    backgroundColor: color.primaryBackground,
    borderRadius: '5px',
    boxShadow: 24,
    p: responsive ? 3 : 4,
    maxWidth: '70%',
    minHeight: responsive ? '70vh' : 'none',
    border: 'none'
  },
  headingContainer: {
    width: responsive ? '100%' : 'fit-content',
    borderBottom: responsive ? `1px solid ${color.primaryBorder}` : 'none'
  },
  heading: {
    fontSize: responsive ? '20px' : '40px',
    textAlign: responsive ? 'left' : 'none',
    marginBottom: responsive ? '20px' : 'none'
  },
  contentContainer: {
    width: '100%',
    minHeight: responsive ? '90%' : '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10px',
    position: responsive ? 'relative' : 'static'
  },
  stateContainer: (stateBoxHeight) => ({
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: responsive ? '170px' : '200px',
    overflowY: stateBoxHeight === 200 || stateBoxHeight === 170 ? 'scroll' : 'none'
  }),
  inputContainer: {
    width: responsive ? '100%' : '80%',
    marginBottom: '15px'
  },
  inputSubContainer: {
    width: '100%'
  },
  input: {
    width: '100%',
    backgroundColor: color.palette.textFieldRed,
    marginTop: '10px',
    fontSize: responsive ? '14px' : '24px',
    '& .MuiTextField-root': {
      border: 'none'
    }
  },
  button: (disabled) => ({
    backgroundColor: disabled ? color.palette.primaryDisabledRed : color.palette.primaryRed,
    color: disabled ? color.palette.lightGray : color.palette.primaryWhite,
    width: responsive ? '93%' : 'fit-content',
    '&:hover': {
      backgroundColor: color.palette.primaryRed
    },
    position: responsive ? 'absolute' : 'none',
    bottom: 10,
    fontSize: responsive ? '14px' : '22px',
    minHeight: responsive ? '40px' : '50px',
    minWidth: '200px'
  }),
  closeButton: {
    maxWidth: '100%',
    maxHeight: responsive ? '40px' : '50px',
    backgroundColor: color.palette.primaryRed,
    borderRadius: '50%',
    position: 'absolute',
    top: '-25px',
    right: responsive ? '-20px' : '-25px',
    cursor: 'pointer'
  },
  errorField: {
    color: color.palette.primaryRed,
    marginTop: '3px',
    fontSize: '14px'
  },
  textArea: {
    width: '100%',
    backgroundColor: color.palette.textFieldRed,
    borderRadius: '4px',
    height: '100px',
    padding: '10px',
    boxSizing: 'border-box',
    fontSize: responsive ? '16px' : '24px',
    marginTop: '10px'
  },
  icon: { color: color.palette.gray, fontSize: responsive ? '30px' : '43px', padding: 0 },
  checkedIcon: {
    color: color.palette.primaryRed,
    fontSize: responsive ? '30px' : '43px',
    padding: 0
  },
  chipButton: {
    margin: responsive ? '10px 10px 0px 0px' : '20px 20px 0px 0px',
    backgroundColor: color.palette.primaryWhite,
    color: color.palette.primaryBlack,
    borderRadius: '5px',
    fontSize: responsive ? '14px' : '20px',
    padding: '0px',
    height: responsive ? '30px' : '45px',
    minWidth: '70px',
    border: `1px solid ${color.primaryBorder}`
  },
  deleteIcon: {
    color: color.palette.primaryWhite,
    paddingLeft: responsive ? '20px' : '10px'
  },
  stateAddButton: (value) => ({
    position: 'absolute',
    right: '6px',
    top: '17px',
    color: !value ? color.palette.lightGray : color.palette.primaryWhite,
    backgroundColor: value ? color.palette.primaryRed : color.palette.primaryDisabledRed,
    fontSize: responsive ? '10px' : '22px',
    padding: responsive ? '5px 18px' : '3px 40px'
  }),
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
};
