import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { Text } from '../../../ui-kit';
import { ProjectStatusIndicator } from '../../other-settings/table-title';
import { styles } from './styles';
import { getProjectFlagsTitle } from '../../../utils';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { customStyles } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import { setSelectedTab } from '../../../services/redux/slices';

export const AssociatedProjectTable = ({ listData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return !defaultValues.isResponsive ? (
    <Table
      sx={{
        ...styles.tableContainer,
        borderCollapse: 'collapse'
      }}
      aria-label="simple table">
      <TableHead sx={styles.tableHead}>
        <TableRow>
          <TableCell sx={styles.headingCell} align="left">
            Project details
          </TableCell>
          <TableCell sx={styles.headingCell} align="left">
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listData &&
          listData.length > 0 &&
          listData.map((item, index) => (
            <TableRow key={index}>
              <TableCell sx={styles.maxW} align="left">
                <Box>
                  <Text
                    variant="small"
                    style={{ ...styles.maxWidth, ...customStyles.link, ...customStyles.sTitle }}
                    onClick={() => {
                      dispatch(setSelectedTab(2));

                      navigate(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                        state: { data: item }
                      });
                    }}>
                    {item.project_title}
                  </Text>
                  <Text variant="responsiveText">{getProjectFlagsTitle(item.flag)}</Text>
                </Box>
              </TableCell>
              <TableCell align="left">
                <ProjectStatusIndicator id={item.status} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  ) : (
    <>
      {listData &&
        listData.map((item, index) => (
          <Accordion
            key={index}
            sx={styles.resContainer}
            expanded={expanded === index}
            onChange={handleChange(index)}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={styles.expandIcon} />}
              aria-controls={index}
              sx={styles.summeryBox}
              id={index}>
              <Box>
                <Text
                  variant="medium"
                  style={{ ...styles.maxWidth, ...customStyles.link, ...customStyles.sTitle }}
                  onClick={() => {
                    navigate(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                      state: { data: item }
                    });
                  }}>
                  {item.project_title}
                </Text>
                <Text variant="responsiveDesc">{getProjectFlagsTitle(item.flag)}</Text>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={customStyles.w100}>
                <Box sx={styles.itemContainer}>
                  <Box sx={styles.actionButtonContainer}>
                    <Text variant="responsiveDesc">Status</Text>
                  </Box>
                  <ProjectStatusIndicator id={item.status} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};
