import { Box } from '@mui/material';
import { Filter, Screen, Text } from '../../ui-kit';
import { UICheckBox } from '../../ui-kit/checkbox';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { style } from './style';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNotificationCountRequest,
  getNotificationRequest,
  resetMessages,
  updateNotificationRequest
} from '../../services/redux/slices';
import {
  getNextPages,
  getNotification,
  getNotificationCount,
  loginError,
  loginLoadingStatus,
  loginSuccessMsg
} from '../../services/redux/selectors';
import { toast } from 'react-toastify';
import { getFormattedTime, getRandomColor, resetMessge } from '../../utils';
import { LoadingScreen } from '../../ui-kit/loading-screen';
import { color } from '../../theme';
import { NoData } from '../../components';

export const Notification = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const allNotifications = useSelector(getNotification);
  const successMessage = useSelector(loginSuccessMsg);
  const isLoading = useSelector(loginLoadingStatus);
  const errorMessage = useSelector(loginError);
  const nextPage = useSelector(getNextPages);
  const count = useSelector(getNotificationCount);

  const [isRead, setIsRead] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [initialCall, setInitialCall] = useState(true);

  const fetchNotifications = async (read_status = null, page = 1) => {
    const response = await dispatch(getNotificationRequest({ read_status, page }));
    if (response.payload.page === 1) {
      setInitialCall(true);
    } else {
      setInitialCall(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleScroll = () => {
    const container = containerRef.current;
    if (parseInt(container.scrollTop + container.clientHeight) >= container.scrollHeight - 5) {
      if (nextPage !== -1) {
        fetchNotifications(filterValue, nextPage);
      }
    }
  };

  const handleFilter = (data) => {
    setFilterValue(data);
    setNotifications([]);
    fetchNotifications(data);
  };

  useEffect(() => {
    if (allNotifications) {
      if (initialCall) {
        setNotifications(allNotifications);
        const newIsRead = {};
        allNotifications.forEach((notification) => {
          newIsRead[notification.id] = notification.read_status;
        });
        setIsRead(newIsRead);
      } else {
        setNotifications((prev) => {
          const newNotifications = allNotifications.filter((notification) => {
            return !prev.some(
              (existingNotification) => existingNotification.id === notification.id
            );
          });

          const newIsRead = {};
          newNotifications.forEach((notification) => {
            newIsRead[notification.id] = notification.read_status;
          });

          setIsRead((prevStatus) => ({
            ...prevStatus,
            ...newIsRead
          }));

          return [...prev, ...newNotifications];
        });
      }
    }
    dispatch(getNotificationCountRequest());
  }, [successMessage, errorMessage, allNotifications]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage !== 'E-10125') {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetMessages()));
  }, [successMessage, errorMessage]);

  const handleMarkRead = (type, notification_id) => {
    setInitialCall(false);
    if (type === 2) {
      const allIds = {};
      notifications.forEach((notification) => {
        allIds[notification.id] = true;
      });
      setIsRead(allIds);
    } else {
      setIsRead((prevStatus) => ({
        ...prevStatus,
        [notification_id]: true
      }));
    }
    dispatch(updateNotificationRequest({ type, notification_id }));
  };

  return (
    <Screen showSideBar={true} currentPage={'Notification'}>
      <div ref={containerRef} onScroll={handleScroll} style={style.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={style.subContainer}>
          <Box sx={style.optionsContainer}>
            {count > 0 && (
              <Text style={style.markText} onClick={() => handleMarkRead(2)}>
                Mark all as read
              </Text>
            )}
            <Filter
              label={'Filter'}
              options={defaultValues.notificationFilterOption}
              selectedOption={(data) => handleFilter(data)}
            />
          </Box>

          <Box sx={style.notificationContainer}>
            {notifications.length > 0 ? (
              notifications.map((item, index) => (
                <Box sx={style.notificationCard} key={index}>
                  <Box sx={style.color(getRandomColor(color.palette.notificationColors))}></Box>
                  <Box sx={style.details}>
                    <Text variant={defaultValues.isResponsive ? 'responsiveText' : 'desc'}>
                      {item.notification}
                    </Text>
                    <Text variant={defaultValues.isResponsive ? 'resDesc' : 'responsiveText'}>
                      {getFormattedTime(item.created_at)}
                    </Text>
                  </Box>
                  <Box sx={style.checkBoxContainer}>
                    {!isRead[item.id] && (
                      <Box sx={style.checkBox}>
                        <UICheckBox
                          type={'square'}
                          onChange={() => handleMarkRead(1, item.id)}
                          checked={isRead[item.id]}
                          disabled={isRead[item.id]}
                          iconColor={color.palette.green}
                          sx={style.checkBoxStyle}
                        />
                        <Text variant={defaultValues.isResponsive ? 'resDesc' : 'responsiveText'}>
                          Mark as read
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
              ))
            ) : (
              <NoData message={getErrorMessage('E-10125')} />
            )}
          </Box>
        </Box>
      </div>
    </Screen>
  );
};
