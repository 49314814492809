import {
  Filter,
  PaginationCustom,
  SearchBar,
  Sort,
  Text,
  ActionButton,
  IconsButton
} from '../../../ui-kit';
import { cStyles } from './styles';
import { Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useEffect, useState } from 'react';
import { EDIT_KEY, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { CollapsableList, NoData, StatusIndicator } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import companyPic from '../../../assets/images/companyPic.png';
import { toast } from 'react-toastify';
import {
  getCustomerCompanyRequest,
  pushCustomerCompanyRequest,
  resetCustomerCompanyMessages,
  updateCompanyDetailsTab,
  updateCustomerFilters,
  updateDetailsData
} from '../../../services/redux/slices/customerCompanySlice';
import {
  cCompanyLoadingStatus,
  getCCompanies,
  getCCompaniesCurrentPg,
  getCCompaniesTotalPg,
  getCCompanyErrorMessage,
  getCCompanySuccessMessage,
  getCustomerCompanyIndicator,
  getDetailsData,
  getFilters
} from '../../../services/redux/selectors/customerCompanySelector';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { CustomerCompanyDetails } from '../company-details';
import { getPhotoUrl } from '../../../utils';
import { customStyles } from '../../../theme/styles';

export const CompanyOverview = () => {
  const dispatch = useDispatch();
  const successMessage = useSelector(getCCompanySuccessMessage);
  const errorMessage = useSelector(getCCompanyErrorMessage);
  const isLoading = useSelector(cCompanyLoadingStatus);
  const selCompanies = useSelector(getCCompanies);
  const currentPage = useSelector(getCCompaniesCurrentPg);
  const totalPage = useSelector(getCCompaniesTotalPg);
  const indicator = useSelector(getCustomerCompanyIndicator);
  const filters = useSelector(getFilters);
  const detailsData = useSelector(getDetailsData);

  const [cCompanies, setCCompanies] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getCustomerCompany();
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    dispatch(resetCustomerCompanyMessages());
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (selCompanies) {
      setCCompanies(selCompanies);
    }
  }, [selCompanies]);

  useEffect(() => {
    if (indicator) {
      getCustomerCompany();
    }
  }, [indicator]);

  const getCustomerCompany = (data) => {
    let payload = { page: currentPage };
    if (data !== undefined) {
      payload = { ...payload, ...filters, ...data };
    }
    setFilterData(payload);
    dispatch(updateCustomerFilters(payload));
    dispatch(getCustomerCompanyRequest(payload));
  };

  const handleSearch = (search_text) => {
    if (search_text.length === 0 || search_text.length >= 3) {
      getCustomerCompany({ search_text });
    }
  };

  const editCustomerCompany = async (payload) => {
    const data = { payload, type: EDIT_KEY };
    dispatch(pushCustomerCompanyRequest(data));
  };

  return (
    <Box sx={cStyles.container}>
      <LoadingScreen isLoading={isLoading} />
      {detailsData ? (
        <CustomerCompanyDetails
          cData={detailsData}
          onBack={() => {
            dispatch(updateDetailsData(null));
            dispatch(updateCompanyDetailsTab(defaultValues.companyDetailsTabs[0].value));
          }}
        />
      ) : (
        <Box sx={cStyles.contentContainer}>
          <Box sx={cStyles.searchTabContainer}>
            <Box sx={cStyles.searchToolsContainer}>
              {!defaultValues.isResponsive && (
                <SearchBar
                  label={'search for a company'}
                  searchedVlaue={handleSearch}
                  onRefresh={!filters.search_text && {}}
                  defaultValue={filters.search_text}
                />
              )}
            </Box>

            <Box sx={cStyles.toolsContainer}>
              <IconsButton
                title={!defaultValues.isResponsive ? null : 'Refresh'}
                icon={<RefreshIcon sx={cStyles.icon} />}
                onClick={() => {
                  getCustomerCompany({ sort: null, filter: null, search_text: null, page: null });
                }}
              />
              <Sort
                selectedOption={(sort) => getCustomerCompany({ sort, page: 1 })}
                activeOption={filterData.sort}
              />
              <Filter
                label={'Filter'}
                applyFilter={(data) => {
                  getCustomerCompany({ filter: JSON.stringify(data), page: 1 });
                }}
                activeData={filterData && filterData.filter}
              />
            </Box>
          </Box>

          {!defaultValues.isResponsive ? (
            <Text sx={cStyles.heading} variant="fileName">
              {defaultValues.companyOverviewHeading}
            </Text>
          ) : (
            <Box sx={cStyles.searchContainer}>
              <SearchBar
                label={'search for a company'}
                searchedVlaue={handleSearch}
                onRefresh={!filters.search_text && {}}
                defaultValue={filters.search_text}
              />
            </Box>
          )}

          {!defaultValues.isResponsive ? (
            cCompanies.length > 0 ? (
              <Table
                sx={{ ...cStyles.tableContainer, borderCollapse: 'collapse' }}
                aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={cStyles.headingCell} align="left">
                      Company name
                    </TableCell>
                    <TableCell sx={cStyles.headingCell} align="left">
                      Status
                    </TableCell>
                    <TableCell sx={cStyles.headingCell} align="center">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cCompanies &&
                    cCompanies.map((row, index) => (
                      <TableRow key={index} sx={cStyles.tableRow}>
                        <TableCell sx={cStyles.maxW}>
                          <Box sx={cStyles.nameBox}>
                            <img
                              style={cStyles.profileImage}
                              src={
                                row.company_logo_url
                                  ? getPhotoUrl(row.company_logo_url)
                                  : companyPic
                              }
                            />
                            <Text
                              variant="desc"
                              style={{
                                ...cStyles.name,
                                ...customStyles.link,
                                ...customStyles.sTitle
                              }}
                              onClick={() => dispatch(updateDetailsData(row))}>
                              {row.company_name}
                            </Text>
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          <StatusIndicator status={row.active} />
                        </TableCell>
                        <TableCell sx={customStyles.actionCont} align="left">
                          <ActionButton
                            item={row}
                            isEdit={false}
                            name={row.company_name}
                            subStyle={cStyles.actionSub}
                            onButtonClick={() =>
                              editCustomerCompany({ id: row.id, active: !row.active })
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <NoData message={getErrorMessage('E-10075')} />
            )
          ) : (
            <Box sx={cStyles.collapseContainer}>
              <CollapsableList
                contentArray={cCompanies}
                type={defaultValues.listConstants.company_customer}
                isEdit={false}
                onCustClick={(val) => dispatch(updateDetailsData(val))}
                onEditClick={() => {}}
                loaderSize={defaultValues.isResponsive && 15}
                onButtonClick={(id, active) => editCustomerCompany({ id, active: !active })}
              />
            </Box>
          )}

          {currentPage && totalPage && !isLoading && (
            <Box sx={cStyles.paginationContainer}>
              <PaginationCustom
                onChange={(e, value) => {
                  getCustomerCompany({ page: value });
                }}
                currentPage={currentPage}
                count={totalPage}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
