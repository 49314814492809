import React, { useState } from 'react';
import profilePic from '../../../assets/images/adminProPic.png';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CollapseStyles } from './style';
import { Box } from '@mui/material';
import { ActionButton, Text } from '../../../ui-kit';
import { StatusIndicator } from '../../other-settings/table-title';
import { customStyles } from '../../../theme';
import { getPhotoUrl } from '../../../utils';

export const CustomerCollapsableList = ({ list, onViewDetailsClick, onChangeStatus }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {list.map((item, index) => {
        return (
          <Accordion
            key={index}
            sx={CollapseStyles.container}
            expanded={expanded === index}
            onChange={handleChange(index)}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={CollapseStyles.expandIcon} />}
              aria-controls={index}
              sx={CollapseStyles.summeryBox}
              id={index}>
              <Box sx={customStyles.flexBox}>
                <img
                  src={item.customer_photo ? getPhotoUrl(item.customer_photo) : profilePic}
                  style={CollapseStyles.iconStyle}
                />
                <Box>
                  <Text
                    variant="medium"
                    onClick={() => onViewDetailsClick && onViewDetailsClick(item)}
                    style={{
                      ...CollapseStyles.maxWidth,
                      ...customStyles.link,
                      ...customStyles.sTitle
                    }}>
                    {item.customer_first_name + ' ' + item.customer_last_name}
                  </Text>
                  <Text variant="responsiveDesc">{item.customer_email}</Text>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={CollapseStyles.listContainer}>
                <Box sx={CollapseStyles.itemContainer}>
                  <Box sx={CollapseStyles.actionButtonContainer}>
                    <Text variant="responsiveDesc">Company Name</Text>
                  </Box>
                  <Text variant="responsiveDesc">{item.customar_company_name}</Text>
                </Box>

                <Box sx={CollapseStyles.itemContainer}>
                  <Box sx={CollapseStyles.actionButtonContainer}>
                    <Text variant="responsiveDesc">Status</Text>
                  </Box>
                  <StatusIndicator status={item.active} />
                </Box>

                <Box sx={CollapseStyles.itemContainer}>
                  <Box sx={CollapseStyles.actionButtonContainer}>
                    <Text variant="responsiveDesc">Action</Text>
                  </Box>
                  <Box sx={CollapseStyles.actionValue}>
                    <ActionButton
                      isEdit={false}
                      item={item}
                      onButtonClick={() => onChangeStatus(item)}
                    />
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
