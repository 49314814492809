import { put, takeLatest } from 'redux-saga/effects';
import {
  associateCustomerCompanyAPI,
  enquiryOtpAPI,
  enquiryOtpVerifyAPI,
  registerCustomerCompanyAPI,
  resendEnquiryOtpAPI,
  resendSupplierOtpApi,
  searchCustomerCompanyAPI,
  submitEnquiryAPI,
  supplierRegisterApi,
  verifySupplierApi
} from '../../api';
import {
  associateCompanyFailure,
  associateCompanyRequest,
  associateCompanySuccess,
  authenticationRequest,
  autoLoginSupplier,
  enquiryOtpRequest,
  enquiryOtpRequestFailure,
  enquiryOtpRequestSuccess,
  enquiryVerifyOtpRequest,
  enquiryVerifyOtpRequestFailure,
  enquiryVerifyOtpRequestSuccess,
  isRegisteredCompany,
  registerCompanyFailure,
  registerCompanyRequest,
  registerCompanySuccess,
  resendEnquiryOtpRequest,
  resendEnquiryOtpRequestFailure,
  resendEnquiryOtpRequestSuccess,
  searchCompanyFailure,
  searchCompanyRequest,
  searchCompanySuccess,
  submitEnquiryFailure,
  submitEnquiryRequest,
  submitEnquirySuccess,
  supplierRegistrationFailure,
  supplierRegistrationRequest,
  supplierRegistrationSuccess,
  supplierResendOtpFailure,
  supplierResendOtpRequest,
  supplierResendOtpSuccess,
  supplierVerificationFailure,
  supplierVerificationRequest,
  supplierVerificationSuccess
} from '../slices';
import { SUCCESS_KEY } from '../../../constants';

function* getEnquiryOtpRequest(action) {
  try {
    const response = yield enquiryOtpAPI(action.payload);

    if (response.data.status === SUCCESS_KEY) {
      yield put(enquiryOtpRequestSuccess(response.data));
    } else {
      yield put(enquiryOtpRequestFailure(response.data));
    }
  } catch (error) {
    yield put(enquiryOtpRequestFailure('E-10001'));
  }
}

function* getEnquiryVerifyOtpRequest(action) {
  try {
    const response = yield enquiryOtpVerifyAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(enquiryVerifyOtpRequestSuccess(response.data));
    } else {
      yield put(enquiryVerifyOtpRequestFailure(response.data));
    }
  } catch (error) {
    yield put(enquiryVerifyOtpRequestFailure('E-10001'));
  }
}

function* getEnquiryResendOtpRequest(action) {
  try {
    const response = yield resendEnquiryOtpAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(resendEnquiryOtpRequestSuccess(response.data));
    } else {
      yield put(resendEnquiryOtpRequestFailure(response.data));
    }
  } catch (error) {
    yield put(resendEnquiryOtpRequestFailure('E-10001'));
  }
}

function* searchCustomerCompany(action) {
  try {
    const response = yield searchCustomerCompanyAPI(action.payload);
    if (response.data.status_code === 'S-10017') {
      yield put(searchCompanySuccess(response.data));
    } else {
      yield put(isRegisteredCompany(response.data));
    }
  } catch (error) {
    yield put(searchCompanyFailure('E-10001'));
  }
}

function* registerCompany(action) {
  try {
    const response = yield registerCustomerCompanyAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(registerCompanySuccess(response.data));
    } else {
      yield put(registerCompanyFailure(response.data));
    }
  } catch (error) {
    yield put(registerCompanyFailure('E-10001'));
  }
}

function* submitEnquiry(action) {
  try {
    const response = yield submitEnquiryAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(submitEnquirySuccess(response.data));
      yield put(authenticationRequest(response.data));
    } else {
      yield put(submitEnquiryFailure(response.data));
    }
  } catch (error) {
    yield put(submitEnquiryFailure('E-10001'));
  }
}

function* registerSupplier(action) {
  try {
    const response = yield supplierRegisterApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(supplierRegistrationSuccess(response.data));
    } else {
      yield put(supplierRegistrationFailure(response.data));
    }
  } catch (error) {
    yield put(supplierRegistrationFailure('E-10001'));
  }
}

function* verifySupplier(action) {
  try {
    const verificationResponse = yield verifySupplierApi(action.payload);
    if (verificationResponse.data.status === SUCCESS_KEY) {
      yield put(supplierVerificationSuccess(verificationResponse.data));
      yield put(autoLoginSupplier());
    } else {
      yield put(supplierVerificationFailure(verificationResponse.data));
    }
  } catch (error) {
    yield put(supplierVerificationFailure('E-10001'));
  }
}

function* supplierResendOtp(action) {
  try {
    const response = yield resendSupplierOtpApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(supplierResendOtpSuccess(response.data));
    } else {
      yield put(supplierResendOtpFailure(response.data));
    }
  } catch (error) {
    yield put(supplierResendOtpFailure('E-10001'));
  }
}

function* associateCustomerCompany(action) {
  try {
    const response = yield associateCustomerCompanyAPI(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(associateCompanySuccess(response.data));
    } else {
      yield put(associateCompanyFailure(response.data));
    }
  } catch (error) {
    yield put(associateCompanyFailure('E-10001'));
  }
}

export function* serviceSaga() {
  yield takeLatest(enquiryOtpRequest.type, getEnquiryOtpRequest);
  yield takeLatest(enquiryVerifyOtpRequest.type, getEnquiryVerifyOtpRequest);
  yield takeLatest(resendEnquiryOtpRequest.type, getEnquiryResendOtpRequest);
  yield takeLatest(searchCompanyRequest.type, searchCustomerCompany);
  yield takeLatest(registerCompanyRequest.type, registerCompany);
  yield takeLatest(submitEnquiryRequest.type, submitEnquiry);
  yield takeLatest(supplierRegistrationRequest.type, registerSupplier);
  yield takeLatest(supplierVerificationRequest.type, verifySupplier);
  yield takeLatest(supplierResendOtpRequest.type, supplierResendOtp);
  yield takeLatest(associateCompanyRequest.type, associateCustomerCompany);
}
