import { Box, Divider } from '@mui/material';
import { AddQuestionForm } from './addQuestionForm';
import { useCallback, useEffect, useState } from 'react';
import { AddQuestionStyle } from '../style';
import { Text, TextInput, UIButton, UIRadioButton, UISwitch } from '../../../ui-kit';
import { defaultValues } from '../../../constants';
import { UICheckBox } from '../../../ui-kit/checkbox';
import { QuestionView } from '../../question-view';

export const QuestionBox = ({
  key,
  addQuestion,
  enableSubQuestion = false,
  optionIndex,
  editData,
  removeQuestion,
  onBlur,
  id,
  name,
  error,
  touched,
  showAction,
  updateQuestion,
  questionMenu
}) => {
  const [question, setQuestion] = useState('');
  const [active, setActive] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectQa, setSelectQa] = useState();
  const [showSubQaIndex, setShowSubQaIndex] = useState(null);
  const [editQuestion, setEditQuestion] = useState();

  useEffect(() => {
    if (editData) {
      setQuestion(editData.question);
      setSelectQa(
        defaultValues.questionTypes.filter((item) => item.answer_type === editData.answer_type)[0]
      );
      if (editData.options) {
        setOptions([...editData.options]);
      }
      editData.active && setActive(editData.active);
    }
  }, [editData]);

  const onAddQuestion = () => {
    let data = {
      question: question,
      answer_type: selectQa.answer_type,
      options: [],
      active: true,
      editable: true
    };

    if (editData) {
      data.order_number = editData.order_number;
    }

    if (options.length > 0) {
      data.options = options.map((item, index) => {
        let opData = {
          option: item.option,
          active: item.active,
          editable: item.editable,
          order_number: index + 1,
          sub_questions: [...item.sub_questions]
        };
        if (item.id) {
          opData.id = item.id;
        }
        return opData;
      });
    }
    setQuestion('');
    setSelectQa();
    if (showAction) {
      data.active = active;
      data.id = editData.id;
      updateQuestion(data);
    } else {
      addQuestion(data, optionIndex);
    }
  };

  const onRemove = () => {
    setQuestion('');
    setSelectQa();
    removeQuestion(editData.order_number, optionIndex);
    setShowSubQaIndex(null);
  };

  const handleAddQuestion = (data, index) => {
    setEditQuestion();
    let filterData = [...options];
    if (data.order_number) {
      filterData[index] = {
        ...filterData[index],
        sub_questions: filterData[index].sub_questions.map((val) =>
          val.order_number === data.order_number ? data : val
        )
      };
    } else {
      filterData[index] = {
        ...filterData[index],
        sub_questions: [
          ...filterData[index].sub_questions,
          {
            order_number: options[index].sub_questions.length + 1,
            active: true,
            ...data
          }
        ]
      };
    }
    setOptions(filterData);
    setShowSubQaIndex(null);
  };

  const handleRemoveQuestion = (orderNo, index) => {
    let filterOption = JSON.parse(JSON.stringify(options));
    let subQ = [];
    options[index].sub_questions.forEach((item) => {
      if (item.id && item.order_number === orderNo) {
        subQ = [...subQ, { ...item, active: false }];
      } else if (item.order_number !== orderNo) {
        subQ = [...subQ, item];
      }
    });
    filterOption[index].sub_questions = subQ;
    setShowSubQaIndex(null);
    setOptions(filterOption);
    setEditQuestion();
  };

  const addSubQuestion = (index) => {
    setShowSubQaIndex(index);
    setEditQuestion();
  };

  const getQuestionComponent = useCallback(
    (typeData) => {
      if (typeData.answer_type === 3) {
        return optionBox(<UIRadioButton checked={false} />);
      } else if (typeData.answer_type === 4) {
        return optionBox(<UICheckBox checked={false} />);
      }
      return <></>;
    },
    [selectQa, options, showSubQaIndex]
  );

  const optionBox = useCallback(
    (child) => {
      return (
        <Box>
          {options.map(
            (item, index) =>
              item.active && (
                <>
                  <Box sx={AddQuestionStyle.questionBox}>
                    <Box sx={AddQuestionStyle.btnFlex}>
                      {child}
                      <TextInput
                        name="question"
                        variant="outlined"
                        placeholder={`option ${index + 1}`}
                        style={AddQuestionStyle.qaInput}
                        showClear={true}
                        value={item.option}
                        onChange={(e) => {
                          setOptions((oldOption) => {
                            const updatedOp = oldOption.map((item, i) => {
                              if (i === index) {
                                return { ...item, option: e.target.value };
                              }
                              return item;
                            });
                            return updatedOp;
                          });
                        }}
                        onClear={() => {
                          if (options[index] && editData) {
                            setOptions((old) => {
                              return old.map((item, i) =>
                                i === index ? { ...item, active: false } : item
                              );
                            });
                          } else {
                            setOptions((oldValue) => {
                              const li = oldValue.filter((value, i) => i != index);
                              return li.length === 0
                                ? [{ option: '', active: true, sub_questions: [] }]
                                : li;
                            });
                          }
                        }}
                      />
                    </Box>
                    {enableSubQuestion && (
                      <UIButton
                        title="+ sub question(s)"
                        endIcon={<></>}
                        disabled={options[index].value === ''}
                        style={AddQuestionStyle.qaBtn}
                        onClick={() => addSubQuestion(index)}
                        aria-expanded={open ? 'true' : undefined}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                      />
                    )}
                  </Box>
                  {item.sub_questions &&
                    (item.sub_questions.length > 0 || showSubQaIndex === index) && (
                      <Box sx={AddQuestionStyle.subQuestionBox}>
                        <Text variant="title">Sub Questions</Text>
                        <Divider sx={AddQuestionStyle.diver} />
                        {item.sub_questions.map((sItem, i) => {
                          if (sItem.active) {
                            return (
                              <QuestionView
                                key={i + sItem.question}
                                data={sItem}
                                onEditQuestion={(data) => {
                                  setShowSubQaIndex(index);
                                  setEditQuestion(data);
                                }}
                              />
                            );
                          }
                        })}
                        {showSubQaIndex === index && (
                          <QuestionBox
                            key={`${index + 'QBox'}`}
                            addQuestion={handleAddQuestion}
                            optionIndex={index}
                            editData={editQuestion}
                            removeQuestion={handleRemoveQuestion}
                            questionMenu={defaultValues.questionTypes.filter(
                              (item) => item.answer_type < 3
                            )}
                          />
                        )}
                        <Divider sx={AddQuestionStyle.diver2} />
                      </Box>
                    )}
                </>
              )
          )}
          <UIButton
            variant="text"
            sx={AddQuestionStyle.textBtn}
            disabled={options[options.length - 1].value === ''}
            onClick={() =>
              setOptions([
                ...options,
                {
                  option: '',
                  active: true,
                  sub_questions: []
                }
              ])
            }
            title={
              <Text variant="small" style={AddQuestionStyle.textBtnText}>
                + option
              </Text>
            }
          />
        </Box>
      );
    },
    [options, showSubQaIndex]
  );

  if (showAction) {
    return (
      <Box
        sx={{
          ...AddQuestionStyle.mainQuestionBox,
          ...(error && touched ? AddQuestionStyle.errorBorder : {})
        }}
        key={key}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <UISwitch title="Activated" check={active} onChange={() => setActive(!active)} />
          <UIButton
            title={'update question'}
            variant="outlined"
            sx={AddQuestionStyle.addButton}
            onClick={onAddQuestion}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        ...AddQuestionStyle.mainQuestionBox,
        ...(error && touched ? AddQuestionStyle.errorBorder : {})
      }}
      key={key}>
      <AddQuestionForm
        id={id}
        name={name}
        onBlur={onBlur}
        question={question}
        onAddOptions={(data) => setOptions([data])}
        questionValue={(d) => setQuestion(d)}
        onSelectQa={(data) => {
          setSelectQa(data);
          setOptions([]);
        }}
        questionType={selectQa}
        questionMenu={questionMenu}
      />
      {selectQa && getQuestionComponent(selectQa)}
      {selectQa && (
        <Box sx={AddQuestionStyle.bottomBtnBox}>
          {showAction === true && (
            <UISwitch title="Activated" check={active} onChange={() => setActive(!active)} />
          )}
          <Box sx={{ marginLeft: 'auto' }}>
            {editData && showAction !== true && (
              <UIButton
                title="- remove"
                variant="outlined"
                sx={{
                  ...AddQuestionStyle.addButton,
                  marginRight: '20px'
                }}
                onClick={onRemove}
              />
            )}
            <UIButton
              title={editData ? 'update question' : '+ add question'}
              variant="outlined"
              sx={AddQuestionStyle.addButton}
              onClick={onAddQuestion}
            />
          </Box>
        </Box>
      )}
      {error && touched && <Text variant="fieldError">{error}</Text>}
    </Box>
  );
};
