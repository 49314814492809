import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const md = defaultValues.isMedium;

export const Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row'
  },
  labelContainer: {
    width: defaultValues.isResponsive ? '100%' : '30%',
    justifyContent: defaultValues.isResponsive ? 'left' : 'right',
    display: 'flex',
    alignItems: !defaultValues.isResponsive && 'center'
  },
  valueContainer: {
    ml: 2,
    mt: !defaultValues.isResponsive && 1,
    width: defaultValues.isResponsive ? '100%' : '70%',
    display: 'flex',
    flexWrap: 'wrap'
  },

  cardContainer: {
    p: 1,
    border: `1px solid ${color.primaryBorder}`,
    width: defaultValues.isResponsive ? '100%' : '40%',
    height: '170px',
    overflow: 'auto'
  },
  checkBox: '1px 0px',
  calenderIconWithText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: defaultValues.isResponsive ? '60%' : '64%',
    my: 1
  },
  date: { display: 'flex', flexDirection: 'row' },
  calenderIcon: {
    ml: 1,
    cursor: 'pointer',
    fontSize: defaultValues.isResponsive ? '20px' : '30px'
  },
  btnContainer: { display: 'flex', alignItems: 'center' },
  cancelBtn: {
    backgroundColor: color.primaryBackground,
    color: color.palette.red,
    border: `1px solid ${color.palette.red}`,
    marginLeft: '10px',
    width: defaultValues.isResponsive ? '110px' : '165px',
    fontSize: defaultValues.isResponsive ? '12px' : '16px'
  },
  txtMargin: { mt: 1, fontSize: defaultValues.isResponsive ? '12px' : md ? '16px' : '22px' },
  repitContainer: { ml: 2, mt: 1, width: defaultValues.isResponsive ? '100%' : '50%' },
  dividerStyle: { my: 2 },
  btnStyle: {
    width: defaultValues.isResponsive ? '110px' : '165px',
    fontSize: defaultValues.isResponsive ? '12px' : '16px'
  },
  saveBtnStyle: {
    width: defaultValues.isResponsive ? '140px' : '195px',
    fontSize: defaultValues.isResponsive ? '12px' : '16px'
  },
  fieldStyle: (isRes) => ({
    inputContainer: { width: isRes ? '100%' : '80%', marginTop: '25px' },
    title: { fontSize: isRes ? '16px' : '28px', mb: '5px' }
  }),
  inputSize: { border: 'none', fontSize: defaultValues.isResponsive ? '12px' : '22px' }
};
