import { Box } from '@mui/material';
import { Text, UIButton } from '../../ui-kit';
import { styles } from './style';
import { ConvertedTime, truncateText } from '../../utils';
import { defaultValues } from '../../constants';

export const JobCard = ({ data, onTitleClick, onButtonClick, timezone }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleContainer}>
        <Box>
          <Text
            variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
            style={styles.navLink}
            onClick={onTitleClick}>
            {truncateText(data.job_title, defaultValues.isResponsive ? 10 : 20)}
          </Text>
          <Text
            variant={defaultValues.isResponsive ? 'responsiveText' : 'tableTitle'}
            style={styles.textStyle}>
            {truncateText(data.project_name, defaultValues.isResponsive ? 10 : 25)}
          </Text>
          <Text variant={defaultValues.isResponsive ? 'resDesc' : 'small'} style={styles.textColor}>
            {data.job_id}
          </Text>
        </Box>

        <UIButton
          title={'Apply for job'}
          style={styles.btnStyle}
          onClick={() => onButtonClick()}
          disabled={data.hasApplied}
        />
      </Box>
      <Box sx={styles.boxStyle}>
        <Text
          variant={defaultValues.isResponsive ? 'responsiveText' : 'tableTitle'}
          style={styles.textStyle}>
          Service required
        </Text>
        <Text variant={defaultValues.isResponsive ? 'resDesc' : 'small'} style={styles.textColor}>
          {data.service.title}
        </Text>
      </Box>
      <Box sx={styles.boxStyle}>
        <Text
          variant={defaultValues.isResponsive ? 'responsiveText' : 'tableTitle'}
          style={styles.textStyle}>
          Timeline
        </Text>
        <Box sx={styles.timelineCont}>
          <ConvertedTime
            time={data.start_date}
            originalTimeZoneName={data.timezone}
            targetTimeZoneName={timezone}
            style={styles.textClr}
          />
          <Box sx={styles.to}>
            <Text style={styles.textClr}>to</Text>
          </Box>
          <ConvertedTime
            time={data.end_date}
            originalTimeZoneName={data.timezone}
            targetTimeZoneName={timezone}
            style={styles.textClr}
          />
        </Box>
      </Box>
    </Box>
  );
};
