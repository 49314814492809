import React from 'react';
import { Screen, Text, UIButton } from '../../ui-kit';
import { Box, Grid, Modal } from '@mui/material';
import { LandingStyles } from './landingPageStyle';
import LandingImagePrimary from '../../assets/images/landing_image_primary.png';
import icon_part_one from '../../assets/icons/icon_part_one.png';
import { SCREEN_PATH, defaultValues } from '../../constants';
import iconTwo from '../../assets/icons/icon_part_two.png';
import iconThree from '../../assets/icons/icon_part_three.png';
import vector_two from '../../assets/icons/Vector 2.png';
import { Link, useNavigate } from 'react-router-dom';
import { EbookCard, FindOutCard, Footer, Goal, ServiceCard } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { loginPageStatus } from '../../services/redux/selectors';
import { SignInPage } from './signInPage';
import { setLoginPage } from '../../services/redux/slices';

export const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoginModalVisible = useSelector(loginPageStatus);

  const hideLoginModal = () => {
    dispatch(setLoginPage(!isLoginModalVisible));
  };

  return (
    <Screen showHeader={true}>
      <Box style={LandingStyles.container}>
        <Box style={LandingStyles.sectionContainer}>
          <Box style={LandingStyles.introContainer}>
            <Box sx={LandingStyles.introSubContainer}>
              <Text variant={defaultValues.isResponsive ? 'fileName' : 'header'}>
                {defaultValues.landingPage.heading1}{' '}
              </Text>
              <Text
                style={LandingStyles.heading2}
                variant={defaultValues.isResponsive ? 'fileName' : 'header'}>
                {defaultValues.landingPage.heading2}
              </Text>
              <Text
                style={LandingStyles.description}
                variant={defaultValues.isResponsive ? 'responsiveText' : 'body'}>
                {defaultValues.landingPage.description}
              </Text>
              <Link to={defaultValues.landingPageUrls.viewLiveDemo}>
                <UIButton
                  variant="contained"
                  title="View live demonstration"
                  style={LandingStyles.liveDemonstrationBtn}
                />
              </Link>
            </Box>
            <Box sx={LandingStyles.imageContainer}>
              <img
                style={LandingStyles.landingImagePrimary}
                alt="image"
                src={LandingImagePrimary}
              />
            </Box>
            {!defaultValues.isResponsive ? (
              <img style={LandingStyles.iconPartOne} alt="icon" src={icon_part_one} />
            ) : (
              <img style={LandingStyles.vector2} alt="icon" src={vector_two} />
            )}
          </Box>

          <Box sx={LandingStyles.signupContainer}>
            <Text
              variant={defaultValues.isResponsive ? 'small' : 'secondaryHeader'}
              style={LandingStyles.primaryWhite}>
              {defaultValues.landingPage.signupHeading}
            </Text>
            <Text
              variant={defaultValues.isResponsive ? 'responsiveDesc' : 'body'}
              style={LandingStyles.primaryWhite}>
              {defaultValues.landingPage.signupDescription}
            </Text>
            <UIButton
              title="Sign Up"
              style={LandingStyles.signUpButton}
              onClick={() => navigate(SCREEN_PATH.SUPPLIER_REGISTER)}
            />
          </Box>

          <Box sx={LandingStyles.enquireNowContainer}>
            <Text
              variant={defaultValues.isResponsive ? 'small' : 'secondaryHeader'}
              style={LandingStyles.primaryWhite}>
              {defaultValues.landingPage.enquireHeading}
            </Text>
            <Text
              variant={defaultValues.isResponsive ? 'responsiveDesc' : 'body'}
              style={LandingStyles.primaryWhite}>
              {defaultValues.landingPage.enquireDescription}
            </Text>
            <UIButton
              title="Book now"
              onClick={() => navigate(SCREEN_PATH.ENQUIRE)}
              style={LandingStyles.enqireButton}
            />
          </Box>
        </Box>

        <Box sx={LandingStyles.serviceContainer}>
          <Text variant={defaultValues.isResponsive ? 'fileName' : 'header'}>
            {defaultValues.ourServicesHeading1}
            <span style={LandingStyles.heading2}>{defaultValues.ourServicesHeading2}</span>
          </Text>

          <Box sx={LandingStyles.SCardCont}>
            {defaultValues.ourServicesSection.map((item, index) => (
              <Box key={index} sx={LandingStyles.sCard}>
                <ServiceCard
                  heading={item.heading}
                  description={item.description}
                  buttonStyle={LandingStyles.buttonColor(item.color)}
                  icon={item.icon}
                  url={item.url}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={LandingStyles.serviceContainer}>
          <Text variant={defaultValues.isResponsive ? 'fileName' : 'header'}>
            {defaultValues.findOutHeader1}{' '}
            <span style={LandingStyles.heading2}>{defaultValues.findOutHeader2}</span>
          </Text>
          <Grid sx={LandingStyles.findOutContainer} container spacing={2}>
            {defaultValues.findOut.map((item, index) => (
              <Grid key={index} item xs={12} md={4} lg={4}>
                <FindOutCard
                  heading={item.heading}
                  icon={item.icon}
                  description={item.description}
                />
              </Grid>
            ))}
          </Grid>

          {!defaultValues.isResponsive && (
            <img style={LandingStyles.iconPartTwo} alt="icon" src={iconTwo} />
          )}
          {!defaultValues.isResponsive && (
            <img style={LandingStyles.iconPartThree} alt="icon" src={iconThree} />
          )}
        </Box>

        <Box sx={LandingStyles.secondaryContainer}>
          <Goal />
        </Box>

        <Box sx={LandingStyles.serviceContainer}>
          <EbookCard />
        </Box>

        <Footer />
      </Box>
      {isLoginModalVisible && (
        <Modal open={isLoginModalVisible} onClose={hideLoginModal}>
          <Box sx={LandingStyles.modalContainer}>
            <SignInPage manageLogin={hideLoginModal} />
          </Box>
        </Modal>
      )}
    </Screen>
  );
};
