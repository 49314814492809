import { Box } from '@mui/material';
import { color } from '../../theme';
import { saveAs } from 'file-saver';
import { Text } from '../../ui-kit';
import download from '../../assets/icons/download.png';
import deleteIcon from '../../assets/icons/mdi_delete.png';
import { defaultValues } from '../../constants';

export const PdfViewer = ({
  url,
  containerStyles,
  subContainer,
  altStyles,
  isDownload = false,
  isDelete = false,
  onDelete,
  altName = 'PDF!',
  type = 'application/pdf'
}) => {
  const res = defaultValues.isResponsive;
  const pdfStyles = {
    pdfViewer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '65vh',
      marginTop: '20px',
      position: 'relative',
      ...containerStyles
    },
    pdfViewSubcontainer: {
      width: '80%',
      height: '60vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      border: `1px solid ${color.primaryBorder}`,
      borderRadius: '8px',
      ...subContainer
    },
    pdfIcon: {
      height: '50px',
      width: '50px',
      cursor: 'pointer',
      position: 'absolute',
      top: 80,
      right: 28
    },
    errorContainer: {
      height: '250px',
      width: '60%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    objectPdf: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px'
    },
    altText: {
      textAlign: 'center',
      fontSize: '14px',
      ...altStyles
    },
    download: {
      color: color.palette.primaryBlue,
      cursor: 'pointer'
    },
    downloadIcon: {
      position: 'absolute',
      bottom: '10px',
      right: res ? '10px' : '30px',
      width: res ? '30px' : '50px',
      height: res ? '30px' : '50px',
      cursor: 'pointer'
    },
    deleteIcon: {
      position: 'absolute',
      bottom: '10px',
      right: res ? '50px' : '90px',
      width: res ? '30px' : '50px',
      height: res ? '30px' : '50px',
      cursor: 'pointer'
    },
    noFoundText: { textAlign: 'center' }
  };

  const handleDownload = () => {
    const pdfUrl = url;
    let arr = url.split('/');
    saveAs(pdfUrl, `${arr[arr.length - 1]}`);
  };

  return (
    <Box sx={pdfStyles.pdfViewer}>
      {url ? (
        <Box sx={pdfStyles.pdfViewSubcontainer}>
          <object data={url} type={type} width="100%" height="100%" style={pdfStyles.objectPdf}>
            <p style={pdfStyles.altText}>
              <u style={pdfStyles.download} onClick={handleDownload}>
                click here
              </u>
              &nbsp;to view or download the {altName}
            </p>
          </object>
          {isDownload && (
            <img src={download} style={pdfStyles.downloadIcon} onClick={handleDownload} />
          )}
          {isDelete && <img src={deleteIcon} style={pdfStyles.deleteIcon} onClick={onDelete} />}
        </Box>
      ) : (
        <Box sx={pdfStyles.errorContainer}>
          <Text style={pdfStyles.noFoundText} variant={'question'}>
            {'No document found'}
          </Text>
        </Box>
      )}
    </Box>
  );
};
