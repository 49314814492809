import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { PopupModal, Screen, Text, UIAutocompleted, UIButton } from '../../../ui-kit';
import { pRStyles } from './style';
import { useCallback, useEffect, useState } from 'react';
import { customStyles } from '../../../theme';
import { ArrowForwardIos } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdminSuccessMessage,
  getProjecsErrorMessage,
  getProjectDetailsSel,
  getProjectManagers,
  getProjectsLoadingStatus,
  getProjectsNextPage,
  getProjectsStatus,
  getProjectsSuccessMessage,
  getQuotation,
  getQuotationId,
  getUserType,
  userInfo
} from '../../../services/redux/selectors';
import {
  acceptQuotationRequest,
  assignPMRequest,
  editRatingRequest,
  getProjectDetailsRequest,
  projectManagersRequest,
  rejectQuotationRequest,
  removePMRequest,
  removeSupplierRequest,
  resendQuotationRequest,
  resetAdminMessages,
  resetProjectsMessages,
  sendQuotationRequest,
  sendRatingRequest,
  updateProjectStatusRequest,
  viewQuotationRequest
} from '../../../services/redux/slices';
import {
  ConvertedTime,
  getFormatDate,
  getFormatDateNotUtc,
  resetMessge,
  truncateText
} from '../../../utils';
import { ProjectStatus, StatusIndicator } from '../../other-settings/table-title';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { CustomModal } from '../../common/modals/status-modal';
import { toast } from 'react-toastify';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { ManageProjectsModal } from '../../common/modals/manage-projects-modal';
import { ProjectMangerCard } from '../../common/card/projectMangerCard';
import { CollapsableList } from '../../common/collapsable-list';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { CheckBox } from '@mui/icons-material';
import { UpdateEventTime } from '../../common/update-event-date';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export const ProjectRequirement = () => {
  const isMobile = defaultValues.isResponsive;
  const userData = useSelector(userInfo);

  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoading = useSelector(getProjectsLoadingStatus);
  const projectDetSel = useSelector(getProjectDetailsSel);
  const userType = useSelector(getUserType);
  const statuses = useSelector(getProjectsStatus);
  const successMessage = useSelector(getProjectsSuccessMessage);
  const success = useSelector(getAdminSuccessMessage);
  const errorMessage = useSelector(getProjecsErrorMessage);
  const quotation = useSelector(getQuotation);
  const quotationId = useSelector(getQuotationId);
  const allProjectManagers = useSelector(getProjectManagers);
  const nextPage = useSelector(getProjectsNextPage);

  let timer;
  const [modelData, setModeData] = useState(null);
  const [quotModel, setQuotModel] = useState(null);
  const [projectDetails, setProjectDetails] = useState({});
  const [fileUrl, setFileUrl] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [projectManagersList, setProjectManagerSList] = useState([]);
  const [projectManger, setProjectManager] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [supplierId, setSupplierId] = useState(null);
  const [ratingSuppId, setRatingSuppId] = useState('');
  const [questions, setQuestions] = useState([]);
  const [openRating, setOpenRating] = useState(false);
  const [rating, setRating] = useState('');
  const [ratingModelType, setRatingModelType] = useState('');
  const [ratingBtnDisable, setRatingBtnDisable] = useState(true);
  const [rateEditMode, setRateEditMode] = useState(false);
  const [eventTime, setEventTime] = useState('');
  const [id, setId] = useState('');
  const [openUpdate, setOpenUpdate] = useState(false);
  const [userTimezone, setUserTimezone] = useState(null);
  const [formatedEventTime, setFormatedEventTime] = useState(null);
  const [eventZone, setEventZone] = useState('');

  const handleFormatedTime = (data) => {
    setFormatedEventTime(data);
  };

  const handleEditTime = (id, data, timezone) => {
    setOpenUpdate(true);
    setEventTime(data);
    setEventZone(timezone);
    setId(id);
  };

  const getProStatus = (id) => {
    const data = statuses.filter((item) => item.id === id);
    if (data && data.length === 1) {
      return data[0];
    }
  };

  useEffect(() => {
    setFileUrl(quotation);
  }, [quotation]);

  const addListData = useCallback(
    (data) => {
      if (data) {
        let filterData = [...projectManagersList];
        data.forEach((item) => {
          if (filterData.findIndex((e) => e.id === item.id) < 0) {
            filterData.push(item);
          }
        });
        setProjectManagerSList(filterData);
      }
    },
    [projectManagersList]
  );

  useEffect(() => {
    if (userData) {
      setUserTimezone(userData.timezone);
    }
  }, [userData]);

  useEffect(() => {
    if (allProjectManagers && allProjectManagers.length > 0) {
      addListData(allProjectManagers);
    }
  }, [allProjectManagers]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      getProjectDetails();
      setQuotModel(null);
      handleClose();
      handleRemoveReplaceClose();
      handleCloseRating();
    }
    if (errorMessage) {
      setErrMsg(errorMessage);
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(success));
    }
    resetMessge(() => dispatch(resetAdminMessages()));
  }, [success]);

  const getProjectDetails = () => {
    if (location.state) {
      let payload = {
        project_id: location.state.data.id
      };
      dispatch(getProjectDetailsRequest(payload));
    }
  };

  useEffect(() => {
    if (projectDetSel) {
      setProjectDetails(projectDetSel);
    }
  }, [projectDetSel]);

  const updateProjectStatus = () => {
    const payload = {
      project_id: projectDetails.id,
      status_id: modelData.id
    };
    dispatch(updateProjectStatusRequest(payload));
    setModeData(null);
  };

  const getTitle = () => {
    return [projectDetails.customer_name, projectDetails.company_name]
      .filter((value) => value !== null && value !== '')
      .join(', ');
  };

  const handleQuot = () => {
    if (projectDetails.project_status.key !== defaultValues.projectsStatus.initialContact) {
      dispatch(viewQuotationRequest({ project_id: projectDetails.id }));
    }
    setQuotModel(
      projectDetails.project_status.id === 1
        ? defaultValues.quotModelType.send
        : defaultValues.quotModelType.view
    );
  };

  const handleQuotationSubmit = (val, type = null) => {
    let payload = null;
    if (quotModel === defaultValues.quotModelType.send) {
      payload = {
        project_id: projectDetails.id,
        quotation_file: val
      };
      dispatch(sendQuotationRequest(payload));
    } else if (
      quotModel === defaultValues.quotModelType.view &&
      userType === defaultValues.userRole.customer
    ) {
      if (!type) {
        payload = {
          quotation_id: quotationId.toString()
        };
        dispatch(acceptQuotationRequest(payload));
      }
      if (type === 'reject') {
        payload = {
          quotation_id: quotationId.toString(),
          rejection_reason: val
        };
        dispatch(rejectQuotationRequest(payload));
      }
    } else if (quotModel === defaultValues.quotModelType.edit) {
      payload = {
        project_id: projectDetails.id,
        quotation_file: val
      };
      dispatch(resendQuotationRequest(payload));
    }
  };

  const handleProjectManagers = (data) => {
    let payload = { page: 1 };
    if (data) {
      payload = { ...payload, ...data };
    }
    dispatch(projectManagersRequest(payload));
  };

  const handleOpen = () => {
    setOpen(true);

    handleProjectManagers();
  };

  const handleRemoveReplaceClose = () => {
    setModeData(null);
    setRating('');
    setSupplierId('');
    setRatingSuppId('');
  };
  const handleClose = () => {
    setOpen(false);
    setChecked(false);
    setButtonDisabled(true);
    setProjectManager({});
  };

  const handleScrollEnd = () => {
    if (nextPage !== -1) {
      handleProjectManagers({ page: nextPage });
    }
  };

  const searchList = (search_text) => {
    let payload = {};
    if (search_text) {
      payload = { search_text };
    }
    handleProjectManagers(payload);
  };

  const handleCheck = () => {
    if (!checked) {
      setProjectManager({});
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    setChecked(!checked);
  };

  const handleAssignPM = () => {
    let payload = { project_id: projectDetails.id };
    if (checked) {
      payload = { ...payload, self_pm: true };
    } else {
      payload = {
        ...payload,
        pm_id: projectManger.id
      };
    }
    dispatch(assignPMRequest(payload));
    handleClose();
  };

  const handleReplacePM = () => {
    setModeData(null);
    handleOpen();
  };

  const handleRemovePM = () => {
    let payload = { project_id: projectDetails.id };
    dispatch(removePMRequest(payload));
  };

  const handleRemoveSupplier = async () => {
    let payload = { project_id: projectDetails.id, project_supplier_id: supplierId };
    if (modelData && modelData.title === defaultValues.listConstants.supplier) {
      dispatch(removeSupplierRequest(payload));
    }
    if (modelData && modelData.title === defaultValues.listConstants.rate_supplier) {
      let ratingPayload = {
        project_id: projectDetails.id,
        rating: rating,
        supplier_id: ratingSuppId
      };
      dispatch(sendRatingRequest(ratingPayload));
      dispatch(removeSupplierRequest(payload));
    }
  };

  useEffect(() => {
    if (projectDetails.questions) {
      rearrangeData(projectDetails.questions);
    }
  }, [projectDetails]);

  const rearrangeData = (data) => {
    const rearrangedData = [];

    if (data && data.length > 0) {
      data.forEach((item) => {
        if (item.parent_question_id === null) {
          rearrangedData.push(item);
        }
      });

      data.forEach((item) => {
        if (item.parent_question_id !== null) {
          const parentIndex = rearrangedData.findIndex(
            (parent) => parent.question_id === item.parent_question_id
          );
          if (parentIndex !== -1) {
            rearrangedData.splice(parentIndex + 1, 0, item);
          }
        }
      });
    }

    setQuestions(rearrangedData);
  };

  const handleOpenRating = () => {
    setOpenRating(true);
  };

  const handleCloseRating = () => {
    setRating('');
    setRatingModelType('');
    setOpenRating(false);
    setRatingBtnDisable(true);
    setSupplierId('');
  };

  const handleRatingChange = (val) => {
    setRating(val);
    setRatingBtnDisable(false);
  };

  const ratingHeading = () => {
    if (ratingModelType === defaultValues.listConstants.company_name) {
      if (rateEditMode) {
        return 'Edit company rating';
      }
      return 'Rate company';
    }
    if (ratingModelType === defaultValues.listConstants.supplier) {
      if (rateEditMode) {
        return 'Edit supplier rating';
      }
      return 'Rate supplier';
    }
  };

  const handleCompanyRatingSubmit = () => {
    let payload = {};
    if (rateEditMode) {
      payload = {
        customer_company_rating_id: projectDetails.customer_company_rating.id,
        rating: rating
      };
      dispatch(editRatingRequest(payload));
    } else {
      payload = {
        project_id: projectDetails.id,
        rating: rating,
        customer_company_id: projectDetails.customer_company_id
      };
      dispatch(sendRatingRequest(payload));
    }
  };

  const handleSupplierRatingSubmit = () => {
    let payload = {};
    if (rateEditMode) {
      payload = {
        supplier_rating_id: supplierId,
        rating: rating
      };
      dispatch(editRatingRequest(payload));
    } else {
      payload = {
        project_id: projectDetails.id,
        rating: rating,
        supplier_id: supplierId
      };
      dispatch(sendRatingRequest(payload));
    }
  };

  return (
    <Screen showSideBar={true} currentPage="Project details">
      <PopupModal
        showModel={openUpdate}
        onClose={() => setOpenUpdate(false)}
        heading={'Update event time'}>
        <UpdateEventTime
          time={eventTime}
          id={id}
          timezone={eventZone}
          submited={(data) => setOpenUpdate(!data)}
        />
      </PopupModal>
      <LoadingScreen isLoading={!openRating && !open && !modelData && isLoading} />
      <Box sx={pRStyles.container}>
        <Box sx={pRStyles.topNav}>
          <Text
            onClick={() => {
              navigation(-1);
            }}
            variant="titleBold"
            style={pRStyles.navLink}>
            {truncateText(projectDetails.project_name, 15)}
          </Text>
          {!isMobile && <ArrowForwardIos sx={pRStyles.icon} />}
          <Text style={pRStyles.idleText} variant="titleBold">
            Project requirements
          </Text>
        </Box>
        {userType !== defaultValues.userRole.supplier && (
          <Box sx={pRStyles.projectDetailsContainer}>
            <Box sx={pRStyles.cDetailsCont}>
              <Box>
                <Text
                  variant={defaultValues.isResponsive ? 'answer' : 'projectSubTitle'}
                  style={{ ...customStyles.wordBr, ...pRStyles.projectName }}>
                  {getTitle()}
                </Text>

                {userType === defaultValues.userRole.customer && (
                  <Text variant={defaultValues.isResponsive ? 'resSmallLight' : 'smallLight'}>
                    {projectDetails.start_date
                      ? `Started on ${getFormatDate(projectDetails.start_date, 'DD-MM-YYYY hh:mm A')}`
                      : `Received on ${getFormatDateNotUtc(
                          projectDetails.received_date,
                          'DD-MM-YYYY hh:mm A'
                        )}`}
                  </Text>
                )}
                {(userType === defaultValues.userRole.admin ||
                  userType === defaultValues.userRole.superAdmin ||
                  userType === defaultValues.userRole.projectManger) && (
                  <Box sx={pRStyles.convCont}>
                    <Text variant={defaultValues.isResponsive ? 'resSmallLight' : 'smallLight'}>
                      {projectDetails.start_date ? 'Started on : ' : 'Received on : '}
                    </Text>
                    {projectDetails.start_date ? (
                      <ConvertedTime
                        time={projectDetails.start_date}
                        originalTimeZoneName={projectDetails.event_timezone}
                        targetTimeZoneName={defaultValues.uKTimeZone}
                        style={pRStyles.clr}
                      />
                    ) : (
                      <Text variant={defaultValues.isResponsive ? 'resSmallLight' : 'smallLight'}>
                        {getFormatDateNotUtc(projectDetails.received_date, 'DD-MM-YYYY hh:mm A')}
                      </Text>
                    )}
                  </Box>
                )}
              </Box>
              {[defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(
                userType
              ) &&
                projectDetails &&
                projectDetails.project_status &&
                projectDetails.project_status.id === 7 && (
                  <Text
                    sx={pRStyles.rateComp}
                    onClick={() => {
                      handleOpenRating();
                      setRatingModelType(defaultValues.listConstants.company_name);
                      if (projectDetails.customer_company_rating) {
                        setRateEditMode(true);
                        setRating(projectDetails.customer_company_rating.rating);
                      } else {
                        setRateEditMode(false);
                      }
                    }}>
                    {projectDetails && projectDetails.customer_company_rating
                      ? 'Edit company rating'
                      : 'Rate company'}
                  </Text>
                )}
            </Box>
            <Stack sx={pRStyles.statusContainer}>
              <Text style={pRStyles.statusText} variant={defaultValues.isResponsive && 'desc'}>
                Current status
              </Text>
              {projectDetails.project_status && (
                <ProjectStatus
                  data={{ status: projectDetails.project_status }}
                  role={userType}
                  options={statuses}
                  onChange={(data, statusId) => {
                    setModeData(getProStatus(statusId));
                  }}
                />
              )}
            </Stack>
            {(((userType === defaultValues.userRole.customer ||
              userType === defaultValues.userRole.supplier ||
              userType === defaultValues.userRole.projectManger) &&
              projectDetails &&
              projectDetails.project_status &&
              projectDetails.project_status.order > 1) ||
              [defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(
                userType
              )) && (
              <UIButton
                title={
                  projectDetails.project_status && projectDetails.project_status.order > 1
                    ? 'View quote'
                    : 'Send quote'
                }
                style={pRStyles.quoteBtn}
                onClick={handleQuot}
              />
            )}
          </Box>
        )}

        {[defaultValues.userRole.admin, defaultValues.userRole.superAdmin].includes(userType) && (
          <Box sx={pRStyles.projectDetailsContainer}>
            <Box sx={pRStyles.additionalTop}>
              <Text
                style={{ ...pRStyles.proDHead, ...pRStyles.thirdTitle }}
                variant={isMobile ? 'bold' : 'menuTextBold'}>
                Additional project details
              </Text>
              {projectDetails &&
                projectDetails.suppliers &&
                projectDetails.suppliers.length !== 0 && (
                  <UIButton
                    style={pRStyles.quoteBtn}
                    title="+ new supplier"
                    onClick={() => {
                      navigation(SCREEN_PATH.PROJECT_SUPPLIER_HUB, {
                        state: { data: projectDetails }
                      });
                    }}
                  />
                )}
              <></>
            </Box>

            {projectDetails && projectDetails.project_manager ? (
              <ProjectMangerCard
                name={
                  projectDetails.project_manager.first_name +
                  ' ' +
                  projectDetails.project_manager.last_name
                }
                email={projectDetails.project_manager.email}
                id={2}
                onReplace={() => setModeData({ title: defaultValues.listConstants.Replace })}
                onRemove={() => setModeData({ title: defaultValues.listConstants.Remove })}
              />
            ) : (
              <Box sx={pRStyles.additionalBox}>
                <Text
                  sx={pRStyles.thirdTitle}
                  variant={defaultValues.isResponsive ? 'smallBold' : 'bold'}>
                  Project manager -
                </Text>
                <UIButton
                  title="assign project manager"
                  style={pRStyles.w250}
                  onClick={handleOpen}
                />
              </Box>
            )}
            {projectDetails && projectDetails.suppliers && projectDetails.suppliers.length > 0 ? (
              isMobile ? (
                <Box sx={pRStyles.collapseContainer}>
                  <Text variant="titleBold" style={{ ...pRStyles.tH, ...pRStyles.thirdTitle }}>
                    Assigned suppliers
                  </Text>
                  <CollapsableList
                    contentArray={projectDetails.suppliers}
                    type={defaultValues.listConstants.supplier}
                    loaderSize={defaultValues.isResponsive && 15}
                    childComment={(row) => (
                      <Box sx={pRStyles.collBox}>
                        <Text variant="responsiveDesc" style={pRStyles.w58}>
                          Action
                        </Text>
                        <Box sx={pRStyles.collAction}>
                          <UIButton
                            title="Remove"
                            style={pRStyles.actionBtn}
                            onClick={() => {
                              if (row.rating) {
                                setModeData({ title: defaultValues.listConstants.supplier });
                              } else {
                                setModeData({
                                  title: defaultValues.listConstants.rate_supplier
                                });
                              }
                              setSupplierId(row.project_supplier_id);
                              setRatingSuppId(row.id);
                            }}
                          />
                          {projectDetails &&
                            projectDetails.project_status &&
                            projectDetails.project_status.id === 6 && (
                              <Text
                                style={pRStyles.rateComp}
                                onClick={() => {
                                  handleOpenRating();
                                  setRatingModelType(defaultValues.listConstants.supplier);
                                  if (row.rating) {
                                    setRateEditMode(true);
                                    setRating(row.rating.rating);
                                    setSupplierId(row.rating.id);
                                  } else {
                                    setRateEditMode(false);
                                    setSupplierId(row.id);
                                  }
                                }}>
                                {row.rating ? 'Edit rating' : 'Rate supplier'}
                              </Text>
                            )}
                        </Box>
                      </Box>
                    )}
                  />
                </Box>
              ) : (
                <Box sx={pRStyles.supplierLiBox}>
                  <Table sx={pRStyles.tableContainer} aria-label="simple table">
                    <TableHead sx={pRStyles.bgColor}>
                      <TableRow>
                        <TableCell sx={pRStyles.headingCell} align="left">
                          Assigned suppliers
                        </TableCell>
                        <TableCell sx={pRStyles.headingCell} align="left">
                          Status
                        </TableCell>
                        <TableCell sx={pRStyles.headingCell} align="center">
                          Action
                        </TableCell>
                        <TableCell sx={pRStyles.headingCell} align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projectDetails.suppliers.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left" sx={pRStyles.w35}>
                            <Box>
                              <Text>{row.first_name + ' ' + row.last_name}</Text>
                              <Text style={pRStyles.email} variant="lightBody">
                                {row.email}
                              </Text>
                            </Box>
                          </TableCell>
                          <TableCell align="left">
                            <StatusIndicator status={row.active} styles={customStyles.flexBox} />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ ...pRStyles.w15, ...customStyles.actionCont }}>
                            <Box sx={pRStyles.btnBox}>
                              <UIButton
                                title="Remove"
                                onClick={() => {
                                  if (row.rating) {
                                    setModeData({ title: defaultValues.listConstants.supplier });
                                  } else {
                                    setModeData({
                                      title: defaultValues.listConstants.rate_supplier
                                    });
                                  }
                                  setSupplierId(row.project_supplier_id);
                                  setRatingSuppId(row.id);
                                }}
                              />
                            </Box>
                          </TableCell>
                          {projectDetails &&
                            projectDetails.project_status &&
                            projectDetails.project_status.id === 7 && (
                              <TableCell align="center">
                                <Text
                                  style={pRStyles.rateComp}
                                  onClick={() => {
                                    handleOpenRating();
                                    setRatingModelType(defaultValues.listConstants.supplier);
                                    if (row.rating) {
                                      setRateEditMode(true);
                                      setRating(row.rating.rating);
                                      setSupplierId(row.rating.id);
                                    } else {
                                      setRateEditMode(false);
                                      setSupplierId(row.id);
                                    }
                                  }}>
                                  {row.rating ? 'Edit rating' : 'Rate supplier'}
                                </Text>
                              </TableCell>
                            )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )
            ) : (
              <Box sx={pRStyles.additionalBox}>
                <Text
                  sx={pRStyles.thirdTitle}
                  variant={defaultValues.isResponsive ? 'smallBold' : 'bold'}>
                  Assigned supplier(s) -
                </Text>
                <UIButton
                  title="assign supplier(s)"
                  style={pRStyles.w250}
                  onClick={() =>
                    navigation(SCREEN_PATH.PROJECT_SUPPLIER_HUB, {
                      state: { data: projectDetails }
                    })
                  }
                />
              </Box>
            )}
          </Box>
        )}

        <Box sx={pRStyles.eventDetailsContainer}>
          <Text
            sx={pRStyles.thirdTitle}
            variant={defaultValues.isResponsive ? 'semiBold' : 'menuTextBold'}>
            Event details
          </Text>
          <Box sx={pRStyles.eventDetailsSubContainer}>
            <Stack sx={pRStyles.rowStyle}>
              <Stack sx={pRStyles.column}>
                <Text sx={pRStyles.tertiaryTitle} variant="bold">
                  Service name
                </Text>
              </Stack>
              <Stack sx={pRStyles.column}>
                <Text style={pRStyles.secondaryText}>{projectDetails.service_name}</Text>
              </Stack>
            </Stack>

            <Stack sx={pRStyles.rowStyle}>
              <Stack sx={pRStyles.column}>
                <Text sx={pRStyles.tertiaryTitle} variant="bold">
                  Timezone
                </Text>
              </Stack>
              <Stack sx={pRStyles.column}>
                {userType === defaultValues.userRole.customer ? (
                  <Text style={pRStyles.secondaryText}>{projectDetails.event_timezone}</Text>
                ) : userType === defaultValues.userRole.supplier ? (
                  <Text style={pRStyles.secondaryText}>{truncateText(userTimezone, '40')}</Text>
                ) : (
                  <Text style={pRStyles.secondaryText}>{defaultValues.uKTimeZone}</Text>
                )}
                <Text
                  style={pRStyles.tLink}
                  onClick={() => window.open('https://www.worldtimebuddy.com/', '_blank')}>
                  Check event time in your timezone
                </Text>
              </Stack>
            </Stack>

            <Stack sx={pRStyles.rowStyle}>
              <Stack sx={pRStyles.column}>
                <Text sx={pRStyles.tertiaryTitle} variant="bold">
                  Spoken languages
                </Text>
              </Stack>
              <Stack sx={pRStyles.column}>
                <Text style={pRStyles.secondaryText}>
                  {projectDetails.spoken_languages &&
                    projectDetails.spoken_languages.map((item) => item.language).join(', ')}
                </Text>
              </Stack>
            </Stack>

            <Stack sx={pRStyles.rowStyle}>
              <Stack sx={pRStyles.column}>
                <Text sx={pRStyles.tertiaryTitle} variant="bold">
                  Captioning languages
                </Text>
              </Stack>
              <Stack sx={pRStyles.column}>
                <Text style={pRStyles.secondaryText}>
                  {projectDetails.captioning_languages &&
                    projectDetails.captioning_languages.map((item) => item.language).join(', ')}
                </Text>
              </Stack>
            </Stack>

            <Stack sx={pRStyles.rowStyle}>
              <Stack sx={pRStyles.column}>
                <Text sx={pRStyles.tertiaryTitle} variant="bold">
                  Translation required
                </Text>
              </Stack>
              <Stack sx={pRStyles.column}>
                <Text style={pRStyles.secondaryText}>
                  {projectDetails.translation_required && projectDetails.translation_required === 1
                    ? 'Yes'
                    : projectDetails.translation_required &&
                        projectDetails.translation_required === 2
                      ? 'No'
                      : 'Not applicable'}
                </Text>
              </Stack>
            </Stack>

            <Stack sx={pRStyles.rowStyle}>
              <Stack sx={pRStyles.column}>
                <Text sx={pRStyles.tertiaryTitle} variant="bold">
                  Event date and time
                </Text>
              </Stack>
              <Stack sx={pRStyles.editContainer}>
                <Text style={pRStyles.secondaryText}>
                  {projectDetails &&
                  projectDetails.event_time &&
                  userType === defaultValues.userRole.customer ? (
                    getFormatDate(projectDetails.event_time, 'DD-MM-YYYY hh:mm A')
                  ) : projectDetails &&
                    projectDetails.event_time &&
                    projectDetails.event_time &&
                    userType === defaultValues.userRole.supplier ? (
                    <ConvertedTime
                      time={projectDetails.event_time}
                      originalTimeZoneName={projectDetails.event_timezone}
                      targetTimeZoneName={userTimezone}
                    />
                  ) : (
                    <ConvertedTime
                      time={projectDetails.event_time}
                      originalTimeZoneName={projectDetails.event_timezone}
                      targetTimeZoneName={defaultValues.uKTimeZone}
                      sendFormattedTime={handleFormatedTime}
                    />
                  )}
                </Text>
                {projectDetails &&
                  projectDetails.project_status &&
                  projectDetails.project_status.id < 7 &&
                  (userType === defaultValues.userRole.admin ||
                    userType === defaultValues.userRole.superAdmin) && (
                    <ModeEditIcon
                      sx={pRStyles.edit}
                      onClick={() =>
                        handleEditTime(
                          projectDetails.id,
                          formatedEventTime,
                          projectDetails.event_timezone
                        )
                      }
                    />
                  )}
              </Stack>
            </Stack>
            <Stack sx={pRStyles.rowStyle}>
              <Stack sx={pRStyles.column}>
                <Text sx={pRStyles.tertiaryTitle} variant="bold">
                  Event description
                </Text>
              </Stack>
              <Stack sx={pRStyles.column}>
                <Text style={pRStyles.secondaryText}>{projectDetails.event_description}</Text>
              </Stack>
            </Stack>
            <Stack sx={pRStyles.rowStyle}>
              <Stack sx={pRStyles.column}>
                <Text sx={pRStyles.tertiaryTitle} variant="bold">
                  Event duration
                </Text>
              </Stack>
              <Stack sx={pRStyles.column}>
                <Text style={pRStyles.secondaryText}>{`${projectDetails.event_duration} ${
                  projectDetails.event_duration === 1 ? 'hour' : 'hours'
                }`}</Text>
              </Stack>
            </Stack>

            {projectDetails.schedule_details && projectDetails.schedule_details.length > 0 && (
              <Stack sx={pRStyles.rowStyle}>
                <Stack sx={pRStyles.column}>
                  <Text sx={pRStyles.tertiaryTitle} variant="bold">
                    Schedule breakup
                  </Text>
                </Stack>
                <Stack sx={pRStyles.column}>
                  <Text style={pRStyles.secondaryText}>
                    {projectDetails.schedule_details && projectDetails.schedule_details.join(', ')}
                  </Text>
                </Stack>
              </Stack>
            )}
          </Box>

          {projectDetails.questions && projectDetails.questions.length > 0 && (
            <Box sx={{ p: 2 }}>
              <Text variant="menuTextBold" style={pRStyles.title}>
                Other additional information
              </Text>
              {questions &&
                questions.length > 0 &&
                questions.map((item, index) => (
                  <Stack sx={pRStyles.questionContainer(item.parent_question_id)} key={index}>
                    <Box sx={pRStyles.py} key={index + 'a'}>
                      <Text sx={pRStyles.tertiaryTitle} variant="bold">
                        • {item.question}
                      </Text>
                      <Text variant="answer" style={{ ...pRStyles.ml, ...pRStyles.secondaryText }}>
                        {item.answer}
                      </Text>
                    </Box>
                  </Stack>
                ))}
            </Box>
          )}
        </Box>
      </Box>

      <CustomModal
        open={modelData}
        onClose={handleRemoveReplaceClose}
        title={modelData && defaultValues.proRequirementConfirmationModal(modelData).title}
        message={modelData && defaultValues.proRequirementConfirmationModal(modelData).message}
        btnType={modelData && defaultValues.proRequirementConfirmationModal(modelData).btn}
        onCancel={handleRemoveReplaceClose}
        onConfirm={
          modelData && modelData.title === defaultValues.listConstants.Replace
            ? handleReplacePM
            : modelData && modelData.title === defaultValues.listConstants.Remove
              ? handleRemovePM
              : (modelData && modelData.title === defaultValues.listConstants.supplier) ||
                  (modelData && modelData.title === defaultValues.listConstants.rate_supplier)
                ? handleRemoveSupplier
                : updateProjectStatus
        }
        confirmStyle={modelData && pRStyles.confirmStyle(modelData.title)}
        modalType={modelData && defaultValues.proRequirementConfirmationModal(modelData).iconType}
        isLoading={isLoading}
        showRating={modelData && modelData.title === defaultValues.listConstants.rate_supplier}
        value={rating}
        handleRatingChange={(val) => handleRatingChange(val)}
        rightBtnDisable={
          modelData && modelData.title === defaultValues.listConstants.rate_supplier && !rating
        }
      />
      <ManageProjectsModal
        open={quotModel}
        close={() => {
          setQuotModel(null);
          setErrMsg('');
        }}
        quotation={fileUrl}
        submitTitle={
          quotModel === defaultValues.quotModelType.send
            ? 'Send'
            : quotModel === defaultValues.quotModelType.view &&
                userType === defaultValues.userRole.customer
              ? 'Accept'
              : quotModel === defaultValues.quotModelType.edit && 'Re-send'
        }
        cancelTitle={
          quotModel === defaultValues.quotModelType.view &&
          userType === defaultValues.userRole.customer
            ? 'Reject'
            : 'Cancel'
        }
        heading={
          quotModel === defaultValues.quotModelType.view
            ? 'View quote'
            : quotModel === defaultValues.quotModelType.edit
              ? 'Edit quote'
              : 'Send quote'
        }
        onSubmit={handleQuotationSubmit}
        type={quotModel}
        isLoading={isLoading}
        successMessage={successMessage}
        errorMessage={errMsg}
        handleModal={(val) => setQuotModel(val)}
        status={projectDetails && projectDetails.project_status && projectDetails.project_status.id}
      />

      <PopupModal
        headingStyle={pRStyles.assignHeading}
        showModel={open}
        onClose={handleClose}
        heading={'Assign project manager'}>
        <LoadingScreen isLoading={isLoading} />
        {defaultValues.isResponsive && <Divider />}
        <UIAutocompleted
          title={'Project manager'}
          value={projectManger}
          placeholder={'select project manager'}
          addShowButton={false}
          options={projectManagersList && projectManagersList.map((item) => item)}
          optionLabel={(item) => (item.first_name ? item.first_name + ' ' + item.last_name : '')}
          variant={defaultValues.isResponsive ? 'medium' : 'body'}
          disabled={checked}
          style={pRStyles.selectTitle}
          size={defaultValues.isResponsive ? 'small' : 'medium'}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2 || val.length === 0) {
                searchList(val);
              }
            }, 500);
          }}
          onScrollEnd={() => handleScrollEnd()}
          selectedValue={(val) => setProjectManager(val)}
          preSelectedValue={projectManger}
        />
        <FormGroup>
          <FormControlLabel
            sx={pRStyles.formControl}
            control={
              <Checkbox
                checked={checked}
                sx={pRStyles.checkBox}
                onChange={handleCheck}
                icon={<CheckBoxOutlineBlankIcon style={pRStyles.square} />}
                checkedIcon={<CheckBox style={pRStyles.checkedIcon} />}
              />
            }
            label={<Text sx={pRStyles.checkText}>assign self</Text>}
          />
        </FormGroup>
        <Box sx={pRStyles.btsContainer}>
          <UIButton
            disabled={
              buttonDisabled && Object.keys(projectManger ? projectManger : {}).length === 0
            }
            title="Assign manager"
            style={pRStyles.assignBtn}
            onClick={handleAssignPM}
          />
        </Box>
      </PopupModal>

      <PopupModal
        headingStyle={pRStyles.assignHeading}
        showModel={openRating}
        showDivider={true}
        headingContainerStyle={pRStyles.ratingHCont}
        dividerStyle={pRStyles.ratingDivider}
        containerStyles={pRStyles.ratingContBox}
        contentContainerStyles={pRStyles.ratingContentBox}
        childBoxStyle={pRStyles.ratingChildBox}
        onClose={handleCloseRating}
        heading={ratingHeading()}>
        <LoadingScreen isLoading={isLoading} />
        <Rating
          sx={pRStyles.ratingStar}
          value={rating}
          onChange={(e) => handleRatingChange(e.target.value)}
        />
        <Box sx={pRStyles.ratingBtnCont}>
          <UIButton
            style={pRStyles.ratingBtn}
            disabled={ratingBtnDisable}
            title="Submit"
            onClick={
              ratingModelType === defaultValues.listConstants.company_name
                ? handleCompanyRatingSubmit
                : handleSupplierRatingSubmit
            }
          />
        </Box>
      </PopupModal>
    </Screen>
  );
};
