import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Text } from '../text';
import { styles } from './style';
import { NoData, StatusIndicator } from '../../components';
import { ActionButton } from '../action-buttons';
import { customStyles } from '../../theme';

export const MasterTable = ({
  data,
  title,
  columnName,
  notFoundMessage,
  onEdit,
  onChangeState,
  OnViewDetails
}) => {
  return (
    <Box>
      {data.length > 0 ? (
        <Table sx={styles.tableContainer} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.headingCell} align="left">
                {title}
              </TableCell>
              <TableCell sx={styles.headingCell} align="left">
                Status
              </TableCell>
              <TableCell sx={styles.headingCell} align="left">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={index} sx={styles.tableRow}>
                  <TableCell component="th" scope="row">
                    <Text
                      variant="small"
                      style={OnViewDetails && customStyles.link}
                      onClick={() => OnViewDetails && OnViewDetails(row)}>
                      {row[columnName]}
                    </Text>
                  </TableCell>
                  <TableCell align="left">
                    <StatusIndicator status={row.active} />
                  </TableCell>
                  <TableCell align="center" sx={customStyles.actionCont}>
                    <ActionButton
                      item={row}
                      isEdit={onEdit ? true : false}
                      onEditClick={() => onEdit && onEdit(row)}
                      isButton={onChangeState ? true : false}
                      onButtonClick={() => onChangeState && onChangeState(row)}
                      contStyle={customStyles.actionSub}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <NoData message={notFoundMessage} />
      )}
    </Box>
  );
};
