import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';
const res = defaultValues.isResponsive;
const win = window.innerHeight < 1025;
export const vJobStyles = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    padding: '2%',
    boxSizing: 'border-box',
    marginBottom: res ? '20px' : '70px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground,
    marginBottom: '20px'
  },
  backContainer: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  backBtn: {
    fontSize: res ? '24px' : '45px'
  },
  backRoot: {
    width: 'fit-content',
    height: 'fit-content',
    padding: 0,
    alignItems: 'center'
  },
  toolsContainer: {
    width: res ? '100%' : 'fit-content',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  icon: {
    fontSize: res ? '20px' : '46px',
    color: 'black'
  },
  heading: {
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 600,
    fontSize: res ? '16px' : '24px'
  },
  titleContainer: {
    flexDirection: 'column',
    alignItems: 'left',
    mb: 2
  },
  cardStyle: {
    height: 'auto',
    minWidth: res ? '100%' : 'calc(50% - 20px)',
    ml: 0,
    mt: 0,
    mb: 0
  },
  titleStyle: {
    fontWeight: 600,
    fontSize: res ? '16px' : win ? '20px' : '24px',
    ml: 0,
    p: 0,
    textDecoration: 'underline',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  leftBtnStyle: {
    fontSize: res ? '10px' : '18px',
    height: res ? '30px' : '50px',
    width: res ? 'calc(50% - 5px)' : 'calc(40% - 5px)'
  },
  rightBtnStyle: {
    fontSize: res ? '10px' : '18px',
    height: res ? '30px' : '50px',
    width: res ? 'calc(50% - 5px)' : 'calc(40% - 5px)'
  },
  modalCancel: {
    color: color.palette.primaryRed
  },
  modalAccept: {
    backgroundColor: color.palette.tertiaryLightBlue
  },
  modalReject: {
    backgroundColor: color.palette.primaryRed
  },
  applicantsContainer: (val) => ({
    width: '100%',
    display: 'flex',
    flexWrap: val > 2 ? 'wrap' : 'no-wrap',
    flexDirection: res ? 'column' : 'row',
    gap: '20px',
    marginTop: '30px'
  }),
  notFoundBox: {
    margin: '10px auto',
    width: '100%',
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    background: color.palette.disableColor
  },
  notFoundText: { width: '100%', textAlign: 'center', color: color.palette.primaryRed }
};
