import { Box } from '@mui/material';
import { Styles } from './style';
import edit from '../../assets/icons/edit.png';
import { Text } from '../text';
import { defaultValues } from '../../constants';
import { UIButton } from '../button';
import { useSelector } from 'react-redux';
import { getUserType } from '../../services/redux/selectors';
import { CustomModal } from '../../components';
import { useState } from 'react';
export const ActionButton = ({
  item,
  onEditClick,
  onViewDetailsClick,
  onButtonClick,
  projectButtonClick,
  isViewDetails,
  isEdit = true,
  isButton = true,
  isLoading,
  loaderSize,
  type,
  disabled,
  name,
  viewTitle = 'view details',
  contStyle
}) => {
  const userType = useSelector(getUserType);
  const [showModel, setShowModel] = useState(false);
  return (
    <>
      <Box sx={Styles.actionContainer(type, contStyle)}>
        {isButton && (
          <UIButton
            disabled={disabled}
            title={item.active || item.isActive ? 'Deactivate' : 'Reactivate'}
            style={Styles.actionButton(item.active || item.isActive)}
            onClick={() => setShowModel(true)}
            isLoading={isLoading}
            loaderSize={loaderSize}
          />
        )}
        {isEdit && <img style={Styles.editIcon} onClick={onEditClick} src={edit} />}
        {isViewDetails && (
          <Text style={Styles.link} onClick={onViewDetailsClick}>
            <u>{viewTitle}</u>
          </Text>
        )}

        {type === defaultValues.listConstants.projects && (
          <UIButton
            style={Styles.projectButton(item.status, userType)}
            title={
              item.status.key === defaultValues.projectsStatus.initialContact
                ? 'Send quote'
                : 'View quote'
            }
            onClick={projectButtonClick}
          />
        )}
      </Box>
      <CustomModal
        open={showModel}
        onClose={() => setShowModel(false)}
        title={defaultValues.changeStatusModal.title}
        message={
          name
            ? `${name} will be ${item.active || item.isActive ? 'deactivated' : 'activated'}`
            : `Do you want to ${item.active || item.isActive ? 'deactivated' : 'activated'}`
        }
        btnType={defaultValues.changeStatusModal.btn}
        onCancel={() => setShowModel(false)}
        onConfirm={() => {
          setShowModel(false);
          onButtonClick();
        }}
      />
    </>
  );
};
