import { put, takeLatest } from 'redux-saga/effects';

import { SUCCESS_KEY } from '../../../constants';
import {
  conversationFailure,
  conversationRequest,
  conversationSuccess,
  getMessagesFailure,
  getMessagesRequest,
  getMessagesSuccess,
  getProjectMessagesFailure,
  getProjectMessagesRequest,
  getProjectMessagesSuccess,
  getProjectThreadsFailure,
  getProjectThreadsRequest,
  getProjectThreadsSuccess,
  getThreadsFailure,
  getThreadsRequest,
  getThreadsSuccess,
  initiateChatFailure,
  initiateChatRequest,
  initiateChatSuccess,
  initiateProjectChatFailure,
  initiateProjectChatRequest,
  initiateProjectChatSuccess,
  projectConversationRequest,
  projectConversationSuccess,
  unreadStatusFailure,
  unreadStatusRequest,
  unreadStatusSuccess
} from '../slices';
import {
  conversationApi,
  getMessagesApi,
  getProjectMessagesApi,
  getProjectThreadsApi,
  getThreadsApi,
  initiateChatApi,
  initiateProjectChatApi,
  projectConversationApi,
  unreadStatusApi
} from '../../api';

function* initiateChat(action) {
  try {
    const response = yield initiateChatApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(initiateChatSuccess(response.data));
    } else {
      yield put(initiateChatFailure(response.data));
    }
  } catch (error) {
    yield put(initiateChatFailure(error.response));
  }
}

function* getThreads(action) {
  try {
    const response = yield getThreadsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getThreadsSuccess(response.data));
    } else {
      yield put(getThreadsFailure(response.data));
    }
  } catch (error) {
    yield put(getThreadsFailure(error.response));
  }
}

function* getMessages(action) {
  try {
    const response = yield getMessagesApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getMessagesSuccess(response.data));
    } else {
      yield put(getMessagesFailure(response.data));
    }
  } catch (error) {
    yield put(getMessagesFailure(error.response));
  }
}

function* conversations(action) {
  try {
    const response = yield conversationApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(conversationSuccess(response.data));
    } else {
      yield put(conversationFailure(response.data));
    }
  } catch (error) {
    yield put(conversationFailure(error.response));
  }
}

function* initiateProjectChat(action) {
  try {
    const response = yield initiateProjectChatApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(initiateProjectChatSuccess(response.data));
    } else {
      yield put(initiateProjectChatFailure(response.data));
    }
  } catch (error) {
    yield put(initiateProjectChatFailure(error.response));
  }
}

function* getProjectThreads(action) {
  try {
    const response = yield getProjectThreadsApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getProjectThreadsSuccess(response.data));
    } else {
      yield put(getProjectThreadsFailure(response.data));
    }
  } catch (error) {
    yield put(getProjectThreadsFailure(error.response));
  }
}

function* getProjectMessages(action) {
  try {
    const response = yield getProjectMessagesApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getProjectMessagesSuccess(response.data));
    } else {
      yield put(getProjectMessagesFailure(response.data));
    }
  } catch (error) {
    yield put(getProjectMessagesFailure(error.response));
  }
}

function* projectConversations(action) {
  try {
    const response = yield projectConversationApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(projectConversationSuccess(response.data));
    } else {
      yield put(conversationFailure(response.data));
    }
  } catch (error) {
    yield put(conversationFailure(error.response));
  }
}

function* unreadStatus(action) {
  try {
    const response = yield unreadStatusApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(unreadStatusSuccess(response.data));
    } else {
      yield put(unreadStatusFailure(response.data));
    }
  } catch (error) {
    yield put(unreadStatusFailure(error.response));
  }
}

export function* chatSaga() {
  yield takeLatest(initiateChatRequest.type, initiateChat);
  yield takeLatest(getThreadsRequest.type, getThreads);
  yield takeLatest(getMessagesRequest.type, getMessages);
  yield takeLatest(conversationRequest.type, conversations);
  yield takeLatest(unreadStatusRequest.type, unreadStatus);
  yield takeLatest(initiateProjectChatRequest.type, initiateProjectChat);
  yield takeLatest(projectConversationRequest.type, projectConversations);
  yield takeLatest(getProjectThreadsRequest.type, getProjectThreads);
  yield takeLatest(getProjectMessagesRequest.type, getProjectMessages);
}
