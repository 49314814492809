import { defaultValues } from '../../../constants';
import { palette } from '../../../theme/palette';
const isRes = defaultValues.isResponsive;

export const EnquireStyles = {
  container: (responsive) => ({
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    justifyContent: 'space-between',
    padding: '5%',
    boxSizing: 'border-box',
    position: 'relative'
  }),
  eventMessageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  eventMessageText: {
    width: '70%'
  },
  leftArrowContainer: {
    width: '10%',
    height: '10%'
  },
  leftArrow: {
    maxWidth: '4%',
    maxHeight: '40px',
    position: 'fixed',
    top: 60,
    left: '1.5%'
  },
  leftSide: (isRes) => ({
    width: isRes ? '100%' : '40%',
    display: 'flex',
    flexDirection: isRes ? 'column' : 'row',
    justifyContent: 'space-between'
  }),
  rightSide: (isRes) => ({
    width: isRes ? '100%' : '55%'
  }),

  formContainer: {
    minWidth: 120,
    width: '80%',
    color: 'black'
  },
  undrawImage: {
    maxWidth: '100%',
    maxHeight: '350px',
    marginTop: 50
  },
  description: (isRes) => ({
    fontSize: isRes ? '12px' : '22px',
    marginTop: 4
  }),
  heading: (res) => ({
    fontSize: res ? '24' : '48px',
    fontWeight: 600,
    color: palette.primaryRed
  }),
  leftSubContainer: {
    paddingLeft: !isRes && '10%',
    boxSizing: 'border-box'
  },

  serviceContainer: {
    width: '100%',
    paddingLeft: '20px',
    boxSizing: 'border-box',
    marginTop: '20px'
  },
  backBtnContainer: { ml: defaultValues.isResponsive && -2 }
};
