import { put, takeLatest } from 'redux-saga/effects';
import { changeProPicApi, deleteProPicApi, editProfileApi, getProfileApi } from '../../api';
import { SUCCESS_KEY } from '../../../constants';
import {
  editProfileFailure,
  editProfileRequest,
  editProfileSuccess,
  profilePicChangeFailure,
  profilePicChangeRequest,
  profilePicChangeSuccess,
  profilePicDeleteFailure,
  profilePicDeleteRequest,
  profilePicDeleteSuccess,
  profileRequest,
  profileRequestFailure,
  profileRequestSuccess,
  updateTimezoneOnProfileUpdate,
  updateUserData,
  updateUserProfilePicture
} from '../slices';

function* getProfile(action) {
  try {
    const response = yield getProfileApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(profileRequestSuccess(response.data));
    } else {
      yield put(profileRequestFailure(response.data));
    }
  } catch (error) {
    yield put(profileRequestFailure(error.response.data));
  }
}

function* editProfile(action) {
  try {
    const response = yield editProfileApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(editProfileSuccess(response.data));
      yield put(updateUserData(response.data));
      yield put(updateTimezoneOnProfileUpdate(response.data));
    } else {
      yield put(editProfileFailure(response.data));
    }
  } catch (error) {
    yield put(editProfileFailure(error.response.data));
  }
}

function* changeProPic(action) {
  try {
    const response = yield changeProPicApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(profilePicChangeSuccess(response.data));
      yield put(updateUserProfilePicture(response.data));
    } else {
      yield put(profilePicChangeFailure(response.data));
    }
  } catch (error) {
    yield put(profilePicChangeFailure(error.response.data));
  }
}

function* deleteProPic(action) {
  try {
    const response = yield deleteProPicApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(profilePicDeleteSuccess(response.data));
      yield put(updateUserProfilePicture(response.data));
    } else {
      yield put(profilePicDeleteFailure(response.data));
    }
  } catch (error) {
    yield put(profilePicChangeFailure(error.response.data));
  }
}

export function* profileSaga() {
  yield takeLatest(profileRequest.type, getProfile);
  yield takeLatest(editProfileRequest.type, editProfile);
  yield takeLatest(profilePicChangeRequest.type, changeProPic);
  yield takeLatest(profilePicDeleteRequest.type, deleteProPic);
}
