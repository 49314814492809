import React, { useEffect, useRef } from 'react';
import { Autocomplete, Box, List, Paper } from '@mui/material';
import { color } from '../../theme/color';
import { Text } from '../text';
import { fonts } from '../../theme/fonts';
import { UIButton } from '../button';
import { TextInput } from '../text-field';
import { defaultValues } from '../../constants';
import { ListItemContent } from '@mui/joy';

export const UIAutocompleted = ({
  name,
  id,
  title,
  placeholder,
  options,
  onChange,
  onAdd,
  touched,
  onBlur,
  error,
  errorStyle,
  required,
  onTyping,
  style,
  addShowButton = true,
  selectedValue,
  optionLabel,
  isLoading = false,
  variant = 'semiBold',
  onScrollEnd,
  preSelectedValue,
  titleStyle,
  disabled = false,
  size = defaultValues.isResponsive ? 'small' : 'medium',
  mainBoxStyle
}) => {
  const res = defaultValues.isResponsive;
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    if (preSelectedValue) {
      setValue(preSelectedValue);
    } else {
      setValue('');
    }
  }, [preSelectedValue]);

  const defaultStyles = {
    mainBox: { width: res ? '100%' : '80%', margin: '25px 0', ...mainBoxStyle },
    boxStyle: {
      boxShadow: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      paddingRight: '15px',
      backgroundColor: color.palette.textFieldRed,
      border: `1px solid ${color.palette.borderColor}`
    },
    autoStyle: {
      width: '100%',
      border: 'none'
    },
    textInput: (disabled) => ({
      '& fieldset': { border: 'none', cursor: disabled ? 'no-drop' : 'auto' },
      '& .MuiInputBase-input': {
        cursor: disabled ? 'no-drop' : 'auto',
        fontSize: res ? '14px' : '20px'
      },
      '& .MuiPaper-root': {
        paddingRight: res ? 0 : '15px'
      },
      fontFamily: fonts.primary,
      width: '100%'
    }),
    btnStyle: { padding: '5px 20px', fontSize: res ? '12px' : '20px' },
    mB: { marginBottom: '10px', ...titleStyle },
    errorStyles: {
      marginTop: '8px',
      ...errorStyle
    },
    asterisk: {
      color: color.palette.red
    }
  };

  const styles = {
    ...defaultStyles,
    ...style
  };

  const listRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      onScrollEnd && onScrollEnd();
    }
  };
  return (
    <Box sx={styles.mainBox}>
      {title && (
        <Text variant={variant} style={defaultStyles.mB}>
          {title}
          {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}
      <Paper
        component="form"
        sx={{
          ...styles.boxStyle,
          border: error && touched && `1px solid ${color.errorText}`
        }}>
        <Autocomplete
          clearOnEscape={false}
          disabled={disabled}
          value={value}
          sx={styles.autoStyle}
          size={size}
          options={options}
          getOptionLabel={optionLabel && optionLabel}
          noOptionsText={isLoading ? 'Loading...' : 'No options'}
          ListboxComponent={(props) => {
            const { children, ...rest } = props;
            return (
              <List ref={listRef} onScroll={handleScroll} {...rest}>
                {React.Children.map(children, (child) => (
                  <ListItemContent sx={{ '&:hover': { backgroundColor: color.palette.lightGray } }}>
                    {child}
                  </ListItemContent>
                ))}
              </List>
            );
          }}
          renderInput={(params) => (
            <TextInput
              id={id}
              name={name}
              onBlur={onBlur}
              required={required}
              onChange={(e) => onTyping && onTyping(e.target.value)}
              placeholder={placeholder}
              style={styles.textInput(disabled)}
              {...params}
            />
          )}
          onChange={(event, newValue) => {
            onChange && onChange(newValue);
            setValue(newValue);
            selectedValue && selectedValue(newValue);
          }}
        />
        {addShowButton && (
          <UIButton
            title="Add"
            style={styles.btnStyle}
            onClick={() => {
              onAdd(value);
              setValue('');
            }}
          />
        )}
      </Paper>
      {error && touched && (
        <Text style={styles.errorStyles} variant="fieldError">
          {error}
        </Text>
      )}
    </Box>
  );
};
