import { Checkbox } from '@mui/material';
import React from 'react';
import { color } from '../../theme/color';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

export const UIRadioButton = ({ onChange, checked, sx, disabled, size = '35px', ...rest }) => {
  const styles = {
    icon: { color: color.palette.primaryBlack, fontSize: size },
    checkedIcon: { color: color.palette.primaryRed, fontSize: size }
  };

  return (
    <Checkbox
      checked={checked}
      disabled={disabled}
      sx={{ marginRight: '8px', ...sx }}
      onChange={onChange}
      icon={<RadioButtonUnchecked style={styles.icon} />}
      checkedIcon={<RadioButtonChecked style={styles.checkedIcon} />}
      {...rest}
    />
  );
};
