import { Box } from '@mui/material';
import { Text } from '../../../ui-kit';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { style } from './style';
import { useEffect, useState } from 'react';
import { defaultValues } from '../../../constants';
import { calculatePercentage } from '../../../utils';
const isRes = defaultValues.isResponsive;

export const CustomPieChart = ({ colors, data }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const temp = data.filter((item) => item.type !== 'total');
      setChartData(temp);
    }
  }, [data]);

  return (
    <Box sx={style.container}>
      <Box sx={style.leftSection}>
        <Text
          variant={isRes ? 'responsiveText' : defaultValues.isMedium ? 'medium' : 'BASE'}
          style={style.textStyle}>
          Supplier Overview
        </Text>
        <Box sx={style.chartContainer}>
          <ResponsiveContainer width={style.width} height={style.height}>
            <PieChart>
              <Pie
                data={chartData && chartData}
                innerRadius={25}
                outerRadius={
                  defaultValues.isMdSmall
                    ? 28
                    : defaultValues.isMobile
                      ? 30
                      : isRes
                        ? 30
                        : defaultValues.isMedium
                          ? 38
                          : 32
                }
                paddingAngle={5}
                dataKey="value">
                {chartData &&
                  chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                <Label
                  value={
                    chartData &&
                    chartData.length > 0 &&
                    data &&
                    data.length > 0 &&
                    calculatePercentage(chartData, data)
                  }
                  position="center"
                  fontSize={style.font.size}
                  fill={style.font.color}
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box>
      <Box sx={style.rightSection}>
        {chartData &&
          chartData.map((item, index) => (
            <Box key={index} sx={style.detailsContainer}>
              <Box sx={style.statusBar(colors, index)}></Box>
              <Text>{item.type}</Text>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
