import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
const responsive = defaultValues.isResponsive;
export const Styles = {
  searchBarContainer: {
    display: 'flex',
    height: '100%',
    border: `1px solid ${color.primaryBorder}`,
    width: responsive ? '100%' : '60%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    padding: '4px',
    boxSizing: 'border-box'
  },
  searchBar: {
    width: '100%',
    height: '100%',
    paddingLeft: '10px',
    fontSize: responsive ? '14px' : '18px'
  },
  searchIcon: {
    maxWidth: '100%',
    maxHeight: '28px'
  }
};
