import { Box } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Text } from '../../../ui-kit';
import { Styles } from './style';
import { defaultValues } from '../../../constants';
const isRes = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const PieChartWithDetails = ({ data, colors }) => {
  return (
    <Box sx={Styles.container}>
      <Box sx={Styles.chartContainer}>
        <Text variant={isRes && 'responsiveText'} style={Styles.textStyle}>
          Lead Overview
        </Text>
        <ResponsiveContainer width={Styles.size} height={Styles.size}>
          <PieChart>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={data}
              outerRadius={defaultValues.isMobile ? 36 : isRes ? 45 : md ? 60 : 50}>
              {data.length > 0 &&
                data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={Styles.rightSection}>
        {data.map((item, index) => (
          <Box key={index} sx={Styles.detailsContainer}>
            <Box sx={Styles.colorIndicator(colors, index)}></Box>
            <Text variant={isRes ? 'resSmallLight' : 'responsiveDesc'} style={Styles.underline}>
              {item.name} ({item.value})
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
