import { color } from '../../../theme/color';

export const CollapseStyles = {
  container: {
    marginBottom: '10px',
    border: `1px solid ${color.primaryBorder}`,
    borderBottom: 'none',
    borderRadius: '5px'
  },
  listContainer: {
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    marginTop: '10px',
    width: '100%'
  },
  expandIcon: {
    height: '24px',
    width: '24px',
    backgroundColor: color.palette.primaryRed,
    color: color.palette.primaryWhite,
    borderRadius: '50%'
  },
  symbol: {
    marginLeft: '25px',
    width: '50%'
  },
  projects: {
    width: '50%',
    marginLeft: '50px'
  },
  admin1: {
    marginLeft: '20%'
  },
  admin2: {
    marginLeft: '27.5%'
  },
  statusIndicator: () => ({
    marginLeft: '0%'
  }),
  actionButtonContainer: {
    width: '50%'
  },
  newStateButton: {
    color: color.palette.primaryWhite,
    backgroundColor: color.palette.primaryRed,
    padding: 0,
    margin: 0,
    fontSize: '10px'
  },
  maxWidth: { maxWidth: '60vw' },
  takeOwnership: {
    backgroundColor: color.palette.lightBlue,
    padding: '4px',
    fontSize: '10px',
    marginLeft: '42%'
  },
  summeryBox: {
    background: color.palette.secondaryWhite
  },
  iconStyle: { height: '30px', width: '30px', marginRight: '15px' },
  textStyle: { width: '37%' },
  supEmail: { color: color.palette.emailGray },
  edit: { width: '18px', height: '18px', cursor: 'pointer' }
};
