import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { Step, StepLabel, Stepper } from '@mui/material';
import { color } from '../../theme';
import { Text } from '../text';
import { useEffect, useState } from 'react';
import { defaultValues } from '../../constants';

export const StepperElem = ({ status, title, options, userRole }) => {
  const [renderOptions, setRenderOptions] = useState([]);
  const [stepperStatus, setStepperStatus] = useState('');
  const isMobile = defaultValues.isResponsive;
  const screenW = window.innerWidth;

  useEffect(() => {
    let newArr = [];
    if (status === 4) {
      newArr = options.filter((item) => {
        return item.id <= 4 && item.id !== 3;
      });
      newArr = [...newArr, ...options.filter((item) => item.id > 3 && item.id !== 4)];
      setRenderOptions(newArr);
    } else {
      newArr = options.filter((item) => {
        return item.id !== 4;
      });
      setRenderOptions(newArr);
    }

    if (status) {
      if (userRole === defaultValues.userRole.supplier) {
        if (status === 6 || status === 3) {
          setStepperStatus(6);
        } else if (status === 7 || status === 8 || status == 9 || status === 11) {
          setStepperStatus(7);
        } else if (status === 10) {
          setStepperStatus(10);
        } else {
          setStepperStatus(1);
        }
      } else {
        setStepperStatus(status);
      }
    }
  }, [status]);

  const styles = {
    completedContainer: {
      height: isMobile ? 8 : 12,
      width: isMobile ? 8 : 12,
      borderRadius: '50%',
      border: `${isMobile ? '3px' : '7px'} solid ${color.palette.primaryRed}`,
      margin: 0,
      padding: 0,
      background: color.palette.primaryWhite
    },
    notCompleted: {
      height: isMobile ? 8 : 12,
      width: isMobile ? 8 : 12,
      borderRadius: '50%',
      border: `${isMobile ? '3px' : '7px'} solid ${color.palette.lighterGray}`
    },
    stepper: { height: isMobile ? '10%' : '20%', width: '100%' },
    title: (index, length) => ({
      fontSize: screenW < 350 ? '7px' : isMobile ? '9px' : '14px',
      marginTop: index % 2 === 0 ? (length > 20 ? '-90px' : '-70px') : '10px'
    })
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: isMobile ? 8 : 7,
      left: `calc(-50% + ${isMobile ? '6px' : '10px'})`,
      right: `calc(50% + ${isMobile ? '5px' : '19px'})`
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[800] : color.palette.lighterGray
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        border: `${isMobile ? '1px' : '4px'} solid`,
        borderColor: color.palette.primaryRed,
        width: '100%'
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      marginTop: '1px',
      border: `${isMobile ? '1px' : '4px'} solid`,
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : color.palette.lighterGray,
      borderTopWidth: isMobile ? '1px' : 3,
      borderRadius: 1,
      width: '100%'
    }
  }));

  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : color.palette.lighterGray,
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4'
    })
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div style={styles.completedContainer}></div>
        ) : (
          <div style={styles.notCompleted} />
        )}
      </QontoStepIconRoot>
    );
  }

  return (
    status && (
      <Stepper
        sx={styles.stepper}
        activeStep={renderOptions.findIndex((item) => item.id === stepperStatus) + 1}
        alternativeLabel
        connector={<QontoConnector />}>
        {renderOptions &&
          renderOptions.map((label, index) => {
            return (
              <Step key={index}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <Text variant="extraSmall" style={styles.title(index, label[title].length)}>
                    {title && label[title]}
                  </Text>
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
    )
  );
};
