import { Box } from '@mui/material';
import { Text, UIButton } from '../../../ui-kit';
import { color } from '../../../theme';
import { defaultValues } from '../../../constants';
const isMobile = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const ProjectMangerCard = ({ id, name, email, onReplace, onRemove }) => {
  return (
    <Box sx={Styles.main}>
      <Box sx={Styles.sub}>
        <Text style={Styles.titleFont}>Project manager -</Text>
        <Box sx={Styles.detailsBox}>
          <Text style={Styles.titleFont}>{name}</Text>
          <Text variant="lightBody" style={Styles.emailFont}>
            {email}
          </Text>
        </Box>
      </Box>
      <Box sx={Styles.mobBtn}>
        <UIButton
          title="Replace"
          variant="secondary"
          onClick={() => onReplace && onReplace(id)}
          style={Styles.br}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <UIButton title="Remove" onClick={() => onRemove && onRemove(id)} style={Styles.br} />
      </Box>
    </Box>
  );
};

const Styles = {
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: isMobile ? 'left' : 'center',
    flexDirection: isMobile ? 'column' : 'row',
    border: `1px solid${color.primaryBorder}`,
    borderRadius: '5px',
    padding: isMobile ? '7px' : '10px',
    boxSizing: 'border-box',
    gap: '10px'
  },
  sub: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    maxWidth: isMobile ? '100%' : '60%'
  },
  detailsBox: { textWrap: 'wrap' },
  mobBtn: { display: 'flex', ml: isMobile && 'auto' },
  titleFont: { fontWeight: 600, fontSize: isMobile ? '12px' : '20px' },
  br: {
    borderRadius: '8px',
    width: isMobile ? '25px' : md ? '120px' : '150px',
    fontSize: isMobile ? '10px' : md ? '14px' : '16px',
    padding: isMobile ? 0 : 10,
    minWidth: isMobile && '64px'
  },
  emailFont: {
    fontSize: isMobile && '10px'
  }
};
