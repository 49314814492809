import { Box } from '@mui/material';
import { Filter, IconsButton, PaginationCustom, Screen, SearchBar, Text } from '../../../ui-kit';
import { SCREEN_PATH, defaultValues, getErrorMessage } from '../../../constants';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SupplierDetailsCard } from '../../../components';
import { useCallback, useEffect, useState } from 'react';
import {
  getLanguageRequest,
  getProjectSupplierRequest,
  getTimeZoneRequest,
  masterListRequest,
  resetSupplierMessages,
  serviceRequest
} from '../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSupplierCurrentPg,
  getSupplierErrorMessage,
  getSupplierTotalPg,
  getSuppliersList,
  supplierLoadingStatus
} from '../../../services/redux/selectors/supplierSelector';
import { filterFun, getOptionsFromList, resetMessge, truncateText } from '../../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { toast } from 'react-toastify';
import { styles } from './style';
import {
  getLanguageNextPage,
  getLanguages,
  getServices,
  getTimeZone,
  getTimezoneNextPage
} from '../../../services/redux/selectors';
import RefreshIcon from '@mui/icons-material/Refresh';

export const SupplierList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();

  const curPage = useSelector(getSupplierCurrentPg);
  const totalPage = useSelector(getSupplierTotalPg);
  const supplierList = useSelector(getSuppliersList);
  const isLoading = useSelector(supplierLoadingStatus);
  const errorMessage = useSelector(getSupplierErrorMessage);
  const allLanguages = useSelector(getLanguages);
  const allServices = useSelector(getServices);
  const allTimezone = useSelector(getTimeZone);
  const timeZoneNextPage = useSelector(getTimezoneNextPage);
  const languageNextPage = useSelector(getLanguageNextPage);

  const [list, setList] = useState('');
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [projectDetails, setProjectDetails] = useState('');
  const [serviceList, setServiceList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [sevicesNextPage, setServicesNextPage] = useState(null);

  useEffect(() => {
    dispatch(getLanguageRequest());
    dispatch(serviceRequest());
    dispatch(getTimeZoneRequest());
    dispatch(masterListRequest());
  }, []);

  useEffect(() => {
    if (supplierList) {
      setList(supplierList);
    }
    if (curPage) {
      setCurrentPage(curPage);
    } else {
      setCurrentPage(null);
    }
    if (totalPage) {
      setTotalPages(totalPage);
    } else {
      setTotalPages(null);
    }
  }, [supplierList]);

  useEffect(() => {
    if (allServices && allServices.next_page) {
      setServicesNextPage(allServices.next_page);
    }
    if (allServices.services) {
      let temp = [...serviceList, ...allServices.services];

      temp = filterFun(temp);

      setServiceList(temp);
    }

    if (allLanguages) {
      let temp = [...languageList, ...allLanguages];

      temp = filterFun(temp);
      setLanguageList(temp);
    }

    if (allTimezone) {
      let temp = [...timezoneList, ...allTimezone];
      temp = filterFun(temp);
      setTimezoneList(temp);
    }
  }, [allLanguages, allServices.services, allTimezone]);

  useEffect(() => {
    if (location && location.state.data) {
      setProjectDetails(location.state.data);
      getSupplerList(null, location.state.data.id);
    }
  }, [location]);

  const getSupplerList = useCallback((data, id) => {
    let payload = { page: curPage || 1, project_id: id || projectDetails.id };
    if (data !== undefined) {
      payload = {
        ...payload,
        ...data
      };
    }

    dispatch(getProjectSupplierRequest(payload));
  }, []);

  useEffect(() => {
    if (languageList.length > 0 && serviceList.length > 0 && timezoneList.length > 0) {
      const options = getOptionsFromList([languageList, serviceList, timezoneList]);
      if (options.length > 0) {
        setFilterOptions(options);
      }
    }
  }, [languageList, serviceList, timezoneList]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetSupplierMessages()));
  }, [errorMessage]);

  const handleSearch = (search_text) => {
    if (search_text.length === 0 || search_text.length >= 2) {
      setTimeout(() => {
        getSupplerList({ search_text, project_id: location.state.data.id });
      }, 1500);
    }
  };

  const onInviteClick = (val) => {
    navigation(SCREEN_PATH.INVITE_SUPPLIER, {
      state: { project_details: projectDetails, supplier_details: val }
    });
  };

  const handleFilter = (data) => {
    let params = { page: 1, project_id: projectDetails.id };

    for (const item of data) {
      const key = Object.keys(item)[0];
      const values = item[key];
      if (Array.isArray(values)) {
        if (values.length > 0) {
          params[key] = `[${values.join(',')}]`;
        }
      } else {
        params[key] = values;
      }
    }

    dispatch(getProjectSupplierRequest(params));
  };

  const handleScrolling = (data) => {
    let item = data.toString();
    if (item === 'languages') {
      if (languageNextPage !== -1) {
        dispatch(getLanguageRequest({ page: languageNextPage }));
      }
    }
    if (item === 'skills') {
      if (sevicesNextPage !== -1) {
        dispatch(serviceRequest({ page: sevicesNextPage }));
      }
    }
    if (item === 'timezones') {
      if (timeZoneNextPage !== -1) {
        dispatch(getTimeZoneRequest({ page: timeZoneNextPage }));
      }
    }
  };

  return (
    <Screen showSideBar={true} currentPage={'Project details'}>
      <Box sx={styles.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={styles.subContainer}>
          <Box sx={styles.titleContainer}>
            <Box sx={styles.heading}>
              <Text
                onClick={() => {
                  navigation(-2);
                }}
                variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
                style={styles.navStyle}>
                {projectDetails.project_name}
              </Text>

              <ArrowBackIosIcon sx={styles.iconStyle} />

              <Text
                variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
                style={styles.navStyle}
                onClick={() => {
                  navigation(-1);
                }}>
                Supplier hub
              </Text>
            </Box>
            {!defaultValues.isResponsive && <ArrowBackIosIcon sx={styles.iconStyle} />}
            <Text
              variant={defaultValues.isResponsive ? 'small' : 'titleBold'}
              style={styles.fontStyle}>
              Select from list
            </Text>
          </Box>
          <Box sx={styles.searchContainer}>
            <SearchBar label={'search suppliers'} searchedVlaue={handleSearch} />
            <Box sx={styles.actionContainer}>
              <IconsButton
                title={!defaultValues.isResponsive ? null : 'Refresh'}
                icon={<RefreshIcon sx={styles.icon} />}
                onClick={() => {
                  dispatch(
                    getProjectSupplierRequest({
                      search_text: null,
                      page: null,
                      project_id: projectDetails.id
                    })
                  );
                }}
              />
              {filterOptions.length > 0 && (
                <Filter
                  label={'Filter'}
                  options={filterOptions.length > 0 && filterOptions}
                  isMultiSelect={true}
                  applyFilter={handleFilter}
                  scrollingOption={handleScrolling}
                />
              )}
            </Box>
          </Box>
          <Box sx={styles.cardContainer}>
            {list.length > 0 && (
              <Text
                variant={defaultValues.isResponsive ? 'cardTitle' : 'menuTextBold'}
                style={styles.titleM}>
                Available suppliers
              </Text>
            )}
            <Box sx={styles.cardContent}>
              {list.length ? (
                list.map((card, index) => {
                  return (
                    <SupplierDetailsCard
                      key={index}
                      title={` ${card.first_name}
                 ${card.last_name}`}
                      language={
                        card.supplier_languages &&
                        truncateText(
                          card.supplier_languages.map((item) => item.language).join(', '),
                          22
                        )
                      }
                      skill={
                        card.supplier_skills &&
                        truncateText(
                          card.supplier_skills.map((item) => item.skill_title).join(','),
                          22
                        )
                      }
                      timezone={card.timezone && card.timezone.timezone}
                      currency={card.currency && card.currency.currency}
                      rating={card.rating}
                      btnDisable={card.is_invited}
                      onRightBtnClick={() =>
                        navigation(`${SCREEN_PATH.VIEW_USER_PROFILE}?role=4&id=${card.id}`)
                      }
                      onClick={() => onInviteClick(card)}
                      leftBtnTitle={card.is_invited ? 'Invited' : 'Invite supplier'}
                    />
                  );
                })
              ) : (
                <Box sx={styles.noFoundBoxSuper}>
                  <Box sx={styles.noFoundBox}>
                    <Text style={styles.noFoundText}>{getErrorMessage('E-10091')}</Text>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {totalPages && currentPage && (
            <Box sx={styles.paginationContainer}>
              <PaginationCustom
                onChange={(e, page) => {
                  getSupplerList({ page, project_id: projectDetails.id });
                }}
                currentPage={curPage}
                count={totalPage}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Screen>
  );
};
