import { Box } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { otpStyles } from './styles';
import { Text } from '../../../ui-kit';
import { useEffect, useState } from 'react';

export const Otp = ({
  otp,
  onChange,
  onBlur,
  otpErrorMessage,
  onResendClick,
  otpSend,
  handleOtpSend,
  otpInputStyle,
  otpContainerStyles,
  otpSubContainerStyles,
  timerStyle,
  errorStyle
}) => {
  const [time, setTime] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }

      if (time === 1) {
        clearInterval(interval);
        handleOtpSend(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const handleOtpValidate = (val) => {
    return !isNaN(Number(val));
  };

  const inputStyles = {
    ...otpStyles.otpInput,
    ...otpInputStyle
  };

  const containerStyles = {
    ...otpStyles.otpContainer,
    ...otpContainerStyles
  };

  const subContainer = {
    ...otpStyles.otpSubContainer,
    ...otpSubContainerStyles
  };

  const otpTimer = {
    ...otpStyles.otpTimer(otpSend),
    ...timerStyle
  };

  const errorFieldStyle = {
    ...otpStyles.errorField,
    ...errorStyle
  };

  return (
    <Box sx={containerStyles}>
      <Box sx={subContainer}>
        <MuiOtpInput
          sx={inputStyles}
          length={6}
          value={otp}
          onBlur={onBlur}
          autoFocus
          onChange={onChange}
          validateChar={handleOtpValidate}
        />

        {otpErrorMessage && (
          <Box>
            <Text style={errorFieldStyle}>{otpErrorMessage}</Text>
          </Box>
        )}

        <Box sx={otpStyles.otpResendContainer}>
          <Text
            style={otpTimer}
            variant="fileName"
            onClick={() => {
              if (!otpSend) {
                setTime(30);
                handleOtpSend(true);
                onResendClick();
              }
            }}>
            {otpSend ? <u>Resend otp in {time} secs</u> : <u>Resend Otp</u>}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
