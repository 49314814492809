import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  ActionButton,
  Filter,
  IconsButton,
  PaginationCustom,
  SearchBar,
  Sort,
  Text,
  UIButton
} from '../../../ui-kit';
import { styles } from './style';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { StatusIndicator, Title } from '../table-title';
import { CollapsableList } from '../../common/collapsable-list';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSettingsErrorMessage,
  getSettingsIndicator,
  getSettingsSuccessMessage,
  getTimeZone,
  getTimezoneCurrentPage,
  getTimezoneTotalPage,
  settingLoadingStatus
} from '../../../services/redux/selectors';
import { ModalWithSelectOption } from '../../select-option-modal';
import {
  addTimeZoneRequest,
  editTimeZoneRequest,
  getTimeZoneRequest,
  resetSettingsMessages
} from '../../../services/redux/slices';
import { toast } from 'react-toastify';
import { otherSettingsTimezoneSchema, resetMessge } from '../../../utils';
import { useFormik } from 'formik';
import { NoData } from '../../common/no-data-message';
import { customStyles } from '../../../theme';

export const TimeZone = () => {
  const dispatch = useDispatch();
  const timeZonesList = useSelector(getTimeZone);
  const pageCount = useSelector(getTimezoneTotalPage);
  const currentRenderPage = useSelector(getTimezoneCurrentPage);
  const isLoading = useSelector(settingLoadingStatus);
  const successMessage = useSelector(getSettingsSuccessMessage);
  const errorMessage = useSelector(getSettingsErrorMessage);
  const indicator = useSelector(getSettingsIndicator);

  const [open, setOpen] = useState(false);
  const [timeZones, setTimeZones] = useState([]);
  const [sortValue, setSortValue] = useState({});
  const [filterValue, setFilterValuer] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);

  const { values, handleBlur, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      timezone: ''
    },
    validationSchema: otherSettingsTimezoneSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  useEffect(() => {
    handleTimeZone({ page });
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetSettingsMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (timeZonesList) {
      setTimeZones(timeZonesList);
    }
    if (pageCount) {
      setTotalPage(pageCount);
    }
    if (currentRenderPage) {
      setPage(currentRenderPage);
    }
  }, [timeZonesList, currentRenderPage, pageCount]);

  useEffect(() => {
    if (!isLoading && successMessage) {
      setCurrentIndex('');
      handleClose();
    }
  }, [isLoading]);

  useEffect(() => {
    if (indicator) {
      handleTimeZone();
    }
  }, [indicator]);

  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFieldValue('timezone', '');
  };

  const handleSearch = (search_text) => {
    if (search_text.length === 0 || search_text.length >= 2) {
      handleTimeZone({ search_text });
    }
  };

  const handleRefresh = () => {
    setSortValue({});
    setFilterValuer({});
    setPage(1);
    handleTimeZone({ sort: null, filter: null, page: 1 });
  };

  const handleFilter = (value) => {
    setFilterValuer({ filter: `[{ isActive: ${value} }]` });
    handleTimeZone({ filter: `[{ isActive: ${value} }]` });
  };

  const handleSort = (sort) => {
    setSortValue({ sort });
    handleTimeZone({ sort });
  };

  const handlePageChange = (e, value) => {
    const payload = {
      page: value,
      ...sortValue,
      ...filterValue
    };
    handleTimeZone(payload);
  };

  const handleTimeZone = (data) => {
    let payload = {
      page: page || 1,
      ...sortValue,
      ...filterValue,
      ...data
    };
    dispatch(getTimeZoneRequest(payload));
  };

  const handleStatus = (id, status, index) => {
    const payload = { id: id, active: !status };
    setCurrentIndex(index, payload);
    dispatch(editTimeZoneRequest(payload));
  };

  const handleAddTimeZone = () => {
    dispatch(addTimeZoneRequest({ timezone: values.timezone }));
  };

  const handleSelectedValue = useCallback((value) => {
    setFieldValue('timezone', value);
  }, []);

  return (
    <Box sx={styles.container}>
      <ModalWithSelectOption
        onButtonClick={handleAddTimeZone}
        open={open}
        name="timezone"
        close={handleClose}
        heading={defaultValues.timeZone.addHeading}
        title={defaultValues.timeZone.title}
        buttonTitle={defaultValues.timeZone.saveBtn}
        selectedValue={handleSelectedValue}
        isLoading={isLoading}
        disabled={!values.timezone.length}
        onBlur={handleBlur}
        touched={touched.timezone}
        error={errors.timezone}
      />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.searchTabContainer}>
          <Box sx={styles.searchToolsContainer}>
            {!defaultValues.isResponsive ? (
              <SearchBar label={defaultValues.timeZone.searchLabel} searchedVlaue={handleSearch} />
            ) : (
              <Text style={styles.headingText} variant="medium">
                Available Skills
              </Text>
            )}
            <UIButton
              style={styles.newButton}
              title={defaultValues.timeZone.addBtn}
              onClick={handleModal}
            />
          </Box>
          <Box sx={styles.toolsContainer}>
            <IconsButton
              title={!defaultValues.isResponsive ? null : 'Refresh'}
              icon={<RefreshIcon sx={styles.icon} />}
              onClick={handleRefresh}
            />
            <Sort selectedOption={handleSort} activeOption={sortValue.sort} />
            <Filter label={'Filter'} selectedOption={handleFilter} />
          </Box>
        </Box>

        {!defaultValues.isResponsive ? (
          <Text sx={styles.heading} variant="fileName">
            {defaultValues.timeZone.heading}
          </Text>
        ) : (
          <Box sx={styles.searchContainer}>
            <SearchBar label={defaultValues.timeZone.searchLabel} searchedVlaue={handleSearch} />
          </Box>
        )}

        {!defaultValues.isResponsive ? (
          timeZones.length > 0 ? (
            <Table sx={styles.tableContainer} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.headingCell} align="left">
                    Time-zone
                  </TableCell>
                  <TableCell sx={styles.headingCell} align="left">
                    Status
                  </TableCell>
                  <TableCell sx={styles.headingCell} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeZones &&
                  timeZones.map((row, index) => (
                    <TableRow key={row.id} sx={styles.tableRow}>
                      <TableCell component="th" scope="row">
                        <Title title={row.timezone} />
                      </TableCell>
                      <TableCell align="left">
                        <StatusIndicator status={row.active} />
                      </TableCell>
                      <TableCell align="left" sx={customStyles.actionCont}>
                        <ActionButton
                          isEdit={false}
                          item={row}
                          isLoading={index === currentIndex && !open && isLoading}
                          onButtonClick={() => handleStatus(row.id, row.active, index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <NoData message={getErrorMessage('E-10088')} />
          )
        ) : (
          <Box sx={styles.collapseContainer}>
            {timeZones.length > 0 ? (
              <CollapsableList
                contentArray={timeZones}
                type={defaultValues.timeZone.name}
                onButtonClick={handleStatus}
                loaderSize={defaultValues.isResponsive && 15}
              />
            ) : (
              <NoData message={getErrorMessage('E-10088')} />
            )}
          </Box>
        )}

        {page && totalPages && (
          <Box sx={styles.paginationContainer}>
            <PaginationCustom onChange={handlePageChange} currentPage={page} count={totalPages} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
