/*
 * Add all the default values here which will be used in code
 * This may include page headers, dropdown options,
 * menu Options, contact details etc.
 */
import ionBusiness from '../assets/icons/ion_business.png';
import education from '../assets/icons/education.png';
import deaf from '../assets/icons/deaf.png';
import hand from '../assets/icons/hand.png';
import bag from '../assets/icons/bag.png';
import book from '../assets/icons/book.png';
import bxsChat from '../assets/icons/bxs_chat.png';
import quillPaper from '../assets/icons/quill_paper.png';
import chat from '../assets/icons/chat.png';
import speed from '../assets/icons/speed.png';
import trophy from '../assets/icons/trophy.png';
import fontIcon from '../assets/icons/font_icon.png';
import network from '../assets/icons/network.png';
import doubleTick from '../assets/icons/doubleTick.png';
import dashbord from '../assets/icons/dashboard.png';
import projects from '../assets/icons/project.png';
import projectManagers from '../assets/icons/pm.png';
import customers from '../assets/images/customers.png';
import admins from '../assets/icons/admins.png';
import suppliers from '../assets/icons/manage.png';
import jobsIcon from '../assets/icons/jobs.png';
import profileSettings from '../assets/icons/profilesettings.png';
import otherSettings from '../assets/icons/settings.png';
import inbox from '../assets/images/chat-2-icon.png';

import { SCREEN_PATH } from '../navigation/routes';
import customerDashIcon from '../assets/images/dash.png';
import teamIcon from '../assets/icons/Team.png';
import solarDocument from '../assets/icons/solar_document.png';
import mdiPersonAdd from '../assets/icons/mdi_person.png';
import todoList from '../assets/icons/todo-list.png';
import clarityDocument from '../assets/icons/clarity.png';
import chatTwo from '../assets/icons/bxs_chat-2.png';
import viewJob from '../assets/icons/view_jobs.png';
import calenderDocument from '../assets/icons/calender.png';
import feedback from '../assets/icons/feedback.png';

import { color } from '../theme';

const email = 'bookings@121captions.com';

export const defaultValues = {
  isResponsive: window.innerWidth < 900,
  isMobile: window.innerWidth < 600,
  isMedium: window.innerWidth < 1025,
  isXSmall: window.innerWidth < 281,
  isMdSmall: window.innerWidth < 400,
  contact_phone: '+91-1234567890',
  contact_email: 'example@0101digitall.com',
  terms: [
    {
      title: 'Terms of service',
      link: 'https://www.121captions.com/about/privacy-policy/'
    },
    {
      title: '•',
      link: '/'
    },
    {
      title: 'Privacy policy',
      link: 'https://www.121captions.com/about/privacy-policy/'
    },
    {
      title: '•',
      link: '/'
    },
    {
      title: 'Cookies policy',
      link: 'https://www.121captions.com/about/privacy-policy/'
    }
  ],
  copyright: 'Copy right © 2024 121 captions, Inc. All rights reserved.',
  no_auth_header_options: [
    { title: 'Home', url: '/' },
    {
      title: 'Services',
      options: [
        {
          title: 'Captions for buissiness',
          url: 'https://www.121captions.com/captioning-services-what-we-do/captions-for-business/'
        },
        {
          title: 'Captions for Education',
          url: 'https://www.121captions.com/captioning-services-what-we-do/captions-for-education/'
        },
        {
          title: 'Deaf awareness Training Courses',
          url: 'https://www.121captions.com/captioning-services-what-we-do/training-courses/'
        },
        {
          title: 'British Sign Language Interpreting',
          url: 'https://www.121captions.com/captioning-services-what-we-do/british-sign-language-interpreting/'
        },
        {
          title: 'Access to Work Deaf Services',
          url: 'https://www.121captions.com/captioning-services-what-we-do/access-to-work-deaf/'
        },
        {
          title: 'Professional lip Reading Services',
          url: 'https://www.121captions.com/captioning-services-what-we-do/professional-lip-reading-services/'
        },
        {
          title: 'Offline Captioning',
          url: 'https://www.121captions.com/captioning-services-what-we-do/offline-captioning/'
        },
        {
          title: 'Transcription Services',
          url: 'https://www.121captions.com/captioning-services-what-we-do/transcription-services/'
        }
      ]
    },
    { title: 'About', url: 'https://www.121captions.com/about/' },
    { title: 'Contact', url: 'https://www.121captions.com/contact-us/' }
  ],
  landingPageUrls: {
    viewLiveDemo: 'https://www.121captions.com/cart-captioning-demo/',
    aboutUs: 'https://www.121captions.com/about/',
    contactUs: 'https://www.121captions.com/contact-us/',
    facebook: 'https://www.facebook.com/121Captions/',
    linkedIn: 'https://www.linkedin.com/company/121-captions-ltd/',
    twitter: 'https://twitter.com/121captions',
    instagram: 'https://www.instagram.com/121captions/',
    tiktok: 'https://www.tiktok.com/@121captions/'
  },
  quickLinks: [
    { title: 'About Us', url: 'https://www.121captions.com/about/' },
    { title: 'Contact Us', url: 'https://www.121captions.com/contact-us/' }
  ],
  ourServicesHeading1: 'What We Do - ',
  ourServicesHeading2: 'Our Services',
  ourServicesSection: [
    {
      heading: 'Captions for Business',
      description:
        'Live captioning services make your meetings, events, conferences and calls accessible not only to clients and colleagues who are deaf, but also those who are non-native English speakers. Captioning services make good business sense.',
      icon: ionBusiness,
      color: 'maroon',
      url: 'https://www.121captions.com/captioning-services-what-we-do/captions-for-business/'
    },
    {
      heading: 'Captions for Education',
      description:
        'We work in collaboration with DSA to open up education to some of the 45,000+ deaf young people in the UK. Live captioning services enable ‘deaf students’ to become simply ‘students’, and share the same learning opportunities as their peers.',
      icon: education,
      color: 'green',
      url: 'https://www.121captions.com/captioning-services-what-we-do/captions-for-education/'
    },
    {
      heading: 'Deaf awareness Training Courses',
      description:
        'Hearing loss affects more than 10 million people in the UK, and 600 million worldwide. The chances are you work with some of them. Many more could be your potential customers. Deaf awareness training courses make business sense.',
      icon: deaf,
      color: 'skyblue',
      url: 'https://www.121captions.com/captioning-services-what-we-do/training-courses/'
    },
    {
      heading: 'British Sign Language Interpreting',
      description:
        'Over 12 million in the UK grapple with hearing loss. At 121 Captions, our experienced experts provide BSL interpreting and Video Relay Service to meet growing demand.',
      icon: hand,
      color: 'orange',
      url: 'https://www.121captions.com/captioning-services-what-we-do/british-sign-language-interpreting/'
    },
    {
      heading: 'Access to Work Deaf Services',
      description:
        'Experience seamless communication with Access to Work Deaf Services. Government-funded support available for workplace communication needs. Benefit from remote captioning expertise tailored to deaf and hard of hearing individuals.',
      icon: bag,
      color: 'yellow',
      url: 'https://www.121captions.com/captioning-services-what-we-do/access-to-work-deaf/'
    },
    {
      heading: 'Professional lip Reading Services',
      description:
        'Real-time lip reading deciphers speech from facial expressions. The art of lip reading serves as an alternative form of speech-based communication. A valuable service for all individuals and organisations in need of audio clarification.',
      icon: book,
      color: 'violet',
      url: 'https://www.121captions.com/captioning-services-what-we-do/professional-lip-reading-services/'
    },
    {
      heading: 'Offline Captioning',
      description:
        'Used for pre-recorded programming or video, offline captioning is prepared in advance before the programme is broadcast or the video is uploaded online. From our UK recording studios we provide the best value offline captioning service you will find.',
      icon: bxsChat,
      color: 'red',
      url: 'https://www.121captions.com/captioning-services-what-we-do/offline-captioning/'
    },
    {
      heading: 'Transcription Services',
      description:
        'Our captioning services deliver free transcription direct to your email as soon as captioning finishes. Our skilled captioners provide a real-time transcript of the content of the meeting, teleconference, lecture, event etc for use afterwards.',
      icon: quillPaper,
      color: 'blue',
      url: 'https://www.121captions.com/captioning-services-what-we-do/transcription-services/'
    }
  ],

  footer: {
    desc: 'Empower your events with 121 Captions – the premier leaders in high quality real-time captioning.'
  },
  landingPage: {
    heading1: 'Live Captioning',
    heading2: 'For All Your Events',
    description:
      'Empower your events with 121 Captions – the premier leaders in high quality real-time captioning. Our expert stenographers deliver 99% accuracy, ensuring accessibility and inclusivity for all. Elevate your communication with our comprehensive services, making every word count."',
    signupHeading: 'Unlock Opportunities with 121 Captions',
    signupDescription: 'Sign Up today and join our network of Expert Captioning Suppliers.',
    enquireHeading: 'Your Words, Our Precision: 121 Captions',
    enquireDescription: 'Unlock Clear Communication with our Expert Services'
  },
  findOutHeader1: 'Your Top Live Captioning Service -',
  findOutHeader2: 'Find Out Why',
  findOut: [
    {
      heading: 'Smooth Streaming',
      icon: chat,
      description: 'As you speak, text appears one word at a time'
    },
    {
      heading: 'Speed Control',
      icon: speed,
      description: 'Adjust your live captioning speed on the fly'
    },
    {
      heading: 'Best Steno Captioners',
      icon: trophy,
      description: 'NCRA and NRCPD accredited with 99.5% accuracy'
    },
    {
      heading: 'Multiple Languages',
      icon: fontIcon,
      description: 'We caption online in more than 30 languages and offline in over 80 languages.'
    },
    {
      heading: 'Highly Flexible',
      icon: network,
      description: 'You can access our captions on your laptop, tablet, smartphone etc'
    },
    {
      heading: 'High Accuracy',
      icon: doubleTick,
      description: 'We pride ourselves on 100% target accuracy, which you will not find elsewhere'
    }
  ],

  goal: {
    heading1: 'Capturing Every Word, ',
    heading2: 'Connecting Every Voice',
    desc: [
      {
        desc: `At 121 Captions, we specialise in capturing every word and connecting every voice, breaking down geographical barriers to deliver live captioning services for events around the world. Whether you're a major corporation, a conference organiser, or an individual seeking accessibility, we aim to help make your business events and teleconference calls inclusive and accessible to all.`
      },
      {
        desc: `We bring our live captioning services directly to you with just an internet and audio connection. Our global coverage means that our high-quality live captioning services are available to you regardless of your timezone.`
      },
      {
        desc: `Our live captioning services are intended to help people who have hearing loss or speak English as a second language. Incorporating live captions into your corporate events and teleconferences not only reduces communication barriers, but also fosters a more inclusive workplace.`
      },
      {
        desc: `Investing in live captioning not only meets accessibility criteria, but it also serves as a strategic move to increase your company's profitability and appeal. A more diversified, accessible, and inclusive strategy appeals to both customers and staff, demonstrating your dedication to embracing diversity.`
      },
      {
        desc: `At 121 Captions, we go beyond just live captioning; we help businesses to communicate inclusively on a worldwide scale. Discover the best live captioning for your events, teleconferences, and corporate communications. Join us in creating a future in which every word is captured and every voice heard.`
      }
    ]
  },
  ebook: {
    heading: 'Download our free e-book!',
    desc: 'An ultimate guide to running successfull events for deaf people'
  },
  loginHeading1: 'Login to',
  loginHeading2: ' 121 Captions',
  otpNotification: 'Enter otp for verification',
  enquireHeading: 'Enquire about Your Event',
  enquireDesc:
    "Whether you're planning an event or looking for services, our enquiry page is designed to gather all the essential details tailored to your needs.",
  enquireEmail: 'Enter your email address to verify your profile information',
  enquireOtpHeading: 'Verify your email address',
  enquireOtpDesc: (email) =>
    `Enter the one time password (otp)  that has been sent via email address to ${email} to verify it.`,
  enquireSelectServiceHeading: 'Select your service',
  enquireSelectServiceDesc: 'Get details about the service',
  enquireEventMessage: `This form has questions based on the service type and event type i.e on-site or remote the customer has selected`,
  serviceDetails: {
    Automation: `Verbatim captions are word for word live, simultaneous report of what is being said at up to 
    360 words per minute (CART). 280 words per minute for UK speech to text reporters 
    (palantypists and stenographers).This service can be booked by the hour with a minimum booking of 1 hour. For 
    events over 90 mins a break will be required or captioners will need to be switched.
    ONSITE-.  A remote service is where your captioner(s) will connect to 
    your event via a web based audio visual link that you will need to supply.
    REMOTE- This service can be booked by the hour with a minimum booking of 1 hour. For 
    events over 90 mins a break will be required or captioners will need to be switched.
    ONSITE-. An onsite service is where our captioner(s) will attend your event in person. This 
    service will be quoted at a half day or full day rate, for onsite requests most captioners will co work. You will need to provide them with a large desk, comfortable armless chair, to be near a power supply or with appropriate extension cable with min of 3 sockets, if captions required on a screen at venue an HDMI cable will be needed. If captions are required on a company computer administrative rights may be required to download view App ahead of 
    event`,
    software_solutions: `A remote service is where your captioner(s) will connect to 
    your event via a web based audio visual link that you will need to supply.
    REMOTE- This service can be booked by the hour with a minimum booking of 1 hour. For 
    events over 90 mins a break will be required or captioners will need to be switched.
    ONSITE-. An onsite service is where our captioner(s) will attend your event in person.`,
    Digital_Marketing: `This service can be booked by the hour with a minimum booking of 1 hour. For 
    events over 90 mins a break will be required or captioners will need to be switched.
    ONSITE-. An onsite service is where our captioner(s) will attend your event in person. This 
    service will be quoted at a half day or full day rate, for onsite requests most captioners will co work. You will need to provide them with a large desk, comfortable armless chair, to be near a power supply or with appropriate extension cable with min of 3 sockets, if captions required on a screen at venue an HDMI cable will be needed. If Captions are required on a company computer administrative rights may be required to download view App ahead of 
    event`
  },
  supplierHeading: 'Join 121 Captions as a supplier',
  supplierDesc:
    'Register to offer your services for 121 Captions. Your expertise is invaluable in making events more accessible!',
  skills: ['HTML', 'CSS', 'JAVASCRIPT', 'REACT'],
  languages: ['ENGLISH', 'HINDI', 'KANNADA', 'TAMIL', 'BENGALI'],
  currency: ['INR', 'USD', 'YEN', 'REAL', 'KWD', 'EURO'],
  city: ['BANGALORE', 'CHENNAI', 'MUMBAI', 'HYDERABAD'],
  state: ['KERALA', 'KARNATAKA', 'MAHARASHTRA', 'WEST-BENGAL'],
  country: ['INDIA', 'USA', 'FRANCE', 'GERMANY', 'CHINA', 'RUSSIA'],
  supplierOtpHeading: 'Verify your email address',
  supplierOtpDesc: (email) =>
    `Enter the one time password (otp)  that has been sent via email address to ${email} to verify it.`,
  adminTableHeading: ['Admin Details', 'Status', 'Project Count', 'Close Leads', 'Actions'],

  superAdminMenuItems: [
    {
      id: 1,
      menu: 'Dashboard',
      icon: customerDashIcon,
      route: SCREEN_PATH.ADMIN_DASHBOARD
    },
    {
      id: 10,
      menu: 'Inbox',
      icon: inbox,
      route: SCREEN_PATH.CHAT_SCREEN
    },
    {
      id: 2,
      menu: 'Manage Projects',
      icon: projects,
      route: SCREEN_PATH.MANAGE_PROJECTS
    },
    {
      id: 4,
      menu: 'Manage Suppliers',
      icon: suppliers,
      route: SCREEN_PATH.MANAGE_SUPPLIERS
    },
    {
      id: 5,
      menu: 'Manage Admins',
      icon: admins,
      route: SCREEN_PATH.MANAGE_ADMINS
    },
    {
      id: 6,
      menu: 'Manage Jobs',
      icon: jobsIcon,
      route: SCREEN_PATH.MANAGE_JOBS
    },
    {
      id: 7,
      menu: 'Project Managers',
      icon: projectManagers,
      route: SCREEN_PATH.PROJECT_MANAGERS
    },
    {
      id: 3,
      menu: 'Manage Customers',
      icon: customers,
      route: SCREEN_PATH.MANAGE_CUSTOMERS
    },
    {
      id: 8,
      menu: 'Profile Settings',
      icon: profileSettings,
      route: SCREEN_PATH.PROFILE_SETTINGS
    },

    {
      id: 9,
      menu: 'Other Settings',
      icon: otherSettings,
      route: SCREEN_PATH.OTHER_SETTINGS
    }
  ],

  superAdminMenuItemsWithLogout: [
    {
      id: 1,
      menu: 'Dashboard',
      icon: dashbord,
      route: SCREEN_PATH.ADMIN_DASHBOARD
    },
    {
      id: 10,
      menu: 'Inbox',
      icon: inbox,
      route: SCREEN_PATH.CHAT_SCREEN
    },
    {
      id: 2,
      menu: 'Manage Projects',
      icon: projects,
      route: SCREEN_PATH.MANAGE_PROJECTS
    },
    {
      id: 4,
      menu: 'Manage Suppliers',
      icon: suppliers,
      route: SCREEN_PATH.MANAGE_SUPPLIERS
    },

    {
      id: 5,
      menu: 'Manage Admins',
      icon: admins,
      route: SCREEN_PATH.MANAGE_ADMINS
    },
    {
      id: 6,
      menu: 'Manage Jobs',
      icon: jobsIcon,
      route: SCREEN_PATH.MANAGE_JOBS
    },
    {
      id: 7,
      menu: 'Project Managers',
      icon: projects,
      route: SCREEN_PATH.PROJECT_MANAGERS
    },
    {
      id: 3,
      menu: 'Manage Customers',
      icon: customers,
      route: SCREEN_PATH.MANAGE_CUSTOMERS
    },
    {
      id: 8,
      menu: 'Profile Settings',
      icon: profileSettings,
      route: SCREEN_PATH.PROFILE_SETTINGS
    },
    {
      id: 9,
      menu: 'Other Settings',
      icon: otherSettings,
      route: SCREEN_PATH.OTHER_SETTINGS
    },

    {
      menu: 'Logout'
    }
  ],

  selectOptions: ['monthly', 'weekly', 'yearly', 'overall'],
  otherSettingsTab: [
    { heading: 'Manage Services' },
    { heading: 'Manage Languages' },
    // { heading: 'Manage Skills' },
    { heading: 'Manage Currencies' },
    { heading: 'Manage Countries' },
    { heading: 'Manage Master List' },
    { heading: 'Manage Timezones' }
  ],
  companyOverviewHeading: 'Current Available Companies',
  otherSettingsLanguagesHeading: 'Current Available Languages',
  otherSettingsLanguageModalHeading: 'Add new language',
  profileOptions: ['Change', 'Delete'],
  sortOptions: [
    { key: 'Alphabetically Asc. (A ➜ Z)', value: 'asc' },
    { key: 'Alphabetically Dsc. (Z ➜ A)', value: 'desc' },
    { key: 'Latest first', value: 'latest' },
    { key: 'Oldest first', value: 'oldest' }
  ],
  applicantsSortOptions: [
    { key: 'Latest first', value: 'latest' },
    { key: 'Oldest first', value: 'oldest' }
  ],
  mainFilterOption: [
    {
      filterType: 'Status',
      value: 'isActive',
      option: [
        { name: 'Active', value: 'true' },
        { name: 'Inactive', value: 'false' }
      ]
    }
  ],
  applicantFilterOption: [
    {
      filterType: 'Status',
      value: 'status',
      option: [
        { name: 'Accepted', value: '2' },
        { name: 'Rejected', value: '3' },
        { name: 'Pending', value: '1' }
      ]
    }
  ],
  approvedFilterOption: [
    {
      filterType: 'Status',
      value: 'active',
      option: [
        { name: 'Active', value: 'true' },
        { name: 'Inactive', value: 'false' }
      ]
    },
    {
      filterType: 'Approved',
      value: 'approved',
      option: [
        { name: 'Pending', value: 'null' },
        { name: 'Accept', value: '1' },
        { name: 'Reject', value: '0' }
      ]
    }
  ],

  proFilterOptions: [
    {
      filterType: 'Status',
      value: 'status',
      option: [
        { name: 'Initial contact', value: 'initial-contact' },
        { name: 'Quote issued', value: 'quotation-issued' },
        { name: 'Quote accepted', value: 'quotation-accepted' },
        { name: 'Amended', value: 'amended' },
        { name: 'Quote rejected', value: 'quotation-rejected' },
        { name: 'In progress', value: 'in-progress' },
        { name: 'Completed', value: 'completed' },
        { name: 'Ready for billing', value: 'ready-for-billing' },
        { name: 'Invoice raised', value: 'invioce-raised' },
        { name: 'Cancelled', value: 'cancelled' },
        { name: 'Archived', value: 'archived' }
      ]
    }
  ],

  proSuppFilterOptions: [
    {
      filterType: 'Status',
      value: 'status',
      option: [
        { name: 'Pending', value: 'pending' },
        { name: 'In progress', value: 'in-progress' },
        { name: 'Completed', value: 'completed' },
        { name: 'Cancelled', value: 'cancelled' }
      ]
    }
  ],

  projectFilterOptions: [
    { key: 'Initial contact', value: 'initial-contact', id: 1 },
    { key: 'Quote issued', value: 'quotation-issued', id: 2 },
    { key: 'Quote accepted', value: 'quotation-accepted', id: 3 },
    { key: 'Amended', value: 'quotation-rejected', id: 5 },
    { key: 'Quote rejected', value: 'quotation-rejected', id: 4 },
    { key: 'In progress', value: 'in-progress', id: 6 },
    { key: 'Completed', value: 'completed', id: 7 },
    { key: 'Ready for billing', value: 'ready-for-billing', id: 8 },
    { key: 'Invoice raised', value: 'invoice-raised', id: 9 },
    { key: 'Archived', value: 'archived', id: 11 }
  ],

  projectFilterOptionsForSupplier: [
    { key: 'Pending', value: 'initial-contact', id: 1 },
    { key: 'In progress', value: 'in-progress', id: 6 },
    { key: 'Completed ', value: 'completed', id: 7 }
  ],

  cancelledProjectFilterOptions: [
    { key: 'Initial contact', value: 'initial-contact', id: 1 },
    { key: 'Cancelled', value: 'cancelled', id: 10 }
  ],

  cancelledSupProjectFilterOptions: [
    { key: 'Pending', value: 'initial-contact', id: 1 },
    { key: 'Cancelled', value: 'cancelled', id: 10 }
  ],

  manageAdminTitle: 'Current available admins',
  managePMTitle: 'Current available project managers',
  otherSettingsServicesHeading: 'Current Available Services',
  otherSettingsCurrencyHeading: 'Current Available Currency',
  otherSettingsCurrencyModalHeading: 'Add new currency',
  otherSettingsSkillsHeading: 'Current Available Skills',
  otherSettingsSkillsModalHeading: 'Add new skill',
  otherSettingsCountriesHeading: 'Current Available Countries',
  countriesModalHeadingAdd: 'Add new country',
  countriesModalHeadingState: 'Add new state',
  countriesModalHeadingEdit: 'Edit country',
  ServiceEditTitle: 'Service details',
  ServiceAddTitle: ' Add new service',
  // demo values for admin dashboard
  dashbordResponse: {
    status: 'success',
    status_code: 'S-10004',
    projects_count: {
      total_projects: 10,
      ongoing_projects: 5,
      delayed_projects: 2,
      closed_projects: 3
    },
    leads_count: {
      new_leads: 10,
      quotation_issued: 5,
      accepted_leads: 2,
      rejected_leads: 3
    },

    lead_distribution_data: [
      {
        key: 'week 1', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        value: 5 //count of leads
      },
      {
        key: 'week 2', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        value: 8 //count of leads
      },
      {
        key: 'week 3', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        value: 4 //count of leads
      },
      {
        key: 'week 4', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        value: 10 //count of leads
      }
    ],
    supplier_count_data: [
      {
        type: 'active', //active or inactive
        value: 75 //number of active or inactive suppliers
      },
      {
        type: 'inactive', //active or inactive
        value: 25 //number of active or inactive suppliers
      }
    ],
    supplier_engagement_data: [
      {
        key: 'Jan', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 5, //number of Available
        Engaged: 7
      },
      {
        key: 'Feb', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 7, //number of Available
        Engaged: 5
      },
      {
        key: 'Mar', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 4, //number of Available
        Engaged: 8
      },
      {
        key: 'Apr', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 2, //number of available
        Engaged: 5
      },
      {
        key: 'May', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 5, //number of Available
        Engaged: 7
      },
      {
        key: 'June', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 7, //number of Available
        Engaged: 5
      },
      {
        key: 'July', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 4, //number of Available
        Engaged: 8
      },
      {
        key: 'Aug', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 2, //number of available
        Engaged: 5
      },
      {
        key: 'Sep', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 5, //number of Available
        Engaged: 7
      },
      {
        key: 'Oct', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 7, //number of Available
        Engaged: 5
      },
      {
        key: 'Nov', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 4, //number of Available
        Engaged: 8
      },
      {
        key: 'Dec', //week 1, week 2, week 3 etc. or Jan, Feb, Mar etc. or Day 1, Day 2, Day 3 etc...
        Available: 2, //number of available
        Engaged: 5
      }
    ],
    new_suppliers: 10 //number of new suppliers for the selected period
  },
  quotModelType: {
    send: 'send',
    view: 'view',
    edit: 'edit'
  },

  questionTypes: [
    {
      title: 'Single Line',
      placeholder: 'single line answer',
      answer_type: 1
    },
    {
      title: 'Multiple Line',
      placeholder: 'multiple line answer',
      answer_type: 2
    },
    {
      title: 'Options',
      placeholder: 'option',
      answer_type: 3
    }
  ],
  prePaymentOptions: [
    {
      title: 'Yes',
      value: true
    },
    {
      title: 'No',
      value: false
    }
  ],
  translationRequired: [
    {
      title: 'Yes',
      value: 1
    },
    {
      title: 'No',
      value: 2
    },
    {
      title: 'Not applicable',
      value: 3
    }
  ],
  type: ['Private', 'Public', 'Co-opp'],

  eventType: [
    {
      title: 'Remote',
      value: true
    },
    {
      title: 'Onsite',
      value: true
    }
  ],
  postEvent: [
    {
      title: 'Rough First Draft',
      value: true
    },
    {
      title: 'Edited transcript',
      value: true
    }
  ],
  commonOption: [
    {
      title: 'Yes',
      value: true
    },
    {
      title: 'No',
      value: false
    }
  ],

  manageCustomers: [
    { heading: 'Company overview', value: 1 },
    { heading: 'Find user', value: 2 }
  ],

  companyDetailsTabs: [
    { heading: 'Team members', value: 'team' },
    { heading: 'Associated project', value: 'project' },
    { heading: 'Associated suppliers', value: 'suppliers' },
    { heading: 'View notes', value: 'note' }
  ],

  regTitle: 'Confirm Company Registration',
  subTitle: 'To proceed, kindly provide your Registration number for validation.',
  placeholder: 'enter company number',
  confirmModal: {
    title: 'Confirmation required',
    message: 'Your company will be registered with us and you will be the admin',
    btn: 'Confirm'
  },
  changeStatusModal: {
    title: 'Are you sure?',
    btn: 'Confirm'
  },
  userExistedModal: {
    title: 'Your company is registered with us',
    message:
      'The company admin has received your booking request. Please feel free to proceed with your booking',
    btn: 'OK'
  },
  errorCode: 'E-10006',
  customerMenu: [
    {
      id: 1,
      menu: 'Dashboard',
      icon: customerDashIcon,
      route: SCREEN_PATH.ADMIN_DASHBOARD,
      title: 'My Dashboard'
    },
    {
      id: 6,
      menu: 'Inbox',
      icon: inbox,
      route: SCREEN_PATH.CHAT_SCREEN
    },
    {
      id: 2,
      menu: 'Manage Projects',
      icon: projects,
      route: SCREEN_PATH.MANAGE_PROJECTS,
      title: 'Manage projects'
    },
    {
      id: 4,
      menu: 'Manage Team',
      icon: teamIcon,
      route: SCREEN_PATH.MANAGE_TEAM,
      title: 'Manage team'
    },

    {
      id: 5,
      menu: 'Profile Settings',
      icon: profileSettings,
      route: SCREEN_PATH.PROFILE_SETTINGS,
      title: 'Profile settings'
    }
  ],
  customerMenuWithLogout: [
    {
      id: 1,
      menu: 'Book Service',
      icon: customerDashIcon,
      route: SCREEN_PATH.ADMIN_DASHBOARD,
      title: 'My Dashboard'
    },
    {
      id: 2,
      menu: 'Dashboard',
      icon: customerDashIcon,
      route: SCREEN_PATH.ADMIN_DASHBOARD,
      title: 'My Dashboard'
    },
    {
      id: 6,
      menu: 'Inbox',
      icon: inbox,
      route: SCREEN_PATH.CHAT_SCREEN
    },

    {
      id: 3,
      menu: 'Manage Projects',
      icon: projects,
      route: SCREEN_PATH.MANAGE_PROJECTS,
      title: 'Manage projects'
    },

    {
      id: 4,
      menu: 'Manage Team',
      icon: teamIcon,
      route: SCREEN_PATH.MANAGE_TEAM,
      title: 'Manage team'
    },

    {
      id: 5,
      menu: 'Profile Settings',
      icon: profileSettings,
      route: SCREEN_PATH.PROFILE_SETTINGS,
      title: 'Profile settings'
    },

    {
      menu: 'Logout'
    }
  ],
  PMMenu: [
    {
      id: 1,
      menu: 'Dashboard',
      icon: customerDashIcon,
      route: SCREEN_PATH.ADMIN_DASHBOARD,
      title: 'My Dashboard'
    },
    {
      id: 4,
      menu: 'Inbox',
      icon: inbox,
      route: SCREEN_PATH.CHAT_SCREEN
    },
    {
      id: 2,
      menu: 'Manage Projects',
      icon: projects,
      route: SCREEN_PATH.MANAGE_PROJECTS,
      title: 'Manage projects'
    },

    {
      id: 3,
      menu: 'Profile Settings',
      icon: profileSettings,
      route: SCREEN_PATH.PROFILE_SETTINGS,
      title: 'Profile settings'
    }
  ],
  PMMenuWithLogout: [
    {
      id: 1,
      menu: 'Dashboard',
      icon: customerDashIcon,
      route: SCREEN_PATH.ADMIN_DASHBOARD,
      title: 'My Dashboard'
    },

    {
      id: 10,
      menu: 'Inbox',
      icon: inbox,
      route: SCREEN_PATH.CHAT_SCREEN
    },
    {
      id: 2,
      menu: 'Manage Projects',
      icon: projects,
      route: SCREEN_PATH.MANAGE_PROJECTS,
      title: 'Manage projects'
    },

    {
      id: 5,
      menu: 'Profile Settings',
      icon: profileSettings,
      route: SCREEN_PATH.PROFILE_SETTINGS,
      title: 'Profile settings'
    },

    {
      menu: 'Logout'
    }
  ],
  customer: 'customer',
  supplier: 'supplier',

  languageExistingError: 'Language is already selected',
  timeZone: {
    title: 'Timezone',
    editHeading: 'Edit timezone',
    addHeading: 'Add new timezone',
    placeholder: 'eg . GMT-05:00 Eastern time',
    name: 'timezone',
    editBtn: 'save changes',
    saveBtn: 'save',
    searchLabel: 'Search timezone',
    addBtn: '+ new timezone',
    heading: 'Current available timezones',
    errorCode: 'E-10087'
  },
  nonApproved: {
    status: 'Account approval pending!',
    rejected: 'Account rejected!',
    message: `⚠️ Your account is currently pending approval by the admin. Please wait for further instructions.`,
    rejectedMsg: `⚠️ Unfortunately your account has been rejected by the admin.`,
    details: `For any further queries, please contact us at ${email}`
  },
  projectsStatues: [
    {
      id: 1,
      value: 'initial-contact',
      title: 'Initial contact',
      color: color.palette.lightBlue,
      bgColor: color.palette.ligntBgBlue
    },
    {
      id: 2,
      value: 'quotation-issued',
      title: 'Quote issued',
      color: color.palette.statusOrange,
      bgColor: color.palette.statusLightOrange
    },
    {
      id: 3,
      value: 'quotation-accepted',
      title: 'Quote accepted',
      color: color.palette.buttonGreen,
      bgColor: color.palette.lightGreen
    },
    {
      id: 4,
      value: 'quotation-rejected',
      title: 'Quote rejected',
      color: color.palette.primaryRed,
      bgColor: color.palette.primaryLightRed
    },
    {
      id: 5,
      value: 'amended',
      title: 'Amended',
      color: color.palette.secondaryRed,
      bgColor: color.palette.statusLightOrange
    },
    {
      id: 6,
      value: 'in-progress',
      title: 'In-progress',
      color: color.palette.secondaryRed,
      bgColor: color.palette.secondaryLightRed
    },
    {
      id: 7,
      value: 'completed',
      title: 'Completed',
      color: color.palette.secondaryBlue,
      bgColor: color.palette.secondaryLightBlue
    },
    {
      id: 8,
      value: 'ready_for_billing',
      title: 'Ready for billing',
      color: color.palette.secondaryBlue,
      bgColor: color.palette.secondaryLightBlue
    },
    {
      id: 9,
      value: 'invioce_raised',
      title: 'Invioce raised',
      color: color.palette.lightBlue,
      bgColor: color.palette.ligntBgBlue
    },
    {
      id: 10,
      value: 'cancelled',
      title: 'Cancelled',
      color: color.palette.darkGray,
      bgColor: color.palette.primaryLightRed
    },
    {
      id: 11,
      value: 'archived',
      title: 'Archived',
      color: color.palette.darkGray,
      bgColor: color.palette.archived
    }
  ],
  projectsFlags: [
    { id: 1, value: 'invoice not sent', title: 'Invoice not sent' },
    { id: 2, value: 'customer query not responded', title: 'Customer query not responded' },
    { id: 3, value: 'project behind schedule', title: 'Project behind schedule' },
    { id: 4, value: 'supplier not assigned', title: 'Supplier not assigned' },
    { id: 5, value: 'brief not accepted', title: 'Brief not accepted' },
    {
      id: 6,
      value: 'supplier has not accepted project',
      title: 'Supplier has not accepted project'
    },
    { id: 7, value: 'platform / link confirmed', title: 'Platform / link confirmed' },
    { id: 8, value: 'extra hours for the project', title: 'Extra hours for the project' }
  ],
  projectsStatus: {
    archived: 'archived',
    inProgress: 'in-progress',
    initialContact: 'initial-contact',
    quotationIssued: 'quotation-issued',
    quotationAccepted: 'quotation-accepted',
    quotationRejected: 'quotation-rejected',
    completedAndInvoiceRaised: 'completed-and-invoice-raised'
  },
  assignProjectHeading: 'Assign project admin',
  titleAssignAdmin: 'Assign admin',
  placeholderSelectAdmin: 'select admin',
  userRole: {
    admin: 'admin',
    superAdmin: 'super-admin',
    customer: 'customer',
    supplier: 'supplier',
    projectManger: 'project-manager'
  },
  userRoles: [
    { name: 'super-admin', id: 1 },
    { name: 'admin', id: 2 },
    { name: 'customer', id: 3 },
    { name: 'supplier', id: 4 },
    { name: 'project-manager', id: 5 }
  ],
  assignAdmin: 'assignAdmin',
  manageTeamTab: [
    { heading: 'Company details' },
    { heading: 'Team details' },
    { heading: 'Incoming requests' }
  ],
  teamDetails: {
    title: 'Timezone',
    editHeading: 'Edit timezone',
    addHeading: 'Add new timezone',
    placeholder: 'eg . GMT-05:00 Eastern time',
    name: 'teamDetails',
    editBtn: 'save changes',
    saveBtn: 'save',
    searchLabel: 'search for a member',
    addBtn: '+ new member',
    heading: 'Current available timezones',
    errorCode: 'E-10087'
  },
  supplierList: [
    {
      id: 1,
      first_name: 'Jane',
      last_name: 'Cooper',
      email: 'janecooper@gmail.com',
      isActive: true,
      isApproved: 1
    },
    {
      id: 2,
      first_name: 'Jane',
      last_name: 'Cooper',
      email: 'janecooper@gmail.com',
      isActive: true,
      isApproved: 2
    },
    {
      id: 3,
      first_name: 'Jane',
      last_name: 'Cooper',
      email: 'janecooper@gmail.com',
      isActive: false,
      isApproved: 3
    },
    {
      id: 4,
      first_name: 'Jane',
      last_name: 'Cooper',
      email: 'janecooper@gmail.com',
      isActive: true,
      isApproved: 1
    }
  ],
  accent: 'Accent',
  postLoginStatusCode: 'S-10075',
  projectDetails: [
    {
      id: 1,
      title: 'Project requirements',
      desc: 'Track customer details, project status, and event specifics effortlessly.',
      icon: solarDocument,
      route: SCREEN_PATH.PROJECT_REQUIREMENTS
    },

    {
      id: 2,
      title: 'Supplier hub',
      desc: 'Connect Projects with Suppliers Effortlessly, from Suggestions to Invitations.',
      icon: mdiPersonAdd,
      route: SCREEN_PATH.PROJECT_SUPPLIER_HUB
    },
    {
      id: 5,
      title: 'Chat',
      desc: 'Stay connected, discuss project details, and make decisions efficiently.',
      icon: chatTwo,
      route: SCREEN_PATH.PROJECT_CHAT
    },
    {
      id: 3,
      title: 'Task management',
      desc: 'Stay organized with project tasks, status, and progress',
      icon: todoList,
      route: SCREEN_PATH.PROJECT_TASK_MAN
    },
    {
      id: 4,
      title: 'Document management',
      desc: 'Manage project documents securely: upload, delete, download.',
      icon: clarityDocument,
      route: SCREEN_PATH.PROJECT_DOCUMENT_MAN
    },
    {
      id: 6,
      title: 'Project Calender',
      desc: 'Visualize and manage project tasks efficiently on a calendar.',
      icon: calenderDocument,
      route: SCREEN_PATH.CALENDER_DOCUMENT_MAN
    },
    {
      id: 7,
      title: 'feedback',
      desc: 'Share valuable feedback to enhance project outcomes.',
      icon: feedback,
      route: SCREEN_PATH.FEEDBACK
    }
  ],
  supplierMenu: [
    {
      menu: 'Dashboard',
      icon: customerDashIcon,
      route: SCREEN_PATH.ADMIN_DASHBOARD,
      title: 'My Dashboard'
    },

    {
      menu: 'Inbox',
      icon: inbox,
      route: SCREEN_PATH.CHAT_SCREEN,
      title: 'Messages'
    },
    {
      menu: 'Manage Projects',
      icon: projects,
      route: SCREEN_PATH.MANAGE_PROJECTS,
      title: 'Manage projects'
    },
    {
      menu: 'Manage Invitations',
      icon: jobsIcon,
      route: SCREEN_PATH.MANAGE_INVITATION,
      title: 'Manage Invitations'
    },
    {
      menu: 'View jobs',
      icon: viewJob,
      route: SCREEN_PATH.VIEW_JOBS,
      title: 'View jobs'
    },

    {
      menu: 'Profile Settings',
      icon: profileSettings,
      route: SCREEN_PATH.PROFILE_SETTINGS,
      title: 'Profile settings'
    }
  ],
  supplierMenuWithLogout: [
    {
      menu: 'Dashboard',
      icon: customerDashIcon,
      route: SCREEN_PATH.ADMIN_DASHBOARD,
      title: 'My Dashboard'
    },
    {
      menu: 'Inbox',
      icon: inbox,
      route: SCREEN_PATH.CHAT_SCREEN,
      title: 'Messages'
    },
    {
      menu: 'Manage Projects',
      icon: projects,
      route: SCREEN_PATH.MANAGE_PROJECTS,
      title: 'Manage projects'
    },
    {
      menu: 'Manage Invitations',
      icon: jobsIcon,
      route: SCREEN_PATH.MANAGE_INVITATION,
      title: 'Manage Invitations'
    },
    {
      menu: 'View jobs',
      icon: viewJob,
      route: SCREEN_PATH.VIEW_JOBS,
      title: 'View jobs'
    },

    {
      menu: 'Profile Settings',
      icon: profileSettings,
      route: SCREEN_PATH.PROFILE_SETTINGS,
      title: 'Profile settings'
    },

    {
      menu: 'Logout'
    }
  ],
  listConstants: {
    projects: 'projects',
    customers: 'customers',
    supplier: 'supplier',
    profile: 'profile',
    timezone: 'timezone',
    services: 'services',
    languages: 'languages',
    language: 'language',
    skills: 'skills',
    currency: 'currency',
    countries: 'countries',
    masterList: 'masterList',
    state: 'state',
    company_customer: 'company_customer',
    company_name: 'company_name',
    admin: 'admin',
    pm: 'PM',
    accent: 'accent',
    language_title: 'language_title',
    skill_title: 'skill_title',
    first_name: 'first_name',
    last_name: 'last_name',
    email_id: 'email_id',
    locality: 'locality',
    country: 'country',
    city: 'city',
    postal_code: 'postal_code',
    timezoneId: 'timezoneId',
    country_name: 'country_name',
    skill_name: 'skill_name',
    project_title: 'project_title',
    title: 'title',
    name: 'name',
    view: 'view',
    edit: 'edit',
    send: 'send',
    item: 'item',
    manage_job: 'manageJob',
    job_title: 'job_title',
    accept: 'accept',
    reject: 'reject',
    Accept: 'Accept',
    Reject: 'Reject',
    Cancel: 'Cancel',
    success: 'success',
    jobs: 'jobs',
    Confirm: 'Confirm',
    Replace: 'Replace',
    Remove: 'Remove',
    manager: 'manager',
    applicants: 'applicants',
    manageTeam: 'teamDetails',
    projectManager: 'projectManager',
    skype_id: 'skype_id',
    whatsapp_number: 'whatsapp_number',
    telephone_number: 'telephone_number',
    four: 4,
    five: 5,
    Services: 'Services',
    Enter: 'Enter',
    Login: 'Login',
    Verify: 'Verify',
    view_task_details: 'view-task-details',
    project_chat: 'project-chat',
    personel_chat: 'personel-chat',
    rate_supplier: 'rate-supplier',
    availability_details: 'availability_details',
    availability_type: 'availability_type',
    active_project: 'active_projects',
    active_admin_project: 'active_projects_admin'
  },
  supplierHub: {
    search: 'search suppliers',
    suggestionTitle: 'Below are the recommended suppliers based on the project requirements',
    existingTitle: 'Below are the suppliers who worked with this customer previously',
    otherOption: 'Explore other options',
    optionNote: `Couldn’t find the right match among the suggested ones, post a job or select from the list of available suppliers`,
    Project_Details: 'Project Details'
  },
  inviteSupDetails: ['Skills', 'Languages', 'Accent', 'Currency'],
  proDetails: {
    id: 123,
    customer_name: 'Jane', //concat the customer first and last mame and return as full name
    company_name: 'xyz',
    project_name: 'Project One',
    start_date: '01/05/2023',
    end_date: '20/08/2024',
    received_date: '15/03/2022', //created at date
    service_name: 'Live captioning',
    optionNote: `Couldn’t find the right match among the suggested ones, post a job or select from the list of available suppliers`
  },
  manageJob: {
    title: 'Manage jobs',
    editHeading: 'Edit timezone',
    addHeading: 'Add new timezone',
    placeholder: 'eg . GMT-05:00 Eastern time',
    name: 'manageJob',
    searchLabel: 'Search for a job',
    heading: 'Current available jobs'
  },

  inviteStatus: [
    {
      id: 1,
      title: 'Pending',
      color: color.palette.secondaryRed,
      bgColor: color.palette.secondaryLightRed
    },
    {
      id: 2,
      title: 'Accepted',
      color: color.palette.buttonGreen,
      bgColor: color.palette.lightGreen
    },
    {
      id: 3,
      title: 'Rejected',
      color: color.palette.primaryRed,
      bgColor: color.palette.primaryLightRed
    },
    { id: 4, title: 'Expired', color: color.palette.darkGray, bgColor: color.palette.lightGray }
  ],
  mInvitationDesc: 'Please tick all the below items to accept the project invitation',
  userInvitationModal: (type) => ({
    title: `Confirm invitation ${
      type === defaultValues.listConstants.accept ? 'acceptance' : 'rejection'
    }`,
    message: `Are you sure you want to ${type} this project invitation?`,
    btn: `${type === defaultValues.listConstants.accept ? 'Accept' : 'Reject'}`
  }),
  jobsConfirmationModal: (type) => ({
    title: `Confirm  ${type === defaultValues.listConstants.accept ? 'acceptance' : 'rejection'}`,
    message: `Are you sure you want to ${type} this applicant for the project ?`,
    btn: `${type === defaultValues.listConstants.accept ? 'Accept' : 'Reject'}`
  }),
  applyJobModal: {
    title: 'Confirm Application',
    message: `Are you sure you want to apply for this job ?`,
    btn: 'Apply'
  },
  proRequirementConfirmationModal: (type) => {
    return {
      title:
        (type && type.title === defaultValues.listConstants.Replace) ||
        (type && type.title === defaultValues.listConstants.Remove)
          ? `${type && type.title} Project Manager`
          : type && type.title === defaultValues.listConstants.supplier
            ? 'Remove Supplier'
            : type && type.title === defaultValues.listConstants.rate_supplier
              ? 'Rate supplier before removal'
              : 'Are you sure?',
      message:
        (type && type.title === defaultValues.listConstants.Replace) ||
        (type && type.title === defaultValues.listConstants.Remove)
          ? `Are you sure you want to ${
              type && type.title === defaultValues.listConstants.Replace ? 'replace' : 'remove'
            } the
        project manager?`
          : type && type.title === defaultValues.listConstants.supplier
            ? `Are you sure you want to remove the 
          assigned supplier?`
            : type && type.title === defaultValues.listConstants.rate_supplier
              ? 'Please rate the supplier before removing the assigned supplier'
              : `Project Status will be change to ${type && type.title}`,
      btn: `${
        (type && type.title === defaultValues.listConstants.Replace) ||
        (type && type.title === defaultValues.listConstants.Remove)
          ? type.title
          : (type && type.title === defaultValues.listConstants.supplier) ||
              (type && type.title === defaultValues.listConstants.rate_supplier)
            ? defaultValues.listConstants.Remove
            : defaultValues.listConstants.Confirm
      }`,
      iconType:
        type && type.title === defaultValues.listConstants.Replace
          ? defaultValues.listConstants.manager
          : (type && type.title === defaultValues.listConstants.Remove) ||
              (type && type.title === defaultValues.listConstants.rate_supplier)
            ? defaultValues.listConstants.reject
            : type && type.title === defaultValues.listConstants.supplier
              ? defaultValues.listConstants.reject
              : defaultValues.listConstants.Confirm
    };
  },
  existingUser: 'E-10036',
  taskListOptions: [
    {
      title: 'No due date',
      value: false
    },
    {
      title: 'Select date',
      value: true
    }
  ],
  repeatOptions: [
    { name: `don’t-repeat`, value: 0 },
    { name: `everyday`, value: 1 },
    { name: `every-weekday`, value: 2 },
    { name: `every-week-same-day`, value: 3 },
    { name: `every-other-week, same day`, value: 4 },
    { name: `every-month-on-specific-date`, value: 5 },
    { name: `every-year-same-day`, value: 6 },
    { name: `weekly-custom`, value: 7 },
    { name: `monthly-custom`, value: 8 }
  ],
  docType: [
    {
      title: 'project-requirements',
      value: 2
    },
    {
      title: 'instructions',
      value: 3
    },
    {
      title: 'process',
      value: 5
    }
  ],
  docFileTypes: ['pdf', 'jpg', 'jpeg', 'doc', 'docx', 'png'],
  days: [
    { key: 'SUNDAY', value: 'sun', name: 'S' },
    { key: 'MONDAY', value: 'mon', name: 'M' },
    { key: 'TUESDAY', value: 'tue', name: 'T' },
    { key: 'WEDNESDAY', value: 'wed', name: 'W' },
    { key: 'THURSDAY', value: 'thu', name: 'T' },
    { key: 'FRIDAY', value: 'fri', name: 'F' },
    { key: 'SATURDAY', value: 'sat', name: 'S' }
  ],
  taskStatus: [
    {
      statusCode: 0,
      status: 'to-do',
      bg: color.palette.taskStatusBlue,
      color: color.palette.lightBlue
    },
    {
      statusCode: 1,
      status: 'in-progress',
      bg: color.palette.dueDate,
      color: color.palette.secondaryRed
    },
    {
      statusCode: 2,
      status: 'completed',
      color: color.palette.buttonGreen,
      bg: color.palette.lightGreen
    },
    {
      statusCode: 3,
      status: 'archived',
      color: color.palette.darkGray,
      bg: color.palette.lightGray
    },
    {
      statusCode: 4,
      status: 'rejected',
      color: color.palette.primaryRed,
      bg: color.palette.primaryLightRed
    }
  ],
  fieldsToReset: [
    'first_name',
    'last_name',
    'email_id',
    'skills',
    'languages',
    'accent',
    'currency',
    'locality',
    'city',
    'state',
    'postal_code',
    'country',
    'timezoneId',
    'skype_id',
    'telephone_number',
    'whatsapp_number'
  ],

  taskColorCode: [
    {
      id: 0,
      bgColor: color.palette.lightBlue
    },
    {
      id: 1,
      bgColor: color.palette.secondaryOrange
    },
    {
      id: 2,
      bgColor: color.palette.buttonGreen
    }
  ],
  notificationFilterOption: [
    {
      filterType: 'Status',
      value: 'read_status',
      option: [
        { name: 'Read', value: 'true' },
        { name: 'Unread', value: 'false' }
      ]
    }
  ],
  projectStatusForSupplier: {
    pending: 'Pending',
    inProgress: 'In-progress',
    completed: 'Completed',
    cancelled: 'Cancelled'
  },
  noIncomingRequest: 'No incoming requests !',
  noTeamMembers: 'No team members found !',
  availabilityOptions: [
    {
      name: 'Week Days',
      id: 1
    },
    {
      name: 'Weekend',
      id: 2
    },
    {
      name: 'All Days',
      id: 3
    },
    { name: 'Other', id: 4 }
  ],
  activeProjects: [
    { name: 'Today', value: 'today' },
    { name: 'Tomorrow', value: 'tomorrow' },
    { name: 'This week', value: 'this-week' },
    { name: 'Next week', value: 'next-week' }
  ],
  uKTimeZone: '(GMT+1:00) Edinburgh, London (BST)'
};
