import { defaultValues } from '../../../constants';
import { customStyles } from '../../../theme';
import { color } from '../../../theme/color';

const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;
export const FCStyles = {
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.palette.primaryBackground,
    borderRadius: '5px'
  },
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: responsive ? '15px' : '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px'
  },
  searchBtn: {
    height: '55px',
    minWidth: responsive ? '100px' : '180px',
    marginLeft: responsive ? '10px' : '25px'
  },
  notFoundBox: {
    width: '100%',
    height: '50vh',
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  w25: { width: '25%', minWidth: '20%', wordWrap: 'break-word', marginX: '15px' },
  w15: {
    maxWidth: '15%',
    minWidth: '15%',
    wordWrap: 'break-word'
  },
  w10: { minWidth: '10%', wordWrap: 'break-word' },
  profileImage: {
    ...customStyles.flexBox,
    width: '40%',
    justifyContent: 'start'
  },
  subTitle: { textAlign: 'center', fontSize: responsive ? '20px' : '25px' },
  viewBtn: {
    color: color.palette.skyBlue,
    cursor: 'pointer',
    minWidth: '100px',
    marginRight: '30px'
  },
  collapseContainer: {
    width: '100%',
    padding: '5px',
    boxSizing: 'border-box'
  },
  topBar: {
    ...customStyles.flexBox,
    justifyContent: 'space-between',
    height: '55px',
    paddingBottom: '25px'
  },
  listTile: {
    ...customStyles.flexBox,
    margin: '20px 0',
    width: '95%',
    justifyContent: 'space-between',
    padding: '15px',
    border: `1px solid ${color.palette.borderColor}`,
    borderRadius: '8px',
    paddingRight: '15px'
  },
  profileBox: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginRight: '25px'
  },
  defaultCom: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginRight: '15px'
  },
  actionButton: (status) => ({
    boxSizing: 'border-box',
    borderRadius: '4px',
    textAlign: 'center',
    color: color.palette.primaryWhite,
    backgroundColor: status ? color.palette.primaryRed : color.palette.buttonGreen,
    fontSize: responsive ? '10px' : '16px',
    cursor: 'pointer',
    minWidth: responsive ? '60px' : '123px',
    minHeight: responsive ? '15px' : '45px',
    maxWidth: responsive ? '30px' : 'auto',
    padding: 0,
    '&:hover': {
      backgroundColor: status ? color.palette.primaryRed : color.palette.buttonGreen
    }
  }),
  nameBox: { wordWrap: 'break-word', flex: 1 },
  name: {
    fontSize: md ? 16 : 20
  },
  email: {
    fontSize: md ? '12px' : '16px'
  },
  cName: {
    fontSize: md ? 16 : 20
  }
};
