import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  ActionButton,
  Filter,
  IconsButton,
  PaginationCustom,
  Screen,
  SearchBar,
  Sort,
  Text,
  UIButton
} from '../../../ui-kit';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { Styles } from './style';
import {
  AddUserModal,
  AdminProfile,
  CollapsableList,
  NoData,
  StatusIndicator,
  Title
} from '../../../components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getUserRole, resetMessge } from '../../../utils';
import {
  getPMParams,
  getProjecsErrorMessage,
  getProjectManagers,
  getProjectsCurrentPage,
  getProjectsIndicator,
  getProjectsLoadingStatus,
  getProjectsSuccessMessage,
  getProjectsTotalPage
} from '../../../services/redux/selectors';
import {
  createProjectManagersRequest,
  projectManagersRequest,
  resetProjectsMessages,
  updateProjectManagersRequest,
  updateProjectParams
} from '../../../services/redux/slices';
import { useNavigate } from 'react-router-dom';
import { customStyles } from '../../../theme/styles';

export const ManagePMPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectManagers = useSelector(getProjectManagers);
  const pageCount = useSelector(getProjectsTotalPage);
  const currentRenderPage = useSelector(getProjectsCurrentPage);
  const successMessage = useSelector(getProjectsSuccessMessage);
  const errorMessage = useSelector(getProjecsErrorMessage);
  const isLoading = useSelector(getProjectsLoadingStatus);
  const indicator = useSelector(getProjectsIndicator);
  const pmParams = useSelector(getPMParams);

  const [managers, setManagers] = useState([]);
  const [openProfile, setOpenProfile] = useState(false);
  const [sortValue, setSortValue] = useState({});
  const roleId = getUserRole(defaultValues.userRole.projectManger);

  useEffect(() => {
    handleProjectManagers();
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    setManagers(projectManagers);
  }, [projectManagers]);

  useEffect(() => {
    if (indicator) {
      handleProjectManagers();
    }
  }, [indicator]);

  const handleProjectManagers = (data) => {
    let payload = {
      page: currentRenderPage || 1,
      ...pmParams,
      ...data
    };
    dispatch(updateProjectParams(payload));
    dispatch(projectManagersRequest(payload));
  };

  const handleProfileClose = () => {
    setOpenProfile(false);
  };

  const handlePageChange = (e, value) => {
    const payload = {
      page: value
    };
    dispatch(projectManagersRequest(payload));
  };

  const handleAddPM = (firstname, lastname, email, propic) => {
    let formdata = new FormData();
    formdata.append('first_name', firstname);
    formdata.append('last_name', lastname);
    formdata.append('email', email);
    formdata.append('photo', propic);
    let payload = {};
    formdata.forEach((value, key) => {
      payload[key] = value;
    });
    dispatch(createProjectManagersRequest(payload));
  };

  const handleSearch = (search_text) => {
    if (search_text.length === 0 || search_text.length >= 2) {
      setTimeout(() => {
        handleProjectManagers({ search_text });
      }, 1500);
    }
  };

  const handleStatus = (id, status) => {
    const payload = { id: id, active: !status };
    dispatch(updateProjectManagersRequest(payload));
  };

  return (
    <Screen showSideBar={true} currentPage="Manage project managers">
      <Box sx={Styles.superContainer}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={Styles.container}>
          <Box sx={Styles.contentContainer}>
            <Box sx={Styles.searchTabContainer}>
              <Box sx={Styles.searchToolsContainer}>
                {!defaultValues.isResponsive ? (
                  <SearchBar
                    label={'Search for project manager'}
                    searchedVlaue={handleSearch}
                    onRefresh={sortValue}
                  />
                ) : (
                  <Text sx={Styles.headingText} variant="medium">
                    Manage Project Managers
                  </Text>
                )}
                <UIButton
                  style={Styles.newButton}
                  title="+ new project managers"
                  onClick={() => setOpenProfile(true)}
                />
              </Box>

              <Box sx={Styles.toolsContainer}>
                <IconsButton
                  title={!defaultValues.isResponsive ? null : 'Refresh'}
                  icon={<RefreshIcon sx={Styles.icon} />}
                  onClick={() => {
                    setSortValue({});
                    handleProjectManagers({
                      sort: null,
                      filter: null,
                      page: null,
                      search_text: null
                    });
                    dispatch(updateProjectParams({}));
                  }}
                />
                <Sort
                  selectedOption={(sort) => {
                    setSortValue({ sort });
                    handleProjectManagers({ sort, page: 1 });
                  }}
                  activeOption={sortValue.sort}
                />
                <Filter
                  label={'Filter'}
                  selectedOption={(value) => {
                    handleProjectManagers({ filter: `[{ isActive: ${value} }]`, page: 1 });
                  }}
                  onRefresh={sortValue}
                />
              </Box>
            </Box>

            {defaultValues.isResponsive && (
              <Box sx={Styles.searchContainer}>
                <SearchBar
                  label={'Search for project manager'}
                  searchedVlaue={handleSearch}
                  onRefresh={sortValue}
                />
              </Box>
            )}
            <Text sx={Styles.tableHeading} variant="fileName">
              {defaultValues.managePMTitle}
            </Text>
            {!defaultValues.isResponsive ? (
              managers.length > 0 ? (
                <Table sx={Styles.tableContainer} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={Styles.headingCell} align="left">
                        Project Managers
                      </TableCell>
                      <TableCell sx={Styles.headingCell} align="left">
                        Engagement status
                      </TableCell>
                      <TableCell sx={Styles.headingCell} align="center">
                        Actions
                      </TableCell>
                      <TableCell sx={Styles.headingCell} align="center">
                        Projects Count
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {managers &&
                      managers.map((row, index) => (
                        <TableRow key={index} sx={Styles.tableRow}>
                          <TableCell component="th" scope="row">
                            <AdminProfile
                              profile={row}
                              titleStyle={{
                                ...customStyles.link,
                                ...customStyles.sTitle
                              }}
                              onTitleClick={() => {
                                navigate(
                                  `${SCREEN_PATH.VIEW_USER_PROFILE}?role=${roleId}&id=${row.id}`,
                                  {
                                    state: {
                                      type: defaultValues.listConstants.projectManager
                                    }
                                  }
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <StatusIndicator status={row.active} />
                          </TableCell>
                          <TableCell align="center" sx={customStyles.actionCont}>
                            <ActionButton
                              name={`${row.first_name} ${row.last_name}`}
                              item={row}
                              isEdit={false}
                              onButtonClick={() => handleStatus(row.id, row.active, index)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Title title={row.project_count} />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <NoData message={getErrorMessage('E-10096')} />
              )
            ) : (
              <Box sx={Styles.collapseContainer}>
                <CollapsableList
                  contentArray={managers}
                  type="PM"
                  isEdit={false}
                  onPMtitleClick={(row) => {
                    navigate(`${SCREEN_PATH.VIEW_USER_PROFILE}?role=${roleId}&id=${row.id}`, {
                      state: {
                        type: defaultValues.listConstants.projectManager
                      }
                    });
                  }}
                  isButton={true}
                  onButtonClick={(a, b, x, item) => handleStatus(item.id, item.active)}
                  modelName={true}
                  loaderSize={defaultValues.isResponsive && 15}
                />
              </Box>
            )}

            {currentRenderPage && pageCount && (
              <Box sx={Styles.paginationContainer}>
                <PaginationCustom
                  onChange={handlePageChange}
                  currentPage={currentRenderPage}
                  count={pageCount}
                />
              </Box>
            )}

            <AddUserModal
              open={openProfile}
              onClose={handleProfileClose}
              heading={'Add new project manager'}
              onButtonClick={handleAddPM}
              isLoading={isLoading}
              successMessage={successMessage}
              btnTitle="Add"
            />
          </Box>
        </Box>
      </Box>
    </Screen>
  );
};
