import React, { useEffect, useState } from 'react';
import {
  ActionButton,
  Filter,
  IconsButton,
  PaginationCustom,
  Screen,
  SearchBar,
  Sort,
  Text
} from '../../../ui-kit';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { CollapsableList, NoData, StatusIndicator, Title } from '../../../components';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import { styles } from './style';
import { useDispatch, useSelector } from 'react-redux';
import {
  getJobRequest,
  resetJobsMessages,
  updateApplicantCurrentPage,
  updateCurrJob,
  updateJobParams,
  updateJobStatusRequest
} from '../../../services/redux/slices/jobsSlice';
import {
  getAppCurrPage,
  getCurrJob,
  getCurrentPages,
  getJobErrorMessage,
  getJobIndicator,
  getJobLoading,
  getJobParams,
  getJobSuccessMessage,
  getJobs,
  getTotalPages
} from '../../../services/redux/selectors/jobSelector';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { useNavigate } from 'react-router-dom';
import { ManageApplicants } from './manage-applicants';
import { toast } from 'react-toastify';
import { resetMessge } from '../../../utils';
import { customStyles } from '../../../theme';

export const ManageJobsPage = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [page, setPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [totalPages, setTotalPage] = useState(0);
  const [filterValue, setFilterValuer] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);

  const allJobs = useSelector(getJobs);
  const isLoading = useSelector(getJobLoading);
  const totalPage = useSelector(getTotalPages);
  const currentPage = useSelector(getCurrentPages);
  const jobParams = useSelector(getJobParams);
  const successMessage = useSelector(getJobSuccessMessage);
  const errorMessage = useSelector(getJobErrorMessage);
  const indicator = useSelector(getJobIndicator);
  const currPage = useSelector(getAppCurrPage);
  const currJob = useSelector(getCurrJob);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetJobsMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (indicator) {
      handleManageJob();
    }
  }, [indicator]);

  const handleManageJob = (data) => {
    let payload = { page: currentPage };
    if (data !== undefined) {
      payload = { ...payload, ...jobParams, ...data };
    }
    dispatch(updateJobParams(payload));
    dispatch(getJobRequest(payload));
  };

  const handleSearch = (search_text) => {
    if (search_text.length === 0 || search_text.length >= 2) {
      handleManageJob({ search_text });
    }
  };
  const handleRefresh = () => {
    setFilterValuer({});
    handleManageJob({ sort: null, filter: null, page: null, search_text: null });
  };

  const handleFilter = (value) => {
    setFilterValuer({ filter: `[{ isActive: ${value} }]` });
    handleManageJob({ filter: `[{ isActive: ${value} }]`, page: 1 });
  };

  const handleSort = (sort) => {
    handleManageJob({ sort, page: 1 });
  };

  const handlePageChange = (e, value) => {
    const payload = {
      page: value
    };
    handleManageJob(payload);
  };

  const handleStatus = (row, index) => {
    setCurrentIndex(index);
    dispatch(updateJobStatusRequest({ job_id: row.job_id, active: !row.active }));
  };

  useEffect(() => {
    dispatch(getJobRequest({}));
  }, []);

  useEffect(() => {
    if (allJobs) {
      setJobs(allJobs);
    }
    if (totalPage) {
      setTotalPage(totalPage);
    }
    if (currentPage) {
      setPage(currentPage);
    }
  }, [allJobs]);

  const handleTitleClick = (item) => {
    navigation(SCREEN_PATH.JOB_DETAILS, { state: { data: item } });
  };

  const handleCurrPage = (page) => {
    dispatch(updateApplicantCurrentPage({ page }));
  };

  const handleViewApplicants = (item) => {
    handleCurrPage(2);
    dispatch(updateCurrJob(item));
  };

  if (currPage === 2) {
    return (
      <ManageApplicants currJob={currJob} isLoading={isLoading} handleCurrPage={handleCurrPage} />
    );
  }

  return (
    <Screen showSideBar={true} currentPage="Manage Jobs">
      <Box sx={styles.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={styles.contentContainer}>
          <Box sx={styles.searchTabContainer}>
            <Box sx={styles.searchToolsContainer}>
              {!defaultValues.isResponsive ? (
                <SearchBar
                  label={defaultValues.manageJob.searchLabel}
                  searchedVlaue={handleSearch}
                  onRefresh={!jobParams.search_text && {}}
                  defaultValue={jobParams.search_text}
                />
              ) : (
                <Text style={styles.headingText} variant="medium">
                  {defaultValues.manageJob.title}
                </Text>
              )}
            </Box>
            <Box sx={styles.toolsContainer}>
              <IconsButton
                title={!defaultValues.isResponsive ? null : 'Refresh'}
                icon={<RefreshIcon sx={styles.icon} />}
                onClick={handleRefresh}
              />
              <Sort selectedOption={handleSort} activeOption={jobParams.sort} />
              <Filter label={'Filter'} selectedOption={handleFilter} onRefresh={filterValue} />
            </Box>
          </Box>

          {!defaultValues.isResponsive ? (
            <Text sx={styles.heading} variant="fileName">
              {defaultValues.manageJob.heading}
            </Text>
          ) : (
            <Box sx={styles.searchContainer}>
              <SearchBar
                label={defaultValues.manageJob.searchLabel}
                searchedVlaue={handleSearch}
                onRefresh={!jobParams.search_text && {}}
                defaultValue={jobParams.search_text}
              />
            </Box>
          )}

          {!defaultValues.isResponsive ? (
            jobs.length > 0 ? (
              <Table sx={styles.tableContainer} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.headingCell} align="left">
                      Job title
                    </TableCell>
                    <TableCell sx={styles.headingCell} align="left">
                      Status
                    </TableCell>
                    <TableCell sx={styles.headingCell} align="left">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs &&
                    jobs.map((row, index) => (
                      <TableRow key={row.id} sx={styles.tableRow}>
                        <TableCell component="th" scope="row">
                          <Title
                            sx={styles.navStyle}
                            title={row.job_title}
                            onClick={() => handleTitleClick(row)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <StatusIndicator status={row.active} />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ ...styles.rowSize, ...customStyles.actionCont }}>
                          <ActionButton
                            isEdit={false}
                            isViewDetails={true}
                            viewTitle="view applicants"
                            item={row}
                            isLoading={index === currentIndex && isLoading}
                            onButtonClick={() => handleStatus(row, index)}
                            onViewDetailsClick={() => handleViewApplicants(row)}
                            name={row.job_title}
                            contStyle={customStyles.actionSub}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <NoData message={getErrorMessage('E-10106')} />
            )
          ) : (
            <Box sx={styles.collapseContainer}>
              <CollapsableList
                onTitleClick={(item) => handleTitleClick(item)}
                viewTitle="view applicants"
                isEdit={false}
                isViewDetails={true}
                onViewDetailsClick={handleViewApplicants}
                contentArray={jobs}
                type={defaultValues.manageJob.name}
                onButtonClick={(id, status, index, item) => handleStatus(item, index)}
                loaderSize={defaultValues.isResponsive && 15}
              />
            </Box>
          )}

          {currentPage && totalPages && (
            <Box sx={styles.paginationContainer}>
              <PaginationCustom onChange={handlePageChange} currentPage={page} count={totalPages} />
            </Box>
          )}
        </Box>
      </Box>
    </Screen>
  );
};
