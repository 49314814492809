import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Text, UIButton, UITextInputWithTitle } from '../../../../ui-kit';
import closeIcon from '../../../../assets/icons/close.png';
import edit from '../../../../assets/icons/edit-two.png';
import download from '../../../../assets/icons/download.png';

import { projectsModalStyle } from './styles';
import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { DragDrop } from '../../drag-and-drop';
import { defaultValues, getErrorMessage } from '../../../../constants';
import { getPhotoUrl, projectsSchema } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from '../../../../services/redux/selectors';
import { LoadingScreen } from '../../../../ui-kit/loading-screen';
import { saveAs } from 'file-saver';
import { clearQuotation } from '../../../../services/redux/slices';

export const ManageProjectsModal = ({
  open,
  close,
  heading,
  type,
  quotation,
  submitTitle,
  cancelTitle,
  onSubmit,
  disabled,
  isLoading,
  successMessage,
  errorMessage,
  handleModal,
  status
}) => {
  const dispatch = useDispatch();
  const userRole = useSelector(getUserType);

  const [file, setFile] = useState(null);
  const [reject, setReject] = useState(false);

  const admin = defaultValues.userRole.admin;
  const superAdmin = defaultValues.userRole.superAdmin;

  const {
    values,
    handleChange,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    errors,
    isValid
  } = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: projectsSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  useEffect(() => {
    if (!isLoading && successMessage) {
      setReject(false);
      setFieldValue('reason', '');
      setFile('');
    }
  }, [successMessage]);

  useEffect(() => {
    if (quotation) {
      setFile({ name: convertFileName(quotation), type: 'edit' });
    }
  }, [quotation]);

  const convertFileName = (val) => {
    let arr = val.split('/');
    return arr[arr.length - 1];
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleClose = () => {
    setFile(null);
    dispatch(clearQuotation());
    setFieldValue('reason', '');
    setFieldTouched('reason', false);
    setReject(false);
    close();
  };

  const handleSubmit = () => {
    if (type === 'send') {
      onSubmit(file);
    } else if (type === 'view' && reject) {
      onSubmit(values.reason, 'reject');
    } else if (type === 'view' && !reject) {
      onSubmit();
    } else if (type === 'edit') {
      onSubmit(file);
    }
  };

  const handleCancel = () => {
    if (type === 'view' && !reject) {
      setReject(true);
    } else {
      handleClose();
    }
  };

  const handleDownload = (url) => {
    const pdfUrl = url;
    let arr = url.split('/');
    saveAs(pdfUrl, `${arr[arr.length - 1]}.pdf`);
  };

  const handlePdfAction = () => {
    if (userRole === admin || userRole === superAdmin) {
      handleModal('edit');
    } else {
      handleDownload(getPhotoUrl(quotation));
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={projectsModalStyle.container}>
          <LoadingScreen isLoading={isLoading} />
          <img style={projectsModalStyle.closeButton} onClick={handleClose} src={closeIcon} />
          <Box sx={projectsModalStyle.contentContainer}>
            <Box sx={projectsModalStyle.headingContainer}>
              <Text style={projectsModalStyle.heading}>{heading}</Text>
            </Box>
            <Divider sx={projectsModalStyle.divider} />

            {(type === 'send' || type === 'edit') && (
              <DragDrop handleChange={handleFileChange} pdfFile={file} />
            )}

            {type === 'view' && !reject && (
              <Box sx={projectsModalStyle.pdfViewer}>
                {quotation && (
                  <Box sx={projectsModalStyle.pdfViewSubcontainer}>
                    <object
                      data={`${getPhotoUrl(quotation)}`}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                      style={projectsModalStyle.objectPdf}>
                      <p style={projectsModalStyle.altText}>
                        <a href={getPhotoUrl(quotation)}>click here</a>
                        &nbsp; to view or download the PDF!
                      </p>
                    </object>

                    <img
                      src={userRole === admin || userRole === superAdmin ? edit : download}
                      style={projectsModalStyle.pdfIcon}
                      onClick={handlePdfAction}
                    />
                  </Box>
                )}

                {errorMessage === 'E-10093' && (
                  <Box sx={projectsModalStyle.errorContainer}>
                    <Text variant="secondaryHeader">{getErrorMessage(errorMessage)}</Text>
                  </Box>
                )}
              </Box>
            )}

            {reject && (
              <UITextInputWithTitle
                title="Reason"
                name="reason"
                variant={defaultValues.isResponsive ? 'medium' : 'body'}
                required
                value={values.reason}
                placeholder="Type your reason here..."
                onChange={handleChange}
                error={errors.reason}
                touched={touched.reason}
                lines={5}
                onBlur={handleBlur}
              />
            )}

            {((type === 'view' &&
              userRole === defaultValues.userRole.customer &&
              quotation &&
              status === 2) ||
              type === 'send' ||
              type === 'edit') && (
              <Box sx={projectsModalStyle.buttonContainer}>
                <UIButton
                  style={projectsModalStyle.cancelButton}
                  onClick={handleCancel}
                  title={reject ? 'Cancel' : cancelTitle}
                />
                <UIButton
                  disabled={
                    type && type === 'send'
                      ? !file
                      : type === 'view' && reject
                        ? !isValid || !values.reason
                        : type === 'edit'
                          ? file && file.type === 'edit'
                          : false
                  }
                  style={projectsModalStyle.button(disabled)}
                  onClick={handleSubmit}
                  title={reject ? 'Submit' : submitTitle}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
