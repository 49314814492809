import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
import { fonts } from '../../theme/fonts';
const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const signInStyles = {
  container: {
    p: responsive ? '10px 0px' : 2,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: responsive ? 'none' : '60vh'
  },
  logoContainer: {
    maxWidth: responsive ? '3rem' : md ? '90px' : '120px',
    maxHeight: responsive ? '3rem' : md ? '90px' : '120px'
  },
  logo: {
    width: '100%',
    height: '100%'
  },
  headingPart1: {
    color: color.palette.primaryWhite,
    fontSize: responsive ? '1rem' : md ? '50px' : '64px',
    marginTop: '10px',
    textAlign: 'center'
  },
  headingPart2: {
    color: color.palette.primaryRed,
    fontFamily: fonts.header,
    fontSize: responsive ? '1rem' : md ? '50px' : '64px',
    fontWeight: 550
  },

  loginButton: {
    backgroundColor: color.palette.primaryWhite,
    textTransform: 'none',
    fontFamily: fonts.primary,
    fontSize: responsive ? '14px' : md ? '24px' : '32px',
    padding: 0,
    marginTop: responsive ? '20px' : '30px',
    minWidth: responsive ? '90px' : md ? '150px' : '200px',
    minHeight: responsive ? '30px' : md ? '45px' : '60px',
    color: color.palette.primaryBlack
  },
  modal: {
    border: 'none'
  },
  closeIcon: {
    width: responsive ? '40px' : '78px',
    height: responsive ? '40px' : '78px',
    position: 'absolute',
    right: '4px',
    top: '4px'
  },
  closeContainer: {
    cursor: 'pointer'
  },
  emailContainer: {
    width: responsive ? '100%' : '80%'
  },
  emailField: {
    width: '100%',
    marginTop: responsive ? '15px' : '30px',
    backgroundColor: color.palette.primaryWhite,
    borderRadius: '4px'
  },
  vectorIconOne: {
    width: responsive ? '50px' : '100px',
    height: responsive ? '70px' : '140px',
    position: 'absolute',
    top: '0%',
    left: '0%'
  },
  vectorIconTwo: {
    width: responsive ? '50px' : '120px',
    height: responsive ? '50px' : '120px',
    position: 'absolute',
    bottom: '5%',
    right: '0%'
  },

  otpNotification: {
    color: color.palette.primaryWhite,
    fontSize: responsive && '11px'
  },
  errorField: {
    color: color.palette.primaryWhite,
    backgroundColor: color.palette.primaryRed,
    borderRadius: '4px',
    padding: '2px 8px',
    marginTop: '10px',
    width: 'fit-content',
    fontSize: responsive ? '11px' : '15px'
  },
  otpContainer: {
    maxWidth: responsive && '425px'
  }
};
