import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

export const styles = {
  container: {
    width: '100%',
    height: '100%',
    p: defaultValues.isResponsive ? 1 : 3,
    boxSizing: 'border-box',
    backgroundColor: color.secondaryBackground
  },
  subContainer: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: color.primaryBackground,
    borderRadius: 1,
    p: defaultValues.isResponsive ? 1 : 4,
    boxSizing: 'border-box'
  },
  titleContainer: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    justifyContent: 'center',
    mb: !defaultValues.isResponsive && '25px',
    p: 1
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  navStyle: {
    cursor: 'pointer',

    color: color.palette.linkBlue,
    textDecoration: 'underline',
    fontWeight: 600
  },
  iconStyle: {
    fontSize: defaultValues.isResponsive ? '14px' : '20px',
    marginX: defaultValues.isResponsive ? '5px' : '10px'
  },
  fontStyle: { fontWeight: 600, wordBreak: 'break-word' },
  listCard: {
    minHeight: '150px',
    m: defaultValues.isResponsive ? 1 : 2,
    p: defaultValues.isResponsive ? 1 : 3,
    boxSizing: 'border-box',
    borderRadius: 1,
    border: `1px solid ${color.primaryBorder}`
  },
  listDetailsContainer: { display: 'flex', justifyContent: 'space-between' },
  listTitleContainer: {
    display: 'flex'
  },
  taskDetailsContainer: { width: defaultValues.isResponsive ? '100%' : '75%' },
  detailsContainer: {
    maxWidth: defaultValues.isResponsive ? '60%' : '75%'
  },
  titleBox: { width: '75%' },
  dueDate: {
    width: defaultValues.isResponsive ? '100px' : '170px',
    height: defaultValues.isResponsive ? '22px' : '40px',
    backgroundColor: color.palette.dueDate,
    display: 'flex',
    justifyContent: 'center',
    my: 1,
    alignItems: 'center'
  },
  status: (bg) => ({
    width: defaultValues.isResponsive ? '100px' : '170px',
    height: defaultValues.isResponsive ? '22px' : '40px',
    backgroundColor: bg,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  toDoBtn: {
    height: defaultValues.isResponsive ? '23px' : '40px',
    width: defaultValues.isResponsive ? '90px' : '125px',
    fontSize: defaultValues.isResponsive ? '9px' : '16px'
  },
  navTitleStyle: {
    cursor: 'pointer',
    borderBottom: '1px solid',
    fontWeight: 600,
    display: 'inline',
    wordWrap: 'break-word'
  },
  textStyle: { color: color.palette.secondaryOrange },
  assingContainer: { display: 'flex', alignItems: 'center' },
  cardDetails: { display: 'flex', flexWrap: 'wrap', my: 1 },
  editIcon: {
    maxWidth: '100%',
    maxHeight: defaultValues.isResponsive ? '20px' : '35px',
    marginLeft: '15px',
    marginRight: '15px',
    cursor: 'pointer'
  },
  customPopUp: '98%',
  countContainer: { display: 'flex', my: 1, alignItems: 'center' },
  circle: {
    width: '20px',
    height: '20px',
    backgroundColor: color.palette.skyBlueLight,
    borderRadius: '50%',
    overflow: 'hidden'
  },
  innerCircle: (width) => ({
    width: `${width}%`,
    height: '100%',
    backgroundColor: color.palette.skyBlue
  }),

  countDetail: { ml: 1, color: color.palette.emailGray },

  scrollContainer: {
    width: '100%',
    maxHeight: '300px',
    boxSizing: 'border-box',
    overflowY: 'auto'
  }
};
