import { Box } from '@mui/material';
import { EbookStyles } from './ebookCardStyle';
import { defaultValues } from '../../../constants';
import { Text, UIButton } from '../../../ui-kit';
import PrimaryBook from '../../../assets/images/bookPrimary.png';
import SecondaryBook from '../../../assets/images/bookSecondary.png';
import iconFive from '../../../assets/icons/icon_part_five.png';
import { Link } from 'react-router-dom';
export const EbookCard = () => {
  return (
    <Box sx={EbookStyles.container}>
      <Text variant={defaultValues.isResponsive ? 'small' : 'header'} style={EbookStyles.heading}>
        {defaultValues.ebook.heading}
      </Text>
      <Text
        variant={defaultValues.isResponsive ? 'responsiveText' : 'body'}
        style={EbookStyles.heading}>
        {defaultValues.ebook.desc}
      </Text>

      <Link to={'https://www.121captions.com/about/'}>
        <UIButton title="Download" style={EbookStyles.button} />
      </Link>

      <img style={EbookStyles.secondaryImage} alt="icon" src={SecondaryBook} />
      <img style={EbookStyles.primaryImage} alt="icon" src={PrimaryBook} />

      {!defaultValues.isResponsive && (
        <img style={EbookStyles.iconFive} alt="icon" src={iconFive} />
      )}
    </Box>
  );
};
