import { Box, Card, CardContent, Divider, Stack } from '@mui/material';
import { styles } from './style';
import { Text, UIButton } from '../../ui-kit';
import { defaultValues } from '../../constants';
import star from '../../assets/icons/Star.png';
import { getInviteStatus, truncateText } from '../../utils';

export const SupplierDetailsCard = ({
  title,
  rating,
  timezone,
  language,
  email,
  skill,
  onClick,
  onRightBtnClick,
  titleContainerStyle,
  cardStyle,
  titleStyle,
  applicantRating,
  status = null,
  leftBtnStyle,
  rightBtnStyle,
  leftBtnTitle = 'Invite supplier',
  rightBtnTitle = 'View more',
  ref,
  btnDisable = false,
  currency,
  onTitleClick = () => {}
}) => {
  return (
    <Card ref={ref} sx={styles.cardStyle(cardStyle)}>
      <CardContent>
        <Stack sx={styles.titleContainer(titleContainerStyle)}>
          <Box sx={styles.titleSub}>
            <Box sx={styles.titleSuperSub}>
              <Text
                style={styles.title(titleStyle)}
                onClick={() => onTitleClick()}
                variant={
                  defaultValues.isMdSmall
                    ? 'responsiveDescBold'
                    : defaultValues.isResponsive
                      ? 'cardTitle'
                      : 'body'
                }>
                {title}
              </Text>
              &nbsp;
              <Box sx={styles.ratingContainer}>
                {applicantRating && (
                  <Box sx={styles.rating}>
                    &#40;
                    <img src={star} style={styles.imgStyle} />
                    <Text sx={styles.applicantRating}>{applicantRating.toFixed(1)}</Text>
                    &#41;
                  </Box>
                )}
              </Box>
            </Box>

            {status && status !== 1 && (
              <Text style={styles.status(getInviteStatus(status))}>
                {getInviteStatus(status).title}
              </Text>
            )}
          </Box>

          <Stack sx={styles.stackStyle}>
            {rating && <img src={star} style={styles.imgStyle} />}
            {rating && (
              <Text
                variant={
                  defaultValues.isResponsive ? 'medium' : defaultValues.isMedium ? 'small' : 'body'
                }>
                {rating.toFixed(1)}
              </Text>
            )}
          </Stack>
          {email && <Text style={styles.emailText}>{email}</Text>}
        </Stack>
        <Divider />
        <Stack sx={styles.stackStyle}>
          <Text variant={defaultValues.isResponsive ? 'cardResTitle' : 'cardTitle'}>Skills -</Text>
          <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'cardSubTitle'}>
            {skill}
          </Text>
        </Stack>
        <Stack sx={styles.stackStyle}>
          <Text variant={defaultValues.isResponsive ? 'cardResTitle' : 'cardTitle'}>
            Languages -
          </Text>
          <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'cardSubTitle'}>
            {language}
          </Text>
        </Stack>

        <Stack sx={styles.stackStyle}>
          <Text variant={defaultValues.isResponsive ? 'cardResTitle' : 'cardTitle'}>
            Timezone -
          </Text>
          <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'cardSubTitle'}>
            {truncateText(
              timezone,
              defaultValues.isMdSmall ? 15 : defaultValues.isResponsive ? 18 : 22
            )}
          </Text>
        </Stack>
        {currency && (
          <Stack sx={styles.stackStyle}>
            <Text variant={defaultValues.isResponsive ? 'cardResTitle' : 'cardTitle'}>
              Currency -
            </Text>
            <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'cardSubTitle'}>
              {currency}
            </Text>
          </Stack>
        )}

        {(!status || status === 1) && (
          <Stack sx={styles.btnContainer}>
            <UIButton
              title={leftBtnTitle}
              style={styles.btnStyle(leftBtnStyle)}
              onClick={onClick}
              disabled={btnDisable}
            />
            <UIButton
              title={rightBtnTitle}
              style={styles.btnnS(rightBtnStyle)}
              onClick={onRightBtnClick}
            />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
