import { put, takeLatest } from 'redux-saga/effects';
import { getCustomerCompanyRequest } from '../slices';
import {
  getCompanyNoteApi,
  getCustomerApi,
  getCustomerCompanyApi,
  pushCompanyNoteApi,
  pushCustomerApi,
  pushCustomerCompaniesApi
} from '../../api';
import { SUCCESS_KEY } from '../../../constants';
import {
  getCompanyNoteFailure,
  getCompanyNoteRequest,
  getCompanyNoteSuccess,
  getCustomerCompanyFailure,
  getCustomerCompanySuccess,
  getCustomersFailure,
  getCustomersRequest,
  getCustomersSuccess,
  pushCompanyNoteFailure,
  pushCompanyNoteRequest,
  pushCompanyNoteSuccess,
  pushCustomerCompanyFailure,
  pushCustomerCompanyRequest,
  pushCustomerCompanySuccess,
  pushCustomerFailure,
  pushCustomerRequest,
  pushCustomerSuccess
} from '../slices/customerCompanySlice';

function* getCustomerCompanies(action) {
  try {
    const response = yield getCustomerCompanyApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getCustomerCompanySuccess(response.data));
    } else {
      yield put(getCustomerCompanyFailure(response.data));
    }
  } catch (error) {
    yield put(getCustomerCompanyFailure('E-10001'));
  }
}

function* pushCustomerCompanies(action) {
  try {
    const response = yield pushCustomerCompaniesApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(pushCustomerCompanySuccess(response.data));
    } else {
      yield put(pushCustomerCompanyFailure(response.data));
    }
  } catch (error) {
    yield put(pushCustomerCompanyFailure('E-10001'));
  }
}

function* getCustomers(action) {
  try {
    const response = yield getCustomerApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getCustomersSuccess(response.data));
    } else {
      yield put(getCustomersFailure(response.data));
    }
  } catch (error) {
    yield put(getCustomersFailure('E-10001'));
  }
}

function* pushCustomers(action) {
  try {
    const response = yield pushCustomerApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(pushCustomerSuccess(response.data));
    } else {
      yield put(pushCustomerFailure(response.data));
    }
  } catch (error) {
    yield put(pushCustomerFailure('E-10001'));
  }
}

function* getCompanyNote(action) {
  try {
    let response = yield getCompanyNoteApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(getCompanyNoteSuccess(response.data));
    } else {
      yield put(getCompanyNoteFailure(response.data));
    }
  } catch (error) {
    yield put(getCompanyNoteFailure('E-10001'));
  }
}

function* pushCompanyNote(action) {
  try {
    const response = yield pushCompanyNoteApi(action.payload);
    if (response.data.status === SUCCESS_KEY) {
      yield put(pushCompanyNoteSuccess(response.data));
    } else {
      yield put(pushCompanyNoteFailure(response.data));
    }
  } catch (error) {
    yield put(pushCompanyNoteFailure('E-10001'));
  }
}

export function* customerCompanySaga() {
  yield takeLatest(getCustomerCompanyRequest.type, getCustomerCompanies);
  yield takeLatest(pushCustomerCompanyRequest.type, pushCustomerCompanies);
  yield takeLatest(getCustomersRequest.type, getCustomers);
  yield takeLatest(pushCustomerRequest.type, pushCustomers);
  yield takeLatest(getCompanyNoteRequest.type, getCompanyNote);
  yield takeLatest(pushCompanyNoteRequest.type, pushCompanyNote);
}
