import { Box } from '@mui/material';
import { EventDetailsStyles } from './eventDetailsStyles';
import {
  DateAndTimePickerWithTitle,
  Text,
  UIAutocompleted,
  UIButton,
  UICheckBoxTitle,
  UIChipButton,
  UITextInputWithTitle
} from '../../../ui-kit';
import { defaultValues } from '../../../constants';
import { useFormik } from 'formik';
import { bookServiceSchema, finalList, sortAlpha, sortTimeZone } from '../../../utils';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  getCommonQuestionRequest,
  getLanguageRequest,
  getSkillsRequest,
  getTimeZoneRequest
} from '../../../services/redux/slices';
import {
  getEventDetails,
  getLanguageNextPage,
  getLanguages,
  getSelectedLanguages,
  getSkills,
  getSkillsNextPage,
  getTimeZone,
  getTimezoneNextPage,
  settingLoadingStatus
} from '../../../services/redux/selectors';
import { UITextWithBtn } from '../../../ui-kit/textfield-with-button';

export const EventDetailsSection = () => {
  const dispatch = useDispatch();
  const languages = useSelector(getSelectedLanguages);
  const eventDetails = useSelector(getEventDetails);
  const timeZone = useSelector(getTimeZone);
  const languageNextPage = useSelector(getLanguageNextPage);
  const timeZoneNextPage = useSelector(getTimezoneNextPage);

  const skillsNextPage = useSelector(getSkillsNextPage);
  const isLoading = useSelector(settingLoadingStatus);
  const allLanguages = useSelector(getLanguages);
  const allSkills = useSelector(getSkills);

  let timer;

  const [timezones, setTimezones] = useState([]);
  const [offeredLanguages, setOfferedLanguages] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [schedule, setSchedule] = useState('');

  const { values, handleBlur, handleChange, errors, touched, setFieldValue, setValues } = useFormik(
    {
      initialValues: {
        eventName: '',
        eventDetails: '',
        eventDate: '',
        eventDuration: '',
        languages: [],
        spokenLanguages: [],
        timezone_id: '',
        translation_required: '',
        schedule_details: []
      },
      validationSchema: bookServiceSchema,
      validateOnChange: true,
      validateOnBlur: true
    }
  );

  const handleNext = () => {
    dispatch(getCommonQuestionRequest(values));
  };

  const handleDelete = (start, end, title) => {
    let updatedList = [...values[title]];
    updatedList.splice(start, end);
    setFieldValue(`${title}`, updatedList);
  };

  useEffect(() => {
    dispatch(getTimeZoneRequest());
    dispatch(getSkillsRequest());
    dispatch(getLanguageRequest());
  }, []);

  useEffect(() => {
    if (languages) {
      setOfferedLanguages(sortAlpha(languages, defaultValues.listConstants.language));
    }

    if (timeZone) {
      setTimezones((prev) => {
        const existingTimezone = prev.map((item) => item.id);
        const uniqueTimezone = timeZone.filter((item) => !existingTimezone.includes(item.id));

        return sortTimeZone([...prev, ...uniqueTimezone], defaultValues.listConstants.timezone);
      });
    }

    if (allLanguages) {
      setLanguageList((prev) => {
        const existingLan = prev.map((item) => item.id);
        const uniqueLan = allLanguages.filter((item) => !existingLan.includes(item.id));
        return sortAlpha([...prev, ...uniqueLan], defaultValues.listConstants.language_title);
      });
    }
  }, [languages, timeZone, allSkills, allLanguages]);

  useEffect(() => {
    if (eventDetails) {
      setValues({
        eventName: eventDetails.eventName || '',
        eventDetails: eventDetails.eventDetails || '',
        eventDate: eventDetails.eventDate || '',
        eventDuration: eventDetails.eventDuration || '',
        languages: eventDetails.languages || []
      });
    }
  }, [eventDetails]);

  const selectedTimeZone = (val) => {
    const timeZoneInfo = timezones.find((zone) => zone.timezone === val);
    if (timeZoneInfo) {
      setFieldValue('timezone_id', timeZoneInfo.id);
    }
  };

  const handleScrollEnd = (title) => {
    let payload = {};
    if (title === defaultValues.listConstants.skills && skillsNextPage !== -1) {
      payload = { page: skillsNextPage };
      dispatch(getSkillsRequest(payload));
    }

    if (title === defaultValues.listConstants.timezone && timeZoneNextPage !== -1) {
      payload = { page: timeZoneNextPage };
      dispatch(getTimeZoneRequest(payload));
    }
    if (title === defaultValues.listConstants.languages && languageNextPage !== -1) {
      payload = { page: languageNextPage };
      dispatch(getLanguageRequest(payload));
    }
  };

  const searchList = (val, title) => {
    let payload = {};
    if (title === defaultValues.listConstants.skills) {
      payload = { search_text: val };
      dispatch(getSkillsRequest(payload));
    }

    if (title === defaultValues.listConstants.timezone) {
      payload = { search_text: val };
      dispatch(getTimeZoneRequest(payload));
    }

    if (title === defaultValues.listConstants.languages) {
      payload = { search_text: val };
      dispatch(getLanguageRequest(payload));
    }
  };

  const selectedItem = (val, title, valueTitle, list, valueList) => {
    const Info = list.find((item) => item[title] === val);
    if (Info) {
      let isDuplicateVal = valueList.findIndex((el) => el === Info.id);
      if (isDuplicateVal < 0) {
        setFieldValue(`${valueTitle}`, [...values[valueTitle], Info.id]);
      }
    }
  };

  const extractTitle = (options, title, val) => {
    const index = options.findIndex((item) => item.id === val);
    return index >= 0 ? options[index][title] : '';
  };

  const handleBtnClick = () => {
    setShowSchedule(true);
  };

  const handleAddSchedule = (value) => {
    setFieldValue('schedule_details', [...values.schedule_details, value]);
    setSchedule('');
  };
  const handleDurationChange = (e) => {
    const duration = parseInt(e.target.value, 10); // Convert to integer using parseInt
    setFieldValue('eventDuration', duration);
  };

  return (
    <Box sx={EventDetailsStyles.container}>
      <Text
        style={EventDetailsStyles.title(defaultValues.isResponsive)}
        variant={defaultValues.isResponsive ? 'body' : 'secondaryHeader'}>
        Fill the below details
      </Text>

      <UITextInputWithTitle
        name="eventName"
        title={'Event title'}
        placeholder="enter your event title"
        variant={defaultValues.isResponsive ? 'medium' : 'body'}
        style={EventDetailsStyles.fieldStyle(defaultValues.isResponsive)}
        onBlur={handleBlur}
        onChange={handleChange}
        error={errors.eventName}
        value={values.eventName}
        touched={touched.eventName}
      />

      <UITextInputWithTitle
        title={'Event details'}
        lines={3}
        placeholder="briefly describe your event..."
        variant={defaultValues.isResponsive ? 'medium' : 'body'}
        style={EventDetailsStyles.fieldStyle(defaultValues.isResponsive)}
        name="eventDetails"
        value={values.eventDetails}
        onBlur={handleBlur}
        onChange={handleChange}
        error={errors.eventDetails}
        touched={touched.eventDetails}
      />

      <DateAndTimePickerWithTitle
        title={'Event date and time'}
        style={EventDetailsStyles.datePicker(defaultValues.isResponsive)}
        onBlur={handleBlur}
        onChange={(date) => setFieldValue('eventDate', date)}
        errors={errors.eventDate}
      />

      <UITextWithBtn
        title={'Event duration (Hours)'}
        placeholder="enter event duration(in hrs)"
        variant={defaultValues.isResponsive ? 'medium' : 'body'}
        style={EventDetailsStyles.fieldStyle(defaultValues.isResponsive)}
        name="eventDuration"
        value={values.eventDuration}
        onBlur={handleBlur}
        type="text"
        pattern="[0-9]*"
        onChange={handleDurationChange}
        error={errors.eventDuration}
        touched={touched.eventDuration}
        allowOnlyNumbers={true}
        addShowButton={true}
        onAdd={handleBtnClick}
      />

      {showSchedule && (
        <UITextWithBtn
          name={`EventSchedule`}
          title={`Break schedule`}
          placeholder="eg:-10:00 - 12:00 PM Briefing Session"
          variant={defaultValues.isResponsive ? 'medium' : 'body'}
          style={EventDetailsStyles.fieldStyle(defaultValues.isResponsive)}
          value={schedule}
          onBlur={handleBlur}
          onChange={(e) => setSchedule(e.target.value)}
          addShowButton={true}
          onAdd={() => handleAddSchedule(schedule)}
          btnTitle="Add"
        />
      )}
      {values.schedule_details && values.schedule_details.length > 0 && (
        <Box>
          {values.schedule_details.map((val, index) => (
            <UIChipButton
              title={val.length > 0 && val}
              key={index}
              onDelete={() => handleDelete(index, 1, 'schedule_details')}
              backgroundColor={EventDetailsStyles.chip}
            />
          ))}
        </Box>
      )}

      <UIAutocompleted
        title={'Spoken language'}
        variant={defaultValues.isResponsive ? 'medium' : 'body'}
        options={finalList(values.spokenLanguages, languageList).map((item) => item.language_title)}
        placeholder="enter language(s)"
        onAdd={(val) =>
          selectedItem(
            val,
            defaultValues.listConstants.language_title,
            'spokenLanguages',
            languageList,
            values.spokenLanguages
          )
        }
        onTyping={(val) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            if (val.length > 2 || val.length === 0) {
              searchList(val, defaultValues.listConstants.languages);
            }
          }, 500);
        }}
        onScrollEnd={() => handleScrollEnd(defaultValues.listConstants.languages)}
        name="spokenLanguages"
        id="spokenLanguages"
        error={errors.spokenLanguages}
        onBlur={handleBlur}
        touched={touched.spokenLanguages}
        style={EventDetailsStyles.selectTitle(defaultValues.isResponsive)}
      />
      {values.spokenLanguages && values.spokenLanguages.length > 0 && (
        <Box>
          {values.spokenLanguages.map((val, index) => (
            <UIChipButton
              title={extractTitle(languageList, defaultValues.listConstants.language_title, val)}
              key={index}
              onDelete={() => handleDelete(index, 1, 'spokenLanguages')}
              backgroundColor={EventDetailsStyles.chip}
            />
          ))}
        </Box>
      )}

      <UIAutocompleted
        title={'Captioning language'}
        variant={defaultValues.isResponsive ? 'medium' : 'body'}
        options={finalList(values.languages, offeredLanguages).map((item) => item.language)}
        placeholder="enter language(s)"
        onAdd={(val) =>
          selectedItem(
            val,
            'language',
            defaultValues.listConstants.languages,
            offeredLanguages,
            values.languages
          )
        }
        name="languages"
        id="languages"
        error={errors.languages}
        onBlur={handleBlur}
        touched={touched.languages}
        style={EventDetailsStyles.selectTitle(defaultValues.isResponsive)}
      />
      {values.languages && values.languages.length > 0 && (
        <Box>
          {values.languages.map((val, index) => (
            <UIChipButton
              title={extractTitle(offeredLanguages, 'language', val)}
              key={index}
              onDelete={() => handleDelete(index, 1, defaultValues.listConstants.languages)}
              backgroundColor={EventDetailsStyles.chip}
            />
          ))}
        </Box>
      )}

      <UICheckBoxTitle
        title={'Have you booked translation services?'}
        values={defaultValues.translationRequired}
        variant={defaultValues.isResponsive ? 'medium' : 'body'}
        onCheck={(data) => {
          setFieldValue('translation_required', data);
        }}
      />

      <UIAutocompleted
        title={'Timezone'}
        placeholder={'select your timezone'}
        variant={defaultValues.isResponsive ? 'medium' : 'body'}
        options={timezones && timezones.map((item) => item.timezone)}
        addShowButton={false}
        selectedValue={selectedTimeZone}
        isLoading={isLoading}
        style={EventDetailsStyles.selectTitle(defaultValues.isResponsive)}
        onTyping={(val) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            if (val.length > 2 || val.length === 0) {
              searchList(val, defaultValues.listConstants.timezone);
            }
          }, 500);
        }}
        onScrollEnd={() => handleScrollEnd(defaultValues.listConstants.timezone)}
      />

      <Box>
        <UIButton
          disabled={
            !values.eventName ||
            !values.eventDetails ||
            !values.eventDate ||
            !values.eventDuration ||
            !values.languages.length ||
            (values.spokenLanguages && !values.spokenLanguages.length > 0) ||
            !values.timezone_id ||
            !values.translation_required ||
            errors.eventDate
          }
          title={'Next'}
          sx={EventDetailsStyles.buttonStyle(defaultValues.isResponsive)}
          onClick={handleNext}
        />
      </Box>
    </Box>
  );
};
