import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

const responsive = defaultValues.isResponsive;
const sm = defaultValues.isXSmall;

export const Styles = {
  container: (disabled) => ({
    display: responsive ? 'flex' : 'block',
    justifyContent: responsive ? 'space-between' : 'none',
    padding: sm ? '0px 4px' : responsive ? '0px 8px' : '8px',
    boxSizing: responsive && 'border-box',
    border: responsive && `1px solid ${color.primaryBorder}`,
    borderRadius: responsive && '4px',
    alignItems: 'center',
    cursor: disabled ? 'not-allowed' : 'pointer',
    minHeight: sm ? '40px' : responsive ? '45px' : '50px',
    marginLeft: '5px',
    '& .MuiButtonBase-root': {
      marginRight: 0
    }
  }),
  toolsIcons: {
    padding: 0,
    marginRight: responsive ? '3px' : '0px',
    fontSize: sm && '12px'
  },
  iconfontSize: {
    fontSize: sm ? '12px' : '14px',
    padding: sm && '4px'
  }
};
