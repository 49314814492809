import { defaultValues } from '../../../constants';
import { color, fonts } from '../../../theme';
const responsive = defaultValues.isResponsive;

export const styles = {
  container: {
    width: '100%',
    padding: '2%',
    boxSizing: 'border-box'
  },
  navContainer: {
    borderBottom: `1px solid ${color.primaryBorder}`
  },
  tab: {
    color: color.palette.primaryBlack,
    fontFamily: fonts.primary,
    textTransform: 'none',
    fontSize: responsive ? '18px' : '24px'
  },
  tabIndicator: {
    backgroundColor: color.palette.primaryRed,
    height: '3px'
  },
  pagesContainer: {
    width: '100%',
    marginTop: responsive ? '10px' : '20px'
  },
  panel: {
    padding: 0
  }
};
