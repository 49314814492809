import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { modalStyle } from './style';
import { Text, TextInput, UIButton } from '../../ui-kit';
import closeIcon from '../../assets/icons/close.png';
import { Checkbox, Chip, FormControlLabel, FormGroup } from '@mui/material';
import { defaultValues } from '../../constants';
import { useEffect, useState } from 'react';
import SquareIcon from '@mui/icons-material/Square';
import { CheckBox } from '@mui/icons-material';
import Backdrop from '@mui/material/Backdrop';

export const OtherSettingsModal = ({
  open,
  close,
  heading,
  type,
  inputTitle1,
  inputTitle2,
  inputName1,
  inputName2,
  inputValue1,
  inputValue2,
  placeholder1,
  placeholder2,
  error,
  buttonTitle,
  onChange,
  onBlur,
  setFieldTouched,
  setFieldError,
  touched,
  onButtonClick,
  disabled,
  addState,
  stateList,
  onStateDelete,
  checked,
  onStateChecked,
  isLoading,
  isEditing,
  disabledInput1
}) => {
  const [stateBoxHeight, setStateBoxHeight] = useState(0);
  const stateBox = document.getElementById('stateBox');
  useEffect(() => {
    stateBox && setStateBoxHeight(stateBox.offsetHeight);
  }, [stateBox && stateBox.offsetHeight]);
  return (
    <div>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Modal open={open} onClose={close}>
          <Box sx={modalStyle.container}>
            <img style={modalStyle.closeButton} onClick={close} src={closeIcon} />
            <Box sx={modalStyle.contentContainer}>
              <Box sx={modalStyle.headingContainer}>
                <Text style={modalStyle.heading}>{heading}</Text>
              </Box>
              <br />
              <Box sx={modalStyle.inputContainer}>
                <Text variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>
                  {inputTitle1}
                </Text>
                <TextInput
                  disabled={disabledInput1}
                  name={inputName1}
                  size={defaultValues.isResponsive ? 'small' : 'medium'}
                  style={modalStyle.input}
                  value={inputValue1}
                  placeholder={placeholder1}
                  error={touched[inputName1] && error[inputName1]}
                  errorStyle={modalStyle.errorField}
                  onBlur={onBlur}
                  setFieldTouched={setFieldTouched}
                  setFieldError={setFieldError}
                  onChange={onChange}
                />
              </Box>
              <Box sx={modalStyle.inputContainer}>
                <Text variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>
                  {inputTitle2}
                </Text>
                {(type === 'currency' || type === 'state') && (
                  <TextInput
                    name={inputName2}
                    style={modalStyle.input}
                    size={defaultValues.isResponsive ? 'small' : 'medium'}
                    value={inputValue2}
                    placeholder={placeholder2}
                    error={touched[inputName2] && error[inputName2]}
                    errorStyle={modalStyle.errorField}
                    onBlur={onBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldError={setFieldError}
                    onChange={onChange}
                  />
                )}

                {type === 'country' && isEditing !== true && (
                  <>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            sx={{ marginRight: '8px' }}
                            onChange={() => {
                              onStateChecked(!checked);
                              checked && onStateDelete(0, stateList.length);
                            }}
                            icon={<SquareIcon style={modalStyle.icon} />}
                            checkedIcon={<CheckBox style={modalStyle.checkedIcon} />}
                          />
                        }
                        label={
                          <Text variant={defaultValues.isResponsive ? 'small' : 'menuText'}>
                            Add state(s)
                          </Text>
                        }
                      />
                    </FormGroup>
                    {checked && (
                      <Box sx={{ position: 'relative' }}>
                        <TextInput
                          name={inputName2}
                          style={modalStyle.input}
                          size={defaultValues.isResponsive ? 'small' : 'medium'}
                          value={inputValue2}
                          placeholder={placeholder2}
                          error={touched[inputName2] && error[inputName2]}
                          errorStyle={modalStyle.errorField}
                          onBlur={onBlur}
                          setFieldTouched={setFieldTouched}
                          setFieldError={setFieldError}
                          onChange={onChange}
                        />
                        <UIButton
                          style={modalStyle.stateAddButton(inputValue2)}
                          title={'Add'}
                          onClick={addState}
                          disabled={!inputValue2}
                        />
                      </Box>
                    )}
                    {checked && (
                      <Box id="stateBox" sx={modalStyle.stateContainer(stateBoxHeight)}>
                        {stateList.length > 0 &&
                          stateList.map((item, index) => {
                            return (
                              <Chip
                                key={index}
                                label={item}
                                style={modalStyle.chipButton}
                                deleteIconStyle={modalStyle.deleteIcon}
                                onDelete={() => onStateDelete(index, 1)}
                              />
                            );
                          })}
                      </Box>
                    )}
                  </>
                )}

                {type === 'language' || type === 'skills' ? (
                  <>
                    <TextInput
                      name={inputName2}
                      style={modalStyle.input}
                      size={defaultValues.isResponsive ? 'small' : 'medium'}
                      value={inputValue2}
                      placeholder={placeholder2}
                      error={touched[inputName2] && error[inputName2]}
                      errorStyle={modalStyle.errorField}
                      onBlur={onBlur}
                      setFieldTouched={setFieldTouched}
                      setFieldError={setFieldError}
                      onChange={onChange}
                      lines={3}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            <Box sx={modalStyle.buttonContainer}>
              <UIButton
                size="small"
                disabled={disabled}
                style={modalStyle.button(disabled)}
                onClick={onButtonClick}
                title={buttonTitle}
                isLoading={isLoading}
              />
            </Box>
          </Box>
        </Modal>
      </Backdrop>
    </div>
  );
};
