import { color } from '../../../theme/color';

export const Styles = {
  buttonStyle: (value, isRes) => ({
    backgroundColor: value ? color.palette.primaryRed : color.palette.primaryDisabledRed,
    display: 'block',
    marginTop: '30px',
    color: color.palette.primaryWhite,
    fontSize: isRes ? '15px' : '24px',
    width: isRes && '100%',
    height: isRes && '40px',
    padding: '5px 50px'
  }),
  emailField: (isRes) => ({
    width: isRes ? '100%' : '80%',
    backgroundColor: color.palette.textFieldRed,
    borderRadius: '4px'
  }),
  title: (isRes) => ({ fontWeight: '500', color: isRes && color.palette.primaryRed }),
  label: { marginTop: '20px', marginBottom: '5px' },
  fieledContainer: (isRes) => ({
    height: isRes && '75vh'
  }),
  buttonContainer: (isRes) => isRes && { height: '95%', display: 'flex', alignItems: 'flex-end' },
  textContainer: { width: '90%' }
};
