/* eslint-disable no-unused-vars */
/*
   Other settings page for admin and super admin
*/

import React, { useEffect, useState } from 'react';
import { Screen } from '../../../ui-kit';
import { Box } from '@mui/system';
import { otherSettingStyles } from './styles';
import {
  Countries,
  Currency,
  Languages,
  ManageServices,
  MasterList,
  Skills,
  TimeZone
} from '../../../components';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { useSelector } from 'react-redux';

export const OtherSettingsPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Screen showSideBar={true} currentPage="Other Settings">
      <Box sx={otherSettingStyles.container}>
        <TabContext value={value}>
          <Box sx={otherSettingStyles.navContainer}>
            <TabList
              textColor="none"
              TabIndicatorProps={{ sx: otherSettingStyles.tabIndicator }}
              onChange={handleChange}
              variant="scrollable"
              aria-label="lab API tabs example">
              {defaultValues.otherSettingsTab.map((item, index) => (
                <Tab key={index} sx={otherSettingStyles.tab} label={item.heading} value={index} />
              ))}
            </TabList>
          </Box>
          <Box sx={otherSettingStyles.pagesContainer}>
            <TabPanel sx={otherSettingStyles.panel} value={0}>
              <ManageServices />
            </TabPanel>
            <TabPanel sx={otherSettingStyles.panel} value={1}>
              <Languages />
            </TabPanel>
            {/* <TabPanel sx={otherSettingStyles.panel} value={2}>
              <Skills />
            </TabPanel> */}
            <TabPanel sx={otherSettingStyles.panel} value={2}>
              <Currency />
            </TabPanel>
            <TabPanel sx={otherSettingStyles.panel} value={3}>
              <Countries />
            </TabPanel>
            <TabPanel sx={otherSettingStyles.panel} value={4}>
              <MasterList />
            </TabPanel>
            <TabPanel sx={otherSettingStyles.panel} value={5}>
              <TimeZone />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Screen>
  );
};
