import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationItem } from '@mui/material';
import { paginationStyles } from './styles';
import { defaultValues } from '../../constants';

export const PaginationCustom = ({ onChange, count, currentPage }) => {
  return (
    <Stack spacing={defaultValues.isResponsive ? 0 : 10}>
      <Pagination
        count={count}
        page={currentPage}
        size="small"
        boundaryCount={0}
        siblingCount={1}
        renderItem={(item) => (
          <PaginationItem sx={paginationStyles.pagination(item, currentPage)} {...item} />
        )}
        onChange={onChange}
      />
    </Stack>
  );
};
