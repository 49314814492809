import { createSelector } from 'reselect';

export const getJobLoading = createSelector(
  [(state) => state.jobs.isLoading],
  (loadingStatus) => loadingStatus
);

export const getJobs = createSelector([(state) => state.jobs.jobs], (jobs) => jobs);

export const getTotalPages = createSelector(
  [(state) => state.jobs.totalPage],
  (totalPage) => totalPage
);

export const getCurrentPages = createSelector(
  [(state) => state.jobs.currentPage],
  (currentPage) => currentPage
);

export const getApplicants = createSelector(
  [(state) => state.jobs.applicants],
  (applicants) => applicants
);

export const getJobSuccessMessage = createSelector(
  [(state) => state.jobs.successMessage],
  (successMessage) => successMessage
);

export const getJobErrorMessage = createSelector(
  [(state) => state.jobs.errorMessage],
  (errorMessage) => errorMessage
);

export const getJobParams = createSelector(
  [(state) => state.jobs.jobParams],
  (jobParams) => jobParams
);

export const getJobIndicator = createSelector(
  [(state) => state.jobs.indicator],
  (indicator) => indicator
);

export const getAppCurrPage = createSelector(
  [(state) => state.jobs.applicantCurrPage],
  (applicantCurrPage) => applicantCurrPage
);

export const getCurrJob = createSelector([(state) => state.jobs.currJob], (currJob) => currJob);
