import { defaultValues } from '../../../constants';
import { customStyles } from '../../../theme';
import { color } from '../../../theme/color';

const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;
export const CDStyles = {
  container: {
    width: '100%',
    minHeight: '80vh',
    padding: responsive ? '10px' : '10px',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    paddingTop: '20px'
  },
  contentContainer: {
    maxWidth: '90vw',
    ...customStyles.flexBox,
    justifyContent: 'start',
    justifyItems: 'start',
    alignItems: 'start',
    marginRight: responsive ? '0px' : '15px'
  },
  logoBox: {
    margin: responsive ? '0 5px' : '0 20px',
    width: responsive ? '100px' : '250px',
    height: responsive ? '100px' : '250px',
    borderRadius: '100%',
    alignSelf: responsive ? 'start' : 'center',
    marginRight: responsive ? '10px' : '25px',
    backgroundColor: 'red'
  },
  logoSize: {
    width: responsive ? '125px' : '250px',
    height: responsive ? '125px' : '250px',
    borderRadius: '50%'
  },
  infoBox: {
    margin: responsive ? '20px 20px' : '10px 50px',
    flex: 1,
    wordWrap: 'break-word',
    maxWidth: '60vw'
  },
  comName: {
    fontSize: responsive ? 22 : md ? 26 : 32,
    fontWeight: 'bold',
    wordWrap: 'break-word'
  },
  regNo: {
    color: color.palette.ligherBlack,
    fontSize: responsive ? '0.8rem' : '1.2rem',
    mb: '0.3rem'
  },
  comType: { fontSize: responsive ? '0.8rem' : '1.2rem', mb: '0.2rem' },
  stackStyle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3px',
    alignItems: 'center',
    mt: '2px'
  },
  imgStyle: { objectFit: 'contain' },

  ml: { marginRight: '10px' },
  tabIndicator: {
    height: '0px'
  },
  tab: (isActive) => ({
    borderRadius: '5px 5px 0 0',
    color: color.palette.primaryBlack,
    textTransform: 'none',
    fontSize: responsive ? '14px' : '18px',
    background: isActive ? color.palette.primaryWhite : 'none',
    padding: responsive ? '10px 15px' : '18px 30px'
  }),
  memberMain: {
    margin: responsive ? '25px 5px' : '20px',
    borderRadius: '5px 5px 0 0',
    border: `1px solid ${color.palette.borderColor}}`
  },
  tabContainer: {
    borderRadius: '5px 5px 0 0',
    backgroundColor: color.palette.secondaryBackground
  },
  modelPop: {
    width: '60vw',
    height: '300px',
    alignSelf: 'center',
    ...customStyles.flexBox,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'start'
  }
};
