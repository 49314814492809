import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { MTStyles } from './styles';
import { defaultValues, getErrorMessage } from '../../../constants';
import { useState } from 'react';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { customStyles } from '../../../theme';
import { Text } from '../../../ui-kit';
import { getFormatDate } from '../../../utils';

export const CompanyNotes = ({ listData }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {!defaultValues.isResponsive ? (
        <>
          {listData && listData.length > 0 ? (
            <Box sx={MTStyles.mainBox}>
              {listData && listData.length <= 0 ? (
                <Box>
                  <Text>{getErrorMessage('E-10098')}</Text>
                </Box>
              ) : (
                <Table
                  sx={{
                    ...MTStyles.tableContainer,
                    borderCollapse: 'collapse'
                  }}
                  aria-label="simple table">
                  <TableHead sx={MTStyles.tableHead}>
                    <TableRow>
                      <TableCell sx={MTStyles.headingCell} align="left">
                        Notes
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listData &&
                      listData.length > 0 &&
                      listData.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell sx={MTStyles.maxW} align="left">
                            <Box>
                              <Text variant="body">{item.added_by}</Text>
                              <Text variant="mediumLight">{getFormatDate(item.added_at)}</Text>
                              <br />
                              <Text variant="small">{item.note}</Text>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          ) : (
            <Text variant="lightBody">{getErrorMessage('E-10098')}</Text>
          )}
        </>
      ) : (
        <>
          {listData && listData.length > 0 ? (
            listData.map((item, index) => (
              <Accordion
                key={index}
                sx={MTStyles.resContainer}
                expanded={expanded === index}
                onChange={handleChange(index)}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon sx={MTStyles.expandIcon} />}
                  aria-controls={index}
                  sx={MTStyles.summeryBox}
                  id={index}>
                  <Box>
                    <Text variant="medium">{item.added_by}</Text>
                    <Text variant="responsiveDesc">{item.added_at}</Text>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={customStyles.w100}>
                    <Box sx={MTStyles.itemContainer}>
                      <Text variant="small">{item.note}</Text>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Text variant="mediumLight">{getErrorMessage('E-10098')}</Text>
          )}
        </>
      )}
    </>
  );
};
