import { Box } from '@mui/material';
import { Screen, Text } from '../../../ui-kit';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjecsErrorMessage,
  getProjectDetailsSel,
  getProjectsLoadingStatus,
  getProjectsSuccessMessage,
  getTaskCmntNxtPage,
  getTaskComments,
  getTaskDetails,
  getTaskLists,
  getUserType,
  projectMembers,
  projectTasks
} from '../../../services/redux/selectors';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import { style } from './style';
import { useEffect, useRef, useState } from 'react';
import { getFormatDate, resetMessge } from '../../../utils';
import { color } from '../../../theme';
import {
  addTaskCommentsRequest,
  getProjectMembers,
  getTaskCommentsRequest,
  getTaskDetailsRequest,
  getTaskRequest,
  resetProjectsMessages,
  taskListRequest,
  updateTaskStatusRequest
} from '../../../services/redux/slices';
import { Comments, CreateToDo, TaskStatus, ViewComments } from '../../../components';
import { toast } from 'react-toastify';

export const TaskDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const containerRef = useRef(null);

  const userType = useSelector(getUserType);
  const members = useSelector(projectMembers);
  const isLoading = useSelector(getProjectsLoadingStatus);
  const allTasks = useSelector(projectTasks);
  const projectDetails = useSelector(getProjectDetailsSel);
  const error = useSelector(getProjecsErrorMessage);
  const success = useSelector(getProjectsSuccessMessage);
  const taskDetails = useSelector(getTaskDetails);
  const taskLists = useSelector(getTaskLists);
  const allComments = useSelector(getTaskComments);
  const nextPage = useSelector(getTaskCmntNxtPage);

  const [taskId, setTaskId] = useState('');
  const [task, setTask] = useState({});
  const [tasks, setTasks] = useState([]);
  const [listName, setListName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectMember, setProjectMember] = useState([]);
  const [showCrateBtn, setShowCrateBtn] = useState(false);
  const [isDueDatePassed, setIsDueDatePassed] = useState(false);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (location && location.state && location.state.list_title) {
      setListName(location.state.list_title && location.state.list_title);
    }
    if (location && location.state && location.state.task) {
      setTaskId(location.state.task && location.state.task);
    }

    if (location && location.state && location.state.taskDetails) {
      setTaskId(location.state.taskDetails.task_id);
    }

    if (
      location &&
      location.state &&
      location.state.type === defaultValues.listConstants.view_task_details
    ) {
      dispatch(getTaskDetailsRequest({ task_id: location.state.taskDetails.task_id }));
    }
  }, [location.state]);

  useEffect(() => {
    if (taskId) {
      dispatch(getTaskCommentsRequest({ task_id: taskId }));
    }
  }, [taskId]);

  useEffect(() => {
    if (taskLists && taskLists.length > 0 && taskDetails) {
      const element = taskLists.find((item) => item.id === taskDetails.list_id);
      setListName(element && element.list_title);
    }

    if (allComments.length > 0) {
      setComments((prev) => {
        const newComments = allComments.filter((item) => {
          return !prev.some((existing) => existing.comment_id === item.comment_id);
        });
        return [...newComments, ...prev];
      });
    } else {
      setComments([]);
    }
  }, [taskLists, allComments]);

  useEffect(() => {
    if (projectDetails.project_name) {
      setProjectName(projectDetails.project_name);
    }
    if (members) {
      setProjectMember(members);
    }
    if (allTasks) {
      setTasks(allTasks);
    }
    if (tasks && taskId) {
      const currentTask = tasks.filter((task) => task.id === taskId);
      setTask(currentTask);
    }
    if (
      userType === defaultValues.userRole.superAdmin ||
      userType === defaultValues.userRole.projectManger ||
      userType === defaultValues.userRole.admin
    ) {
      setShowCrateBtn(true);
    }
  }, [projectDetails, members, allTasks, tasks, taskId, userType]);

  useEffect(() => {
    if (task && task[0] && task[0].due_date) {
      const dueDate = new Date(task[0].due_date);
      dueDate.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      setIsDueDatePassed(dueDate < today);
    }
  }, [task]);

  useEffect(() => {
    if (taskDetails) {
      setTask([taskDetails]);
      dispatch(getTaskRequest({ list_id: taskDetails.list_id }));
      dispatch(taskListRequest({ project_id: projectDetails.id }));
      dispatch(getProjectMembers({ project_id: projectDetails.id }));
    }
  }, [taskDetails]);

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(success));
    }

    if (error !== 'E-10127') {
      toast.error(getErrorMessage(error));
    }
    setComment('');

    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [success, error]);

  const handleTaskStatusChange = (event, id) => {
    dispatch(updateTaskStatusRequest({ task_id: id, status: event.target.value }));
  };

  const handlePost = () => {
    const payload = {
      task_id: taskId,
      comment: comment
    };
    dispatch(addTaskCommentsRequest(payload));
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (parseInt(container.scrollTop + container.clientHeight) >= container.scrollHeight - 5) {
      if (nextPage !== -1) {
        dispatch(getTaskCommentsRequest({ task_id: taskId, page: nextPage }));
      }
    }
  };

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive}
      currentPage={'Project details'}>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={style.container}>
        <Box sx={style.subContainer}>
          <Box sx={style.titleContainer}>
            <Box sx={style.heading}>
              <Text
                onClick={() => {
                  navigation(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                    state: { data: { id: projectDetails.id } }
                  });
                }}
                variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
                style={style.navStyle}>
                {projectName}
              </Text>
              <ArrowForwardIos sx={style.forwardIcon} />
              <Text
                onClick={() => {
                  navigation(SCREEN_PATH.PROJECT_TASK_MAN);
                }}
                variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
                style={style.navStyle}>
                Task management
              </Text>
              <ArrowForwardIos sx={style.forwardIcon} />
              <Text
                onClick={() => {
                  if (taskDetails) {
                    navigation(SCREEN_PATH.PROJECT_TASKS, {
                      state: { data: taskDetails.list_id, showCrateBtn }
                    });
                  } else {
                    navigation(-1);
                  }
                }}
                variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
                style={style.navStyle}>
                {listName}
              </Text>
            </Box>
            {!defaultValues.isResponsive && <ArrowForwardIos sx={style.forwardIcon} />}
            <Text variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}>
              {task && task[0] && task[0].title}
            </Text>
          </Box>
          <Box sx={style.taskListContainer}>
            <Box sx={style.listDetailsContainer}>
              <Box sx={style.details}>
                <Box sx={style.listTitleContainer}>
                  <Text
                    style={style.navTitleStyle}
                    variant={defaultValues.isResponsive ? 'responsiveText' : 'fileName'}>
                    {`${task && task[0] && task[0].title} (Est.time: ${
                      task && task[0] && task[0].estimated_time
                    } hrs)`}
                  </Text>
                </Box>
                <Text
                  variant={defaultValues.isResponsive ? 'responsiveDesc' : 'small'}
                  style={{ color: color.palette.emailGray }}>
                  {task && task[0] && task[0].description}
                </Text>

                {task && task[0] && task[0].due_date && (
                  <Box sx={style.dueDate}>
                    <Text
                      variant={defaultValues.isResponsive ? 'extraSmall' : 'responsiveText'}
                      style={style.dueDateColor}>
                      {`Due on ${getFormatDate(task && task[0] && task[0].due_date)}`}
                    </Text>
                  </Box>
                )}
                {defaultValues.isResponsive && task && task[0] && task[0].status !== undefined && (
                  <TaskStatus
                    status={task && task[0] && task[0].status}
                    onChange={(e) => handleTaskStatusChange(e, task && task[0] && task[0].id)}
                  />
                )}
              </Box>
              {!defaultValues.isResponsive && task && task[0] && task[0].status !== undefined && (
                <TaskStatus
                  status={task && task[0] && task[0].status}
                  onChange={(e) => handleTaskStatusChange(e, task && task[0] && task[0].id)}
                />
              )}
            </Box>
            <CreateToDo
              projectMembers={projectMember}
              data={task && task[0] && task[0]}
              showBtn={showCrateBtn}
              isEditable={isDueDatePassed}
            />
          </Box>
          <Comments
            onChange={(e) => setComment(e.target.value)}
            onClick={handlePost}
            showBtn={comment.length > 0}
            value={comment}
          />
          {comments.length > 0 && (
            <div ref={containerRef} onScroll={handleScroll} style={style.scrollContainer}>
              {comments.map((item, index) => (
                <ViewComments comment={item} key={index} />
              ))}
            </div>
          )}
        </Box>
      </Box>
    </Screen>
  );
};
