import { defaultValues } from '../../../../constants';
import { customStyles } from '../../../../theme';

const responsive = defaultValues.isResponsive;
export const CDStyles = {
  contentContainer: {
    width: '100%',
    minHeight: '80vh',
    padding: responsive ? '0%' : '2%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '5px'
  },
  editBtn: {
    maxWidth: '100%',
    maxHeight: responsive ? '20px' : '40px',
    marginRight: '15%',
    cursor: 'pointer'
  },
  detailBox: { marginLeft: '65px', paddingTop: '40px' },
  notFound: {
    ...customStyles.flexBox,
    height: '60vh'
  }
};
