import { styles } from './style';
import { Box, Stack } from '@mui/material';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { PopupModal, Screen, Text, UIButton } from '../../../ui-kit';
import { JobPostForm, MultiCardsContainer } from '../../../components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSupplierMessages,
  supplierRecomendationRequest
} from '../../../services/redux/slices';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getExixstingSuppliers,
  getSuggestedSuppliers,
  getSupplierErrorMessage,
  getSupplierSuccessMessage,
  supplierLoadingStatus
} from '../../../services/redux/selectors/supplierSelector';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { ArrowForwardIos } from '@mui/icons-material';
import { getProjectsLoadingStatus } from '../../../services/redux/selectors';
import { resetMessge } from '../../../utils';

export const SupplierHub = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const isLoading = useSelector(supplierLoadingStatus);
  const suggestedSuppliers = useSelector(getSuggestedSuppliers);
  const existingSuppliers = useSelector(getExixstingSuppliers);
  const isJobPosting = useSelector(getProjectsLoadingStatus);
  const successMessage = useSelector(getSupplierSuccessMessage);
  const errorMessage = useSelector(getSupplierErrorMessage);

  const [suggestions, setSuggestions] = useState([]);
  const [existingSupplier, setExistingSupplier] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);

  useEffect(() => {
    if (location.state && location.state.data) {
      setProjectDetails(location.state.data);
    }
    if (location.state && location.state.data) {
      const project_id = location.state.data.id;
      dispatch(supplierRecomendationRequest(project_id));
    }
    if (location.state.data && location.state.data.project_name) {
      setProjectName(location.state.data.project_name);
    }
  }, []);

  useEffect(() => {
    if (suggestedSuppliers) {
      setSuggestions(suggestedSuppliers);
    }
    if (existingSuppliers) {
      setExistingSupplier(existingSuppliers);
    }
  }, [suggestedSuppliers, existingSuppliers]);

  useEffect(() => {
    if (!isJobPosting) {
      setShowModal(false);
    }
  }, [isJobPosting]);

  useEffect(() => {
    resetMessge(() => dispatch(resetSupplierMessages()));
  }, [successMessage, errorMessage]);

  const onInviteClick = (val) => {
    navigation(SCREEN_PATH.INVITE_SUPPLIER, {
      state: { project_details: location.state && location.state.data, supplier_details: val }
    });
  };

  return (
    <Screen showSideBar={true} currentPage={'Manage Projects'}>
      <Box sx={styles.container}>
        <Box sx={styles.subContainer}>
          <LoadingScreen isLoading={isLoading} />
          <Box sx={styles.topNav}>
            <Text
              onClick={() => {
                navigation(-1);
              }}
              variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
              style={styles.navLink}>
              {projectName}
            </Text>
            {!defaultValues.isResponsive && <ArrowForwardIos sx={styles.icon} />}
            <Text variant={defaultValues.isResponsive ? 'small' : 'titleBold'}>Supplier hub </Text>
          </Box>

          {suggestions.length > 0 || existingSupplier.length > 0 ? (
            <>
              {suggestions.length > 0 && (
                <Box sx={styles.cardContainer}>
                  <MultiCardsContainer
                    data={suggestions}
                    title={defaultValues.supplierHub.suggestionTitle}
                    projectDetails={location.state && location.state.data}
                    onInviteClick={onInviteClick}
                  />
                </Box>
              )}
              {existingSupplier.length > 0 && (
                <Box sx={styles.cardContainer}>
                  <MultiCardsContainer
                    data={existingSupplier}
                    title={defaultValues.supplierHub.existingTitle}
                    projectDetails={location.state && location.state.data}
                    onInviteClick={onInviteClick}
                  />
                </Box>
              )}
            </>
          ) : (
            <Box sx={styles.noFoundBoxSuper}>
              <Box sx={styles.noFoundBox}>
                <Text style={styles.noFoundText}>No suppliers available</Text>
              </Box>
            </Box>
          )}
          <Box sx={styles.details}>
            <Box sx={styles.sub}>
              <Text
                style={styles.text}
                variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>
                {defaultValues.supplierHub.otherOption}
              </Text>
              <Text
                variant={defaultValues.isResponsive ? 'responsiveDesc' : 'small'}
                style={styles.desc}>
                {defaultValues.supplierHub.optionNote}
              </Text>
              <Stack sx={styles.buttonContainer}>
                <UIButton
                  title={'Post a job'}
                  style={styles.primaryBtn}
                  onClick={() => setShowModal(true)}
                />
                <UIButton
                  title={'Select from list'}
                  style={styles.secBtn}
                  onClick={() =>
                    navigation(SCREEN_PATH.SUPPLIER_LIST, { state: { data: projectDetails } })
                  }
                />
              </Stack>
            </Box>
          </Box>
        </Box>
        <PopupModal
          customContentWidth={styles.contentWidth}
          showModel={showModal}
          onClose={() => setShowModal(false)}
          showDivider={true}
          dividerWidth={true}
          heading={'Post a new job'}
          titlePosition={true}>
          <JobPostForm data={projectDetails} isLoading={isJobPosting} />
        </PopupModal>
      </Box>
    </Screen>
  );
};
