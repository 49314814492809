import React from 'react';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { defaultValues } from '../../../constants';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Text } from '../../../ui-kit';

export const SupplierEngagementChart = ({ data, option, handleChange, userRole }) => {
  const isRes = defaultValues.isResponsive;

  const styles = {
    container: { display: 'flex', flexDirection: 'column' },
    selectContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      mb: 1
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.selectContainer}>
        <Text variant={isRes ? 'small' : 'subTitle'}>Project Overview</Text>
        <FormControl sx={{ maxWidth: 100 }} size="small">
          <Select value={option} onChange={handleChange} sx={{ fontSize: 12 }}>
            {defaultValues.selectOptions.map((option, index) => (
              <MenuItem key={index} sx={{ fontSize: 12 }} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <div>
        <ResponsiveContainer
          width={'100%'}
          height={userRole === defaultValues.userRole.supplier ? 250 : 350}>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: isRes ? 10 : 20,
              left: isRes ? -20 : 10,
              bottom: 20
            }}>
            <XAxis
              dataKey="key"
              fontSize={defaultValues.isMdSmall ? 4 : isRes ? 4 : 10}
              interval={0}
            />
            <YAxis
              type="number"
              domain={[0, 'dataMax']}
              ticks={
                userRole === defaultValues.userRole.supplier
                  ? [0, 5, 10, 15, 20]
                  : [0, 5, 10, 15, 20, 25]
              }
              fontSize={defaultValues.isMdSmall ? 4 : isRes ? 6 : 12}
            />

            <Tooltip />
            <Bar
              dataKey="count"
              shape={(props) => <rect {...props} fill={data[props.index].color} />}
              barSize={isRes ? 20 : 60}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
};
