import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
import { fonts } from '../../theme/fonts';
const responsive = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const Styles = {
  actionContainer: (type, style) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: type === defaultValues.manageJob.name ? responsive && 'column' : 'row',
    justifyContent: responsive ? 'space-start' : 'center',
    alignItem: 'center',
    maxWidth: '300px',
    minWidth: responsive ? 'none' : '200px',
    gap: '5px',
    boxSizing: 'border-box',
    ...style
  }),
  actionButton: (status) => ({
    boxSizing: 'border-box',
    borderRadius: '4px',
    textAlign: 'center',
    color: color.palette.primaryWhite,
    backgroundColor: status ? color.palette.primaryRed : color.palette.buttonGreen,
    fontSize: responsive ? '10px' : md ? '14px' : '16px',
    cursor: 'pointer',
    width: responsive ? '80px' : md ? '80px' : '120px',
    padding: responsive ? '4px' : md ? 7 : 10,
    fontFamily: fonts.primary,
    '&:hover': {
      backgroundColor: status ? color.palette.primaryRed : color.palette.buttonGreen
    }
  }),
  editIcon: {
    maxWidth: '100%',
    maxHeight: responsive ? '20px' : '35px',
    marginX: '15%',
    cursor: 'pointer',
    marginLeft: '10px'
  },
  link: {
    color: color.palette.skyBlue,
    cursor: 'pointer',
    minWidth: '120px',
    marginY: 'auto',
    padding: responsive ? 0 : '0px 10px',
    boxSizing: 'border-box',
    fontSize: responsive ? '12px' : md ? '16px' : '18px'
  },
  projectButton: (status, role) => ({
    margin: 0,
    visibility:
      status.key === defaultValues.projectsStatus.archived ||
      status.key === defaultValues.projectsStatus.inProgress ||
      (role !== defaultValues.userRole.admin &&
        role !== defaultValues.userRole.superAdmin &&
        role !== defaultValues.userRole.projectAdmin &&
        (status.key === defaultValues.projectsStatus.archived ||
          status.key === defaultValues.projectsStatus.inProgress ||
          status.key === defaultValues.projectsStatus.initialContact))
        ? 'hidden'
        : 'visible',
    fontSize: responsive ? '10px' : window.innerWidth < 1441 && '14px',
    padding: responsive && 0,
    width: responsive && '100px',
    height: responsive && '25px'
  }),
  projectBtnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: responsive ? 'start' : 'space-between',
    gap: responsive && '10px'
  },

  viewDetailsIcon: {
    maxWidth: responsive ? '20px' : '40px',
    maxHeight: responsive ? '20px' : '40px',
    cursor: 'pointer'
  },
  subContainer: (type, style) => ({
    width: '100%',
    display: 'flex',
    justifyContent:
      type === 'projects' || type === 'countries' || type === 'PM' ? 'start' : 'center',
    ...style
  })
};
