import { createSelector } from '@reduxjs/toolkit';

export const chatLoadingStatus = createSelector(
  [(state) => state.chat.isLoading],
  (isLoading) => isLoading
);

export const getChatSuccessMessage = createSelector(
  [(state) => state.chat.successMessage],
  (successMessage) => successMessage
);

export const getChatErrorMessage = createSelector(
  [(state) => state.chat.errorMessage],
  (errorMessage) => errorMessage
);

export const getChatCurrentPage = createSelector(
  [(state) => state.chat.Thread_current_page],
  (Thread_current_page) => Thread_current_page
);

export const getChatNextpage = createSelector(
  [(state) => state.chat.Thread_next_page],
  (Thread_next_page) => Thread_next_page
);

export const getChatTotalPage = createSelector(
  [(state) => state.chat.Thread_total_page],
  (Thread_total_page) => Thread_total_page
);

export const getChatThreads = createSelector([(state) => state.chat.threads], (threads) => threads);

export const getChatMessages = createSelector(
  [(state) => state.chat.messages],
  (messages) => messages
);

export const getMsgCurrPage = createSelector(
  [(state) => state.chat.msg_current_page],
  (msg_current_page) => msg_current_page
);
export const getMsgNextPage = createSelector(
  [(state) => state.chat.msg_next_page],
  (msg_next_page) => msg_next_page
);

export const getMsgTotalPage = createSelector(
  [(state) => state.chat.msg_total_page],
  (msg_total_page) => msg_total_page
);

export const getThreadId = createSelector(
  [(state) => state.chat.currThreadId],
  (currThreadId) => currThreadId
);

export const getPersonelChatCurrentPage = createSelector(
  [(state) => state.chat.personel_Thread_current_page],
  (personel_Thread_current_page) => personel_Thread_current_page
);

export const getPersonelChatNextpage = createSelector(
  [(state) => state.chat.personel_Thread_next_page],
  (personel_Thread_next_page) => personel_Thread_next_page
);

export const getPersonelChatTotalPage = createSelector(
  [(state) => state.chat.personel_Thread_total_page],
  (personel_Thread_total_page) => personel_Thread_total_page
);

export const getPersonelChatThreads = createSelector(
  [(state) => state.chat.personel_threads],
  (personel_threads) => personel_threads
);

export const getPersonelChatMessages = createSelector(
  [(state) => state.chat.personel_messages],
  (personel_messages) => personel_messages
);

export const getPersonelMsgCurrPage = createSelector(
  [(state) => state.chat.personel_msg_current_page],
  (personel_msg_current_page) => personel_msg_current_page
);
export const getPersonelMsgNextPage = createSelector(
  [(state) => state.chat.personel_msg_next_page],
  (personel_msg_next_page) => personel_msg_next_page
);

export const getPersonelMsgTotalPage = createSelector(
  [(state) => state.chat.personel_msg_total_page],
  (personel_msg_total_page) => personel_msg_total_page
);
