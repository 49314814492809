import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

export const serviceCardStyles = {
  container: {
    width: '100%',
    minHeight: defaultValues.isResponsive ? 'none' : '360px',
    border: `1px solid ${color.primaryBorder}`,
    padding: '2%',
    boxSizing: 'border-box',
    borderRadius: '4px'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: '15px'
  },
  iconContainer: {
    maxWidth: '100px',
    maxHeight: '100px',
    width: '20%',
    height: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    width: '100%',
    height: '100%'
  },
  textContainer: {
    ml: 2
  },
  buttonContainer: {
    mt: 2
  }
};
