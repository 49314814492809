import { Text } from '../ui-kit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTimezones } from '../services/redux/selectors';
import { useEffect } from 'react';
import { setTimezoneOptionsValue } from '../services/redux/slices';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import { defaultValues } from '../constants';
import { convertTimeToTimeZone } from './commonFunction';
const labelStyle = 'abbrev';

dayjs.extend(utc);
dayjs.extend(timezone);

export const ConvertedTime = ({
  time,
  originalTimeZoneName,
  targetTimeZoneName,
  style,
  sendFormattedTime
}) => {
  const styles = {
    text: {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      ...style
    }
  };

  const dispatch = useDispatch();
  const timezones = useSelector(getAllTimezones);

  const { options: timezoneOptions } = useTimezoneSelect({ labelStyle, timezones: allTimezones });
  useEffect(() => {
    dispatch(setTimezoneOptionsValue(timezoneOptions));
  }, [timezoneOptions, dispatch]);

  const value =
    time && originalTimeZoneName && targetTimeZoneName
      ? convertTimeToTimeZone(time, originalTimeZoneName, targetTimeZoneName, timezones)
      : '';
  sendFormattedTime && sendFormattedTime(value.formattedTime);
  return (
    <Text variant={defaultValues.isResponsive ? 'responsiveDesc' : 'small'} style={styles.text}>
      {value.inFormat}
    </Text>
  );
};
