import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Text } from '../text';
import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
import { useState } from 'react';
const isRes = defaultValues.isResponsive;

export const UISelectWithTitles = ({
  data,
  title,
  onBlur,
  touched,
  errors,
  name,
  label,
  style,
  selected,
  onScroll,
  ref
}) => {
  const defaultStyles = {
    title: {
      marginTop: '20px',
      marginBottom: '10px'
    },
    formContainer: {
      minWidth: 120,
      width: isRes ? '100%' : '80%'
    },
    selectArea: {
      backgroundColor: color.palette.textFieldRed
    }
  };
  const styles = {
    ...defaultStyles,
    ...style
  };

  const [value, setValue] = useState('');
  return (
    <Box>
      <Text style={styles.title} variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>
        {title}
      </Text>

      <FormControl sx={styles.formContainer}>
        {(!value || value.length === 0) && <InputLabel>{label}</InputLabel>}
        <Select
          ref={ref}
          name={name}
          // onScroll={onScroll}
          sx={styles.selectArea}
          onBlur={onBlur}
          label={label}
          MenuProps={{ onScroll: onScroll }}
          onChange={(e) => {
            setValue(e.target.value);
            selected(e.target.value);
          }}>
          {data.length > 0 &&
            data.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.title || item.item || item.timezone || item.country_name || item.name}
              </MenuItem>
            ))}
        </Select>
        {touched && errors && <Text variant="fieldError">{errors}</Text>}
      </FormControl>
    </Box>
  );
};
