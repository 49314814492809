import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';
import { fonts } from '../../../theme/fonts';
import { palette } from '../../../theme/palette';

const responsive = defaultValues.isResponsive;
export const headerStyles = {
  container: {
    background: color.primaryBackground,
    width: '100%'
  },
  subContainer: (isAuthenticated) => ({
    padding: isAuthenticated ? '0% 1%' : '0%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    boxShadow: isAuthenticated && 3
  }),

  line: {
    height: '0.8px',
    backgroundColor: color.palette.navbarline,
    width: '100%'
  },
  headerText: {
    color: color.primaryText,
    fontFamily: fonts.header
  },
  toolbar: (isAuthenticated, dashboard, type, currentPage) => ({
    display: 'flex',
    justifyContent:
      (isAuthenticated && dashboard) ||
      (isAuthenticated && (type === defaultValues.customer || currentPage))
        ? 'space-between'
        : isAuthenticated
          ? 'flex-end'
          : 'space-between',
    alignItems: 'center',
    py: isAuthenticated ? 2 : 0,
    marginTop: !isAuthenticated && '20px',
    margin: isAuthenticated && type === defaultValues.customer ? '0% 2% 0% 0%' : '0% 2%',
    marginBottom: '10px'
  }),
  navToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    minHeight: responsive ? '40px' : '56px'
  },
  buttonStyle: {
    minWidth: '10%',
    maxWidth: '10%',
    backgroundColor: color.transparent,
    color: color.palette.primaryWhite,
    textTransform: 'none'
  },

  buttonTextStyle: { color: color.palette.primaryWhite, textTransform: 'none' },
  menuTextStyle: {
    color: color.palette.primaryBlack,
    fontSize: defaultValues.isResponsive ? '12px' : '28px',
    marginLeft: '10%',
    '&:nth-child(1)': {
      marginLeft: 0
    },
    cursor: 'pointer'
  },
  toggleMenu: {
    flexGrow: 1,
    display: { xs: 'flex', md: 'none' }
  },
  toggleMenuStyle: {
    display: { xs: 'block', md: 'none' }
  },
  toggleMenuHide: { display: { xs: 'none', md: 'block' } },
  logoContainer: {
    height: '100%',
    width: '50%',
    maxWidth: '250px'
  },
  logoStyles: {
    width: '100%',
    maxWidth: responsive ? '120px' : '240px',
    maxHeight: responsive ? '40px' : '80px',
    display: 'flex',
    justifyContent: 'center'
  },
  menuStyle: {
    width: '100%',
    display: 'flex',
    flexDdirection: 'row',
    alignItems: 'center',
    paddingLeft: '5%',
    paddingRight: '5%'
  },
  signInbuttonContainer: (isAuthenticated) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: isAuthenticated ? 'space-around' : 'center',
    width: 'fit-content'
  }),
  downIcon: { color: color.palette.primaryBlack },

  iconContainer: { display: 'flex', justifyContent: 'flex-end' },
  iconsStyles: {
    width: '30px',
    height: '30px',
    objectFit: 'contain',
    margin: '10px',
    cursor: 'pointer'
  },
  userLogo: { width: '50px', height: '50px', borderRadius: '25px' },
  signInBtn: {
    background: color.palette.primaryWhite,
    borderRadius: '5px',
    color: color.palette.primaryBlack,
    textTransform: 'none',
    fontSize: defaultValues.isResponsive ? '14px' : '32px',
    fontWeight: 600,
    padding: defaultValues.isResponsive ? '3px 10px' : '0px 25px',
    boxSizing: 'border-box',
    boxShadow: 'none',
    minWidth: '80px'
  },
  booknowBtn: {
    background: color.palette.primaryRed,
    borderRadius: '5px',
    color: palette.primaryWhite,
    textTransform: 'none',
    fontSize: defaultValues.isResponsive ? '14px' : '32px',
    marginLeft: '10px',
    padding: defaultValues.isResponsive ? '3px' : '0px 25px',
    minWidth: '80px'
  },
  customerBtn: {
    marginRight: '10px',
    backgroundColor: color.primaryText,
    fontSize: defaultValues.isResponsive ? '10px' : '18px'
  },
  marginBtw: (isRes) => ({
    mx: !isRes && 1,
    cursor: 'pointer'
  }),
  dropDown: { m: 1, minWidth: 120 },
  services: {
    '& fieldset': { border: 'none' },
    '& .MuiSelect-icon': {
      color: color.palette.primaryBlack,
      fontSize: responsive ? '20px' : '40px',
      padding: 0
    },
    '& .MuiOutlinedInput-input': responsive && { padding: 0 }
  },
  servicesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10%'
  },
  sMenu: {
    fontSize: responsive ? '12px' : '20px',
    padding: '10px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: color.palette.lightGray }
  },
  link: { textDecoration: 'none', color: 'inherit' },
  badge: {
    mr: 1,
    '& .MuiBadge-badge': {
      right: 10,
      top: 5,
      padding: '0 4px'
    }
  }
};
