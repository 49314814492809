import { Box } from '@mui/material';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { PopupModal, Screen, Text, UIButton } from '../../../ui-kit';
import { styles } from './style';
import { ArrowForwardIos } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import {
  getCmntNxtPage,
  getComments,
  getProjecsErrorMessage,
  getProjectDetailsSel,
  getProjectsLoadingStatus,
  getProjectsSuccessMessage,
  getTaskLists,
  projectMembers,
  projectTasks
} from '../../../services/redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { color } from '../../../theme';
import {
  Comments,
  CreateToDo,
  MemberCard,
  TaskListForm,
  TaskStatus,
  ViewComments
} from '../../../components';
import { getFormatDate, resetMessge } from '../../../utils';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import {
  addListCommentsRequest,
  getListCommentsRequest,
  getTaskRequest,
  resetProjectsMessages,
  updateTaskListStatusRequest,
  updateTaskStatusRequest
} from '../../../services/redux/slices';
import edit from '../../../assets/icons/edit.png';
import { toast } from 'react-toastify';

export const ViewTask = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const containerRef = useRef(null);

  const members = useSelector(projectMembers);
  const isLoading = useSelector(getProjectsLoadingStatus);
  const projectDetails = useSelector(getProjectDetailsSel);
  const allTasks = useSelector(projectTasks);
  const error = useSelector(getProjecsErrorMessage);
  const success = useSelector(getProjectsSuccessMessage);
  const taskLists = useSelector(getTaskLists);
  const allComments = useSelector(getComments);
  const nextPage = useSelector(getCmntNxtPage);

  const [projectName, setProjectName] = useState('');
  const [projectMember, setProjectMember] = useState([]);
  const [addTodo, setAddTodo] = useState(false);
  const [taskDetails, setTaskDetails] = useState({});
  const [taskListId, setTaskListId] = useState(null);
  const [showCrateBtn, setShowCrateBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [editTaskList, setEditTaskList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (projectDetails.project_name) {
      setProjectName(projectDetails.project_name);
    }
    if (members) {
      setProjectMember(members);
    }
    if (allTasks) {
      setTasks(allTasks);
    }
    if (taskLists) {
      setTaskList(taskLists);
    }
    if (allComments.length > 0) {
      setComments((prev) => {
        const newComments = allComments.filter((item) => {
          return !prev.some((existing) => existing.comment_id === item.comment_id);
        });
        return [...newComments, ...prev];
      });
    } else {
      setComments([]);
    }
  }, [projectDetails, members, allTasks, taskLists, allComments]);

  useEffect(() => {
    if (location && location.state.data) {
      setTaskListId(location.state.data);
    }
    if (location && location.state.showCrateBtn) {
      setShowCrateBtn(location.state.showCrateBtn);
    }
    if (taskList) {
      const currentTaskList = taskList.filter((list) => list.id === location.state.data);
      setTaskDetails(currentTaskList);
    }
  }, [location.state, taskList]);

  useEffect(() => {
    dispatch(getTaskRequest({ list_id: taskListId }));
    dispatch(getListCommentsRequest({ list_id: taskListId }));
  }, [taskListId]);

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(success));
    }

    if (error !== 'E-10127') {
      toast.error(getErrorMessage(error));
    }

    setShowModal(false);
    setAddTodo(false);
    setComment('');

    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [success, error]);

  const handleAddTodoClick = () => {
    setAddTodo(true);
  };
  const handleCancel = () => {
    setAddTodo(false);
  };

  const handleStatusChange = (event, id) => {
    dispatch(updateTaskListStatusRequest({ list_id: id, status: event.target.value }));
  };

  const handleTaskStatusChange = (event, id) => {
    dispatch(updateTaskStatusRequest({ task_id: id, status: event.target.value }));
  };

  const handlePost = () => {
    const payload = {
      list_id: taskDetails[0] && taskDetails[0].id,
      comment: comment
    };
    dispatch(addListCommentsRequest(payload));
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (parseInt(container.scrollTop + container.clientHeight) >= container.scrollHeight - 5) {
      if (nextPage !== -1) {
        dispatch(getListCommentsRequest({ list_id: taskListId, filter: nextPage }));
      }
    }
  };

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive}
      currentPage={'Project details'}>
      <Box sx={styles.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={styles.subContainer}>
          <Box sx={styles.titleContainer}>
            <Box sx={styles.heading}>
              <Text
                onClick={() => {
                  navigation(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                    state: { data: projectDetails }
                  });
                }}
                variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
                style={styles.navStyle}>
                {projectName}
              </Text>

              <ArrowForwardIos sx={styles.iconStyle} />

              <Text
                variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
                style={styles.navStyle}
                onClick={() => {
                  navigation(SCREEN_PATH.PROJECT_TASK_MAN, { state: projectDetails });
                }}>
                Task Management
              </Text>
            </Box>
            {!defaultValues.isResponsive && <ArrowForwardIos sx={styles.iconStyle} />}

            <Text
              variant={defaultValues.isResponsive ? 'small' : 'titleBold'}
              style={styles.fontStyle}>
              {taskDetails && taskDetails[0] && taskDetails[0].list_title}
            </Text>
          </Box>
          <Box sx={styles.listCard}>
            <Box sx={styles.listDetailsContainer}>
              <Box sx={styles.detailsContainer}>
                <Box sx={styles.listTitleContainer}>
                  <Box sx={styles.titleBox}>
                    <Text
                      style={styles.navTitleStyle}
                      variant={defaultValues.isResponsive ? 'responsiveText' : 'fileName'}>
                      {`${taskDetails[0] && taskDetails[0].list_title} (Est.time: ${
                        taskDetails[0] && taskDetails[0].estimated_time
                      } hrs)`}
                    </Text>
                  </Box>
                  {showCrateBtn && (
                    <img
                      style={styles.editIcon}
                      src={edit}
                      onClick={() => {
                        setShowModal(true);
                        setEditTaskList(taskDetails);
                      }}
                    />
                  )}

                  {!defaultValues.isResponsive &&
                    taskDetails[0] &&
                    taskDetails[0].status !== undefined && (
                      <TaskStatus
                        status={taskDetails[0] && taskDetails[0].status}
                        onChange={(e) => handleStatusChange(e, taskDetails[0] && taskDetails[0].id)}
                      />
                    )}
                </Box>
                <Box sx={styles.countContainer}>
                  <Box sx={styles.circle}>
                    <Box
                      sx={styles.innerCircle(
                        Math.round(
                          (tasks &&
                            tasks.reduce((count, task) => {
                              return task.status === 2 ? count + 1 : count;
                            }, 0) / tasks.length) * 100
                        )
                      )}></Box>
                  </Box>
                  <Text
                    style={styles.countDetail}
                    variant={defaultValues.isResponsive ? 'responsiveDesc' : 'small'}>
                    {`${
                      tasks &&
                      tasks.reduce((count, task) => {
                        return task.status === 2 ? count + 1 : count;
                      }, 0)
                    } / ${tasks.length} completed`}
                  </Text>
                </Box>

                {taskDetails[0] && taskDetails[0].due_date && (
                  <Box sx={styles.dueDate}>
                    <Text
                      variant={defaultValues.isResponsive ? 'extraSmall' : 'responsiveText'}
                      style={{ color: color.palette.secondaryOrange }}>
                      {`Due on ${getFormatDate(taskDetails[0] && taskDetails[0].due_date)}`}
                    </Text>
                  </Box>
                )}
                {defaultValues.isResponsive &&
                  taskDetails[0] &&
                  taskDetails[0].status !== undefined && (
                    <TaskStatus
                      status={taskDetails[0] && taskDetails[0].status}
                      onChange={(e) => handleStatusChange(e, taskDetails[0] && taskDetails[0].id)}
                    />
                  )}
              </Box>
              {showCrateBtn && (
                <UIButton title={'add to do'} style={styles.toDoBtn} onClick={handleAddTodoClick} />
              )}
            </Box>
            {addTodo && (
              <CreateToDo
                projectMembers={projectMember}
                list_id={taskDetails[0] && taskDetails[0].id}
                onCancel={handleCancel}
                showBtn={showCrateBtn}
              />
            )}
            {tasks &&
              tasks.map((task, index) => (
                <Box sx={styles.listCard} key={index}>
                  <Box sx={styles.listDetailsContainer}>
                    <Box
                      sx={styles.taskDetailsContainer}
                      onClick={() =>
                        navigation(SCREEN_PATH.PROJECT_TASKDETAILS, {
                          state: {
                            list_title: taskDetails[0] && taskDetails[0].list_title,
                            task: task.id
                          }
                        })
                      }>
                      <Text
                        style={styles.navTitleStyle}
                        variant={defaultValues.isResponsive ? 'responsiveText' : 'fileName'}>
                        {`${task.title} (Est.time: ${task.estimated_time} hrs)`}
                      </Text>
                      <Text
                        variant={defaultValues.isResponsive ? 'responsiveDesc' : 'small'}
                        style={{ color: color.palette.emailGray }}>
                        {task.description}
                      </Text>
                      {task.due_date && (
                        <Box sx={styles.dueDate}>
                          <Text
                            variant={defaultValues.isResponsive ? 'extraSmall' : 'responsiveText'}
                            style={styles.textStyle}>
                            {`Due on ${getFormatDate(task.due_date)}`}
                          </Text>
                        </Box>
                      )}
                      {defaultValues.isResponsive && (
                        <TaskStatus
                          status={task.status}
                          onChange={(e) => handleTaskStatusChange(e, task.id)}
                        />
                      )}
                      <Box sx={styles.assingContainer}>
                        <Text variant={defaultValues.isResponsive ? 'extraSmall' : 'fileName'}>
                          Assigned to:
                        </Text>
                        <Box sx={styles.cardDetails}>
                          {task.assigned_to &&
                            task.assigned_to.map((item, index) => (
                              <MemberCard
                                key={index}
                                name={item.full_name}
                                profile={item.profile_pic}
                              />
                            ))}
                        </Box>
                      </Box>
                      <Box sx={styles.assingContainer}>
                        <Text variant={defaultValues.isResponsive ? 'extraSmall' : 'fileName'}>
                          When done, notifying to :
                        </Text>
                        <Box sx={styles.cardDetails}>
                          {task.notify_completion &&
                            task.notify_completion.map((item, index) => (
                              <MemberCard
                                key={index}
                                name={item.full_name}
                                profile={item.profile_pic}
                              />
                            ))}
                        </Box>
                      </Box>
                    </Box>
                    {!defaultValues.isResponsive && (
                      <TaskStatus
                        status={task.status}
                        onChange={(e) => handleTaskStatusChange(e, task.id)}
                      />
                    )}
                  </Box>
                </Box>
              ))}
            <Comments
              onChange={(e) => setComment(e.target.value)}
              onClick={handlePost}
              showBtn={comment.length > 0}
              value={comment}
            />
            {comments.length > 0 && (
              <div ref={containerRef} onScroll={handleScroll} style={styles.scrollContainer}>
                {comments.map((item, index) => (
                  <ViewComments comment={item} key={index} />
                ))}
              </div>
            )}
          </Box>
        </Box>
        <PopupModal
          customContentWidth={styles.customPopUp}
          showModel={showModal}
          heading={editTaskList ? 'Edit task list' : 'Create task list'}
          showDivider={true}
          titlePosition={true}
          onClose={() => setShowModal(false)}>
          <TaskListForm
            data={editTaskList && editTaskList[0]}
            btnTitle={editTaskList ? 'Update list' : 'Create list'}
            isLoading={isLoading}
          />
        </PopupModal>
      </Box>
    </Screen>
  );
};
