import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { mLStyle } from './style';
import {
  ActionButton,
  PopupModal,
  SearchBar,
  Text,
  UIButton,
  UISelectWithTitle,
  UITextInputWithTitle
} from '../../../ui-kit';
import {
  ADD_KEY,
  EDIT_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../../constants';
import { CollapsableList } from '../../common/collapsable-list';
import { StatusIndicator } from '../table-title';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getMasterList,
  getSettingsErrorMessage,
  getSettingsSuccessMessage,
  settingLoadingStatus
} from '../../../services/redux/selectors';
import { useDispatch } from 'react-redux';
import {
  addMasterListRequest,
  masterListRequest,
  resetSettingsMessages
} from '../../../services/redux/slices';
import { toast } from 'react-toastify';
import { resetMessge } from '../../../utils';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { NoData } from '../../common/no-data-message';
import { customStyles } from '../../../theme';

export const MasterList = () => {
  const dispatch = useDispatch();
  const successMessage = useSelector(getSettingsSuccessMessage);
  const errorMessage = useSelector(getSettingsErrorMessage);
  const selMmList = useSelector(getMasterList);
  const isLoading = useSelector(settingLoadingStatus);

  const [mOptions, setMOptions] = useState([]);
  const [mList, setMList] = useState([]);
  const [mType, setMType] = useState('');
  const [modelType, setModelType] = useState(null);
  const [editData, setEditData] = useState(null);
  const [listItem, setListItem] = useState('');
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const getOptionName =
    mType !== '' && mOptions ? mOptions.filter((item) => item.value === mType)[0].name : '';

  useEffect(() => {
    dispatch(masterListRequest());
  }, []);

  useEffect(() => {
    if (successMessage) {
      setModelType(null);
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetSettingsMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (selMmList) {
      setMOptions(
        selMmList.map((item) => {
          return { name: item.list_name, value: item.list_id };
        })
      );
    }
  }, [selMmList]);

  useEffect(() => {
    if (mType !== '') {
      let list = selMmList.filter((item) => item.list_id === mType)[0].list_items;
      list = list.filter((liItem) => liItem.item.toLowerCase().includes(search.toLowerCase()));
      setMList(list.slice().sort((a, b) => a.item.localeCompare(b.item)));
    }
  }, [mType, selMmList, search, mList]);

  const onSaveListItem = useCallback(() => {
    let payload = {};
    if (modelType === ADD_KEY) {
      payload = {
        master_Id: mType,
        item: listItem
      };
    } else {
      payload = editData;
    }
    dispatch(addMasterListRequest({ payload, type: modelType }));
  }, [mList, editData, modelType]);

  const onActiveChange = useCallback(
    (payload) => {
      dispatch(addMasterListRequest({ payload, type: EDIT_KEY }));
    },
    [mList]
  );

  const onSearch = (keyword) => {
    setSearch(keyword);
    if (mType !== '') {
      setMList(selMmList.filter((item) => item.list_id === mType)[0].list_items);
    }
    if (keyword !== '') {
      const list = selMmList.filter((item) => item.list_id === mType)[0].list_items;
      setMList(list.filter((liItem) => liItem.item.toLowerCase().includes(keyword.toLowerCase())));
    }
  };

  const onItemChange = useCallback(
    (e) => {
      setBtnDisabled(false);
      setListItem(e.target.value);
      if (modelType === EDIT_KEY) {
        setEditData({ ...editData, item: e.target.value });
      }
      if (e.target.value !== '') {
        setError('');
      }
    },
    [listItem]
  );

  const onEdit = (val) => {
    setModelType(EDIT_KEY);
    setListItem(val.item);
    setError('');
    setEditData(val);
  };

  return (
    <Box sx={mLStyle.container}>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={mLStyle.contentContainer}>
        <Box sx={mLStyle.selectTypeBox}>
          <Text style={mLStyle.typeTitle}>Master List Type</Text>
          <UISelectWithTitle
            minWidth="250px"
            options={mOptions}
            onChange={(val) => setMType(val)}
            value={mType}
            placeholder="Select"
          />
        </Box>

        {mType !== '' && (
          <>
            <Box sx={mLStyle.searchTabContainer}>
              <Box sx={mLStyle.searchToolsContainer}>
                {!defaultValues.isResponsive ? (
                  <SearchBar label={`Search ${getOptionName}`} searchedVlaue={onSearch} />
                ) : (
                  <Text style={mLStyle.headingText} variant="medium">
                    {`Available  ${mType === 2 ? 'accents' : 'company-type'}`}
                  </Text>
                )}
                <UIButton
                  style={mLStyle.newButton}
                  title={'+ new ' + getOptionName}
                  onClick={() => {
                    setModelType(ADD_KEY);
                    setListItem('');
                    setError('');
                  }}
                />
              </Box>
            </Box>

            {!defaultValues.isResponsive ? (
              <Text sx={mLStyle.heading} variant="fileName">
                {`Current available ${mType === 2 ? 'accents' : 'company-type'}`}
              </Text>
            ) : (
              <Box sx={mLStyle.searchContainer}>
                <SearchBar label={`Search ${getOptionName}`} searchedVlaue={onSearch} />
              </Box>
            )}

            {!defaultValues.isResponsive ? (
              mList.length > 0 ? (
                <Table sx={mLStyle.tableContainer} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={mLStyle.headingCell} align="left">
                        {getOptionName}
                      </TableCell>
                      <TableCell sx={mLStyle.headingCell} align="left">
                        Status
                      </TableCell>
                      <TableCell sx={mLStyle.headingCell} align="left">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mList &&
                      mList.map((val) => (
                        <TableRow key={val.id} sx={mLStyle.tableRow}>
                          <TableCell component="th" scope="item">
                            <Text>{val.item}</Text>
                          </TableCell>
                          <TableCell align="left">
                            <StatusIndicator status={val.active} />
                          </TableCell>
                          <TableCell align="left" sx={customStyles.actionCont}>
                            <ActionButton
                              item={val}
                              onEditClick={() => onEdit(val)}
                              onButtonClick={() => onActiveChange({ ...val, active: !val.active })}
                              contStyle={customStyles.actionSub}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <NoData message={getErrorMessage('E-10065')} />
              )
            ) : (
              <Box sx={mLStyle.collapseContainer}>
                {mList && mList.length > 0 ? (
                  <CollapsableList
                    contentArray={mList}
                    type="masterList"
                    onEditClick={(val) => onEdit(val)}
                    onButtonClick={(id, active) => {
                      const val = mList.filter((item) => item.id === id)[0];
                      onActiveChange({ ...val, active: !active });
                    }}
                    loaderSize={defaultValues.isResponsive && 15}
                  />
                ) : (
                  <NoData message={getErrorMessage('E-10065')} />
                )}
              </Box>
            )}
          </>
        )}
      </Box>
      <PopupModal
        showModel={modelType !== null}
        onClose={() => {
          setBtnDisabled(true);
          setModelType(null);
        }}
        heading={
          modelType === EDIT_KEY
            ? `Edit ${getOptionName}`
            : `Add new  ${getOptionName.toLowerCase()}`
        }>
        <Box sx={mLStyle.popUpBox}>
          <UITextInputWithTitle disabled title="List type" value={getOptionName} />
          <UITextInputWithTitle
            title="List item"
            value={listItem}
            onChange={onItemChange}
            onBlur={() => setError(listItem === '' ? getErrorMessage('E-10063') : '')}
            touched={error !== ''}
            error={error}
          />
          <UIButton
            sx={mLStyle.marginSpace}
            isLoading={isLoading}
            disabled={listItem === '' || btnDisabled}
            title={modelType === EDIT_KEY ? 'Save changes' : 'Save'}
            onClick={onSaveListItem}
          />
        </Box>
      </PopupModal>
    </Box>
  );
};
