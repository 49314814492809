import { Chip } from '@mui/material';
import React from 'react';
import { color } from '../../theme/color';
import { Clear } from '@mui/icons-material';
import { defaultValues } from '../../constants';

export const UIChipButton = ({
  title,
  key,
  showDelete = true,
  onDelete,
  style,
  disabled,
  deleteIconStyle,
  backgroundColor = color.palette.gray,
  ...rest
}) => {
  const responsive = defaultValues.isResponsive;
  const styles = {
    chip: {
      margin: responsive ? '10px 10px 10px 0px' : '20px 20px 20px 0px',
      backgroundColor: backgroundColor,
      color: color.palette.primaryWhite,
      borderRadius: '5px',
      fontSize: responsive ? '12px' : '18px',
      padding: '5px',
      height: responsive ? '35px' : '45px',
      minWidth: responsive ? '70px' : '100px'
    },
    deleteIcon: { color: color.palette.primaryWhite, paddingLeft: '10px' }
  };

  if (title) {
    return (
      <Chip
        key={key}
        label={title && title}
        onDelete={showDelete && (() => onDelete())}
        onClick={() => onDelete}
        deleteIcon={showDelete && <Clear style={{ ...styles.deleteIcon, ...deleteIconStyle }} />}
        sx={{ ...styles.chip, ...style }}
        disabled={disabled}
        {...rest}
      />
    );
  }
};
