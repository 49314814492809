/*
    Manage Project Dashboard. For all users. will be configured based on roles
*/

import { useDispatch, useSelector } from 'react-redux';
import {
  getProjecsErrorMessage,
  getProjectParams,
  getProjects,
  getProjectsCurrentPage,
  getProjectsIndicator,
  getProjectsLoadingStatus,
  getProjectsStatus,
  getProjectsSuccessMessage,
  getProjectsTotalPage,
  getQuotation,
  getQuotationId,
  getUserType
} from '../../services/redux/selectors';
import { useCallback, useEffect, useState } from 'react';
import {
  acceptQuotationRequest,
  projectRequest,
  projectStatusRequest,
  rejectQuotationRequest,
  resendQuotationRequest,
  resetProjectsMessages,
  sendQuotationRequest,
  updateProjectParams,
  updateProjectStatusRequest,
  viewQuotationRequest
} from '../../services/redux/slices';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { toast } from 'react-toastify';
import { resetMessge, truncateText } from '../../utils';
import {
  ActionButton,
  Filter,
  IconsButton,
  PaginationCustom,
  Screen,
  SearchBar,
  Sort,
  Text
} from '../../ui-kit';
import { LoadingScreen } from '../../ui-kit/loading-screen';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { projectsStyles } from './styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  CollapsableList,
  CustomModal,
  ManageProjectsModal,
  NoData,
  ProjectStatus,
  ProjectTitle,
  Title
} from '../../components';
import { useNavigate } from 'react-router-dom';
import { customStyles } from '../../theme/styles';

export const ProjectsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allProjects = useSelector(getProjects);
  const pageCount = useSelector(getProjectsTotalPage);
  const currentRenderPage = useSelector(getProjectsCurrentPage);
  const successMessage = useSelector(getProjectsSuccessMessage);
  const errorMessage = useSelector(getProjecsErrorMessage);
  const isLoading = useSelector(getProjectsLoadingStatus);
  const statuses = useSelector(getProjectsStatus);
  const quotation = useSelector(getQuotation);
  const quotationId = useSelector(getQuotationId);
  const userType = useSelector(getUserType);
  const indicator = useSelector(getProjectsIndicator);
  const projectParams = useSelector(getProjectParams);

  const [projects, setProjects] = useState([]);
  const [filterValue, setFilterValue] = useState({});

  const [open, setOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [modalType, setModelType] = useState(defaultValues.listConstants.send);
  const [projectId, setProjectId] = useState('');
  const [statusId, setStatusId] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [errMsg, setErrMsg] = useState('');

  function getFeedBackURL() {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port ? `:${window.location.port}` : '';

    return `${protocol}//${hostname}${port}/project-details/feedback?proId=${projectId}`;
  }

  useEffect(() => {
    handleProjects({});
    if (
      userType === defaultValues.userRole.admin ||
      userType === defaultValues.userRole.superAdmin
    ) {
      dispatch(projectStatusRequest());
    }
  }, []);

  useEffect(() => {
    setFileUrl(quotation);
  }, [quotation]);

  useEffect(() => {
    setProjects(allProjects);
  }, [allProjects]);

  useEffect(() => {
    if (!isLoading && successMessage) {
      handleProjects({});
      handleClose();
      handleConfirmClose();
    }
  }, [isLoading]);

  useEffect(() => {
    if (indicator) {
      handleProjects({});
    }
  }, [indicator]);

  const handleProjects = useCallback(
    (data) => {
      let payload = { page: currentRenderPage || 1 };
      if (data !== undefined) {
        payload = {
          ...payload,
          ...projectParams,
          ...data
        };
      }
      setFilterValue(payload);
      dispatch(updateProjectParams(payload));
      dispatch(projectRequest(payload));
    },
    [filterValue, projects]
  );

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      setErrMsg(errorMessage);
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetProjectsMessages()));
  }, [successMessage, errorMessage]);

  const handleModelType = (val) => {
    setModelType(val);
  };

  const handleOpen = (item) => {
    setStatusId(item.status.id);
    setProjectId(item.id);
    if (item.status.key !== defaultValues.projectsStatus.initialContact) {
      dispatch(viewQuotationRequest({ project_id: item.id }));
      handleModelType(defaultValues.listConstants.view);
    }
    setOpen(true);
  };
  const handleClose = () => {
    handleModelType(defaultValues.listConstants.send);
    setErrMsg('');
    setOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmModal(false);
  };
  const handleSearch = (search_text) => {
    if (search_text.length === 0 || search_text.length >= 2) {
      handleProjects({ search_text });
    }
  };

  const handlePageChange = (e, value) => {
    const payload = {
      page: value
    };
    handleProjects(payload);
  };

  const handleRefresh = () => {
    handleProjects({ page: null, sort: null, filter: null, search_text: null });
    setFilterValue({});
    dispatch(updateProjectParams({}));
  };

  const handleProjectStatusChange = () => {
    const payload = {
      project_id: projectId,
      status_id: statusId,
      feedbackURL: getFeedBackURL()
    };
    dispatch(updateProjectStatusRequest(payload));
  };

  const openConfirmModal = (projectId, statusId) => {
    setProjectId(projectId);
    setStatusId(statusId);
    setConfirmModal(true);
  };

  const handleQuotationsubmit = (val, type = null) => {
    let payload = null;
    if (modalType === defaultValues.listConstants.send) {
      payload = {
        project_id: projectId,
        quotation_file: val
      };
      dispatch(sendQuotationRequest(payload));
    } else if (
      modalType === defaultValues.listConstants.view &&
      userType === defaultValues.userRole.customer
    ) {
      if (!type) {
        payload = {
          quotation_id: quotationId.toString()
        };
        dispatch(acceptQuotationRequest(payload));
      }
      if (type === 'reject') {
        payload = {
          quotation_id: quotationId.toString(),
          rejection_reason: val
        };
        dispatch(rejectQuotationRequest(payload));
      }
    } else if (modalType === defaultValues.listConstants.edit) {
      payload = {
        project_id: projectId,
        quotation_file: val
      };
      dispatch(resendQuotationRequest(payload));
    }
  };

  return (
    <Screen showSideBar={true} currentPage={'Manage Projects'}>
      <LoadingScreen isLoading={!confirmModal && isLoading} />
      <Box sx={projectsStyles.container}>
        <Box sx={projectsStyles.contentContainer}>
          <Box sx={projectsStyles.searchTabContainer}>
            <Box sx={projectsStyles.searchToolsContainer}>
              {!defaultValues.isResponsive ? (
                <SearchBar
                  label={'Search by project name or id'}
                  searchedVlaue={handleSearch}
                  onRefresh={!projectParams.search_text && {}}
                  defaultValue={projectParams.search_text}
                />
              ) : (
                <Text sx={projectsStyles.headingText} variant="medium">
                  Manage Projects
                </Text>
              )}
            </Box>

            <Box sx={projectsStyles.toolsContainer}>
              <IconsButton
                title={!defaultValues.isResponsive ? null : 'Refresh'}
                icon={<RefreshIcon sx={projectsStyles.icon} />}
                onClick={handleRefresh}
              />
              <Sort
                selectedOption={(sort) => handleProjects({ sort, page: 1 })}
                activeOption={filterValue.sort}
              />
              <Filter
                label={'Filter'}
                applyFilter={(data) => handleProjects({ filter: JSON.stringify(data), page: 1 })}
                options={
                  userType === defaultValues.userRole.supplier
                    ? defaultValues.proSuppFilterOptions
                    : defaultValues.proFilterOptions
                }
                activeData={filterValue && filterValue.filter}
              />
            </Box>
          </Box>

          {defaultValues.isResponsive && (
            <Box sx={projectsStyles.searchContainer}>
              <SearchBar
                label={'Search by project name or id'}
                searchedVlaue={handleSearch}
                onRefresh={!projectParams.search_text && {}}
                defaultValue={projectParams.search_text}
              />
            </Box>
          )}

          {!defaultValues.isResponsive ? (
            projects.length > 0 ? (
              <Table sx={projectsStyles.tableContainer} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={projectsStyles.headingCell} align="center">
                      Id
                    </TableCell>
                    <TableCell sx={projectsStyles.headingCell} align="left">
                      Project title
                    </TableCell>
                    <TableCell sx={projectsStyles.headingCell} align="left">
                      Service
                    </TableCell>
                    <TableCell sx={projectsStyles.headingCell} align="left">
                      Project status
                    </TableCell>
                    {userType !== defaultValues.userRole.supplier && (
                      <TableCell sx={projectsStyles.headingCell} align="center">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects &&
                    projects.map((row, index) => (
                      <TableRow key={index} sx={projectsStyles.tableRow}>
                        <TableCell component="th" scope="row" align="center">
                          <Title
                            sx={{ ...customStyles.link }}
                            title={row.id}
                            onClick={() => {
                              navigate(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                                state: { data: row }
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell sx={projectsStyles.title} align="left">
                          <ProjectTitle
                            titleStyle={projectsStyles.proTitle}
                            title={row.project_title}
                            flag={row.flag}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Title title={truncateText(row.service.name, 20)} />
                          <Title title={`Servide id ${row.service.id}`} />
                        </TableCell>
                        <TableCell sx={projectsStyles.statusContainer} align="left">
                          <ProjectStatus
                            data={row}
                            role={userType}
                            options={statuses}
                            onChange={openConfirmModal}
                          />
                        </TableCell>
                        {userType !== defaultValues.userRole.supplier && (
                          <TableCell sx={customStyles.actionCont} align="center">
                            <ActionButton
                              item={row}
                              isEdit={false}
                              projectButtonClick={() => handleOpen(row)}
                              isButton={false}
                              type={defaultValues.listConstants.projects}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <NoData message={getErrorMessage('E-10072')} />
            )
          ) : (
            <Box sx={projectsStyles.collapseContainer}>
              {projects && projects.length > 0 ? (
                <CollapsableList
                  contentArray={projects}
                  statusOptions={statuses}
                  statusChange={openConfirmModal}
                  type={defaultValues.listConstants.projects}
                  onProjectButtonClick={(row) => handleOpen(row)}
                  onProjectTitleClick={(row) => {
                    navigate(`${SCREEN_PATH.PROJECT_DETAILS}`, {
                      state: { data: row }
                    });
                  }}
                  isEdit={false}
                  isButton={false}
                />
              ) : (
                <NoData message={getErrorMessage('E-10072')} />
              )}
            </Box>
          )}

          {currentRenderPage && pageCount && (
            <Box sx={projectsStyles.paginationContainer}>
              <PaginationCustom
                onChange={handlePageChange}
                currentPage={currentRenderPage}
                count={pageCount}
              />
            </Box>
          )}

          <ManageProjectsModal
            open={open}
            close={handleClose}
            quotation={fileUrl}
            submitTitle={
              modalType === defaultValues.listConstants.send
                ? 'Send'
                : modalType === defaultValues.listConstants.view &&
                    userType === defaultValues.userRole.customer
                  ? 'Accept'
                  : modalType === defaultValues.listConstants.edit && 'Re-send'
            }
            cancelTitle={
              modalType === defaultValues.listConstants.view &&
              userType === defaultValues.userRole.customer
                ? 'Reject'
                : 'Cancel'
            }
            heading={
              modalType === defaultValues.listConstants.view
                ? 'View quote'
                : modalType === defaultValues.listConstants.edit
                  ? 'Edit quote'
                  : 'Send quote'
            }
            onSubmit={handleQuotationsubmit}
            type={modalType}
            isLoading={isLoading}
            successMessage={successMessage}
            errorMessage={errMsg}
            handleModal={handleModelType}
            status={statusId}
          />

          <CustomModal
            open={confirmModal}
            onClose={handleConfirmClose}
            title="Change status ?"
            btnType={'Confirm'}
            onCancel={handleConfirmClose}
            onConfirm={handleProjectStatusChange}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Screen>
  );
};
