import { defaultValues } from '../../../constants';

export const styles = {
  input: (taskStatus) => ({
    color: taskStatus.color,
    backgroundColor: taskStatus.bg,
    borderRadius: '4px',
    textAlign: 'left',
    width: defaultValues.isResponsive ? '120px' : '180px',
    height: defaultValues.isResponsive ? '20px' : '40px',
    fontSize: defaultValues.isResponsive ? '10px' : '14px',
    fontWeight: 600,
    mx: 1,
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 0
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0
    },
    '.MuiSvgIcon-root ': {
      fill: taskStatus.color
    }
  })
};
