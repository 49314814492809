import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

export const style = {
  container: {
    width: '100%',
    backgroundColor: color.secondaryBackground,
    minHeight: '100%',
    p: defaultValues.isResponsive ? 0 : 3,
    boxSizing: 'border-box'
  },
  subContainer: {
    width: '100%',
    backgroundColor: color.primaryBackground,
    boxSizing: 'border-box',
    minHeight: '100%',
    borderRadius: 1,
    p: defaultValues.isResponsive ? 0 : 2
  },
  titleContainer: {
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  navStyle: {
    cursor: 'pointer',
    color: color.palette.linkBlue,
    textDecoration: 'underline',
    fontWeight: 600
  },
  forwardIcon: {
    fontSize: defaultValues.isResponsive ? '14px' : '20px',
    marginX: defaultValues.isResponsive ? '5px' : '10px'
  },
  taskListContainer: {
    minHeight: '70vh',
    m: defaultValues.isResponsive ? 1 : 2,
    p: defaultValues.isResponsive ? 1 : 2,
    boxSizing: 'border-box',
    borderRadius: 1,
    border: `1px solid ${color.primaryBorder}`,
    backgroundColor: color.primaryBackground
  },
  listTilte: {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
    boxSizing: 'border-box'
  },
  addBtn: {
    width: defaultValues.isResponsive ? '110px' : '190px',
    height: defaultValues.isResponsive ? '26px' : '48px',
    fontSize: defaultValues.isResponsive ? '10px' : '20px'
  },
  listCard: {
    minHeight: defaultValues.isResponsive ? '' : '150px',
    m: 2,
    p: defaultValues.isResponsive ? 1 : 2,
    boxSizing: 'border-box',
    borderRadius: 1,
    border: `1px solid ${color.primaryBorder}`
  },
  listDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  detailsContainer: {
    maxWidth: defaultValues.isResponsive ? '60%' : '75%'
  },
  listTitleContainer: {
    display: 'flex',
    width: '100%'
  },
  titleBox: { width: '75%' },
  dueDate: {
    width: defaultValues.isResponsive ? '100px' : '170px',
    backgroundColor: color.palette.dueDate,
    display: 'flex',
    justifyContent: 'center',
    my: 1
  },
  toDoBtn: {
    height: defaultValues.isResponsive ? '23px' : '40px',
    width: defaultValues.isResponsive ? '90px' : '125px',
    fontSize: defaultValues.isResponsive ? '9px' : '16px'
  },
  customPopUp: '98%',
  popUpCont: {
    minHeight: defaultValues.isResponsive && 'fit-content'
  },
  popupContentBox: {
    marginLeft: defaultValues.isResponsive ? 0 : '8%',
    width: '100%'
  },
  navTitleStyle: {
    cursor: 'pointer',
    borderBottom: '1px solid',
    fontWeight: 600,
    display: 'inline',
    wordWrap: 'break-word'
  },
  countContainer: { display: 'flex', my: 1, alignItems: 'center' },
  circle: {
    width: '20px',
    height: '20px',
    backgroundColor: color.palette.skyBlueLight,
    borderRadius: '50%',
    overflow: 'hidden'
  },
  innerCircle: (width) => ({
    width: `${width}%`,
    height: '100%',
    backgroundColor: color.palette.skyBlue
  }),
  countDetail: { ml: 1, color: color.palette.emailGray },
  dueDateColor: { color: color.palette.secondaryOrange },
  editIcon: {
    maxWidth: '100%',
    maxHeight: defaultValues.isResponsive ? '20px' : '35px',
    marginLeft: defaultValues.isResponsive ? '5px' : '15px',
    marginRight: defaultValues.isResponsive ? '5px' : '15px',
    cursor: 'pointer'
  },
  noFoundBox: {
    width: '100%',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.secondaryBackground,
    marginTop: '10px'
  },
  noFoundText: {
    color: color.palette.primaryRed,
    fontWeight: 600
  }
};
