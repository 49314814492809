import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

const responsive = defaultValues.isResponsive;

export const OtpStyles = {
  container: {
    width: '100%',
    paddingLeft: !responsive && '20px',
    boxSizing: 'border-box'
  },
  heading: {
    fontWeight: 600
  },
  label: {
    marginTop: '20px',
    marginBottom: '10px'
  },
  buttonStyle: (otp, isRes) => ({
    backgroundColor: otp ? color.palette.primaryRed : color.palette.primaryDisabledRed,
    display: 'block',
    marginTop: '30px',
    color: color.palette.primaryWhite,
    fontSize: isRes ? '15px' : '24px',
    width: isRes && '100%',
    padding: '5px 50px'
  }),
  otpInputStyles: {
    '& .MuiOtpInput-TextField': {
      borderRadius: '8px',
      width: responsive ? '40px' : '58px',
      height: responsive ? '40px' : '58px',
      fontSize: '12px',
      color: 'black',
      fontWeight: 400,
      border: 'none',
      backgroundColor: color.palette.textFieldRed
    }
  },
  otpResendContainer: {
    width: '100%'
  },
  otpTimer: {
    color: color.palette.primaryBlack
  },
  resendOtp: {
    color: color.palette.primaryBlack,
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontSize: responsive && '11px',
    marginTop: '10px'
  },
  otpContainer: {
    alignItems: 'left'
  }
};
