import { Avatar, Box } from '@mui/material';
import { Text } from '../../../ui-kit';
import DP from '../../../assets/images/chatPropic.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';
import { color } from '../../../theme';
import { useSelector } from 'react-redux';
import { getThreadId, getUserId, userInfo } from '../../../services/redux/selectors';
import { format, parseISO } from 'date-fns';
import { getPhotoUrl } from '../../../utils';
import { saveAs } from 'file-saver';
import { defaultValues } from '../../../constants';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const styles = {
  container: (sender) => ({
    width: '100%',
    display: 'flex',
    gap: '10px',
    margin: '10px 0px',
    boxSizing: 'border-box',
    flexDirection: sender ? 'row-reverse' : 'row'
  }),
  imgBox: {
    width: res ? '30px' : '50px',
    height: res ? '30px' : '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    width: '25px',
    height: '25px'
  },
  msgBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: res && '100%'
  },
  secondText: (sender) => ({
    color: color.palette.emailGray,
    fontSize: res ? '10px' : '14px',
    textAlign: sender ? 'right' : 'left'
  }),
  messageCont: (sender) => ({
    padding: '7px',
    boxSizing: 'border-box',
    backgroundColor: color.secondaryBackground,
    width: 'fit-content',
    borderRadius: '4px',
    maxWidth: '80%',
    alignSelf: sender ? 'flex-end' : 'none',
    wordWrap: 'break-word'
  }),
  message: {
    fontSize: res ? '14px' : '16px'
  },
  viewerCont: (sender) => ({
    width: 'fit-content',
    height: 'fit-content',
    alignSelf: sender ? 'flex-end' : 'none',
    objectFit: 'cover',
    display: 'flex',
    justifyContent: sender ? 'right' : 'left'
  }),
  objectPdf: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px'
  },
  altText: {
    textAlign: 'center',
    fontSize: '14px'
  },
  download: {
    color: color.palette.primaryBlue,
    cursor: 'pointer'
  },
  fileContent: {
    width: res ? '200px' : md ? '250px' : '300px',
    height: res ? '200px' : md ? '250px' : '300px',
    cursor: 'pointer'
  },
  link: {
    backgroundColor: color.secondaryBackground,
    padding: '7px',
    boxSizing: 'border-box',
    fontSize: res ? '10px' : '16px',
    wordWrap: 'break-word',
    textAlign: res && 'right',
    width: res && '80%'
  },
  pdfCont: {
    width: '200px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: color.secondaryBackground,
    padding: '4px',
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: '4px'
  },
  fileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: res ? '14px' : '16px'
  },
  pdfIcon: { width: '20px', height: '20px' }
};

export const MessageCard = ({ item, threads }) => {
  const userId = useSelector(getUserId);
  const userData = useSelector(userInfo);
  const currThreadId = useSelector(getThreadId);

  const formatDate = (timestamp) => {
    const date = parseISO(timestamp);
    return format(date, 'hh:mm a');
  };

  const handleDownload = (url) => {
    const pdfUrl = url;
    let arr = url.split('/');
    saveAs(pdfUrl, `${arr[arr.length - 1]}`);
  };

  const FileMessage = ({ fileUrl, fileType }) => {
    const isimage = ['png', 'jpg', 'jpeg'].includes(fileType);

    const renderFile = () => {
      const getFileName = () => {
        const fname = fileUrl.split('/');
        return fname[fname.length - 1];
      };

      if (isimage) {
        return (
          <img
            src={fileUrl}
            alt="File"
            style={styles.fileContent}
            onClick={() => handleDownload(fileUrl)}
          />
        );
      } else if (fileType === 'pdf') {
        return (
          <Box sx={styles.pdfCont} onClick={() => handleDownload(fileUrl)}>
            <img src={pdfIcon} style={styles.pdfIcon} />
            <Text style={styles.fileName}>{getFileName()}</Text>
          </Box>
        );
      } else if (fileType === 'doc' || fileType === 'docx') {
        return (
          <div style={styles.link}>
            <a style={styles.linkText} href={fileUrl}>
              {fileUrl}
            </a>
          </div>
        );
      } else {
        return <p>Unsupported file type: {fileType}</p>;
      }
    };
    return renderFile();
  };

  const getFileType = (url) => {
    const Ftype = url.split('.');
    return Ftype[Ftype.length - 1];
  };

  const getPropic = () => {
    if (threads && threads.length > 0) {
      const element = threads.find((ele) => ele.thread_id === currThreadId);
      if (userId === item.sender.id) {
        if (userData.photo_url) {
          return getPhotoUrl(userData.photo_url);
        } else {
          return null;
        }
      } else if (element && element.user && element.user.profile_pic) {
        return getPhotoUrl(element.user.profile_pic);
      } else if (element && element.users) {
        if (item && item.sender) {
          const propic = element.users.find((ele) => ele.full_name === item.sender.full_name);
          if (propic) {
            return getPhotoUrl(propic.profile_pic && propic.profile_pic);
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    }
  };

  return (
    <Box sx={styles.container(userId === item.sender.id)}>
      <Box sx={styles.imgBox}>
        <Avatar
          alt={item.sender.full_name.toUpperCase() || ''}
          src={getPropic() ? getPropic() : DP}
          sx={styles.img}
        />
      </Box>

      <Box sx={styles.msgBox}>
        <Text sx={styles.secondText(userId === item.sender.id)}>
          {item && item.sender && item.sender.full_name}
        </Text>
        {item && item.message && (
          <Box sx={styles.messageCont(userId === item.sender.id)}>
            <Text sx={styles.message}>{item && item.message}</Text>
          </Box>
        )}
        {item && item.attachment && (
          <Box sx={styles.viewerCont(userId === item.sender.id)}>
            <FileMessage
              fileUrl={item && getPhotoUrl(item.attachment)}
              fileType={item && getFileType(item.attachment)}
            />
          </Box>
        )}

        <Text sx={styles.secondText(userId === item.sender.id)}>
          {formatDate(item && item.sent_at)}
        </Text>
      </Box>
    </Box>
  );
};
