import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const isRes = defaultValues.isResponsive;
export const styles = {
  container: {
    width: '100%',
    backgroundColor: color.secondaryBackground,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: isRes ? '70px' : '30px'
  },
  subContainer: {
    width: '96%',
    minHeight: '90vh',
    backgroundColor: color.primaryBackground,
    m: 2,
    boxSizing: 'border-box',
    p: !isRes && 2
  },
  cardContainer: {
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: 1,
    m: 2,
    boxSizing: 'border-box',
    p: 2
  },
  divider: { my: 1 },
  cardRow: { display: 'flex', flexDirection: 'column', flex: 1, mt: isRes && 1 },
  title: { fontWeight: 600, mt: !isRes && 1, mb: !isRes && 2 },
  contentContainer: !isRes && { display: 'flex', mt: 1 },
  font: { fontWeight: 600 },
  fontColor: { color: color.palette.emailGray },
  desc: { fontWeight: 600, mt: 1 },
  requirmentCard: { display: 'flex', alignItems: 'center' },
  margin: { mt: 1 },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: isRes ? 'center' : 'left'
  },
  btnStyle: {
    marginLeft: !isRes && 2,
    width: isRes ? '90%' : 'auto',
    justifyContent: 'center',
    display: 'flex',
    fontSize: isRes ? '12px' : '20px'
  },
  details: { ml: 1, color: color.palette.emailGray },
  modalCancel: {
    color: color.palette.primaryRed
  },
  apply: {
    backgroundColor: color.palette.tertiaryLightBlue
  },
  tLink: {
    color: color.palette.linkBlue,
    textDecoration: 'underline',
    fontSize: defaultValues.isResponsive ? '11px' : '16px',
    fontWeight: 400,
    cursor: 'pointer',
    ml: !isRes && '100px'
  },
  textClr: { color: color.palette.gray, fontSize: isRes ? '10px' : '16px' },
  to: { display: 'flex', justifyContent: 'center' },
  timelineCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'fit-content'
  }
};
