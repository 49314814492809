import React, { useEffect } from 'react';
import { Screen } from '../../../ui-kit';
import { Box } from '@mui/system';
import { style } from './styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { defaultValues } from '../../../constants';
import { Tab } from '@mui/material';
import { CompanyOverview, FindCustomer } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentTab } from '../../../services/redux/selectors/customerCompanySelector';
import { clearCustomer, tabChange } from '../../../services/redux/slices';

export const ManageCustomersPage = () => {
  const tabValue = useSelector(getCurrentTab);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCustomer());
  }, [tabValue]);

  return (
    <Screen showSideBar={true} currentPage="Manage Customers">
      <Box sx={style.container}>
        <TabContext value={tabValue}>
          <Box sx={style.navContainer}>
            <TabList
              value={tabValue}
              textColor="none"
              TabIndicatorProps={{ sx: style.tabIndicator }}
              onChange={(e, newValue) => {
                dispatch(tabChange(newValue));
              }}
              variant="scrollable"
              aria-label="lab API tabs example">
              {defaultValues.manageCustomers.map((item, index) => (
                <Tab key={index} sx={style.tab} label={item.heading} value={item.value} />
              ))}
            </TabList>
          </Box>
          <Box sx={style.pagesContainer}>
            <TabPanel sx={style.panel} value={1}>
              <CompanyOverview />
            </TabPanel>
            <TabPanel sx={style.panel} value={2}>
              <FindCustomer />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Screen>
  );
};
