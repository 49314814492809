import { Box } from '@mui/material';
import { Filter, IconsButton, Screen, Sort, Text, UIBackButton } from '../../../../ui-kit';
import { vJobStyles } from './styles';
import { LoadingScreen } from '../../../../ui-kit/loading-screen';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptRejectJobRequest,
  getJobApplicantRequest
} from '../../../../services/redux/slices/jobsSlice';
import {
  getApplicants,
  getJobLoading,
  getJobSuccessMessage
} from '../../../../services/redux/selectors/jobSelector';
import { CustomModal, SupplierDetailsCard } from '../../../../components';
import { getUserRole, truncateText } from '../../../../utils';
import { SCREEN_PATH, defaultValues, getErrorMessage } from '../../../../constants';
import { useNavigate } from 'react-router-dom';

export const ManageApplicants = ({ currJob, handleCurrPage }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const cardContainer = useRef(null);
  const applicantsList = useSelector(getApplicants);
  const isLoading = useSelector(getJobLoading);
  const successMessage = useSelector(getJobSuccessMessage);

  const [applicants, setApplicants] = useState([]);
  const [filters, setFilters] = useState({});
  const [open, setOpen] = useState(false);
  const [accept, setAccept] = useState(null);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const roleId = getUserRole(defaultValues.userRole.supplier);

  useEffect(() => {
    handleApplicants({});
  }, []);

  useEffect(() => {
    setApplicants(applicantsList);
  }, [applicantsList]);

  useEffect(() => {
    if (successMessage) {
      handleClose();
    }
  }, [successMessage]);

  const handleApplicants = (data) => {
    let payload = {
      job_id: currJob && currJob.job_id,
      ...filters,
      ...data
    };
    dispatch(getJobApplicantRequest(payload));
  };

  const handleSort = (sort) => {
    setFilters({ ...filters, sort });
    handleApplicants({ sort });
  };
  const handleRefresh = () => {
    setFilters({});
    handleApplicants({ sort: null, filter: null });
  };

  const handleFilter = (value) => {
    setFilters({ ...filters, filter: `[{ status: ${value} }]` });
    handleApplicants({ filter: `[{ status: ${value} }]` });
  };

  const handleAcceptReject = (val) => {
    let payload = {
      application_id: selectedApplicant.application_id,
      action: val
    };
    dispatch(acceptRejectJobRequest(payload));
  };

  const handleClose = () => {
    setOpen(false);
    setAccept(null);
  };

  const containerWidth = () => {
    const width = cardContainer.current && cardContainer.current.offsetWidth;
    return parseInt(width * 0.04);
  };

  const handleTitleClick = (row) => {
    if (row.isApproved === 1) {
      navigation(`${SCREEN_PATH.VIEW_USER_PROFILE}?role=${roleId}&id=${row.applicant_id}`, {
        state: {
          type: defaultValues.listConstants.supplier
        }
      });
    }
  };

  return (
    <Screen showSideBar={true} currentPage="Manage Jobs">
      <Box sx={vJobStyles.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={vJobStyles.contentContainer}>
          <Box sx={vJobStyles.backContainer}>
            <UIBackButton
              buttonStyle={vJobStyles.backBtn}
              backRoot={vJobStyles.backRoot}
              onClick={() => handleCurrPage(1)}
            />
            <Box sx={vJobStyles.toolsContainer}>
              <IconsButton icon={<RefreshIcon sx={vJobStyles.icon} />} onClick={handleRefresh} />
              <Sort
                selectedOption={handleSort}
                activeOption={filters.sort}
                dropdownOptions={defaultValues.applicantsSortOptions}
              />
              <Filter
                label={'Filter'}
                selectedOption={handleFilter}
                onRefresh={filters}
                options={defaultValues.applicantFilterOption}
              />
            </Box>
          </Box>
          <Text style={vJobStyles.heading} variant="fileName">{`Applicants for  ${
            (currJob && currJob.job_title) || ''
          }`}</Text>

          <Box
            ref={cardContainer}
            sx={vJobStyles.applicantsContainer(applicants && applicants.length)}>
            {applicants && applicants.length > 0 ? (
              applicants.map((card, index) => (
                <SupplierDetailsCard
                  key={index}
                  title={` ${card.first_name} ${card.last_name}`}
                  onTitleClick={() => handleTitleClick(card)}
                  language={
                    card.languages &&
                    truncateText(
                      card.languages.map((item) => item.language).join(', '),
                      containerWidth()
                    )
                  }
                  skill={
                    card.skills &&
                    truncateText(
                      card.skills.map((item) => item.skill).join(','),
                      containerWidth(card.skills)
                    )
                  }
                  timezone={card.timezone && card.timezone.timezone}
                  currency={card.currency && card.currency.currency}
                  applicantRating={card.rating}
                  email={card.email}
                  status={card.application_status}
                  onClick={() => {
                    setSelectedApplicant(card);
                    setOpen(true);
                    setAccept(false);
                  }}
                  onRightBtnClick={() => {
                    setSelectedApplicant(card);
                    setOpen(true);
                    setAccept(true);
                  }}
                  type={defaultValues.listConstants.applicants}
                  leftBtnTitle={defaultValues.listConstants.Reject}
                  rightBtnTitle={defaultValues.listConstants.Accept}
                  rightBtnStyle={vJobStyles.rightBtnStyle}
                  leftBtnStyle={vJobStyles.leftBtnStyle}
                  titleContainerStyle={vJobStyles.titleContainer}
                  cardStyle={vJobStyles.cardStyle}
                  titleStyle={vJobStyles.titleStyle}
                />
              ))
            ) : (
              <Box style={vJobStyles.notFoundBox}>
                <Text variant="titleBold" style={vJobStyles.notFoundText}>
                  {getErrorMessage('E-10110')}
                </Text>
              </Box>
            )}
          </Box>

          <CustomModal
            title={
              accept
                ? defaultValues.jobsConfirmationModal(defaultValues.listConstants.accept).title
                : defaultValues.jobsConfirmationModal(defaultValues.listConstants.reject).title
            }
            message={
              accept
                ? defaultValues.jobsConfirmationModal(defaultValues.listConstants.accept).message
                : defaultValues.jobsConfirmationModal(defaultValues.listConstants.reject).message
            }
            open={open}
            btnType={
              accept
                ? defaultValues.jobsConfirmationModal(defaultValues.listConstants.accept).btn
                : defaultValues.jobsConfirmationModal(defaultValues.listConstants.reject).btn
            }
            onClose={handleClose}
            onConfirm={() =>
              handleAcceptReject(
                accept ? defaultValues.listConstants.accept : defaultValues.listConstants.reject
              )
            }
            onCancel={handleClose}
            cancelStyle={vJobStyles.modalCancel}
            confirmStyle={accept ? vJobStyles.modalAccept : vJobStyles.modalReject}
            modalType={
              accept ? defaultValues.listConstants.jobs : defaultValues.listConstants.reject
            }
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Screen>
  );
};
