import { createSlice } from '@reduxjs/toolkit';
import { color } from '../../../theme';
import { defaultValues } from '../../../constants';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  projectsCount: {},
  leadsCount: {},
  leadDistributionData: [],
  supplierCountData: [],
  supplierEngagementData: [],
  newSuppliers: null,
  admins: [],
  currentPage: null,
  totalPage: null,
  companyDetails: {},
  teamDetails: [],
  incomingRequest: [],
  selectedTabIndex: 0,
  indicator: null,
  teamTabIndex: 0,
  adminParams: {},
  dashboardType: '',
  activeProjects: [],
  allProjects: [],
  projectReceived: null,
  jobsPosted: null
};

const adminSlice = createSlice({
  initialState,
  name: 'admin',
  reducers: {
    dashboardDataRequest(state, action) {
      state.isLoading = true;
      state.dashboardType = action.payload.type;
    },
    updateTeamTab(state, action) {
      state.teamTabIndex = action.payload;
    },
    updateAdminParams(state, action) {
      state.adminParams = action.payload;
    },
    dashboardDataRequestSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.success_code;
      state.projectsCount = action.payload.projects_count;
      state.leadsCount = action.payload.leads_count;
      state.leadDistributionData = action.payload.lead_distribution_data;
      state.supplierCountData = action.payload.supplier_count_data;
      state.supplierEngagementData = action.payload.supplier_engagement_data;
      state.newSuppliers = action.payload.new_suppliers;
    },
    dashboardDataRequestFailure(state, action) {
      state.isLoading = false;
      state.dashboardType = action.payload.type;
      state.errorMessage = '';
    },
    getAdminRequest(state) {
      state.isLoading = true;
    },
    getAdminSuccess(state, action) {
      state.isLoading = false;
      state.admins = action.payload.data.admin;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
    },
    getAdminFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    addAdminRequest(state) {
      state.isLoading = true;
    },
    addAdminSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    addAdminFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    editAdminRequest(state) {
      state.isLoading = true;
    },
    editAdminSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.indicator = true;
    },
    editAdminFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    resetAdminMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
      state.indicator = null;
    },
    getCompanyDetailsRequest(state) {
      state.isLoading = true;
    },
    getCompanyDetailsSuccess(state, action) {
      state.isLoading = false;
      state.companyDetails = action.payload.data;
      state.teamDetails = action.payload.data.team_details;
      state.incomingRequest = action.payload.data.incoming_requests;
      state.successMessage = action.payload.status_code;
    },
    getCompanyDetailsFailure(state, action) {
      state.errorMessage = action.payload.status_code;
      state.isLoading = false;
    },
    addTeamMemberRequest(state) {
      state.isLoading = true;
    },
    addTeamMemberSuccess(state, action) {
      state.isLoading = false;
      state.teamDetails = action.payload.team_members;
      state.successMessage = action.payload.status_code;
    },
    addTeamMemberFailure(state, action) {
      state.errorMessage = action.payload.status_code;
      state.isLoading = false;
    },
    editTeamMemberRequest(state) {
      state.isLoading = true;
    },
    editTeamMemberSuccess(state, action) {
      state.isLoading = false;
      state.teamDetails = action.payload.team_details;
      state.successMessage = action.payload.status_code;
    },
    editTeamMemberFailure(state, action) {
      state.errorMessage = action.payload.status_code;
      state.isLoading = false;
    },
    editCustomerCompanyRequest(state) {
      state.isLoading = true;
    },
    editCustomerCompanySuccess(state, action) {
      state.isLoading = false;
      state.companyDetails = action.payload.data;
      state.teamDetails = action.payload.data.team_details;
      state.incomingRequest = action.payload.data.incoming_requests;
      state.successMessage = action.payload.status_code;
    },
    editCustomerCompanyFailure(state, action) {
      state.errorMessage = action.payload.status_code;
      state.isLoading = false;
    },
    approveTeamMemberRequest(state) {
      state.isLoading = true;
    },
    approveTeamMemberSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.incomingRequest = action.payload.incoming_requests;
      state.teamDetails = action.payload.team_details;
    },
    approveTeamMemberFailure(state, action) {
      state.errorMessage = action.payload.status_code;
      state.isLoading = false;
    },
    setSelectedTab(state, action) {
      state.selectedTabIndex = action.payload;
    },
    deleteCompanyLogoRequest(state) {
      state.isLoading = true;
    },
    deleteCompanyLogoSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    deleteCompanyLogoFailure(state, action) {
      state.errorMessage = action.payload.status_code;
      state.isLoading = false;
    },
    requestCustomerDashboard(state) {
      state.isLoading = true;
    },
    customerDashboardSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.data) {
        state.allProjects = [
          {
            key: 'Initial contact',
            count: action.payload.data.initial_contact,
            color: color.palette.lightBlue
          },
          {
            key: defaultValues.isMedium ? 'Issued' : 'Quote issued',
            count: action.payload.data.quotation_issued,
            color: color.palette.secondaryRed
          },
          {
            key: defaultValues.isMedium ? 'Accepted' : 'Quote accepted',
            count: action.payload.data.quotation_accepted,
            color: color.palette.green
          },
          {
            key: 'Amended',
            count: action.payload.data.amended,
            color: color.palette.secondaryOrange
          },
          {
            key: defaultValues.isMedium ? 'Rejected' : 'Quote rejected',
            count: action.payload.data.quotation_rejected,
            color: color.palette.lightRed
          },
          {
            key: 'In progress',
            count: action.payload.data.in_progress,
            color: color.palette.goldenYellow
          },
          {
            key: 'Completed',
            count: action.payload.data.completed,
            color: color.palette.buttonGreen
          },
          {
            key: defaultValues.isMedium ? 'Billing' : 'Ready for billing',
            count: action.payload.data.ready_for_billing,
            color: color.palette.goldenYellow
          },
          {
            key: 'Invioce raised',
            count: action.payload.data.invioce_raised,
            color: color.palette.secondaryBlue
          },
          {
            key: 'Cancelled',
            count: action.payload.data.cancelled,
            color: color.palette.lightRed
          },
          { key: 'Archived', count: action.payload.data.archived, color: color.palette.archived }
        ];
      }
      state.activeProjects = action.payload.data.active_projects;
    },
    customerDashboardFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    requestSupplierDashboard(state) {
      state.isLoading = true;
    },
    supplierDashboardSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.data) {
        state.allProjects = [
          {
            key: 'Pending',
            count: action.payload.data.initial_contact,
            color: color.palette.lightBlue
          },
          {
            key: 'In progress',
            count: action.payload.data.in_progress,
            color: color.palette.goldenYellow
          },
          {
            key: 'Completed',
            count: action.payload.data.completed,
            color: color.palette.buttonGreen
          },
          {
            key: 'Cancelled',
            count: action.payload.data.cancelled,
            color: color.palette.buttonRed
          }
        ];
      }
      state.activeProjects = action.payload.data.active_projects;
      state.projectReceived = action.payload.data.project_received;
      state.jobsPosted = action.payload.data.jobs_posted;
    },
    supplierDashboardFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    requestProjectManagerDashboard(state) {
      state.isLoading = true;
    },
    projectManagerDashboardSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.data) {
        state.allProjects = [
          {
            key: 'Initial contact',
            count: action.payload.data.initial_contact,
            color: color.palette.lightBlue
          },
          {
            key: 'In progress',
            count: action.payload.data.in_progress,
            color: color.palette.goldenYellow
          },
          {
            key: 'Completed',
            count: action.payload.data.completed,
            color: color.palette.buttonGreen
          },
          {
            key: 'Cancelled',
            count: action.payload.data.cancelled,
            color: color.palette.buttonRed
          }
        ];
      }
      state.activeProjects = action.payload.data.active_projects;
    },
    projectManagerDashboardFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getAdminActiveProjects(state) {
      state.isLoading = true;
    },
    adminActiveProjectsSuccess(state, action) {
      state.isLoading = false;
      state.activeProjects = action.payload.data.active_projects;
    },
    adminActiveProjectsFailure(state, action) {
      state.isLoading = false;
      state.activeProjects = [];
      state.errorMessage = action.payload.status_code !== 'E-10133' && action.payload.status_code;
    },

    updateProjects(state) {
      state.isLoading = true;
    },
    updateProjectsSuccess(state, action) {
      state.isLoading = false;
      const updatedId = action.payload.data.id;
      const updatedEventTime = action.payload.data.event_time;
      const index = state.activeProjects.findIndex((item) => item.id === updatedId);
      if (index !== -1) {
        state.activeProjects[index].event_time = updatedEventTime;
      }
      state.successMessage = action.payload.status_code;
    },
    updateProjectsFailure(state) {
      state.isLoading = false;
    }
  }
});

export const {
  updateTeamTab,
  dashboardDataRequest,
  dashboardDataRequestFailure,
  dashboardDataRequestSuccess,
  getAdminRequest,
  getAdminSuccess,
  getAdminFailure,
  addAdminRequest,
  addAdminSuccess,
  addAdminFailure,
  editAdminRequest,
  editAdminSuccess,
  editAdminFailure,
  getCompanyDetailsRequest,
  getCompanyDetailsSuccess,
  getCompanyDetailsFailure,
  resetAdminMessages,
  addTeamMemberRequest,
  addTeamMemberSuccess,
  addTeamMemberFailure,
  editTeamMemberRequest,
  editTeamMemberSuccess,
  editTeamMemberFailure,
  editCustomerCompanyRequest,
  editCustomerCompanySuccess,
  editCustomerCompanyFailure,
  approveTeamMemberRequest,
  approveTeamMemberSuccess,
  approveTeamMemberFailure,
  setSelectedTab,
  deleteCompanyLogoRequest,
  deleteCompanyLogoSuccess,
  deleteCompanyLogoFailure,
  updateAdminParams,
  requestCustomerDashboard,
  customerDashboardSuccess,
  customerDashboardFailure,
  requestSupplierDashboard,
  supplierDashboardSuccess,
  supplierDashboardFailure,
  requestProjectManagerDashboard,
  projectManagerDashboardSuccess,
  projectManagerDashboardFailure,
  getAdminActiveProjects,
  adminActiveProjectsSuccess,
  adminActiveProjectsFailure,
  updateProjects,
  updateProjectsFailure,
  updateProjectsSuccess
} = adminSlice.actions;

export default adminSlice.reducer;
