import React from 'react';
import { Backdrop, Box, Modal, Rating } from '@mui/material';
import { Text, UIButton } from '../../../../ui-kit';
import img from '../../../../assets/images/warning.png';
import imgScss from '../../../../assets/icons/succs.png';
import accept from '../../../../assets/icons/envelop.png';
import reject from '../../../../assets/icons/cancel.png';
import BlueTick from '../../../../assets/icons/blueSucces.png';
import Manager from '../../../../assets/icons/manager.png';

import { Styles } from './style';
import { defaultValues } from '../../../../constants';

export const CustomModal = ({
  open,
  onClose,
  title,
  message,
  btnType,
  onConfirm,
  confirmStyle,
  cancelStyle,
  onCancel,
  modalType,
  isLoading,
  showRating = false,
  rating,
  handleRatingChange,
  rightBtnDisable = false
}) => {
  return (
    <div>
      <Backdrop open={open} onClick={onClose} />
      <Modal open={open} onClose={onClose}>
        <Box sx={Styles.container(defaultValues.isResponsive)}>
          {modalType === defaultValues.listConstants.success ? (
            <img src={imgScss} style={Styles.imageStyleScss} />
          ) : modalType === defaultValues.listConstants.accept ? (
            <img src={accept} style={Styles.imageStyle} />
          ) : modalType === defaultValues.listConstants.reject ? (
            <img src={reject} style={Styles.imageStyle} />
          ) : modalType === defaultValues.listConstants.jobs ? (
            <img src={BlueTick} style={Styles.imageStyle} />
          ) : modalType === defaultValues.listConstants.manager ? (
            <img src={Manager} style={Styles.imageStyle} />
          ) : (
            <img src={img} style={Styles.imageStyle} />
          )}

          <Text
            style={Styles.messageStyle}
            variant={
              defaultValues.isResponsive
                ? 'small'
                : defaultValues.isMedium
                  ? 'fileName'
                  : 'menuText'
            }>
            {title}
          </Text>

          <Text
            style={Styles.messageStyle}
            variant={defaultValues.isResponsive ? 'responsiveText' : 'small'}>
            {message}
          </Text>

          {showRating && (
            <Rating
              sx={Styles.ratingStar}
              value={rating}
              onChange={(e) => handleRatingChange(e.target.value)}
            />
          )}

          <Box sx={Styles.buttonContainer(modalType)}>
            {onCancel && (
              <UIButton
                onClick={onCancel}
                style={Styles.buttonStyleCancel(defaultValues.isResponsive, cancelStyle)}
                title={defaultValues.listConstants.Cancel}
              />
            )}
            &nbsp;&nbsp;
            <UIButton
              onClick={onConfirm}
              disabled={rightBtnDisable}
              title={btnType}
              style={
                btnType === 'OK'
                  ? Styles.buttonStyleOk(defaultValues.isResponsive, btnType)
                  : Styles.buttonStyleConfirm(defaultValues.isResponsive, confirmStyle)
              }
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
