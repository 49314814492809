import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Text, TextInput, UIButton } from '../../../../ui-kit';
import closeIcon from '../../../../assets/icons/close.png';
import { profileModalStyle } from './styles';
import { defaultValues } from '../../../../constants';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { adminSchema } from '../../../../utils';
import { UploadProfilePicture } from '../../upload-profile-picture';

export const AddUserModal = ({
  open,
  onClose,
  heading,
  onButtonClick,
  isLoading,
  btnTitle = 'Add admin',
  successMessage
}) => {
  const [formFilled, setFormFilled] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldError,
    setFieldTouched,
    setFieldValue
  } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: ''
    },
    validationSchema: adminSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  const numberOfErrors = Object.keys(errors).length;
  useEffect(() => {
    if (values.firstname && values.lastname && values.email && numberOfErrors === 0) {
      setFormFilled(true);
    } else {
      setFormFilled(false);
    }
  }, [values, numberOfErrors]);

  useEffect(() => {
    if (!isLoading && successMessage) {
      handleClose();
    }
  }, [isLoading]);

  const handleClose = () => {
    onClose();
    setFieldValue('firstname', '');
    setFieldValue('lastname', '');
    setFieldValue('email', '');
    setFieldTouched('firstname', false);
    setFieldTouched('lastname', false);
    setFieldTouched('email', false);
    setProfilePicture(null);
  };

  const handleProfilePictureChange = (file) => {
    setProfilePicture(file);
  };

  const handleProPicDelete = () => {
    setProfilePicture(null);
  };

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box sx={profileModalStyle.container}>
          <img style={profileModalStyle.closeButton} onClick={handleClose} src={closeIcon} />
          <Box sx={profileModalStyle.contentContainer}>
            <Box sx={profileModalStyle.headingContainer}>
              <Text style={profileModalStyle.heading}>{heading}</Text>
            </Box>

            <UploadProfilePicture
              onProfilePicChange={handleProfilePictureChange}
              onProPicDelete={handleProPicDelete}
              isLoading={isLoading}
              successMessage={successMessage}
            />

            <Box sx={profileModalStyle.inputContainer}>
              <Text variant={defaultValues.isResponsive ? 'medium' : 'desc'}>First name</Text>
              <TextInput
                name={'firstname'}
                style={profileModalStyle.input}
                size={'small'}
                value={values.firstname}
                placeholder={'enter first name'}
                error={touched.firstname && errors.firstname}
                errorStyle={profileModalStyle.errorField}
                onBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                setFieldError={setFieldError}
                onChange={handleChange}
              />
            </Box>
            <Box sx={profileModalStyle.inputContainer}>
              <Text variant={defaultValues.isResponsive ? 'medium' : 'desc'}>Last name</Text>
              <TextInput
                name={'lastname'}
                style={profileModalStyle.input}
                size={'small'}
                value={values.lastname}
                placeholder={'enter last name'}
                error={touched.lastname && errors.lastname}
                errorStyle={profileModalStyle.errorField}
                onBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                setFieldError={setFieldError}
                onChange={handleChange}
              />
            </Box>
            <Box sx={profileModalStyle.inputContainer}>
              <Text variant={defaultValues.isResponsive ? 'medium' : 'desc'}>Email</Text>
              <TextInput
                name={'email'}
                style={profileModalStyle.input}
                size={'small'}
                value={values.email}
                placeholder={'enter admin email-id'}
                error={touched.email && errors.email}
                errorStyle={profileModalStyle.errorField}
                onBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                setFieldError={setFieldError}
                onChange={handleChange}
              />
            </Box>
            <UIButton
              disabled={!formFilled}
              style={profileModalStyle.button}
              onClick={() =>
                onButtonClick(values.firstname, values.lastname, values.email, profilePicture)
              }
              title={btnTitle}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
