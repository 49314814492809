import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

export const styles = {
  container: {
    width: '100%',
    height: window.innerHeight
  },

  secondaryContainer: {
    width: '100%',
    backgroundColor: color.palette.primaryBlack
  },
  serviceContainer: {
    width: '100%',
    padding: '0% 5%',
    boxSizing: 'border-box',
    marginTop: defaultValues.isResponsive ? 3 : 8,
    position: 'relative'
  },
  subContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: color.primaryBackground
  },
  firstSection: {
    width: '100%',
    height: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  leftImg: (isRes) => ({
    width: isRes ? '50px' : '100px',
    position: 'absolute',
    height: isRes ? '50px' : '100px',
    top: 0,
    left: 0
  }),
  logo: (isRes) => ({ height: isRes ? '30%' : '60%' }),
  buttonStyle: (isRes) => ({
    position: 'absolute',
    top: '5%',
    right: '6%',
    fontSize: isRes ? '12px' : '24px',
    width: isRes ? '100px' : '200px',
    height: isRes ? '30px' : '60px'
  }),
  rightImg: (isRes) => ({
    position: 'absolute',
    top: '27%',
    right: 0,
    height: isRes ? '75px' : '150px'
  }),
  msgContainer: { display: 'flex', alignItems: 'center', flexDirection: 'column' },
  divider: { width: '90%' },
  details: (isRes) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    m: isRes ? 1 : 3
  }),
  warning: { width: '70%', p: 2, textAlign: 'center' },
  query: {
    backgroundColor: color.palette.primaryRed,
    p: 2,
    borderRadius: 1,
    textAlign: 'center',
    mb: 5
  },
  text: {
    color: color.primaryBackground
  }
};
