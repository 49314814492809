import React from 'react';
import { Box, Switch } from '@mui/material';
import { Text } from '../text';
import { defaultValues } from '../../constants';

export const UISwitch = ({ title, check, onChange, sx, ...rest }) => {
  const styles = {
    switch: { marginLeft: '15px' }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Text variant={defaultValues.isResponsive && 'responsiveDesc'}>{title}</Text>
      <Switch
        checked={check}
        onChange={onChange}
        InputProps={{ 'aria-label': 'controlled' }}
        sx={{ ...styles.switch, ...sx }}
        {...rest}
      />
    </Box>
  );
};
