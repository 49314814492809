import { Box, Divider } from '@mui/material';
import { Screen, Text, UIButton, UIChipButton } from '../../../ui-kit';
import { invitationStyles } from './styles';
import { BasicProfileDetails } from '../../../components';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { useEffect, useState } from 'react';
import { DragDrop } from '../../../components/common/drag-and-drop';
import { UITextInputWithButton } from '../../../ui-kit/text-input-with-button';
import { useFormik } from 'formik';
import {
  ConvertedTime,
  getFormatDateNotUtc,
  getValueWithId,
  invitationSchema,
  resetMessge
} from '../../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { resetSupplierMessages, supplierInvitationRequest } from '../../../services/redux/slices';
import { toast } from 'react-toastify';
import {
  getSupplierErrorMessage,
  getSupplierSuccessMessage,
  supplierLoadingStatus
} from '../../../services/redux/selectors/supplierSelector';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { customStyles } from '../../../theme';

export const InviteSupplier = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector(supplierLoadingStatus);
  const successMessage = useSelector(getSupplierSuccessMessage);
  const errorMessage = useSelector(getSupplierErrorMessage);

  const [details, setDetails] = useState({});
  const [file, setFile] = useState(null);
  const [checked, setChecked] = useState(false);
  const [availability, setAvailability] = useState(null);
  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldError,
    setFieldTouched,
    setFieldValue
  } = useFormik({
    initialValues: {
      checklists: [],
      checkListText: ''
    },
    validationSchema: invitationSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      navigate(-1);
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessge(() => dispatch(resetSupplierMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (location.state) {
      setDetails(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    if (details && details.supplier_details && details.supplier_details.availability_type) {
      setAvailability(
        getValueWithId(
          defaultValues.availabilityOptions,
          details.supplier_details.availability_type
        )
      );
    }
  }, [details]);

  const handleAddCheckList = () => {
    if (values.checkListText) {
      setFieldValue('checklists', [...values.checklists, `"${values.checkListText}"`]);
      setFieldValue('checkListText', '');
      setFieldTouched('checkListText', false);
      setChecked(false);
    }
  };

  const handleCheckDelete = (start, end) => {
    let updatedStateList = [...values.checklists];
    updatedStateList.splice(start, end);
    setFieldValue('checklists', updatedStateList);
  };

  const handleInvite = () => {
    let payload = {
      project_id: details.project_details.id,
      supplier_id: details.supplier_details.id
    };
    if (file) {
      payload.attachment = file;
    }
    if (values.checklists.length > 0) {
      payload.checklists = `[${values.checklists}]`;
    }

    dispatch(supplierInvitationRequest(payload));
    setFieldValue('checklists', []);
    setFile(null);
  };

  const handleCheckFieldClose = () => {
    setFieldValue('checkListText', '');
    setFieldTouched('checkListText', false);
    setChecked(false);
  };

  const getEndDate = (date) => {
    if (date) {
      return (
        <ConvertedTime
          time={date}
          originalTimeZoneName={details.project_details.event_timezone}
          targetTimeZoneName={defaultValues.uKTimeZone}
          style={invitationStyles.textClr}
        />
      );
    } else {
      return '';
    }
  };

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive && true}
      currentPage={'Manage Projects'}>
      <Box sx={invitationStyles.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={invitationStyles.contentContainer}>
          <Box sx={invitationStyles.navContainer}>
            <Box sx={customStyles.flexBox}>
              <Text style={invitationStyles.navText} onClick={() => navigate(-2)}>
                {details && details.project_details && details.project_details.project_name}
              </Text>
              <ArrowForwardIos sx={invitationStyles.icon} />

              <Text
                style={invitationStyles.navText}
                onClick={() => navigate(-1)}>{`Supplier hub`}</Text>
              {!defaultValues.isResponsive && <ArrowForwardIos sx={invitationStyles.icon} />}
            </Box>

            <Text style={invitationStyles.navIdleText}>Invite supplier</Text>
          </Box>

          <Box sx={invitationStyles.subContainer}>
            <Box sx={invitationStyles.supplierContainer}>
              <Text
                style={invitationStyles.mb}
                variant={defaultValues.isResponsive ? 'semiBold' : 'titleBold'}>
                Supplier Details
              </Text>
              <BasicProfileDetails
                titleStyle={invitationStyles.checkTitle}
                title={`${
                  details && details.supplier_details && details.supplier_details.first_name
                } ${details && details.supplier_details && details.supplier_details.last_name}`}
                subTitle={details && details.supplier_details && details.supplier_details.email}
              />
              <Divider sx={invitationStyles.divider} />
              <Box>
                <Text style={invitationStyles.checkTitle}>
                  Skills -
                  {details &&
                    details.supplier_details &&
                    details.supplier_details.supplier_skills &&
                    details.supplier_details.supplier_skills.map((item, idx) => (
                      <span style={invitationStyles.spanText} key={idx}>
                        {` ${item.skill_title}${
                          idx === details.supplier_details.supplier_skills.length - 1 ? '' : ', '
                        }`}
                      </span>
                    ))}
                  {details &&
                    details.supplier_details &&
                    details.supplier_details.skills &&
                    details.supplier_details.skills.map((item, idx) => (
                      <span style={invitationStyles.spanText} key={idx}>
                        {` ${item.skill}${
                          idx === details.supplier_details.skills.length - 1 ? '' : ', '
                        }`}
                      </span>
                    ))}
                </Text>
                <Text style={invitationStyles.checkTitle}>
                  Languages -
                  {details &&
                    details.supplier_details &&
                    details.supplier_details.languages &&
                    details.supplier_details.languages.map((item, idx) => (
                      <span style={invitationStyles.spanText} key={idx}>
                        {` ${item.language}${
                          idx === details.supplier_details.languages.length - 1 ? '' : ', '
                        }`}
                      </span>
                    ))}
                  {details &&
                    details.supplier_details &&
                    details.supplier_details.supplier_languages &&
                    details.supplier_details.supplier_languages.map((item, idx) => (
                      <span style={invitationStyles.spanText} key={idx}>
                        {` ${item.language}${
                          idx === details.supplier_details.supplier_languages.length - 1 ? '' : ', '
                        }`}
                      </span>
                    ))}
                </Text>

                <Text style={invitationStyles.checkTitle}>
                  Currency -
                  <span style={invitationStyles.spanText}>
                    {details &&
                      details.supplier_details &&
                      details.supplier_details.currency.currency}
                  </span>
                </Text>
                <Text style={invitationStyles.checkTitle}>
                  Timezone -
                  <span style={invitationStyles.spanText}>
                    {details &&
                      details.supplier_details &&
                      details.supplier_details.timezone.timezone}
                  </span>
                </Text>
                <Text style={invitationStyles.checkTitle}>
                  Availability -
                  <span style={invitationStyles.spanText}>{availability && availability}</span>
                </Text>

                <Text style={invitationStyles.checkTitle}>Working schedule</Text>
                <Text style={invitationStyles.spanText}>
                  {details &&
                    details.supplier_details &&
                    details.supplier_details.availability_details &&
                    details.supplier_details.availability_details}
                </Text>
              </Box>
            </Box>
            <Box sx={invitationStyles.projectsContainer}>
              <Text
                style={invitationStyles.mb}
                variant={defaultValues.isResponsive ? 'semiBold' : 'titleBold'}>
                Project Details
              </Text>
              <BasicProfileDetails
                titleStyle={invitationStyles.checkTitle}
                title={`${
                  details && details.project_details && details.project_details.project_name
                } (${details && details.project_details && details.project_details.id})`}
                subTitle={`${
                  details &&
                  details.project_details &&
                  getFormatDateNotUtc(details.project_details.received_date, 'DD-MM-YYYY hh:mm A')
                }`}
                propic={false}
              />
              <BasicProfileDetails
                titleStyle={invitationStyles.checkTitle}
                title={'Service Required'}
                subTitle={
                  details && details.project_details && details.project_details.service_name
                }
                propic={false}
              />
              <BasicProfileDetails
                titleStyle={invitationStyles.checkTitle}
                title={'Timeline'}
                subTitle={
                  <>
                    <ConvertedTime
                      time={
                        details && details.project_details && details.project_details.start_date
                      }
                      originalTimeZoneName={
                        details && details.project_details && details.project_details.event_timezone
                      }
                      targetTimeZoneName={defaultValues.uKTimeZone}
                      style={invitationStyles.textClr}
                    />
                    {getEndDate(
                      details && details.project_details && details.project_details.end_date
                    )}
                  </>
                }
                propic={false}
              />
              <Text style={invitationStyles.checkTitle}>
                Spoken languages -
                {details &&
                  details.project_details &&
                  details.project_details.spoken_languages &&
                  details.project_details.spoken_languages.map((item, idx) => (
                    <span style={invitationStyles.spanText} key={idx}>
                      {` ${item.language}${
                        idx === details.project_details.spoken_languages.length - 1 ? '' : ', '
                      }`}
                    </span>
                  ))}
              </Text>
              <Text style={invitationStyles.checkTitle}>
                Captioning languages
                {details &&
                  details.project_details &&
                  details.project_details.captioning_languages &&
                  details.project_details.captioning_languages.map((item, idx) => (
                    <span style={invitationStyles.spanText} key={idx}>
                      {` ${item.language}${
                        idx === details.project_details.captioning_languages.length - 1 ? '' : ', '
                      }`}
                    </span>
                  ))}
              </Text>
              <Text style={invitationStyles.checkTitle}>
                Translation required -
                {details &&
                details.project_details &&
                details.project_details.translation_required === 1 ? (
                  <span style={invitationStyles.spanText}>Yes</span>
                ) : details.project_details &&
                  details.project_details.translation_required === 2 ? (
                  <span style={invitationStyles.spanText}>No</span>
                ) : (
                  <span style={invitationStyles.spanText}>Not applicable</span>
                )}
              </Text>
              <BasicProfileDetails
                titleStyle={invitationStyles.checkTitle}
                title={'Event duration'}
                subTitle={`${
                  details && details.project_details && details.project_details.event_duration
                } ${
                  details && details.project_details && details.project_details.event_duration === 1
                    ? 'hour'
                    : 'hours'
                }`}
                propic={false}
              />
              {details &&
                details.project_details &&
                details.project_details.schedule_details.length > 0 && (
                  <Box>
                    <Text style={invitationStyles.checkTitle}>Schedule breakup</Text>
                    {details.project_details.schedule_details &&
                      details.project_details.schedule_details.map((item, index) => (
                        <Text key={index}>
                          <span style={invitationStyles.spanText}>{item}</span>
                        </Text>
                      ))}
                  </Box>
                )}
            </Box>
          </Box>
          <Box sx={invitationStyles.briefContainer}>
            <Text style={invitationStyles.primeTitle}>Briefing document</Text>
            <DragDrop handleChange={(val) => setFile(val)} pdfFile={file} />
          </Box>

          <Box sx={invitationStyles.checklistContainer}>
            <Box sx={invitationStyles.checkContainer}>
              <Box sx={invitationStyles.checkSubBox}>
                <BasicProfileDetails
                  titleStyle={invitationStyles.primeTitle}
                  title={'Add checklist'}
                  propic={false}
                />
                <UIButton
                  title="+ new checklist"
                  style={invitationStyles.addCheckBtn}
                  onClick={() => {
                    setChecked(true);
                  }}
                />
              </Box>
              <Text
                style={invitationStyles.desc}
                variant={defaultValues.isResponsive ? 'extraSmall' : 'small'}>
                Add any additional requirements/expectations required for the project
              </Text>
            </Box>

            {checked && (
              <UITextInputWithButton
                name="checkListText"
                value={values.checkListText}
                error={touched && touched.checkListText && errors.checkListText}
                placeholder={'type here'}
                onBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                setFieldError={setFieldError}
                onChange={handleChange}
                onAdd={handleAddCheckList}
                onClose={handleCheckFieldClose}
                btnTitle={'Save'}
              />
            )}

            {values.checklists.length > 0 && (
              <Box sx={invitationStyles.chipContainer}>
                {values.checklists.map((item, index) => (
                  <UIChipButton
                    key={index}
                    title={item.replace(/"/g, '')}
                    style={invitationStyles.chipButton}
                    deleteIconStyle={invitationStyles.deleteIcon}
                    onDelete={() => handleCheckDelete(index, 1)}
                  />
                ))}
              </Box>
            )}
          </Box>
          <UIButton title={'Send invite'} style={invitationStyles.sendBtn} onClick={handleInvite} />
        </Box>
      </Box>
    </Screen>
  );
};
