import { defaultValues } from '../../constants';
import { color, fonts } from '../../theme';
const isRes = defaultValues.isResponsive;
export const styles = {
  container: {
    height: '100%',
    width: '100%',
    m: 3,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: 1,
    position: 'relative',
    p: 1
  },
  leftArrow: { position: 'absolute', top: '50%', left: isRes ? '-2%' : '1%' },
  title: (style) => ({ fontWeight: 600, textAlign: 'left', ...style }),
  cardContainer: {
    display: 'flex',
    justifyContent: 'start',
    mt: 2,
    ml: !isRes && 5,
    width: '100%'
  },
  cardStyle: (style) => ({
    minWidth: defaultValues.isMdSmall ? '210px' : isRes ? '260px' : '320px',
    height: 'fit-content',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: 1,
    ml: 1,
    mb: '20px',
    ...style
  }),
  titleContainer: (style) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mb: '2px',
    ...style
  }),
  stackStyle: { display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '2px' },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mt: 2,
    width: '100%',
    gap: '10px'
  },
  btnStyle: (style) => ({
    fontSize: defaultValues.isMdSmall ? '6px' : isRes ? '7px' : '14px',
    height: isRes ? '23px' : '40px',
    width: isRes ? '115px' : '135px',
    ...style
  }),
  btnnS: (style) => ({
    fontSize: defaultValues.isMdSmall ? '6px' : isRes ? '8px' : '14px',
    height: isRes ? '23px' : '40px',
    width: isRes ? '110px' : '135px',
    backgroundColor: color.primaryBackground,
    color: color.primaryText,
    border: `1px solid ${color.primaryText}`,
    ...style
  }),
  rightArrow: { position: 'absolute', top: '50%', right: isRes ? '-2%' : '2%' },
  imgStyle: {
    objectFit: 'contain',
    width: isRes ? '16px' : '20px',
    height: isRes ? '16px' : '20px'
  },
  applicantRating: {
    fontSize: isRes ? '16px' : '20px'
  },
  emailText: { fontSize: isRes ? '12px' : '16px', color: color.palette.gray },
  spanText: { textDecoration: 'none' },
  titleSub: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleSuperSub: {
    display: 'flex',
    flexDirection: 'row',

    width: '100%'
  },
  rating: {
    fontSize: isRes ? '14px' : '22px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
  },
  ratingContainer: {
    fontWeight: 600,
    fontFamily: fonts.primary,
    display: 'flex',
    alignItems: 'center'
  },
  status: (item) => ({
    height: 'fit-content',
    width: isRes ? '70px' : '100px',
    color: item && item.color,
    backgroundColor: item && item.bgColor,
    padding: '4px',
    borderRadius: '4px',
    fontSize: isRes ? '11px' : '14px',
    textAlign: 'center'
  })
};
