import { defaultValues } from '../../../constants';

export const styles = {
  container: { width: defaultValues.isResponsive ? '90%' : '80%' },
  btn: {
    marginTop: defaultValues.isResponsive ? '10px' : '20px',
    width: defaultValues.isResponsive ? '100%' : '220px',
    fontSize: defaultValues.isResponsive ? '14px' : '20px',
    height: defaultValues.isResponsive ? '35px' : '50px'
  }
};
