import { Avatar, Box } from '@mui/material';
import { FCStyles } from './styles';
import { SearchBar, Text, UIButton } from '../../../ui-kit';
import { customStyles } from '../../../theme';
import { useCallback, useEffect, useState } from 'react';
import { StatusIndicator } from '../../other-settings/table-title';
import {
  EDIT_KEY,
  SCREEN_PATH,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../../constants';
import ProfilePic from '../../../assets/images/adminProPic.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  cCompanyLoadingStatus,
  getCCompanyErrorMessage,
  getCCompanySuccessMessage,
  getCustomers,
  getUserSearch
} from '../../../services/redux/selectors/customerCompanySelector';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { toast } from 'react-toastify';
import {
  clearCustomer,
  findCustomerSearchChange,
  getCustomersRequest,
  pushCustomerRequest,
  resetCustomerCompanyMessages
} from '../../../services/redux/slices';
import { getPhotoUrl, getUserRole, resetMessge } from '../../../utils';
import { CustomerCollapsableList } from '../../common/customer-collapsable';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomModal } from '../../common/modals/status-modal';

export const FindCustomer = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const successMessage = useSelector(getCCompanySuccessMessage);
  const errorMessage = useSelector(getCCompanyErrorMessage);
  const isLoading = useSelector(cCompanyLoadingStatus);
  const selCustomers = useSelector(getCustomers);
  const search = useSelector(getUserSearch);

  const location = useLocation();

  const [notFound, setNotFound] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [editCustomer, setEditCustomer] = useState(null);
  const roleId = getUserRole(defaultValues.userRole.customer);

  useEffect(() => {
    dispatch(clearCustomer());
    setNotFound(false);
  }, []);

  useEffect(() => {
    if (location.state && location.state.search) {
      handleLocation(location.state.search);
    }
  }, [location.state]);

  const handleLocation = useCallback(
    (val) => {
      dispatch(findCustomerSearchChange(val));
      val.length >= 3 && dispatch(getCustomersRequest({ search_text: val }));
      navigation(location.pathname, { state: {} });
    },
    [search]
  );

  useEffect(() => {
    if (selCustomers) {
      getData();
    }
  }, [selCustomers]);

  const getData = async () => {
    let data = selCustomers;
    setCustomerList(data);
    setNotFound(selCustomers.length == 0 && search.length > 0);
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      handleSearch();
    }
    if (errorMessage) {
      if (errorMessage === 'E-10076') {
        setNotFound(true);
      } else {
        toast.error(getErrorMessage(errorMessage));
      }
    }
    resetMessge(() => dispatch(resetCustomerCompanyMessages()));
  }, [errorMessage, successMessage]);

  const handleSearch = () => {
    if (search.length >= 3) {
      dispatch(getCustomersRequest({ search_text: search }));
    }
    if (search === '') {
      dispatch(getCustomersRequest());
    }
  };

  const handleViewDetails = (id) => {
    navigation(`${SCREEN_PATH.VIEW_USER_PROFILE}?role=${roleId}&id=${id}`, {
      state: {
        route: location.pathname,
        search,
        type: defaultValues.listConstants.customers
      }
    });
  };

  const handleEdit = (payload) => {
    const data = { payload, type: EDIT_KEY };
    dispatch(pushCustomerRequest(data));
    setEditCustomer(null);
  };

  return (
    <Box sx={FCStyles.container}>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={FCStyles.contentContainer}>
        <Box sx={FCStyles.topBar}>
          <SearchBar
            style={customStyles.w100}
            label={'search here'}
            defaultValue={search}
            searchedVlaue={(val) => {
              dispatch(findCustomerSearchChange(val));
            }}
            showSearchIcon={false}
          />
          <UIButton
            title="Search"
            style={FCStyles.searchBtn}
            disabled={search.length <= 0}
            onClick={handleSearch}
          />
        </Box>
        {notFound ? (
          <Box sx={FCStyles.notFoundBox}>
            <Text variant="sectionTitleBold">User Not Found</Text>
            <Text variant="body" style={FCStyles.subTitle}>
              Verify the entered information and try again
            </Text>
          </Box>
        ) : (
          <>
            {customerList.length > 0 && <Text variant="title">Search results</Text>}
            {!defaultValues.isResponsive ? (
              customerList.map((item) => (
                <Box key={item} sx={FCStyles.listTile}>
                  <Box sx={FCStyles.profileImage}>
                    <Avatar
                      alt={item.customer_first_name.toUpperCase() || ''}
                      src={item.customer_photo ? getPhotoUrl(item.customer_photo) : ProfilePic}
                      sx={FCStyles.profileBox}
                    />
                    <Box sx={FCStyles.nameBox}>
                      <Text
                        variant="bold"
                        style={{ ...FCStyles.name, ...customStyles.link, ...customStyles.sTitle }}
                        onClick={() => handleViewDetails(item.id)}>
                        {item.customer_first_name + item.customer_last_name}
                      </Text>
                      <Text style={FCStyles.email} variant="lightBody">
                        {item.customer_email}
                      </Text>
                    </Box>
                  </Box>
                  <Box sx={FCStyles.w15}>
                    {item.customar_company_name && (
                      <Text style={{ ...customStyles.txtC, ...FCStyles.cName }}>
                        {item.customar_company_name}
                      </Text>
                    )}
                  </Box>
                  <Box sx={FCStyles.w10}>
                    <StatusIndicator status={item.active} />
                  </Box>
                  <Box sx={FCStyles.w10}>
                    <UIButton
                      title={item.active ? 'Deactivate' : 'Reactivate'}
                      style={FCStyles.actionButton(item.active)}
                      onClick={() => {
                        setEditCustomer({
                          name: `${item.customer_first_name} ${item.customer_last_name}`,
                          payload: { id: item.id, active: !item.active }
                        });
                      }}
                    />
                  </Box>
                </Box>
              ))
            ) : (
              <Box sx={FCStyles.collapseContainer}>
                <CustomerCollapsableList
                  list={customerList}
                  onViewDetailsClick={(item) => handleViewDetails(item.id)}
                  onChangeStatus={(val) =>
                    handleEdit(
                      { id: val.id, active: !val.active },
                      `${val.first_name} ${val.last_name}`
                    )
                  }
                />
              </Box>
            )}
          </>
        )}
      </Box>

      <CustomModal
        open={editCustomer}
        onClose={() => setEditCustomer(null)}
        title={defaultValues.changeStatusModal.title}
        message={
          editCustomer &&
          `${editCustomer.name} will be ${
            editCustomer.payload.active ? 'activated' : 'deactivated'
          }`
        }
        btnType={defaultValues.changeStatusModal.btn}
        onCancel={() => setEditCustomer(null)}
        onConfirm={() => handleEdit(editCustomer && editCustomer.payload)}
      />
    </Box>
  );
};
