import { Box, Divider } from '@mui/material';
import { Screen, Text } from '../../../ui-kit';
import { LoadingScreen } from '../../../ui-kit/loading-screen';
import { style } from './style';
import { ArrowForwardIos } from '@mui/icons-material';
import { defaultValues, getErrorMessage } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getProjectsLogs } from '../../../services/redux/slices';
import {
  getProjectDetailsSel,
  getProjectsLoadingStatus,
  projectsLog
} from '../../../services/redux/selectors';
import { formatDateTime } from '../../../utils';
import { NoData } from '../../common/no-data-message';
import { useNavigate } from 'react-router-dom';

export const ProjectLogs = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const pLog = useSelector(projectsLog);
  const project = useSelector(getProjectDetailsSel);
  const isLoading = useSelector(getProjectsLoadingStatus);

  const [logs, setLogs] = useState([]);
  const [selPro, setSelPro] = useState({});

  useEffect(() => {
    if (project) {
      setSelPro(project);
      dispatch(getProjectsLogs({ project_id: project.id }));
    }
  }, [project]);

  useEffect(() => {
    if (pLog) {
      setLogs(pLog);
    }
  }, [pLog]);

  return (
    <Screen
      showSideBar={!defaultValues.isResponsive}
      showHeader={defaultValues.isResponsive}
      currentPage="Project details">
      <div style={style.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={style.subContainer}>
          <Box sx={style.optionsContainer}>
            <Text
              variant={defaultValues.isResponsive ? 'medium' : 'titleBold'}
              style={style.navLink}
              onClick={() => navigation(-1)}>
              {selPro.project_name}
            </Text>
            {!defaultValues.isResponsive && <ArrowForwardIos sx={style.icon} />}
            <Text variant={defaultValues.isResponsive ? 'small' : 'titleBold'}>Log</Text>
          </Box>

          {logs.length > 0 ? (
            <Box sx={style.notificationContainer}>
              <Text variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>
                Log of status change
              </Text>
              <Box sx={style.logContainer}>
                {logs.map((item, index) => (
                  <Box key={index} sx={{ m: 1 }}>
                    <Text variant={defaultValues.isResponsive ? 'responsiveDescBold' : 'semiBold'}>
                      {`Updated by ${item.created_by} on ${formatDateTime(item.created_at)} `}
                    </Text>
                    <Text variant={defaultValues.isResponsive ? 'cardResSubTitle' : 'cardSubTitle'}>
                      {item.log}
                    </Text>
                    {index !== logs.length - 1 && <Divider sx={style.divider} />}
                  </Box>
                ))}
              </Box>
            </Box>
          ) : (
            <NoData message={getErrorMessage('E-10132')} />
          )}
        </Box>
      </div>
    </Screen>
  );
};
