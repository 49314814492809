import { palette } from './palette';

/**
 * Roles for colors. Define different colors with tgheir type or role. change the color codes from the palette file
 */
export const color = {
  /**
   * The palette is available to use, but prefer using the name.
   */
  palette,
  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   */
  transparent: 'rgba(0, 0, 0, 0)',
  /**
   * The screen background.
   */
  primaryBackground: palette.primaryWhite,
  secondaryBackground: palette.secondaryBackground,
  /**
   * The main tinting color.
   */
  primary: palette.primaryBlack,

  /**
   * The default color of text in many components.
   */
  primaryText: palette.primaryBlack,
  secondaryText: palette.primaryWhite,
  // tertiaryText: palette.white,
  /**
   * Error messages and icons.
   */
  errorText: palette.primaryRed,
  /**
default border color
**/
  primaryBorder: palette.lightGray
};
