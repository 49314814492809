import { Box } from '@mui/material';
import { Text, TextInput, UIButton } from '../../../ui-kit';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { bookServiceSchema } from '../../../utils';
import { Styles } from './style';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetRightSection,
  searchCompanyRequest,
  setIsCompanyRegistered,
  setRightSection
} from '../../../services/redux/slices';
import { getCompanyActive, getRegNo, isCompanyRegisterd } from '../../../services/redux/selectors';
import { CustomModal } from '../..';

export const Registration = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const companyRegStatus = useSelector(isCompanyRegisterd);
  const isCompanyActive = useSelector(getCompanyActive);
  const registerNumber = useSelector(getRegNo);

  const [showModal, setShowModal] = useState(false);
  const [isCmpny, setIsCmpny] = useState(false);

  const { values, handleBlur, handleChange, errors, setValues } = useFormik({
    initialValues: { regNo: '' },
    validationSchema: bookServiceSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  const handleSearch = () => {
    dispatch(searchCompanyRequest({ registration_number: values.regNo }));
  };

  const handleConfirm = () => {
    setShowModal(false);
    dispatch(setRightSection('companyDetails'));
  };

  const handleCancel = () => {
    setShowModal(false);
    dispatch(resetRightSection());
    navigation(SCREEN_PATH.HOME);
  };

  const handleOK = () => {
    if (isCompanyActive) {
      dispatch(setRightSection('companyDetails'));
      setIsCmpny(false);
    } else {
      setIsCmpny(false);
      dispatch(setIsCompanyRegistered(null));
    }
  };

  useEffect(() => {
    if (companyRegStatus === false) {
      setIsCmpny(false);
      setShowModal(true);
    } else if (companyRegStatus === true) {
      setShowModal(false);
      if (isCompanyActive === true) {
        setIsCmpny(true);
      } else {
        setIsCmpny(false);
      }
    }
    if (registerNumber) {
      setValues({ ...values, regNo: registerNumber });
    }
  }, [companyRegStatus, registerNumber, isCompanyActive]);

  return (
    <Box>
      <CustomModal
        open={showModal}
        onClose={handleCancel}
        title={defaultValues.confirmModal.title}
        message={defaultValues.confirmModal.message}
        btnType={defaultValues.confirmModal.btn}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <CustomModal
        modalType={'success'}
        open={isCmpny}
        onClose={handleOK}
        title={defaultValues.userExistedModal.title}
        message={defaultValues.userExistedModal.message}
        btnType={defaultValues.userExistedModal.btn}
        onConfirm={handleOK}
      />
      <Text
        style={Styles.title(defaultValues.isResponsive)}
        variant={defaultValues.isResponsive ? 'body' : 'secondaryHeader'}>
        {defaultValues.regTitle}
      </Text>
      <Box sx={Styles.textContainer}>
        <Text variant={defaultValues.isResponsive ? 'responsiveText' : 'body'}>
          {defaultValues.subTitle}
        </Text>
      </Box>

      <Text style={Styles.label}>
        <Text
          style={Styles.registrationNumber}
          variant={defaultValues.isResponsive ? 'medium' : 'menuText'}>
          Registration number
        </Text>
        <Text variant={defaultValues.isResponsive ? 'extraSmall' : 'responsiveText'}>
          (Individuals enter your Unique Tax Reference Code)
        </Text>
      </Text>
      <Box sx={Styles.fieledContainer(defaultValues.isResponsive)}>
        <TextInput
          required
          name="regNo"
          value={values.regNo}
          variant="outlined"
          size={defaultValues.isResponsive ? 'small' : 'medium'}
          style={Styles.emailField(defaultValues.isResponsive)}
          onBlur={handleBlur}
          error={errors.regNo && errors.regNo}
          placeholder={defaultValues.placeholder}
          onChange={handleChange}
        />
        <Box sx={Styles.buttonContainer(defaultValues.isResponsive)}>
          <UIButton
            disabled={!values.regNo.length}
            size="large"
            style={Styles.buttonStyle(values.regNo.length, defaultValues.isResponsive)}
            title="Search"
            onClick={handleSearch}
          />
        </Box>
      </Box>
    </Box>
  );
};
