import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  totalPage: null,
  nextPage: null,
  threads: [],
  messages: [],
  currThreadId: null,
  Thread_current_page: null,
  Thread_next_page: null,
  Thread_total_page: null,
  msg_current_page: null,
  msg_next_page: null,
  msg_total_page: null,
  personel_threads: [],
  personel_messages: [],
  personel_Thread_current_page: null,
  personel_Thread_next_page: null,
  personel_Thread_total_page: null,
  personel_msg_current_page: null,
  personel_msg_next_page: null,
  personel_msg_total_page: null
};

const chatSlice = createSlice({
  initialState,
  name: 'chat',
  reducers: {
    updateChatParams(state, action) {
      state.jobParams = action.payload;
    },
    resetChatMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
      state.indicator = null;
    },
    clearMessages(state) {
      state.messages = [];
      state.msg_current_page = null;
      state.msg_next_page = null;
      state.msg_total_page = null;
    },
    clearThreads(state) {
      state.threads = [];
      state.Thread_current_page = null;
      state.Thread_next_page = null;
      state.Thread_total_page = null;
    },
    clearPersonelMessages(state) {
      state.personel_messages = [];
      state.personel_msg_current_page = null;
      state.personel_msg_next_page = null;
      state.personel_msg_total_page = null;
    },
    clearPersonelThreads(state) {
      state.personel_threads = [];
      state.personel_Thread_current_page = null;
      state.personel_Thread_next_page = null;
      state.personel_Thread_total_page = null;
    },

    initiateChatRequest(state) {
      state.isLoading = true;
    },
    initiateChatSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    initiateChatFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getThreadsRequest(state) {
      state.isLoading = true;
    },
    getThreadsSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.personel_Thread_current_page = action.payload.data.current_page;
      state.personel_Thread_next_page = action.payload.data.next_page;
      state.personel_Thread_total_page = action.payload.data.total_page;
      const list = [...state.personel_threads, ...action.payload.data.threads];
      const temp = list.filter((item, index, self) => {
        return index === self.findIndex((s) => s.thread_id === item.thread_id);
      });
      state.personel_threads = temp;
    },
    getThreadsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.personel_Thread_next_page = null;
      state.personel_Thread_current_page = null;
      state.personel_Thread_total_page = null;
      state.personel_threads = [];
    },

    getMessagesRequest(state) {
      state.isLoading = false;
    },
    getMessagesSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.personel_msg_current_page = action.payload.data.current_page;
      state.personel_msg_next_page = action.payload.data.next_page;
      state.personel_msg_total_page = action.payload.data.total_page;
      const list = [...state.personel_messages, ...action.payload.data.messages];
      const temp = list.filter((item, index, self) => {
        return index === self.findIndex((s) => s.message_id === item.message_id);
      });
      state.personel_messages = temp;
      state.currThreadId = action.payload.data.thread_id;
    },
    getMessagesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    conversationRequest(state) {
      state.isLoading = false;
    },
    conversationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.personel_msg_current_page = action.payload.data.current_page;
      state.personel_msg_next_page = action.payload.data.next_page;
      state.personel_msg_total_page = action.payload.data.total_page;
      const list = [...action.payload.data.messages, ...state.personel_messages];
      const temp = list.filter((item, index, self) => {
        return index === self.findIndex((s) => s.message_id === item.message_id);
      });
      state.personel_messages = temp;
    },
    conversationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    unreadStatusRequest(state) {
      state.isLoading = true;
    },
    unreadStatusSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    unreadStatusFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    initiateProjectChatRequest(state) {
      state.isLoading = true;
    },
    initiateProjectChatSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    initiateProjectChatFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getProjectThreadsRequest(state) {
      state.isLoading = true;
    },
    getProjectThreadsSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.Thread_current_page = action.payload.data.current_page;
      state.Thread_next_page = action.payload.data.next_page;
      state.Thread_total_page = action.payload.data.total_page;
      const list = [...state.threads, ...action.payload.data.threads];
      const temp = list.filter((item, index, self) => {
        return index === self.findIndex((s) => s.thread_id === item.thread_id);
      });
      state.threads = temp;
    },
    getProjectThreadsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getProjectMessagesRequest(state) {
      state.isLoading = false;
    },
    getProjectMessagesSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.msg_current_page = action.payload.data.current_page;
      state.msg_next_page = action.payload.data.next_page;
      state.msg_total_page = action.payload.data.total_page;
      const list = [...state.messages, ...action.payload.data.messages];
      const temp = list.filter((item, index, self) => {
        return index === self.findIndex((s) => s.message_id === item.message_id);
      });
      state.messages = temp;
      state.currThreadId = action.payload.data.thread_id;
    },
    getProjectMessagesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    projectConversationRequest(state) {
      state.isLoading = false;
    },
    projectConversationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.msg_current_page = action.payload.data.current_page;
      state.msg_next_page = action.payload.data.next_page;
      state.msg_total_page = action.payload.data.total_page;
      const list = [...action.payload.data.messages, ...state.messages];
      const temp = list.filter((item, index, self) => {
        return index === self.findIndex((s) => s.message_id === item.message_id);
      });
      state.messages = temp;
    },
    projectConversationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getProjectMembersRequest(state) {
      state.isLoading = true;
    },
    getProjectMembersSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    getProjectMembersFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  resetChatMessages,
  clearMessages,
  clearThreads,
  clearPersonelMessages,
  clearPersonelThreads,
  initiateChatRequest,
  initiateChatSuccess,
  initiateChatFailure,
  getThreadsRequest,
  getThreadsSuccess,
  getThreadsFailure,
  getMessagesRequest,
  getMessagesSuccess,
  getMessagesFailure,
  conversationRequest,
  conversationSuccess,
  conversationFailure,
  unreadStatusRequest,
  unreadStatusSuccess,
  unreadStatusFailure,
  getProjectMessagesFailure,
  getProjectMessagesRequest,
  getProjectMessagesSuccess,
  getProjectThreadsFailure,
  getProjectThreadsRequest,
  getProjectThreadsSuccess,
  projectConversationFailure,
  projectConversationRequest,
  projectConversationSuccess,
  initiateProjectChatFailure,
  initiateProjectChatRequest,
  initiateProjectChatSuccess
} = chatSlice.actions;

export default chatSlice.reducer;
