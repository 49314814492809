import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { defaultValues } from '../../../constants';
import { SelectServiceStyles } from './selectServiceStyles';
import {
  BulletListsWithTitle,
  Text,
  UIAutocompleted,
  UIButton,
  UICheckBoxTitle
} from '../../../ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { selectService, serviceRequest } from '../../../services/redux/slices';
import {
  getServices,
  getServicesCurrentPage,
  getServicesTotalPage,
  settingLoadingStatus
} from '../../../services/redux/selectors';

export const SelectServiceSection = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(settingLoadingStatus);
  const serviceSelector = useSelector(getServices);
  const totalPage = useSelector(getServicesTotalPage);
  const currentPage = useSelector(getServicesCurrentPage);
  let timer;
  let pageService = 0;

  const [selectedService, setSelectedService] = useState('');
  const [serviceDetails, setServiceDetails] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [searchService, setSearchService] = useState('');

  const handleButtonClick = () => {
    if (serviceDetails) {
      dispatch(
        selectService({
          languages: serviceData.offered_languages,
          question: serviceData.questions,
          serviceId: serviceData.id
        })
      );
    } else {
      const temp = [...serviceList];
      const selected = temp.find((item) => item.title === selectedService);
      setServiceData(selected);
      selectedService && setServiceDetails(true);
    }
  };

  useEffect(() => {
    let payload = {
      page: 1
    };
    if (searchService !== '') {
      payload.search_text = searchService;
    }
    dispatch(serviceRequest(payload));
    clearTimeout(timer);
  }, [searchService]);

  useEffect(() => {
    if (serviceSelector.services) {
      setServiceList((prev) => {
        const existingServiceIds = prev.map((service) => service.id);
        const uniqueServices = serviceSelector.services.filter(
          (service) => !existingServiceIds.includes(service.id)
        );
        return [...prev, ...uniqueServices];
      });
    }
  }, [serviceSelector]);

  const handleSelectedValue = useCallback((value) => {
    setSelectedService(value);
    setServiceDetails(false);
  }, []);

  const getServiceData = () => {
    if (totalPage > currentPage) {
      pageService = currentPage + 1;
      let payload = { page: pageService };
      if (searchService !== '') {
        payload.search_text = searchService;
      }
      dispatch(serviceRequest(payload));
      clearTimeout(timer);
    }
  };

  return (
    <Box sx={SelectServiceStyles.container}>
      {!serviceDetails && (
        <Text
          style={SelectServiceStyles.heading(defaultValues.isResponsive)}
          variant={defaultValues.isResponsive ? 'body' : 'secondaryHeader'}>
          {defaultValues.enquireSelectServiceHeading}
        </Text>
      )}
      <Box sx={SelectServiceStyles.selectContainer(defaultValues.isResponsive, serviceDetails)}>
        <UIAutocompleted
          title={'Service Type'}
          placeholder={'Choose your Service'}
          variant={defaultValues.isResponsive ? 'medium' : 'menuText'}
          options={serviceList && serviceList.map((item) => item.title)}
          addShowButton={false}
          selectedValue={handleSelectedValue}
          isLoading={isLoading}
          style={SelectServiceStyles.selectTitle(defaultValues.isResponsive)}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2) {
                setSearchService(val);
              } else setSearchService('');
            }, 500);
          }}
          onScrollEnd={() => getServiceData()}
        />
      </Box>

      {serviceData && serviceDetails && (
        <Box sx={SelectServiceStyles.details}>
          <Text
            style={SelectServiceStyles.serviceDetailsHeading}
            variant={defaultValues.isResponsive ? 'smallBold' : 'menuText'}>
            Service Details
          </Text>
          <Text variant={defaultValues.isResponsive ? 'responsiveDesc' : 'small'}>
            {serviceData.summary}
          </Text>
          <BulletListsWithTitle
            data={serviceData.offered_languages}
            title={'Offered languages'}
            type="language"
          />
          <UICheckBoxTitle
            key="pre_payment_required"
            title="Pre-payment required"
            values={defaultValues.prePaymentOptions}
            onCheck={(data) => ('pre_payment_required', data)}
            selected={serviceData.pre_payment_required}
            disableSelection={true}
          />
        </Box>
      )}
      <Box sx={SelectServiceStyles.buttonContainer(defaultValues.isResponsive)}>
        <UIButton
          disable={!selectedService}
          size="large"
          style={SelectServiceStyles.buttonStyle(selectedService, defaultValues.isResponsive)}
          onClick={handleButtonClick}
          title={serviceDetails ? 'continue' : 'submit'}
        />
      </Box>
    </Box>
  );
};
