import { Box } from '@mui/material';
import { GoalStyles } from './styles';
import { defaultValues } from '../../constants';
import { Text, UIButton } from '../../ui-kit';
import iconFour from '../../assets/icons/icon_part_four.png';
import { Link } from 'react-router-dom';

export const Goal = () => {
  return (
    <Box sx={GoalStyles.container}>
      <Text
        variant={defaultValues.isResponsive ? 'fileName' : 'header'}
        style={GoalStyles.heading1}>
        {defaultValues.goal.heading1}
        <span style={GoalStyles.heading2}>{defaultValues.goal.heading2}</span>
      </Text>
      {defaultValues.goal.desc.map((item, index) => (
        <Text
          key={index}
          variant={defaultValues.isResponsive ? 'responsiveDesc' : 'body'}
          style={GoalStyles.heading3}>
          {item.desc}
        </Text>
      ))}
      <Link to={defaultValues.landingPageUrls.aboutUs}>
        <UIButton title="More about Us" style={GoalStyles.button} />
      </Link>
      {!defaultValues.isResponsive && <img style={GoalStyles.image} alt="icon" src={iconFour} />}
    </Box>
  );
};
