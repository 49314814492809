import { color } from '../../theme';

export const style = {
  container: {
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: color.secondaryBackground
  },
  sidebarContainer: {
    width: '100%',
    height: window.innerHeight,
    display: 'flex',
    position: 'fixed',
    backgroundColor: color.secondaryBackground
  },
  subContainer: (res) => ({ width: res ? '100%' : '82%' }),
  childrenContainer: {
    with: '100%',
    height: '90%',
    overflowY: 'auto'
  },
  detailsSubContainer: {
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: 2
  },
  detailsChildContainer: {
    padding: '2% 4%',
    boxSizing: 'border-box',
    backgroundColor: color.secondaryBackground
  }
};
