import React from 'react';
import { Box } from '@mui/material';
import { Text } from '../text';
import { palette } from '../../theme/palette';
import { UIBackButton } from '../back-button';
import { defaultValues } from '../../constants';

export const UISubTitleBar = ({ title, onBack, endComponent }) => {
  const styles = {
    leftSide: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxHeight: '70px'
    },
    bottomBorder: {
      display: 'flex',
      width: '100%',
      height: '100%',
      borderBottom: `0.1rem solid ${palette.borderColor}`,
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: defaultValues.isResponsive ? '0px' : '25px',
      paddingBottom: '20px',
      boxSizing: 'border-box'
    },
    myFont: {
      fontSize: defaultValues.isResponsive && '18px',
      fontWeight: 600,
      marginLeft: '20px',
      marginBottom: '10px',
      color: palette.primaryBlack
    }
  };
  return (
    <Box sx={styles.leftSide}>
      <UIBackButton onClick={onBack} />
      <Box sx={styles.bottomBorder}>
        <Text variant="sectionTitle" style={styles.myFont}>
          {title}
        </Text>
        {endComponent && endComponent}
      </Box>
    </Box>
  );
};
