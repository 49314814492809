import { Box } from '@mui/material';
import { defaultValues, getErrorMessage } from '../../../constants';
import { useEffect, useState } from 'react';
import { OtpStyles } from './otpStyles';
import { useDispatch, useSelector } from 'react-redux';
import { enquiryVerifyOtpRequest, resendEnquiryOtpRequest } from '../../../services/redux/slices';
import { getUserEmail } from '../../../services/redux/selectors';
import { Otp } from '../../common/otp';
import { Text, UIButton } from '../../../ui-kit';

export const OtpSection = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);

  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otpSend, setOtpSend] = useState(false);

  const handleSubmit = () => {
    dispatch(enquiryVerifyOtpRequest({ email: userEmail, otp: otp }));
  };

  useEffect(() => {
    setOtpSend(true);
  }, []);

  const handleOtpChange = (val) => {
    if (!isNaN(Number(val))) {
      setOtp(val);
      setErrorMessage('');
    } else if (!otp) {
      setErrorMessage(getErrorMessage(defaultValues.errorCode));
    }
  };

  const handleOtpBlur = () => {
    if (otp.length !== 6) {
      setErrorMessage(getErrorMessage(defaultValues.errorCode));
    }
  };

  const handleResend = () => {
    dispatch(resendEnquiryOtpRequest({ email: userEmail }));
  };

  const handleOtpSend = (val) => {
    setOtpSend(val);
  };

  return (
    <Box sx={OtpStyles.container}>
      <Text
        style={OtpStyles.heading}
        variant={defaultValues.isResponsive ? 'small' : 'secondaryHeader'}>
        {defaultValues.enquireOtpHeading}
      </Text>
      <Text
        style={OtpStyles.label}
        variant={defaultValues.isResponsive ? 'responsiveDesc' : 'body'}>
        {defaultValues.enquireOtpDesc(userEmail)}
      </Text>

      <Otp
        otp={otp}
        onChange={handleOtpChange}
        onBlur={handleOtpBlur}
        otpErrorMessage={errorMessage}
        otpSend={otpSend}
        handleOtpSend={handleOtpSend}
        onResendClick={handleResend}
        otpInputStyle={OtpStyles.otpInputStyles}
        otpContainerStyles={OtpStyles.otpContainer}
        timerStyle={OtpStyles.otpTimer}
      />

      <UIButton
        disabled={otp.length < 6 || errorMessage}
        size="large"
        style={OtpStyles.buttonStyle(otp, defaultValues.isResponsive)}
        title="Verify"
        onClick={handleSubmit}
      />
    </Box>
  );
};
