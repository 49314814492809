import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
import { palette } from '../../theme/palette';
const responsive = defaultValues.isResponsive;
export const paginationStyles = {
  pagination: (item, IsSelected) => ({
    margin: '5px 5px',
    padding: '10px',
    color:
      item.type === 'next' || item.type === 'previous'
        ? color.palette.primaryWhite
        : IsSelected === item.page
          ? color.palette.primaryBlack
          : color.palette.gray,
    backgroundColor:
      item.type === 'next' || item.type === 'previous'
        ? color.palette.primaryRed
        : color.palette.primaryWhite,
    fontSize: responsive ? '12px' : '20px',
    fontWeight: IsSelected === item.page ? 'bold' : '200',
    '&:hover': {
      backgroundColor:
        item.type === 'next' || item.type === 'previous'
          ? palette.primaryRed
          : palette.primaryWhite,
      color:
        item.type === 'next' || item.type === 'previous'
          ? palette.primaryWhite
          : palette.primaryBlack
    },

    border:
      item.type === 'next' || item.type === 'previous' ? `1px solid ${palette.primaryRed}` : 'none'
  })
};
